import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import TileLocation from "../../../Tile/TileLocation";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import DialogSearchLocation from "../../../Dialog/Search/DialogSearchLocation";


export default class CardAdminLocationCredentialing extends Card {
    constructor(props) {
        super(props);

        this.state.billingLocationDialogOpen = false;
    }

    static contextTypes = {
        locationsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        let location = this.props.location;
        let billingLocation = null;

        if (location && location.billingLocationId !== null) {
            billingLocation = this.context.locationsIndexed[location.billingLocationId];
        }

        // Billing Location
        const handleEditBillingLocation = () => {
            this.setState({ billingLocationDialogOpen: true });
        };
        const handleClearBillingLocation = () => {
            this.props.onChange({ billingLocationId: null });
        };
        const billingLocationIconButtons = [
            (<MUIIconButton onClick={handleEditBillingLocation}><MUIEditIcon /></MUIIconButton>)
        ];

        if (billingLocation) {
            billingLocationIconButtons.push(
                (<MUIIconButton onClick={handleClearBillingLocation}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        return (
            <MUIGrid container spacing={2}>
                 <MUIGrid item xs={12} sm={6} md={4}>
                    <TileLocation
                        location={billingLocation}
                        iconButtons={billingLocationIconButtons}
                        label="Billing Location"
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.location && this.props.location.npi ? this.props.location.npi : ""}
                        size="small"
                        label="NPI"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 10,
                            inputMode: "numeric", // Show numeric keyboard on mobile
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, ''); // Allow only digits
                            this.props.onChange({ npi: value });
                        }}
                        error={this.props.validationIssues.npi ? true : false}
                        helperText={this.props.validationIssues.npi ? this.props.validationIssues.npi : ""}
                    />
                </MUIGrid>
                <DialogSearchLocation
                    open={this.state.billingLocationDialogOpen}
                    onClose={() => this.setState({ billingLocationDialogOpen: false })}
                    onSelect={(location) => {
                        this.props.onChange({ billingLocationId: location.id });
                    }}
                />
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Credentialing";
    }
}