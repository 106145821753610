import React from "react";
import DialogSearch from "../DialogSearch";
import TileIssueCategory from "../../Tile/TileIssueCategory";
import PropTypes from "prop-types";
import IssueCategory from "../../../Utility/Crud/IssueCategory";

export default class DialogSearchIssueCategory extends DialogSearch {

    static contextTypes = {
        issueCategoriesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered issueCategory.
     */
    getSearchResults(query) {
        return IssueCategory.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.issueCategoriesIndexed);
        }
    }

    /**
     * @param {object} issueCategory The issueCategory object.
     * @returns A TileIssueCategory component for this issueCategory.
     */
    renderTile(issueCategory) {
        return (
            <TileIssueCategory
                key={issueCategory.id}
                issueCategory={issueCategory}
            />
        );
    }
}
