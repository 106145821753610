import React from "react";
import Layer from "../../Layer";
import PageHeader from "../../Component/PageHeader";
import MUIGrid from "@mui/material/Grid";
import Team from "../../Utility/Crud/Team";
import TeamQueue from "../../Utility/Crud/TeamQueue";
import TeamUser from "../../Utility/Crud/TeamUser";
import Queue from "../../Utility/Crud/Queue";
import CardQueueDashboard from "../../Component/Card/Queue/CardQueueDashboard";
import QueueService from "../../Seating/Security/QueueService/queueService";
import Department from "../../Utility/Crud/Department";
import Security from "../../Utility/Security";
import QueuePrefilters from "../../Component/QueuePrefilters";
import EmptyState from "../../Component/EmptyState";
import MUICard from "@mui/material/Card";
import MUICardContent from "@mui/material/CardContent";
import Setting from "../../Utility/Setting";
import User from "../../Utility/Crud/User";
import MarketingCampaign from "../../Utility/Crud/MarketingCampaign";
import Term from "../../Utility/Crud/Term";

export default class QueueDashboard extends Layer {
    /**
     * Set the data in the state.
     */
    afterComponentDidMount() {
        this.setRegionTypeTermIdSetting();
        this.getQueueCounts();
    }

    setRegionTypeTermIdSetting() {
        // Get a list of available region types.
        let availableRegionTypeTerms;
        if (Security.hasPermission("user.viewAllRegions") === true) {
            availableRegionTypeTerms = Term.sort(
                Term.read(
                    this.context.termsIndexed,
                    [{ key: "type", value: "region_type" }]
                )
            );
        } else {
            const availableRegionTypeTermIds = {};
            Object.values(this.context.teamUsersIndexed).some((teamUser) => {
                if (teamUser.userId === this.context.currentUser.id) {
                    const team = this.context.teamsIndexed[teamUser.teamId];
                    if (team && team.departmentId) {
                        const department = this.context.departmentsIndexed[team.departmentId];
                        if (department.jsonPrefilters?.regionTypeTermId) {
                            availableRegionTypeTermIds[department.jsonPrefilters.regionTypeTermId] = true;
                        }
                    }
                }

                return false;
            });
            availableRegionTypeTerms = Term.sort(
                Term.read(
                    this.context.termsIndexed,
                    [{ key: "id", value: Object.keys(availableRegionTypeTermIds).map(Number) }]
                )
            );
        }

        // Initialize this setting
        if (
            availableRegionTypeTerms.length > 0 &&
            (
                Setting.get("queue.prefilters.regionTypeTermId") === undefined ||
                !availableRegionTypeTerms.some(term => term.id === Setting.get("queue.prefilters.regionTypeTermId"))
            )
        ) {
            Setting.set(
                "queue.prefilters.regionTypeTermId",
                availableRegionTypeTerms[0].id
            );
        }
    }

    /**
     * Get prefilters based on the settings.
     *
     * @param {number} id The ID to get prefilters for.
     * @returns {array}
     */
    getPrefilters() {
        const prefilters = [];

        if (
            Security.hasPermission("queue.useRegionPrefilter") === false &&
            Security.hasPermission("queue.useLocationPrefilter") === false &&
            Security.hasPermission("queue.useUserPrefilter") === false &&
            Security.hasPermission("queue.useMarketingCampaignPrefilter") === false
        ) {
            return prefilters;
        }

        /**
         * For all of these prefilters, we default to using the user's chosen
         * setting (if one is set). If the user did not explicitly pick one,
         * then it defaults to all that they have access to. However, if the
         * user has access to all, then just don't set a filter at all to avoid
         * sending hundreds of IDs in a filter.
         */

        // Region
        const filteredRegions = User.getFilteredRegions(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.regionsIndexed,
            this.context.locationsIndexed
        ).filter((region) => {
            if (Security.hasPermission("user.viewAllRegions") === true) {
                return true;
            } else {
                // Filter only regions with a type available to my prefilters
                return Object.values(this.context.teamUsersIndexed).some((teamUser) => {
                    if (teamUser.userId === this.context.currentUser.id) {
                        const team = this.context.teamsIndexed[teamUser.teamId];
                        if (team && team.departmentId) {
                            const department = this.context.departmentsIndexed[team.departmentId];
                            return (
                                department.jsonPrefilters?.regionTypeTermId === region.regionTypeTermId
                            );
                        }
                    }

                    return false;
                });
            }
        });
        const filteredRegionIds = filteredRegions.map(region => region.id);

        let prefilterRegionIds = null;
        if (Setting.get("queue.prefilters.regionIds")) {
            prefilterRegionIds = Setting.get("queue.prefilters.regionIds").filter(regionId =>
                filteredRegionIds.includes(regionId)
            );
            if (prefilterRegionIds.length === 0) {
                prefilterRegionIds = filteredRegionIds;
            }
        } else {
            if (!Security.hasPermission("user.viewAllRegions")) {
                prefilterRegionIds = filteredRegionIds;
            }
        }

        // Location
        const filteredLocations = User.getFilteredLocations(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.locationsIndexed
        );
        const filteredLocationIds = filteredLocations.map(location => location.id);

        let prefilterLocationIds = null;
        if (Setting.get("queue.prefilters.locationIds")) {
            prefilterLocationIds = Setting.get("queue.prefilters.locationIds").filter(locationId =>
                filteredLocationIds.includes(locationId)
            );
            if (prefilterLocationIds.length === 0) {
                prefilterLocationIds = filteredLocationIds;
            }
        } else {
            if (!Security.hasPermission("user.viewAllLocations")) {
                prefilterLocationIds = filteredLocationIds;
            }
        }

        // User
        const filteredUsers = User.getFilteredUsers(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.locationsIndexed,
            this.context.usersIndexed,
            this.context.salesPcrLinksIndexed
        );
        const filteredUserIds = filteredUsers.map(user => user.id);

        let prefilterUserIds = null;
        if (Setting.get("queue.prefilters.userIds")) {
            prefilterUserIds = Setting.get("queue.prefilters.userIds").filter(userId =>
                filteredUserIds.includes(userId)
            );
            if (prefilterUserIds.length === 0) {
                prefilterUserIds = filteredUserIds;
            }
        } else {
            if (!Security.hasPermission("user.viewAllUsers")) {
                prefilterUserIds = filteredUserIds;
            }
        }

        // Marketing Campaign
        const filteredMarketingCampaignIds = Object.values(this.context.marketingCampaignsIndexed)
            .map(marketingCampaign => marketingCampaign.id);

        let prefilterMarketingCampaignIds = null;
        if (
            Setting.get("queue.prefilters.marketingCampaignIds") &&
            Setting.get("queue.prefilters.marketingCampaignIds").length > 0
        ) {
            prefilterMarketingCampaignIds = Setting.get("queue.prefilters.marketingCampaignIds").filter(marketingCampaignId =>
                filteredMarketingCampaignIds.includes(marketingCampaignId)
            );
            if (prefilterMarketingCampaignIds.length === 0) {
                prefilterMarketingCampaignIds = filteredMarketingCampaignIds;
            }
        }

        // Set up the prefilter object.
        if (
            prefilterRegionIds &&
            Security.hasPermission("queue.useRegionPrefilter")
        ) {
            let key;
            if (Setting.get("queue.prefilters.regionTypeTermId") === 11) { // Sales
                key = "order_activity.sales_location.region_id"
            } else if (Setting.get("queue.prefilters.regionTypeTermId") === 12) { // Field Operations
                key = "order_activity.service_location.field_operations_region_id"
            } else {
                throw new Error(`Invalid queue.prefilters.regionTypeTermId: ${Setting.get("queue.prefilters.regionTypeTermId")}`);
            }

            prefilters.push({
                Key: key,
                Value: prefilterRegionIds.map(value => String(value)),
            });
        }

        if (
            prefilterLocationIds &&
            Security.hasPermission("queue.useLocationPrefilter")
        ) {
            let key;
            if (Setting.get("queue.prefilters.regionTypeTermId") === 11) { // Sales
                key = "order_activity.sales_location_id"
            } else if (Setting.get("queue.prefilters.regionTypeTermId") === 12) { // Field Operations
                key = "order_activity.service_location_id"
            } else {
                throw new Error(`Invalid queue.prefilters.regionTypeTermId: ${Setting.get("queue.prefilters.regionTypeTermId")}`);
            }

            prefilters.push({
                Key: key,
                Value: prefilterLocationIds.map(value => String(value)),
            });
        }

        if (
            prefilterUserIds &&
            Security.hasPermission("queue.useUserPrefilter")
        ) {
            prefilters.push({
                Key: "order_activity.activity.owner_id",
                Value: prefilterUserIds.map(value => String(value)),
            });
        }

        if (
            prefilterMarketingCampaignIds &&
            Security.hasPermission("queue.useMarketingCampaignPrefilter")
        ) {
            prefilters.push({
                Key: "order_activity.marketing_campaign_id",
                Value: prefilterMarketingCampaignIds.map(value => String(value)),
            });
        }

        return prefilters;
    }

    getQueueCounts() {
        let queueIds = [];

        this.setState({queueCounts: null});

        Object.values(this.context.departmentsIndexed).forEach((department) => {
            let teams = Team.read(
                this.context.teamsIndexed,
                [{
                    key: "departmentId",
                    value: department.id
                }]
            );

            // If user can't view all queues.
            if (Security.hasPermission("queue.viewAll") === false) {
                // Do I have a teamUser for one of the teams in the current department?
                let teamUsers = TeamUser.read(
                    this.context.teamUsersIndexed,
                    [{
                        key: "teamId",
                        value: teams.map(team => team.id)
                    }, {
                        key: "userId",
                        value: this.context.currentUser.id
                    }]
                );

                // Am I a manager of one of the teams in the current department?
                let teamsCurrentUserManages = Team.read(
                    this.context.teamsIndexed,
                    [{
                        key: "departmentId",
                        value: department.id
                    },
                    {
                        key: "managerId",
                        value: this.context.currentUser.id
                    }]
                );

                // If the current user is not a member of one of the teams in
                // the department, and the current user is not a manager of one
                // of the teams in the department, don't show this department.
                if (
                    teamUsers.length === 0 &&
                    teamsCurrentUserManages.length === 0
                ) {
                    return;
                }
            }

            const teamQueues = TeamQueue.read(
                this.context.teamQueuesIndexed,
                [{
                    key: "teamId",
                    value: teams.map(team => team.id)
                }]
            );

            queueIds = queueIds.concat(teamQueues.map(teamQueue => teamQueue.queueId));
        });

        // Create an array of promises for each queueId
        const promises = queueIds.map(queueId =>
            QueueService.getQueueCounts({
                ids: [queueId],
                prefilters: this.getPrefilters()
            })
                .then(queueCounts => {
                    // Update the state with the cumulative result
                    this.setState(prevState => ({
                        queueCounts: {
                            ...prevState.queueCounts,
                            [queueId]: queueCounts[queueId]
                        }
                    }));
                })
                .catch(err => {
                    console.log(err);
                })
        );

        // Execute all promises
        Promise.all(promises);
    }

    /**
     * Render the content.
     */
    renderContent() {
        let showEmptyState = true;

        const grid = (
            <MUIGrid container spacing={2}>
                {Department.sort(Object.values(this.context.departmentsIndexed)).map((department) => {
                    const teams = Team.read(
                        this.context.teamsIndexed,
                        [{
                            key: "departmentId",
                            value: department.id
                        }]
                    );

                    // If user can't view all queues.
                    if (Security.hasPermission("queue.viewAll") === false) {
                        // Do I have a teamUser for one of the teams in the current department?
                        let teamUsers = TeamUser.read(
                            this.context.teamUsersIndexed,
                            [{
                                key: "teamId",
                                value: teams.map(team => team.id)
                            }, {
                                key: "userId",
                                value: this.context.currentUser.id
                            }]
                        );

                        // Am I a manager of one of the teams in the current department?
                        let teamsCurrentUserManages = Team.read(
                            this.context.teamsIndexed,
                            [{
                                key: "departmentId",
                                value: department.id
                            },
                            {
                                key: "managerId",
                                value: this.context.currentUser.id
                            }]
                        );

                        // If the current user is not a member of one of the teams in
                        // the department, and the current user is not a manager of one
                        // of the teams in the department, don't show this department.
                        if (
                            teamUsers.length === 0 &&
                            teamsCurrentUserManages.length === 0
                        ) {
                            return null;
                        }
                    }

                    const teamQueues = TeamQueue.read(
                        this.context.teamQueuesIndexed,
                        [{
                            key: "teamId",
                            value: teams.map(team => team.id)
                        }]
                    );

                    const productionQueues = Queue.sort(Queue.read(
                        this.context.queuesIndexed,
                        [
                            {
                                key: "id",
                                value: teamQueues.map(teamQueue => teamQueue.queueId)
                            },
                            {
                                key: "queueTypeTermId",
                                value: 1 // Production
                            }
                        ]
                    ));

                    const errorQueues = Queue.sort(Queue.read(
                        this.context.queuesIndexed,
                        [
                            {
                                key: "id",
                                value: teamQueues.map(teamQueue => teamQueue.queueId)
                            },
                            {
                                key: "queueTypeTermId",
                                value: 2 // Error
                            }
                        ]
                    ));

                    if (
                        productionQueues.length > 0 ||
                        errorQueues.length > 0
                    ) {
                        showEmptyState = false;

                        return (
                            <React.Fragment key={department.id}>
                                <MUIGrid item xs={12} sm={6}>
                                    <CardQueueDashboard
                                        queueCounts={this.state.queueCounts}
                                        department={department}
                                        queueTypeTerm={this.context.termsIndexed[1]} // Production
                                        queues={productionQueues}
                                        size={Math.max(productionQueues.length, errorQueues.length)}
                                    />
                                </MUIGrid>
                                <MUIGrid item xs={12} sm={6}>
                                    <CardQueueDashboard
                                        queueCounts={this.state.queueCounts}
                                        department={department}
                                        queueTypeTerm={this.context.termsIndexed[2]} // Error
                                        queues={errorQueues}
                                        size={Math.max(productionQueues.length, errorQueues.length)}
                                    />
                                </MUIGrid>
                            </React.Fragment>
                        );
                    }

                    return null;
                })}
            </MUIGrid>
        );

        if (showEmptyState === true) {
            return (
                <MUICard>
                    <MUICardContent>
                        <EmptyState line1="No Queues Assigned" line2="Please reach out to your manager to request access." />
                    </MUICardContent>
                </MUICard>
            );
        }

        return grid;
    }

    renderHeader() {
        const buttons = [];
        if (
            Security.hasPermission("queue.useRegionPrefilter") === true ||
            Security.hasPermission("queue.useLocationPrefilter") === true ||
            Security.hasPermission("queue.useUserPrefilter") === true
        ) {
            buttons.push(<QueuePrefilters direction="row" onChange={this.getQueueCounts.bind(this)} />);
        }

        return (
            <PageHeader title="Dashboard" buttons={buttons} />
        );
    }

    /**
     * @returns The max width of this page.
     */
    getMaxWidth() {
        return 1000;
    }
}
