import Moment from "moment";

class QuickFilter {
    getOptions() {
        return [
            {
                label: "Today",
                value: 0,
            },
            {
                label: "This Week",
                value: 1,
            },
            {
                label: "This Month",
                value: 2,
            },
            {
                label: "Last 7 Days",
                value: 3,
            },
            {
                label: "Last 30 Days",
                value: 4,
            },
            {
                label: "Previous Week",
                value: 5,
            },
            {
                label: "Previous Month",
                value: 6,
            },
            {
                label: "Last 6 Months",
                value: 7,
            },
            {
                label: "Last 12 Months",
                value: 8,
            },
            {
                label: "Custom",
                value: 9,
            },
        ];
    }

    getOptionsShort() {
        return [
            {
                label: "Today",
                value: 0,
            },
            {
                label: "This Week",
                value: 1,
            },
            {
                label: "This Month",
                value: 2,
            },
            {
                label: "Last 7 Days",
                value: 3,
            },
            {
                label: "Last 30 Days",
                value: 4,
            },
            {
                label: "Previous Week",
                value: 5,
            },
            {
                label: "Previous Month",
                value: 6,
            },
            {
                label: "Last 6 Months",
                value: 7,
            },
            {
                label: "Last 12 Months",
                value: 8,
            },
            {
                label: "Custom",
                value: 9,
            },
        ];
    }

    getDates(val) {
        switch (val) {
            // Today
            default:
            case 0:
                // [start date, end date]
                return [new Date(), new Date()];
                // This Week
            case 1:
                return [
                    Moment().startOf("week").add(1, "days").toDate(),
                    Moment().endOf("week").add(1, "days").toDate(),
                ];
                // This Month
            case 2:
                return [
                    Moment().startOf("month").toDate(),
                    Moment().endOf("month").toDate(),
                ];
                // Last 7 Days
            case 3:
                return [Moment().subtract(6, "days").toDate(), new Date()];
                // Last 30 Days
            case 4:
                return [Moment().subtract(29, "days").toDate(), new Date()];
                // Previous Week
            case 5:
                return [
                    Moment().subtract(1, "week").startOf("week").add(1, "days").toDate(),
                    Moment().subtract(1, "week").endOf("week").add(1, "days").toDate(),
                ];
                // Previous Month
            case 6:
                return [
                    Moment().subtract(1, "month").startOf("month").toDate(),
                    Moment().subtract(1, "month").endOf("month").toDate(),
                ];
                // Last 6 Months
            case 7:
                return [Moment().subtract(6, "months").toDate(), new Date()];
                // Last 12 Months
            case 8:
                return [Moment().subtract(12, "months").toDate(), new Date()];
                //  Custom
            case 9:
                return [];
        }
    }
}

const quickFilter = new QuickFilter();

export default quickFilter;
