import TermList from "../AdminTermList";

export default class AdminTermListInvoiceFollowupStatus extends TermList {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return "invoice_followup_status";
    }
}
