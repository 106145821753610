import React from "react";
import {
    Button,
    Card,
    CardBody,
    Input,
    toast,
    CardHeader,
    MDBIcon,
    ToastContainer,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import Select from "react-select";
import ProdcutMatrixEntryService from "../Security/ProdcutMatrixEntryService/prodcutMatrixEntryService";

export default class addProdcutMatrixEntry extends React.Component {
    constructor(props) {
        super(props);

        let sp = props.productSubType
            ? { label: props.productSubType.name, value: props.productSubType.id }
            : null;

        this.state = {
            errorMessage: "",
            insuranceTypes: [],
            insuranceOptions: [],
            selectedProduct: sp,
            selectedInsurance: null,
            nProduct: {},
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        insuranceTypes: PropTypes.array,
    };

    componentDidMount() {
        this.getInsuranceOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { insuranceTypes } = this.context;
        if (
            insuranceTypes.length > 0 &&
			insuranceTypes.length !== prevState.insuranceTypes.length
        ) {
            this.getInsuranceOptions();
        }
    }

    getInsuranceOptions() {
        const { insuranceTypes } = this.context;
        let arr = [];
        let entryId = this.props.entries.map((e) => e.insuranceTypeId);
        insuranceTypes.map((v) => {
            return arr.push({
                label: v.name,
                value: v.id,
                isDisabled: entryId.includes(v.id) ? true : false,
            });
        });
        this.setState({
            insuranceTypes: insuranceTypes,
            insuranceOptions: arr,
        });
    }

    renderPage() {
        let st = this.state,
            np = st.nProduct;
        return (
            <div>
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <Input
                    value={st.selectedProduct ? st.selectedProduct.label : ""}
                    label={"Product SubType"}
                    disabled
                />
                <br />
                <Select
                    placeholder={"Insurance"}
                    options={st.insuranceOptions}
                    onChange={this.handleChange.bind(this, "selectedInsurance")}
                    value={st.selectedInsurance}
                />
                <Input
                    value={np.points || ""}
                    type="number"
                    label={ "Points" }
                    onChange={this.handleChange.bind(this, "points")}
                />
                <div style={{ float: "right" }}>
                    <Button
                        size="sm"
                        color={"red"}
                        data-tip={"Close"}
                        onClick={this.props.closeButton}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        size="sm"
                        color={"success"}
                        data-tip={"Save New Product"}
                        onClick={this.addSaveButtonClick}
                    >
                        <MDBIcon far icon="save" style={{ fontSize: "2em" }} />
                    </Button>
                </div>
                <ReactTooltip />
            </div>
        );
    }
    handleChange = (prop, e) => {
        if (prop === "points") {
            let p = this.state.nProduct;
            p[prop] = e.target.value !== "" ? parseFloat(e.target.value) : null;
            this.setState({
                nProduct: p,
            });
        } else {
            this.setState({
                [prop]: e,
            });
        }
    };

    addSaveButtonClick = () => {
        if (this.validate()) {
            let st = this.state,
                p = st.nProduct;
            p.insuranceTypeId = st.selectedInsurance.value;
            p.productSubTypeId = st.selectedProduct.value;
            return ProdcutMatrixEntryService.createProduct(p)
                .then((res) => {
                    if (res) {
                        this.setState({
                            errorMessage: "",
                            nProduct: {},
                            selectedInsurance: null,
                            selectedProduct: null,
                        });
                        this.props.getAllProductMatrixEntrySubTypes();
                        this.props.editSaveButtonClick();
                        this.props.closeButton();
                    } else {
                        this.setState({
                            errorMessage:
								"Product points already overridden for insurance type: " +
								st.selectedInsurance.label,
                        });
                    }
                })
                .catch((err) => {
                    toast.warn("An error occured adding a new product");
                });
        }
    };

    validate() {
        let st = this.state;
        if (!st.selectedProduct) {
            this.setError("Please select a product");
            return false;
        } else if (!st.selectedInsurance) {
            this.setError("Please select an insurance");
            return false;
        } else if (!st.nProduct.points) {
            this.setError("Please enter points");
            return false;
        }
        return true;
    }

    setError(e) {
        this.setState({
            errorMessage: e,
        });
    }

    render() {
        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />

                <Card>
                    <CardHeader
                        color={"blue-grey lighten-2"}
                        style={{ textAlign: "center" }}
                    >
                        {" "}
						Add New Product
                    </CardHeader>
                    <CardBody>{this.renderPage()}</CardBody>
                </Card>
            </div>
        );
    }
}
