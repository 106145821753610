import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import Queue from "../../../../Utility/Crud/Queue";

export default withRouter(class CardAdminQueueList extends Card {
    static contextTypes = {
        queuesIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        const modifiedRows = Object.values(this.context.queuesIndexed).map((queue) => {
            return {
                ...queue,
                ...{
                    outOfBounds: Queue.getOutOfBoundsString(queue, "simple")
                },
            };
        });

        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                    { key: "queueTypeTermId", name: "Type", type: "term", filter: true },
                    { key: "table", name: "Type", type: "string" },
                    { key: "outOfBounds", name: "Out of Bounds", type: "string" },
                    { key: "ordinal", name: "Ordinal", type: "number" }
                ]}
                sort={[
                    {
                        key: "name",
                        direction: "ascending"
                    },
                ]}
                rows={modifiedRows}
                onClick={(queue) => {
                    this.props.history.push({
                        pathname: "/admin/queue/" + queue.id
                    });
                }}
            />
        );
    }
});