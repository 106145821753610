import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import Region from "../../Utility/Crud/Region";
import Term from "../../Utility/Crud/Term";
import PropTypes from "prop-types";

export default class TileRegion extends Tile {
    static contextTypes = {
        regionsIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The Region name.
      */
    getTitle() {
        return this.props.region ? Region.getDisplayName(this.props.region) : "None";
    }

    /**
     * @returns {string} The region type.
     */
    getSubtitle() {
        return this.props.region ? Term.getDisplayName(this.context.termsIndexed[this.props.region.regionTypeTermId]) : null;
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.region ? Region.getDisplayName(this.props.region) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.region ? Region.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return Region.getIcon();
    }
}
