import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import MUIGreyColor from "@mui/material/colors/grey";
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
    GroupingState,
    SortingState,
    IntegratedSorting,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
    SelectionState,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
    Button,
    Container,
    Col,
    Row,
    Spinner,
    toast,
    ToastContainer,
    MDBIcon,
    ModalBody,
    ModalFooter,
    Fa,
    Modal,
    MDBSelect,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBCardHeader,
} from "mdbreact";
import LiaisonMeetingService from "../Security/LiaisonMeetingService/LiaisonMeetingService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "../Orders/orderList.css";
import ReactTooltip from "react-tooltip";
import NoteService from "../Security/NoteService/noteService";
import OrderService from "../Security/OrderService/orderService";
import globalFunctions from "../Filters/GlobalFunctions";
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LayerLegacy from "../../LayerLegacy";


const compareDate = (a, b) => {
    let da = new Date(a),
        db = new Date(b);

    if (da > db) {
        return 1;
    } else if (da < db) {
        return -1;
    }

    //if here, it is the same value ;
    return 0;
};

export default class liaisonMeetingView extends LayerLegacy {
    Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

    CellContent = ({ row, column, expanded, ...props }) => {
        let cell = (
            <Table.Cell
                {...props}
                row={row}
                column={column}
                expanded={expanded}
                onClick={(e) => {
                    if (column.name === "orderId") {
                        this.setState({
                            targetLink: row.orderId,
                        });

                        setTimeout(() => {
                            document.getElementById("orderListId").click();
                        }, 5);
                    } else {
                        if (row.orderId === 999999999) {
                            this.setState({
                                modalOpen: true,
                                selectedMeeting: row,
                                orderId: row.orderId,
                            });
                        } else {
                            return OrderService.getOrderActivity(row.orderId).then((data) => {
                                this.setState({
                                    account: data.activity.account,
                                    order: data,
                                    modalOpen: true,
                                    selectedMeeting: row,
                                    orderId: row.orderId,
                                });
                            });
                        }
                    }
                }}
            />
        );
        return cell;
    };

    Content = ({ row, column }) => {
        return (
            <span>
                <span>
                    {column.title} : {row.value}
                </span>
                <span style={{ fontWeight: "bold" }}>&nbsp; {this.getVal(row)}</span>
            </span>
        );
    };
    getVal(row) {
        if (row.collapsedRows.length > 0) {
            return "Count: " + row.collapsedRows.length;
        } else {
            return "";
        }
    }

    ItemCounter = () => (
        <Plugin name="ItemCounter">
            <Template
                name="tableCell"
                predicate={({ tableRow }) => isGroupTableRow(tableRow)}
            >
                {(params) => (
                    <TemplateConnector>
                        {({ getCollapsedRows, rows }) => {
                            const updatedParams = {
                                ...params,
                                tableRow: {
                                    ...params.tableRow,
                                    row: {
                                        ...params.tableRow.row,
                                        collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                        rows: rows.filter((r) => {
                                            return r.groupedBy === undefined;
                                        }),
                                    },
                                },
                            };
                            return <TemplatePlaceholder params={updatedParams} />;
                        }}
                    </TemplateConnector>
                )}
            </Template>
        </Plugin>
    );

    changeGrouping = (grouping) => {
        let ary = [];

        grouping.forEach((group) => {
            ary.push({ columnName: group.columnName });
        });
        this.setState({
            groupingColumns: ary,
        });
    };

    constructor(props) {
        super(props);

        const groupColumns = [{ columnName: "salesLiaison" }];

        const data = {
            columns: [
                {
                    title: "Order ID",
                    name: "orderId",
                },
                {
                    title: "Sales Rep",
                    name: "salesRep",
                },
                {
                    title: "Sales Liaison",
                    name: "salesLiaison",
                },
                {
                    title: "Scheduled Date",
                    name: "scheduledDate",
                },
                {
                    title: "Status",
                    name: "status",
                },
                {
                    title: "Reason",
                    name: "reason",
                },
            ],
            rows: [],
        };

        let defaultColumnWidths = [
            { columnName: "id", width: 120 },
            { columnName: "orderId", width: 120 },
            { columnName: "salesRep", width: 150 },
            { columnName: "salesLiaison", width: 150 },
            { columnName: "scheduledDate", width: 250 },
            { columnName: "status", width: 100 },
            { columnName: "reason", width: 200 },
            { columnName: "complete", width: 150 },
            { columnName: "closedOn", width: 250 },
        ];

        const rOptions = [
            {
                text: "SWO / DSWO",
                value: "SWO / DSWO",
            },
            {
                text: "F2F",
                value: "F2F",
            },
            {
                text: "Eval",
                value: "Eval",
            },
            {
                text: "Rx",
                value: "Rx",
            },
            {
                text: "Verification",
                value: "Verification",
            },
            {
                text: "PA Expired",
                value: "PA Expired",
            },
            {
                text: "Doc Review",
                value: "Doc Review",
            },
            {
                text: "Insurance Denial",
                value: "Insurance Denial",
            },
            {
                text: "Insurance Review Kick Back",
                value: "Insurance Review Kick Back",
            },
            {
                text: "Time Frame In Task",
                value: "Time Frame In Task",
            },
        ];

        let sd = new Date(),
            ed = new Date();

        sd.setMinutes(0);
        ed.setMinutes(0);

        this.state = {
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            defaultColumnWidths: defaultColumnWidths,
            //integratedSortingColumnExtensions: integratedSortingColumnExtensions,
            groupSummaryItems: [],
            rOptions: rOptions,
            modalOpen: false,
            blockOutModalOpen: false,
            moveModalOpen: false,
            cancelModalOpen: false,
            startDate: sd,
            endDate: ed,
            allLiaisons: [],
            allReps: [],
            chosenLiaison: "",
            selectedRows: [],
            closedReason: "",
            statuses: [
                "New",
                "InProcess",
                "Ready to Deliver",
                "Setup",
                "Cancelled",
            ],
        };

        this.getResults();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { allLiaisons, allUsers } = this.context;
        let st = this.state;

        if (st.allLiaisons.length !== allLiaisons.length) {
            this.getLiaisonList();
        }

        if (st.allReps.length !== allUsers.length) {
            this.getSalesRepsList();
        }
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLiaisons: PropTypes.array,
        salesReps: PropTypes.array,
        allUsers: PropTypes.array,
    };

    downloadFunction() {
        this.clientCSV(this.state.data, "LiaisonMeetings.csv");
    }

    //This will convert all the data in the grid to a csv file
    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.name);
            headers.push(col.title);
        });

        let replace = ["meetingNotes", "scheduledDate", "closedOn"];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    linkingCell(cellData) {
        return (
            <div style={{ cursor: "pointer" }}>
                <a href={"/order/" + cellData.value} target={"_blank"} rel="opener">
                    {cellData.value}
                </a>
            </div>
        );
    }

    getResults() {
        LiaisonMeetingService.findAllMeetingsNative()
            .then((res) => {
                console.log(res);
                this.buildNewResults(res);
            })
            .catch((err) => {
                this.setState({ isLoaded: true });
            });
    }

    buildNewResults(res) {
        let ary = [],
            dt = this.state.data;

        res.forEach((val) => {
            ary.push({
                id: val.id,
                orderId: val.orderId,
                salesRep: val.salesRep,
                salesLiaison: val.salesLiaison,
                scheduledDate: globalFunctions.formatDateTime(val.scheduledDate),
                //scheduledDate: new Date(val.scheduledDate).toLocaleString("en-US", {
                //	timeZone: "America/Indiana/Indianapolis",
                //}),
                status: val.status,
                reason: val.reason,
            });
        });

        dt.rows = ary;

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    addNoteOrClose() {
        let m = this.state.selectedMeeting;
        let nText = document.getElementById("newActivityNoteText").value;
        m.meetingNotes = nText;
        if (nText && m.orderId !== 999999999) {
            this.addNewNote(nText);
        } else {
            this.completeMeeting();
        }
    }

    replaceStatus(status) {
        switch (status) {
            case "In Process":
                return "InProcess";
            case "Ready to Deliver":
                return "ReadyToDeliver";
            case "New":
            case "Setup":
            case "Cancelled":
            default:
                return status;
        }
    }

    completeMeeting() {
        const { allUsers, currentUser } = this.context;

        let m = this.state.selectedMeeting,
            c = Object.assign({}, m);

        //this will get the liaison;
        let salesRep = allUsers.find((l) => {
            return l.firstname + " " + l.lastname === m.salesRep;
        });
        let salesLiaison = allUsers.find((l) => {
            return l.firstname + " " + l.lastname === m.salesLiaison;
        });

        if (!this.state.closedReason) {
            this.setState({isLoaded: true});
            return toast.warning("You must select a meeting Reason");
        }

        c.salesLiaisonId = currentUser.id;
        delete c.salesLiaisonId;
        delete c.salesRepId;

        if (this.state.orderId === 999999999) {
            c.salesRep = salesLiaison;
            c.salesLiaison = salesLiaison;
        }
        else {
            c.salesRep = salesRep;
            c.salesLiaison = salesLiaison;
        }
        // else {
        // 	c.salesRep = salesRep ?  salesRep.username, id: parseInt(salesRep.id) } : c.salesLiaison;
        // 	// c.salesRepId = salesRep ? parseInt(salesRep.id) : c.salesLiaisonId;
        // }
        delete c.salesLiaisonId;
        delete c.salesRepId;



        c.closedOn = new Date();
        c.complete = true;
        c.scheduledDate = new Date(m.scheduledDate);
        c.status = this.state.statuses.indexOf(m.status);
        c.meetingReason = this.state.closedReason;

        LiaisonMeetingService.updateMeeting(c)
            .then((r) => {
                this.setState({
                    modalOpen: false,
                    existingMeeting: null,
                    closedReason: "",
                });
                toast.success("Meeting Completed");
                this.getResults();
            })
            .catch((e) => {
                toast.error(
                    "There was an error completing the meeting. Try again later."
                );

                this.setState({
                    isLoaded: true,
                    closedReason: "",
                });
            });
    }

    addNewNote(noteText) {
        //get the current user context that can be passed into the note DTO...BC
        const { currentUser } = this.context;
        let cb = { id: currentUser.id, name: currentUser.username },
            t = this;

        let note = {
            task: false,
            completed: true,
            assignedTo: null,
            createdAt: globalFunctions.getUTCMoment(),
            createdBy: cb,
            createdById: cb.id,
            account: this.state.account,
            patientName: null,
        };

        note.text = noteText;

        return NoteService.createActivityNote(this.state.orderId, note)
            .then((res) => {
                t.setState({
                    modalOpen: false,
                    orderId: "",
                    account: null,
                    isLoaded: false,
                });
                t.completeMeeting();
            })
            .catch((err) => {
                //Maybe send an error to the user?...BC
                console.log(err);
            });
    }

    setLiaisonClosedReason(r) {
        if (r.length === 0) {
            return;
        }

        let v = r[0];

        this.setState({
            closedReason: v,
        });
    }

    renderMeetingModal() {
        let st = this.state,
            rOptions = this.state.rOptions;

        return (
            <Modal
                centered
                size="medium"
                isOpen={st.modalOpen}
                toggle={() => {
                    return;
                }}
            >
                <MDBCardHeader
                    className={"form-header text-center"}
                    style={{
                        backgroundColor: "#1e88e5",
                        margin: "-40px 30px 10px 30px",
                        fontSize: 24,
                    }}
                >
					Complete Meeting
                </MDBCardHeader>
                <ModalBody>
                    <textarea
                        placeholder={"Add meeting notes here"}
                        id={"newActivityNoteText"}
                        style={{ width: "100%", height: "200px" }}
                    />

                    <MDBSelect
                        outline
                        color="default"
                        style={{ maxHeight: "100px", marginTop: "1px" }}
                        options={rOptions}
                        label={"Select Meeting Reason"}
                        getValue={this.setLiaisonClosedReason.bind(this)}
                    />
                </ModalBody>

                <ModalFooter>
                    <span
                        className={"ptBtn cancelBtn"}
                        data-tip={"Cancel"}
                        onClick={() => this.setState({ modalOpen: false })}
                    >
                        <Fa size="2x" icon={"times"}>
                            {" "}
                        </Fa>
                    </span>
                    <span
                        className={"ptBtn saveBtn"}
                        data-tip={"Close Meeting"}
                        onClick={() => this.addNoteOrClose()}
                    >
                        <Fa size="2x" icon={"check"}>
                            {" "}
                        </Fa>
                    </span>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    createBlockMeetings() {
        const { currentUser } = this.context;

        let st = this.state,
            startDate = new Date(st.startDate),
            endDate = new Date(st.endDate),
            t = this;

        let cu = {
            name: currentUser.username,
            id: currentUser.id,
        };

        if (st.chosenLiaison) {
            cu = { id: st.chosenLiaison };
        }

        if (endDate < startDate) {
            toast.error("End date must be after start date");
            return;
        }

        // startDate.setMinutes(0);
        startDate.setSeconds(0);

        // endDate.setMinutes(0);
        endDate.setSeconds(0);

        let obj = {
            orderId: 999999999,
            status: 4,
            reason: "This is unavailable time",
            salesRepId: cu.id,
            salesLiaisonId: cu.id,
            scheduledDate: startDate,
            complete: false,
            closedOn: null,
            meetingNotes: "",
        };
        console.log(obj);
        createMeeting(startDate, endDate, obj);

        function createMeeting(startTime, endTime, obj) {
            if (startTime <= endTime) {
                LiaisonMeetingService.createMeeting(obj)
                    .then((r) => {
                        console.log(r);
                        t.getResults();
                    })
                    .catch((e) => {
                        console.log(e);
                        toast.error(
                            "There was an issue creating the meeting. Try again later."
                        );
                    });

                let sendObj = JSON.parse(JSON.stringify(obj)),
                    nt = new Date(startTime);

                if (nt.getHours() < 16) {
                    //set the next hour;
                    nt.setMinutes(nt.getMinutes() + 30);
                } else {
                    //set the next day at 9 am
                    nt.setDate(nt.getDate() + 1);
                    nt.setHours(9);
                }

                sendObj.scheduledDate = nt;

                createMeeting(nt, endTime, sendObj);
            } else {
                setTimeout(() => {
                    t.getResults();
                }, 500);
            }
        }

        this.setState({
            blockOutModalOpen: false,
        });
    }

    getLiaisonList() {
        const { allLiaisons } = this.context;
        let ls = [];

        allLiaisons.map((l) => {
            return ls.push({
                text: l.firstname + " " + l.lastname,
                value: l.id,
            });
        });

        this.setState({
            allLiaisons: ls,
        });
    }

    getSalesRepsList() {
        const { allUsers } = this.context;
        let ls = [];

        allUsers.map((l) => {
            return ls.push({
                text: l.firstname + " " + l.lastname,
                value: l.id,
            });
        });

        this.setState({
            allReps: ls,
        });
    }

    chooseLiaison(e) {
        this.setState({ chosenLiaison: e[0] });
    }

    renderLiaisonList() {
        const { currentUser } = this.context;

        let r = currentUser.role;

        if (
            r === "SALES_LIAISON_MANAGER" ||
			r === "SALES_LIAISON" ||
			r === "ADMIN"
        ) {
            return (
                <MDBSelect
                    outline
                    color={"info"}
                    selected={"Choose Liaison"}
                    options={this.state.allLiaisons}
                    getValue={this.chooseLiaison.bind(this)}
                ></MDBSelect>
            );
        } else {
            return <div />;
        }
    }

    renderBlockOutModal() {
        let st = this.state;

        return (
            <Modal
                centered
                size="md"
                isOpen={st.blockOutModalOpen}
                toggle={() => {
                    return;
                }}
            >
                <ModalBody style={{ padding: 10 }}>
                    <MDBCardHeader
                        className={"form-header text-center"}
                        style={{
                            backgroundColor: "#1e88e5",
                            margin: "-50px 30px 10px 30px",
                        }}
                    >
                        <MDBRow style={{ justifyContent: "center" }}>
                            <MDBCol size="12">
                                <span style={{ fontSize: 20 }}>Set Unavailable Times</span>
                            </MDBCol>
                            <span style={{ fontSize: 14 }}> (end time is included)</span>
                        </MDBRow>
                    </MDBCardHeader>

                    {this.renderLiaisonList()}

                    <MDBRow style={{ justifyContent: "space-around" }}>
                        <MDBCol size="6">
                            <MUIDateTimePicker
                                label="Start Date & Time"
                                value={this.state.startDate ? moment(this.state.startDate) : null}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        size: "small",
                                        variant: 'outlined',
                                        InputLabelProps: {
                                            shrink: true
                                        },
                                    },
                                    layout: {
                                        sx: {
                                            ul: {
                                                '::-webkit-scrollbar': {
                                                    width: '2px',
                                                },
                                                '::-webkit-scrollbar-thumb:hover': {
                                                    backgroundColor: MUIGreyColor[500],
                                                },
                                                '::-webkit-scrollbar-thumb': {
                                                    backgroundColor: MUIGreyColor[500],
                                                },
                                                '::-webkit-scrollbar-track': {
                                                    backgroundColor: MUIGreyColor[300],
                                                }
                                            }
                                        }
                                    }
                                }}
                                onChange={(value) => { this.setState({ startDate: value }); }}
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                            />
                        </MDBCol>

                        <MDBCol size="6">
                            <MUIDateTimePicker
                                label="End Date & Time"
                                value={this.state.endDate ? moment(this.state.endDate) : null}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        size: "small",
                                        variant: 'outlined',
                                        InputLabelProps: {
                                            shrink: true
                                        },
                                    },
                                    layout: {
                                        sx: {
                                            ul: {
                                                '::-webkit-scrollbar': {
                                                    width: '2px',
                                                },
                                                '::-webkit-scrollbar-thumb:hover': {
                                                    backgroundColor: MUIGreyColor[500],
                                                },
                                                '::-webkit-scrollbar-thumb': {
                                                    backgroundColor: MUIGreyColor[500],
                                                },
                                                '::-webkit-scrollbar-track': {
                                                    backgroundColor: MUIGreyColor[300],
                                                }
                                            }
                                        }
                                    }
                                }}
                                onChange={(value) => { this.setState({ endDate: value }); }}
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                            />
                        </MDBCol>
                    </MDBRow>
                </ModalBody>

                <ModalFooter>
                    <span
                        className={"ptBtn cancelBtn"}
                        data-tip={"Cancel"}
                        onClick={() => this.setState({ blockOutModalOpen: false })}
                    >
                        <Fa size="2x" icon={"times"}>
                            {" "}
                        </Fa>
                    </span>
                    <span
                        className={"ptBtn saveBtn"}
                        data-tip={"Block out times"}
                        onClick={() => this.createBlockMeetings()}
                    >
                        <Fa size="2x" icon={"check"}>
                            {" "}
                        </Fa>
                    </span>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderBatchMoveModal() {
        let st = this.state;

        return (
            <MDBModal
                size="sm"
                centered
                isOpen={st.moveModalOpen}
                toggle={() => {
                    this.setState({ moveModalOpen: false });
                }}
            >
                <MDBCardHeader
                    className={"form-header text-center"}
                    style={{
                        backgroundColor: "#1e88e5",
                        margin: "-40px 30px 10px 30px",
                        fontSize: 24,
                    }}
                >
					Move Meetings
                </MDBCardHeader>
                <ModalBody style={{ padding: 20 }}>
                    <MDBRow style={{ justifyContent: "center" }}>
                        <MDBCol size="12">{this.renderLiaisonList()}</MDBCol>

                        <MDBCol size="6">
                            <MDBBtn onClick={() => this.batchMove()}>Move</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
                <ReactTooltip />
            </MDBModal>
        );
    }

    renderBatchCancelModal() {
        return (
            <MDBModal
                centered
                size="md"
                isOpen={this.state.cancelModalOpen}
                toggle={() => this.setState({ cancelModalOpen: false })}
            >
                <MDBCardHeader
                    className={"form-header text-center"}
                    style={{ backgroundColor: "#1e88e5", margin: "-40px 30px 10px 30px" }}
                >
					Are You Sure You Want to Cancel the Selected Meetings?
                </MDBCardHeader>
                <MDBModalBody>
                    <MDBRow style={{ justifyContent: "space-around" }}>
                        <MDBCol size="6">
                            <MDBBtn onClick={() => this.batchCancel()} color={"orange"}>
								Cancel Meetings
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        );
    }

    openBatchModal(type) {
        if (this.state.selectedRows.length > 0) {
            if (type === "move") {
                this.setState({
                    moveModalOpen: true,
                });
            } else if (type === "cancel") {
                this.setState({
                    cancelModalOpen: true,
                });
            }
        } else {
            toast.error("Please select some rows");
            return;
        }
    }

    async batchCancel() {
        const {allUsers} = this.context;

        let st = this.state,
            selected = st.selectedRows,
            all = st.data,
            brokeEarly = false;


        for (const r of selected) {
            //this should get the selected rows by index...BC
            let row = all.rows[r];
            let sr = null,
                sl = null;

            if(!allUsers || allUsers.length === 0){
                //get the object for the rep
                // let sr = allUsers.find((v) => {
                sr = this.state.allReps.find((v) => {
                    return v.text === row.salesRep;
                    // return v.firstname + " " + v.lastname === row.salesRep;
                });
                sr = sr.value;

                // let sl = allUsers.find((q) => {
                sl = this.state.allLiaisons.find((q) => {
                    return q.text === row.salesLiaison;
                    // return q.firstname + " " + q.lastname  === row.salesLiaison;
                });
                sl = sl.value;
            }
            else{
                //get the object for the rep
                sr = allUsers.find((v) => {
                    return v.firstname + " " + v.lastname === row.salesRep;
                });

                sl = allUsers.find((q) => {
                    return q.firstname + " " + q.lastname  === row.salesLiaison;
                });
            }

            if (row.salesRep === row.salesLiaison){
                sr = sl;
            }
            console.log(row);
            if ((!sr || !sl) && row.salesRep !== row.salesLiaison) {
                toast.error("Unable to find sales rep. Please try again.");
                brokeEarly = true;
                break;
            }

            let m = {
                id: row.id,
                orderId: parseInt(row.orderId),
                status: this.state.statuses.indexOf(row.status),
                reason: row.reason,
                salesRep: sr,
                salesLiaison: sl,
                scheduledDate: new Date(row.scheduledDate),
                meetingNotes: "",
                closedOn: new Date(),
                complete: true,
            };

            await LiaisonMeetingService.updateMeeting(m)
                .then((r) => {
                    // putting a console.log here because it didn't do anything else with r at the moment
                    console.log(r);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (brokeEarly === true) {
            return;
        }

        this.setState({
            isLoaded: false,
            cancelModalOpen: false,
            selectedRows: [],
        });

        this.getResults();
    }

    async batchMove() {
        const {allUsers} = this.context;

        let st = this.state,
            selected = st.selectedRows,
            all = st.data,
            brokeEarly = false;

        if (!this.state.chosenLiaison) {
            toast.error("You must choose a liaison to move the meetings to.");
            return;
        }
        const chosenLiaisonObj = this.state.allLiaisons.find(
            (l) => l.value === this.state.chosenLiaison
        );
        const chosenLiaisonName = chosenLiaisonObj?.text;

        if (!chosenLiaisonName) {
            toast.error("Chosen liaison not found.");
            return;
        }

        for (const r of selected) {
            //this should get the selected rows by index...BC
            let row = all.rows[r];
            let scheduledDate = new Date(row.scheduledDate);

            let sr = allUsers.find((v) => {
                return v.firstname + " " + v.lastname === row.salesRep;
            });

            if (!sr) {
                toast.error("Unable to find sales rep.  Please try again.");
                brokeEarly = true;
                break;
            }
            const conflict = all.rows.some((meeting, i) => {
                if (i === r) return false; // skip the current row being moved
                return (
                    meeting.salesLiaison === chosenLiaisonName &&
                    new Date(meeting.scheduledDate).getTime() === scheduledDate.getTime()
                );
            });
            if (conflict) {
                toast.error(
                    `Liaison "${chosenLiaisonName}" already has a meeting at ${scheduledDate.toLocaleString()}.`
                );
                brokeEarly = true;
                break;
            }

            let m = {
                id: row.id,
                orderId: parseInt(row.orderId),
                status: this.state.statuses.indexOf(row.status),
                reason: row.reason,
                salesRepId: sr.id,
                salesLiaisonId: this.state.chosenLiaison,
                scheduledDate: new Date(row.scheduledDate),
                meetingNotes: "",
                meetingReason: row.meetingReason,
                closedOn: null,
                complete: false,
            };

            await LiaisonMeetingService.updateMeeting(m)
                .then((r) => {
                    console.log(r);
                    this.getResults();
                })
                .catch((e) => {
                    console.log(e);
                    this.getResults();
                });
        }

        if (brokeEarly === true) {
            return;
        }

        this.setState({
            isLoaded: false,
            moveModalOpen: false,
            selectedRows: [],
        });

        this.getResults();
    }

    async move() {}

    changeSelection(e) {
        this.setState({
            selectedRows: e,
        });
    }

    renderTable() {
        const integratedSortingColumnExtensions = [
            { columnName: "scheduledDate", compare: compareDate },
        ];

        let st = this.state;
        return (
            <Grid
                rootComponent={this.Root}
                rows={st.data.rows}
                columns={st.data.columns}
            >
                <PagingState defaultCurrentPage={0} pageSize={50} />
                <DragDropProvider />
                <SortingState
                    defaultSorting={[{ columnName: "scheduledDate", direction: "asc" }]}
                />
                <IntegratedSorting
                    columnExtensions={integratedSortingColumnExtensions}
                />
                <GroupingState
                    grouping={st.groupingColumns}
                    onGroupingChange={this.changeGrouping}
                />
                <IntegratedGrouping />
                <IntegratedPaging />
                <VirtualTable cellComponent={this.CellContent} height={"auto"} />
                <PagingPanel />
                <TableHeaderRow showSortingControls />
                <SelectionState
                    selection={this.state.selectedRows}
                    onSelectionChange={this.changeSelection.bind(this)}
                />
                <TableSelection />
                <TableGroupRow
                    contentComponent={this.Content}
                    showColumnsWhenGrouped={true}
                />

                {this.ItemCounter()}
                <Toolbar />
                <GroupingPanel />
            </Grid>
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div
                className={"purchasingGrid"}
                style={{ height: "80vh", backgroundColor: "#FFF" }}
            >
                {this.renderTable()}
            </div>
        );
    }

    renderContent() {
        const { currentUser } = this.context;

        let role = currentUser.role,
            shouldDisable = true;

        if (
			role === "ADMIN" ||
			role === "SALES_LIAISON_MANAGER" ||
			role === "SALES_LIAISON"
        ) {
            shouldDisable = false;
        }

        return (
            <div>
                {this.renderMeetingModal()}
                {this.renderBlockOutModal()}
                {this.renderBatchMoveModal()}
                {this.renderBatchCancelModal()}
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{ marginTop: "75px" }}
                />

                <div style={{ maxWidth: "98%", marginLeft: "1rem" }}>
                    <MDBCol
                        size="2"
                        style={{
                            position: "absolute",
                            width: "100%",
                            zIndex: 2,
                            right: 10,
                        }}
                    >
                        <Button
                            floating
                            size="sm"
                            color={"secondary"}
                            data-place={"right"}
                            data-tip={"Block Out Times"}
                            onClick={() => this.setState({ blockOutModalOpen: true })}
                        >
                            <MDBIcon far icon="calendar-times" style={{ fontSize: "2em" }} />
                        </Button>

                        <Button
                            floating
                            size="sm"
                            color={"yellow"}
                            disabled={shouldDisable}
                            data-tip={"Move Meetings"}
                            onClick={() => this.openBatchModal("move")}
                        >
                            <MDBIcon fas icon="exchange-alt" style={{ fontSize: "2em" }} />
                        </Button>

                        <Button
                            floating
                            size="sm"
                            color={"orange"}
                            disabled={shouldDisable}
                            data-tip={"Cancel Meetings"}
                            onClick={() => this.openBatchModal("cancel")}
                        >
                            <MDBIcon fas icon="ban" style={{ fontSize: "2em" }} />
                        </Button>
                    </MDBCol>

                    <Row>
                        <Col size="12">{this.renderTableOrSpinner()}</Col>
                    </Row>

                    <Link
                        id="orderListId"
                        to={{
                            pathname: `/order/${this.state.targetLink}`,
                            state: { goto: "liaison" },
                        }}
                        target="_blank"
                        rel="opener"
                        style={{ display: "none" }}
                        activeclassname="active"
                    />

                    <div className={"fixed-bottom downloadCsvDiv"}>
                        <Button
                            className={"downloadCsvButton"}
                            floating
                            size="sm"
                            color={"primary"}
                            data-tip={"Download CSV"}
                            onClick={this.clientCSV.bind(
                                this,
                                this.state.data,
                                "LiaisonMeetings.csv"
                            )}
                        >
                            <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                        </Button>

                        <ReactTooltip />
                    </div>
                </div>
            </div>
        );
    }
}
