import React from "react";
import moment from "moment";
import Dialog from "../Dialog";
import MUITextField from "@mui/material/TextField";
import MUIFormLabel from "@mui/material/FormLabel";
import MUIFormControl from "@mui/material/FormControl";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
import MUIRadio from "@mui/material/Radio";
import MUIRadioGroup from "@mui/material/RadioGroup";
import MUIDialogActions from "@mui/material/DialogActions";
import MUITypography from "@mui/material/Typography";
import MUIButton from "@mui/material/Button";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUIBox from "@mui/material/Box";
import MUIList from "@mui/material/List";
import MUIListItem from "@mui/material/ListItem";
import MUIListItemText from "@mui/material/ListItemText";
import MUIPersonIcon from '@mui/icons-material/Person';
import MUIGrid from '@mui/material/Grid';
import MUITooltip from "@mui/material/Tooltip";

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';

export default class DialogIntakeQuestionnaire extends Dialog {
    constructor(props) {
        super(props);

        this.state.intakeQuestionnaire = this.props.intakeQuestionnaire;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                intakeQuestionnaire: {
                    meta: {
                        version: moment("2024-10-27") || null,
                    },
                }
            })
        }

        if (this.props.readOnly && JSON.stringify(prevProps.intakeQuestionnaire) !== JSON.stringify(this.props.intakeQuestionnaire)) {
            this.setState({
                intakeQuestionnaire: this.props.intakeQuestionnaire,
            })
        } 
    }

    /**
    * Render header title of dialog
    */
    renderHeader() {
        return (
            <>
                <MUITypography variant="h6" color="textPrimary">
                    {this.props.header}
                </MUITypography>
                <MUIBox sx={{ marginTop: 2 }}>{this.renderPatientInformation()}</MUIBox>
            </>
        );
    }

    /**
     * Render main content of dialog
     */
    renderContent() {
        return (
            <MUIBox>{this.renderBody()}</MUIBox>
        );
    }

    /**
     * Render patient contact and insurance information on top of the dialog.
     */
    renderPatientInformation() {
        const { row } = this.props;

        if (!row) return null;

        return (
            <MUIGrid container spacing={1}>
                {this.renderPatientInfoSection(row["order_activity.patient._name"], row["order_activity.patient.phone"])}
                {row["order_activity.insurance_type_id"] && this.renderInsuranceSection(row["order_activity.insurance_type_id"], row["order_activity.insurance_sub_type_id"], row["order_activity.insurance_policy"], row["order_activity.insurance_group"])}
                {row["order_activity.secondary_insurance_type_id"] && this.renderInsuranceSection(row["order_activity.secondary_insurance_type_id"], row["order_activity.secondary_insurance_sub_type_id"], row["order_activity.secondary_insurance_policy"], row["order_activity.secondary_insurance_group"])}
            </MUIGrid>
        );
    }

    /**
    * Render patient name and phone number with person icon
    */
    renderPatientInfoSection(name, phone) {
        return (
            <MUIGrid item lg={4} md={4} sm={4} xs={12}>
                <MUIBox sx={{ display: "flex", alignItems: "center" }}>
                    <MUIPersonIcon sx={{ fontSize: 40 }} />
                    <MUIBox sx={{ display: 'flex', flexDirection: 'column' }}>
                        <MUITypography variant="subtitle2">{name}</MUITypography>
                        <MUITypography variant="caption">{phone ? phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3').padStart(14, phone) : null}</MUITypography>
                    </MUIBox>
                </MUIBox>
            </MUIGrid>
        );
    }

    /**
    * Render patient insurance with policy and group #
    */
    renderInsuranceSection(type, subtype, policy, group) {
        const insuranceDetails = [type];

        if (subtype) {
            insuranceDetails.push(subtype);
        }

        const insuranceText = insuranceDetails.join(" • ");
        const insurancePolicy = policy ? policy : <MUITypography variant="caption" sx={{ color: 'text.disabled' }}>No Policy #</MUITypography>;
        const insuranceGroup = group ? group : <MUITypography variant="caption" sx={{ color: 'text.disabled' }}>No Group #</MUITypography>;

        return (
            <MUIGrid item lg={4} md={4} sm={4} xs={12}>
                <MUIBox sx={{ display: 'flex', flexDirection: 'column' }}>
                    <MUITooltip title={insuranceText} placement="top">
                        <MUITypography variant="subtitle2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{insuranceText}</MUITypography>
                    </MUITooltip>
                    <MUITypography variant="caption">
                        {insurancePolicy} • {insuranceGroup}
                    </MUITypography>
                </MUIBox>
            </MUIGrid>
        );
    }

    handleChange = (updatedIntakeQuestionnaire) => {
        this.setState({
            intakeQuestionnaire: {
                ...this.state.intakeQuestionnaire,
                ...updatedIntakeQuestionnaire
            },
        });
    }

    renderHomeHealth() {
        return (
            <MUIBox>
                <MUITypography variant="body1" sx={{ marginBottom: 2 }}>
                    Does the patient have Home Health?
                </MUITypography>
                <MUIGrid container spacing={1} alignItems="center">
                    <MUIGrid item lg={3} xs={12}>
                        <MUIFormControl component="fieldset" required error={this.props.validationIssues.homeHealth ? true : false}>
                            <MUIFormLabel sx={{ typography: "caption" }}>Select One</MUIFormLabel>
                            <MUIRadioGroup row value={this.state.intakeQuestionnaire.homeHealth !== undefined ? this.state.intakeQuestionnaire.homeHealth : null} onChange={(e) => this.handleChange({ homeHealth: e.target.value === 'true' })}>
                                <MUIFormControlLabel
                                    disabled={this.props.readOnly}
                                    value={true}
                                    control={<MUIRadio size="small" />}
                                    label="Yes"
                                />
                                <MUIFormControlLabel
                                    disabled={this.props.readOnly}
                                    value={false}
                                    control={<MUIRadio size="small" />}
                                    label="No"
                                />
                            </MUIRadioGroup>
                        </MUIFormControl>
                    </MUIGrid>
                    <MUIGrid item lg={9} xs={12}>
                        <MUITextField
                            disabled={this.props.readOnly}
                            value={this.state.intakeQuestionnaire ? this.state.intakeQuestionnaire.homeHealthComments : ""}
                            multiline
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { resize: "both" } }}
                            label={"Comments (optional)"}
                            onChange={(e) => this.handleChange({ homeHealthComments: e.target.value })}
                            variant="standard"
                        />
                    </MUIGrid>
                </MUIGrid>
            </MUIBox>
        );
    }

    renderConfirmedInsurance() {
        return (
            <MUIBox>
                <MUITypography variant="body1" sx={{ marginBottom: 2 }}>
                    Has the patient's insurance been confirmed?
                </MUITypography>
                <MUIGrid container spacing={1} alignItems="center">
                    <MUIGrid item lg={3} xs={12}>
                        <MUIFormControl component="fieldset" required error={this.props.validationIssues.confirmedInsurance ? true : false}>
                            <MUIFormLabel sx={{ typography: "caption" }}>Select One</MUIFormLabel>
                            <MUIRadioGroup row value={this.state.intakeQuestionnaire.confirmedInsurance !== undefined ? this.state.intakeQuestionnaire.confirmedInsurance : null} onChange={(e) => this.handleChange({ confirmedInsurance: e.target.value === 'true' })}>
                                <MUIFormControlLabel
                                    disabled={this.props.readOnly}
                                    value={true}
                                    control={<MUIRadio size="small" />}
                                    label="Yes"
                                />
                                <MUIFormControlLabel
                                    disabled={this.props.readOnly}
                                    value={false}
                                    control={<MUIRadio size="small" />}
                                    label="No"
                                />
                            </MUIRadioGroup>
                        </MUIFormControl>
                    </MUIGrid>
                    <MUIGrid item lg={9} xs={12}>
                        <MUITextField
                            disabled={this.props.readOnly}
                            value={this.state.intakeQuestionnaire ? this.state.intakeQuestionnaire.confirmedInsuranceComments : ""}
                            multiline
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { resize: "both" } }}
                            label={"Comments (optional)"}
                            onChange={(e) => this.handleChange({ confirmedInsuranceComments: e.target.value })}
                            variant="standard"
                        />
                    </MUIGrid>
                </MUIGrid>
            </MUIBox>
        );
    }

    renderPlanToChangeInsurance() {
        return (
            <MUIBox>
                <MUITypography variant="body1" sx={{ marginBottom: 2 }}>
                    Does the patient plan on keeping the same insurance during this process?
                </MUITypography>
                <MUIGrid container spacing={1} alignItems="center">
                    <MUIGrid item lg={3} xs={12}>
                        <MUIFormControl component="fieldset" required error={this.props.validationIssues.planToChangeInsurance ? true : false}>
                            <MUIFormLabel sx={{ typography: "caption" }}>Select One</MUIFormLabel>
                            <MUIRadioGroup row value={this.state.intakeQuestionnaire.planToChangeInsurance !== undefined ? this.state.intakeQuestionnaire.planToChangeInsurance : null} onChange={(e) => this.handleChange({ planToChangeInsurance: e.target.value === 'true' })}>
                                <MUIFormControlLabel
                                    disabled={this.props.readOnly}
                                    value={true}
                                    control={<MUIRadio size="small" />}
                                    label="Yes"
                                />
                                <MUIFormControlLabel
                                    disabled={this.props.readOnly}
                                    value={false}
                                    control={<MUIRadio size="small" />}
                                    label="No"
                                />
                            </MUIRadioGroup>
                        </MUIFormControl>
                    </MUIGrid>
                    <MUIGrid item lg={9} xs={12}>
                        <MUITextField
                            disabled={this.props.readOnly}
                            value={this.state.intakeQuestionnaire ? this.state.intakeQuestionnaire.planToChangeInsuranceComments : ""}
                            multiline
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { resize: "both" } }}
                            label={"Comments (optional)"}
                            onChange={(e) => this.handleChange({ planToChangeInsuranceComments: e.target.value })}
                            variant="standard"
                        />
                    </MUIGrid>
                </MUIGrid>
            </MUIBox>
        );
    }

    renderPhysicianDate() {
        return (
            <MUIBox>
                <MUITypography variant="body1" sx={{ marginBottom: 2 }}>
                    When is the date of the patient's last physician visit?
                </MUITypography>
                <MUIDatePicker
                    disabled={this.props.readOnly}
                    value={
                        this.state.intakeQuestionnaire && this.state.intakeQuestionnaire.lastPhysicianVisitDate
                            ? moment(this.state.intakeQuestionnaire.lastPhysicianVisitDate)
                            : null
                    }
                    label="Select Date"
                    slotProps={{
                        textField: {
                            size: "small",
                            variant: 'standard',
                            required: true,
                            error: this.props.validationIssues.lastPhysicianVisitDate ? true : false,
                            helperText: this.props.validationIssues.lastPhysicianVisitDate ? this.props.validationIssues.lastPhysicianVisitDate : "",
                            InputLabelProps: {
                                shrink: true
                            },
                        },
                    }}
                    onChange={(value) => this.handleChange({ lastPhysicianVisitDate: value })}
                    views={['year', 'month', 'day']}
                />
            </MUIBox>
        );
    }

    renderPatientCommit() {
        return (
            <MUIBox>
                <MUITypography variant="body1" sx={{ marginBottom: 2 }}>
                    Does the patient commit to the process and to attending evaluations?
                </MUITypography>
                <MUIGrid container spacing={1} alignItems="center">
                    <MUIGrid item lg={3} xs={12}>
                        <MUIFormControl component="fieldset" required error={this.props.validationIssues.patientCommitToProcess ? true : false}>
                            <MUIFormLabel sx={{ typography: "caption" }}>Select One</MUIFormLabel>
                            <MUIRadioGroup row value={this.state.intakeQuestionnaire.patientCommitToProcess !== undefined ? this.state.intakeQuestionnaire.patientCommitToProcess : null} onChange={(e) => this.handleChange({ patientCommitToProcess: e.target.value === 'true' })}>
                                <MUIFormControlLabel
                                    disabled={this.props.readOnly}
                                    value={true}
                                    control={<MUIRadio size="small" />}
                                    label="Yes"
                                />
                                <MUIFormControlLabel
                                    disabled={this.props.readOnly}
                                    value={false}
                                    control={<MUIRadio size="small" />}
                                    label="No"
                                />
                            </MUIRadioGroup>
                        </MUIFormControl>
                    </MUIGrid>
                    <MUIGrid item lg={9} xs={12}>
                        <MUITextField
                            disabled={this.props.readOnly}
                            value={this.state.intakeQuestionnaire ? this.state.intakeQuestionnaire.patientCommitToProcessComments : ""}
                            multiline
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { resize: "both" } }}
                            label={"Comments (optional)"}
                            onChange={(e) => this.handleChange({ patientCommitToProcessComments: e.target.value })}
                            variant="standard"
                        />
                    </MUIGrid>
                </MUIGrid>
            </MUIBox >
        );
    }

    renderReasonForEquipment() {
        const handleBlur = (e) => {
            if (this.props.intakeQuestionnaire.reasonForEquipment) {
                this.handleChange({ reasonForEquipment: e.target.value.trim() });
            }
        };
        return (
            <MUIBox>
                <MUITypography variant="body1" sx={{ marginBottom: 2 }}>
                    What is the reason for the equipment?
                </MUITypography>
                <MUITextField
                    disabled={this.props.readOnly}
                    value={this.state.intakeQuestionnaire ? this.state.intakeQuestionnaire.reasonForEquipment : ""}
                    multiline
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { resize: "both" } }}
                    label={"Comments"}
                    onChange={(e) => this.handleChange({ reasonForEquipment: e.target.value })}
                    onBlur={handleBlur}
                    variant="standard"
                    required={true}
                    error={this.props.validationIssues.reasonForEquipment ? true : false}
                    helperText={this.props.validationIssues ? this.props.validationIssues.reasonForEquipment : ""}
                />
            </MUIBox>
        );
    }

    /**
     * Render body of Intake Questionaire dialog.
     */
    renderBody() {
        return (
            <MUIBox>
                <MUIList sx={{ listStyle: "decimal", pl: 3 }}>
                    <MUIListItem sx={{ display: "list-item", pl: 0 }}>
                        <MUIListItemText primary={this.renderHomeHealth()} />
                    </MUIListItem>
                    <MUIListItem sx={{ display: "list-item", pl: 0 }}>
                        <MUIListItemText primary={this.renderConfirmedInsurance()} />
                    </MUIListItem>
                    <MUIListItem sx={{ display: "list-item", pl: 0 }}>
                        <MUIListItemText primary={this.renderPlanToChangeInsurance()} />
                    </MUIListItem>
                    <MUIListItem sx={{ display: "list-item", pl: 0 }}>
                        <MUIListItemText primary={this.renderPhysicianDate()} />
                    </MUIListItem>
                    <MUIListItem sx={{ display: "list-item", pl: 0 }}>
                        <MUIListItemText primary={this.renderPatientCommit()} />
                    </MUIListItem>
                    <MUIListItem sx={{ display: "list-item", pl: 0 }}>
                        <MUIListItemText primary={this.renderReasonForEquipment()} />
                    </MUIListItem>
                </MUIList>
            </MUIBox>
        );
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    {!this.props.rowDataLoading && !this.props.readOnly ? `Cancel` : `Close`}
                </MUIButton>
                {!this.props.rowDataLoading && !this.props.readOnly ?
                    (<MUILoadingButton variant="contained" loading={this.props.loading} onClick={() => this.props.onConfirm(this.state.intakeQuestionnaire)}>
                        Submit
                    </MUILoadingButton>)
                    : null}
            </MUIDialogActions>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "md";
    }
}
