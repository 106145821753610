import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import TileUser from "../../../Tile/TileUser";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import DialogSearchUser from "../../../Dialog/Search/DialogSearchUser";
export default class CardAdminTeamManagement extends Card {
    static contextTypes = {
        usersIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state.managerDialogOpen = false;
    }

    /**
     * Renders the content of the card.
     */
    renderContent() {
        let managerUser = null;
        if (this.props.team && this.props.team.managerId !== null) {
            managerUser = this.context.usersIndexed[this.props.team.managerId];
        }

        // Manager
        const handleEditManager = () => {
            this.setState({ managerDialogOpen: true });
        };
        const handleClearManager = () => {
            this.props.onChange({ managerId: null });
        };

        const managerIconButtons = [
            (<MUIIconButton onClick={ handleEditManager }><MUIEditIcon /></MUIIconButton>)
        ];

        if (managerUser) {
            managerIconButtons.push(
                (<MUIIconButton onClick={ handleClearManager }><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Render
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={ 12 } sm={ 6 } md={ 4 }>
                        <TileUser
                            user={managerUser}
                            iconButtons={ managerIconButtons }
                            label="Manager"
                            error={this.props.validationIssues.managerId ? true : false}
                            helperText={this.props.validationIssues.managerId ? this.props.validationIssues.managerId : ""}
                        />
                    </MUIGrid>
                </MUIGrid>
                <DialogSearchUser
                    open={ this.state.managerDialogOpen }
                    onClose={ () => this.setState({ managerDialogOpen: false }) }
                    onSelect={ (user) => {
                        this.props.onChange({ managerId: user.id });
                    } }
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Management";
    }
}