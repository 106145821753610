import Admin from "../Admin";
import React from "react";
import CardAdminQueueRouteGeneral from "../../../Component/Card/Admin/QueueRoute/CardAdminQueueRouteGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import QueueRouteService from "../../../Seating/Security/QueueRouteService/queueRouteService";
import Validator from "../../../Utility/Validator";
import CardAdminQueueRouteSimilar from "../../../Component/Card/Admin/QueueRoute/CardAdminQueueRouteSimilar";
import CardAdminQueueRouteDetail from "../../../Component/Card/Admin/QueueRoute/CardAdminQueueRouteDetail";
import CardAdminQueueRouteTrigger from "../../../Component/Card/Admin/QueueRoute/CardAdminQueueRouteTrigger";
import CardAdminQueueRouteServiceReasons from "../../../Component/Card/Admin/QueueRoute/CardAdminQueueRouteServiceReasons";
import CardAdminQueueRouteQueue from "../../../Component/Card/Admin/QueueRoute/CardAdminQueueRouteQueue";
import QueueRoute from "../../../Utility/Crud/QueueRoute";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminQueueRouteCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.queueRoute = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the queueRoute in the state.
     */
    afterComponentDidMount() {
        const queueRouteId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (queueRouteId !== null) {
            this.setState({
                queueRoute: this.context.queueRoutesIndexed[queueRouteId]
            });
        }
    }

    /**
     * Creates the queueRoute with the current properties in state.queueRoute.
     */
    async createQueueRoute() {
        this.setState({
            isProcessing: true,
        });

        return QueueRouteService.createQueueRoute(this.state.queueRoute)
            .then(queueRoute => {
                this.context.updateIndexed("queueRoutesIndexed", queueRoute);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Queue Route created successfully!", {variant: "success"});
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Queue Route", {variant: "error"});
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the queueRoute with the current properties in state.queueRoute.
     */
    async updateQueueRoute() {
        this.setState({
            isProcessing: true,
        });

        return QueueRouteService.updateQueueRoute(this.state.queueRoute)
            .then(queueRoute => {
                this.context.updateIndexed("queueRoutesIndexed", queueRoute);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Queue Route updated successfully!", {variant: "success"});
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Queue Route", {variant: "error"});
                this.setState({
                    isProcessing: false,
                });
            });
    }

    async deleteQueueRoute() {
        this.setState({
            isProcessing: true,
        });

        return QueueRouteService.deleteQueueRoute(this.state.queueRoute.id)
            .then(() => {
                this.context.deleteIndexed("queueRoutesIndexed", this.state.queueRoute.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Queue Route deleted successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Queue Route", {variant: "error"});
                this.setState({
                    confirmDialogOpen: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (queueRoute) => {
            // Special handling for the isInitialRoute flag. Throw away the
            // value when done.
            if (queueRoute.isInitialRoute !== undefined) {
                this.setState({ isInitialRoute: queueRoute.isInitialRoute });
                delete queueRoute.isInitialRoute;
            }
            if (queueRoute.hasTrigger !== undefined) {
                this.setState({ hasTrigger: queueRoute.hasTrigger });
                delete queueRoute.hasTrigger;
            }

            this.setState({
                queueRoute: {
                    ...this.state.queueRoute,
                    ...queueRoute
                },
            });
        };

        return (
            <>
                <CardAdminQueueRouteGeneral
                    queueRoute={this.state.queueRoute}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminQueueRouteDetail
                    queueRoute={this.state.queueRoute}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminQueueRouteServiceReasons
                    queueRoute={this.state.queueRoute}
                    onChange={handleChange.bind(this)}
                />
                <CardAdminQueueRouteTrigger
                    queueRoute={this.state.queueRoute}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminQueueRouteQueue
                    queueRoute={this.state.queueRoute}
                />
                <CardAdminQueueRouteSimilar
                    queueRoute={this.state.queueRoute}
                    onChange={handleChange.bind(this)}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteQueueRoute()}
                    text="Are you sure you want to delete this Queue Route? This cannot be undone."
                    header="Delete Queue Route"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * @returns Page header
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Queue Route';
            subtitle = null;
        } else {
            title = 'Edit Queue Route';
            subtitle = (this.state.queueRoute && (QueueRoute.getDisplayName(this.state.queueRoute) + " • " + this.state.queueRoute.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.queueRoute,
                {
                    name: {
                        'required': true
                    },
                    orderActivityTypeId: {
                        'required': true
                    },
                    fromOrderStatusReasonId: {
                        'required': !(this.state.isInitialRoute === true)
                    },
                    trigger: {
                        'required': (this.state.hasTrigger === true)
                    },
                    toOrderStatusReasonId: {
                        'required': true
                    },
                }
            );

            if (
                this.state.queueRoute.fromOrderStatusReasonId === this.state.queueRoute.toOrderStatusReasonId &&
                !!this.state.queueRoute.fromOrderStatusReasonId &&
                !!this.state.queueRoute.toOrderStatusReasonId
            ) {
                validationIssues.toOrderStatusReasonId = 'Cannot route to the same reason';
            }

            this.setState({
                validationIssues: validationIssues,
            });


            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateQueueRoute();
                } else {
                    this.createQueueRoute();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", {variant: "error"});
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
