import React from "react";
import DialogSearch from "../DialogSearch";
import TileAccount from "../../Tile/TileAccount";
import Account from "../../../Utility/Crud/Account";
import AccountService from "../../../Seating/Security/AccountService/accountService";

export default class DialogSearchAccount extends DialogSearch {
    /**
     * Searches for accounts by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered accounts.
     */
    getSearchResults(query) {
        return Account.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        }
    }

    /**
     * @param {object} account The account object.
     * @returns A TileAccount component for this account.
     */
    renderTile(account) {
        return (
            <TileAccount
                key={ account.id }
                account={ account }
            />
        );
    }
}
