import React from "react";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUIButton from "@mui/material/Button";
import MUIDialogActions from "@mui/material/DialogActions";
import MUITypography from "@mui/material/Typography";
import MUIGrid from "@mui/material/Grid";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
import MUIFormLabel from "@mui/material/FormLabel";
import MUIRadio from "@mui/material/Radio";
import MUITextField from "@mui/material/TextField";
import MUIMenuItem from "@mui/material/MenuItem";
import MUIBackspaceIcon from '@mui/icons-material/Backspace';
import MUITableContainer from "@mui/material/TableContainer";
import MUITable from "@mui/material/Table";
import MUITableHead from "@mui/material/TableHead";
import MUITableRow from "@mui/material/TableRow";
import MUITableCell from "@mui/material/TableCell";
import MUITableBody from "@mui/material/TableBody";
import MUITooltip from "@mui/material/Tooltip";
import MUIAutoComplete from "@mui/material/Autocomplete";

export default class DialogCreateNewOrder extends Dialog {
    static contextTypes = {
        orderActivityTypesIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        internalLocations: PropTypes.array,
        salesReps: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
    };

    renderSalesLocationDropdown() {
        const { internalLocations, salesReps } = this.context;

        let salesRep = this.props.accountRecentSalesRep || this.props.accountOwner;
        let internalLocationsArray = internalLocations,
            index = null;
        let rep = salesReps.find((r) => {
            return r.id === salesRep.id;
        });

        if (rep && rep.userprofileLocations && rep.userprofileLocations.length > 0) {
            internalLocationsArray = rep.userprofileLocations.map(x => x.locations);
        }
        if (this.props.salesLocation) {
            index = internalLocationsArray.find((loc) => {
                return loc.id === this.props.salesLocation.id;
            });
        }

        if (index && !this.props.salesLocation) {
            this.props.onChangeLocation(index, "salesLocation");
        }

        return (
            <MUIGrid item lg={6} md={12} sm={12}>
                <MUITextField
                    id="Sales-Location"
                    select
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Sales Location"
                    value={index ? index : ""}
                    onChange={(e) =>
                        this.props.onChangeLocation(e.target.value, "salesLocation")
                    }
                    variant="standard"
                    required={true}
                >
                    {internalLocationsArray.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </MUIGrid>
        );
    }

    renderServiceLocationDropdown() {
        const { internalLocations, salesReps } = this.context;
        let salesRep = this.props.accountRecentSalesRep || this.props.accountOwner;

        let ary = internalLocations,
            curr = this.props.serviceLocation,
            index = null;
        let rep = salesReps.find((r) => {
            return r.id === salesRep.id;
        });

        if (curr) {
            index = ary.find((il) => {
                return il.id === curr.id;
            });
        } else {
            if (rep && rep.userprofileLocations && rep.userprofileLocations.length > 0) {
                index = ary.find((il) => {
                    return il.id === rep.userprofileLocations[0].locationsId;
                });
            }
        }

        if (index && !this.props.serviceLocation) {
            this.props.onChangeLocation(index, "serviceLocation");
        }

        return (
            <MUIGrid item lg={6} md={12} sm={12}>
                <MUITextField
                    select
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Service Location"
                    value={index ? index : ""}
                    onChange={(e) =>
                        this.props.onChangeLocation(e.target.value, "serviceLocation")
                    }
                    variant="standard"
                    required={true}
                >
                    {ary.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </MUIGrid>
        );
    }

    renderOrderStatusDropdown() {
        let index = this.context.orderStatusesIndexed[this.props.orderStatus.id];

        return (
            <MUIGrid item lg={6} md={12} sm={12}>
                <MUITextField
                    select
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Order Status"
                    onChange={(e) => this.props.onOrderStatusChange(e.target.value)}
                    variant="standard"
                    value={index ? index : ""}
                    required={true}
                >
                    {Object.values(this.context.orderStatusesIndexed).map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </MUIGrid>
        );
    }

    renderOrderStatusReasonDropdown() {
        return (
            <MUIGrid item lg={6} md={12} sm={12}>
                <MUITextField
                    select
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Order Status Reason"
                    onChange={(e) => this.props.onUpdateReasonChange(e.target.value)}
                    variant="standard"
                    required={true}
                >
                    {Object.values(this.context.orderStatusReasonsIndexed).map((option, idx) => {
                        if (option.orderStatus === this.props.orderStatus.id) {
                            return (
                                <MUIMenuItem key={idx} value={option}>
                                    {option.description}
                                </MUIMenuItem>
                            );
                        }
                        return false;
                    })}
                </MUITextField>
            </MUIGrid>
        );
    }

    renderCategoryDropdown() {
        return (
            <MUIGrid item lg={6} md={12} sm={12}>
                <MUIAutoComplete
                    options={this.props.categoryOptions}
                    onChange={(e, value) => this.props.onCategoryChange(e, value)}
                    renderInput={(params) => <MUITextField {...params} InputLabelProps={{ shrink: true }} size="small" variant="standard" label="Category" />}
                />
            </MUIGrid>
        );
    }

    renderProductDropdown() {
        return (
            <MUIGrid item lg={6} md={12} sm={12}>
                <MUIAutoComplete
                    options={this.props.productOptions}
                    onChange={(e, value) => this.props.onProductChange(e, value)}
                    renderInput={(params) => <MUITextField {...params} InputLabelProps={{ shrink: true }} size="small" variant="standard" label="Product" />}
                />
            </MUIGrid>
        );
    }

    renderOrderProduct() {
        let op = this.props.orderActivityProducts ? this.props.orderActivityProducts.map(x => x.products) : [];

        if (op.length === 0) {
            return;
        }

        return (
            <MUITableContainer>
                <MUITable>
                    <MUITableHead>
                        <MUITableRow>
                            <MUITableCell>
                                Added Product
                            </MUITableCell>
                        </MUITableRow>
                    </MUITableHead>
                    <MUITableBody>
                        {op.map((p, idx) => {
                            return (
                                <MUITableRow key={p.productType.name}>
                                    <MUITableCell component="th" scope="row">
                                        <span>{p.productType.name}</span> &nbsp; &nbsp;
                                        <span>{p.productSubType.name}</span>
                                        <MUITooltip title="Remove">
                                            <MUIBackspaceIcon sx={{ cursor: "pointer", float: 'right' }}
                                                onClick={() => this.props.removeProduct(idx)}/>
                                        </MUITooltip>
                                    </MUITableCell>
                                </MUITableRow>
                            );
                        })}
                    </MUITableBody>
                </MUITable>
            </MUITableContainer>
        );
    }

    /**
     * Render header title of dialog
     */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                {this.props.header}
            </MUITypography>
        );
    }


    /**
     * Render main content text of dialog
     */
    renderContent() {
        const orderActivityTypes = Object.values(this.context.orderActivityTypesIndexed).filter(({ id }) => ![2, 3, 4, 5].includes(id));

        return (
            <>
                <MUIFormLabel required>Order Type</MUIFormLabel>
                <MUIGrid container spacing={2}>
                    {orderActivityTypes.map((o) => (
                        <MUIGrid key={o.id} item>
                            <MUIFormControlLabel
                                onChange={() => this.props.onRadioChange(o)}
                                checked={this.props.selectedOrderTypeRadio === o.id}
                                value={o.id}
                                control={<MUIRadio />}
                                label={o.name}
                            />
                        </MUIGrid>
                    ))}
                </MUIGrid>

                <MUIGrid container spacing={2} sx={{ padding: "1rem 0" }}>
                    {this.renderSalesLocationDropdown()}
                    {this.renderServiceLocationDropdown()}
                </MUIGrid>

                <MUIGrid container spacing={2} sx={{ padding: "1rem 0" }}>
                    {this.props.selectedOrderTypeRadio === 1 ? (
                        <>
                            {this.renderCategoryDropdown()}
                            {this.renderProductDropdown()}
                        </>
                    ) : (
                        <>
                            {this.renderOrderStatusDropdown()}
                            {this.renderOrderStatusReasonDropdown()}
                        </>
                    )}
                </MUIGrid>

                {this.props.selectedOrderTypeRadio === 1 && (this.renderOrderProduct())}
            </>
        );
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Cancel
                </MUIButton>
                <MUILoadingButton variant="contained" loading={this.props.loading} onClick={this.props.onConfirm}>
                    Create Order
                </MUILoadingButton>
            </MUIDialogActions>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "sm";
    }
}
