import React from "react";
import Layer from "../Layer";

import MUICard from '@mui/material/Card';
import MUICardContent from '@mui/material/CardContent';
import MUIContainer from "@mui/material/Container";
import MUIMenuItem from "@mui/material/MenuItem";
import MUIGrid from "@mui/material/Grid";
import MUIBusinessIcon from "@mui/icons-material/Business";
import MUITypography from "@mui/material/Typography";
import MUITextField from "@mui/material/TextField";
import MUIBox from "@mui/material/Box";
import MUIAlert from "@mui/material/Alert";
import MUIButton from "@mui/material/Button";

import video from "../Asset/Image/logoAnimation.mp4";
import logo from "../Asset/Image/logoBlue.png";
import Setting from "../Utility/Setting";
import moment from "moment";

export default class Login extends Layer {

    static organizations = [
        {
            'name': 'Rehab Medical',
            'code': 'rehabmedical'
        },
        // {
        //     'name': 'Cork Medical',
        //     'code': 'corkmedical'
        // },
        {
            'name': 'UAT',
            'code': 'uat'
        },
    ];

    constructor(props) {
        super(props);

        // Auto select the organization based on the URL.
        const url = new URL(window.location);
        switch (url.hostname) {
            case 'salespilot.com':
                this.state.organization_code = 'rehabmedical';
                break;
            case 'corkmedical.salespilot.com':
                this.state.organization_code = 'corkmedical';
                break;
            case 'localhost':
                this.state.organization_code = 'uat';
                break;
            default:
                this.state.organization_code = null;
                break;
        }
    }

    renderContent() {
        return (
            <MUIContainer maxWidth="xs" sx={{marginTop: 10}}>
                <MUICard>
                    <MUICardContent
                        style={{
                            fontSmoothingEnabled: true,
                            WebkitFontSmoothing: "antialiased",
                        }}
                    >
                        <MUIContainer disableGutters>{this.renderVideo()}</MUIContainer>
                        <MUIGrid container alignItems="center" sx={{ padding: "10px 0px 20px" }} >
                            <MUIGrid item xs={12}>
                                <MUIBox sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <MUIBusinessIcon sx={{ color: 'text.secondary', mr: 1, my: 0.5 }} />
                                    <MUITextField
                                        fullWidth
                                        select={true}
                                        label={"Organization"}
                                        InputLabelProps={{ shrink: true }}
                                        value={this.state.organization_code}
                                        onChange={(e) => {
                                            this.setState({
                                                organization_code: e.target.value
                                            })
                                        }}
                                        variant="standard"
                                    >
                                        {Login.organizations.map((organization) => {
                                            return (
                                                <MUIMenuItem key={organization.code} value={organization.code}>
                                                    {organization.name}
                                                </MUIMenuItem>
                                            );
                                        })}
                                    </MUITextField>
                                </MUIBox>
                            </MUIGrid>
                        </MUIGrid>
                        <MUIGrid container>
                            <MUIButton
                                type="submit"
                                fullWidth
                                size="large"
                                disabled={!this.state.organization_code}
                                variant={"contained"}
                                onClick={() => {
                                    // Temporarily disabling this; I think it's causing problems.
                                    if (false && Setting.get("session") && Setting.get("organization") === this.state.organization_code) {
                                        // If you are already logged in to the organization you're trying to log in to, skip Okta.
                                        window.location.href = `/dashboard`;
                                    } else {
                                        window.location.href = `/authorize?organization=${this.state.organization_code}`
                                    }
                                }}
                            >
                                LOG IN
                            </MUIButton>
                        </MUIGrid>
                        <MUIContainer disableGutters>
                            {Setting.get("loginError") && moment().isBefore(moment(Setting.get("loginErrorExpiration"))) && (
                                <MUIAlert severity="error" sx={{ marginTop: "10px" }} >{Setting.get("loginError")}</MUIAlert>
                            )}
                            <MUIContainer disableGutters sx={{ paddingTop: 1, textAlign: "right" }}>
                                <MUITypography onClick={this.localhostLogin.bind(this)}  variant="body" style={{ marginTop: "10px" }}>
                                    Version {window.salesPilot.meta.version}
                                </MUITypography>
                            </MUIContainer>
                        </MUIContainer>
                    </MUICardContent>
                </MUICard>
            </MUIContainer>
        );
    }

    /**
     * Log in using a locally hosted API.
     *
     * @param {event} event
     */
    localhostLogin(event) {
        if (event.detail === 3) {
            window.location.href = "/authorize?organization=localhost";
        }
    }

    renderVideo() {
        return (
            <video
                width="100%"
                autoPlay
                muted
            >
                <source
                    src={video}
                    type={"video/mp4"}
                />
                <img
                    src={logo}
                    style={{ marginBottom: 6 }}
                    alt={"Sales Pilot Logo"}
                />
            </video>
        );
    }

    getBackgroundColor() {
        return "transparent";
    }
}

