import TermList from "../AdminTermList";

export default class AdminTermListBrightreeInvoiceStatus extends TermList {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return "brightree_invoice_status";
    }
}
