import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import MUIIconButton from "@mui/material/IconButton";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import TileUser from "../../../Tile/TileUser";
import DialogSearchUser from "../../../Dialog/Search/DialogSearchUser";

export default class CardAdminSalesPcrLinkGeneral extends Card {
    static contextTypes = {
        usersIndexed: PropTypes.object,
    };


    constructor(props) {
        super(props);

        this.state.salesRepDialogOpen = false;
        this.state.salesPcrDialogOpen = false;
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        let salesPcrLink = this.props.salesPcrLink;
        let salesRepUser = null;
        let salesPcrUser = null;

        if (salesPcrLink && salesPcrLink.salesRepId !== null) {
            salesRepUser = this.context.usersIndexed[salesPcrLink.salesRepId];
        }

        if (salesPcrLink && salesPcrLink.patientCareRepId !== null) {
            salesPcrUser = this.context.usersIndexed[salesPcrLink.patientCareRepId];
        }

        //Sales Rep
        const handleEditSalesRep = () => {
            this.setState({ salesRepDialogOpen: true });
        };

        const handleClearSalesRep = () => {
            this.props.onChange({ salesRepId: null });
        };

        const salesRepIconButtons = [
            (<MUIIconButton onClick={handleEditSalesRep}><MUIEditIcon /></MUIIconButton>)
        ];

        if (salesRepUser) {
            salesRepIconButtons.push(
                (<MUIIconButton onClick={handleClearSalesRep}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        //Patient Care Rep
        const handleEditSalesPcr = () => {
            this.setState({ salesPcrDialogOpen: true });
        };

        const handleClearSalesPcr = () => {
            this.props.onChange({ patientCareRepId: null });
        };

        const salesPcrIconButtons = [
            (<MUIIconButton onClick={handleEditSalesPcr}><MUIEditIcon /></MUIIconButton>)
        ];

        if (salesPcrUser) {
            salesPcrIconButtons.push(
                (<MUIIconButton onClick={handleClearSalesPcr}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        return (
            <MUIGrid container spacing={ 2 } columns={ 12 }>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <TileUser
                        label="Sales Rep"
                        user={salesRepUser}
                        iconButtons={salesRepIconButtons}
                        required={true}
                        error={ this.props.validationIssues.salesRepId ? true : false }
                        helperText={ this.props.validationIssues.salesRepId ? this.props.validationIssues.salesRepId : "" }
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <TileUser
                        label="PCR"
                        user={salesPcrUser}
                        iconButtons={salesPcrIconButtons}
                        required={true}
                        error={ this.props.validationIssues.patientCareRepId ? true : false }
                        helperText={ this.props.validationIssues.patientCareRepId ? this.props.validationIssues.patientCareRepId : "" }
                    />
                </MUIGrid>
                <DialogSearchUser
                    open={ this.state.salesRepDialogOpen }
                    onClose={ () => this.setState({ salesRepDialogOpen: false }) }
                    onSelect={ (user) => {
                        this.props.onChange({ salesRepId: user.id });
                    } }
                />
                <DialogSearchUser
                    open={ this.state.salesPcrDialogOpen }
                    onClose={ () => this.setState({ salesPcrDialogOpen: false }) }
                    onSelect={ (user) => {
                        this.props.onChange({ patientCareRepId: user.id });
                    } }
                />
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}