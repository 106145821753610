import MUIPersonAddIcon from '@mui/icons-material/PersonAdd';
import MUIMenuItem from "@mui/material/MenuItem";
import MUIListItemIcon from '@mui/material/ListItemIcon';
import DialogIntakeQuestionnaire from "../../../../Dialog/DialogIntakeQuestionnaire";
import CardQueueDataGridOrderActivity from "../CardQueueDataGridOrderActivity";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import DialogDatePicker from '../../../../Dialog/DialogDatePicker';
import Validator from '../../../../../Utility/Validator';
import intakeQuestionnaireService from '../../../../../Seating/Security/IntakeQuestionnaireService/intakeQuestionnaireService';
import moment from 'moment';

export default class CardQueueDataGridOrderActivitySales extends CardQueueDataGridOrderActivity {
    constructor(props) {
        super(props);

        this.columns = {
            ...this.columns,
            "order_activity.scheduled_delivery_date": {
                "type": "dateTime",
                "name": "Scheduled",
                "width": 180,
            },
        };

        this.state.datePickerOpen = false;
    }

    /**
     * Handle clicking on a grid row.
     */
    handleClickRow(row) {
        this.setState({
            clickedRow: row
        });

        if (this.props.onClickRow) {
            this.props.onClickRow();
        }

        this.openOrder(row);
    }

     /**
      * Opens the order.
      *
      * @param {Object} row The grid row.
      */
     openOrder(row) {
        let orderActivityType = Object.values(this.context.orderActivityTypesIndexed).find((o) => o.name.toLowerCase() === row["order_activity.order_type"].toLowerCase());

        if (orderActivityType.activityType.toLowerCase() === "ticket") {
            window.open(
                `/patientInformation/${row["order_activity.patient_id"]}/${row["order_activity.id"]}`,
                "_blank"
            );
        } else {
            window.open(`/order/${row["order_activity.id"]}`, "_blank");
        }
 
        this.setState({
            queueDirty: true,
        });
    }


    /**
     * Handles the opening the Intake Questionnaire dialog.
     *
     * @param {object} row - The data for the current row, typically containing order details.
     */
    handleOpenIntakeQuestionnaireDialog(row) {
        const currentIntakeQuestionnaireId = row['order_activity.current_intake_questionnaire_id'];

        this.setState({
            intakeQuestionnaireDialogOpen: true,
            row: row,
            rowDataLoading: true,
        });

        if (currentIntakeQuestionnaireId) {
            intakeQuestionnaireService.getIntakeQuestionnaire(row['order_activity.current_intake_questionnaire_id'])
            .then((res) => {
                this.setState({
                    intakeQuestionnaire: JSON.parse(res.jsonResponse),
                    readOnly: true,
                    rowDataLoading: false,
                })
            })
            .catch((error) => {
                NotistackEnqueueSnackbar("Oh no! There as an error loading the intake questionnaire.", { variant: "error" });
                this.setState({ rowDataLoading: false, readOnly: true });
            });
        } else {
            this.setState({ rowDataLoading: false, readOnly: false });

        }
    }

    /**
     * Handles the opening of a date picker or date-time picker.
     *
     * @param {boolean} isDateTimePicker - Indicates if the date-time picker
     * should be opened.
     * @param {object} row - The data for the current row, typically containing
     * order details.
     * @param {string} columnName - The name of the column associated with the
     * date selection.
     */
    handleOpenDatePicker(row, columnName, defaultValue) {
        const columnNameMap = {
            "order_activity.evaluation_date": "evaluationDate",
            "order_activity.face_to_face_date": "faceToFaceDate",
            "order_activity.atp_evaluation_date": "atpEvaluationDate",
        };

        // Update columnName based on the mapping or keep it unchanged
        const updatedColumnName = columnNameMap[columnName] || columnName;

        this.setState({
            datePickerOpen: true,
            row: row,
            columnName: updatedColumnName,
            defaultValue: defaultValue
        });
    };

    renderInternal() {
        const handleIntakeQuestionnaireSave = (intakeQuestionnaire) => {
            if (intakeQuestionnaire.lastPhysicianVisitDate) {
                intakeQuestionnaire.lastPhysicianVisitDate = moment(intakeQuestionnaire.lastPhysicianVisitDate).format("YYYY-M-D");
            }

            const validationIssues = Validator.validate(
                intakeQuestionnaire,
                {
                    homeHealth: {
                        'required': true
                    },
                    confirmedInsurance: {
                        'required': true
                    },
                    planToChangeInsurance: {
                        'required': true,
                    },
                    lastPhysicianVisitDate: {
                        'required': true
                    },
                    patientCommitToProcess: {
                        'required': true
                    },
                    reasonForEquipment: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                this.handleUpdateOrderActivity(
                    this.state.row,
                    {
                        "intakeQuestionnaire": {
                            orderActivityId: this.state.row['order_activity.id'],
                            jsonResponse: JSON.stringify(intakeQuestionnaire)
                        }
                    }
                );
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }
        };

        return (
            <>
                <DialogIntakeQuestionnaire
                    open={this.state.intakeQuestionnaireDialogOpen}
                    onClose={() => this.handleOnCloseIntakeQuestionnaireDialog()}
                    header={"Intake Questionnaire"}
                    row={this.state.row}
                    loading={this.state.loading}
                    validationIssues={this.state.validationIssues}
                    onConfirm={(intakeQuestionnaire) => handleIntakeQuestionnaireSave(intakeQuestionnaire)}
                    intakeQuestionnaire={this.state.intakeQuestionnaire}
                    readOnly={this.state.readOnly}
                    rowDataLoading={this.state.rowDataLoading}
                />
                {this.state.datePickerOpen ? (
                    <DialogDatePicker
                        open={this.state.datePickerOpen}
                        onClose={() => {
                            this.setState({ datePickerOpen: false })
                        }}
                        onConfirm={(value) => {
                            this.handleUpdateOrderActivity(
                                this.state.row,
                                {
                                    [this.state.columnName]: value ? value.format("YYYY-MM-DD") : null,
                                    lastUpdatedById: this.context.currentUser.id,
                                }
                            );
                        }}
                        loading={this.state.loading}
                        defaultValue={this.state.defaultValue}
                    />
                ) : null}
            </>
        );
    }

    /**
    * @return {array} Order activity actions.
    */
    getActionsInternal() {
        let actions = [];

        actions.push(
            <MUIMenuItem value={"Create Patient"} onClick={() => { this.props.history.push({ pathname: "/chart/patient" }); }} >
                <MUIListItemIcon>
                    <MUIPersonAddIcon fontSize="small" />
                </MUIListItemIcon>
                Create Patient
            </MUIMenuItem>
        );

        return actions;
    }

    /**
     * Get any applicable group summary items for the data grid. This is used to
     * add custom values to the grouping rows.
     */
    getGroupSummaryItems() {
        return [{
            columnName: 'order_activity.order_activity_products._sum_points',
            type: 'sumPoints',
            showInGroupFooter: false
        }];
    }
}