import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Spinner,
    Col,
    CardHeader,
    Button,
    Input,
    InputFile,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    toast,
    ToastContainer,
    View,
    MDBIcon,
    MDBBtn,
} from "mdbreact";
import ExpenseService from "../Security/ExpenseService/expenseService";
import Select from "react-select";
import QuickFilter from "../Filters/quickFilter";
import {
    Grid,
    Table,
    PagingPanel,
    TableHeaderRow,
    TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import { CustomPaging, PagingState } from "@devexpress/dx-react-grid";
import "./expenses.css";
import ReactTooltip from "react-tooltip";
import GlobalFunctions from "../Filters/GlobalFunctions";
import ActivitiesModal from "../Activities/activitiesModal";
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import LayerLegacy from "../../LayerLegacy";

export default class expensesList extends LayerLegacy {
    constructor(props) {
        super(props);

        const devData = {
            columns: [
                {
                    title: "Approved By",
                    name: "approvedBy",
                },
                {
                    title: "Denied By",
                    name: "deniedBy",
                },
                {
                    title: "Receipt",
                    name: "receipt",
                },
                {
                    title: "Details",
                    name: "details",
                },
                {
                    title: "Expense Info",
                    name: "expenseInfo",
                },
                {
                    title: "Appointment Info",
                    name: "appointmentInfo",
                },
                {
                    title: "Deny Reason",
                    name: "approveDenyNote",
                },
            ],
            groupSummaryItems: [{columnName: "totalPoints", type: "sum"}],
            rows: [],
        };

        const defaultWidths = [
            { columnName: "receipt", width: 300 },
            { columnName: "details", width: 200 },
            { columnName: "appointmentInfo", width: 200 },
            { columnName: "approveDenyNote", width: 300 },
            { columnName: "expenseInfo", width: 200 },
            { columnName: "expenseDate", width: 120 },
            { columnName: "lastUpdated", width: 120 },
            { columnName: "owner", width: 120 },
            { columnName: "vendor", width: 120 },
            { columnName: "expenseDesc", width: 120 },
            { columnName: "peopleAttending", width: 150 },
            { columnName: "educationalTopics", width: 150 },
            { columnName: "expenseType", width: 120 },
            { columnName: "cardType", width: 120 },
            { columnName: "amount", width: 120 },
            { columnName: "approvedBy", width: 120 },
            { columnName: "deniedBy", width: 120 },
        ];

        const columnHeaders = [
            "id",
            "expenseDate",
            "lastUpdated",
            "salesRep",
            "company",
            "expenseDesc",
            "peopleAttending",
            "educationalTopics",
            "physiciansAttending",
            "expenseType",
            "cardType",
            "amount",
            "approvedBy",
            "deniedBy",
        ];

        let hasData = props.location.filters;

        this.state = {
            allowFiltersRoles: ["ADMIN", "EXECUTIVE_MANAGEMENT", "FINANCE", "SALES_MANAGER", "AREA_MANAGER"],
            devData: devData,
            defaultWidths: defaultWidths,
            columnHeaders: columnHeaders,
            isLoaded: true,
            filters: {},
            addExpenseModal: false,
            denyModalOpen: false,
            nExpense: {
                expenseReceipt: null,
                expenseDate: null,
                expenseDesc: null,
                vendor: null,
                expenseType: {},
                cardType: {},
                expenses: 0,
            },
            errorMessage: [],
            allLocs: [],
            allUsers: [],
            locationsSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            allUsersSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            locationsSelected: [],
            showDatePicker: true,
            quickFilter: [],
            quickFilterSelected: null,
            totalCount: 0,
            currentPage: 0,
            startDate: new Date(),
            endDate: new Date(),
            rawData: [],
            skip: 0,
            rowData: [],
            cardTypes: [],
            expenseTypes: [],
            resLength: 0,
            allSystemUsersSelected: [],
        };

        if (hasData) {
            let st = this.state;

            st.isLoaded = true;
            st.allSystemUsersSelected = hasData.users || [];
            st.locationsSelected = hasData.locations || [];
            st.quickFilter = hasData.quickFilter || [];
            st.quickFilterSelected = this.renderQuickFilter(hasData);
            st.totalCount = hasData.totalCount;
            st.currentPage = hasData.currentPage;
            st.startDate = this.renderStartDate(hasData);
            st.endDate = this.renderEndDate(hasData);
        }

        //this.changeCurrentPage = this.changeCurrentPage.bind(this);
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        internalLocations: PropTypes.array,
        cardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
        allLocations: PropTypes.array,
        salesReps: PropTypes.array,
        allUsers: PropTypes.array,
        companyName: PropTypes.string,
    };

    componentDidMount() {
        const { currentUser } = this.context;
        this.getLocationsOptions();
        this.getSalesRepsOptions();
        //this.getCardTypes();
        //this.getExpenseTypes();
        this.getLocalStorage();

        if (!this.state.allowFiltersRoles.includes(currentUser.role)) {
            this.setState({
                allSystemUsersSelected: [{ label: currentUser.firstname + " " + currentUser.lastname, value: currentUser.id }]
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { allUsers, currentUser, allLocations, expenseTypes, cardTypes} = this.context;
        let st = this.state;
        if (
            st.allLocs.length !== allLocations.length &&
            st.currUser !== currentUser
        ) {
            this.getLocationsOptions();
        }
        if (!st.allUsers || st.allUsers.length !== allUsers.length) {
            this.getSalesRepsOptions();
        }
        if (!st.cardTypes || st.cardTypes.length !== cardTypes.length) {
            this.getCardTypes();
        }
        if (!st.expenseTypes || st.expenseTypes.length !== expenseTypes.length) {
            this.getExpenseTypes();
        }
    }

    renderStartDate(props) {
        return props.startDate;
    }

    renderEndDate(props) {
        return props.endDate;
    }

    renderQuickFilter(props) {
        return props.quickFilterSelected;
    }

    changeCurrentPage(currentPage) {
        this.setState({
            isLoaded: false,
            currentPage: currentPage,
        });

        this.retrieveExpenses(currentPage);
    }

    getSalesRepsOptions(e) {
        const { currentUser, allUsers } = this.context;

        let options = [];

        if (e === undefined || e.length === 0) {
            if (
                currentUser.role === "ADMIN" ||
				currentUser.role === "EXECUTIVE_MANAGEMENT" ||
				currentUser.role === "FINANCE"
            ) {
                allUsers.map((rep) => {
                    return options.push({
                        label: rep.firstname + " " + rep.lastname,
                        value: rep.id,
                    });
                });
                this.setState({
                    allUsersSelect: options,
                    allUsers: allUsers,
                });
            }
        } else {
            let locs = [],
                salesList = [];
            e.forEach((location) => {
                locs.push(location.value);
            });

            allUsers.map((rep) => {
                if (rep.userprofileLocations.filter(x => locs.includes(x.locationsId)).length > 0) {
                    salesList.push(rep.id);
                    return options.push({
                        label: rep.firstname + " " + rep.lastname,
                        value: rep.id,
                    });
                }
                return null;
            });
            this.setState({
                allUsersSelect: options,
                allUsers: allUsers,
            });
        }
    }

    getLocationsOptions() {
        const { currentUser,allLocations } = this.context;
        let select = [],
            locations = [],
            allLocs = allLocations || [];

        if (
            currentUser.role !== "ADMIN" &&
			currentUser.role !== "EXECUTIVE_MANAGEMENT" &&
			currentUser.role !== "FINANCE"
        ) {
            locations = currentUser.userprofileLocations.map(x => x.locations) || [];
            locations.map((location) => {
                return select.push({
                    label: location.name,
                    value: location.id,
                });
            });
            this.setState({
                locationsSelect: select,
                allLocs: allLocs,
            });
        }
        else{
            allLocs.map((location) => {
                return select.push({
                    label: location.name,
                    value: location.id,
                });
            });
            this.setState({
                locationsSelect: select,
                allLocs: allLocs,
            });
        }
    }

    handleLocationChange = (e) => {
        this.setState({
            locationsSelected: e,
        });
        this.getSalesRepsOptions(e);
    };

    handleSaleRepChange = (e) => {
        this.setState({
            allSystemUsersSelected: e,
        });
    };

    handleQuickFilter = (e) => {
        let a = QuickFilter.getDates(e.value),
            startDate = "",
            endDate = "";

        if (a.length > 0) {
            startDate = a[0];
            endDate = a[1];
        }

        this.setState({
            quickFilter: a,
            quickFilterSelected: e,
            startDate: startDate,
            endDate: endDate,
        });
    };

    renderExpenseReceipt(receipt) {
        return (
            <View>
                <img
                    src={"data:image/png;base64," + receipt}
                    className="img-thumbnail"
                    alt={"Receipt"}
                    style={{ height: 80, width: 100 }}
                />
            </View>
        );
    }

    retrieveExpenses(pageNumber) {
        const { currentUser } = this.context;
        let filters = {},
            users = this.state.allSystemUsersSelected,
            arr = [];
        this.setState({ isLoaded: false });
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let filterStartDate = new Date(startDate).toLocaleDateString();
        let filterEndDate = new Date(endDate).toLocaleDateString();
        filters.start = filterStartDate;
        filters.end = filterEndDate;

        filters.userId = currentUser.id;

        if (users && users.length > 0) {
            users.forEach((u) => {
                arr.push(u.value || "");
            });
        }

        if (arr.length) {
            filters.ids = arr;
        }
        filters.page = pageNumber;
        filters.pageSize = 10;
        console.log(filters);
        return ExpenseService.getAllExpenses(filters)
            .then((res) => {
                console.log(res);
                this.setState({
                    rawData: res.items,
                    totalCount: res.totalCount,
                });
                toast.success("Found " + (res.totalCount || 0) + " Results");
                this.formatRows(res.items, res.totalCount);
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isLoaded: true,
                });
            });
    }

    ableToApproveDeny(expense) {
        const {currentUser} = this.context;

        switch (currentUser.role) {
            case "ADMIN":
                return true;
            case "EXECUTIVE_MANAGEMENT":
            case "SALES_MANAGER":
            case "AREA_MANAGER":
                return !(expense.activity.owner && expense.activity.owner.id === currentUser.id);
            default:
                return false;
        }
    }

    getExpensesForCSV() {
        // this.clientCSV(this.state.rawData, "Expenses.csv");
        let filters = {},
            st = this.state;

        if (!this.state.startDate || !this.state.endDate) {
            toast.error("Check your date filters before downloading");
            return;
        }

        filters.start = new Date(st.startDate).toLocaleDateString();
        filters.end = new Date(st.endDate).toLocaleDateString();

        if (st.allSystemUsersSelected && st.allSystemUsersSelected.length > 0) {
            let u = [];
            st.allSystemUsersSelected.forEach((r) => {
                u.push(r.value || "");
            });
            filters.ids = u.toString();
        }

        return ExpenseService.getExpensesForCSV(filters)
            .then((res) => {
                this.clientCSV(res, "Expenses.csv");
            })
            .catch((err) => {
                //handle error..BC
            });
    }

    renderApprovedBy(expense) {
        if (expense.approvedBy) {
            return expense.approvedBy.firstname + " " + expense.approvedBy.lastname;
        }

        if (this.ableToApproveDeny(expense)) {
            return (
                <Button
                    floating
                    size="sm"
                    color={"success"}
                    data-tip={"Approve"}
                    onClick={(e) => {
                        this.approveDenyExpense(expense, true);
                    }}
                >
                    <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                </Button>
            );
        } else {
            return "Not Approved";
        }
    }

    toggleDenyModal(expense) {
        this.setState({
            denyModalOpen: !this.state.denyModalOpen,
            selectedExpense: expense,
        });
    }

    approveDenyExpense(expense, approve) {
        const {currentUser} = this.context;

        let cb = { username: currentUser.username, id: currentUser.id, firstname: currentUser.firstname, lastname: currentUser.lastname },
            devData = this.state.rawData;

        let toEdit = devData.find((exp) => {
            return exp.id === expense.id;
        });

        if (approve === true) {
            toEdit.approvedBy = cb;
            toEdit.approvedById = cb.id;
            toEdit.deniedBy = null;
            toEdit.deniedById = null;
        } else {
            if (!toEdit.approveDenyNote) {
                toast.error("You must a note when denying an expense.");
                return;
            }
            toEdit.deniedBy = cb;
            toEdit.approvedBy = null;
            toEdit.deniedById = cb.id;
            toEdit.approvedById = null;
        }

        this.updateExpense(toEdit);

        this.formatRows(devData, this.state.totalCount);
    }

    updateExpense(expense) {
        return ExpenseService.updateExpense(expense)
            .then((data) => {
                toast.success("Saved Successfully!");
            })
            .catch((err) => {
                toast.warn("An error occurred while saving.");
            });
    }

    setDenyReason(reason) {
        let expense = this.state.selectedExpense;
        expense.approveDenyNote = reason;

        this.setState({
            selectedExpense: expense,
        });
    }

    renderDenyModal() {
        let se = this.state.selectedExpense;

        return (
            <Modal
                size="md"
                isOpen={this.state.denyModalOpen}
                value={se ? se.approveDenyNote : ""}
                toggle={() => this.toggleDenyModal(null)}
            >
                <ModalBody>
                    <Input
                        onChange={(e) => this.setDenyReason(e.target.value)}
                        outline
                        label={"Deny Reason"}
                    />
                </ModalBody>
                <ModalFooter>
                    <MDBBtn
                        color={"red"}
                        disabled={!se || !se.approveDenyNote}
                        onClick={() =>
                            this.approveDenyExpense(this.state.selectedExpense, false)
                        }
                    >
						Deny Expense
                    </MDBBtn>
                    <MDBBtn
                        color={"secondary"}
                        onClick={() => this.toggleDenyModal(null)}
                    >
						Cancel
                    </MDBBtn>
                </ModalFooter>
            </Modal>
        );
    }

    renderDeniedBy(expense) {
        if (expense.deniedBy) {
            return expense.deniedBy.firstname + " " + expense.deniedBy.lastname;
        }

        if (this.ableToApproveDeny(expense)) {
            return (
                <Button
                    floating
                    size="sm"
                    color={"red"}

                    data-tip={"Deny Expense"}
                    onClick={() => {
                        this.toggleDenyModal(expense);
                    }}
                >
                    <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                </Button>
            );
        } else {
            return "Not Denied";
        }
    }

    renderInfoColumn(expense) {
        let str = "";

        if (expense.expenseDate) {
            str += "Date: " + GlobalFunctions.formatDate(expense.expenseDate);
            str += " \n";
        }
        if (expense.activity.owner) {
            str += "Owner: " + expense.activity.owner.firstname + " " + expense.activity.owner.lastname;
            str += " \n";
        }

        if (expense.vendor) {
            str += "Vendor: " + expense.vendor;
            str += " \n";
        }
        if (expense.expenses) {
            str += "Amount: $" + expense.expenses.toString();
            str += " \n";
        }
        if (expense.expenseDesc) {
            str += expense.expenseDesc;
            str += " \n";
        }

        return str;
    }

    renderAppointmentInfoColumn(expense) {
        let str = "";

        if (expense.peopleAttending) {
            str += "People Attending: " + expense.peopleAttending;
            str += "\n";
        }

        if (expense.educationalTopics) {
            str += "Topics: " + expense.educationalTopics;
            str += "\n";
        }

        if (expense.physiciansAttending) {
            str += "Physicians Attending: " + expense.physiciansAttending;
            str += "\n";
        }

        return str;
    }

    renderExpenseInfo(expense) {
        let str = "";

        if (expense.cardType) {
            str += "Card Type: " + expense.cardType.name;
            str += "\n";
        }

        if (expense.expenseType) {
            str += "Expense Type: " + expense.expenseType.name;
            str += "\n";
        }

        if (expense.expenseDate) {
            str += "Last Updated: " + GlobalFunctions.formatDate(expense.expenseDate);
            str += "\n";
        }

        return str;
    }

    formatRows(expenses, totalElements) {
        let ary = [],
            dt = this.state.devData;

        expenses.forEach((expense, index) => {
            ary.push({
                receipt: expense.expenseReceipt && expense.expenseReceipt.length > 0
                    ? this.renderExpenseReceipt(expense.expenseReceipt)
                    : "",
                details: this.renderInfoColumn(expense),
                appointmentInfo: this.renderAppointmentInfoColumn(expense),
                expenseInfo: this.renderExpenseInfo(expense),
                // expenseDate: expense.expenseDate? GlobalFunctions.formatDate(expense.expenseDate) : '',
                // lastUpdated: expense.activity.modified ? GlobalFunctions.formatDate(expense.activity.modified) : '',
                // owner: expense.owner? expense.owner.name : '',
                // vendor: expense.vendor? expense.vendor.replace(/,/g, ' ') : '',
                // expenseDesc: expense.expenseDesc? expense.expenseDesc.replace(/,/g, ' ') : '',
                // peopleAttending: expense.peopleAttending || '',
                // educationalTopics: expense.educationalTopics? expense.educationalTopics.replace(/,/g, ' ') : '',
                // physiciansAttending: expense.physiciansAttending? expense.physiciansAttending.replace(/,/g, ' ') : '',
                // expenseType: expense.expenseType? expense.expenseType.name.replace(/,/g, ' ') : '',
                // cardType: expense.cardType? expense.cardType.name.replace(/,/g, ' ') : '',
                // amount: expense.expenses? ('$' + expense.expenses) :  '',
                approvedBy: this.renderApprovedBy(expense),
                deniedBy: this.renderDeniedBy(expense),
                approveDenyNote: expense.approveDenyNote,
                id: expense.id,
            });
        });

        dt.rows = ary;
        //if (this.state.currentPage === 0 && totalElements > 0) {
        //	toast.success("Found " + totalElements + " Results");
        //}

        this.setState({
            devData: dt,
            isLoaded: true,
            totalCount: totalElements,
            denyModalOpen: false,
        });
    }

    toggleNewExpenseModalPopup() {
        //toggle,clear the inputs & disble file input
        let currentState = this.state.addExpenseModal;
        this.setState({
            addExpenseModal: !currentState,
            //nExpense: nExpense,
        });
    }

    handleExpenseDate = (property, value) => {
        //update the date property on the object that the user has edited in the order...BC
        const nExpense = this.state.nExpense;
        nExpense[property] = value;
        this.setState({nExpense: nExpense});
    };

    changeCardType(cardType) {
        let oExpense = this.state.nExpense;
        oExpense.cardType = cardType.value;
        oExpense.expenseType = {};
        this.setState({nExpense: oExpense});
    }

    changeExpenseType(expenseType) {
        let oExpense = this.state.nExpense;
        oExpense.expenseType = {
            id: expenseType.value.id,
            name: expenseType.value.name,
        };
        this.setState({
            nExpense: oExpense,
        });
    }

    handleChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const nExpense = this.state.nExpense;
        nExpense[property] = event.target.value;
        this.setState({nExpense: nExpense});
    };

    fileInputHandler(value) {
        let reader = new FileReader(),
            file = value[0],
            expense = this.state.nExpense;

        reader.onloadend = () => {
            let data = reader.result,
                newStr = "";
            let rt = data.indexOf(",");
            if (rt > -1) {
                newStr = data.slice(rt + 1);
            }
            expense.receipt = newStr;
            this.setState({nExpense: expense});
        };

        reader.readAsDataURL(file);

        //let reader2 = new FileReader();
        //reader2.readAsArrayBuffer(file);
        //reader2.onloadend = function (ev) {
        //	if(ev.target.readyState === FileReader.DONE){
        //		var ab = ev.target.result,
        //			ar = new Uint8Array(ab);

        //		t.state.nExpense.expenseReceipt = ar;
        //		t.setState({ nExpense: expense });
        //	}
        //}

        //reader.onloadend = function () {
        //	t.state.nExpense.receiptString = reader.result;
        //	t.setState({ nExpense: expense });
        //};
        //reader.readAsDataURL(file);
    }

    renderReceipt() {
        let expense = this.state.nExpense;
        if (expense.expenseReceipt) {
            return (
                <Row>
                    <Col md="3">
                        <h6>
                            <strong>Preview Receipt :</strong>
                        </h6>
                    </Col>
                    <Col md="6">
                        <View>
                            <img
                                className={"thumbnail"}
                                src={"data:image/jpeg;base64," + expense.expenseReceipt}
                                alt={"Receipt"}
                                style={{ height: 250, width: 500 }}
                            />
                        </View>
                    </Col>
                </Row>
            );
        } else {
            return <div></div>;
        }
    }

    addValidation(msg) {
        let errorMessage = this.state.errorMessage;
        if (!errorMessage.includes(msg)) {
            errorMessage.push(msg);
        }
        this.setState({errorMessage: errorMessage});
    }

    removeValidation(msg) {
        let errorMessage = this.state.errorMessage;
        if (errorMessage.includes(msg)) {
            errorMessage.splice(msg);
        }
        this.setState({errorMessage: errorMessage});
    }

    validateForm() {
        let n = this.state.nExpense;

        if (Object.keys(n.cardType).length === 0) {
            this.addValidation("Select Card Type");
        } else if (Object.keys(n.cardType).length > 0) {
            this.removeValidation("Select Card Type");
        }
        if (Object.keys(n.expenseType).length === 0) {
            this.addValidation("Select Expense Type");
        } else {
            this.removeValidation("Select Expense Type");
        }
        if (n.expenseDate == null) {
            this.addValidation("Select Expense Date");
        } else {
            this.removeValidation("Select Expense Date");
        }

        if (n.expenseType) {
            if (n.expenseType.receiptRequired) {
                if (n.expenseReceipt == null) {
                    this.addValidation("Upload a receipt");
                } else {
                    this.removeValidation("Upload a receipt");
                }
            } else {
                //need to make sure to remove the validation if they don't need a receipt...BC
                this.removeValidation("Upload a receipt");
            }
        }

        if (n.vendor == null) {
            this.addValidation("Enter Vendor");
        } else {
            this.removeValidation("Enter Vendor");
        }

        if (n.expenseDesc == null) {
            this.addValidation("Enter Description");
        } else {
            this.removeValidation("Enter Description");
        }

        if (n.expenses == null || n.expenses <= 0) {
            this.addValidation("Add an amount greater than 0");
        } else {
            this.removeValidation("Add an amount greater than 0");
        }

        //check errormessage
        if (this.state.errorMessage.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    addExpense = () => {
        this.retrieveExpenses(0);
        //if (this.validateForm()) {
        //    const {currentUser} = this.context;
        //    let expense = this.state.nExpense;
        //    expense.when = moment(new Date());
        //    expense.owner = {id: currentUser.id, name: currentUser.name};

        //    return ExpenseService.createExpense(expense)
        //        .then((data) => {
        //            let nExpense = {
        //                receipt: null,
        //                expenseDate: null,
        //                when: null,
        //                owner: {},
        //                expenseDescription: null,
        //                expenseType: {},
        //                cardType: {},
        //                expenses: 0,
        //                type: "Appointment",
        //                isExpense: true,
        //                quality: false,
        //            };
        //            this.setState({nExpense: nExpense, isLoaded: false});
        //            this.toggleNewExpenseModalPopup();

        //            toast.success("Expense Created Successfully");
        //            this.retrieveExpenses(0);
        //        })
        //        .catch((err) => {
        //            toast.error("There was an error adding the expense");
        //            //handle err bcz
        //        });
        //}
    };

    getCardTypes() {
        const {cardTypes} = this.context;
        let ary = [];

        cardTypes.map((card) => {
            return ary.push({
                label: card.name,
                value: card,
            });
        });
        return ary;
    }

    getExpenseTypes() {
        let oExpense = this.state.nExpense;

        const {expenseTypes} = this.context;
        let ary = [];

        ary.push({
            label: "Choose Type",
            value: "",
        });

        expenseTypes.map((expense) => {
            return expense.expenseTypeCreditCards.forEach((card) => {
                if (oExpense.cardType.id === card.creditCardsId) {
                    ary.push({
                        label: expense.name,
                        value: expense,
                    });
                }
            });
        });
        return ary;
    }

    renderReceiptInput() {
        return (
            <Row>
                <Col md={"12"}>
                    <InputFile
                        textFieldTitle="only .png or .jpg files are currently supported"
                        getValue={this.fileInputHandler.bind(this)}
                    />
                </Col>
            </Row>
        );
    }

    renderAddExpenseModal() {
        const { companyName } = this.context;
        return (
            <ActivitiesModal
                plainExpense={companyName === "corkmedical"}
                hideNotes={true}
                isOpen={this.state.addExpenseModal}
                type={"Appointment"}
                activityFunction={this.addExpense.bind(this)}
                cancelFunction={this.toggleNewExpenseModalPopup.bind(this)}
                toggleFunction={this.toggleNewExpenseModalPopup.bind(this)}
                cardTypes={this.state.cardTypes}
                expenseTypes={this.state.expenseTypes}
                validateList={companyName === "corkmedical" ? null : ["account", "expenseType", "date"]}
            />
        );
    }

    getInternalLocations() {
        const {internalLocations, currentUser} = this.context;

        let ary = [],
            role = currentUser.role;

        ary.push({
            label: "Choose a Location...",
            value: "",
        });

        switch (role) {
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "FINANCE":
                internalLocations.map((location) => {
                    return ary.push({
                        label: location.name,
                        value: location,
                    });
                });
                break;
            case "SALES_MANAGER":
                if (currentUser.locations) {
                    currentUser.locations.map((location) => {
                        if (location.type === "Internal") {
                            return ary.push({
                                label: location.name,
                                value: location,
                            });
                        }
                        return null;
                    });
                }
                break;
            default:
                break;
        }
        this.setState({
            locationsSelect: ary,
        });
    }

    selectLocation(location) {
        let nFilters = this.state.filters;
        nFilters.locationId = location.value.id;
        this.setState({filters: nFilters});
    }

    getLocalStorage() {
        let f = window.localStorage.getItem("expenseFilters");

        if (f != null) {
            f = JSON.parse(f);

            this.setState({
                startDate: f.startDate ? new Date(f.startDate) : new Date(),
                endDate: f.endDate ? new Date(f.endDate) : new Date(),
                allSystemUsersSelected: f.users,
                locationsSelected: f.locations,
            });
            this.getSalesRepsOptions(f.locations);
        }

        //setTimeout(() => {
        //	if (this.validateFilters()) {
        //		this.retrieveExpenses(0);
        //	}
        //}, 500);
    }

    updateLocalStorageAndSearch() {
        let filters = {},
            st = this.state;

        filters.startDate = st.startDate;
        filters.endDate = st.endDate;
        filters.users = st.allSystemUsersSelected;
        filters.locations = st.locationsSelected;

        window.localStorage.setItem("expenseFilters", JSON.stringify(filters));
        this.retrieveExpenses(0);
    }

    validateFilters(showError){
        let st = this.state;
        let valid = true;
        if (!st.startDate || !st.endDate){
            valid = false;
            if (showError) toast.error("Please add a start and end date.");
        }
        //if (st.allSystemUsersSelected.length === 0){
        //	valid = false;
        //	if (showError) toast.error("At least one user needs to be selected.");
        //}
        if(!valid){
            this.setState({
                isLoaded: true
            });
        }
        return valid;
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div
                className={"expensesGrid orderList"}
                style={{marginBottom: "1rem"}}
            >
                {this.renderDevTable()}
            </div>
        );
    }

    renderDevTable() {
        let filtersData = {
            users: this.state.allSystemUsersSelected,
            locations: this.state.locationsSelected,
            currentPage: this.state.currentPage,
            totalCount: this.state.totalCount,
        };

        //build filters to send over
        if (this.state.showDatePicker) {
            filtersData.startDate = this.state.startDate;
            filtersData.endDate = this.state.endDate;
        } else {
            filtersData.quickFilter = this.state.quickFilter;
            filtersData.quickFilterSelected = this.state.quickFilterSelected;
        }

        const TableCell = ({row, column, ...restProps}) => (
            <Table.Cell
                {...restProps}
                onClick={() => {
                    let c = column;

                    if (c.name === "approvedBy" || c.name === "deniedBy") {
                        //we don't want to move with these columns...BC
                        return;
                    }

                    this.props.history.push({
                        pathname: "/expenses/" + row.id,
                        filters: filtersData,
                    });
                }}
                style={{
                    cursor: "pointer",
                }}
            />
        );

        return (
            <Grid
                style={{maxWidth: "1800px !important"}}
                rows={this.state.devData.rows}
                columns={this.state.devData.columns}
            >
                <PagingState
                    defaultCurrentPage={0}
                    pageSize={10}
                    currentPage={this.state.currentPage}
                    onCurrentPageChange={this.changeCurrentPage.bind(this)}
                />
                <CustomPaging totalCount={this.state.totalCount} />
                <Table cellComponent={TableCell}/>
                <TableColumnResizing defaultColumnWidths={this.state.defaultWidths}/>
                <TableHeaderRow/>
                <PagingPanel/>
            </Grid>
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    handleDatePickerChange = (property, value) => {
        this.setState({
            [property]: value,
        });
    };

    renderLocationsFilter() {
        const {currentUser} = this.context;
        let role = currentUser.role;

        if(this.state.allowFiltersRoles.includes(role)) {
			 return (
                <Row>
                    <Col md={"6"} style={{ marginTop: 5 }}>
                        <Select
                            placeholder="Select Location"
                            // closeMenuOnSelect={false}
                            isMulti
                            options={this.state.locationsSelect}
                            onChange={this.handleLocationChange.bind(this)}
                            value={this.state.locationsSelected}
                        />
                    </Col>
                    <Col md={"6"} style={{ marginTop: 5 }}>
                        <Select
                            placeholder="Select User"
                            closeMenuOnSelect={false}
                            isMulti
                            options={this.state.allUsersSelect}
                            onChange={this.handleSaleRepChange.bind(this)}
                            value={this.state.allSystemUsersSelected}
                        />
                    </Col>
                </Row>
            );
        }
    }

    //call to download data
    clientCSV(stateData, filename) {
        let result = "",
            ctr = 0,
            keys = this.state.columnHeaders,
            columnDelimiter = ",",
            lineDelimiter = "\n";

        if (stateData == null || !stateData.length) {
            return null;
        }

        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        stateData.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (
                    key === "expenseDesc" ||
                    key === "educationalTopics" ||
                    key === "physiciansAttending"
                ) {
                    result += '"' + item[key] + '"';
                } else {
                    result +=
                        item[key] === null || item[key] === undefined ? "" : item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //csv downlaod data here
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    renderContent() {
        return (
            <div style={{ marginLeft: "4%", marginRight: "4%" }}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                {this.renderDenyModal()}
                {this.renderAddExpenseModal()}
                <CardHeader style={{backgroundColor: "#5881C1"}}>
                    <Row>
                        <Col md={"4"} style={{ color: "#000" }}>
                            {this.renderLocationsFilter()}
                        </Col>
                        <Col md={"2"} style={{ marginTop: 5, color: "#000" }}>
                            <Select
                                placeholder="Quick Filter"
                                options={QuickFilter.getOptions()}
                                onChange={this.handleQuickFilter.bind(this)}
                                value={this.state.quickFilterSelected}
                            />
                        </Col>
                        <Col md={"2"} style={{ color: "#FFF", marginTop: 4  }}>
                            <div className={"expenseDateOptions"}>
                                <MUIDatePicker
                                    label="Start Date"
                                    value={this.state.startDate ? moment(this.state.startDate) : null}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            size: "small",
                                            variant: 'outlined',
                                            InputLabelProps: {
                                                shrink: true
                                            },
                                            InputProps: {
                                                sx: {
                                                    backgroundColor: "#fff",
                                                    height: '40px', // Match the height of the Quick Filter Select
                                                },
                                            },
                                        },
                                    }}
                                    onChange={this.handleDatePickerChange.bind(
                                        this,
                                        "startDate"
                                    )}
                                    views={['year', 'month', 'day']}
                                />
                            </div>
                        </Col>
                        <Col md={"2"} style={{ marginTop: 4 }}>
                            <div className={"expenseDateOptions"}>
                                  <MUIDatePicker
                                    label="End Date"
                                    value={this.state.endDate ? moment(this.state.endDate) : null}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            size: "small",
                                            variant: 'outlined',
                                            InputLabelProps: {
                                                shrink: true
                                            },
                                            InputProps: {
                                                sx: {
                                                    backgroundColor: "#fff",
                                                    height: '40px', // Match the height of the Quick Filter Select
                                                },
                                            },
                                        },
                                    }}
                                    onChange={this.handleDatePickerChange.bind(this, "endDate")}
                                    views={['year', 'month', 'day']}
                                />
                            </div>
                        </Col>
                        <Col md={"2"}>
                            <Button
                                floating
                                size="sm"
                                color={"indigo"}
                                data-tip={"Save and Search"}
                                onClick={() => {
                                    this.updateLocalStorageAndSearch();
                                }}
                            >
                                <MDBIcon icon="search-plus" style={{ fontSize: "2em" }} />
                            </Button>

                            <Button
                                floating
                                size="sm"
                                color={"secondary"}
                                data-tip={"Add New Expense"}
                                onClick={() => this.toggleNewExpenseModalPopup()}
                            >
                                <MDBIcon icon="plus" style={{ fontSize: "2em" }} />
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <Row style={{ zIndex: 0 }}>
                    <Col style={{ zIndex: 0 }}>{this.renderTableOrSpinner()}</Col>
                </Row>
                <div className={"fixed-bottom downloadCsvDiv"}>
                    <Button
                        className={"downloadCsvButton"}
                        floating
                        size="sm"
                        color={"indigo"}
                        data-tip={"Download CSV"}
                        onClick={this.getExpensesForCSV.bind(this)}
                    >
                        <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                    </Button>

                    <ReactTooltip/>
                </div>
            </div>
        );
    }
}
