import Admin from "../Admin";
import React from "react";
import CardAdminInsuranceTypeGeneral from "../../../Component/Card/Admin/InsuranceType/CardAdminInsuranceTypeGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';

import Validator from "../../../Utility/Validator";

import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import InsuranceType from "../../../Utility/Crud/InsuranceType";
import insuranceService from "../../../Seating/Security/InsuranceService/insuranceService";

export default class AdminInsuranceTypeCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.insuranceType = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the insuranceType in the state.
     */
    afterComponentDidMount() {
        const insuranceTypeId = this.props.match.params.id ? +this.props.match.params.id : null;
        
        if (insuranceTypeId !== null) {
            const insuranceType = this.context.insuranceTypesIndexed[insuranceTypeId];
            this.setState({
                insuranceType: insuranceType,
            });
        }
    }

    /**
     * Creates the insurance with the current properties in state.insuranceType.
     */
    async createInsuranceType() {
        this.setState({
            isProcessing: true,
        });

        return insuranceService.createInsurance(this.state.insuranceType)
            .then(insuranceType => {
                this.context.updateIndexed("insuranceTypesIndexed", insuranceType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Payor Source created successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Payor Source.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the insurance with the current properties in state.insuranceType.
     */
    async updateInsuranceType() {
        this.setState({
            isProcessing: true,
        });

        return insuranceService.updateInsurance(this.state.insuranceType)
            .then(insuranceType => {
                this.context.updateIndexed("insuranceTypesIndexed", insuranceType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Payor Source updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Payor Source.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }


    async deleteInsuranceType() {
        this.setState({
            isProcessing: true,
        });

        return insuranceService.deleteInsurance(this.state.insuranceType.id)
            .then(() => {
                this.context.deleteIndexed("insuranceTypesIndexed", this.state.insuranceType.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Payor Source deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Payor Source.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (insuranceType) => {
            this.setState({
                insuranceType: {
                    ...this.state.insuranceType,
                    ...insuranceType,
                },
            });
        };
        
        return (
            <>
                <CardAdminInsuranceTypeGeneral
                    insuranceType={this.state.insuranceType}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteInsuranceType()}
                    text="Are you sure you want to delete this payor source? This cannot be undone."
                    header="Delete Payor Source"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Render the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Payor Source';
            subtitle = null;
        } else {
            title = 'Edit Payor Source';
            subtitle = (this.state.insuranceType && (InsuranceType.getDisplayName(this.state.insuranceType) + " • " + this.state.insuranceType.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.insuranceType,
                {
                    name: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateInsuranceType();
                } else {
                    this.createInsuranceType();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
