import React from "react";
import moment from "moment";
import {
    Button,
    DataTable,
    Container,
    Spinner,
    MDBIcon,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardHeader,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import GlobalFunctions from "../Filters/GlobalFunctions";
import reportsService from "../Security/ReportsService/reportsService";
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import LayerLegacy from "../../LayerLegacy";

export default class ShippingDetailsReport extends LayerLegacy {
    constructor(props) {
        super(props);

        //table headers & columns
        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                    width: 80,
                },
                {
                    label: "PO Number",
                    field: "poNumber",
                    sort: "asc",
                    width: 100,
                    type: "string",
                },
                {
                    label: "Confirmation Number",
                    field: "confirmationNumber",
                    sort: "asc",
                    width: 200,

                },
                {
                    label: "Service Location",
                    field: "serviceLocation",
                    sort: "asc",
                },
                {
                    label: "Vendor",
                    field: "vendor",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Tracking Number",
                    field: "trackingNumber",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Patient Name",
                    field: "patientName",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Order Id",
                    field: "orderId",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Exp. Ship Date",
                    field: "expectedShipDate",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Requested On",
                    field: "requestedOn",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Status Changed On",
                    field: "statusChangedOn",
                    sort: "asc",
                    width: 100,
                },
            ],
            rows: [],
        };

        let dt = new Date(),
            dt2 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - 2);

        this.state = {
            data: data,
            startDate: dt2,
            endDate: dt,
            isLoaded: true,
        };
    }

    getReport() {
        let sd = new Date(this.state.startDate).toLocaleDateString();
        let ed = new Date(this.state.endDate).toLocaleDateString();

        let f = {
            start: sd,
            end: ed,
        };

        reportsService.getShippingDetailsReport(f)
            .then(res => {
                let a = [],
                    dt = this.state.data;

                if (res) {
                    res.forEach(i => {
                        a.push({
                            id: i.id,
                            poNumber: i.poNumber || "",
                            confirmationNumber: i.confirmationNumber || "",
                            serviceLocation: i.serviceLocation || "",
                            vendor: i.vendor || "",
                            trackingNumber: i.trackingNumber || "",
                            patientName: i.patientName || "",
                            orderId: i.orderId || "",
                            expectedShipDate: i.expectedShipDate || "",
                            requestedOn: i.requestedOn ? GlobalFunctions.formatDateTimeTimeZone(i.requestedOn) : "",
                            status: i.status || "",
                            statusChangedOn: i.statusChangedOn ? GlobalFunctions.formatDateTimeTimeZone(i.statusChangedOn) : "",
                        });
                    });
                }

                dt.rows = a;
                this.setState({
                    data: dt,
                    isLoaded: true,
                });
            }).catch(err => {
                console.log(err);
                this.setState({ isLoaded: true });
            });
    }

    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.field);
            headers.push(col.label);
        });

        let replace = ["confirmationNumber", "vendor", "trackingNumber", "patientName", "expectedShipDate", "requestedOn", "statusChangedOn"],
            children = ["account"];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (children.indexOf(key) > -1 && item[key] != null) {
                    //a child in this case is the object, but we just want the text...BC
                    result += item[key].props.children.replace(/,/g, " ");
                } else if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center", margin:"100px" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    //load in the table
    renderContent() {
        return (
            <MDBCard style={{width: "90vw", marginLeft: "5vw", marginTop: "5px"}}>
                <MDBCardHeader style={{width: "100%", backgroundColor: "#5881C1", padding: "10px 20px"}}>
                    <MDBRow className={'inputDateWrapper'}>
                        <MDBCol size={'2'}>
                            <MUIDatePicker
                                label="Start Date"
                                value={this.state.startDate ? moment(this.state.startDate) : null}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        variant: 'outlined',
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                        InputProps: {
                                            sx: {
                                                backgroundColor: "#fff",
                                            },
                                        },
                                    },
                                }}
                                onChange={(value) => { this.setState({ startDate: value }); }}
                                views={['year', 'month', 'day']}
                            />
                        </MDBCol>
                        <MDBCol size={'2'}>
                            <MUIDatePicker
                                label="End Date"
                                value={this.state.endDate ? moment(this.state.endDate) : null}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        variant: 'outlined',
                                        InputLabelProps: {
                                            shrink: true
                                        },
                                        InputProps: {
                                            sx: {
                                                backgroundColor: "#fff",
                                            },
                                        },
                                    },
                                }}
                                onChange={(value) => { this.setState({ endDate: value }); }}
                                views={['year', 'month', 'day']}
                            />
                        </MDBCol>

                        <MDBCol style={{display: 'flex', justifyContent: "right", padding: "5px"}}>
                            <Button className="px-2 toolbarButton"
                                outline
                                rounded
                                size="sm"
                                color={'success'}
                                data-place={'bottom'}
                                data-tip={"View Report"}
                                disabled={!this.state.isLoaded}
                                onClick={() => {
                                    this.setState({
                                        isLoaded: false,
                                    });
                                    this.getReport();
                                }}>
                                <MDBIcon icon="chart-line" style={{fontSize: "2em"}}/>
                            </Button>
                        </MDBCol>
                    </MDBRow>
                </MDBCardHeader>
                <MDBRow>
                    {this.state.isLoaded ? <MDBCol size="12" style={{marginLeft: "2.5vw", paddingRight: "5vw"}}>
                        <DataTable
                            entriesOptions={[25, 50, 100]}
                            pagesAmount={10}
                            data={this.state.data}
                            striped
                            bordered
                        ></DataTable>
                    </MDBCol> : this.renderLoadingSpinner()}

                </MDBRow>
                <div className={"fixed-bottom downloadCsvDiv"}>
                    <Button
                        floating
                        size="sm"
                        className={"downloadCsvButton"}
                        color={"primary"}
                        data-tip={"Download CSV"}
                        onClick={this.clientCSV.bind(
                            this,
                            this.state.data,
                            "Shipping Details.csv"
                        )}
                    >
                        <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                    </Button>
                    <ReactTooltip />
                </div>
                <ReactTooltip/>
            </MDBCard>
        );
    }
}
