import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    CardHeader,
    Container,
    Input,
    Modal,
    ModalBody,
    Row,
    toast,
    Fa,
    ModalFooter,
} from "mdbreact";
import "./account.css";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import AccountService from "../Security/AccountService/accountService";
import NoteService from "../Security/NoteService/noteService";
import GlobalFunctions from "../Filters/GlobalFunctions";
import LayerLegacy from "../../LayerLegacy";

export default class accountAdd extends LayerLegacy {
    constructor(props) {
        super(props);

        this.accountFunction = props.accountFunction;
        this.cancelFunction = props.cancelFunction;

        this.state = {
            nAccount: {
                name: "",
                accountType: {},
                owner: {},
                address: {
                    lines: [],
                    city: "",
                    state: "",
                    zip: "",
                },
                phone: "",
                fax: "",
                email: "",
                notes: null,
            },
            isOpen: props.isOpen,
            typeRadio: "Note",
            noteText: "",
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        salesReps: PropTypes.array,
        accountTypes: PropTypes.array,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen != null) {
            this.setState({
                isOpen: nextProps.isOpen,
            });
        }
    }

    componentDidMount() {
        //Set the owner of the account to be the currentUser...BC
        const { currentUser } = this.context;
        let acc = this.state.nAccount;
        acc.owner = { id: currentUser.id, username: currentUser.username };
        acc.ownerId = currentUser.id;

        this.setState({ nAccount: acc });
    }

    handleChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const nAccount = this.state.nAccount;
        nAccount[property] = event.target.value;
        this.setState({ eAccount: nAccount });
    };

    handleSelectChange = (property, e) => {
        let n = this.state.nAccount;

        if (property === "owner") {
            n.owner = { id: e.value.id, name: e.value.username };
            n.ownerId = e.value.id;
        } else if (property === "accountType") {
            n.accountType = { id: e.value.id, name: e.value.name };
            n.accountTypeId = e.value.id;
        }
        this.setState({ nAccount: n });
    };

    validateForm() {
        let n = this.state.nAccount,
            status = true;

        if (n.state === "") {
            toast.warn("Enter State");
            status = false;
        }

        if (n.city === "") {
            toast.warn("Enter City");
            status = false;
        }

        if (Object.keys(n.accountType).length === 0) {
            toast.warn("Select an Account Type");
            status = false;
        }

        if (Object.keys(n.owner).length === 0) {
            toast.warn("Select an Owner");
            status = false;
        }

        if (n.name === "") {
            toast.warn("Enter an Account Name");
            status = false;
        }

        return status;
    }

    addAccount() {
        if (this.validateForm()) {
            return AccountService.createAccount(this.state.nAccount)
                .then((response) => {
                    //reset the user object to blank...BC
                    let nAccount = {
                        name: "",
                        accountType: {},
                        group: {},
                        owner: {},
                        address: {
                            lines: [],
                            city: "",
                            state: "",
                            zip: "",
                        },
                        phone: "",
                        fax: "",
                        email: "",
                        notes: null,
                    };
                    this.createNoteTask(response);

                    //this is the callback that will add the newly created account to the list...BC
                    this.accountFunction(response);
                    this.setState({ nAccount: nAccount });
                })
                .catch((err) => {
                    toast.warn("Oh! There was an error creating the Account.");
                });
        }
    }

    createNoteTask(account) {
        const { currentUser } = this.context;

        let typeRadio = this.state.typeRadio,
            accountDTO = { id: account.id, name: account.name },
            cb = { id: currentUser.id, name: currentUser.username };

        let nText = document.getElementById("newNoteText").value;

        //we don't have any text, no note to create
        if (nText === "") {
            this.cancelFunction();
        } else {
            let note = {
                text: nText,
                account: accountDTO,
                completed: true,
                task: false,
                createdAt: GlobalFunctions.getUTCMoment(),
                createdBy: cb,
                createdById: cb.id,
            };

            if (typeRadio === "Task") {
                note.completed = false;
                note.task = true;
            }

            return NoteService.createAccountNote(note)
                .then((res) => {
                    toast.success("Task successfully created.");
                    this.cancelFunction();
                })
                .catch((err) => {
                    toast.warn("Oh! There was an error creating the Task.");
                });
        }
    }

    getAccountTypeList() {
        const { accountTypes } = this.context;

        let a = [];
        accountTypes.map((type) => {
            return a.push({
                label: type.name,
                value: type,
            });
        });

        return a;
    }

    getSalesRepList() {
        const { salesReps } = this.context;

        let a = [];
        salesReps.map((rep) => {
            return a.push({
                label: rep.firstname + " " + rep.lastname,
                value: rep,
            });
        });

        return a;
    }

    renderInputs() {
        let account = this.state.nAccount;

        return (
            <div>
                <Input
                    value={account.name}
                    onChange={this.handleChange.bind(this, "name")}
                    label="Account Name"
                    size="sm"
                />
                <Row>
                    <Col md={"6"}>
                        <Select
                            placeholder={
                                account.owner ? account.owner.firstname + " " + account.owner.lastname : "Select Sales Rep..."
                            }
                            options={this.getSalesRepList()}
                            onChange={this.handleSelectChange.bind(this, "owner")}
                        />
                    </Col>
                    <Col md={"6"}>
                        <Select
                            placeholder={"Select Account Type..."}
                            options={this.getAccountTypeList()}
                            onChange={this.handleSelectChange.bind(this, "accountType")}
                        />
                    </Col>
                </Row>
                <Input
                    value={account.addrLine1}
                    onChange={this.handleChange.bind(this, "addrLine1")}
                    label="Address"
                    size="sm"
                />
                <Row>
                    <Col md={"4"}>
                        <Input
                            value={account.city}
                            onChange={this.handleChange.bind(this, "city")}
                            label="City"
                            size="sm"
                        />
                    </Col>
                    <Col md={"4"}>
                        <Input
                            value={account.state}
                            onChange={this.handleChange.bind(this,  "state")}
                            label="State"
                            size="sm"
                        />
                    </Col>
                    <Col md={"4"}>
                        <Input
                            value={account.zip}
                            onChange={this.handleChange.bind(this, "zip")}
                            label="ZipCode"
                            size="sm"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={"4"}>
                        <Input
                            value={account.phoneNumber}
                            onChange={this.handleChange.bind(this, "phoneNumber")}
                            label="Phone"
                            size="sm"
                        />
                    </Col>
                    <Col md={"4"}>
                        <Input
                            value={account.faxNumber}
                            onChange={this.handleChange.bind(this, "faxNumber")}
                            label="Fax"
                            size="sm"
                        />
                    </Col>
                    <Col md={"4"}>
                        <Input
                            value={account.email}
                            onChange={this.handleChange.bind(this, "email")}
                            label="E-mail"
                            size="sm"
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    renderContent() {
        return (
            <Container>
                <Modal
                    position={"top"}
                    className=""
                    isOpen={this.state.isOpen}
                    toggle={() => {
                        return;
                    }}
                >
                    <CardHeader color={"indigo"}>
                        <Row>
                            <Col md={"6"}>Add New Account</Col>
                        </Row>
                    </CardHeader>

                    <ModalBody>
                        {this.renderInputs()}
                        <Row>
                            <Col size="5">
                                <Input
                                    onClick={() => this.setState({ typeRadio: "Note" })}
                                    checked={this.state.typeRadio === "Note"}
                                    label="Note"
                                    type="radio"
                                    id="noteRadio"
                                />
                            </Col>
                            <Col size="5">
                                <Input
                                    onClick={() => this.setState({ typeRadio: "Task" })}
                                    checked={this.state.typeRadio === "Task"}
                                    label="Task"
                                    type="radio"
                                    id="taskRadio"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={"12"}>
                                <textarea
                                    placeholder={"Enter Text"}
                                    id={"newNoteText"}
                                    style={{ width: "100%", height: "160px" }}
                                ></textarea>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            color={"success"}
                            floating
                            size="sm"
                            data-tip={"Add New Account"}
                            onClick={this.addAccount.bind(this)}
                        >
                            <Fa size="2x" icon={"check"}>
                                {" "}
                            </Fa>
                        </Button>

                        <Button
                            color={"red"}
                            floating
                            size="sm"
                            data-tip={"Cancel"}
                            onClick={this.cancelFunction.bind(this)}
                        >
                            <Fa size="2x" icon={"times"}>
                                {" "}
                            </Fa>
                        </Button>

                        <ReactTooltip />
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}
