import React from "react";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";

export default class CardChartPatientContactInfo extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleChange = (event) => {
            const { value } = event.target;
            // Remove all non-digit characters
            const cleaned = value.replace(/\D/g, '');
            // Format the cleaned number
            let formattedNumber = cleaned;
            if (cleaned.length > 3) {
                formattedNumber = `(${cleaned.slice(0, 3)}`;
            }
            if (cleaned.length > 6) {
                formattedNumber += `) ${cleaned.slice(3, 6)}-`;
                formattedNumber += cleaned.slice(6, 10);
            } else if (cleaned.length > 3) {
                formattedNumber += `) ${cleaned.slice(3)}`;
            }
            this.props.onChange({ phone: formattedNumber });
        };

        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.patient ? this.props.patient.phone : ""}
                        size="small"
                        label="Phone Number"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        required={true}
                        error={this.props.validationIssues.phone ? true : false}
                        helperText={this.props.validationIssues.phone ? this.props.validationIssues.phone : ""}
                    />
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Contact";
    }
}