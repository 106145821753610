import React from "react";
import Card from "../../../Card";
import Upload from "../../../Upload";
import MUIBox from "@mui/material/Box";
import MUIButton from "@mui/material/Button";
import MUIAlert from "@mui/material/Alert";
import MUIAlertTitle from "@mui/material/AlertTitle";
import DataGrid from "../../../DataGrid";
import Progress from "../../../Progress";
import EmptyState from "../../../EmptyState";

export default class CardPatientCollectionsImport extends Card {
    /**
     * Render the content.
     */
    renderContent() {
        if (this.props.isUploading) {
            return (<Progress message="Loading Data..." />);
        }

        if (this.props.rows && this.props.rows.length > 0) {
            return (
                <div>
                    {this.props.unmappedRows.length > 0 ? (
                        <MUIAlert severity="error">
                            <MUIAlertTitle>Error: Missing Patient</MUIAlertTitle>
                            You must correct all highlighted rows before file can be imported.
                        </MUIAlert>
                    ) : null}
                    <MUIBox sx={{height: '580px', marginBottom: 5}}>
                        <DataGrid
                            rows={this.props.rows}
                            columns={this.props.columns}
                            onClick={this.props.onClick}
                            defaultColumnWidths={this.props.defaultColumnWidths}
                        />
                    </MUIBox>
                </div>
            )
        } else {
            return (
                <EmptyState
                line1={"Import"}
                line2={"Upload a file to begin import"}
            />
            )
        }
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Import";
    }

    /**
    * Renders add member button.
    */
    getActions() {
        if (this.props.fileImported) {
            return [
                (<MUIButton
                    component="label"
                    variant="text"
                    onClick={this.props.onCancel}
                >
                    Cancel
                </MUIButton>),
                (<MUIButton
                    component="label"
                    variant="contained"
                    onClick={this.props.onImportButtonClick}
                    disabled={this.props.unmappedRows.length > 0}
                >
                    Import
                </MUIButton>),
            ]
        }
        return [
            (<Upload
                label={"Upload File"}
                onChange={this.props.onUpload}
            />)
        ];
    }
}