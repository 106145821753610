import React from "react";
import DialogSearch from "../DialogSearch";
import TileTeam from "../../Tile/TileTeam";
import PropTypes from "prop-types";
import Team from "../../../Utility/Crud/Team";

export default class DialogSearchTeam extends DialogSearch {

    static contextTypes = {
        teamsIndexed: PropTypes.object,
    };

    /**
     * Searches for teams by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered teams.
     */
    getSearchResults(query) {
        return Team.search(this.state.data, query, true).filter((team) => {
            return team.deleted === false;
        });
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.teamsIndexed);
        }
    }

    /**
     * @param {object} team The team object.
     * @returns A TileTeam component for this team.
     */
    renderTile(team) {
        return (
            <TileTeam
                key={team.id}
                team={team}
            />
        );
    }
}
