import React from "react";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUIIconButton from "@mui/material/IconButton";
import MUIEditIcon from "@mui/icons-material/Edit";
import TileDepartment from "../../../Tile/TileDepartment";
import DialogSearchDepartment from "../../../Dialog/Search/DialogSearchDepartment";

export default class CardAdminTeamGeneral extends Card {
    constructor(props) {
        super(props);

        this.state.departmentDialogOpen = false;
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleEditDepartment = () => {
            this.setState({ departmentDialogOpen: true });
        };

        return (
            <MUIGrid container spacing={ 2 } columns={ 12 }>
                <MUIGrid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <MUITextField
                        value={ this.props.team ? this.props.team.name : "" }
                        autoFocus={ true }
                        size="small"
                        label="Name"
                        variant="standard"
                        fullWidth={ true }
                        inputProps={ {
                            maxLength: 50,
                        } }
                        InputLabelProps={ { shrink: true } }
                        onBlur={ (e) => this.props.onChange({ name: e.target.value.trim() }) }
                        onChange={ (e) => this.props.onChange({ name: e.target.value }) }
                        required={ true }
                        error={ this.props.validationIssues.name ? true : false }
                        helperText={ this.props.validationIssues.name ? this.props.validationIssues.name : "" }
                    />
                </MUIGrid>
                <MUIGrid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <TileDepartment
                        department={ this.props.department }
                        iconButtons={[(<MUIIconButton onClick={handleEditDepartment} ><MUIEditIcon /></MUIIconButton>)]}
                        label="Department"
                        required={true}
                        error={ this.props.validationIssues.departmentId ? true : false }
                        helperText={ this.props.validationIssues.departmentId ? this.props.validationIssues.departmentId : "" }
                    />
                </MUIGrid>
                <DialogSearchDepartment
                    open={ this.state.departmentDialogOpen }
                    onClose={ () => this.setState({ departmentDialogOpen: false }) }
                    onSelect={ (department) => {
                        this.props.onChange({ departmentId: department.id });
                    } }
                />
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}