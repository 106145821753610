import React from "react";
import Card from "../../../Card";
import PropTypes from "prop-types";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import TileMarketingCampaignCategory from "../../../Tile/TileMarketingCampaignCategory";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUISwitch from "@mui/material/Switch";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
import DialogSearchMarketingCampaignCategory from "../../../Dialog/Search/DialogSearchMarketingCampaignCategory";

export default class CardAdminMarketingCampaignGeneral extends Card {
    static contextTypes = {
        marketingCampaignCategoriesIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        const marketingCampaignCategory = this.props.marketingCampaign ?
            this.context.marketingCampaignCategoriesIndexed[this.props.marketingCampaign.campaignTypeId] :
            null;

        const handleEditMarketingCampaignCategory = () => {
            this.setState({ marketingCampaignCategoryDialogOpen: true });
        };

        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={4}>
                        <MUITextField
                            value={this.props.marketingCampaign && this.props.marketingCampaign.name ? this.props.marketingCampaign.name : ""}
                            autoFocus={true}
                            size="small"
                            label="Name"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 255,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ name: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ name: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.name ? true : false}
                            helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={8}>
                        <MUITextField
                            value={this.props.marketingCampaign && this.props.marketingCampaign.description ? this.props.marketingCampaign.description : ""}
                            size="small"
                            label="Description"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 255,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ description: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ description: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.description ? true : false}
                            helperText={this.props.validationIssues.description ? this.props.validationIssues.description : ""}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={4}>
                        <TileMarketingCampaignCategory
                            required={true}
                            error={!!this.props.validationIssues.campaignTypeId}
                            helperText={this.props.validationIssues.campaignTypeId ? this.props.validationIssues.campaignTypeId : null}
                            marketingCampaignCategory={marketingCampaignCategory}
                            label="Campaign Category"
                            iconButtons={[
                                (<MUIIconButton onClick={handleEditMarketingCampaignCategory}><MUIEditIcon /></MUIIconButton>),
                            ]}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <MUIFormControlLabel
                            control={<MUISwitch/>}
                            onChange={(e) => this.props.onChange({ isActive: e.target.checked })}
                            label="Active"
                            checked={this.props.marketingCampaign ? this.props.marketingCampaign.isActive : false}
                        />
                    </MUIGrid>
                </MUIGrid>
                <DialogSearchMarketingCampaignCategory
                    open={this.state.marketingCampaignCategoryDialogOpen}
                    onClose={() => this.setState({ marketingCampaignCategoryDialogOpen: false })}
                    onSelect={(marketingCampaignCategory) => {
                        this.props.onChange({ campaignTypeId: marketingCampaignCategory.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}