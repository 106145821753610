import React from "react";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import TileTeam from "../../../Tile/TileTeam";
import MUIIconButton from "@mui/material/IconButton";
import MUIOpenInNewIcon from "@mui/icons-material/OpenInNew";
import EmptyState from "../../../EmptyState";
export default class CardAdminDepartmentTeams extends Card {
    /**
     * Renders a list of teams or an empty state if there are none.
     */
    renderContent() {
        if (this.props.teams && this.props.teams.length === 0) {
            return (
                <EmptyState
                    line1="No Teams"
                    line2="Set departments from the Team Admin page"
                />
            );
        } else {
            return (
                <MUIGrid container spacing={2}>
                    {this.props.teams && this.props.teams.map((team) => {
                        const handleClick = () => {
                            this.props.history.push({
                                pathname: "/admin/team/" + team.id
                            });
                        };

                        return (
                            <MUIGrid key={team.id} item xs={12} sm={6} md={4}>
                                <TileTeam
                                    team={team}
                                    iconButtons={[(<MUIIconButton onClick={handleClick}><MUIOpenInNewIcon /></MUIIconButton>)]}
                                />
                            </MUIGrid>
                        );
                    })}
                </MUIGrid>
            );
        }
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Teams";
    }
}