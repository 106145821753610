import Admin from "../Admin";
import React from "react";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import CardAdminSalesPcrLinkGeneral from "../../../Component/Card/Admin/SalesPcrLink/CardAdminSalesPcrLinkGeneral";
import SalesPcrLinkService from "../../../Seating/Security/SalesPcrLinkService/salesPcrLinkService";
import Validator from "../../../Utility/Validator";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminSalesPcrLinkCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.isUpdate = !!props.match.params.id;
        this.state.salesPcrLink = {
            name: "nothing"
        };
        this.state.isProcessing = false;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Set the Sales PCR Link in state.
     */
    afterComponentDidMount() {
        const salesPcrLinkId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (salesPcrLinkId !== null) {
            this.setState({
                salesPcrLink: this.context.salesPcrLinksIndexed[salesPcrLinkId],
            });
        }
    }

    /**
     * Creates the Sales PCR Link with the current properties in state.salesPcrLink.
     */
    async createSalesPcrLink() {
        this.setState({
            isProcessing: true,
        });

        return SalesPcrLinkService.createSalesPcrLink(this.state.salesPcrLink)
            .then(salesPcrLink => {
                this.context.updateIndexed("salesPcrLinksIndexed", salesPcrLink);
            })
            .then(() => {
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Sales PCR Link created successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Sales PCR Link", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
    * Updates the Sales PCR Link with the current properties in state.salesPcrLink.
     */
    async updateSalesPcrLink() {
        this.setState({
            isProcessing: true,
        });

        return SalesPcrLinkService.updateSalesPcrLink(this.state.salesPcrLink)
            .then(salesPcrLink => {
                this.context.updateIndexed("salesPcrLinksIndexed", salesPcrLink);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Sales PCR Link updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Sales PCR Link.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Delete the Sales PCR Link.
     */
    async deleteSalesPcrLink() {
        this.setState({
            isProcessing: true,
        });

        return SalesPcrLinkService.deleteSalesPcrLink(this.state.salesPcrLink.id)
            .then(() => {
                this.context.deleteIndexed("salesPcrLinksIndexed", this.state.salesPcrLink.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Sales PCR Link deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Sales PCR Link", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChangeSalesPcrLink = (salesPcrLink) => {
            this.setState({
                salesPcrLink: {
                    ...this.state.salesPcrLink,
                    ...salesPcrLink
                },
            });
        };

        return (
            <>
                <CardAdminSalesPcrLinkGeneral
                    salesPcrLink={this.state.salesPcrLink}
                    onChange={handleChangeSalesPcrLink}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteSalesPcrLink()}
                    text="Are you sure you want to delete this Sales PCR Link? This cannot be undone."
                    header="Delete Sales PCR Link"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * @returns Page header
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Sales PCR Link';
            subtitle = null;
        } else {
            title = 'Edit Sales PCR Link';
            subtitle = null;
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleSave = () => {
            const validationIssues = Validator.validate(
                this.state.salesPcrLink,
                {
                    salesRepId: {
                        'required': true
                    },
                    patientCareRepId: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateSalesPcrLink();
                } else {
                    this.createSalesPcrLink();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }
        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
