import React from "react";
import PropTypes from "prop-types";
import Card from "../../Card";
import MUIGreenColor from "@mui/material/colors/green";
import Table from "../../Table";
import BaseUnit from "../../../Utility/Crud/BaseUnit";
import Vendor from "../../../Utility/Crud/Vendor";
import ProductType from "../../../Utility/Crud/ProductType";
import BaseUnitType from "../../../Utility/Crud/BaseUnitType";

export default class CardApprovalApprovedEquipment extends Card {
    static contextTypes = {
        productTypesIndexed: PropTypes.object,
        productSubTypesIndexed: PropTypes.object,
        baseUnitsIndexed: PropTypes.object,
        baseUnitTypesIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        const filteredApprovedProducts = this.props.products.filter((product) => product.approved === true)
        filteredApprovedProducts.map((product) => {
            if (product.baseUnitId) {
                const baseUnit = this.context.baseUnitsIndexed[product.baseUnitId]
                const vendorName = Vendor.getDisplayName(this.context.vendorsIndexed[baseUnit.vendorId])
                const baseUnitName = BaseUnit.getDisplayName(this.context.baseUnitsIndexed[product.baseUnitId]);
                product.productSubTypeName = `${vendorName} ${baseUnitName}`;
                product.category = {
                    component: 'ChipBaseUnitType',
                    id: baseUnit.baseUnitTypeId,
                    value: BaseUnitType.getDisplayName(this.context.baseUnitTypesIndexed[baseUnit.baseUnitTypeId])
                }
            } else {
                product.category = {
                    component: 'ChipProductType',
                    id: product.productTypeId,
                    value: ProductType.getDisplayName(this.context.productTypesIndexed[product.productTypeId]),
                };
                product.productSubTypeName = product.productSubTypeId && this.context.productSubTypesIndexed[product.productSubTypeId]
                    ? this.context.productSubTypesIndexed[product.productSubTypeId].name
                    : null;
            }
        })
        return (
            <Table
                columns={[
                    { key: "category", name: "Category", type: "custom", width: "150" },
                    { key: "productSubTypeName", name: "Product", type: "string", width: "200"},
                    { key: "serial", name: "Serial", type: "string", width: "150" },
                    { key: "warranty", name: "Warranty", type: "boolean", icon: "Check", color: MUIGreenColor[500], width: "100" },
                    { key: "delivered", name: "Delivered", type: "boolean", icon: "Check", color: MUIGreenColor[500], width: "100" },
                ]}
                rows={filteredApprovedProducts}
            />
        )
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Approved Equipment";
    }
}