import Layer from "../../../Layer";
import moment from "moment";
import React from "react";
import PageHeader from "../../../Component/PageHeader";
import CardReportSettings from "../../../Component/Card/Report/CardReportSettings";
import ReverseQualityService from "../../../Seating/Settings/reverseQualityService";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import CardReportReverseQualityResults from "../../../Component/Card/Report/ReverseQuality/CardReportReverseQualityResults";
import User from "../../../Utility/Crud/User";

export default class ReportReverseQuality extends Layer {

    /**
     * Fetches and processes reverse quality logs, updating the component's
     * state with the results.
     *
     * @param {Object} settings - Configuration settings for the metrics
     * request.
     */
    async getReverseQuality(settings) {
        this.setState({ loading: true })

        let transformedSettings = {
            start: settings.dateRange.beginDate,
            end: settings.dateRange.endDate,
        };

        if (
            settings?.multiUserByLocation?.userprofileIds?.length === 0 &&
            settings?.multiUserByLocation?.locationIds?.length > 0
        ) {
            // If a location was selected but no users, choose all the users at
            // that location.
            transformedSettings.users = User.read(
                this.context.usersIndexed,
                [
                    {
                        key: "active",
                        value: true
                    },
                    {
                        key: "id",
                        value: Object.values(this.context.userprofileLocationsIndexed)
                            .filter((userprofileLocation) => {
                                return settings.multiUserByLocation.locationIds.includes(userprofileLocation.locationsId)
                            })
                            .map((userProfileLocation) => {
                                return userProfileLocation.userprofileId
                            })
                    }
                ]
            ).map(userprofile => userprofile.id).join(",");
        } else if(settings?.multiUserByLocation?.userprofileIds?.length > 0) {
            transformedSettings.users = settings.multiUserByLocation.userprofileIds.join(",");
        }

        return ReverseQualityService.getReverseQualityLogs(transformedSettings)
            .then((reverseQualityLogs) => {
                const transformedRows = reverseQualityLogs.map((entry) => {
                    const { id, orderId, reportedBy, reportedOn, userReported, reviewedBy, reviewedOn, reverseQualityCategory, reverseQualityReason, notes } = entry;

                    const formattedReportedDetails = reportedBy + " " + moment.utc(reportedOn).format("M/D/YYYY");
                    const formattedReviewedDetails = reviewedBy && reviewedOn ? reviewedBy + " " + new Date(reviewedOn).toLocaleDateString('en-US') : null;

                    return {
                        id: id,
                        orderId: orderId,
                        reportedDetails: formattedReportedDetails,
                        reportedBy: reportedBy,
                        reportedOn: moment.utc(reportedOn).format("M/D/YYYY"),
                        userReported: userReported,
                        reviewedDetails: formattedReviewedDetails,
                        reviewedBy: reviewedBy ? reviewedBy : null,
                        reviewedOn: reviewedOn ? new Date(reviewedOn).toLocaleDateString('en-US') : null,
                        reverseQualityCategory: reverseQualityCategory,
                        reverseQualityReason: reverseQualityReason,
                        notes: notes,
                    };
                });

                this.setState({
                    rows: transformedRows,
                    loading: false,
                    loadedAt: new Date(),
                });
            })
            .catch(() => {
                this.setState({ loading: false });
                NotistackEnqueueSnackbar("Error retrieving results", { variant: "error" });
            });
    }


    /**
     * Function to retrieve a reverse quality log by its ID,
     * update the log with the current user's review details,
     * and update the corresponding row in the component's state.
     */
    retrieveAndUpdateReverseQualityLogById = (clickedRow) => {
        ReverseQualityService.getLogById(clickedRow.id)
            .then(reverseQualityLog => {
                reverseQualityLog.reviewedById = this.context.currentUser.id;
                reverseQualityLog.reviewedOn = new Date().toISOString();

                ReverseQualityService.editReverseQualityLog(reverseQualityLog)
                    .then(res => {
                        let updatedRows = this.state.rows.map(row => {
                            if (row.id === clickedRow.id) {
                                return {
                                    ...row,
                                    reviewedBy: `${this.context.currentUser.firstname} ${this.context.currentUser.lastname}`,
                                    reviewedOn: moment(new Date()).format("M/D/YYYY"),
                                    reviewedDetails: `${this.context.currentUser.firstname} ${this.context.currentUser.lastname} ${moment(new Date()).format("M/D/YYYY")}`
                                };
                            }
                            return row;
                        });

                        this.setState({
                            rows: updatedRows
                        });

                    });
            }).catch(e => {
                console.log(e);
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        return (
            <>
                <CardReportSettings
                    onRun={(settings) => this.getReverseQuality(settings)}
                    isLoading={this.state.loading}
                    settingComponents={{
                        dateRange: {
                            validationRules: {
                                beginDate: {
                                    required: true
                                },
                                endDate: {
                                    required: true
                                },
                            },
                        },
                        multiUserByLocation: {
                            validationRules: {}
                        },
                    }}
                />
                <CardReportReverseQualityResults
                    rows={this.state.rows}
                    isLoading={this.state.loading}
                    loadedAt={this.state.loadedAt}
                    onClickRowAction={this.retrieveAndUpdateReverseQualityLogById.bind(this)}
                />
            </>
        );
    }

    renderHeader() {
        return (
            <PageHeader title="Reverse Quality Logs" />
        );
    }

    getMaxWidth() {
        return 1600;
    }
}
