import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Col,
    MDBInput,
    Modal,
    Row,
    Spinner,
    toast,
    ToastContainer,
    MDBIcon,
    MDBCardFooter,
    MDBRow,
    MDBCol,
    ModalHeader, ModalBody, ModalFooter, MDBContainer, MDBBtn,
} from "mdbreact";
import "./faxView.css";
//import "./userEdit.css";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import userService from "../Security/UserService/userService";
import orderService from "../Security/OrderService/orderService";
import DocumentTypesService from "../Settings/documentTypesService";
import LayerLegacy from "../../LayerLegacy";

export default class faxes extends LayerLegacy {
    constructor(props) {
        super(props);

        let user = {},
            isLoaded = false;

        //this.retrieveUser(id);
        this.setState({
            isLoaded: true,
        });

        this.state = {
            faxState: "Select Fax",
            confirmationModal: false,
            thumbnails: [],
            faxes: [],
            selectedFax: [],
            patientName: "",
            orders: [],
            selectedOrder: "",
            documentType: "",
            isLoaded: isLoaded,
            user: user,
            eUser: JSON.parse(JSON.stringify(user)),
            currentUser: null,
            selectedUser: null,
            allLocations: [],
            salesReps: [],
            documentTypes: [],
            otherDocType: "",
            selectedPages: [],
            rotateMode: false,
            hover: true,
            hideUnselected: false,
            dragId: "",

        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        documentTypes: PropTypes.array,
    };

    componentDidMount() {
        this.setState({
            currentUser: this.context.currentUser,
        });
        this.getDocumentTypes();
        this.getSalesReps();
    }

    confirmationToggle = () => {
        this.setState({
            confirmationModal: !this.state.confirmationModal,
        });
    };

    retrieveUser(id) {
        userService.getUser(id)
            .then((data) => {
                if (data.userprofileLocations === null) {
                    data.userprofileLocations = [];
                }
                this.setState({
                    user: data,
                    eUser: JSON.parse(JSON.stringify(data)),
                    userAccounts: data.accountOwner,
                    isLoaded: false,
                });
            });
    }

    getFaxes() {

        this.setState({
            faxes:[],
            selectedFax:[],
            thumbnails:[],
            selectedPages:[],
            faxState: "Loading Faxes"
        });
        let faxes = [],
            fs = "Select Fax";

        userService.getMyFaxes(this.state.selectedUser.value)
            .then((res) => {
                if (!res || res.length === 0) {
                    fs = "No Faxes";
                }
                else {
                    res.map(res => {
                        return faxes.push({
                            label: res.faxId + (res.datetimeRcvd ? " : Received  " + res.datetimeRcvd : ""),
                            value: res.id,
                            filename: res.filename,
                            faxId: res.faxId
                        });
                    });
                }

                this.setState({
                    faxes: faxes,
                    faxState: fs,
                });
            })
            .catch((err) => {
                this.setState({
                    faxState: "No Faxes",
                });
                console.log(err);
            });
    }

    getOrders() {
        this.setState({
            faxes:[],
            selectedFax:[],
            thumbnails:[],
            selectedPages:[],
        });
        this.getFaxes();
        let orders = [];

        let today = new Date().toLocaleDateString();
        let oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString();

        let f = {
            start: oneYearAgo,
            end: today,
            locType: "Sales",
            status: "0,1,2,3",
            users: this.state.selectedUser.value,
        };

        orderService.getOrderListing(f)
            .then((res) => {
                res.map(res => {
                    return orders.push({
                        label: res.patientName + " _ " + res.id,
                        value: res.id,
                    });
                });

                this.setState({
                    orders: orders,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getDocumentTypes() {
        const { documentTypes } = this.context;
        let documents = [];
        if (!documentTypes || documentTypes.length === 0) {

            DocumentTypesService.getAllDocumentTypes()
                .then((documentTypes) => {
                    sessionStorage.setItem("documentTypes", JSON.stringify(documentTypes));
                    documentTypes.map(res => {
                        return documents.push({
                            label: res.name,
                            value: res.id,
                        });
                    });
                    this.setState({
                        documentTypes: documents,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {


            documentTypes.map(res => {
                return documents.push({
                    label: res.name,
                    value: res.id,
                });
            });
            this.setState({
                documentTypes: documents,
            });
        }
    }

    getSalesReps() {
        let a = [];
        let currentUser = this.context.currentUser;
        a.push({
            label: currentUser.firstname + " " + currentUser.lastname,
            value: currentUser.id,
        });
        if (["PATIENT_CARE_REP", "SALES_MANAGER"].includes(currentUser.role)) {
            userService.getMyPcrReps(currentUser.id)
                .then(res => {
                    res.map(r => {
                        return a.push({
                            label: r.firstname + " " + r.lastname,
                            value: r.id,
                        });
                    });
                    this.setState({
                        salesReps: a,
                        isLoaded: true
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        }else {
            this.setState({
                salesReps: a,
                isLoaded: true
            });
        }
    }

    deleteFax(upload) {
        let message = "Fax Deleted";
        if (upload) { message += " After Upload"; }

        userService.removeFax(this.state.selectedFax.value)
            .then(r => {
                this.getFaxes();
                if(!upload){this.confirmationToggle();}
                toast.success("Fax Deleted");
            })
            .catch(e => {
                toast.warn(message);
                if(!upload){this.confirmationToggle();}
                console.log(e);
            });
    }

    uploadFax() {
        let documentType = "",
            pageIndices = [],
            rotatePageIndices = [],
            pages = this.state.thumbnails,
            order = this.state.selectedOrder,
            fax = this.state.selectedFax;

        pages.map(page => {
            if(page.included){
                pageIndices.push(page.value + 1);
                if(page.rotate){
                    return rotatePageIndices.push(page.value + 1);
                }
            }
            return null;
        });

        if (this.state.documentType === "Other") {
            documentType = this.state.otherDocType;
        } else {
            documentType = this.state.documentType;
        }

        let r = {
            orderId: order,
            uploadedById: this.context.currentUser.id,
            documentType: documentType,
            pages: [{
                faxId: fax.value,
                pageIndices: pageIndices,
                rotatePageIndices: rotatePageIndices,
            }]
        };
        userService.mergeAndUploadFax(r)
            .then(r => {
                toast.success("Fax Uploaded");
                this.deleteFax("upload");
            })
            .catch(e => {
                toast.warn("There Was An Issue Uploading The Fax");
                console.log(e);
            });
    }

    downloadFax() {
        return userService.downloadFax(this.state.selectedFax.value)
            .then((res) => {
                console.log(res);
                let file = new File([res], this.state.selectedFax.filename);
                console.log(file);
                let url = window.URL.createObjectURL(file);
                let temp = document.createElement("a");
                temp.href = url;
                temp.setAttribute("download", this.state.selectedFax.filename);
                temp.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => {
                toast.warn("download Unsuccessful");
            });
    }

    async handleFaxImages(){
        let val = await this.getFaxImages(),
            fin = val.data;
        this.setState({
            thumbnails: fin,
            selectedPages: fin,
        });
    }

    async getFaxImages() {
        let id = this.state.selectedFax.value;

        return new Promise((resolve, reject) => {
            userService.getFaxImages(id)
                .then((res) => {
                    this.setState({
                        thumbnails: [],
                        selectedPages: [],
                    });
                    if (res) {
                        let obj = {
                            id: id,
                            data: res.map((r, index) => {
                                return {
                                    value: index,
                                    image: r,
                                    included: true,
                                    rotate: false,
                                };
                            }),
                        };
                        resolve(obj);
                    }
                })
                .catch((err) => {
                    // need index in case we can't get the actual image for the doc...BC
                    // let idx = tRef.state.selectedDocuments.indexOf(id);

                    toast.error(
                        "Unable to add document to packet. Ensure documents are of type '.pdf' "
                    );

                    // let sel = tRef.state.selectedDocuments;
                    //
                    // tRef.setState({
                    //     selectedDocuments: sel,
                    // });

                    resolve();
                });
        });
    }

    handleSelectChange(e, option) {
        this.setState({
            thumbnails: [],
            selectedPages: [],
        });
        if(option === "selectedUser") {
            this.setState({
                [option]: e,
            },
            this.getOrders,
            );
        }
        else{
            this.setState({
                [option]: e,
            },
            this.handleFaxImages,
            );
        }
    }

    selectChange(dat, option) {
        let selected = this.state.thumbnails;
        if (option === "Rotate" || this.state.rotateMode) {
            dat.rotate = !dat.rotate;
        } else {
            dat.included = !dat.included;
        }
        let y = selected.findIndex(v => {
            return v.value === dat.value;
        });
        selected[y] = dat;
        this.setState({
            selectedPages: selected,
        });
    }

    handleDrag = (e) => {
        console.log("Dragging..." + e.currentTarget.id);
        this.setState({
            dragId: e.currentTarget.id,
        });
    };

    handleDrop = (e) => {
        let thumbs = this.state.thumbnails;
        let  beingDragged = thumbs.findIndex(v => {
            return v.value.toString() === this.state.dragId;
        });
        let temp = thumbs[beingDragged];
        let current = thumbs.findIndex(v => {
            return v.value.toString() === e.currentTarget.id;
        });
        thumbs[beingDragged] = thumbs[current];
        thumbs[current] = temp;
        this.setState({
            thumbnails: thumbs,
        });
        console.log("Drop Zone!" + e.currentTarget.id);
    };

    renderThumbnail() {

        let thumbs = this.state.thumbnails,
            hover = this.state.hover,
            hideUnselected= this.state.hideUnselected;

        if (thumbs.length < 1) {
            return;
        }
        return thumbs.map((dat, index) => {
            let src = "data:image/jpeg;base64, " + dat.image;
            if (hideUnselected && !dat.included){
                return null;
            }

            let classname = "thumbhover";
            if ((index + 1) % 4 === 0) {
                classname = "thumbhoverend";
            }
            if(!hover){
                classname = "noZoom";
            }
            if (dat.rotate){
                classname += " rotateImage";
            }
            return (
                <Col key={index} md={"3"} className={"upload-thumbnail"} style={{marginBottom: "20px"}}>
                    <div className={classname}
                        draggable={true}
                        id={dat.value}
                        onDragOver={(ev) => ev.preventDefault()}
                        onDragStart={this.handleDrag}
                        onDrop={this.handleDrop}
                    >
                        <img
                            style={{
                                width: "100%",
                                height: "auto",
                                border: dat.included ? "2px solid green" : "2px solid red",
                            }}
                            onDragOver= {(e)=>{e.preventDefault();}}
                            onDragEnd = {(e)=>{e.preventDefault();}}
                            onDragLeave = {(e)=>{e.preventDefault();}}
                            onDrop = {(e)=>{e.preventDefault();}}
                            src={src}
                            onClick={() => this.selectChange(dat)}
                            data-for="preview"
                            data-place={"right"}
                            data-html="true"
                            alt={"Page " + index}
                        />
                    </div>
                    <MDBRow>
                        <MDBCol>
                            {dat.included  ?
                                <div onClick={() => this.selectChange(dat, "Select")}><MDBIcon icon="check"
                                    style={{fontSize: "1em", color: "green"}}/> Included
                                </div> :
                                <div onClick={() => this.selectChange(dat, "Select")}><MDBIcon  icon="times"
                                    style={{fontSize: "1em", color: "red"}}/> Not Included
                                </div>}
                        </MDBCol>
                        <MDBCol onClick={() => this.selectChange(dat, "Rotate")}>
                            {dat.rotate  ?
                                <div style={{justifyContent:"right", display:"flex"}}> Rotated
                                    <MDBIcon icon="sync"
                                        style={{fontSize: "1em", paddingTop:"2px", marginLeft: "3px", color: "green"}}/>
                                </div> :
                                <div style={{justifyContent:"right", display:"flex"}}>
                                    <MDBIcon icon="sync"
                                        style={{fontSize: "1em", paddingTop:"2px", marginLeft: "3px", color: "black"}}/>
                                </div>
                            }</MDBCol>
                    </MDBRow>
                    <ReactTooltip id={"preview"} html={true}/>
                </Col>
            );
        });
    }

    renderConfirmationModal() {
        return (
            <Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
                <ModalHeader
                    style={{ backgroundColor: "#F93154", color: "white" }}
                    toggle={this.confirmationToggle}
                >
                    Confirm Delete
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderConfirmationModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Confirm Delete"}
                        onClick={() => this.deleteFax()}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.confirmationToggle}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderConfirmationModalBody() {
        return (
            <div>
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <MDBContainer>
                    <MDBRow>
                        Are you sure you want to delete this fax?
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }

    handleConfirmationClose = () => {
        let i = {
            id: -1,
            name: "",
            sortOrder: "",
        };
        this.setState({
            currInsurance: i,
            currIndex: -1,
            errorMessage: "",
            confirmationModal: !this.state.confirmationModal,
        });
    };

    renderFaxSelectCard() {
        let fs = this.state.faxState,
            disabled = (fs === "No Faxes" || fs === "Loading Faxes" || this.state.faxes.length === 0);

        return (
            <div>
                <Card>
                    <CardHeader color={"blue-grey"} className="blueHeader text-center">
                        <h2>Select Fax</h2>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>

                                <Select
                                    placeholder={"Select User"}
                                    options={this.state.salesReps}
                                    onChange={(e) => this.handleSelectChange(e, "selectedUser")}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: "20px"}}>
                            <Col>
                                <Select
                                    isDisabled={disabled}
                                    placeholder={this.state.faxState}
                                    options={this.state.faxes}
                                    value={this.state.selectedFax}
                                    onChange={(e) => this.handleSelectChange(e, "selectedFax")}
                                />
                            </Col>
                        </Row>
                        <br/>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderFaxDisplayCard() {
        return (
            <div>
                <Card style={{minHeight: "600px", marginBottom: "350px"}}>
                    <CardHeader color={"blue-grey"} className="blueHeader text-center">
                        <h2>Fax Display</h2>
                    </CardHeader>
                    <CardBody>
                        {this.state.thumbnails.length > 0 ? <Row>
                            <MDBBtn
                                outline={this.state.rotateMode}
                                rounded
                                color={"green"}
                                data-tip={"Rotate"}
                                onClick={() => this.setState(prevState => ({
                                    rotateMode: false
                                }))}
                                style={{fontSize: "15px", padding: "5px 15px 5px 15px"}}
                            >
                                Select/Un-select Images
                                <MDBIcon far icon="check-circle"
                                    style={{fontSize: "14px", paddingLeft: "3px", color: "black"}}/>
                            </MDBBtn>
                            <MDBBtn
                                outline={!this.state.rotateMode}
                                rounded
                                color={"green"}
                                data-tip={"Rotate"}
                                onClick={ () => this.setState(prevState => ({
                                    rotateMode: true
                                }))}
                                style={{fontSize: "15px", padding: "5px 15px 5px 15px"}}
                            >
                                Rotate Images
                                <MDBIcon icon="sync"
                                    style={{fontSize: "12px", paddingLeft: "3px", color: "black"}}/>
                            </MDBBtn>
                            <MDBBtn
                                outline
                                rounded
                                color={"green"}
                                data-tip={"Disable Zoom"}
                                onClick={() => this.setState(prevState => ({
                                    hover: !prevState.hover
                                }))}
                                style={{fontSize: "15px", padding: "5px 15px 5px 15px"}}
                            >
                                {this.state.hover ? "Disable Zoom" : "Enable Zoom"}
                                <MDBIcon icon="search"
                                    style={{fontSize: "12px", paddingLeft: "3px", color: "black"}}/>
                            </MDBBtn>
                            <MDBBtn
                                outline
                                rounded
                                color={"green"}
                                data-tip={"Hide/Show"}
                                onClick={() => this.setState(prevState => ({
                                    hideUnselected: !prevState.hideUnselected
                                }))}
                                style={{fontSize: "15px", padding: "5px 15px 5px 15px"}}
                            >
                                {this.state.hideUnselected ? "Show Unselected" : "Hide Unselected"}
                                <MDBIcon far icon={this.state.hideUnselected ? "eye" : "eye-slash"}
                                    style={{fontSize: "14px", paddingLeft: "3px", color: "black"}}/>
                            </MDBBtn>
                        </Row> : ""}
                        <Row style={{marginTop: "1%", minHeight: "140px"}} className={"thumbRow"}>
                            {this.renderThumbnail()}
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }

    validateUpload(){
        if(this.state.selectedOrder && this.state.selectedFax && this.state.documentType){
            if(this.state.documentType === "Other"){
                if(this.state.otherDocType !== ""){
                    return false;
                }else
                    return true;
            }else {
                return false;
            }
        }else{
            return true;
        }
    }

    handleOrderNameChange(order){
        this.setState({
            patientName: order,
        });
    }

    handleOrderChange(order){
        this.setState({
            patientName: order.label.substr(0, order.label.lastIndexOf(" _")),
            selectedOrder: order.value,
        });
    }

    handleDocumentTypeChange = (e) => {
        this.setState({
            documentType: e.label,
        });
    };



    handleOtherDoc(e) {
        this.setState({otherDocType: e.target.value});
    }

    renderUploadFaxCard() {
        let st = this.state;

        return (
            <div>
                <ReactTooltip/>
                <Card>

                    <CardHeader color={"blue-grey"} className="blueHeader text-center">
                        <h2>Upload Fax</h2>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <Select
                                    placeholder={"Select Order"}
                                    style={{maxHeight: "100px"}}
                                    options={this.state.orders}
                                    onChange={(e) => this.handleOrderChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <MDBCol size={"12"} style={{marginTop: "20px"}}>
                                <Select
                                    options={this.state.documentTypes}
                                    placeholder={"Select Document Type"}
                                    onChange={(e) => this.handleDocumentTypeChange(e)}
                                    label={"Select Document Type"}
                                >
                                </Select>
                            </MDBCol>

                        </Row>
                        <Row>
                            {st.documentType === "Other" ? (
                                <MDBCol size="12" style={{marginTop: "20px"}} className={"attachmentsOtherField"}>
                                    <MDBInput
                                        outline
                                        style={{marginTop: 2, marginBottom: 2}}
                                        onChange={this.handleOtherDoc.bind(this)}
                                        valueDefault={st.otherDocType}
                                        label="Document Name"
                                    />
                                </MDBCol>
                            ) : (
                                <div/>
                            )}
                        </Row>
                    </CardBody>
                    <MDBCardFooter>
                        <Row className={"faxButtons"}>
                            <Col size="5">
                                <Button
                                    className={"faxButtons"}
                                    floating
                                    size="sm"
                                    color={"green"}
                                    data-tip={"Download"}
                                    onClick={() => this.downloadFax()}
                                    disabled={this.state.selectedFax.length === 0}
                                >

                                    <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                                </Button>
                            </Col>
                            <Col size="5">
                                <Button
                                    className={"faxButtons"}
                                    floating
                                    size="sm"
                                    color={"red"}
                                    data-tip={"Delete"}
                                    onClick={() => this.confirmationToggle()}
                                    disabled={this.state.selectedFax.length === 0}
                                >
                                    <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                                </Button>
                            </Col>
                            <Col size="2">
                                <Button
                                    className={"faxButtons"}
                                    floating
                                    size="sm"
                                    color={"blue"}
                                    data-tip={"Upload & Delete"}
                                    onClick={() => this.uploadFax()}
                                    disabled={this.validateUpload()}
                                >
                                    <MDBIcon icon="upload" style={{fontSize: "2em"}}/>
                                </Button>
                            </Col>
                        </Row>
                    </MDBCardFooter>
                </Card>
            </div>
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }

        return (
            <div>
                <div className={"backgroundFax"}></div>

                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Container className=" w-100" style={{maxWidth: "100%"}}>
                    <Row>
                        <Col md={"3"}>
                            <Row style={{paddingTop: "20px"}}>
                                <Col>{this.renderFaxSelectCard()}</Col>
                            </Row>
                            <Row style={{paddingTop: "20px"}}>
                                <Col>{this.renderUploadFaxCard()}</Col>
                            </Row>
                        </Col>
                        <Col style={{paddingTop: "20px"}}>
                            {this.renderFaxDisplayCard()}
                            {this.renderConfirmationModal()}
                        </Col>
                    </Row>
                </Container>

            </div>

        );
    }
}
