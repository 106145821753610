import Admin from "../Admin";
import React from "react";
import CardAdminUserProductionTypeList from "../../../Component/Card/Admin/UserProductionType/CardAdminUserProductionTypeList";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIAddIcon from "@mui/icons-material/Add";

export default class AdminUserProductionTypeList extends Admin {
    /**
     * Render the content.
     */
    renderContent() {
        return (
             <CardAdminUserProductionTypeList/>
        );
    }

    /**
     * Render the header.
     */
    renderHeader() {
        const handleClickCreate = () => {
            this.props.history.push({
                pathname: "/admin/userProductionType"
            });
        };

        const buttons = [
            (<MUIButton
                component="label"
                variant="contained"
                startIcon={<MUIAddIcon />}
                onClick={handleClickCreate}>Create New</MUIButton>),
        ];

        return (
            <PageHeader title="User Production Types" buttons={ buttons } />
        );
    }
}
