import api from "../api";

class MarketingCampaignCategoryService {
    async getAllMarketingCampaignCategories() {
        return new Promise((res, reject) => {
            api
                .get("/campaigns/category")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getMarketingCampaignCategoryById(id) {
        return new Promise((res, reject) => {
            api
                .get("/campaigns/category/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async createMarketingCampaignCategory(obj) {
        return new Promise((res, reject) => {
            api
                .post("/campaigns/category", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateMarketingCampaignCategory(obj) {
        return new Promise((res, reject) => {
            api
                .put("/campaigns/category", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async deleteMarketingCampaignCategory(id) {
        return new Promise((res, rej) => {
            return api.delete('/campaigns/category/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }




}

const marketingCampaignCategoryService = new MarketingCampaignCategoryService();

export default marketingCampaignCategoryService;

