import React from "react";
import PropTypes from "prop-types";
import MUIGrid from '@mui/material/Grid';
import MUIAutocomplete from "@mui/material/Autocomplete";
import MUITextField from '@mui/material/TextField';
import MUIChip from "@mui/material/Chip";
import Location from "../../Utility/Crud/Location";
import User from "../../Utility/Crud/User";
import ReportSetting from "../ReportSetting";

export default class ReportSettingMultiUserByLocation extends ReportSetting {
    static contextTypes = {
        locationsIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
        userprofileLocationsIndexed: PropTypes.object,
    };

    /**
     * Renders location dropdown for selecting multiple locations.
     */
    renderLocationDropdown() {
        const handleLocationChange = (e, locations) => {
            this.handleSettingChange("locationIds", locations.map((location) => location.id));
        }

        return (
            <MUIGrid item xs={12} sm={6} md={6}>
                <MUIAutocomplete
                    multiple
                    options={Location.sort(Object.values(this.context.locationsIndexed))}
                    value={Location.read(
                        this.context.locationsIndexed,
                        [{
                            key: "id",
                            value: this.state.settings.locationIds || []
                        }]
                    )}
                    getOptionLabel={(option) => Location.getDisplayName(option)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={handleLocationChange}
                    renderInput={(params) => {
                        return (
                            <MUITextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                label="Location"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            {!this.state.settings.locationIds || this.state.settings.locationIds.length === 0 ? (
                                                <MUIChip
                                                    label="All Locations"
                                                    sx={{ margin: "3px" }}
                                                    color="primary"
                                                />
                                            ) : null}
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                }}
                            />
                        );
                    }}
                />
            </MUIGrid>
        )
    }

    /**
     * Renders user dropdown for selecting multiple users.
     */
    renderUserDropdown() {
        const handleUserChange = (e, userprofiles) => {
            this.handleSettingChange("userprofileIds", userprofiles.map((userprofile) => userprofile.id));
        }

        let users;
        if (this.state.settings.locationIds?.length > 0) {
            // Limit user dropdown to users at the specified location(s).
            users = User.sort(
                User.read(
                    this.context.usersIndexed,
                    [
                        {
                            key: "active",
                            value: true
                        },
                        {
                            key: "id",
                            value: Object.values(this.context.userprofileLocationsIndexed)
                                .filter((userprofileLocation) => {
                                    return this.state.settings.locationIds.includes(userprofileLocation.locationsId)
                                })
                                .map((userProfileLocation) => {
                                    return userProfileLocation.userprofileId
                                })
                        }
                    ]
                )
            );
        } else {
            // Only limit the user dropdown to active users.
            users = User.sort(
                User.read(
                    this.context.usersIndexed,
                    [
                        {
                            key: "active",
                            value: true
                        }
                    ]
                )
            );
        }

        return (
            <MUIGrid item xs={12} sm={6} md={6}>
                <MUIAutocomplete
                    multiple
                    options={users}
                    getOptionLabel={(user) => User.getDisplayName(user)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={User.read(
                        this.context.usersIndexed,
                        [{
                            key: "id",
                            value: this.state.settings.userprofileIds || []
                        }]
                    )}
                    onChange={handleUserChange}
                    renderInput={(params) => (
                        <MUITextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            label="User"
                            variant="standard"
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {!this.state.settings.userprofileIds || this.state.settings.userprofileIds.length === 0 ? (
                                            <MUIChip
                                                label={this.state.settings.locationIds && this.state.settings.locationIds.length !== 0 ? "All Location Users" : "All Users"}
                                                sx={{ margin: "3px" }}
                                                color="primary"
                                            />
                                        ) : null}
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </MUIGrid>
        );
    }

    render() {
        return (
            <MUIGrid container spacing={1}>
                {this.renderLocationDropdown()}
                {this.renderUserDropdown()}
            </MUIGrid>
        );
    }

}