import Crud from "../Crud";
import MUIYellowColor from "@mui/material/colors/yellow";

/**
 * Static helper functions related to Hcpcs Codes.
 */
export default class HcpcsCode extends Crud {

    /**
     * @param {object} hcpcsCode The hcpcsCode object.
     * @returns The display code of the hcpcsCode.
     */
    static getDisplayName(hcpcsCode) {
        return hcpcsCode.code;
    }

    /**
     * Determines if a hcpcsCode matches a search query.
     *
     * @param {object} hcpcsCode The hcpcsCode to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the hcpcsCode matches the query.
     */
    static match(hcpcsCode, query) {
        return (
            (hcpcsCode.code !== null && hcpcsCode.code.toLowerCase().includes(query)) ||
            hcpcsCode.id === +query
        );
    }

    /**
     * @param {array} hcpcsCodes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(hcpcsCodes) {
        return hcpcsCodes.sort((a, b) => {
            return (a.code).localeCompare(b.code);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIYellowColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Code";
    }
}