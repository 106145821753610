import React from "react";
import {
    MDBTabContent,
    MDBTabPane,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import PurchaseQueueTable from "./purchaseQueueTable";
import InventoryReqeustTable from "./inventoryRequestTable";
import "./purchaseGrid.css";
import LayerLegacy from "../../LayerLegacy";

export default class purchaseQueueView extends LayerLegacy {
    constructor(props) {
        super(props);

        this.state = {
            activeItemJustified: "1",
        };
    }

    toggleJustified = (tab) => () => {
        if (this.state.activeItemJustified !== tab) {
            this.setState({
                activeItemJustified: tab,
            });
        }
    };

    renderContent() {
        let st = this.state;
        return (
            <div style={ { padding: '1rem' } }>
                <MDBNav tabs className="nav-justified purchaseNav" color="blue">
                    <MDBNavItem>
                        <MDBNavLink
                            link
                            to="#"
                            active={st.activeItemJustified === "1"}
                            onClick={this.toggleJustified("1")}
                            role="tab"
                        >
							Pending
                        </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink
                            link
                            to="#"
                            active={st.activeItemJustified === "2"}
                            onClick={this.toggleJustified("2")}
                            role="tab"
                        >
							Approved
                        </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink
                            link
                            to="#"
                            active={st.activeItemJustified === "3"}
                            onClick={this.toggleJustified("3")}
                            role="tab"
                        >
							Denied
                        </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink
                            link
                            to="#"
                            active={st.activeItemJustified === "4"}
                            onClick={this.toggleJustified("4")}
                            role="tab"
                        >
							Inventory Request
                        </MDBNavLink>
                    </MDBNavItem>
                </MDBNav>
                <MDBTabContent
                    className="card"
                    style={{ paddingTop: "1rem", margin: "2% 2% 0 2%"}}
                    activeItem={st.activeItemJustified}
                >
                    <MDBTabPane tabId="1" role="tabpanel">
                        {st.activeItemJustified === "1" ? (
                            <PurchaseQueueTable status={"PENDING"} />
                        ) : (
                            <div />
                        )}
                    </MDBTabPane>
                    <MDBTabPane tabId="2" role="tabpanel">
                        {st.activeItemJustified === "2" ? (
                            <PurchaseQueueTable status={"APPROVED"} />
                        ) : (
                            <div />
                        )}
                    </MDBTabPane>
                    <MDBTabPane tabId="3" role="tabpanel">
                        {st.activeItemJustified === "3" ? (
                            <PurchaseQueueTable status={"DENIED"} />
                        ) : (
                            <div />
                        )}
                    </MDBTabPane>
                    <MDBTabPane tabId="4" role="tabpanel">
                        {st.activeItemJustified === "4" ? (
                            <InventoryReqeustTable />
                        ) : (
                            <div />
                        )}
                    </MDBTabPane>
                </MDBTabContent>
            </div>
        );
    }
}
