import React from "react";
import DialogSearch from "../DialogSearch";
import TileLocation from "../../Tile/TileLocation";
import PropTypes from "prop-types";
import Location from "../../../Utility/Crud/Location";

export default class DialogSearchLocation extends DialogSearch {

    static contextTypes = {
        locationsIndexed: PropTypes.object,
    };

    /**
     * Searches for locations by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered location.
     */
    getSearchResults(query) {
        return Location.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.locationsIndexed);
        }
    }

    /**
     * @param {object} location The location object.
     * @returns A LocationTile component for this location.
     */
    renderTile(location) {
        return (
            <TileLocation
                key={ location.id }
                location={ location }
            />
        );
    }

    /**
     * @returns The maximum number of results that can be rendered. Set to
     * Infinity to remove the cap. Just be aware that rendering too many items
     * is not performant or generally useful.
     */
    getMaxResults() {
        return Infinity;
    }
}
