import api from "../api";

class UserProfileLocationService {
    async getAllUserProfileLocations() {
        return new Promise((res, reject) => {
            api
                .get("/userProfileLocations")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createLocationUser(obj) {
        return new Promise((res, reject) => {
            api
                .post("/users/userProfileLocations", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteLocationUser(id) {
        return new Promise((res, rej) => {
            return api.delete('/users/userProfileLocations/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const userProfileLocationService = new UserProfileLocationService();

export default userProfileLocationService;
