import React from "react";
import MUIBox from "@mui/material/Box";
import Layer from "../../Layer";
import CardQueueFiltersSidebar from "../../Component/Card/Queue/CardQueueFiltersSidebar";
import CardQueueDataGrid from "../../Component/Card/Queue/CardQueueDataGrid";
import Setting from "../../Utility/Setting";
import QueueService from "../../Seating/Security/QueueService/queueService";
import CardQueueDataGridOrderActivity from "../../Component/Card/Queue/CardQueueDataGrid/CardQueueDataGridOrderActivity";
import CardQueueDataGridOrderActivitySales from "../../Component/Card/Queue/CardQueueDataGrid/CardQueueDataGridOrderActivity/CardQueueDataGridOrderActivitySales";
import CardQueueDataGridPatientIssue from "../../Component/Card/Queue/CardQueueDataGrid/CardQueueDataGridPatientIssue";
import CardQueueDataGridPendingPreauth from "../../Component/Card/Queue/CardQueueDataGrid/CardQueueDataGridPendingPreauth";
import CardQueueDataGridBrightreeInvoice from "../../Component/Card/Queue/CardQueueDataGrid/CardQueueDataGridBrightreeInvoice";
import User from "../../Utility/Crud/User";
import Security from "../../Utility/Security";

export default class Queue extends Layer {
    constructor(props) {
        super(props);
        this.state.sidebarCollapsed = Setting.get("queue.sidebarCollapsed") === true ? true : false;
    }

    /**
     * Set the data in the state.
     */
    afterComponentDidMount() {
        const queueId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (queueId !== null) {
            this.setState({
                queue: this.context.queuesIndexed[queueId],
            });
            this.getQueueRows(queueId);
            this.getQueueStatistics(queueId);
        }

        document.addEventListener(
            "visibilitychange",
            this.handleVisibilityChange.bind(this)
        );
    }

    /**
     * Remove the visibilitychange event handler.
     */
    componentWillUnmount() {
        document.removeEventListener(
            "visibilitychange",
            this.handleVisibilityChange.bind(this)
        );
    }

    /**
     * Get prefilters based on the settings.
     *
     * @param {number} id The ID to get prefilters for.
     * @returns {array}
     */
    getPrefilters() {
        const prefilters = [];

        if (
            Security.hasPermission("queue.useRegionPrefilter") === false &&
            Security.hasPermission("queue.useLocationPrefilter") === false &&
            Security.hasPermission("queue.useUserPrefilter") === false &&
            Security.hasPermission("queue.useMarketingCampaignPrefilter") === false
        ) {
            return prefilters;
        }

        /**
         * For all of these prefilters, we default to using the user's chosen
         * setting (if one is set). If the user did not explicitly pick one,
         * then it defaults to all that they have access to. However, if the
         * user has access to all, then just don't set a filter at all to avoid
         * sending hundreds of IDs in a filter.
         */

        // Region
        const filteredRegions = User.getFilteredRegions(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.regionsIndexed,
            this.context.locationsIndexed
        ).filter((region) => {
            if (Security.hasPermission("user.viewAllRegions") === true) {
                return true;
            } else {
                // Filter only regions with a type available to my prefilters
                return Object.values(this.context.teamUsersIndexed).some((teamUser) => {
                    if (teamUser.userId === this.context.currentUser.id) {
                        const team = this.context.teamsIndexed[teamUser.teamId];
                        if (team && team.departmentId) {
                            const department = this.context.departmentsIndexed[team.departmentId];
                            return (
                                department.jsonPrefilters?.regionTypeTermId === region.regionTypeTermId
                            );
                        }
                    }

                    return false;
                });
            }
        });
        const filteredRegionIds = filteredRegions.map(region => region.id);

        let prefilterRegionIds = null;
        if (Setting.get("queue.prefilters.regionIds")) {
            prefilterRegionIds = Setting.get("queue.prefilters.regionIds").filter(regionId =>
                filteredRegionIds.includes(regionId)
            );
            if (prefilterRegionIds.length === 0) {
                prefilterRegionIds = filteredRegionIds;
            }
        } else {
            if (!Security.hasPermission("user.viewAllRegions")) {
                prefilterRegionIds = filteredRegionIds;
            }
        }

        // Location
        const filteredLocations = User.getFilteredLocations(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.locationsIndexed
        );
        const filteredLocationIds = filteredLocations.map(location => location.id);

        let prefilterLocationIds = null;
        if (Setting.get("queue.prefilters.locationIds")) {
            prefilterLocationIds = Setting.get("queue.prefilters.locationIds").filter(locationId =>
                filteredLocationIds.includes(locationId)
            );
            if (prefilterLocationIds.length === 0) {
                prefilterLocationIds = filteredLocationIds;
            }
        } else {
            if (!Security.hasPermission("user.viewAllLocations")) {
                prefilterLocationIds = filteredLocationIds;
            }
        }

        // User
        const filteredUsers = User.getFilteredUsers(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.locationsIndexed,
            this.context.usersIndexed,
            this.context.salesPcrLinksIndexed
        );
        const filteredUserIds = filteredUsers.map(user => user.id);

        let prefilterUserIds = null;
        if (Setting.get("queue.prefilters.userIds")) {
            prefilterUserIds = Setting.get("queue.prefilters.userIds").filter(userId =>
                filteredUserIds.includes(userId)
            );
            if (prefilterUserIds.length === 0) {
                prefilterUserIds = filteredUserIds;
            }
        } else {
            if (!Security.hasPermission("user.viewAllUsers")) {
                prefilterUserIds = filteredUserIds;
            }
        }

        // Marketing Campaign
        const filteredMarketingCampaignIds = Object.values(this.context.marketingCampaignsIndexed)
            .map(marketingCampaign => marketingCampaign.id);

        let prefilterMarketingCampaignIds = null;
        if (
            Setting.get("queue.prefilters.marketingCampaignIds") &&
            Setting.get("queue.prefilters.marketingCampaignIds").length > 0
        ) {
            prefilterMarketingCampaignIds = Setting.get("queue.prefilters.marketingCampaignIds").filter(marketingCampaignId =>
                filteredMarketingCampaignIds.includes(marketingCampaignId)
            );
            if (prefilterMarketingCampaignIds.length === 0) {
                prefilterMarketingCampaignIds = filteredMarketingCampaignIds;
            }
        }

        // Set up the prefilter object.
        if (
            prefilterRegionIds &&
            Security.hasPermission("queue.useRegionPrefilter")
        ) {
            let key;
            if (Setting.get("queue.prefilters.regionTypeTermId") === 11) { // Sales
                key = "order_activity.sales_location.region_id"
            } else if (Setting.get("queue.prefilters.regionTypeTermId") === 12) { // Field Operations
                key = "order_activity.service_location.field_operations_region_id"
            } else {
                throw new Error(`Invalid queue.prefilters.regionTypeTermId: ${Setting.get("queue.prefilters.regionTypeTermId")}`);
            }

            prefilters.push({
                Key: key,
                Value: prefilterRegionIds.map(value => String(value)),
            });
        }

        if (
            prefilterLocationIds &&
            Security.hasPermission("queue.useLocationPrefilter")
        ) {
            let key;
            if (Setting.get("queue.prefilters.regionTypeTermId") === 11) { // Sales
                key = "order_activity.sales_location_id"
            } else if (Setting.get("queue.prefilters.regionTypeTermId") === 12) { // Field Operations
                key = "order_activity.service_location_id"
            } else {
                throw new Error(`Invalid queue.prefilters.regionTypeTermId: ${Setting.get("queue.prefilters.regionTypeTermId")}`);
            }

            prefilters.push({
                Key: key,
                Value: prefilterLocationIds.map(value => String(value)),
            });
        }

        if (
            prefilterUserIds &&
            Security.hasPermission("queue.useUserPrefilter")
        ) {
            prefilters.push({
                Key: "order_activity.activity.owner_id",
                Value: prefilterUserIds.map(value => String(value)),
            });
        }

        if (
            prefilterMarketingCampaignIds &&
            Security.hasPermission("queue.useMarketingCampaignPrefilter")
        ) {
            prefilters.push({
                Key: "order_activity.marketing_campaign_id",
                Value: prefilterMarketingCampaignIds.map(value => String(value)),
            });
        }

        return prefilters;
    }

    /**
     * API call for getting queue data by id.
     */
    async getQueueRows(id) {
        const args = {
            id: id,
            prefilters: this.getPrefilters(),
        };

        this.setState({ loadedAt: null, loadFailed: false });

        return QueueService.getQueueRows(args)
            .then((rows) => {
                this.setState({
                    rows: rows,
                    queueDirty: false,
                    loadedAt: new Date(),
                    query: Setting.get(`queue.${id}.searchQuery`) ? Setting.get(`queue.${id}.searchQuery`) : "",
                });
            })
            .catch((error) => {
                this.setState({
                    loadFailed: true,
                });
                console.error(error);
            });
    }

    /**
     * API call for getting queue data history by id, start date, and end date.
     */
    async getQueueStatistics(id) {
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);

        const args = {
            startDate: startDate.toLocaleDateString(),
            prefilters: this.getPrefilters(),
        };

        if (args.prefilters && args.prefilters.length > 0) {
            args.prefilters = JSON.stringify(args.prefilters);
        }

        return QueueService.getQueueStatistics(id, args)
            .then((stats) => {
                this.setState({
                    queueStatistics: stats,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * Reload the queue when the tab becomes visible again if it's dirty.
     */
    handleVisibilityChange() {
        if (
            document.hidden === false &&
            this.state.queueDirty === true
        ) {
            this.getQueueRows(this.state.queue.id);
        }
    };

    /**
     * Renders page content.
     */
    renderContent() {
        let CardQueueDataGridComponent;
        switch (this.state.queue.table) {
            case "order_activity":
                CardQueueDataGridComponent = CardQueueDataGridOrderActivity;
                break;
            case "order_activity.sales":
                CardQueueDataGridComponent = CardQueueDataGridOrderActivitySales;
                break;
            case "patient_issue":
                CardQueueDataGridComponent = CardQueueDataGridPatientIssue;
                break;
            case "pending_preauth":
                CardQueueDataGridComponent = CardQueueDataGridPendingPreauth;
                break;
            case "brightree_invoice":
                CardQueueDataGridComponent = CardQueueDataGridBrightreeInvoice;
                break;
            default:
                CardQueueDataGridComponent = CardQueueDataGrid;
                break;
        }

        return (
            <>
                <MUIBox sx={{ height: "calc(100vh - 80px)", marginLeft: 1, marginRight: 1 }}>
                    <MUIBox sx={{ display: "flex", height: "100%", alignItems: "stretch", gap: 2 }}>
                        <MUIBox sx={ { width: this.state.sidebarCollapsed ? "60px" : "260px" } }>
                            <CardQueueFiltersSidebar
                                rows={this.state.rows}
                                queue={this.state.queue}
                                loadedAt={this.state.loadedAt}
                                collapsed={this.state.sidebarCollapsed}
                                onCollapse={(collapsed) => {
                                    Setting.set("queue.sidebarCollapsed", collapsed);
                                    this.setState({
                                        sidebarCollapsed: collapsed
                                    });
                                }}
                                onPrefilterChange={() => {
                                    this.getQueueStatistics(this.state.queue.id);
                                    this.getQueueRows(this.state.queue.id);
                                }}
                                viewOutOfBoundsOnly={this.props.history.location.state && this.props.history.location.state.viewOutOfBoundsOnly ? this.props.history.location.state.viewOutOfBoundsOnly : null}
                                queueStatistics={this.state.queueStatistics}
                            />
                        </MUIBox>

                        {/* The goofy margin/padding here is necessary because the overflow breaks the box shadow on the card inside the box. */ }
                        <MUIBox sx={{ flexGrow: 1, overflowX: "hidden", margin: "-8px", padding: "8px" }}>
                            <CardQueueDataGridComponent
                                rows={this.state.rows}
                                queue={this.state.queue}
                                loadedAt={this.state.loadedAt}
                                loadFailed={this.state.loadFailed}
                                onReload={() => this.getQueueRows(this.state.queue.id)}
                                onClickRow={() => this.setState({ queueDirty: true })}
                                viewOutOfBoundsOnly={this.props.history.location.state && this.props.history.location.state.viewOutOfBoundsOnly ? this.props.history.location.state.viewOutOfBoundsOnly : null}
                                history={this.props.history}
                                query={this.state.query}
                            />
                        </MUIBox>
                    </MUIBox>
                </MUIBox>
            </>
        );
    }
}
