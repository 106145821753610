import React from "react";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import MUIAlert from "@mui/material/Alert";
import MUISwitch from "@mui/material/Switch";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
import MUITextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Queue from "../../../../Utility/Crud/Queue";

export default class CardAdminQueueThresholds extends Card {
    static contextTypes = {
        termsIndexed: PropTypes.object
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        const getNumber = (string) => {
            if (string.trim() === '') return null;
            const number = +string.trim();
            return isNaN(number) ? string : number;
        };

        return (
            <>
                <MUIGrid container spacing={2} columns={12}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <MUITextField
                            value={this.props.queue && this.props.queue.thresholdSize !== null && this.props.queue.thresholdSize !== undefined ? this.props.queue.thresholdSize : ""}
                            size="small"
                            label="Size"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 5,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => this.props.onChange({ thresholdSize: getNumber(e.target.value) })}
                            error={this.props.validationIssues.thresholdSize ? true : false}
                            helperText={this.props.validationIssues.thresholdSize ? this.props.validationIssues.thresholdSize : ""}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <MUITextField
                            value={this.props.queue && this.props.queue.thresholdAgeHours !== null && this.props.queue.thresholdAgeHours !== undefined ? this.props.queue.thresholdAgeHours : ""}
                            size="small"
                            label="Age (Hours)"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 3,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => this.props.onChange({ thresholdAgeHours: getNumber(e.target.value) })}
                            error={this.props.validationIssues.thresholdAgeHours ? true : false}
                            helperText={this.props.validationIssues.thresholdAgeHours ? this.props.validationIssues.thresholdAgeHours : ""}
                        />
                        {this.props.queue && this.props.queue.thresholdAgeHours !== null && this.props.queue.thresholdAgeHours !== undefined && (
                            <MUIFormControlLabel
                                control={<MUISwitch />}
                                onChange={(e) => this.props.onChange({ thresholdAgeHoursIsBusiness: e.target.checked })}
                                label="Business Hours"
                                checked={this.props.queue.thresholdAgeHoursIsBusiness}
                            />
                        )}
                    </MUIGrid>
                    {Queue.getOutOfBoundsString(this.props.queue) && (
                        <MUIGrid item xs={12}>
                            <MUIAlert severity="info">
                                OOB after {Queue.getOutOfBoundsString(this.props.queue)}.
                            </MUIAlert>
                        </MUIGrid>
                    )}
                </MUIGrid>
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Thresholds";
    }
}