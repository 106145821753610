import TermCreateEdit from "../AdminTermCreateEdit";

export default class AdminTermCreateEditServicePartCatalogCategory extends TermCreateEdit {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'service_part_catalog_category';
    }
}
