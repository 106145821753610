import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminIssueReasonList extends Card {
    static contextTypes = {
        issueReasonsIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "issueCategoryId", name: "Issue Category", type: "issueCategory", filter: true, width: 200 },
                    { key: "name", name: "Name", type: "string" },
                ]}
                sort={[
                    {
                        key: "issueCategoryId",
                        direction: "ascending",
                        type: "issueCategory",
                    },
                    {
                        key: "name",
                        direction: "ascending",
                    },
                ]}
                rows={Object.values(this.context.issueReasonsIndexed)}
                onClick={(issueReason) => {
                    this.props.history.push({
                        pathname: "/admin/issueReason/" + issueReason.id
                    });
                }}
            />
        );
    }
});