/**
 * Static validator class.
 */
export default class Validator {
    /**
     * @param {object} object The object to validate.
     * @param {object} rules A set of rules to validate the object against. The
     * keys in this object should match the keys in the "object" parameter.
     * @returns {object} An object containing any validation issues. If there
     * are no issues, an empty object.
     */
    static validate(object, rules) {
        const validationIssues = {};

        for (const key in rules) {
            const rule = rules[key];

            if (
                rule.required === true &&
                Validator.validateRequired(object[key]) === false
            ) {
                validationIssues[key] = "Required";
            } else if (
                rule.type === "integer" &&
                object[key] !== null &&
                object[key] !== undefined &&
                Validator.validateInteger(object[key]) === false
            ) {
                validationIssues[key] = "Must be an integer";
            }
            else if (
                rule.type === "phone" &&
                object[key] !== null &&
                object[key] !== undefined &&
                Validator.validatePhone(object[key]) === false
            ) {
                validationIssues[key] = `Must be at least 10 digits`;
            }
            else if (
                rule.type === "zipCode" &&
                object[key] !== null &&
                object[key] !== undefined &&
                Validator.validateZipCode(object[key]) === false
            ) {
                validationIssues[key] = `Must be at least 5 digits`;
            }
            else if (
                rule.type === "npi" &&
                object[key] !== null &&
                object[key] !== undefined &&
                Validator.validateNPI(object[key]) === false
            ) {
                validationIssues[key] = `Must be at least 10 digits`;
            }
        }

        return validationIssues;
    }

    /**
     * @param {*} value
     * @returns Whether or not the value is set and is not an empty string.
     */
    static validateRequired(value) {
        if (
            value === null ||
            value === undefined ||
            (
                typeof value === "string" &&
                value.trim() === ""
            )
        ) {
            return false;
        }

        return true;
    }

    /**
     * @param {*} value
     * @returns Whether or not the value is an integer.
     */
    static validateInteger(value) {
        return Number.isInteger(+value);
    }

    /**
     * @param {*} value
     * @returns Whether or not the value is set and is the correct amount of
     * digits.
     */
    static validatePhone(value) {
        return ('' + value).replace(/\D/g, '').length === 10;
    }

    /**
     * @param {*} value
     * @returns Whether or not the value is set and is the correct amount of
     * digits.
     */
    static validateZipCode(value) {
        return ('' + value).replace(/\D/g, '').length === 5;
    }

    /**
     * @param {*} value
     * @returns Whether or not the value is set and is the correct amount of
     * digits.
     */
    static validateNPI(value) {
        return ('' + value).replace(/\D/g, '').length === 10;
    }
}