import api from "../api";

class MarketingCampaignService {
    async getAllMarketingCampaigns() {
        return new Promise((res, reject) => {
            api
                .get("/campaigns")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getMarketingCampaignById(id) {
        return new Promise((res, reject) => {
            api
                .get("/campaigns/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async createMarketingCampaign(obj) {
        return new Promise((res, reject) => {
            api
                .post("/campaigns", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateMarketingCampaign(obj) {
        return new Promise((res, reject) => {
            api
                .put("/campaigns", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async deleteMarketingCampaign(id) {
        return new Promise((res, rej) => {
            return api.delete('/campaigns/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }




}

const marketingCampaignService = new MarketingCampaignService();

export default marketingCampaignService;
