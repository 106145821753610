import Card from "../../../Card";
import React from "react";
import MUIGrid from "@mui/material/Grid";
import MUIFormControlLabel from '@mui/material/FormControlLabel';
import MUISwitch from '@mui/material/Switch';
import MUIButton from "@mui/material/Button";
import MUIAddIcon from "@mui/icons-material/Add";
import DialogSearchServiceReason from "../../../Dialog/Search/DialogSearchServiceReason";
import TileServiceReason from "../../../Tile/TileServiceReason";
import PropTypes from "prop-types";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import EmptyState from "../../../EmptyState";
import MUIAlert from "@mui/material/Alert";

export default class CardAdminQueueRouteServiceReasons extends Card {
    constructor(props) {
        super(props);

        this.state.serviceReasonDialogOpen = false;
    }

    static contextTypes = {
        serviceReasonsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleAdd = (serviceReason) => {
            const jsonSubTypes = (this.props.queueRoute && this.props.queueRoute.jsonSubTypes) || {};
            jsonSubTypes.options = [
                ...new Set((jsonSubTypes.options || []).concat([serviceReason.description]))
            ];

            this.props.onChange({
                jsonSubTypes: jsonSubTypes
            });
        };

        const handleRemove = (serviceReason) => {
            const jsonSubTypes = this.props.queueRoute.jsonSubTypes;
            jsonSubTypes.options = jsonSubTypes.options.filter((description) => {
                return description !== serviceReason.description;
            });

            this.props.onChange({
                jsonSubTypes: jsonSubTypes.options.length > 0 ? jsonSubTypes : null
            });
        };

        const handleRequiredChange = (e) => {
            const jsonSubTypes = this.props.queueRoute.jsonSubTypes;
            jsonSubTypes.required = e.target.checked;

            this.props.onChange({
                jsonSubTypes: jsonSubTypes
            });
        };

        const serviceReasons = [];
        if (this.props.queueRoute && this.props.queueRoute.jsonSubTypes) {
            this.props.queueRoute.jsonSubTypes.options.forEach(description => {
                serviceReasons.push(
                    Object.values(this.context.serviceReasonsIndexed).find(serviceReason => {
                        return serviceReason.description === description;
                    })
                );
            });
        }

        const dialogSearchServiceReason = (
            <DialogSearchServiceReason
                open={this.state.serviceReasonDialogOpen}
                onClose={() => this.setState({ serviceReasonDialogOpen: false })}
                onSelect={handleAdd}
            />
        );

        if (serviceReasons.length === 0) {
            return (
                <>
                    <EmptyState
                        line1="No Subtypes"
                        line2="Add subtypes to display them on the route"
                    />
                    {dialogSearchServiceReason}
                </>
            );
        }

        return (
            <>
                {serviceReasons.length > 0 && (<MUIFormControlLabel
                    control={<MUISwitch />}
                    label="Require Subtype"
                    onChange={handleRequiredChange}
                    checked={this.props.queueRoute.jsonSubTypes.required === true}
                />)}
                <MUIGrid container spacing={2} sx={{ "paddingBottom": 2 }}>
                    {serviceReasons.map((serviceReason, i) => {
                        return (
                            <MUIGrid key={i} item xs={12} sm={6} md={4}>
                                <TileServiceReason
                                    serviceReason={serviceReason}
                                    iconButtons={[(<MUIIconButton onClick={() => handleRemove(serviceReason)}><MUIDeleteIcon /></MUIIconButton>)]}

                                />
                            </MUIGrid>
                        );
                    })}
                </MUIGrid>
                {serviceReasons.length === 1 && this.props.queueRoute.jsonSubTypes.required === true && (
                    <MUIAlert severity="info">
                        When subtype is required and there is only one option, it will be hidden from the user and automatically selected.
                    </MUIAlert>
                )}
                {dialogSearchServiceReason}
            </>
        );
    }

    /**
     * Renders add subtype button
     */
    getActions() {
        const handleAdd = () => {
            this.setState({
                serviceReasonDialogOpen: true,
            });
        };

        const buttonItems = [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIAddIcon />}
                onClick={handleAdd}>Add Subtype</MUIButton>)
        ];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Subtypes";
    }
}