import React from "react";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";

export default class CardChartPatientGeneral extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.patient ? this.props.patient.name : ""}
                        autoFocus={true}
                        size="small"
                        label="First Name"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ firstName: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ firstName: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.firstName ? true : false}
                        helperText={this.props.validationIssues.firstName ? this.props.validationIssues.firstName : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.patient ? this.props.patient.name : ""}
                        size="small"
                        label="Last Name"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ lastName: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ lastName: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.lastName ? true : false}
                        helperText={this.props.validationIssues.lastName ? this.props.validationIssues.lastName : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUIDatePicker
                        label="DOB"
                        defaultValue={this.props.patient ? this.props.patient.dateOfBirth : null}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                size: "small",
                                variant: 'standard',
                                required: true,
                                error: this.props.validationIssues.dateOfBirth ? true : false,
                                helperText: this.props.validationIssues.dateOfBirth ? this.props.validationIssues.dateOfBirth : "",
                                InputLabelProps: {
                                    shrink: true
                                },
                            },
                        }}
                        onChange={(value) => this.props.onChange({ dateOfBirth:  moment(value).format('M/D/YYYY') })}
                        views={['year', 'month', 'day']}
                    />
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}