import React from "react";
import MUIPinkColor from "@mui/material/colors/pink";
import * as MUIIcons from "@mui/icons-material";

/**
 * Static helper functions for CRUD operations.
 */
export default class Crud {
    /**
     * Searches rows based on a number of different criteria. Calls the subclass
     * match function so please implement that.
     *
     * @param {array} rows An array of rows.
     * @param {string} query The search query.
     * @param {string} returnAllIfQueryEmpty Whether to return all rows if the
     * input query was an empty string.
     * @returns The filtered user list.
     */
    static search(rows, query, returnAllIfQueryEmpty = false) {
        const queryCleaned = query
            .toLowerCase()
            .trim()
            .replace(/\s+/g, ' ');

        if (queryCleaned === '') {
            return returnAllIfQueryEmpty ? this.sort(rows) : [];
        }

        const filtered = Object.values(rows).filter((row) => {
            return this.match(row, queryCleaned);
        });

        return this.sort(filtered);
    }

    /**
     * Allows you to read rows directly from indexed context variables.
     *
     * Ex: read(this.context.termsIndexed, [{key:"type",value:"queue_type"},
     * {key:"foo",value:"bar"}])
     *
     * @param {object} rows Rows directly out of context; indexed by primary
     * key.
     * @param {array} conditions The conditions to check.
     * @returns {array} A new array with only the rows matching the provided
     * conditions.
     */
    static read(rows, conditions) {
        return Object.values(rows).filter(row => {
            return conditions.every(condition => {
                if (Array.isArray(condition.value)) {
                    return condition.value.includes(row[condition.key]);
                }
                return row[condition.key] === condition.value;
            });
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getColor(modifier = 0) {
        let shade = this.getBaseColorShade();
        shade = Math.max(100, Math.min(900, shade + modifier));
        return this.getBaseColor()[shade];
    }

    /**
     * @returns {object} The base color of this object.
     */
    static getBaseColor() {
        return MUIPinkColor;
    }

    /**
     * @returns {number} The base color shade of this object.
     */
    static getBaseColorShade() {
        return 500;
    }

    /**
     * @returns {JSX} The icon for this object.
     */
    static getIcon(props) {
        return React.createElement(MUIIcons[this.getIconName() || "Warning"], props);
    }
}