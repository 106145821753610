import api from "../Security/api";

class EquipmentTrainingLinkService {
    async getAllTrainingLinks() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/equipmentTrainingLink", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getVideosByProduct(id) {
        return new Promise((res, reject) => {
            let url = "/equipmentTrainingLinkByCategory/" + id;

            return api
                .get(url)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getVideosByCategory(id) {
        return new Promise((res, reject) => {
            let url = "/equipmentTrainingLinkByCategory/" + id;

            return api
                .get(url)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateTrainingLink(link) {
        return new Promise((res, reject) => {
            api
                .put("/equipmentTrainingLink/" + link.id, link)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createTrainingLink(link) {
        return new Promise((res, reject) => {
            api
                .post("/equipmentTrainingLink", link)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteTrainingLink(id) {
        return new Promise((res, reject) => {
            api
                .delete("/equipmentTrainingLink/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spQueueTrainingVideos
    // async fireEquipmentVideoProcess(filters) {
    // 	return new Promise((res, reject) => {
    // 		api
    // 			.get("/equipmentTrainingLinkNative", filters)
    // 			.then((data) => {
    // 				res(data);
    // 			})
    // 			.catch((err) => {
    // 				reject(err);
    // 			});
    // 	});
    // }
}

const equipmentTrainingLink = new EquipmentTrainingLinkService();

export default equipmentTrainingLink;
