import React from "react";
import DialogSearch from "../DialogSearch";
import TileProductSubType from "../../Tile/TileProductSubType";
import PropTypes from "prop-types";
import ProductSubType from "../../../Utility/Crud/ProductSubType";

export default class DialogSearchProductSubType extends DialogSearch {

    static contextTypes = {
        productSubTypesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered productSubType.
     */
    getSearchResults(query) {
        return ProductSubType.search(this.state.data, query);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.productSubTypesIndexed);
        }
    }

    /**
     * @param {object} productSubType The productSubType object.
     * @returns A TileProductSubType component for this productSubType.
     */
    renderTile(productSubType) {
        return (
            <TileProductSubType
                key={productSubType.id}
                productSubType={productSubType}
            />
        );
    }
}
