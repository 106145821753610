import React from "react";
import Card from "../../Card";
import MUIGrid from "@mui/material/Grid";
import MUITypography from "@mui/material/Typography";

export default class CardApprovalSummaryPayment extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        const paymentInformation = [
            { label: "Deductible", value: this.props.approval?.deductible || "" },
            { label: "Coinsurance", value: this.props.approval?.coInsurance || "" },
            { label: "Due at Delivery", value: this.props.approval?.paymentOnDelivery || "" },
            { label: "Total Responsibility", value: this.props.approval?.patientResponsibility || "" },
        ]
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return (
            <MUIGrid container spacing={1}>
                {paymentInformation.map((item, index) => {
                    return (
                        <MUIGrid item xs={12} sx={{ display: 'flex', alignItems: 'center' }} key={index}>
                            <MUITypography variant="subtitle2" sx={{ pr: 1 }}>{item.label}</MUITypography>
                            <div style={{ height: '8px', borderBottom: '1px dotted black', flexGrow: 1 }}></div>
                            <MUITypography variant="caption" sx={{ pl: 1 }}>{currencyFormatter.format(item.value)}</MUITypography>
                        </MUIGrid>
                    );
                })}
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Payment";
    }

    /**
     * @returns {string} The minHeight of the card.
     */
     getMinHeight() {
        return "100%";
    }
}