import React from "react";
import Dialog from "../Dialog";
import MUIDialogActions from "@mui/material/DialogActions";
import MUITypography from "@mui/material/Typography";
import MUIButton from "@mui/material/Button";
import MUIList from "@mui/material/List";
import MUIListItemText from '@mui/material/ListItemText';
import MUIListItem from "@mui/material/ListItem";
import MUIContainer from "@mui/material/Container";

export default class DialogQueueHelp extends Dialog {

    /**
    * Render header title of dialog
    */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                {this.props.header}
            </MUITypography>
        );
    }

    /**
     * Render main content of dialog
     */
    renderContent() {
        return (
            <MUIContainer disableGutters>
                <MUIList>
                    <MUITypography variant="h6">
                        Data Grid
                    </MUITypography>
                    <MUIListItem disablePadding>
                        <MUIListItemText>
                            <MUITypography variant="subtitle1"><b>• Add Sorted Column:</b> Shift + Click on the column header to select multiple sorted columns. This also applies for grouped columns inside the grouping panel.</MUITypography>
                        </MUIListItemText>
                    </MUIListItem>
                    <MUIListItem disablePadding>
                        <MUIListItemText>
                            <MUITypography variant="subtitle1"><b>• Remove Sorted Column:</b> Ctrl + Click on the column header to remove a sorted column. This also applies for grouped columns inside the grouping panel.</MUITypography>
                        </MUIListItemText>
                    </MUIListItem>
                    <MUIListItem disablePadding>
                        <MUIListItemText>
                            <MUITypography variant="subtitle1"><b>• Adjust Column Width:</b> Click + Hold the mouse cursor between two column headers and drag left or right to adjust the width of a column.</MUITypography>
                        </MUIListItemText>
                    </MUIListItem>
                </MUIList>
                <MUIList>
                    <MUITypography variant="h6">
                        Menu Items
                    </MUITypography>
                    <MUIListItem disablePadding>
                        <MUIListItemText>
                            <MUITypography variant="subtitle1"><b>• Reload Queue:</b> Refreshes the queue and will save any custom grouping, sorting, or expanded rows.</MUITypography>
                        </MUIListItemText>
                    </MUIListItem>
                    <MUIListItem disablePadding>
                        <MUIListItemText>
                            <MUITypography variant="subtitle1"><b>• Reset View:</b> Resets the queue to the default grouping and sorting and closes any expanded rows.</MUITypography>
                        </MUIListItemText>
                    </MUIListItem>
                    <MUIListItem disablePadding>
                        <MUIListItemText>
                            <MUITypography variant="subtitle1"><b>• View All:</b> Shows all rows with no filter.</MUITypography>
                        </MUIListItemText>
                    </MUIListItem>
                    <MUIListItem disablePadding>
                        <MUIListItemText>
                            <MUITypography variant="subtitle1"><b>• View OOB Only:</b> Filters rows to show orders that are "out of bounds" only.</MUITypography>
                        </MUIListItemText>
                    </MUIListItem>
                    <MUIListItem disablePadding>
                        <MUIListItemText>
                            <MUITypography variant="subtitle1"><b>• Clear Open By Me:</b> Clears all orders currently open by you.</MUITypography>
                        </MUIListItemText>
                    </MUIListItem>
                </MUIList>
            </MUIContainer>
        );
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Close
                </MUIButton>
            </MUIDialogActions>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "sm";
    }
}
