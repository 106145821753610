import api from "../Security/api";

class ReasonService {
    async getAllReasons() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/reasons", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateReason(reason) {
        return new Promise((res, reject) => {
            api
                .put("/reasons", reason)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createReason(reason) {
        return new Promise((res, reject) => {
            api
                .post("/reasons", reason)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteReason(id) {
        return new Promise((res, reject) => {
            api
                .delete("/reasons/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }


    //Reverse Quality Reasons

    async getAllReverseQualityReasons() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/reverseQualityReason", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateReverseQualityReason(reason) {
        return new Promise((res, reject) => {
            api
                .put("/reverseQualityReason", reason)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createReverseQualityReason(reason) {
        return new Promise((res, reject) => {
            api
                .post("/reverseQualityReason", reason)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteReverseQualityReason(id) {
        return new Promise((res, reject) => {
            api
                .delete("/reverseQualityReason/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const reasonService = new ReasonService();

export default reasonService;
