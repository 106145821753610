import * as React from 'react';
import MUIStyled from '@mui/material/styles/styled';
import MUIButton from '@mui/material/Button';
import MUIAttachFileIcon from '@mui/icons-material/AttachFile';

export default class Upload extends React.Component {
    render() {
        const HiddenInput = MUIStyled('input')({
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: 1,
            overflow: 'hidden',
            position: 'absolute',
            bottom: 0,
            left: 0,
            whiteSpace: 'nowrap',
            width: 1,
        });

        return (
            <MUIButton
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<MUIAttachFileIcon />}
            >
                {this.props.label || "Choose File"}
                <HiddenInput
                    accept={this.props.accept || "*"}
                    type="file"
                    onChange={(event) => { this.props.onChange(event.target.files[0]); }}
                    multiple
                />
            </MUIButton>
        );
    }
}
