import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container,
    MDBBtn,
    MDBIcon,
    MDBSelect,
    Modal,
    ModalBody,
    ModalFooter,
    MDBInput,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
    MDBFormInline,
    MDBCol,
    MDBRow,
    MDBModalHeader,
    MDBCardBody,
    MDBCard, MDBModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "mdbreact";
import classnames from "classnames";
import {ToastContainer, toast} from "react-toastify";
import "./deliveries.css";
import '../../Components/jkTooltip.css';
import OrderService from "../Security/OrderService/orderService";
import BtProductService from "../Security/BtProductService/btProductService";
import CallLogService from "../Security/CallLogService/callLogService";
import patientCommunicationService from "../Settings/PatientCommunicationService";
import PropTypes from "prop-types";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import NoteService from "../Security/NoteService/noteService";
import StreetMap from "./StreetMap";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";
import ReadyToScheduleGrid from "./readyToScheduleGrid";
import MUITextField from "@mui/material/TextField";
import MUIMenuItem from "@mui/material/MenuItem";
import GlobalFunctions from "../Filters/GlobalFunctions";
import InformationChangeModal from "../../Components/Custom/InformationChangeModal";
import partsService from "../Security/ProductService/partsService";
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MUIGreyColor from "@mui/material/colors/grey";
import LayerLegacy from "../../LayerLegacy";


// region Constants

const colorGroups = [
    [75, 192, 192, 1],    [75, 90, 192, 1],    [192, 75, 115, 1],   [75, 188, 66, 1],
    [147, 81, 160, 1],    [244, 171, 68, 1],   [191, 244, 68, 1],   [68, 244, 171, 1],
    [33, 75, 89, 1],      [81, 97, 160, 1],    [255, 210, 215, 1],  [255, 210, 140, 1],
    [145, 230, 205, 1],   [130, 220, 225, 1],  [255, 100, 180, 1],  [50, 90, 105, 1],
    [255, 245, 175, 1],   [225, 160, 60, 1],   [60, 40, 45, 1],     [85, 10, 15, 1],
];

// endregion

export default class DeliveryList extends LayerLegacy {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            techScheduleLoaded: false,
            rootData: [],

            dataNotScheduled: [],
            dataApproved: [],
            dataEquipmentOrdered: [],

            dataTechSchedule: [],
            userLocations: [],
            selectedLocationIds: [],
            locationList: [],
            editRow: {},
            toggleModal: false,
            errorMessage: "",
            mapWindow: null,
            tooltipContent: "",
            geoLocations: [],
            noteToggle: false,
            scheduledStyle: [],
            nNote: "",
            TabsOneActiveItem: "4",
            techs: [],
            equipApproval: false,
            equipApprovalModal: false,
            hiddenOrders: [],
            isOpen: true,
            equipOrder: {},
            callRadio: null,
            callPatientNote: "",
            techTooltip: "",
            technicianText: "",
            ssAcknowledge: false,
            flatOrder: null,
            changedLocation: false,

            orderRetrieved: false,
            orderProducts: [],
            smsMessages: null,
            loadingFlatOrder: false,

            notesRetrieved: false,
            orderNotes: [],
            ModalTabsOneActiveItem:  "1",
            idNames: [],
            patientRegistered: null,
            patientInfoModalOpen: false,
            allLocations: [],
            serviceQuoteFromOrderId: [],
            servicePartsLoaded: false,
            firstLoad: true,
        };

        this.renderEditOrderModal = this.renderEditOrderModal.bind(this);

        setTimeout(() => {
            this.getLocalStorage();
        }, 2500);
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        internalLocations: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        techs: PropTypes.array,
        reasons: PropTypes.array,
        companyName: PropTypes.string,
    };

    toggle = (tabset, tab) => {
        if (this.state.TabsOneActiveItem !== tab) {
            this.setState({
                TabsOneActiveItem: tab,
            });
        }
    };

    toggleCollapse() {
        this.setState((state) => ({
            isOpen: !state.isOpen,
            isLoaded: false,
        }));
        window.mapComponent.forceMapRender();
        //This is necessary because otherwise the grid won't use the full width on resize
        let t = this;
        setTimeout(() => {
            t.setState({
                isLoaded: true,
            });
        }, 5);
    }

    componentDidMount() {
        this.getUserLocations();

    }
    getFlatOrder(){

        if(this.state.loadingFlatOrder || (this.state.flatOrder && this.state.flatOrder.id === this.state.editRow.id)){
            return;
        }
        this.setState({
            loadingFlatOrder: true
        });
        OrderService.getFlatOrderActivity(this.state.editRow.id).then((res) => {

            this.setState({
                flatOrder:res,
                loadingFlatOrder:false
            });
        }).catch((err)=>{
            console.log(err);
        });


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { internalLocations } = this.context;

        if (internalLocations.length > 0 && this.state.allLocations.length !== internalLocations.length) {
            this.getUserLocations();
        }
    }

    // region Locations
    getUserLocations() {
        const { currentUser, internalLocations} = this.context;

        let locations = [],
            a = [];

        if (
            currentUser.role === "ADMIN" ||
            currentUser.role === "EXECUTIVE_MANAGEMENT"
        ) {
            locations = internalLocations;
        } else if (currentUser.userprofileLocations) {
            locations = currentUser.userprofileLocations.map(x => x.locations);
        }

        locations.map((loc) => {
            return a.push({
                disabled: false,
                text: loc.name,
                value: loc.id.toString(),
                locVal: loc,
            });
        });

        this.setState({
            userLocations: locations,
            allLocations: internalLocations,
            locationList: a,
        });
    }

    setLocalStorage(e) {
        window.localStorage.setItem("deliveriesFilters", JSON.stringify(e));
    }

    getLocalStorage() {
        let f = window.localStorage.getItem("deliveriesFilters");

        if (f != null) {
            f = JSON.parse(f);

            let ll = this.state.locationList;

            if (ll.length > 0 && f.length > 0) {
                let v = ll.find((r) => {
                    return r.value === f[0];
                });

                let t = "";

                if (v) {
                    v.checked = true;
                    t = v.text;
                }

                this.setState({
                    locationList: ll,
                    selectedLocationText: t,
                });
            }

            this.handleLocationChange(f);
        }
    }

    handleLocationChange = (e) => {
        let mapWindow = this.state.mapWindow;

        if (e.length === 0) {
            //location was not actually changed to anything
            return;
        }

        this.setState({
            selectedLocationIds: e,
            dataTechSchedule: [],
        });
        if (window && window.mapComponent) {
            window.mapComponent.clearTechStyle();
        }
        if (mapWindow && !mapWindow.closed) {
            this.refreshMapWindow(e[0]);
        }
        if (e.length > 0) {
            let loc = this.state.locationList.filter((lo) => {
                return lo.value === e[0];
            });
            if (loc[0] && loc[0].locVal.address != null) {
                let lo = loc[0].locVal;
                let addr = lo.address; // + " " + lo.city + ", " + lo.state + " " + lo.zipCode;
                addr += lo.city === null ? "" : " " + lo.city;
                addr += lo.state === null ? "" : ", " + lo.state;
                addr += lo.zipCode === null ? "" : " " + lo.zipCode;

                window.mapComponent.setOfficeLocation(
                    addr,
                    loc[0].locVal.latitude,
                    loc[0].locVal.longitude
                );
            }
            this.retrieveDeliveries(e);
            this.setLocalStorage(e);
        }
    };

    getIDs() {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            let arr = [];
            let products = this.state.orderProducts;
            if (products.length > 0) {
                products.forEach((product, index) => {
                    arr.push(this.getProductsDetails(product, index));
                });
            }
        }
    }

    getProductsDetails(product, index) {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            let a = [];

            a.push({
                text: "N/A",
                value: "N/A",
            });

            if (product.hcpcs && product.hcpcs.length > 0) {
                BtProductService.getBtProductsByHcpcs(product.hcpcs)
                    .then((res) => {
                        res.map((t) => {
                            return a.push({
                                text: t,
                                value: t,
                                selected: res.length === 1
                            });
                        });

                        if (a.length < 1) {
                            a[0].selected = true;
                        }
                        let productList = this.state.idNames;
                        //productList.splice(index, 0, a)
                        productList[index] = a;
                        this.setState({
                            idNames: productList
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        return a.push({
                            text: "N/A",
                            value: "N/A",
                        });
                    });
            }
            else {
                let productList = this.state.idNames;
                productList[index] = a;
                this.setState({
                    idNames: productList
                });
            }
        }
    }

    handleIDChange = (e, prod) => {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            if (prod.length === 0) {
                return;
            }
            let products = this.state.orderProducts;
            products = products.map(product => {
                if (product.id === e.id) {
                    return { ...product, btitemId: prod[0] };
                }
                return { ...product };
            });

            this.setState({
                orderProducts: products,
            });
        }
    };

    retrieveDeliveries(ids) {
        this.setState({
            isLoaded: false,
            techScheduleLoaded: false,
        });

        // This takes the locations selected in the dropdown and adds them as the part of the parameters for api call...JK
        if (ids.length > 0) {
            OrderService.getDeliveriesForLocation(ids)
                .then((res) => {
                    this.buildTechTimeline(res);
                    this.buildDataSets(res);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("An error occurred getting the deliveries");
                });
        }
    }

    createPatientCommunication(patientId, orderId, commType) {
        const { companyName } = this.context;
        if (["uat", "rehabmedical"].includes(companyName) && this.state.order !== null && [2, 26].includes(this.state.order.salesLocation.id)) {
            var ds = new Date();
            var day = ds.getDate(),
                month = ds.getMonth() + 1,
                yr = ds.getFullYear();

            var dateStamp = (month < 9 ? "0" : "") + (month).toString() + "/" + (day < 9 ? "0" : "") + day.toString() + "/" + yr;

            var comm = {
                id: null,
                name: "Patient Registration",
                orderID: orderId,
                patientID: patientId,
                type: commType,
                dateToSend: dateStamp,
                sent: false,
                sentOn: null,
            };
            patientCommunicationService.createPatientCommunication(comm)
                .then(res => {
                    toast.success("The link has been created for the patient.");
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
    formatDate(date) {
        if (date == null || date === "") {
            return "";
        }
        let dt = new Date(date);
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        }).format(dt);
    }

    formatTime(date) {
        if (date == null || date === "") {
            return "";
        }
        let dt = new Date(new Date(date).toString() + " UTC");
        return new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "2-digit",
        }).format(dt);
    }

    buildDataSets(data) {
        const { companyName } = this.context;
        let d2 = [],
            d3 = [],
            d4 = [],
            today = new Date(),
            ordersToMap = [],
            regEx = new RegExp("No Answer.+"),
            oldDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
        // Have to use reg ex since reaason is slightly different in Rehab compared to Cork...JK

        let blank = data.orders.filter((bl) => {
            return !bl.reason || bl.reason === null || regEx.test(bl.reason);
        });
        blank.map((item) => {
            return d3.push({
                order: item.id,
                type: item.orderType,
                name: item.patientName,
                address: item.address,
                city: item.city,
                state: item.state,
                zip: item.zipCode,
                reason: item.reason,
                category: item.category,
                product: item.product,
                pts: item.totalPoints,
                lastNoteDate: this.formatDate(item.lastNoteDate),
                answered: item.answered,
                calledBy: item.calledBy,
                calledOn: item.calledOn,
            });
        });

        let eo = data.orders.filter((bl) => {
            return bl.reason && bl.reason === "Equipment Ordered";
        });
        eo.map((item) => {
            return d4.push({
                order: item.id,
                category: item.category,
                product: item.product,
                phone: item.phone,
                name: item.patientName,
                equipmentOrderedDate: item.equipmentOrderedDate,
                lastNoteDate: GlobalFunctions.formatDate(item.lastNoteDate),
                answered: item.answered,
                calledBy: item.calledBy,
                calledOn: item.calledOn,
            });
        });

        // let rest = data.orders.filter(bl => {
        //     return bl.reason !== 'Equipment Ordered'
        // });
        data.orders.map((item) => {
            ordersToMap.push(item);
            let tc = item.technicianId
                ? data.techs.find((x) => x.id === item.technicianId)
                : null;
            return d2.push({
                patientRegistered: item.patientRegistered,
                missingIds: companyName !== "corkmedical" ? item.missingIds : false,
                order: item.id,
                type: item.orderType,
                address: item.address,
                city: item.city,
                state: item.state,
                zip: item.zipCode,
                reason: item.reason,
                name: item.patientName,
                patientId: item.patientId,
                product: item.product,
                pts: item.totalPoints,
                tech: tc ? tc.username : "",
                scheduledDate: GlobalFunctions.formatDate(item.scheduledDeliveryDate),
                expectedShipDate: item.expectedShipDate || "",
                approvalDate: GlobalFunctions.formatDate(item.approvalDate),
                equipmentOrderedDate: item.equipmentOrderedDate,
                lastNoteDate: GlobalFunctions.formatDate(item.lastNoteDate),
                pediatric: oldDate <= new Date(item.dateOfBirth) ? "Yes" : "No",
            });
        });

        window.mapComponent.setOrderLocations(ordersToMap);

        this.setState({
            dataNotScheduled: d2,
            dataApproved: d3,
            dataEquipmentOrdered: d4,
            rootData: data.orders,
            techs: data.techs,
            isLoaded: true,
        });
    }

    buildTechTimeline(data) {
        let d4 = this.state.dataTechSchedule,
            ids = this.state.selectedLocationIds,
            techs = data.techs;

        if (ids.length > 0) {
            this.setState({techScheduleLoaded: false});

            d4 = [];

            let filteredData = data.orders.filter((order) => {
                return (
                    order.scheduledDeliveryDate !== null && order.technicianId !== null
                );
            });

            techs.forEach((tech, index) => {
                let x = index % colorGroups.length;
                let currStyle = {
                    tech: tech.id,
                    stroke: [0, 0, 0, 0.5],
                    fill: colorGroups[x],
                    visible: true,
                    name: tech.firstname + " " + tech.lastname
                };

                let orderCount = 0,
                    row = {
                        isOpen: true,
                        techId: tech.id,
                        name: tech.firstname + " " + tech.lastname + " - ",
                        deliveries: [],
                        stroke: "1px solid rgba(" + currStyle.stroke.toString() + ")",
                        fill: "rgba(" + currStyle.fill.toString() + ")",
                    };

                let ords = filteredData.filter((ord) => {
                    return ord.technicianId === tech.id;
                });

                window.mapComponent.setTechStyle(currStyle);

                ords.forEach((od, idx) => {
                    let orderDate = new Date(
                        od.scheduledDeliveryDate
                    ).toLocaleDateString();

                    let exists = row.deliveries.findIndex((del) => {
                        return del.date === orderDate;
                    });

                    if (exists > -1) {
                        row.deliveries[exists].orders.push(od);
                    } else {
                        row.deliveries.push({
                            date: orderDate,
                            orders: [od],
                        });
                    }

                    orderCount++;
                });

                row.deliveries.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date);
                });

                row.name += orderCount.toString();
                d4.push(row);
            });

            this.setState({
                dataTechSchedule: d4,
                techScheduleLoaded: true,
                techs: techs,
            });
        }
    }

    handleDeliveryDate(e) {
        let row = this.state.editRow;
        let eOrder = this.state.flatOrder;

        if (e) {
            let q = new moment.utc(e);
            let v = q.format("YYYY-MM-DDTHH:mm:ss");
            row.scheduledDeliveryDate = v;
            eOrder.scheduledDeliveryDate = e;
        } else {
            eOrder.scheduledDeliveryDate = null;
            row.scheduledDeliveryDate = null;
        }

        this.setState({editRow: row,
            flatOrder: eOrder});
    }

    handleTechChange(e) {
        let row = this.state.editRow,
            eOrder = this.state.flatOrder,
            text = "";

        if (e) {
            eOrder.technicianId = e.id;
            row.technicianId = e.id;
            text = e.firstname + " " + e.lastname;
        } else {
            row.technicianId = null;
            eOrder.technicianId = null;
        }

        this.setState({
            editRow: row,
            technicianText: text,
            flatOrder: eOrder,
        });
    }

    handleReasonChange(e) {
        const {reasons} = this.context;
        let row = this.state.editRow;
        let order = this.state.flatOrder;

        if (e) {
            let r = reasons.find((o) => {
                return e === o.id;
            });

            if (r) {
                row.reason = r.description;
                row.reasonId = r.id;
                order.orderStatusReasonId = r.id;
            } else {
                row.reason = null;
                order.orderStatusReasonId = null;
            }
        }
        else {
            row.reason = null;
            row.reasonId = null;
            order.orderStatusReasonId = null;
        }

        this.setState({
            editRow: row,
            flatOrder: order,
        });
    }

    goToOrderOnMap(id) {
        if (id) {
            this.toggle("4", "4");
            window.mapComponent.focusOnOrder(id);
        }
    }

    updateOrder(rowData, note) {

        const { currentUser } = this.context;
        let order = this.state.flatOrder;
        order.lastUpdatedBy = {
            id: currentUser.id,
            username: currentUser.username,
        };


        order.lastUpdatedById = currentUser.id;
        // if(this.state.changedLocation){
        // this.setState({
        // changedLocation: false
        // })
        OrderService.updateFlatOrder(order).then((res) => {

        // }

            let row = this.state.editRow;
            if (row.id != res.id) {
                return toast.warn("Order was recently updated. Please refresh the page and resubmit your changes.");
            }

        if ((!row || !row.id) && rowData != null) {
            row = rowData;
        }

        if (row.id) {

            if (!row.reasonId) {
                return toast.error("You must select a status reason.");
            }

            if (row.reason === "Equipment Ordered") {
                if (!row.equipmentOrderedDate) {
                    row.equipmentOrderedDate = new Date().toLocaleString();
                }
            }

            if (!this.state.servicePartsLoaded) {
                this.updateProducts();
            }


            //update smsMessages property...BC
            row.smsMessages = this.state.smsMessages;

            OrderService.updateDelivery(row)
                .then((ord) => {
                    if (this.state.nNote || note) {
                        this.saveNote(row, note);
                    }
                    if (row.scheduledDeliveryDate) {
                        let noteDate = GlobalFunctions.formatDateTimeTimeZone(row.scheduledDeliveryDate);
                        // let noteDate = new Date(row.scheduledDeliveryDate).toLocaleString();
                        // row.scheduledDeliveryDate = moment
                        //     .utc(row.scheduledDeliveryDate)
                        //     .local()
                        //     .format();

                        if (this.state.ssAcknowledge) {
                            if (!this.state.nNote) {
                                toast.error(
                                    "You must add a note explaining why you are scheduling with Same/Sim on file."
                                );
                                return;
                            }

                            let note =
                                currentUser.username +
                                " acknowledged that there is a same/sim on file and scheduled the order to be delivered on " +
                                noteDate;

                            if (this.state.technicianText) {
                                note += " with technician " + this.state.technicianText;
                            }

                            this.saveNote(row, note);
                        }

                        if (row.technicianId && row.reason === "Scheduled") {
                            let text =
                                "Order scheduled for " +
                                noteDate +
                                " by " +
                                currentUser.username +
                                " with technician " +
                                this.state.technicianText;
                            this.saveNote(row, text);
                        }
                    }
                    this.toggleEditModal();
                    //window.mapComponent.clearTechStyle();
                    toast.success("Saved Successfully!");
                    this.setState({
                        editRow: {},
                        toggleModal: false,
                        equipApprovalModal: false,
                        callPatientModal: false,
                        equipOrder: {},
                    });
                    if (row.reason && row.reasonId !== 8) {
                        window.mapComponent.updateOrderPin(row);
                    }
                    this.retrieveDeliveries(this.state.selectedLocationIds);
                    this.refreshMapWindow(this.state.selectedLocationIds[0]);
                })
                .catch((err) => {
                    toast.error("An error occurred while saving.");
                    console.log(err);
                    this.setState({isLoaded: true});
                });
        }
        }).catch((err) => {


        });
    }

    updateProducts() {
        let prods = this.state.orderProducts;
        OrderService.updateOrderProducts(this.state.editRow.id, prods)
            .then((r) => {
                toast.success("Products Saved Successfully!");
            })
            .catch((err) => {
                console.log(err);
                toast.warn("Error Saving Products");
            });
    }

    saveNote(order, noteText) {
        const {currentUser} = this.context;
        let n = noteText ? noteText : this.state.nNote;
        let note = {
            type: "ActivityNote",
            text: n,
            createdAt: new Date(),
            account: order.account,
            task: false,
            completed: false,
            activityId: order.id,
            createdBy: { id: currentUser.id, name: currentUser.username },
            createdById: currentUser.id,
        };
        NoteService.createActivityNote(order.id, note)
            .then((res) => {
                toast.success("Note saved");
            })
            .catch((err) => {
                toast.error("Error: Note not saved");
            });
        this.setState({
            nNote: "",
        });
    }

    toggleEditModal() {
        this.setState({
            toggleModal: !this.state.toggleModal,
            orderRetrieved: false,
            servicePartsLoaded: false,
            notesRetrieved: false,
            ssAcknowledge: false,
            orderProducts: [],
            smsMessages: null,
            orderNotes: [],
            idNames: [],
            order: {},
            technicianText: "",
        });
    }

    toggleTechRow(index) {
        let d4 = this.state.dataTechSchedule,
            tech = d4[index];

        tech.isOpen = !tech.isOpen;
        d4[index] = tech;

        this.setState({
            dataTechSchedule: d4,
        });
    }

    printTechTooltip(tech, date) {
        var mywindow = window.open("", "PRINT", "height=600,width=600");
        mywindow.document.write(
            "<html><head><title>Daily Delivery Schedule</title>"
        );
        mywindow.document.write("</head><body >");
        mywindow.document.write("<h1>" + tech + "</h1>");
        mywindow.document.write("<h1>Delivery Schedule for " + date + "</h1>");
        mywindow.document.write(document.getElementById("techTooltip").innerHTML);
        mywindow.document.write("</body></html>");
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); //  for IE >= 10*/
        mywindow.print();
        mywindow.close();
        return true;
    }

    getTooltipContent(orders, tech, date) {
        orders.sort((a, b) => {
            return (
                new Date(a.scheduledDeliveryDate) - new Date(b.scheduledDeliveryDate)
            );
        });

        let ary = [];

        orders.forEach((ord, index) => {
            let address =
				!ord.address && !ord.city && !ord.state && !ord.zipCode
				    ? "*** No Address Provided ***"
				    : ord.address +
					"\n\t" +
					ord.city +
					", " +
					ord.state +
					" " +
					ord.zipCode;
            let phone = ord.phone ? ord.phone : "*** No phone Provided ***";
            ary.push(
                <p
                    key={index}
                    className={"techTooltipBoxInner"}
                    onClick={() => this.printTechTooltip(tech, date)}
                >
                    {"Order ID: " + ord.id} <br/>
                    {"Patient Name: " + ord.patientName} <br/>
                    {"Delivery Time: " + GlobalFunctions.formatTime(ord.scheduledDeliveryDate)}{" "}
                    <br/>
                    {"Address: " + address} <br/>
                    {"Phone: " + phone} <br/>
                    {"Equipment: " + ord.product}
                </p>
            );
        });
        this.setState({
            techTooltip: ary,
        });
    }

    clientCSV() {
        let result,
            ctr,
            keys = [
                "order",
                "name",
                "approvalDate",
                "address",
                "city",
                "state",
                "zip",
                "lastNoteDate",
                "type",
                "product",
                "pts",
                "reason",
                "scheduledDate",
                "tech",
            ],
            headers = [
                "Order ID",
                "Patient Name",
                "Approval Date",
                "Address",
                "City",
                "State",
                "Zip",
                "Last Note Date",
                "Order Type",
                "Product",
                "Points",
                "Reason",
                "Scheduled Date",
                "Technician",
            ],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data = this.state.dataNotScheduled;

        if (data == null || !data.length) {
            return null;
        }

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) {
                    result += columnDelimiter;
                }

                if (item[key]) {
                    let v = item[key].toString();
                    result += v.replace(/,/g, " ");
                } else {
                    result += "";
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, "DeliveriesList.csv");
    }

    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    openMapWindow() {
        let win = this.state.mapWindow,
            id = this.state.selectedLocationIds;

        if (!win) {
            win = window.open(
                "./mapWindow/" + (id ? id[0] : ""),
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=500"
            );
            if (this.state.TabsOneActiveItem === "4") {
                this.setState({TabsOneActiveItem: "3"});
            }
            win.onunload = () => {
                setTimeout(() => {
                    if (win.closed) {
                        this.setState({mapWindow: null});
                    }
                }, 100);
            };
        }

        this.setState({
            mapWindow: win,
        });
    }

    getOrderProducts(id) {
        OrderService.getOrderActivity(id)
            .then((ord) => {
                // let prod = ord.orderActivityProducts.map(x => x.products);
                if(!ord.orderActivityProducts || ord.orderActivityProducts.length<1){

                    this.setState({
                        smsMessages: ord.patient.smsMessages,
                        order: ord,
                    });
                    this.getServiceQuoteByOrder(id);
                }

                else {
                    this.setState({
                        orderRetrieved: true,
                        smsMessages: ord.patient.smsMessages,
                        orderProducts: ord.orderActivityProducts.map(x => x.products),
                        order: ord,
                    });
                    this.getIDs();
                }


            })
            .catch((err) => {
                console.log(err);
            });
    }
    sortNotes(allNotes) {

        let sorted = allNotes.sort((a,b) => {
            let da = new Date(a.createdAt).getTime(),
                db = new Date(b.createdAt).getTime();

            return da > db ? -1: 1;
        });

        return sorted;
    }
    getOrderNotes(id) {
        NoteService.getNotes(id)
            .then((n) => {
                let notes = this.sortNotes(n);
                this.setState({
                    notesRetrieved: true,
                    orderNotes: notes,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    getServiceQuoteByOrder(id) {
        // console.log(id)
        if (id == null || id === "") {
            return;
        }
        partsService.getServiceOrderProductsByOrder(id)
            .then((r) => {
                console.log(r);
                this.setState({
                    orderProducts: r,
                    orderRetrieved: true,
                    serviceQuoteFromOrderId: r,
                    servicePartsLoaded: true
                });
                this.getIDs();
            })
            .catch((e) => {
                console.log(e);
                this.setState({});
            });
    }


    refreshMapWindow(ids) {
        let id = ids,
            mapWindow = this.state.mapWindow;

        if (mapWindow !== null && !mapWindow.closed) {
            let pathArray = mapWindow.location.pathname.split("/");
            if (pathArray[pathArray.length - 1] === id) {
                mapWindow.location.reload();
            } else {
                let pathFiltered = "";
                pathArray.forEach((item, index) => {
                    if (index < pathArray.length - 1) {
                        pathFiltered += item + "/";
                    }
                });

                let newPath = mapWindow.location.origin + pathFiltered + id;
                mapWindow.location = newPath;
            }
        }
    }

    renderEditOrderModal = (id) => {
        let row = this.state.rootData.find((item) => {
            return item.id === id;
        });
        if (row) {
            let techs = this.state.techs,
                technicianText = "";

            let index = techs.find((rep) => {
                return rep.id === row.technicianId;
            });

            if (index) {
                technicianText = index.firstname + " " + index.lastname;
            }

            this.setState({
                editRow: row,
                selectedTech: row.technicianId,
                technicianText: technicianText,
            });
            this.getOrderProducts(id);
            this.getOrderNotes(id);
            this.toggleEditModal();
        } else {
            toast.warn("Could not locate the order.");
        }
    };

    changeByRef(prop, val) {
        if(prop === "serviceLocation"){
            this.setState({
                changedLocation: true
            });
        }
        let o = null;

        if (val) {
            o = {
                name: val.name,
                id: val.id,
            };
        }

        this.setAndUpdate(prop, o);
    }

    setAndUpdate(prop, val) {
        if (val && val.toString() === "Invalid date") {
            val = null;
        }
        let eOrder = this.state.flatOrder;

        if (eOrder.hasOwnProperty(prop)) {
            eOrder[prop] = val;
        }

        if (eOrder.activity.hasOwnProperty(prop)) {
            eOrder.activity[prop] = val;
        }

        if (eOrder.patient.hasOwnProperty(prop)) {
            eOrder.patient[prop] = val;
        }

        this.setState({ flatOrder: eOrder });

    }

    renderServiceLocationDropdown() {
        const { internalLocations } = this.context;
        if(!this.state.flatOrder){
            return;
        }
        let eOrder = this.state.flatOrder;

        let index = internalLocations.find((loc) => {
            if (eOrder.serviceLocation) {
                return loc.id === eOrder.serviceLocation.id;
            } else if (eOrder.serviceLocationId) {
                return loc.id === eOrder.serviceLocationId;
            } else {
                return null;
            }
        });

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="Service-Location"
                    select
                    size={"small"}
                    fullWidth
                    label="Service Location"
                    value={index ? index : ""}
                    onChange={(e) => {
                        this.changeByRef("serviceLocation", e.target.value);
                        this.setAndUpdate("serviceLocationId", e.target.value.id);
                    }}
                    variant="outlined"
                >
                    {internalLocations.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }

    renderTechnicianDropdown() {
        const { techs } = this.context;
        // let techs = this.state.techs,
        let   row = this.state.editRow;

        //let index = techs.find((rep) => {
        //    return rep.id === row.technicianId;
        //});
        if (techs && techs.length > 0) {
            let index = techs.find((rep) => {
                if (row.technicianId) {
                    return rep.id === row.technicianId;
                } else {
                    return null;
                }
            });
            var resp = techs.sort((a, b) => {
                if (a.firstname && a.lastname && b.firstname && b.lastname)
                    return a.firstname.trim() + " " + a.lastname.trim() > b.firstname.trim() + " " + b.lastname.trim() ? 1 : -1;
                else
                    return 1;
            });

            return (
                <div className={"uiOutlines"}>
                    <MUITextField
                        disabled={this.disableCheck()}
                        id="Technicians"
                        select
                        size="small"
                        fullWidth
                        label="Technician"
                        value={index ? index : ""}
                        onChange={(e) => this.handleTechChange(e.target.value)}
                        variant="outlined"
                    >
                        <MUIMenuItem key={-1} value={null}>
                            {"(Remove Technician)"}
                        </MUIMenuItem>
                        {resp.map((option, idx) => {
                            return (
                                <MUIMenuItem key={idx} value={resp[idx]}>
                                    {option.firstname + " " + option.lastname}
                                </MUIMenuItem>
                            );
                        })}
                    </MUITextField>
                </div>
            );
        }
    }

    renderProductsList() {
        const { companyName } = this.context;
        let p = this.state.orderProducts;

        if (this.state.orderRetrieved && p) {
            return (
                <div>
                    <MDBRow style={{ textDecoration: "underline", fontWeight: "bold" }}>
                        <MDBCol size="4">{this.state.servicePartsLoaded ? "Brightree Product" :"Product"} </MDBCol>
                        <MDBCol size="4">{companyName !== "corkmedical" ? (this.state.servicePartsLoaded ? "Brightree Product" : "HCPCS") : ""}</MDBCol>
                        <MDBCol size="1">Qty </MDBCol>
                        <MDBCol size="3">{this.state.servicePartsLoaded ? "":"Serial"} </MDBCol>
                    </MDBRow>
                    {/* To catch tickets not created in new system and need a piece of equipment to be able to be delivered */}
                    {p && p.length < 1 ?
                        <MDBRow className={"eqRow"}>
                            <Button
                                className={"registrationButtons"}
                                disabled={false}
                                color={"blue"}
                                size="md"
                                onClick={() => this.addPartForDelivery("30day")}>
                                Add 30 Day Fit for You
                            </Button>
                            <Button
                                className={"registrationButtons"}
                                disabled={false}
                                color={"blue"}
                                size="md"
                                onClick={() => this.addPartForDelivery("techSkilled")}>
                                Add Tech Skilled Labor
                            </Button>
                            <Button
                                className={"registrationButtons"}
                                disabled={false}
                                color={"blue"}
                                size="md"
                                onClick={() => this.addPartForDelivery("fieldService")}>
                                Add Field Service Diagnosis
                            </Button>

                        </MDBRow>
                        :
                        <div></div>

                    }

                    {p.map((prod, idx) => {
                        return (
                            <MDBRow key={idx} className={"eqRow"}>
                                <MDBCol size="4">
                                    {this.state.servicePartsLoaded ? prod.name :
                                        (prod.productType ? prod.productType.name : "") +
									" " + (prod.productSubType ? prod.productSubType.name : "")}
                                </MDBCol>
                                <MDBCol size="4">
                                    {companyName !== "corkmedical" ?
                                        this.state.servicePartsLoaded ?
                                            <MDBCol size="4">
                                                {(prod.hcpcs ? prod.hcpcs : "noname")}
                                            </MDBCol>

                                            :
                                            <MDBSelect
                                                className={"smallMargin searchField"}
                                                outline={true}
                                                search={true}
                                                color="info"
                                                selected={prod.btitemId || "Choose Product"}
                                                style={{ margin: "5px" }}
                                                options={this.state.idNames[idx]}
                                                getValue={this.handleIDChange.bind(this, prod)}
                                            />
                                        : ''}
                                </MDBCol>
                                <MDBCol size="1">{prod.qty}</MDBCol>
                                {this.state.servicePartsLoaded ? null : <MDBCol size="3">{prod.serial}</MDBCol> }

                            </MDBRow>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <MDBRow>
                    <MDBCol />
                    <MDBCol>
                        <Spinner small />
                    </MDBCol>
                </MDBRow>
            );
        }
    }

    addPartForDelivery(val) {
        this.setState({
            orderRetrieved: false,
            orderProducts: null,
            productsForNote: [],

            serviceQuoteFromOrderId: null,
            servicePartsLoaded: false
        });
        const { currentUser, productTypes, productSubTypes } = this.context;
        let id = null;
        if (val === "techSkilled") {
            //1963 Tech Skilled Labor
            id = 1963;
        }
        else if (val === "30day") {
            //70  30 Day Fit for You
            id = 70;
        } else if (val === "fieldService") {
            //1964 Field Service Diagnosis
            id = 1964;
        }

        let newItem = {
            abnRequired: false,
            amount: 0,
            approved: true,
            hcpcs: "na",
            isRental: false,
            orderActivityProducts:
                { orderActivityId: this.state.editRow.id },

            points: 0,
            productSubTypeId: id,
            productTypeId: 27,
            qty: 1,
            serial: "",
            updatedById: currentUser.id,
            updatedOn: new Date().toISOString()
        };
        let noteText =
            currentUser.firstname +
            " " +
            currentUser.lastname +
            " approved order " +
            this.state.editRow.id +
            " on " +
            moment().format("MMMM Do YYYY, h:mm a") +
            " for the following products : ";

        OrderService.updateOrderProducts(this.state.editRow.id, [newItem]).then(resProd => {
            if (resProd) {

                // let productsForNote = resProd.map((product) => {
                //     return product.products
                // })
                resProd.forEach((product) => {
                    var pt = product.productTypeId ? productTypes.filter(x => x.id === product.productTypeId)[0].name || "" : "";
                    var pst = product.productSubTypeId ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0].name || "" : "";
                    // totalPoints += parseFloat(product.points);
                    noteText += product.qty + " " + pt + " " + pst + " | ";
                });
                OrderService.getFlatOrderActivity(this.state.editRow.id).then((res)=>{
                    let note = {
                        type: "ActivityNote",
                        text: noteText,
                        createdAt: GlobalFunctions.getUTCMoment(),
                        account: res.activity.account,
                        accountId: res.activity.accountId,
                        task: false,
                        completed: true,
                        createdBy:  { id: currentUser.id, name: currentUser.username },
                        createdById: currentUser.id,
                        patientName: res.patientName,
                        activityId: res.id,
                    };

                    NoteService.createActivityNote(res.id, note)
                        .then((res) => {
                            // currentNotes.unshift(res);
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    let order = res;
                    if(!order.approvalDate){
                        order.approvalDate = new Date();
                    }
                    order.lastUpdatedBy = {
                        id: currentUser.id,
                        username: currentUser.username,
                    };
                    order.lastUpdatedById = currentUser.id;
                    OrderService.updateFlatOrder(order).then((res)=>{
                        this.getOrderProducts(this.state.editRow.id);
                        this.getOrderNotes(this.state.editRow.id);
                    }).catch((err)=>{
                        console.log(err);
                    });

                }).catch(err=>{
                    console.log(err);
                });

            }
            else {
                toast.warn("There was an issue updating the products.");
            }
        })
            .catch(err => {
                console.log(err);

            });
    }

    verifiedPatientRegistration() {
        const { currentUser, companyName } = this.context;
        if (companyName !== "corkmedical") {
            let order = this.state.order;
            order.patientRegistered = true;

            if (order.orderStatusReasonId === 62)
                order.orderStatusReasonId = 85;

            let txt = "Patient Registration confirmed correct by: " + currentUser.username + " on " + GlobalFunctions.formatDateTimeTimeZone(new moment());
            this.saveNote(order, txt);
            order.lastUpdatedBy = {
                id: currentUser.id,
                username: currentUser.username,
            };
            order.lastUpdatedById = currentUser.id;
            OrderService.updateOrder(order)
                .then(res => {
                    console.log(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    renderModal() {
        const { companyName } = this.context;
        let dataRow = this.state.editRow,
            ss = dataRow.sameOrSimilar,
            order = this.state.order,

            onFile =
				ss === "Change in Condition" ||
				ss === "Damage" ||
				ss === "Identified Same/Sim on File" ||
				ss === "Identified Patient in SNF";
        let t = this;
        let appr = order ? order.orderActivityApprovals ? this.state.order.orderActivityApprovals[0] ? this.state.order.orderActivityApprovals[0].approvals : null : null : null;


        function registrationActive() {
            return companyName !== "corkmedical" && order && order.patientRegistered !== true && order.orderType === "New";
        }

        function renderSSSection() {
            if (onFile === true) {
                return (
                    <MDBCol size="8">
                        <MDBInput
                            type={"checkbox"}
                            value={t.state.ssAcknowledge}
                            id={"sameSimCheckbox"}
                            onChange={(e) =>
                                t.setState({
                                    ssAcknowledge: e.target.checked,
                                })
                            }
                            label={"I acknowledge there is a same or similar on file."}
                        />
                    </MDBCol>
                );
            }
        }


        function renderRegistrationSection() {
            let disabled = !registrationActive();

            if (order && companyName !== "corkmedical") {
                return (
                    <div className={"patientRegistrationInfo"}>

                        <MDBCol size="2" style={{ display: "inline-block", verticalAlign: "middle" }}>
                            <MDBRow>{"Registration Status "} {(order.patientRegistered ? <div style={{color:"Green"}}>: Completed</div> : <div style={{color:"red"}}>: Not Completed</div>)}</MDBRow>
                            <MDBRow>{"Patient ID: " + order.patientId}</MDBRow>
                        </MDBCol>
                        {/*{order.scheduledDeliveryDate ?*/}
                        <div>
                            <MDBCol size="5" style={{ display: "inline-block" }}>
                                <MDBRow>
                                    <Dropdown className={"registrationDrop"}>
                                        <DropdownToggle caret color="blue 300" disabled={disabled} className={"registrationButtons"}>
												Send Registration Link
                                        </DropdownToggle>
                                        <DropdownMenu basic>
                                            <DropdownItem
                                                onClick={() => t.createPatientCommunication(order.patient.id, order.id, "SMS")}>
													Send SMS Link
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => t.createPatientCommunication(order.patient.id, order.id, "Email")}>
													Send Email Link
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Button
                                        className={"registrationButtons"}
                                        disabled={disabled}
                                        color={"blue"}
                                        size="md"
                                        onClick={() => t.togglePatientInfoRegistrationModal()}>
											Fill in for Patient
                                    </Button>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol size="3" style={{ display: "inline-block" }}>
                            </MDBCol>

                            <MDBCol size="2" style={{ display: "inline-block" }}>
                                <Button
                                    className={"registrationButtons"}
                                    disabled={disabled || (order && order.patientRegistered !== false)}
                                    color={"green"}
                                    size="md"
                                    onClick={() => t.verifiedPatientRegistration()}>
										Patient Registration is Complete
                                </Button>
                            </MDBCol>
                        </div>
                        {/*: ''}*/}
                    </div>
                );
            }
            else {
                return '';
            }
        }

        if (dataRow && dataRow.id) {

            // DateTime issue for java - The date is stored in UTC and sent in UTC,
            // changing the display is the best option currently
            // The following can be done on one line, but is very unreadable- Chip
            // let orgDate = dataRow.scheduledDeliveryDate,
            // 	parsedDate = null;
            // if (orgDate) {
            // 	parsedDate = GlobalFunctions.formatDateTime(orgDate)
            // }
            // let dbd = dataRow.deliverByDate
            // 	? new Date(dataRow.deliverByDate)
            // 	: "01/01/2999";

            this.getFlatOrder();
            return (
                <div className="overFlowFix">
                <Modal
                    //size="fluid"
                    className={"delModal"}
                    isOpen={this.state.toggleModal}
                    toggle={() => {
                        return;
                    }}
                >
                    <MDBModalHeader
                        className={"mHeaderTitle"}
                        style={{
                            backgroundColor: "#5581C1",
                            padding: "4px 10px",
                            color: "#FFF",
                        }}
                    >
                        <MDBRow>
                            <MDBCol size="6">{dataRow.patientName}</MDBCol>
                            <MDBCol size="6" style={{ textAlign: "right", paddingRight: 10 }}>
                                <span>Order Id: {dataRow.id}</span>
                            </MDBCol>
                            <Col md="12">
                                <h6 style={{ color: "red" }}>{this.state.errorMessage}</h6>
                            </Col>
                        </MDBRow>
                    </MDBModalHeader>

                    {/* render navTabs */ }
                    <Nav tabs className="nav-justified navFull" color={ "blue" }
                        style={ { marginTop: "-10px" } }>
                        <NavItem>
                            <NavLink
                                link
                                to="#"
                                className={ classnames({
                                    active: this.state.ModalTabsOneActiveItem === "1",
                                }) }
                                onClick={ () => {
                                    this.modalToggle("1", "1");
                                } }
                            >
                                Order Information
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                link
                                to="#"
                                className={ classnames({
                                    active: this.state.ModalTabsOneActiveItem === "2",
                                }) }
                                onClick={ () => {
                                    this.modalToggle("1", "2");
                                } }
                            >
                                Notes
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent
                        className="card deliveryNav"
                        activeItem={this.state.ModalTabsOneActiveItem}
                    >
                        <TabPane tabId="1" role="tabpanel">
                            <Row>
                                <MDBCol size="2" style={{ marginTop: -4 }}>
                                    <MDBInput
                                        outline
                                        disabled={true}
                                        value={dataRow.sameOrSimilar || "Not Specified"}
                                        label={"Same or Similar"}
                                    />

                                    <MDBInput
                                        outline
                                        disabled={true}
                                        value={dataRow.expectedShipDate || "Not Specified"}
                                        label={"Expected Ship Date"}
                                        />
                                        <MDBInput
                                            outline
                                            disabled={true}
                                            value={appr && appr.deliverByDate ? moment(appr.deliverByDate).format("MM-DD-YYYY") : "Not Specified"}
                                            label={"Deliver By Date"}
                                        />



                                    <div className={"uiOutlines"}>
                                        <MUIDateTimePicker
                                            disabled={this.disableCheck()}
                                            label="Scheduled Delivery Date"
                                            defaultValue={dataRow.scheduledDeliveryDate ? moment(GlobalFunctions.formatDateTime(dataRow.scheduledDeliveryDate)) : null}
                                            slotProps={{
                                                field: {
                                                    clearable: true,
                                                },
                                                textField: {
                                                    fullWidth: true,
                                                    size: "small",
                                                    variant: 'outlined',
                                                    InputLabelProps: {
                                                        shrink: true
                                                    },
                                                },
                                                layout: {
                                                    sx: {
                                                        ul: {
                                                            '::-webkit-scrollbar': {
                                                                width: '2px',
                                                            },
                                                            '::-webkit-scrollbar-thumb:hover': {
                                                                backgroundColor: MUIGreyColor[500],
                                                            },
                                                            '::-webkit-scrollbar-thumb': {
                                                                backgroundColor: MUIGreyColor[500],
                                                            },
                                                            '::-webkit-scrollbar-track': {
                                                                backgroundColor: MUIGreyColor[300],
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                            onChange={(value) => this.handleDeliveryDate(value)}
                                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                                shouldDisableDate={(date) => {
                                                    if (!appr || !appr.deliverByDate) {
                                                        return false;
                                                    }

                                                    const deliverByDate = moment(appr.deliverByDate);
                                                    return date.isAfter(deliverByDate, 'day');
                                                }}
                                        />
                                    </div>


                                    {this.renderServiceLocationDropdown()}
                                    {this.renderTechnicianDropdown()}

                                    {this.renderReasonsDropdown()}
                                    {/*{this.renderDisableNotification()}*/}
                                </MDBCol>
                                {dataRow.orderType.toLowerCase() !== "billing issue" ? (
                                    <MDBCol >{this.renderProductsList()}</MDBCol>
                                ) : null}
                            </Row>
                        </TabPane>
                        <TabPane tabId="2" role="tabpanel">
                            <MDBRow >
                                <MDBCol size={ 7 }>


                                    {/* {this.renderNotesList()} */ }
                                    <div style={ { overflowY: "scroll", maxHeight: "430px", overflowX: "hidden" } } >
                                        { this.state.orderNotes.map((note) => {
                                            return (
                                                <MDBCard style={ { backgroundColor: "#FFF7D1", margin: 6 } } key={ note.id }>

                                                    <MDBRow>
                                                        <MDBCol size="6">
                                                            { GlobalFunctions.formatDateTime(note.createdAt) }
                                                        </MDBCol>

                                                        <MDBCol size="5">
                                                            { note.createdBy ? note.createdBy.firstname + " " + note.createdBy.lastname : "" }
                                                        </MDBCol>
                                                    </MDBRow>


                                                    <MDBCardBody>
                                                        <p style={ { whiteSpace: "pre-line" } }>{ note.text }</p>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            );
                                        }) }
                                    </div>


                                </MDBCol>
                                <MDBCol>
                                    <MDBInput
                                        type="textarea"
                                        label="Add Note"
                                        onChange={this.handleNoteChange.bind(this)}
                                        outline
                                        style={{height: "400px"}}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </TabPane>
                    </TabContent>


                    {/*</ModalBody>*/}
                    <MDBRow>
                        <MDBCol size="12">{renderRegistrationSection()}</MDBCol>
                    </MDBRow>

                    <MDBRow style={{ justifyContent: "flex-end" }}>
                        {renderSSSection()}
                    </MDBRow>
                    <MDBModalFooter style={{padding: "5px"}}>

                        {registrationActive() ? <MDBCol className={"disableNotification"}>
							* Registration options disabled until the order is in New and Ready To Deliver
                        </MDBCol>: <div></div>}

                        <MDBCol size="2" style={{ float: "right", justifyContent: "right" }}>
                            <Button
                                floating
                                rounded
                                size="sm"
                                data-tip={"Save"}
                                //disabled={true}
                                disabled={onFile === true && this.state.ssAcknowledge !== true}
                                color={"success"}
                                onClick={() => {
                                    this.updateOrder();
                                }}
                            >
                                <MDBIcon fas icon="save" style={{ fontSize: "2em" }} />
                            </Button>
                            <Button
                                floating
                                size="sm"
                                color={"red"}
                                data-tip={"Cancel"}
                                onClick={() => this.toggleEditModal()}
                            >
                                <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                            </Button>
                        </MDBCol>
                        <ReactTooltip />
                    </MDBModalFooter>
                    </Modal>
                </div>
            );
        } else {
            return <div />;
        }
    }

    disableDelivery() {
        const {currentUser} = this.context;

        if (
            currentUser.role === "WAREHOUSE_TECHNICIAN" || currentUser.role === "ADMIN"
        ) {
            return false;
        }
    }

    disableCheck() {
        //const { companyName } = this.context;
        let products = this.state.orderProducts,
            //order = this.state.order,
            result = false;

        if (this.state.editRow && this.state.editRow.orderType.toLowerCase() === "billing issue") {
            return false;
        }
        
        if(products && products.length === 0){
            return true;
        }
        else
            result = false;

        //if (order.orderType === "New" && companyName !== "corkmedical") {
        //	products.map(product => {
        //		if (!product.btitemId || product.btitemId === "null" || (product.productType && product.productType.requiresSerialNumber === true && (!product.serial || product.serial === ""))) {
        //			result = true;
        //		}
        //		return result;
        //	});
        //}
        //if (result === false) {
        //	if (["local", "uat", "rehabmedical"].includes(companyName) && order && [2, 26].includes(order.serviceLocationId)) {
        //		result = order.patientRegistered !== true && order.orderType === "New";
        //  }
        //}


        if (result === false){
            return this.disableDelivery();
        }else
            return result;

    }

    modalToggle = (tabset, tab) => {
        if (this.state.ModalTabsOneActiveItem !== tab) {
            this.setState({
                ModalTabsOneActiveItem: tab,
            });
        }
    };

    // renderDisableNotification() {
    // 	const { companyName } = this.context;
    // 	if(this.disableCheck() && companyName !== "corkmedical"){
    // 		return (
    // 			<div className={"disableNotification"}>
    // 				*All products need a brightree id and serial number before you can schedule
    // 			</div>
    // 		);
    // 	}
    // }

    renderReasonsDropdown() {
        const { reasons } = this.context;

        let o = this.state.editRow;

        return (
            <div className={"uiOutlines smallMargin"}>
                <MUITextField
                    disabled={this.disableDelivery()}
                    id="outlined-select-statusReason"
                    select
                    size="small"
                    fullWidth
                    label="Status Reason"
                    value={o.reasonId || ""}
                    onChange={(e) => this.handleReasonChange(e.target.value)}
                    variant="outlined"
                >
                    {reasons.map((r, idx) => {
                        //2 = readyToDeliver
                        if (r.orderStatus === 2) {
                            if (r.description === "Scheduled") {
                                if (!o.technicianId || !o.scheduledDeliveryDate) {
                                    return (
                                        <MUIMenuItem disabled key={idx} value={r.id}>
                                            {r.description}
                                        </MUIMenuItem>
                                    );
                                }
                            }

                            if (o.orderType === "Pickup") {
                                //Only show the options for "Scheduled" id 13  and "No Answer / Not Able to Deliver" id 20 for Pickup tickets
                                if (r.id === 20 || r.id === 13 || o.reason === r.description) {
                                    return (
                                        <MUIMenuItem key={idx} value={r.id}>
                                            {r.description}
                                        </MUIMenuItem>
                                    );
                                }

                            }
                            else {
                                return (
                                    <MUIMenuItem key={idx} value={r.id}>
                                        {r.description}
                                    </MUIMenuItem>
                                );
                            }
                        }
                        return null;
                    })}
                </MUITextField>
            </div>
        );
    }

    // toggleNoteCollapse = () => {
    //     this.setState({
    //         noteToggle: !this.state.noteToggle,
    //     });
    // };

    handleNoteChange = (e) => {
        this.setState({
            nNote: e.target.value,
        });
    };


    renderCollapse(item) {
        if (item.deliveries.length === 0) {
            return <div/>;
        }
        return (
            <Collapse isOpen={item.isOpen}>
                {item.deliveries.map((del, index) => {
                    return (
                        <Row key={index}>
                            <Col md={"8"}>
                                <span style={{paddingLeft: "10px"}}>{del.date}</span>
                            </Col>
                            <Col md={"4"}>
                                <span
                                    data-for="notes"
                                    style={{
                                        cursor: "pointer",
                                        textAlign: "right",
                                        margin: 5,
                                    }}
                                    onClick={() => this.printTechTooltip(item.name, del.date)}
                                    onMouseEnter={() =>
                                        this.getTooltipContent(del.orders, item.name, del.date)
                                    }
                                    onMouseLeave={() => this.setState({techTooltip: ""})}
                                >
                                    {del.orders.length}
                                </span>
                            </Col>
                        </Row>
                    );
                })}
                <hr style={{borderColor: "#555555", borderWidth: "3px"}}/>
            </Collapse>
        );
    }

    renderSideBar() {
        let ds = this.state.dataTechSchedule;
        return (
            <CardBody style={ { overflowY: "scroll" } }>
                <MDBSelect
                    color="info"
                    selected={ this.state.selectedLocationText || "Choose Location" }
                    style={ { margin: "5px" } }
                    options={ this.state.locationList }
                    getValue={ this.handleLocationChange }
                />

                <div>
                    <div
                        style={ {
                            paddingRight: 0,
                            width: "100%",
                            paddingTop: "10px",
                        } }
                    >
                        { this.state.techScheduleLoaded && ds.length > 0 ? (
                            ds.map((item, id) => {
                                return (
                                    <div key={ id } style={ { maxWidth: "90%" } }>
                                        <div
                                            style={ {
                                                paddingTop: "6px",
                                                paddingBottom: "6px",
                                                cursor: "pointer",
                                            } }
                                            onClick={ () => this.toggleTechRow(id) }
                                        >
                                            <MDBIcon
                                                icon="caret-down"
                                                style={ { color: item.fill, fontSize: 28 } }
                                            />

                                            <h6 style={ { display: "inline" } }>{ item.name }</h6>
                                            <hr
                                                style={ { borderColor: "#555555", borderWidth: "3px" } }
                                            />
                                        </div>

                                        { this.renderCollapse(item) }
                                    </div>
                                );
                            })
                        ) : this.state.selectedLocationIds.length > 0 &&
                            !this.state.techScheduleLoaded ? (
                                <Container className="mt-5">
                                    <div style={ { textAlign: "center", verticalAlign: "center" } }>
                                        <Spinner multicolor />
                                    </div>
                                </Container>
                            ) : (
                                <div />
                            ) }
                    </div>
                    <ReactTooltip />
                </div>
            </CardBody>
        );
    }

    renderTabs() {
        let unscheduled = this.state.dataNotScheduled;

        return (
            <Nav
                style={ { padding: 2, boxShadow: 'none' } }
                tabs
                className="nav-justified navFull"
                color={ "info" }

            >
                <NavItem>
                    <NavLink
                        link
                        to="#"
                        className={classnames({
                            active: this.state.TabsOneActiveItem === "3",
                        })}
                        onClick={() => {
                            this.toggle("3", "3");
                        }}
                    >
                            Ready To Schedule - {unscheduled ? unscheduled.length : 0}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <Row>
                        <Col md={"8"}>
                            <NavLink
                                link
                                to="#"
                                className={classnames({
                                    active: this.state.TabsOneActiveItem === "4",
                                })}
                                onClick={() => {
                                    this.toggle("4", "4");
                                }}
                            >
                                    Map
                            </NavLink>
                        </Col>
                        <Col md={"2"}>
                            <Button
                                floating
                                size="sm"
                                style={{margin: "0"}}
                                color={"secondary"}
                                title={"View Map in New Window"}
                                onClick={this.openMapWindow.bind(this)}
                            >
                                <MDBIcon icon="window-restore"/>
                            </Button>
                        </Col>
                        <Col md={"2"}>
                            <Button
                                floating
                                size="sm"
                                style={{margin: "0"}}
                                color={"indigo"}
                                title={"Download CSV"}
                                onClick={this.clientCSV.bind(this)}
                            >
                                <MDBIcon icon="download"/>
                            </Button>
                        </Col>
                    </Row>
                </NavItem>
            </Nav>
        );
    }

    patientCalledRadio = (result) => () => {
        this.setState({
            callRadio: result,
        });
    };

    generatePhoneLink(phone) {
        let regEx = /\(?\d{3}\)?\s*-?\s*\d{3}\s*-?\s*\d"4"/;
        return phone.match(regEx);
    }

    renderCallPatientModal() {
        let st = this.state,
            order = st.equipOrder,
            phoneLink = order.phone ? this.generatePhoneLink(order.phone) : "";
        return (
            <Modal
                centered
                isOpen={st.callPatientModal}
                toggle={() => this.toggleCallPatientModal()}
            >
                <ModalBody>
                    <CardHeader
                        style={{marginBottom: "1rem"}}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>{order ? order.patientName : ""}</h3>
                    </CardHeader>
                    <div align="left">
                        {phoneLink ? (
                            <a className={"callPatientLink"} href={"tel:+1" + phoneLink[0]}>
                                <MDBIcon
                                    style={{margin: "0 10px 0 0"}}
                                    size="lg"
                                    data-tip={phoneLink[0]}
                                    icon="phone"
                                />
                                {"Call Patient: " + phoneLink[0]}
                            </a>
                        ) : (
                            <MDBInput
                                value={order.phone}
                                icon="phone"
                                group
                                disabled={true}
                                type="text"
                            />
                        )}
                        <MDBInput
                            value={order.patientName}
                            icon="user"
                            group
                            disabled={true}
                            type="text"
                        />
                        <MDBInput
                            value={
                                order.address +
                                ", " +
                                order.city +
                                "\n" +
                                order.state +
                                " " +
                                order.zipCode
                            }
                            icon="home"
                            group
                            className="text-left"
                            disabled={true}
                            type="textarea"
                        />
                        <MDBInput
                            value={order.category + " - " + order.product}
                            icon="wheelchair"
                            group
                            disabled
                            type="text"
                        />
                        <MDBInput
                            className="callPatientInput"
                            rows="2"
                            onChange={(e) => {
                                this.setState({callPatientNote: e.target.value});
                            }}
                            label={"Add notes..."}
                            icon="notes-medical"
                            group
                            type="textarea"
                        />
                        <MDBFormInline>
                            <MDBInput
                                onClick={this.patientCalledRadio(1)}
                                checked={st.callRadio === 1}
                                label="Answered"
                                type="radio"
                                id="radio1"
                            />
                            <MDBInput
                                onClick={this.patientCalledRadio(2)}
                                checked={st.callRadio === 2}
                                label="Didn't answer"
                                type="radio"
                                id="radio2"
                            />
                        </MDBFormInline>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        floating
                        size="sm"
                        color={"danger"}
                        data-tip={"Close"}
                        onClick={() => this.toggleCallPatientModal()}
                    >
                        <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                    </Button>
                    <Button
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Save Order"}
                        onClick={this.handleCallPatient}
                    >
                        <MDBIcon icon="save" style={{fontSize: "2em"}}/>
                    </Button>
                </ModalFooter>
                <ReactTooltip/>
            </Modal>
        );
    }

    handleCallPatient = () => {
        let st = this.state,
            cr = st.callRadio,
            order = st.equipOrder,
            note = st.callPatientNote;

        if (!cr) {
            toast.warn("Please select if patient answered");
            return;
        }

        this.saveNote(order, note);

        let callLog = {
            answered: cr === 1,
            orderId: order.id,
        };

        CallLogService.createCallLog(callLog)
            .then((res) => {
                this.setState({
                    callRadio: null,
                });
                this.toggleCallPatientModal();
                this.retrieveDeliveries(this.state.selectedLocationIds);
                this.refreshMapWindow(this.state.selectedLocationIds[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    renderEquipApprovalModal() {
        let st = this.state,
            order = st.equipOrder;
        return (
            <Modal
                centered
                isOpen={st.equipApprovalModal}
                toggle={() => this.toggleEquipmentApprovalModal()}
            >
                <ModalBody>
                    <CardHeader
                        style={{marginBottom: "1rem"}}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Equipment Acknowledgment</h3>
                    </CardHeader>
                    <div align="left">
                        <MDBInput
                            key={order.id}
                            id={"equipCheckBox"}
                            onChange={this.handleEquipmentApproval.bind(this)}
                            value={st.equipApproval}
                            label={
                                "I hereby acknowledge that all equipment have been ordered for order " +
                                order.id
                            }
                            type="checkbox"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <MDBBtn
                        color="secondary"
                        onClick={() => this.toggleEquipmentApprovalModal()}
                    >
                        Close
                    </MDBBtn>
                    <MDBBtn
                        disabled={!st.equipApproval}
                        color="primary"
                        onClick={() => this.equipAcknowledgeClicked(order)}
                    >
                        Acknowledge
                    </MDBBtn>
                </ModalFooter>
            </Modal>
        );
    }

    equipAcknowledgeClicked(order) {
        const {currentUser} = this.context;
        let note =
            currentUser.username +
            " acknowledged that all equipment has been ordered for order " +
            this.state.equipOrder.id;
        order.equipmentOrderedDate = new Date().toLocaleString();
        this.updateOrder(order, note);
    }

    toggleEquipmentApprovalModal = () => {
        this.setState({
            equipApprovalModal: !this.state.equipApprovalModal,
        });
    };

    toggleCallPatientModal = () => {
        this.setState({
            callPatientModal: !this.state.callPatientModal,
        });
    };

    handleEquipmentApproval = (event) => {
        this.setState({equipApproval: event.target.checked});
    };

    togglePatientInfoRegistrationModal = () => {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            this.setState({
                patientInfoModalOpen: !this.state.patientInfoModalOpen,
            });
        }
    };

    updatePatientRegistration(value) {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            let order = this.state.order;
            order.patientRegistered = value;
            if (value === false) {
                order.orderStatusReasonId = 62;
            }
            OrderService.updateOrder(order)
                .then(res => {
                    console.log(res);
                    this.togglePatientInfoRegistrationModal();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    renderPatientInfoRegistrationModal() {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            let order = this.state.order,
                modalOpen = this.state.patientInfoModalOpen;

            if (order && order.salesLocation && [2, 26].includes(order.salesLocationId)) {
                return (
                    <InformationChangeModal
                        key={this.state.order.id}
                        cancelFunction={() => { this.togglePatientInfoRegistrationModal(); }}
                        submitFunction={(e) => { this.updatePatientRegistration(e); }}
                        activeOrder={order}
                        modalOpen={modalOpen} />
                );
            }
            else {
                return <div/>;
            }
        }
    }

    renderContent() {
        if (this.state.firstLoad) {
            setTimeout(() => {
                this.setState({ firstLoad: false });
            }, 200);
        }
        if (this.state.firstLoad) {
            return <MDBCol>
                <Spinner small />
            </MDBCol>;
        }
        let h = window.innerHeight,
            c1 = 2,
            c2 = 10;

        if (!this.state.isOpen) {
            c1 = 0;
            c2 = 12;
        }

        return (
            <Container style={ { width: "98%", maxWidth: "98%", marginTop: '30px' } }>
                {this.renderModal()}
                {this.renderEquipApprovalModal()}
                {this.renderCallPatientModal()}
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />

                {/* renderCollapseButton */ }
                <div
                    style={ {
                        position: "absolute",
                        top: "13%",
                        left: ".4rem",
                        zIndex: "50000",
                    } }
                >
                    <Button
                        floating
                        size="sm"
                        color={ "orange" }
                        data-tip={ "Hide/Show Filters" }
                        onClick={ () => this.toggleCollapse() }
                    >
                        <MDBIcon icon="filter" style={ { fontSize: "2em" } } />
                    </Button>
                    <ReactTooltip />
                </div>


                {/* renderTooltipCards*/ }
                <div id={ "techTooltip" } className={ "techTooltipBoxOuter" }>
                    <div className="wrapper">
                        { this.state.techTooltip ? (
                            this.state.techTooltip.map((c, idx) => {
                                return (
                                    <div className={ "techTooltipBox" } key={ idx }>
                                        { c }
                                    </div>
                                );
                            })
                        ) : (
                            <div />
                        ) }
                    </div>
                </div>


                <Row>
                    <Col size={ c1 }>
                        <Collapse
                            delay={{ show: 100, hide: 0 }}
                            id="accordion1"
                            isOpen={this.state.isOpen}
                        >
                            <Card
                                style={{
                                    height: h * 0.8,
                                }}
                            >
                                {this.renderSideBar()}
                            </Card>
                        </Collapse>
                    </Col>
                    <Col size={ c2 }>
                        <div>{ this.renderTabs() }</div>
                        <div>

                            <TabContent
                                style={ {
                                    padding: 0,
                                    backgroundColor: "transparent",
                                } }
                                activeItem={ this.state.TabsOneActiveItem }
                            >
                                {/*<TabPane tabId="1" role="tabpanel"*/ }
                                {/*         style={{backgroundColor: '#dddddd'}}>*/ }
                                {/*    <ApprovedGrid*/ }
                                {/*        isLoaded={this.state.isLoaded}*/ }
                                {/*        selectedLocationIds={this.state.selectedLocationIds}*/ }
                                {/*        dataApproved={this.state.dataApproved}*/ }
                                {/*        callPatient={this.callPatient.bind(this)}*/ }
                                {/*        updateStatusReason={this.updateStatusReason.bind(this)}*/ }
                                {/*    />*/ }
                                {/*</TabPane>*/ }
                                {/*<TabPane tabId="2" role="tabpanel"*/ }
                                {/*         style={{backgroundColor: '#dddddd'}}>*/ }
                                {/*    <EquipmentOrderedGrid*/ }
                                {/*        isLoaded={this.state.isLoaded}*/ }
                                {/*        selectedLocationIds={this.state.selectedLocationIds}*/ }
                                {/*        dataEquipmentOrdered={this.state.dataEquipmentOrdered}*/ }
                                {/*        updateStatusReason={this.updateStatusReason.bind(this)}*/ }
                                {/*        updateEquipmentOrderedDate={this.updateEquipmentOrderedDate.bind(this)}*/ }
                                {/*        callPatient={this.callPatient.bind(this)}*/ }
                                {/*        renderEditOrderModal={this.renderEditOrderModal.bind(this)}*/ }
                                {/*    />*/ }
                                {/*</TabPane>*/ }
                                <TabPane
                                    tabId="3"
                                    role="tabpanel"
                                    style={ { backgroundColor: "#dddddd" } }
                                >
                                    <ReadyToScheduleGrid
                                        history={ this.props.history }
                                        isLoaded={ this.state.isLoaded }
                                        selectedLocationIds={ this.state.selectedLocationIds }
                                        dataNotScheduled={ this.state.dataNotScheduled }
                                        renderEditOrderModal={ this.renderEditOrderModal.bind(this) }
                                        gotoMap={ this.goToOrderOnMap.bind(this) }
                                    />
                                </TabPane>
                                <TabPane
                                    tabId="4"
                                    role="tabpanel"
                                    style={ { backgroundColor: "#dddddd" } }
                                >
                                    <div
                                        id={ "mapHost" }
                                        style={ { height: h * 0.7, width: "100%" } }
                                    >
                                        <StreetMap
                                            id={ "mapRef" }
                                            editHandler={ this.renderEditOrderModal.bind(this) }
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                </Row>
                {this.renderPatientInfoRegistrationModal()}
                <ReactTooltip />
            </Container>
        );
    }
}
