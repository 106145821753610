import Admin from "../Admin";
import React from "react";
import CardAdminTeamGeneral from "../../../Component/Card/Admin/Team/CardAdminTeamGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import Validator from "../../../Utility/Validator";
import TeamService from "../../../Seating/Security/TeamService/teamService";
import CardAdminTeamManagement from "../../../Component/Card/Admin/Team/CardAdminTeamManagement";
import CardAdminTeamUsers from "../../../Component/Card/Admin/Team/CardAdminTeamUsers";
import CardAdminTeamQueue from "../../../Component/Card/Admin/Team/CardAdminTeamQueue";
import TeamUserService from "../../../Seating/Security/TeamUserService/teamUserService";
import TeamQueueService from "../../../Seating/Security/TeamQueueService/teamQueueService";
import Team from "../../../Utility/Crud/Team";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminTeamCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.isUpdate = !!props.match.params.id;
        this.state.team = null;
        this.state.teamUsers = [];
        this.state.teamQueues = [];
        this.state.isProcessing = false;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Set the team and teamUsers in state.
     */
    afterComponentDidMount() {
        const teamId = this.props.match.params.id ? +this.props.match.params.id : null;
        const teamUsers = Object.values(this.context.teamUsersIndexed).filter((teamUser) => teamUser.teamId === teamId);
        const teamQueues = Object.values(this.context.teamQueuesIndexed).filter((teamQueue) => teamQueue.teamId === teamId);
        if (teamId !== null) {
            this.setState({
                team: this.context.teamsIndexed[teamId],
                teamUsers: teamUsers,
                teamUsersOriginal: teamUsers,
                teamQueues: teamQueues,
                teamQueuesOriginal: teamQueues,
            });
        }
    }

    /**
     * Creates the team with the current properties in state.team.
     */
    async createTeam() {
        this.setState({
            isProcessing: true,
        });

        return TeamService.createTeam(this.state.team)
            .then(team => {
                this.context.updateIndexed("teamsIndexed", team);

                this.setState({
                    teamUsers: this.state.teamUsers.map((teamUser) => {
                        return {
                            ...teamUser,
                            ...{
                                teamId: team.id
                            }
                        };
                    }),
                    teamQueues: this.state.teamQueues.map((teamQueue) => {
                        return {
                            ...teamQueue,
                            ...{
                                teamId: team.id
                            }
                        };
                    })
                });

                return this.createDeleteTeamUsers();
            })
            .then(() => {
                return this.createDeleteTeamQueues();
            })
            .then(() => {
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Team created successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Team", {variant: "error"});
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
    * Updates the team with the current properties in state.team.
     */
    async updateTeam() {
        this.setState({
            isProcessing: true,
        });
        const promises = [];

        promises.push(
            TeamService.updateTeam(this.state.team).then(team => {
                this.context.updateIndexed("teamsIndexed", team);
            })
        );
        promises.push(this.createDeleteTeamUsers());
        promises.push(this.createDeleteTeamQueues());

        return Promise.all(promises)
            .then(() => {
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Team updated successfully!", {variant: "success"});
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Team", {variant: "error"});
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
    * Creates or deletes team users from team.
    */
    async createDeleteTeamUsers() {
        const promises = [];

        this.state.teamUsers.forEach((teamUser) => {
            if (!teamUser.id) {
                promises.push(
                    TeamUserService.createTeamUser(teamUser).then((teamUser) => {
                        this.context.updateIndexed("teamUsersIndexed", teamUser);
                    })
                );
            }
        });

        if (this.state.teamUsersOriginal) {
            this.state.teamUsersOriginal.forEach((teamUserOriginal) => {
                if (
                    this.state.teamUsers.find((teamUser) => {
                        return teamUser.userId === teamUserOriginal.userId;
                    }) === undefined
                ) {
                    promises.push(
                        TeamUserService.deleteTeamUser(teamUserOriginal.id).then(() => {
                            this.context.deleteIndexed("teamUsersIndexed", teamUserOriginal.id);
                        })
                    );
                }
            });
        }

        return Promise.all(promises);
    }

    /**
    * Creates or deletes team queues from team.
    */
    async createDeleteTeamQueues() {
        const promises = [];

        this.state.teamQueues.forEach((teamQueue) => {
            if (!teamQueue.id) {
                promises.push(
                    TeamQueueService.createTeamQueues(teamQueue).then((teamQueue) => {
                        this.context.updateIndexed("teamQueuesIndexed", teamQueue);
                    })
                );
            }
        });

        if (this.state.teamQueuesOriginal) {
            this.state.teamQueuesOriginal.forEach((teamQueueOriginal) => {
                if (
                    this.state.teamQueues.find((teamQueue) => {
                        return teamQueue.queueId === teamQueueOriginal.queueId;
                    }) === undefined
                ) {
                    promises.push(
                        TeamQueueService.deleteTeamQueues(teamQueueOriginal.id).then(() => {
                            this.context.deleteIndexed("teamQueuesIndexed", teamQueueOriginal.id);
                        })
                    );
                }
            });
        }

        return Promise.all(promises);
    }

    /**
     * Delete the team.
     */
    async deleteTeam() {
        this.setState({
            isProcessing: true,
        });

        return TeamService.deleteTeam(this.state.team.id)
            .then(() => {
                this.context.deleteIndexed("teamsIndexed", this.state.team.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Team deleted successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Team", {variant: "error"});
                this.setState({
                    confirmDialogOpen: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChangeTeam = (team) => {
            this.setState({
                team: {
                    ...this.state.team,
                    ...team
                },
            });
        };

        const handleChangeTeamUsers = (teamUsers) => {
            this.setState({
                teamUsers: teamUsers
            });
        };

        const handleChangeTeamQueues = (teamQueues) => {
            this.setState({
                teamQueues: teamQueues
            });
        };

        return (
            <>
                <CardAdminTeamGeneral
                    onChange={handleChangeTeam}
                    team={this.state.team}
                    validationIssues={this.state.validationIssues}
                    department={this.state.team ? this.context.departmentsIndexed[this.state.team.departmentId] : null}
                />
                <CardAdminTeamManagement
                    team={this.state.team}
                    onChange={handleChangeTeam}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminTeamUsers
                    team={this.state.team}
                    teamUsers={this.state.teamUsers}
                    onChange={handleChangeTeamUsers}
                />
                <CardAdminTeamQueue
                    team={this.state.team}
                    teamQueues={this.state.teamQueues}
                    onChange={handleChangeTeamQueues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteTeam()}
                    text="Are you sure you want to delete this team? This cannot be undone."
                    header="Delete Team"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * @returns Page header
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Team';
            subtitle = null;
        } else {
            title = 'Edit Team';
            subtitle = (this.state.team && (Team.getDisplayName(this.state.team) + " • " + this.state.team.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleSave = () => {
            const validationIssues = Validator.validate(
                this.state.team,
                {
                    name: {
                        'required': true
                    },
                    departmentId: {
                        'required': true
                    }
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateTeam();
                } else {
                    this.createTeam();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", {variant: "error"});
            }
        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
