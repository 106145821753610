import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import DocumentType from "../../../../Utility/Crud/DocumentType";

export default withRouter(class CardAdminDocumentTypeList extends Card {
    static contextTypes = {
        documentTypesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                ]}
                rows={DocumentType.sort(Object.values(this.context.documentTypesIndexed))}
                onClick={(documentType) => {
                    this.props.history.push({
                        pathname: "/admin/documentType/" + documentType.id
                    });
                }}
            />
        );
    }
});