import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import InsuranceType from "../../../../Utility/Crud/InsuranceType";

export default withRouter(class CardAdminInsuranceTypeList extends Card {
    static contextTypes = {
        insuranceTypesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                    { key: "sortOrder", name: "Ordinal", type: "number", width: "140"},
                ]}
                rows={InsuranceType.sort(Object.values(this.context.insuranceTypesIndexed))}
                onClick={(insuranceType) => {
                    this.props.history.push({
                        pathname: "/admin/insuranceType/" + insuranceType.id
                    });
                }}
            />
        );
    }
});