import React from "react";
import MUIGrid from "@mui/material/Grid";
import MUIAutocomplete from "@mui/material/Autocomplete";
import MUITextField from "@mui/material/TextField";
import moment from "moment";
import QuickFilter from "../../Seating/Filters/quickFilter";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import ReportSetting from "../ReportSetting";

export default class ReportSettingDateRange extends ReportSetting {
    /**
     * Renders a date range selector.
     */
    render() {
        const handlePresetChange = (e, value) => {
            if (value) {
                const dates = QuickFilter.getDates(value.value);

                this.handleSettingChange("beginDate", moment(dates[0]).format("YYYY-MM-DD"));
                this.handleSettingChange("endDate", moment(dates[1])
                    .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                this.handleSettingChange("preset", value);
            } else {
                this.handleSettingChange("beginDate", null);
                this.handleSettingChange("endDate", null);
                this.handleSettingChange("preset", null);
            }
        };

        const handleDateChange = (key, value) => {
            if (value) {
                if (key === "endDate") {
                    this.handleSettingChange(key, moment(value)
                        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                    );
                } else {
                    this.handleSettingChange(key, moment(value)
                        .format("YYYY-MM-DD")
                    );
                }

                this.handleSettingChange("preset", { label: "Custom", value: 9 });
            } else {
                this.handleSettingChange(key, null);
                this.handleSettingChange("preset", null);
            }
        };

        return (
            <MUIGrid container spacing={1}>
                <MUIGrid item xs={12} sm={6} md={2}>
                    <MUIDatePicker
                        label="Begin Date"
                        value={this.state.settings.beginDate ? moment(this.state.settings.beginDate) : null}
                        slotProps={{
                            field: {
                                clearable: true,
                            },
                            textField: {
                                fullWidth: true,
                                size: "small",
                                variant: "standard",
                                required: true,
                                error: this.props.showValidationIssues && !!this.props.validationIssues?.beginDate,
                                helperText: this.props.showValidationIssues && this.props.validationIssues?.beginDate ? this.props.validationIssues?.beginDate : "",
                                InputLabelProps: {
                                    shrink: true
                                }
                            },
                        }}
                        onChange={(date) => handleDateChange("beginDate", date)}
                        views={["year", "month", "day"]}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={2}>
                    <MUIDatePicker
                        label="End Date"
                        value={this.state.settings.endDate ? moment(this.state.settings.endDate) : null}
                        slotProps={{
                            field: {
                                clearable: true,
                            },
                            textField: {
                                fullWidth: true,
                                size: "small",
                                variant: "standard",
                                required: true,
                                error: this.props.showValidationIssues && !!this.props.validationIssues?.endDate,
                                helperText: this.props.showValidationIssues && this.props.validationIssues?.endDate ? this.props.validationIssues?.endDate : "",
                                InputLabelProps: {
                                    shrink: true
                                },
                            },
                        }}
                        onChange={(date) => handleDateChange("endDate", date)}
                        views={["year", "month", "day"]}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={2}>
                    <MUIAutocomplete
                        options={QuickFilter.getOptions()}
                        isOptionEqualToValue={(option, value) => {
                            return option.value === value.value;
                        }}
                        value={this.state.settings.preset || null}
                        onChange={handlePresetChange}
                        renderInput={(params) => {
                            return (
                                <MUITextField
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    label="Preset"
                                    variant="standard"
                                    fullWidth
                                />
                            );
                        }}
                    />
                </MUIGrid>
            </MUIGrid>
        )
    }
}