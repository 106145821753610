import api from "../api";

class ContactService {
    async getAllContacts(filters) {
        return new Promise((res, reject) => {
            let params = {
                ids: filters.ids || "",
                q: filters.q || "",
            };
            api
                .get("/contacts", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    async getAccountContacts(id) {
        return new Promise((res, reject) => {
            api
                .get("/sources/" + id + "/contacts")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getContact(id) {
        return new Promise((res, reject) => {
            api
                .get("/contacts/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createContact(con) {
        return new Promise((res, reject) => {
            api
                .post("/contacts/", con)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findAllContactTypes() {
        return new Promise((res, reject) => {
            api
                .get("/contactTypes/")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateContact(contact) {
        return api.put("/contacts", contact);
    }
}

const contactService = new ContactService();

export default contactService;
