import React from "react";
import moment from "moment";
import { toast } from "mdbreact";
import Select from "react-select";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import MUIDialogActions from "@mui/material/DialogActions";
import MUITypography from "@mui/material/Typography";
import MUIBox from '@mui/material/Box';
import MUIButton from "@mui/material/Button";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUITextField from "@mui/material/TextField";
import MUIRadio from "@mui/material/Radio";
import MUIRadioGroup from "@mui/material/RadioGroup";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
import MUIFormControl from "@mui/material/FormControl";
import MUIFormLabel from "@mui/material/FormLabel";
import MUIContainer from "@mui/material/Container";
import NoteService from "../../Seating/Security/NoteService/noteService";
import OrderService from "../../Seating/Security/OrderService/orderService";

export default class DialogAddNote extends Dialog {
    constructor(props) {
        super(props);

        this.addNoteUpdateOrderCloseModal = props.addNoteUpdateOrderCloseModal;
        this.toggleProcessing = props.toggleProcessing;
        this.refreshNotes = props.getNotes;
        this.handleRouting = props.handleRouting;

        this.state = {
            saving: false,
            eOrder: props.eOrder,
            usedLiaisonKeys: [],
            resultingLiaisonKeys: [],
            existingMeeting: {},
            allNotes: props.allNotes,
            noteText: props.text,
            open: false,
            selectedNoteRadio: "Note",
            //noteAssignedTo: props.isOrderDetails ? props.noteAssignedTo || null : props.eOrder.activity.owner || null,
            noteAssignedToId: props.isOrderDetails ? props.noteAssignedToId || null : (props.eOrder && props.eOrder.activity ? props.eOrder.activity.ownerId : null),
            usersList: [],
            isOrderDetails: props.isOrderDetails,
            approvalAction: props.approvalAction,
            queueRoute: props.queueRoute,
            activityId: props.isAutoVerification ? props.verificationNoteActivityId : (props.eOrder ? props.eOrder.id : null),
            followupDays: 5
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allUsers: PropTypes.array,
        queueRoutesIndexed: PropTypes.object,
    };

    componentDidMount() {
        this.getUserList();
    }

    componentDidUpdate() {
        const { allUsers } = this.context;
        if (allUsers.length !== this.state.usersList.length) {
            this.getUserList();
        }
    }

    validate() {
        if (this.state.noteText === null || this.state.noteText.trim() === "") {
            toast.error("You must add note text!");
            return false;
        }
        return true;
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.open !== prevState.open) {
            return {
                open: nextProps.open,
                eOrder: nextProps.eOrder,
            };
        } else return null;
    }

    getUserList() {
        const { allUsers } = this.context;
        let a = [];

        allUsers.map(x => {
            return a.push({
                label: x.firstname + " " + x.lastname,
                value: x.id,
            });
        });
        this.setState({ usersList: a });
    }

    handleNoteUserSelected = (e) => {
        let user = { name: e.label, id: e.value };
        this.setState({
            noteAssignedTo: user,
        });
    };

    addNewNote() {
        let shouldContinue = this.validate();

        if (!shouldContinue) {
            return;
        }

        if (this.state.saving) {
            return;
        }
        this.setState({
            saving: true
        });

        this.toggleProcessing();

        let queueRouteName = "";
        if (this.state.isOrderDetails || this.state.queueRoute) {
            const { queueRoutesIndexed } = this.context;
            let queueRoute = queueRoutesIndexed[this.state.queueRoute].name.replace(/\s/g, '').toLowerCase();

            if (queueRoute.includes("unabletoapprove(insurance)")) {
                queueRouteName = "unableInsurance";
            } else if (queueRoute.includes("unabletoapprove(insurance)")) {
                queueRouteName = "unableInsurance";
            } else if (queueRoute.includes("unabletoapprove(equipment)")) {
                queueRouteName = "unableEquipment";
            } else {
                return null;
            }
        }

        const { currentUser } = this.context;

        let cb = currentUser; //{ id: currentUser.id, username: currentUser.username };

        if (this.state.selectedNoteRadio === "Followup" && this.state.eOrder.orderStatusReasonId === 57) {
            let followupNote = {
                orderId: this.state.eOrder.id,
                noteText: this.state.noteText,
                contactType: this.state.selectedServiceDocFollowupRadio,
                userId: cb.id,
                reasonId: this.state.eOrder.orderStatusReasonId,
            };

            NoteService.addSdfuNote(followupNote)
                .then((res) => {
                    this.setState({ saving: false });
                    this.props.onClose();
                    if (this.refreshNotes) {
                        this.refreshNotes();
                    }
                    toast.success("Contact Attempt Noted");
                })
                .catch((err) => {
                    this.setState({ saving: false });
                    console.log(err);
                    toast.error("Error: Contact attempt not saved");
                });
        } else {
            let note = {
                task: this.state.selectedNoteRadio === "Task" ? true : false,
                completed: this.state.selectedNoteRadio === "Task" ? false : true,
                //assignedTo: this.state.noteAssignedTo,
                assignedToId: this.state.noteAssignedTo ? this.state.noteAssignedTo.id : null,
                createdBy: cb,
                createdById: cb.id,
                //account: this.state.isOrderDetails ? this.state.eOrder.account : this.state.eOrder.activity.account,
                accountId: this.state.isOrderDetails ? this.state.eOrder.accountId : (this.state.eOrder && this.state.eOrder.activity ? this.state.eOrder.activity.accountId : null),
                patientName: this.state.eOrder ? this.state.eOrder.patientName : "",
                type: "ActivityNote",
                activityId: this.state.activityId,
                dueDate: null,
                isAutoVerification: this.props.isAutoVerification,
            };

            let eOrder = this.state.eOrder || this.props.eOrder

            if (this.props.isAutoVerification) {
                eOrder.insuranceVerified = this.state.selectedVerificationNoteRadio 
            }

            note.text = this.state.noteText;
            let followupNote = {};

            function addWeekdays(date, days) {
                date = moment(date); // use a clone
                while (days > 0) {
                    date = date.add(1, "days");
                    // decrease "days" only if it's a weekday.
                    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                        days -= 1;
                    }
                }
                return date.toISOString();
            }

            return NoteService.createActivityNote(this.state.eOrder || this.props.eOrder ? this.state.eOrder.id || this.props.eOrder.id : null, note)
                .then((res) => {
                    if (res) {
                        if (this.state.selectedNoteRadio === "Followup") {
                            followupNote = {
                                name: this.state.eOrder.patientName,
                                orderActivityId: this.state.eOrder.id,
                                followUpOn: new Date().toISOString(),
                                followUpById: cb.id,
                                followUpNoteId: res.id,
                            };
                            NoteService.createOrderFollowup(followupNote)
                                .then((result) => {
                                    let order = this.state.eOrder;
                                    order.followUpDate = addWeekdays(new Date(), this.state.followupDays);

                                    this.setState({
                                        eOrder: order,
                                    });
                                    return OrderService.updateOrder(order)
                                        .then((res) => {
                                            toast.success("Followup Date Saved Successfully!");
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                            toast.error("There was an error saving the followup date.");
                                        });
                                })
                                .catch((errRes) => {
                                    console.log(errRes);
                                });
                        }
                        //need to set the last note date because otherwise the save overrides it...BC
                        //let ord = this.state.eOrder;
                        //ord.activity.lastNoteDate = new Date();
                        if (this.state.approvalAction === "needsAdditionalDocs" || this.state.approvalAction === "diagnoseIssue" || this.state.approvalAction === "unableToPickUp" || queueRouteName) {
                            this.handleRouting("fromNote");
                        } else {
                            this.setState({ saving: false });
                            this.props.onClose();
                        }
                        if (res.createdAt.indexOf("Z") > 0) {
                            res.createdAt = res.createdAt.split("Z")[0];
                        }

                        this.addNoteUpdateOrderCloseModal(res, this.state.eOrder);
                        if (this.refreshNotes) {
                            this.refreshNotes();
                        }

                        toast.success("Note Saved Successfully!");
                    }
                })
                .catch((err) => {
                    this.setState({ saving: false });
                    console.log(err);
                    toast.error("There was an error adding note.");
                });
        }
    }

    /**
     * Render header title of dialog
     */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                {this.props.isAutoVerification ? "Add Verification Note" : "Add Note"}
            </MUITypography>
        );
    }

    /**
     * Render main content of dialog
     */
    renderContent() {
        const handleTextChange = (e) => {
            this.setState({
                noteText: e.target.value,
            });
        };


        const handleNoteRadioChange = (e) => {
            this.setState({
                selectedNoteRadio: e.target.value
            });
        };

        const handleFollowupRadioChange = (e) => {
            this.setState({
                selectedServiceDocFollowupRadio: e.target.value
            });
        };

        const { queueRoutesIndexed } = this.context;
        let queueRouteName = "";
        if (this.state.queueRoute) {
            let queueRoute = queueRoutesIndexed[this.state.queueRoute].name.replace(/\s/g, '').toLowerCase();
            if (queueRoute.includes("unabletoapprove(insurance)")) {
                queueRouteName = "unableInsurance";
            } else if (queueRoute.includes("unabletoapprove(insurance)")) {
                queueRouteName = "unableInsurance";
            } else if (queueRoute.includes("unabletoapprove(equipment)")) {
                queueRouteName = "unableEquipment";
            } else {
                return null;
            }
        }

        return (
            <>
                <MUITextField
                    multiline
                    rows={8}
                    fullWidth={true}
                    autoFocus={true}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{style: {resize: "both"}}}
                    label={"Note"}
                    value={this.state.noteText ? this.state.noteText : ""}
                    onChange={(e) =>
                        handleTextChange(e)
                    }
                    variant="standard"
                />
                {false || this.state.approvalAction === "needsAdditionalDocs" || this.state.approvalAction === "diagnoseIssue" || queueRouteName || this.props.isAutoVerification ? null : (
                    <MUIContainer disableGutters sx={{mt: 2}}>
                        <MUIFormControl component="fieldset" required>
                            <MUIFormLabel>Select One</MUIFormLabel>
                            <MUIRadioGroup row value={this.state.selectedNoteRadio !== undefined ? this.state.selectedNoteRadio : null} onChange={(e) => handleNoteRadioChange(e)}>
                                <MUIFormControlLabel
                                    checked={this.state.selectedNoteRadio === "Note"}
                                    value={"Note"}
                                    control={<MUIRadio size="small" />}
                                    label="Note"
                                />

                                <MUIFormControlLabel
                                    checked={this.state.selectedNoteRadio === "Task"}
                                    value={"Task"}
                                    control={<MUIRadio size="small" />}
                                    label="Task"
                                />

                                <MUIFormControlLabel
                                    checked={this.state.selectedNoteRadio === "Followup"}
                                    value={"Followup"}
                                    control={<MUIRadio size="small" />}
                                    label="Followup"
                                />
                            </MUIRadioGroup>
                        </MUIFormControl>
                    </MUIContainer>
                )}

                {this.props.isAutoVerification ? (
                    <MUIContainer disableGutters sx={{mt: 2}} >
                        <MUIFormControl component="fieldset" required>
                            <MUIFormLabel>Insurance Verified?</MUIFormLabel>
                            <MUIRadioGroup row value={this.state.selectedVerificationNoteRadio !== undefined ? this.state.selectedVerificationNoteRadio : null} onChange={(e) => this.setState({selectedVerificationNoteRadio: e.target.value === "true"})}>
                                <MUIFormControlLabel
                                    value={true}
                                    control={<MUIRadio size="small" />}
                                    label="Yes"
                                />
                                <MUIFormControlLabel
                                    value={false}
                                    control={<MUIRadio size="small" />}
                                    label="No"
                                />
                            </MUIRadioGroup>
                        </MUIFormControl>
                    </MUIContainer>
                ) : null}

                {this.state.selectedNoteRadio === "Followup" && this.state.eOrder.orderStatusReasonId === 57 ? (
                    <>
                        <MUIContainer disableGutters sx={{mt: 1}}>
                            <MUIFormControl component="fieldset" required>
                                <MUIFormLabel>Select One</MUIFormLabel>
                                <MUIRadioGroup row value={this.state.selectedServiceDocFollowupRadio !== undefined ? this.state.selectedServiceDocFollowupRadio : null} onChange={(e) => handleFollowupRadioChange(e)}>
                                    <MUIFormControlLabel
                                        checked={this.state.selectedServiceDocFollowupRadio === "Phone"}
                                        value={"Phone"}
                                        control={<MUIRadio size="small" />}
                                        label="Phone"
                                    />

                                    <MUIFormControlLabel
                                        checked={this.state.selectedServiceDocFollowupRadio === "Email"}
                                        value={"Email"}
                                        control={<MUIRadio size="small" />}
                                        label="Email"
                                    />

                                    <MUIFormControlLabel
                                        checked={this.state.selectedServiceDocFollowupRadio === "Fax"}
                                        value={"Fax"}
                                        control={<MUIRadio size="small" />}
                                        label="Fax"
                                    />
                                </MUIRadioGroup>
                            </MUIFormControl>
                        </MUIContainer>
                    </>
                ) : null}

                {this.state.approvalAction === "needsAdditionalDocs" || this.state.approvalAction === "diagnoseIssue" || queueRouteName || this.state.selectedNoteRadio !== "Task" ? null : (
                    <>
                        <br />
                        <label> Assigned to (optional) </label>
                        <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            placeholder={
                                this.state.noteAssignedTo
                                    ? this.state.noteAssignedTo.name
                                    : "Select User..."
                            }
                            options={this.state.usersList}
                            onChange={this.handleNoteUserSelected.bind(this)}
                        />
                        <br />
                    </>
                )}
            </>
        );
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        const decreaseFollowupDays = () => {
            if (this.state.followupDays > 0) {
                this.setState({ followupDays: this.state.followupDays - 1 });
            }
        };

        const increaseFollowupDays = () => {
            if (this.state.followupDays < 5) {
                this.setState({ followupDays: this.state.followupDays + 1 });
            }
        };

        return (
            <>
                <MUIBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: '1rem' }}>
                    {this.state.selectedNoteRadio === "Followup" && this.state.eOrder.orderStatusReasonId !== 57 ? (
                        <MUIBox container sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 2 }}>
                            <MUITypography variant="body1">Follow up after</MUITypography>
                            <div className="number-input">
                                <button
                                    onClick={() =>
                                        decreaseFollowupDays()
                                    }
                                    className="minus"
                                />
                                <input
                                    value={this.state.followupDays}
                                    style={{
                                        width: "50px",
                                        textAlign: "center",
                                    }}
                                    disabled
                                />
                                <button
                                    onClick={() =>
                                        increaseFollowupDays()
                                    }
                                    className="plus"
                                />
                            </div>
                            <MUITypography variant="body1">days</MUITypography>
                        </MUIBox>
                    ) : null}
                    <MUIBox sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
                        <MUIDialogActions spacing={2}>
                            <MUIButton variant="text" onClick={this.props.onClose}>
                                Cancel
                            </MUIButton>
                            <MUILoadingButton disabled={(this.state.noteText.trim() === "") || (this.props.isAutoVerification && this.state.selectedVerificationNoteRadio === undefined) || (this.state.selectedNoteRadio === "Followup" && this.state.eOrder.orderStatusReasonId === 57 && !this.state.selectedServiceDocFollowupRadio)} variant="contained" loading={this.state.saving} onClick={this.addNewNote.bind(this)}>
                                {this.props.isAutoVerification ? "Add Verification Note" : "Add Note"}
                            </MUILoadingButton>
                        </MUIDialogActions>
                    </MUIBox>
                </MUIBox>
            </>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "sm";
    }

    /**
     * Don't blur the background so users can still see other notes if they need
     * to reference them.
     *
     * @returns {boolean} Whether or not to show blur the background.
     */
    getBlur() {
        return false;
    }
}
