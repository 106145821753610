import React from "react";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";

export default class CardAdminDistributionReasonGeneral extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={3}>
                    <MUITextField
                        value={this.props.distributionReason ? this.props.distributionReason.code : ""}
                        autoFocus={true}
                        size="small"
                        label="Code"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 3,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ code: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ code: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.code ? true : false}
                        helperText={this.props.validationIssues.code ? this.props.validationIssues.code : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={12} md={12}>
                    <MUITextField
                        value={this.props.distributionReason ? this.props.distributionReason.description : ""}
                        size="small"
                        label="Description"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 1024,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ description: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ description: e.target.value })}
                    />
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}