import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import Team from "../../Utility/Crud/Team";
import PropTypes from "prop-types";

export default class TileTeam extends Tile {
    static contextTypes = {
        teamUsersIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The Team name.
      */
    getTitle() {
        return this.props.team ? Team.getDisplayName(this.props.team) : "None";
    }

    /**
      * @returns {string} The number of users in the Team.
      */
    getSubtitle() {
        if (this.props.team) {
            const teamUserCount = Object.values(this.context.teamUsersIndexed).filter((teamUser) => {
                return teamUser.teamId === this.props.team.id;
            }).length;
    
            return teamUserCount + " Member" + (teamUserCount === 1 ? "" : "s");
        }
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.team ? Team.getDisplayName(this.props.team) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.team ? Team.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return Team.getIcon();
    }
}
