import React from "react";
import DialogSearch from "../DialogSearch";
import TileMarketingCampaignCategory from "../../Tile/TileMarketingCampaignCategory";
import PropTypes from "prop-types";
import MarketingCampaignCategory from "../../../Utility/Crud/MarketingCampaignCategory";

export default class DialogSearchMarketingCampaignCategory extends DialogSearch {

    static contextTypes = {
        marketingCampaignCategoriesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered marketingCampaignCategories.
     */
    getSearchResults(query) {
        return MarketingCampaignCategory.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.marketingCampaignCategoriesIndexed);
        }
    }

    /**
     * @param {object} marketingCampaignCategory The marketingCampaignCategory object.
     * @returns A TileMarketingCampaignCategory component for this marketingCampaignCategory.
     */
    renderTile(marketingCampaignCategory) {
        return (
            <TileMarketingCampaignCategory
                key={marketingCampaignCategory.id}
                marketingCampaignCategory={marketingCampaignCategory}
            />
        );
    }
}
