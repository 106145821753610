import React from "react";
import PropTypes from "prop-types";
import {isGroupTableRow} from "@devexpress/dx-grid-core";
import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar, TableColumnResizing, Table,
} from "@devexpress/dx-react-grid-material-ui";

import {
    GroupingState,
    SortingState,
    IntegratedSorting,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
    Container,
    Spinner,
    ToastContainer,
    MDBIcon,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "../PurchaseQueue/purchaseGrid.css";
import moment from "moment";
import NoteService from "../Security/NoteService/noteService";
import { Link } from "react-router-dom";
import GlobalFunctions from "../Filters/GlobalFunctions";

export default class TaskTable extends React.Component {
    constructor(props) {
        super(props);

        const status = this.props.status;

        const groupColumns = [{columnName: "assignedTo"}];

        const data = this.buildData(status);

        let defaultColumnWidths = [
            {columnName: "complete", width: 120},
            {columnName: "id", width: 120},
            {columnName: "description", width: 500},
            {columnName: "patientName", width: 200},
            {columnName: "assignedTo", width: 200},
            {columnName: "type", width: 100},
            {columnName: "dueDate", width: 200},
            {columnName: "account", width: 200},
        ];

        this.state = {
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            defaultColumnWidths: defaultColumnWidths,
            groupSummaryItems: [],
            status: status,
            approvalModal: false,
            nPurchaseQueues: [],
            brightreeId: "",
            errorMessage: "",
            tasks: props.tasks,
            targetType: "_self",
            targetPath: "dashboard"
        };

        this.changeGrouping = (grouping) => {
            let ary = [];

            grouping.forEach((group) => {
                ary.push({columnName: group.columnName});
            });
            this.setState({
                groupingColumns: ary,
            });
        };

    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    buildData() {
        let d = {
            columns: [
                {
                    title: "Complete",
                    name: "complete",
                },
                {
                    title: "ID",
                    name: "id",
                },
                {
                    title: "Description",
                    name: "description",
                },
                {
                    title: "Patient Name",
                    name: "patientName",
                },
                {
                    title: "Assigned To",
                    name: "assignedTo",
                },
                {
                    title: "Type",
                    name: "type",
                },
                {
                    title: "Due Date",
                    name: "dueDate",
                },
                {
                    title: "Account",
                    name: "account",
                },

            ],
            rows: [],
        };

        return d;
    }

    Root = (props) => <Grid.Root {...props} style={{height: "100%"}}/>;

    CellContent = ({row, column, expanded, ...props}) => {
        let cell = (
            <TableGroupRow.Cell
                {...props}
                row={row}
                column={column}
                expanded={expanded}
            />
        );
        return cell;
    };

    Content = ({row, column}) => (
        <span>
            <span>
                {column.title} : {row.value || "(Blank)"}
            </span>
            <span style={{fontWeight: "bold"}}>&nbsp; {this.getVal(row)}</span>
        </span>
    );

    getVal(row) {
        if (row.collapsedRows.length > 0) {
            return "Count: " + row.collapsedRows.length;
        } else {
            return "";
        }
    }

    getResults() {
        if (this.state.isLoaded === true) {
            return;
        }

        let ary = [],
            dt = this.state.data,
            tasks = this.props.tasks;

        tasks.forEach((val, idx) => {
            if (val.noTask) {
                return;
            }
            else if(val.completed){
                return;
            }
            let id = "No id";

            if (val.activityId) {
                id = val.activityId;
            } else if (val.account) {
                id = val.account.id;
            }

            let row = {
                complete: val.assignedTo ? this.createBtn(val.assignedTo.id, val, idx) : "",
                id: id,
                description: val.text ? val.text : "No Description",
                patientName: val.patientName || "No Patient Name",
                assignedTo: val.assignedTo ? val.assignedTo.firstname + " " + val.assignedTo.lastname : "Not assigned",
                dueDate: val.dueDate ? moment(val.dueDate).format("YYYY-MM-DD") : "No Due Date",
                account: val.account ? val.account.name : "No Account",
                activity: val.activityId,
                type: val.activityId ? "Activity" : "Account",

            };
            ary.push(row);
        });

        dt.rows = ary;

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    determineTarget(link, nWindow) {
        let target = nWindow === true ? "_blank" : "_self",
            targetLink = "dashboard";

        if (link.activity) {
            targetLink = "/order/" + link.id;
        } else if (link.account) {
            targetLink = "/account/" + link.id + "/detail";
        }

        this.setState({
            targetType: target,
            targetPath: targetLink
        });


        setTimeout(() => {
            document.getElementById("orderListId").click();
        }, 5);

    }

    renderTable() {
        const Cell = ({row, column, ...props}) => {
            return (
                <Table.Cell
                    {...props}
                    onClick={(e) => {

                        if (e.target.id === "btn") {
                            return;
                        }
                        this.determineTarget(row, e.ctrlKey);
                        if (e.ctrlKey === true) {
                            this.setState({
                                targetLink: row.id,
                            });

                        }
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                />
            );
        };


        this.getResults();
        let st = this.state;
        return (
            <Grid
                rootComponent={this.Root}
                rows={st.data.rows}
                columns={st.data.columns}
            >
                <PagingState defaultCurrentPage={0} pageSize={50}/>
                <DragDropProvider/>
                <SortingState
                    defaultSorting={[{columnName: "status", direction: "desc"}]}
                />

                <GroupingState
                    columnGroupingEnabled={true}
                    defaultGrouping={st.groupingColumns}
                />
                <IntegratedGrouping/>
                <IntegratedPaging/>
                <IntegratedSorting/>
                <VirtualTable cellComponent={Cell} height={"auto"}/>
                <PagingPanel/>
                <TableColumnResizing
                    defaultColumnWidths={this.state.defaultColumnWidths}
                />
                <TableHeaderRow showSortingControls/>
                <TableGroupRow
                    cellComponent={this.CellContent}
                    contentComponent={this.Content}
                    showColumnsWhenGrouped={false}
                />
                {this.ItemCounter()}
                <Toolbar/>
                <GroupingPanel/>
            </Grid>
        );
    }

    ItemCounter = () => (
        <Plugin name="ItemCounter">
            <Template
                name="tableCell"
                predicate={({tableRow}) => isGroupTableRow(tableRow)}
            >
                {(params) => (
                    <TemplateConnector>
                        {({getCollapsedRows, rows}) => {
                            const updatedParams = {
                                ...params,
                                tableRow: {
                                    ...params.tableRow,
                                    row: {
                                        ...params.tableRow.row,
                                        collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                        rows: rows.filter((r) => {
                                            return r.groupedBy === undefined;
                                        }),
                                    },
                                },
                            };
                            return <TemplatePlaceholder params={updatedParams}/>;
                        }}
                    </TemplateConnector>
                )}
            </Template>
        </Plugin>
    );

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    createBtn(assignedTo, task, idx) {
        const {currentUser} = this.context;
        if (assignedTo !== "" && assignedTo !== currentUser.id) {
            return;
        }

        return (
            <MDBIcon icon="check"
                size={"2x"}
                id="btn"
                style={{color: "#6b93c4"}}
                onClick={() => this.completeTask(task, idx)}
            />
        );

    }

    completeTask(note, idx) {
        this.setState({
            isLoaded: false,
        });
        const {currentUser} = this.context;
        note.completed = true;

        let name = currentUser ? currentUser.firstname + " " + currentUser.lastname : "user";


        note.task = false;
        note.completed = true;
        note.text +=
            " --> Completed by " + name + " on " + GlobalFunctions.formatDate(new Date());


        return NoteService.updateActivityNote(note)
            .then((res) => {
                let d = this.state.data,
                    r = d.rows;
                r.splice(idx,1);
                d.rows = r;
                this.setState({
                    data: d
                });
                this.getResults();
            })
            .catch((err) => {
                this.setState({
                    isLoaded: true,
                });
                console.log(err);
            });
    }

    renderTableOrSpinner() {
        if (!this.props.loaded) {
            return this.renderLoadingSpinner();
        }

        return (
            <div className={"purchasingGrid"} style={{height: "100%"}}>
                {this.renderTable()}
            </div>
        );
    }

    render() {
        let h = window.innerHeight;
        return (
            <div style={{overflowY: "scroll", height: h * 0.7, maxHeight: h * 0.7}}>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{marginTop: "75px"}}
                />
                {this.renderTableOrSpinner()}
                <Link
                    id="orderListId"
                    to={{
                        pathname: `${this.state.targetPath}`,
                    }}
                    target={this.state.targetType}
                    rel="opener"
                    style={{display: "none"}}
                    activeclassname="active"
                />
            </div>
        );
    }
}
