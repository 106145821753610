import api from "../api";

class CallLogService {
    async getCallLogByOrderId(orderId) {
        return new Promise((res, reject) => {
            api
                .get("/callLog/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createCallLog(params) {
        return new Promise((res, reject) => {
            api
                .post("/callLog", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const callLogService = new CallLogService();

export default callLogService;
