import api from "../api";

class UserProductionTypeService {
    async getAllUserProductionTypes() {
        return new Promise((res, reject) => {
            api
                .get("/userProductionType")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getUserProductionTypeById(id) {
        return new Promise((res, reject) => {
            api
                .get("/userProductionType/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createUserProductionType(obj) {
        return new Promise((res, reject) => {
            api
                .post("/userProductionType", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateUserProductionType(obj) {
        return new Promise((res, reject) => {
            api
                .put("/userProductionType", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteUserProductionType(id) {
        return new Promise((res, rej) => {
            return api.delete('/userProductionType/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const userProductionTypeService = new UserProductionTypeService();

export default userProductionTypeService;
