import TermList from "../AdminTermList";

export default class AdminTermListMarketingPreferredContactMethod extends TermList {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'marketing_preferred_contact_method';
    }
}
