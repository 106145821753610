import api from "../api";

class DepartmentService {
    parseJSON(departments) {
        if (Array.isArray(departments) === false) {
            if (departments.jsonPrefilters) {
                return { ...departments, ...{ jsonPrefilters: JSON.parse(departments.jsonPrefilters) } };
            }
            return departments;
        }
        return departments.map((department) => {
            if (department.jsonPrefilters) {
                return { ...department, ...{ jsonPrefilters: JSON.parse(department.jsonPrefilters) } };
            }
            return department;
        });
    }

    async getAllDepartments() {
        return new Promise((res, reject) => {
            api
                .get("/allDepartments")
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getDepartmentById(id) {
        return new Promise((res, reject) => {
            api
                .get("/departments/" + id)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async createDepartment(obj) {
        const department = {
            ...obj,
            ...{
                jsonPrefilters: obj.jsonPrefilters ? JSON.stringify(obj.jsonPrefilters) : null
            }
        };
        return new Promise((res, reject) => {
            api
                .post("/departments", department)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateDepartment(obj) {
        const department = {
            ...obj,
            ...{
                jsonPrefilters: obj.jsonPrefilters ? JSON.stringify(obj.jsonPrefilters) : null
            }
        };
        return new Promise((res, reject) => {
            api
                .put("/departments", department)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async deleteDepartment(id) {
        return new Promise((res, rej) => {
            return api.delete('/departments/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }




}

const departmentService = new DepartmentService();

export default departmentService;
