import React from "react";
import Dialog from "../Dialog";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUIButton from "@mui/material/Button";
import MUIDialogActions from "@mui/material/DialogActions";
import { DateCalendar as MUIDateCalendar } from '@mui/x-date-pickers/DateCalendar';


export default class DialogDatePicker extends Dialog {
    /**
     * Render main content text of dialog
     */
    renderContent() {
        return (
            <MUIDateCalendar
                onChange={(value) => this.setState({ value: value })}
                views={['year', 'month', 'day']}
                sx={{marginTop: 3}}
            />
        );
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Cancel
                </MUIButton>
                {this.props.defaultValue ?
                    (
                        <MUIButton variant="text" onClick={() => this.props.onConfirm(null) } color="error">
                            Remove
                        </MUIButton>
                    )
                    : null}
                <MUILoadingButton variant="contained" loading={this.props.loading} onClick={() => this.props.onConfirm(this.state.value)}>
                    Confirm
                </MUILoadingButton>
            </MUIDialogActions>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "xs";
    }

    /**
     * @returns {boolean} Whether or not to make dialog full width.
     */
    getFullWidth() {
        return false;
    }
}
