import React from "react";
import { Button, DataTable, Container, Spinner, MDBIcon } from "mdbreact";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import ReportsService from "../Security/ReportsService/reportsService";

export default class ServiceOrderReasonReport extends React.Component {
    constructor(props) {
        super(props);

        let filters = this.determineFilters(props.filters);
        let topFilters = props.topFilters;

        //table headers & columns
        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Patient Name",
                    field: "name",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Technician",
                    field: "technician",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Sales Rep",
                    field: "salesRep",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "ATP",
                    field: "atp",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Service Reason",
                    field: "serviceReason",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Previous Order ID",
                    field: "previousOrderId",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Current Order ID",
                    field: "currentOrderId",
                    sort: "asc",
                    width: 200,
                },
            ],
            rows: [],
        };

        this.state = {
            allServiceOrderReason: [],
            serviceOrderReasonData: data,
            startDate: new Date(filters.startDate),
            endDate: new Date(filters.endDate),
            isLoaded: true,
            csvFunction: props.csvFunction,
            topFilters: topFilters,
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    componentDidUpdate(prevProps) {
        // Get the most up-to-date filters passed down from parent
        if (
            JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
        ) {
            this.setState({
                topFilters: this.props.topFilters,
            });
        }
        // Checks if the generate reports button has been clicked...JK
        if (prevProps.generateClicked !== this.props.generateClicked) {
            this.getServiceOrderReasonReport();
        }
    }

    determineFilters(userFilters) {
        let d = new Date(),
            filters = {};

        if (userFilters) {
            if (userFilters.startDate) {
                filters.startDate = new Date(userFilters.startDate);
            } else {
                filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
            }

            if (userFilters.endDate) {
                filters.endDate = new Date(userFilters.endDate);
            } else {
                filters.endDate = d;
            }
        } else {
            filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
            filters.endDate = d;
        }

        return filters;
    }

    getServiceOrderReasonReport() {
        let st = this.state,
            tp = st.topFilters;
        let filters = {
            startDate: tp.startDate,
            endDate: tp.endDate,
        };

        try {
            filters = {
                startDate: new Date(tp.startDate).toLocaleDateString(),
                endDate: new Date(tp.endDate).toLocaleDateString(),
            };
        } catch {
            // toast.error("Please check your dates.");
            return;
        }

        // Have to check len since showDatePicker defaults to false at startup
        if (!tp.showDatePicker && tp.quickFilter.length > 0) {
            filters.startDate = tp.quickFilter[0].toLocaleDateString();
            filters.endDate = tp.quickFilter[1].toLocaleDateString();
        } else {
            // Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter
            if (
                filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
            ) {
                // toast.error('Please check your dates');
                return;
            }
        }

        this.setState({ isLoaded: false });

        ReportsService.getServiceOrderReasonReport(filters)
            .then((res) => {
                this.setState({
                    allServiceOrderReason: res,
                });

                this.formatRows();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    formatRows() {
        let orders = this.state.allServiceOrderReason,
            ary = [],
            dt = this.state.serviceOrderReasonData;

        orders.forEach((order) => {
            ary.push({
                id: order.id,
                name: order.name,
                technician: order.technician,
                salesRep: order.salesRep,
                atp: order.atp,
                serviceReason: order.serviceReason,
                previousOrderId: order.previousOrderId,
                currentOrderId: order.currentOrderId,
            });
        });
        dt.rows = ary;

        this.setState({
            serviceOrderReasonData: dt,
            isLoaded: true,
        });
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    //load data in the table
    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div>
                <DataTable
                    entriesOptions={[25, 50, 100]}
                    pagesAmount={10}
                    data={this.state.serviceOrderReasonData}
                    responsive
                    fixed
                    striped
                    bordered
                ></DataTable>

                <Button
                    style={{ float: "right" }}
                    color={"blue"}
                    floating
                    size="sm"
                    data-tip={"Download CSV"}
                    onClick={this.state.csvFunction.bind(
                        this,
                        this.state.serviceOrderReasonData,
                        "serviceOrderReasonExport.csv"
                    )}
                >
                    <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                </Button>
                <ReactTooltip />
            </div>
        );
    }
}
