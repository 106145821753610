import React from "react";
import MUIIconButton from "@mui/material/IconButton";
import MUIButton from "@mui/material/Button";
import MUIGrid from "@mui/material/Grid";
import TileUser from "../../../Tile/TileUser";
import MUIAddIcon from "@mui/icons-material/Add";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import EmptyState from "../../../EmptyState";
import DialogSearchUser from "../../../Dialog/Search/DialogSearchUser";
import User from "../../../../Utility/Crud/User";
import PropTypes from "prop-types";
import MUITextField from "@mui/material/TextField";
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUISearchIcon from "@mui/icons-material/Search";
import Card from "../../../Card";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class CardAdminLocationUsers extends Card {
    static contextTypes = {
        usersIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state.locationUserDialogOpen = false;
        this.state.query = "";
        this.state.filteredUserprofileLocations = this.props.userprofileLocations;
    }

    /**
     * Set the search results on mount. This ensures that the list is sorted
     * appropriately on first render.
     */
    componentDidMount() {
        this.setState({
            filteredUserprofileLocations: this.getFilteredUserprofileLocations(this.state.query),
        });
    }

    /**
     * Handle removes or adds to the userprofileLocations prop.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.userprofileLocations.length !== this.props.userprofileLocations.length) {
            this.setState({
                filteredUserprofileLocations: this.getFilteredUserprofileLocations(this.state.query),
            });
        }
    }

    /**
     * Renders a list of users on the location or an empty state if there are none.
     */
    renderContent() {
        const handleRemoveLocationUser = (removedUserprofileLocation) => {
            this.props.onChange(this.props.userprofileLocations.filter((existingUserprofileLocation) => {
                return (existingUserprofileLocation.userprofileId !== removedUserprofileLocation.userprofileId);
            }));
        };

        if (this.props.userprofileLocations.length > 0) {
            if (this.state.filteredUserprofileLocations.length > 0) {
                return (
                    <>
                        {this.renderSearchBox()}
                        <MUIGrid container spacing={2}>
                            {this.state.filteredUserprofileLocations.map((locationUser) => {
                                return (
                                    <MUIGrid key={locationUser.userprofileId} item xs={12} sm={6} md={4}>
                                        <TileUser
                                            user={this.context.usersIndexed[locationUser.userprofileId]}
                                            iconButtons={[(<MUIIconButton onClick={() => handleRemoveLocationUser(locationUser)}><MUIDeleteIcon /></MUIIconButton>)]}
                                        />
                                    </MUIGrid>
                                );
                            })}
                            {this.renderDialog()}
                        </MUIGrid>
                    </>
                );
            } else {
                return (
                    <>
                        {this.renderSearchBox()}
                        <EmptyState
                            line1="No results found"
                            line2="Try searching for something a little different."
                        />
                        {this.renderDialog()}
                    </>
                );
            }
        } else {
            return (
                <>
                    <EmptyState
                        line1="No Users"
                        line2="Users added to this location will appear here"
                    />
                    {this.renderDialog()}
                </>
            );
        }
    }

    /**
     * Renders a search dialog of all users
     */
    renderDialog() {
        const handleAddUserprofileLocation = (user) => {
            const duplicateUserprofileLocation = this.props.userprofileLocations.filter((userprofileLocation) => {
                return userprofileLocation.userprofileId === user.id;
            });
            if (duplicateUserprofileLocation.length === 0) {
                this.props.onChange(
                    this.props.userprofileLocations.concat([{
                        locationsId: this.props.location.id,
                        userprofileId: user.id
                    }])
                );
            } else {
                NotistackEnqueueSnackbar('Cannot add duplicate users.', { variant: "error" });
            }
        };

        return (
            <DialogSearchUser
                open={this.state.locationUserDialogOpen}
                onClose={() => this.setState({ locationUserDialogOpen: false })}
                onSelect={(user) => { handleAddUserprofileLocation(user); }}
            />
        );
    }

    /**
     * Renders add user button.
     */
    getActions() {
        const handleClick = () => {
            this.setState({
                locationUserDialogOpen: true,
            });
        };

        return [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIAddIcon />}
                onClick={handleClick}>Add User</MUIButton>)
        ];
    }

    /**
     * Searches for location users using the standard user search.
     *
     * @param {string} query The search query.
     * @returns A list of filtered location users.
     */
    getFilteredUserprofileLocations(query) {
        const users = [];
        this.props.userprofileLocations.forEach((userprofileLocation) => {
            const user = this.context.usersIndexed[userprofileLocation.userprofileId];
            if (user) { // Check if user is defined
                users.push(user);
            }
        });

        const filteredUsers = User.search(
            Object.values(users),
            query,
            true
        );

        const filteredUserprofileLocations = [];
        filteredUsers.forEach((user) => {
            // Nested loop not ideal, but set size is small enough.
            filteredUserprofileLocations.push(this.props.userprofileLocations.find((userprofileLocation) => {
                return userprofileLocation.userprofileId === user.id;
            }));
        });

        return filteredUserprofileLocations;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Users";
    }

    /**
     * Render a search box.
     */
    renderSearchBox() {
        const handleChange = (e) => {
            this.setState({
                query: e.target.value,
                filteredUserprofileLocations: this.getFilteredUserprofileLocations(e.target.value)
            });
        };

        return (
            <MUITextField
                autoFocus={true}
                placeholder="Type to search..."
                hiddenLabel={true}
                fullWidth={true}
                variant="filled"
                size="small"
                onChange={handleChange}
                sx={{
                    marginBottom: 2,
                    "& .MuiInputLabel-root": { display: "none", height: 0 }
                }}
                InputProps={{
                    startAdornment: (
                        <MUIInputAdornment position="start">
                            <MUISearchIcon />
                        </MUIInputAdornment>
                    ),
                    disableUnderline: true,
                    style: { borderRadius: 4 }
                }}
            />
        );
    }
}