import api from "../Security/api";

class DocumentTypeService {
    async getAllDocumentTypes() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/documentTypes", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateDocumentType(doc) {
        return new Promise((res, reject) => {
            api
                .put("/documentTypes", doc)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createDocumentType(doc) {
        return new Promise((res, reject) => {
            api
                .post("/documentTypes", doc)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    async deleteDocumentType(id) {
        return new Promise((res, reject) => {
            api
                .delete("/documentTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const documentTypeService = new DocumentTypeService();

export default documentTypeService;
