import React from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    toast,
    ToastContainer,
    Col,
    Row,
    MDBInput, MDBBtn,
} from "mdbreact";
import UserService from "../Security/UserService/userService";
import "./userEdit.css";
import Select from "react-select";
import LayerLegacy from "../../LayerLegacy";

export default class userAdd extends LayerLegacy {
    constructor(props) {
        super(props);

        this.state = {
            nUser: {
                username: "",
                email: "",
                firstname: "",
                lastname: "",
                role: "",
                userprofileLocations: [],
                active: true,
                canEditPoints: false,
            },
            locations: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            locationsSelected: [],
        };
    }

    componentDidMount() {
        this.getLocations();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { allLocations } = this.context;
        let st = this.state;
        if (st.locations.length !== allLocations.length) {
            this.getLocations();
        }
    }

    static contextTypes = {
        allLocations: PropTypes.array,
    };

    changeRole(role) {
        let nUser = this.state.nUser;
        nUser.role = role;
        this.setState({ nUser: nUser });
    }

    getLocations() {
        const { allLocations } = this.context;
        let l = [];
        allLocations.map((loc) => {
            return l.push({
                label: loc.name,
                value: loc.name,
                val: {
                    id: loc.id,
                    name: loc.name,
                },
            });
        });
        this.setState({
            locations: l,
        });
    }

    handleChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const nUser = this.state.nUser;
        nUser[property] = event.target.value;
        this.setState({ eUser: nUser });
    };

    validateForm() {
        let n = this.state.nUser;

        if (n.username === "") {
            return "Please enter a username.";
        }
        if (n.firstname === "") {
            return "Please enter a first name.";
        }
        if (n.lastname === "") {
            return "Please enter a last name.";
        }
        if (n.role === "") {
            return "Please choose a role.";
        }
        if (n.email === "") {
            return "Please enter an email address.";
        }
        if (!(n.email.includes("@") && n.email.includes("."))) {
            return "Please enter a valid email address.";
        }
        if (!n.userprofileLocations || n.userprofileLocations.length === 0) {
            return "Please add at least 1 location.";
        }
        return true;
    }

    addUser() {
        let st = this.state,
            user = st.nUser;
        user.email = user.email.trim();
        return UserService.createUser(user)
            .then((res) => {
                toast.success("Saved Successfully!");
                let nUser = {
                    username: "",
                    password: "",
                    retypePassword: "",
                    firstname: "",
                    lastname: "",
                    email: "",
                    userprofileLocations: [],
                };
                this.setState({
                    nUser: nUser,
                    locationsSelected: [],
                });
                //if (st.locationsSelected.length > 0) {
                //	st.locationsSelected.map((loc) => {
                //		return l.push(loc.val);
                //	});
                //	res.userProfileLocations = l.map(x => {
                //		return { locationsId: x.id }
                //	}) || [];
                //	return UserService.updateUser(res)
                //		.then((data) => {
                //			this.setState({
                //				locationsSelected: [],
                //			});
                //			toast.success("Locations Set Successfully!");
                //		})
                //		.catch((err) => {
                //			toast.warn("An error occurred while saving.");
                //		});
                //}
                //else{
                //	toast.warn("An error occurred creating the user.")
                //}
            })
            .catch((err) => {
                console.log(err);
                toast.warn("Oh! There was an error creating the user.");
            });
    }

    renderLocationsCard() {
        let st = this.state;
        return (
            <Card>
                <CardHeader
                    style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30, minHeight: 82, margin: "0"}}
                    className="form-header text-center">
                    <h2>Locations</h2>
                </CardHeader>
                <CardBody>
                    <Select
                        placeholder={"Select Location..."}
                        options={st.locations}
                        isMulti
                        onChange={this.handleLocationChange.bind(this)}
                        value={st.locationsSelected}
                    />
                </CardBody>
            </Card>
        );
    }

    handleLocationChange = (e) => {
        console.log(e);
        let u = this.state.nUser;
        u.userprofileLocations = [];
        if (e && e.length > 0) {
            e.map(x => u.userprofileLocations.push({ locationsId: x.val.id }));
        }
        this.setState({
            locationsSelected: e,
            eUser: u,
        });
    };

    handlePoints = () => {
        let u = this.state.eUser;
        u.canEditPoints = !u.canEditPoints;
        this.setState({
            eUser: u,
        });
    };

    handleAtp = () => {
        let u = this.state.eUser;
        u.isAtp = !u.isAtp;
        this.setState({
            eUser: u,
        });
    };

    handleFollowUp = () => {
        let u = this.state.eUser;
        u.canFollowUp = !u.canFollowUp;
        this.setState({
            eUser: u,
        });
    };

    renderErrorMessage() {
        let res = this.validateForm(),
            color = "green darken-2",
            text = "Create User",
            disable = false,
            onClick = this.addUser.bind(this);

        //if the form is not valid, change the style...BC
        if (res !== true) {
            disable = true;
            text = res;
            color = "pink darken-4";
            onClick = () => {
                return;
            };
        }

        //return the chip...BC
        return (
            <MDBBtn
                // outline={this.state.rotateMode}
                disabled={disable}
                rounded
                color={color}
                data-tip={text}
                onClick={onClick}
                style={{fontSize: "15px", padding: "5px 15px 5px 15px"}}
            >
                {text}
                {/*<MDBIcon far icon="check-circle"*/}
                {/*		 style={{fontSize: "14px", paddingLeft: "3px", color: "black"}}/>*/}
            </MDBBtn>
        // <Chip
        // 	className="userHeader"
        // 	size="lg"
        // 	bgColor={color}
        // 	text="white"
        // 	onClick={onClick}
        // >
        // 	{text}
        // </Chip>
        );
    }

    renderContent() {
        let user = this.state.nUser;
        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Container style={{marginTop: 30}}>
                    <Row>
                        <Col size="7">
                            <Card>
                                <CardHeader
                                    style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30, minHeight: 82, margin: "0" }}
                                    className="form-header text-center"
                                >
                                    <h2 style={{marginBottom: "0"}}>
										Create New User
                                    </h2>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col size="6">
                                            <Input
                                                outline
                                                value={user.username || ""}
                                                onChange={this.handleChange.bind(this, "username")}
                                                label="Username"
                                                size="sm"
                                                icon="user"
                                            />
                                        </Col>

                                        <Col size="6">
                                            <Dropdown>
                                                <DropdownToggle caret color="primary">
                                                    {user.role || "Select a role"}
                                                </DropdownToggle>
                                                <DropdownMenu basic>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "ADMIN")}
                                                    >
														Admin
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "EXECUTIVE_MANAGEMENT"
                                                        )}
                                                    >
														Executive Management
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "FINANCE")}
                                                    >
														Finance
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "OFFICE_MANAGER"
                                                        )}
                                                    >
														Office Manager
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "AREA_MANAGER")}
                                                    >
														Area Manager
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "MARKETING")}
                                                    >
														Marketing
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "MARKETING_MANAGER")}
                                                    >
														Marketing Manager
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "SERVICE_TEAM")}
                                                    >
														Service Team
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "CUSTOMER_EXPERIENCE"
                                                        )}
                                                    >
														Customer Experience
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "TECHNICIAN")}
                                                    >
														Technician
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "WAREHOUSE_TECHNICIAN"
                                                        )}
                                                    >
														Warehouse Technician
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "SALES_MANAGER"
                                                        )}
                                                    >
														Sales Manager
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "SALES")}
                                                    >
														Sales Rep
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "SALES_LIAISON_MANAGER"
                                                        )}
                                                    >
														Sales Liaison Manager
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "SALES_LIAISON"
                                                        )}
                                                    >
														Sales Liaison
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "PATIENT_CARE_REP"
                                                        )}
                                                    >
														Patient Care Rep
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "OFFICE_SUPPORT"
                                                        )}
                                                    >
														Office
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(
                                                            this,
                                                            "CARE_COORDINATOR"
                                                        )}
                                                    >
														Care Coordinator
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "VERIFICATION")}
                                                    >
														Verification
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "FOLLOWUP")}
                                                    >
														Follow Up
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "COLLECTIONS")}
                                                    >
														Collections
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "BILLING")}
                                                    >
														Billing
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={this.changeRole.bind(this, "PURCHASING")}
                                                    >
														Purchasing
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col size="6">
                                            <Input
                                                outline
                                                value={user.firstname || ""}
                                                onChange={this.handleChange.bind(this, "firstname")}
                                                label="First Name"
                                                size="sm"
                                                icon="book"
                                            />
                                        </Col>

                                        <Col size="6">
                                            <Input
                                                outline
                                                value={user.lastname || ""}
                                                onChange={this.handleChange.bind(this, "lastname")}
                                                label="Last Name"
                                                size="sm"
                                                icon="book"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col size="12">
                                            <Input
                                                outline
                                                value={user.email || ""}
                                                onChange={this.handleChange.bind(this, "email")}
                                                label="Email"
                                                size="sm"
                                                icon="envelope"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col size="4">
                                            <MDBInput
                                                id="pointsStatus"
                                                label="Can Edit Points"
                                                checked={user.canEditPoints}
                                                value={user.canEditPoints}
                                                onChange={this.handlePoints}
                                                type="checkbox"
                                            />
                                        </Col>

                                        <Col size="4">
                                            <MDBInput
                                                id="atpStatus"
                                                label="is ATP"
                                                checked={user.isAtp}
                                                value={user.isAtp}
                                                onChange={this.handleAtp}
                                                type="checkbox"
                                            />
                                        </Col>

                                        <Col size="6">
                                            <MDBInput
                                                id="followUpStatus"
                                                label="Can Follow Up"
                                                checked={user.canFollowUp}
                                                value={user.canFollowUp}
                                                onChange={this.handleFollowUp}
                                                type="checkbox"
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{display: "flex", justifyContent: "center"}}>
                                        {this.renderErrorMessage()}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col size="5">{this.renderLocationsCard()}</Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
