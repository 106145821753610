import TermList from "../AdminTermList";

export default class AdminTermListServicePartCatalogCategory extends TermList {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'service_part_catalog_category';
    }
}
