import Layer from "../Layer";
import Setting from "../Utility/Setting";

export default class Logout extends Layer {

    constructor(props) {
        super(props);

        Setting.clear("session", window.sessionStorage);
        Setting.clear("session", window.localStorage);
        window.sessionStorage.clear();
    }

    /**
     * Set loaded to false so the AppBar doesn't render.
    */
   afterComponentDidMount() {
        this.context.setContextItem("loaded", false);
    }

    renderContent() {
        return null;
    }
}

