import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import Term from "../../Utility/Crud/Term";

export default class TileTerm extends Tile {
    /**
      * @returns {string} The Term name.
      */
    getTitle() {
        return this.props.term ? Term.getDisplayName(this.props.term) : "None";
    }
 
    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.term ? Term.getDisplayName(this.props.term) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.term ? Term.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return Term.getIcon();
    }
}