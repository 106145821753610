import TermList from "../AdminTermList";

export default class AdminTermListQueueType extends TermList {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'region_type';
    }
}
