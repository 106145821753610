import React from "react";
import Moment from "moment";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Spinner,
    Row,
    MDBIcon,
    ToastContainer,
    toast,
    MDBDatePicker, MDBDataTable,
} from "mdbreact";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import reportsService from "../Security/ReportsService/reportsService";
import GlobalFunctions from "../Filters/GlobalFunctions";
import LayerLegacy from "../../LayerLegacy";

export default class inventoryTracking extends LayerLegacy {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "id",
                    field: "id",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Service Location",
                    field: "serviceLocation",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Order Id",
                    field: "orderId",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Setup Date",
                    field: "setupDate",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Patient Name",
                    field: "patientName",
                    sort: "asc",
                    width: 50,
                },
                {
                    label: "Equipment",
                    field: "equipment",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Serial",
                    field: "serial",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Quantity",
                    field: "qty",
                    sort: "asc",
                    width: 100,
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            startDate: new Date(),
            endDate: new Date(),
            allLocs: [],
            locationsSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            locationSelected: [],
            showDatePicker: true,
            quickFilter: [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        salesReps: PropTypes.array,
    };

    componentDidMount() {
        this.getLocationsOptions();
        this.getLocalStorage();
    }

    updateLocalStorage() {
        let filters = {},
            st = this.state;

        filters.startDate = st.startDate;
        filters.endDate = st.endDate;
        filters.location = st.locationSelected;

        window.localStorage.setItem("inventoryReportFilters", JSON.stringify(filters));
    }

    getLocalStorage() {
        let f = window.localStorage.getItem("inventoryReportFilters");

        if (f != null) {
            f = JSON.parse(f);

            this.setState({
                startDate: f.startDate ? new Date(f.startDate) : new Date(),
                endDate: f.endDate ? new Date(f.endDate) : new Date(),
                locationSelected: f.location,
                isLoaded: false,
            });
        }

        setTimeout(() => {
            if(!this.state.locationSelected || !this.state.locationSelected.value){
                this.setState({
                    isLoaded: true,
                });
            } else {
                this.getInventory();
            }
        }, 500);
    }

    getLocationsOptions() {
        const { currentUser, allLocations } = this.context;

        let cu = currentUser;
        let select = [];

        if (cu.role === "SALES") {
            cu.userprofileLocations.map((l) => {
                if (l.locations.type === 0) {
                    return select.push({
                        label: l.locations.name,
                        value: l.locations.id,
                    });
                }
                return null;
            });
            this.setState({
                locationsSelect: select,
                allLocs: cu.userprofileLocations.map(x => x.locations) || [],
            });
        } else {
            allLocations.forEach((obj, index) => {
                select.push({
                    label: obj.name,
                    value: obj.id.toString(),
                });
            });

            this.setState({
                locationsSelect: select,
                allLocs: allLocations,
            });
        }
    }

    handleLocationChange = (e) => {
        this.setState({
            locationSelected: e,
        });
    };

    handleDatePickerChange = (property, value) => {
        this.setState({
            [property]: value,
        });
    };

    renderFilter() {
        let st = this.state;
        return (
            <Card style={{ marginBottom: "1%", backgroundColor: "#5881C1" }}>
                <CardBody>
                    <Row>
                        <Col md={"1"}></Col>
                        <Col md={"3"} className={"filterSearchBoxCol"}>
                            <Select
                                placeholder="Select Location"
                                closeMenuOnSelect={true}
                                // isMulti
                                isClearable
                                options={st.locationsSelect}
                                onChange={this.handleLocationChange.bind(this)}
                                value={st.locationSelected}
                            />
                        </Col>

                        <Col md={"3"} className={"filterSearchBoxCol"}>
                            <div className={"expenseDateOptions"}>
                                <MDBDatePicker
                                    className={"openDatePicker"}
                                    label={"Start Date"}
                                    showTodayButton
                                    autoOk
                                    allowKeyboardControl
                                    keyboard={true}
                                    okLabel={false}
                                    value={this.state.startDate}
                                    getValue={this.handleDatePickerChange.bind(this, "startDate")}
                                />
                            </div>
                        </Col>
                        <Col md={"3"} className={"filterSearchBoxCol"}>
                            <div className={"expenseDateOptions"}>
                                <MDBDatePicker
                                    className={"openDatePicker"}
                                    label={"End Date"}
                                    showTodayButton
                                    autoOk
                                    allowKeyboardControl
                                    keyboard={true}
                                    okLabel={false}
                                    value={this.state.endDate}
                                    getValue={this.handleDatePickerChange.bind(this, "endDate")}
                                />
                            </div>
                        </Col>
                        <Col className={"filterSearchBoxCol"}>
                            <Button
                                floating
                                size="sm"
                                color={"indigo"}
                                data-tip={"Search"}
                                onClick={() => this.filterClicked()}
                            >
                                <MDBIcon icon="search" style={{ fontSize: "2em" }} />
                            </Button>
                        </Col>
                        <ReactTooltip />
                    </Row>

                </CardBody>
            </Card>
        );
    }

    filterClicked = () => {
        if(!this.state.locationSelected || !this.state.locationSelected.value){
            toast.warn("Please Choose a Location");
            return;
        }
        this.setState({
            isLoaded: false,
        });
        this.updateLocalStorage();
        this.getInventory();
    };

    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.field);
            headers.push(col.label);
        });

        let replace = ["description", "account", "setupDate", "equipment"],
            children = ["account"];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (children.indexOf(key) > -1 && item[key] != null) {
                    //a child in this case is the object, but we just want the text...BC
                    result += item[key].props.children.replace(/,/g, " ");
                } else if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    getInventory() {
        let st = this.state,
            filters = {},
            sd = new Date(st.startDate),
            ed = new Date(st.endDate);

        if (st.startDate) {
            //Need to apply time as 0 for the start date...BC
            filters.startDate = Moment(sd).format("YYYY/MM/DD");
            // + "T00:00:00.000Z";
        } else {
            toast.warn("Please Choose a start date");
            return;
        }

        if (st.locationSelected) {
            //Need to apply time as 0 for the start date...BC
            filters.location = st.locationSelected.value ;
        }

        if (st.endDate) {
            //Need to apply time as 23:59 for the end date...BC
            filters.endDate = Moment(ed).format("YYYY/MM/DD") ;
            // + "T23:59:59.000Z";
        } else {
            toast.warn("Please Choose an end date");
            return;
        }

        this.setState({ isLoaded: false });

        var f = {
            start: filters.startDate || "",
            end: filters.endDate || "",
            locationId: filters.location || "",
        };

        reportsService.getInventoryReport(f)
            .then((res) => {
                this.setState({ inventoryReport: res });
                this.formatRows();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    formatRows() {
        let inventory = this.state.inventoryReport,
            dt = this.state.data,
            ary = [];

        inventory.forEach((activity, index) => {
            ary.push({
                id: activity.id,
                serviceLocation: activity.serviceLocation || "Unknown",
                orderId: activity.orderId || "Unknown",
                setupDate: (activity.setupDate ? GlobalFunctions.formatDate(activity.setupDate ) : "Unknown"),
                patientName: activity.patientName || "Unknown",
                equipment: activity.equipment || "Unknown",
                serial: activity.serial || "Unknown",
                qty : activity.qty || "",
            });
        });

        dt.rows = ary;

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }



    renderTable() {
        if (this.state.isLoaded === true) {
            return (
                <MDBDataTable striped bordered responsive autoWidth={false} info={false}  data={this.state.data}>
                    {" "}
                </MDBDataTable>
            );
        } else {
            return this.renderLoadingSpinner();
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderContent() {
        return (
            <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                {this.renderFilter()}
                <Card style={{ marginBottom: ".5%" }}>
                    <CardBody>{this.renderTable()}</CardBody>
                </Card>
                <div className={"fixed-bottom downloadCsvDiv"}>
                    <Button
                        floating
                        size="sm"
                        className={"downloadCsvButton"}
                        color={"primary"}
                        data-tip={"Download CSV"}
                        onClick={this.clientCSV.bind(
                            this,
                            this.state.data,
                            "Inventory Tracking.csv"
                        )}
                    >
                        <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                    </Button>
                    <ReactTooltip />
                </div>
            </div>
        );
    }
}
