export default class Exporter {

    /**
     * @param {array} rows The array of rows.
     * @param {string} filename A url string for the exported csv file name.
     */
    static exportCSV(rows, filename) {
        let csv = "";
        rows.forEach(row => {
            // Escape each cell in the row
            const escapedRow = row.map(cell => this.escape(cell));
            csv += escapedRow.join(",") + "\n";
        });

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8," });

        const link = document.createElement("a");
        const csvUrl = URL.createObjectURL(blob);

        link.href = csvUrl;
        link.style = "visibility:hidden";
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    /**
     * @param {*} value
     * @returns Escaped values containing double quotes, commas, or newlines.
     */
    static escape(value) {
        let escapedValue;
        try {
            escapedValue = value.toString();
        } catch (error) {
            escapedValue = "";
        }

        // Escape double quotes by doubling them
        if (escapedValue.includes('"')) {
            escapedValue = escapedValue.replace(/"/g, '""');
        }

        // Escape values containing commas, double quotes, or newlines by wrapping them in double quotes
        if (escapedValue.includes(',') || escapedValue.includes('\n') || escapedValue.includes('"')) {
            escapedValue = `"${escapedValue}"`;
        }

        return escapedValue;
    }
}