import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import ContactType from "../../../../Utility/Crud/ContactType";

export default withRouter(class CardAdminContactTypeList extends Card {
    static contextTypes = {
        contactTypesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                ]}
                rows={ContactType.sort(Object.values(this.context.contactTypesIndexed))}
                onClick={(contactType) => {
                    this.props.history.push({
                        pathname: "/admin/contactType/" + contactType.id
                    });
                }}
            />
        );
    }
});