import React from "react";
import Chip from "../Chip";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIAvatar from "@mui/material/Avatar";
import Region from "../../Utility/Crud/Region";

export default class ChipRegion extends Chip {
    /**
     * Renders a MUI Avatar.
     */
    renderAvatar() {
        const color = (this.props.region ? Region.getColor() : MUIGreyColor[500]);
        const avatarColor = this.props.isTransparent ? "transparent" : color;
        const iconColor = this.props.isTransparent ? color : "#fff";

        return (
            <MUIAvatar sx={{ bgcolor: avatarColor }}>
                {Region.getIcon({ fontSize: "small", sx: { color: iconColor } })}
            </MUIAvatar>
        );
    }

    /**
     * @return {string} The region name.
     */
    getLabel() {
        if (this.props.region) {
            return Region.getDisplayName(this.props.region);
        } else {
            return this.props.nullLabel || "None";
        }
    }
}
