import React from "react";
import DialogSearch from "../DialogSearch";
import TileBaseUnitType from "../../Tile/TileBaseUnitType";
import PropTypes from "prop-types";
import BaseUnitType from "../../../Utility/Crud/BaseUnitType";

export default class DialogSearchBaseUnitType extends DialogSearch {

    static contextTypes = {
        baseUnitTypesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered baseUnitTypes.
     */
    getSearchResults(query) {
        return BaseUnitType.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.baseUnitTypesIndexed);
        }
    }

    /**
     * @param {object} baseUnitType The baseUnitType object.
     * @returns A TileBaseUnitType component for this baseUnitType.
     */
    renderTile(baseUnitType) {
        return (
            <TileBaseUnitType
                key={baseUnitType.id}
                baseUnitType={baseUnitType}
            />
        );
    }
}
