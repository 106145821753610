import api from "../api";

class BaseUnitTypeService {
    async getAllBaseUnitTypes() {
        return new Promise((res, reject) => {
            api
                .get("/baseUnitTypes")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getBaseUnitTypeById(id) {
        return new Promise((res, reject) => {
            api
                .get("/baseUnitTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createBaseUnitType(obj) {
        return new Promise((res, reject) => {
            api
                .post("/baseUnitTypes", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateBaseUnitType(obj) {
        return new Promise((res, reject) => {
            api
                .put("/baseUnitTypes", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteBaseUnitType(id) {
        return new Promise((res, rej) => {
            return api.delete('/baseUnitTypes/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const baseUnitTypeService = new BaseUnitTypeService();

export default baseUnitTypeService;
