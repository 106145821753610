import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import MarketingCampaign from "../../Utility/Crud/MarketingCampaign";
import MarketingCampaignCategory from "../../Utility/Crud/MarketingCampaignCategory";
import PropTypes from "prop-types";


export default class TileMarketingCampaign extends Tile {
    static contextTypes = {
        marketingCampaignCategoriesIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The MarketingCampaign name.
      */
    getTitle() {
        return this.props.marketingCampaign ? MarketingCampaign.getDisplayName(this.props.marketingCampaign) : "None";
    }

    /**
      * @returns {string} The MarketingCampaignCategory name.
      */
    getSubtitle() {
        if (this.props.marketingCampaign) {
            const parts = [];
            parts.push(MarketingCampaignCategory.getDisplayName(
                this.context.marketingCampaignCategoriesIndexed[this.props.marketingCampaign.campaignTypeId]
            ));
            if (!this.props.marketingCampaign.isActive) {
                parts.push("(Inactive)");
            }
            return parts.join(" ");
        }

        return null;
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.marketingCampaign ? MarketingCampaign.getDisplayName(this.props.marketingCampaign) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.marketingCampaign ? MarketingCampaign.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return MarketingCampaign.getIcon();
    }
}
