import React from "react";
import moment from "moment";
import Card from "../../Card";
import MUIBox from "@mui/material/Box";
import MUIChevronRightIcon from '@mui/icons-material/ChevronRight';
import MUIChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MUIIconButton from "@mui/material/IconButton";
import MUIAlert from "@mui/material/Alert";
import MUICard from "@mui/material/Card";
import MUICardContent from "@mui/material/CardContent";
import MUIStack from "@mui/material/Stack";
import MUITypography from "@mui/material/Typography";
import MUISkeleton from '@mui/material/Skeleton';
import MUIRedColor from "@mui/material/colors/red";
import MUIBlueColor from "@mui/material/colors/blue";
import MUIGreyColor from "@mui/material/colors/grey";
import MUITooltip from "@mui/material/Tooltip";
import MUIFileDownloadIcon from '@mui/icons-material/FileDownload';
import ChipOrderActivityType from "../../Chip/ChipOrderActivityType";
import ChipOrderActivityProductSubType from "../../Chip/ChipOrderActivityProductSubType";
import ChipInsuranceType from "../../Chip/ChipInsuranceType";
import ChipOrderStatus from "../../Chip/ChipOrderStatus";
import ChipOrderStatusReason from "../../Chip/ChipOrderStatusReason";
import PropTypes from "prop-types";
import ChipIssueCategory from "../../Chip/ChipIssueCategory";
import ChipIssueReason from "../../Chip/ChipIssueReason";
import ChipIssueStatus from "../../Chip/ChipIssueStatus";
import Chip from "../../Chip";
import MUICheckIcon from "@mui/icons-material/Check";
import MUIDateRangeIcon from "@mui/icons-material/DateRange";
import MUILocalAtmIcon from "@mui/icons-material/LocalAtm";
import MUIAvatar from "@mui/material/Avatar";
import Queue from "../../../Utility/Crud/Queue";
import TeamQueue from "../../../Utility/Crud/TeamQueue";
import TeamUser from "../../../Utility/Crud/TeamUser";
import ChipDepartment from "../../Chip/ChipDepartment";
import ChipMarketingCampaign from "../../Chip/ChipMarketingCampaign";
import ChipUser from "../../Chip/ChipUser";
import ChipTeam from "../../Chip/ChipTeam";
import User from "../../../Utility/Crud/User";
import Security from "../../../Utility/Security";
import { ResponsiveLine as NivoResponsiveLine } from '@nivo/line';
import Exporter from "../../../Utility/Exporter";
import Setting from "../../../Utility/Setting";
import QueuePrefilters from "../../QueuePrefilters";
import ChipTerm from "../../Chip/ChipTerm";
import MUIGreenColor from "@mui/material/colors/green";

export default class CardQueueFiltersSidebar extends Card {
    static contextTypes = {
        orderStatusesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        insuranceTypesIndexed: PropTypes.object,
        productSubTypesIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        issueCategoriesIndexed: PropTypes.object,
        issueReasonsIndexed: PropTypes.object,
        issueStatusesIndexed: PropTypes.object,
        teamQueuesIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        teamUsersIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
        marketingCampaignsIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state.collapsed = this.props.collapsed;
    }

    /**
     * @returns A MUI Card component with a header and content. Overrides the
     * default card properties for a custom 100% height look.
     */
    render() {
        return (
            <MUICard sx={Setting.get("ui.theme") === "dark" ? {
                height: "100%",
                background: this.getBackground(),
                overflowY: "auto",
                '::-webkit-scrollbar': {
                    backgroundColor: MUIGreyColor[900],
                    width: "8px"
                },
                "::-webkit-scrollbar-track": {
                    backgroundColor: MUIGreyColor[900],
                },
                "::-webkit-scrollbar-thumb": {
                    background: MUIGreyColor[500],
                    border: `1px solid ${MUIGreyColor[900]}`
                },
                "::-webkit-scrollbar-corner": {
                    display: "none !important"
                },
            } :
                {
                    height: "100%",
                    background: this.getBackground(),
                    overflowY: "auto",
                    scrollbarWidth: "thin",

                }
            }>
                {this.renderHeader()}

                <MUICardContent sx={this.state.collapsed ? { padding: this.getPadding() } : { padding: this.getPadding(), width: "260px" }}>
                    {this.renderContent()}
                </MUICardContent>
            </MUICard>
        );
    }

    /**
    * Renders the Queue Filters Sidebar content.
    */
    renderContent() {
        if (this.state.collapsed === false) {
            return (
                <MUIStack spacing={2}>
                    {this.renderCount()}
                    {this.renderThreshold()}
                    {this.renderPrefilters()}
                    {this.renderOrderActivityOrderActivityTypeFilter()}
                    {this.renderOrderActivityOrderStatusFilter()}
                    {this.renderOrderActivityOrderStatusReasonFilter()}
                    {this.renderOrderActivityInsuranceTypeFilter()}
                    {this.renderOrderActivityPrimaryProductSubTypeFilter()}
                    {this.renderOrderActivityMarketingCampaignFilter()}
                    {this.renderPatientIssueIssueCategoryFilter()}
                    {this.renderPatientIssueIssueReasonFilter()}
                    {this.renderPatientIssueIssueStatusFilter()}
                    {this.renderPendingPreauthCompletedFilter()}
                    {this.renderBrightreeInvoiceBrightreeInvoiceStatusTermFilter()}
                    {this.renderBrightreeInvoiceBalanceFilter()}
                    {this.renderBrightreeInvoiceFollowupDateFilter()}
                    {this.renderBrightreeInvoiceDateOfServiceFilter()}
                    {this.renderBrightreeInvoiceWorkedAtFilter()}
                    {Security.hasPermission("queue.viewHistoryChart") && this.renderOrderHistoryLineGraph()}
                    {this.renderDepartmentsTeamsUsers()}
                </MUIStack>
            );
        }

        return null;
    }

    /**
     * Render the threshold information.
     */
    renderThreshold() {
        const outOfBoundsString = Queue.getOutOfBoundsString(this.props.queue);

        if (outOfBoundsString !== null) {
            return (
                <MUIAlert severity="info">OOB after {outOfBoundsString}.</MUIAlert>
            );
        }
    }

    /**
    * Render the Order Count Filter Item.
    */
    renderCount() {
        if (this.props.loadedAt) {
            return (
                <MUIBox>
                    <MUITypography variant="body2">
                        {`${this.props.rows.length.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} result${this.props.rows.length === 1 ? "" : "s"}`}
                    </MUITypography>
                    <MUITypography variant="caption">
                        {"Updated at " + moment(this.props.loadedAt).format("h:mma")}
                    </MUITypography>
                </MUIBox>
            );
        } else {
            return (
                <MUIBox>
                    <MUISkeleton variant="text" sx={{ fontSize: '0.875rem' }} />
                    <MUISkeleton variant="text" sx={{ fontSize: '0.75rem' }} />
                </MUIBox>
            );
        }
    }

    /**
    * Render the queue preset filter clickable chips.
    */
    renderPrefilters() {
        if (
            Security.hasPermission("queue.useRegionPrefilter") === true ||
            Security.hasPermission("queue.useLocationPrefilter") === true ||
            Security.hasPermission("queue.useUserPrefilter") === true
        ) {
            return (
                <MUIBox>
                    <MUITypography variant="subtitle2">Prefilters</MUITypography>
                    <MUIStack sx={{ marginTop: 1, display: "block" }} direction="column" spacing={1}>
                        <QueuePrefilters
                            direction="column"
                            onChange={this.props.onPrefilterChange}
                        />
                    </MUIStack>
                </MUIBox>
            );
        } else {
            return null;
        }
    }

    /**
    * Renders an array of Order Status Filter Items.
    */
    renderOrderActivityOrderStatusFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["order_activity.status"] && this.props.queue.filter["order_activity.status"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Order Status</MUITypography>
                    {this.props.queue.filter["order_activity.status"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipOrderStatus
                                    orderStatus={this.context.orderStatusesIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }


    /**
    * Renders an array of Order Status Reason Filter Items.
    */
    renderOrderActivityOrderStatusReasonFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["order_activity.order_status_reason_id"] && this.props.queue.filter["order_activity.order_status_reason_id"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Reason</MUITypography>
                    {this.props.queue.filter["order_activity.order_status_reason_id"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipOrderStatusReason
                                    orderStatusReason={this.context.orderStatusReasonsIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    /**
    * Renders an array of Insurance Type Filter Items.
    */
    renderOrderActivityInsuranceTypeFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["order_activity.insurance_type_id"] && this.props.queue.filter["order_activity.insurance_type_id"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Primary Insurance Type</MUITypography>
                    {this.props.queue.filter["order_activity.insurance_type_id"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipInsuranceType
                                    insuranceType={this.context.insuranceTypesIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    /**
    * Renders an array of Order Activity Type Filter Items.
    */
    renderOrderActivityOrderActivityTypeFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["order_activity.order_type"] && this.props.queue.filter["order_activity.order_type"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Order Activity Type</MUITypography>
                    {this.props.queue.filter["order_activity.order_type"].map((order_type) => {
                        return (
                            <div key={order_type}>
                                <ChipOrderActivityType
                                    orderActivityType={Object.values(this.context.orderActivityTypesIndexed).find((orderType) => orderType.name.toLowerCase() === order_type.toLowerCase())}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    renderOrderActivityPrimaryProductSubTypeFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["order_activity_products._primary_product_sub_type_id"] && this.props.queue.filter["order_activity_products._primary_product_sub_type_id"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Primary Product Subtype</MUITypography>
                    {this.props.queue.filter["order_activity_products._primary_product_sub_type_id"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipOrderActivityProductSubType
                                    productSubType={this.context.productSubTypesIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    renderOrderActivityMarketingCampaignFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["order_activity.marketing_campaign_id"] && this.props.queue.filter["order_activity.marketing_campaign_id"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Marketing Campaign</MUITypography>
                    {this.props.queue.filter["order_activity.marketing_campaign_id"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipMarketingCampaign
                                    marketingCampaign={this.context.marketingCampaignsIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    renderPatientIssueIssueCategoryFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["patient_issue.issue_category_id"] && this.props.queue.filter["patient_issue.issue_category_id"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Category</MUITypography>
                    {this.props.queue.filter["patient_issue.issue_category_id"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipIssueCategory
                                    issueCategory={this.context.issueCategoriesIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    renderPatientIssueIssueReasonFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["patient_issue.issue_reason_id"] && this.props.queue.filter["patient_issue.issue_reason_id"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Reason</MUITypography>
                    {this.props.queue.filter["patient_issue.issue_reason_id"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipIssueReason
                                    issueReason={this.context.issueReasonsIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    renderPatientIssueIssueStatusFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["patient_issue.issue_status"] && this.props.queue.filter["patient_issue.issue_status"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Status</MUITypography>
                    {this.props.queue.filter["patient_issue.issue_status"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipIssueStatus
                                    issueStatus={this.context.issueStatusesIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    renderPendingPreauthCompletedFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["pending_preauth.completed"] && this.props.queue.filter["pending_preauth.completed"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Completed Status</MUITypography>
                    {this.props.queue.filter["pending_preauth.completed"].map((completed, i) => {
                        return (
                            <div key={i}>
                                <Chip
                                    avatar={(<MUIAvatar sx={{ background: "transparent" }}>
                                        <MUICheckIcon fontSize="small" />
                                    </MUIAvatar>)}
                                    label={completed === 1 ? "Completed" : "Not Completed"}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    renderBrightreeInvoiceBrightreeInvoiceStatusTermFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["brightree_invoice.brightree_invoice_status_term_id"] && this.props.queue.filter["brightree_invoice.brightree_invoice_status_term_id"].length > 0 ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Status</MUITypography>
                    {this.props.queue.filter["brightree_invoice.brightree_invoice_status_term_id"].map((id) => {
                        return (
                            <div key={id}>
                                <ChipTerm
                                    term={this.context.termsIndexed[id]}
                                    isTransparent={true}
                                />
                            </div>
                        );
                    })}
                </MUIBox>
            ) : null
        );
    }

    renderBrightreeInvoiceBalanceFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["invoice.balance"] ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Balance</MUITypography>
                    <Chip
                        avatar={(<MUIAvatar sx={{ background: "transparent" }}>
                            <MUILocalAtmIcon fontSize="small" sx={{ color: MUIGreenColor[500] }} />
                        </MUIAvatar>)}
                        label={`${this.props.queue.filter["invoice.balance"].properties.operator} ${new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(this.props.queue.filter["invoice.balance"].properties.value)}`}
                        isTransparent={true}
                    />
                </MUIBox>
            ) : null
        );
    }

    formatRelativeDateFilter(filter) {
        const operatorAliases = {
            "<": "Before",
            ">": "After"
        }
        const s = filter.properties.value !== 1 ? 's' : '';

        if (filter.properties.value === 0) {
            return `${operatorAliases[filter.properties.operator]} now`;
        } else {
            return `${operatorAliases[filter.properties.operator]} ${filter.properties.value} ${filter.properties.unit}${s} ago`;
        }
    }

    renderBrightreeInvoiceDateOfServiceFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["invoice.date_of_service"] ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">DoS</MUITypography>
                    <Chip
                        avatar={(<MUIAvatar sx={{ background: "transparent" }}>
                            <MUIDateRangeIcon fontSize="small" />
                        </MUIAvatar>)}
                        label={this.formatRelativeDateFilter(this.props.queue.filter["invoice.date_of_service"])}
                        isTransparent={true}
                    />
                </MUIBox>
            ) : null
        );
    }

    renderBrightreeInvoiceWorkedAtFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["invoice.worked_at"] ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Worked At</MUITypography>
                    <Chip
                        avatar={(<MUIAvatar sx={{ background: "transparent" }}>
                            <MUIDateRangeIcon fontSize="small" />
                        </MUIAvatar>)}
                        label={this.formatRelativeDateFilter(this.props.queue.filter["invoice.worked_at"])}
                        isTransparent={true}
                    />
                </MUIBox>
            ) : null
        );
    }

    renderBrightreeInvoiceFollowupDateFilter() {
        return (
            this.props.queue && this.props.queue.filter && this.props.queue.filter["invoice.followup_date"] ? (
                <MUIBox>
                    <MUITypography variant="subtitle2">Followup Date</MUITypography>
                    <Chip
                        avatar={(<MUIAvatar sx={{ background: "transparent" }}>
                            <MUIDateRangeIcon fontSize="small" />
                        </MUIAvatar>)}
                        label={this.formatRelativeDateFilter(this.props.queue.filter["invoice.followup_date"])}
                        isTransparent={true}
                    />
                </MUIBox>
            ) : null
        );
    }

    renderDepartmentsTeamsUsers() {
        const teamQueues = TeamQueue.read(
            this.context.teamQueuesIndexed,
            [
                {
                    key: "queueId",
                    value: this.props.queue.id
                }
            ]
        );
        const teamIds = teamQueues.map(teamQueue => teamQueue.teamId);
        let count = 0;

        const content = (
            <MUIBox>
                <MUITypography variant="subtitle2">Team</MUITypography>
                {
                    teamIds.map(teamId => {
                        const team = this.context.teamsIndexed[teamId];
                        const department = this.context.departmentsIndexed[team.departmentId];
                        const teamUsers = TeamUser.read(
                            this.context.teamUsersIndexed,
                            [
                                {
                                    key: "teamId",
                                    value: team.id
                                }
                            ]
                        );
                        const userIds = teamUsers.map(teamUser => teamUser.userId);
                        const users = User.sort(User.read(
                            this.context.usersIndexed,
                            [
                                {
                                    key: "id",
                                    value: userIds
                                }
                            ]
                        ));

                        return (
                            <div key={team.id}>
                                <ChipDepartment isTransparent={true} key={department.id} department={this.context.departmentsIndexed[department.id]} />
                                <MUIBox sx={{ paddingLeft: 1 }}>
                                    <ChipTeam isTransparent={true} key={team.id} team={this.context.teamsIndexed[team.id]} />
                                    <MUIBox sx={{ paddingLeft: 1 }}>
                                        <ChipUser tooltip="Manager" isTransparent={true} user={this.context.usersIndexed[this.context.teamsIndexed[team.id].managerId]} />
                                        {users.map((user) => {
                                            count++;
                                            return (
                                                <div key={user.id}>
                                                    <ChipUser isTransparent={true} user={this.context.usersIndexed[user.id]} />
                                                </div>
                                            );
                                        })}
                                    </MUIBox>
                                </MUIBox>
                            </div>
                        );
                    })
                }
            </MUIBox>
        );

        // Hide this for large lists (ex: Sales) as it's not especially helpful.
        if (count <= 25) {
            return content;
        }

        return null;
    }

    /**
     * Exports a CSV file.
     */
    exportCSV() {
        const rows = [];

        // Header
        rows.push([
            'Timestamp',
            'Queue ID',
            'Count',
            'OOB',
            'Points',
        ]);

        // Data
        this.props.queueStatistics.forEach(queueStatistic => {
            rows.push([
                moment.utc({
                    year: queueStatistic.year,
                    month: queueStatistic.month - 1,
                    day: queueStatistic.day,
                    hour: queueStatistic.hour
                }).local().format("YYYY-MM-DD h:mma"),
                queueStatistic.queueId,
                queueStatistic.count,
                queueStatistic.countOutOfBounds,
                queueStatistic.sumPoints
            ]);
        });

        Exporter.exportCSV(
            rows,
            `${this.props.queue.name} - 30 Day Volume - ${moment(this.props.loadedAt).format("YYYY-MM-DD h mm a")}.csv`
        );
    }

    renderOrderHistoryLineGraph() {
        let countData = [];
        let outOfBoundsData = [];

        if (this.props.queueStatistics) {
            this.props.queueStatistics.forEach((queueStatistic) => {
                const x = moment.utc({
                    year: queueStatistic.year,
                    month: queueStatistic.month - 1,
                    day: queueStatistic.day,
                    hour: queueStatistic.hour
                }).local().format('M/D ha');

                countData.push({
                    x: x,
                    y: queueStatistic.count,
                });
                outOfBoundsData.push({
                    x: x,
                    y: queueStatistic.countOutOfBounds,
                });
            });
        }

        let lineChartData = [
            {
                id: "OOB",
                color: MUIRedColor[700],
                data: outOfBoundsData,
            },
            {
                id: "Count",
                color: MUIBlueColor[700],
                data: countData,
            },
        ];

        return (
            this.props.queueStatistics ? (
                this.props.queueStatistics.length > 1 ? (<MUIBox>
                    <MUIBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <MUITypography variant="subtitle2">30 Day Volume</MUITypography>
                        <MUITooltip title="Export CSV" placement="right" onClick={this.exportCSV.bind(this)} >
                            <MUIIconButton area-label="export csv">
                                <MUIFileDownloadIcon fontSize="small" />
                            </MUIIconButton>
                        </MUITooltip>
                    </MUIBox>
                    <MUIBox style={{ height: 200, width: 227 }}>
                        <NivoResponsiveLine
                            data={lineChartData}
                            yScale={{
                                type: "linear",
                            }}
                            xScale={{
                                type: "point",
                            }}
                            curve="monotoneX"
                            enablePoints={false}
                            enableGridX={false}
                            enableGridY={false}
                            axisTop={null}
                            axisRight={null}
                            colors={{ datum: "color" }}
                            axisBottom={null}
                            axisLeft={null}
                            crosshairType={"x"}
                            sliceTooltip={(point) => {
                                return (
                                    <MUICard style={{ background: this.getBackground(), padding: "4px" }}>
                                        <MUITypography variant="body2" style={{ fontWeight: "bold" }}>{point.slice.points[0].data.x}</MUITypography>
                                        <MUITypography variant="body2" style={{ display: "flex", alignItems: "center" }}><span style={{ backgroundColor: point.slice.points[0].color, width: "10px", height: "10px", marginRight: "8px" }}></span>{`${point.slice.points[0].serieId}: ${point.slice.points[0].data.y}`}</MUITypography>
                                        <MUITypography variant="body2" style={{ display: "flex", alignItems: "center" }}><span style={{ backgroundColor: point.slice.points[1].color, width: "10px", height: "10px", marginRight: "8px" }}></span>{`${point.slice.points[1].serieId}: ${point.slice.points[1].data.y}`}</MUITypography>
                                    </MUICard>
                                );
                            }}
                            enableTouchCrosshair={true}
                            enableArea
                            enableSlices="x"
                            fill={[
                                {
                                    id: 'gradientA',
                                    match: '*'
                                }
                            ]}
                            defs={[
                                {
                                    colors: [
                                        {
                                            color: 'inherit',
                                            offset: 0
                                        },
                                        {
                                            color: 'inherit',
                                            offset: 100,
                                            opacity: 0
                                        }
                                    ],
                                    id: 'gradientA',
                                    type: 'linearGradient'
                                }
                            ]}
                        />
                    </MUIBox>
                </MUIBox>
                ) :
                    (
                        <MUIBox>
                            <MUIBox>
                                <MUITypography variant="subtitle2">30 Day Volume</MUITypography>
                            </MUIBox>
                            <MUIBox sx={{ "paddingTop": 2 }}>
                                <MUITypography display="block" variant="body2">No Data</MUITypography>
                            </MUIBox>
                        </MUIBox>
                    )
            ) : (
                <MUISkeleton variant="rectangular" height={230} width={230} />
            )
        );
    }

    /**
    * Renders collapsed and expand sidebar icon buttons.
    */
    getActions() {
        const handleClick = () => {
            const collapsed = !this.state.collapsed;

            this.setState({
                collapsed: collapsed
            });
            this.props.onCollapse(collapsed);
        };

        return [(
            <MUIIconButton onClick={handleClick}>
                {this.state.collapsed ? (<MUIChevronRightIcon fontSize="small" />) : (<MUIChevronLeftIcon fontSize="small" />)}
            </MUIIconButton>
        )];
    }

    /**
     * @returns {string} The title of the card.
    */
    getTitle() {
        return this.state.collapsed ? null : this.props.queue.name;
    }

}