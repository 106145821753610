import Layer from "../../../Layer";
import React from "react";
import PageHeader from "../../../Component/PageHeader";
import CardARDashboardResults from "../../../Component/Card/Report/AR/CardARDashboardResults";
import api from "../../../Seating/Security/api";
import Location from "../../../Utility/Crud/Location";
import Term from "../../../Utility/Crud/Term";
import Setting from "../../../Utility/Setting";
import CardReportSettings from "../../../Component/Card/Report/CardReportSettings";

export default class ARDashboard extends Layer {
    columns = [
        {
            title: "Sales Location",
            name: "brightree_invoice.order_activity.sales_location_id",
            type: "string",
            width: 150,
        },
        {
            title: "Payor",
            name: "brightree_invoice.payor_name",
            type: "string",
            width: 220,
        },
        {
            title: "Status",
            name: "brightree_invoice.brightree_invoice_status_term_id",
            type: "string",
            width: 100,
        },
        {
            title: " ",
            name: "blank",
            type: "blank",
            width: 0,
        },
        {
            title: "Total",
            name: "brightree_invoice.balance.bucket_total",
            type: "currency",
            width: 130,
            align: "right"
        },
        {
            title: "0-30",
            name: "brightree_invoice.balance.bucket_0_30",
            type: "currency",
            width: 130,
            align: "right"
        },
        {
            title: "31-60",
            name: "brightree_invoice.balance.bucket_31_60",
            type: "currency",
            width: 130,
            align: "right"
        },
        {
            title: "61-90",
            name: "brightree_invoice.balance.bucket_61_90",
            type: "currency",
            width: 130,
            align: "right"
        },
        {
            title: "91-120",
            name: "brightree_invoice.balance.bucket_91_120",
            type: "currency",
            width: 130,
            align: "right"
        },
        {
            title: "120+",
            name: "brightree_invoice.balance.bucket_121_plus",
            type: "currency",
            width: 130,
            align: "right"
        },
    ];

    allGroups = [
        {
            columnName: "brightree_invoice.order_activity.sales_location_id",
            title: "Sales Location",
        },
        {
            columnName: "brightree_invoice.payor_name",
            title: "Payor"
        },
        {
            columnName: "brightree_invoice.brightree_invoice_status_term_id",
            title: "Status"
        }
    ];

    defaultActiveGroups = [
        {
            columnName: "brightree_invoice.order_activity.sales_location_id",
            title: "Sales Location",
        },
    ]

    afterComponentDidMount() {
        const groupByColumns = Setting.get("reports.ARDashboard.grouping") ? Setting.get("reports.ARDashboard.grouping").map(group => group.columnName) : this.defaultActiveGroups.map(group => group.columnName);
        this.setState({
            grouping: Setting.get("reports.ARDashboard.grouping") || this.defaultActiveGroups,
            dataGridGrouping: this.allGroups,
            allGroups: this.allGroups,
        }, () => {
            this.getAccountsReceivable(groupByColumns);
            this.setColumnWidths();
        });
    }

    /**
    * Sets the column widths after component has mounted.
    */
    setColumnWidths() {
        const groupingLength = this.state.grouping.length;

        const getColumnWidth = (columnName, columnType, groupingLength) => {
            if (columnType === "currency") {
                return 130; 
            }

            if (columnType === "blank") {
                return groupingLength === 0 ? 570 : 0; 
            }

            const defaultWidth = 200;

            let widthMultiplier = 5;
            
            if (groupingLength === 3) {
                widthMultiplier = 0.78;
            } else if (groupingLength === 2) {
                widthMultiplier = 1.3;
            } else if (groupingLength === 1) {
                widthMultiplier = 2.85;
            } 
            
            const dynamicWidth = defaultWidth * widthMultiplier;

            const limit = 1400;
            return Math.min(dynamicWidth, limit);
        };

        const defaultColumnWidths = this.columns.map((column) => {
            const width = getColumnWidth(column.name, column.type, groupingLength);  
            return {
                columnName: column.name,
                width: width,
            };
        });

        this.setState({
            defaultColumnWidths: defaultColumnWidths,
        });
    }

    async getAccountsReceivable(groupBy) {
        this.setState({ accountsReceivableLoading: true })

        let arg = {
            "groupBy": groupBy
        };

        return api.send("POST", '/invoice/getAccountsReceivable', arg)
            .then((res) => {
                const transformedRows = res.data.map(row => {
                    if (row["brightree_invoice.order_activity.sales_location_id"]) {
                        row["brightree_invoice.order_activity.sales_location_id"] = Location.getDisplayName(this.context.locationsIndexed[row["brightree_invoice.order_activity.sales_location_id"]]) || row["brightree_invoice.order_activity.sales_location_id"];
                    }

                    if (row["brightree_invoice.brightree_invoice_status_term_id"]) {
                        row["brightree_invoice.brightree_invoice_status_term_id"] = Term.getDisplayName(this.context.termsIndexed[row["brightree_invoice.brightree_invoice_status_term_id"]]) || row["brightree_invoice.brightree_invoice_status_term_id"];
                    }

                    return row;
                });

                let dataGridGrouping = [...this.state.grouping];
                dataGridGrouping.pop();

                this.setState({
                    dataGridGrouping: dataGridGrouping,
                    rows: transformedRows,
                    loadedAt: new Date(),
                    accountsReceivableLoading: false,
                })
            })
            .catch((err) => {
                this.setState({ accountsReceivableLoading: false })
                // NotistackEnqueueSnackbar("Error retrieving data", { variant: "error" });
            });
    }

    handleGroupingChange(grouping) {
        const updatedGroupByColumns = grouping.map(group => group.columnName);

        this.setState({
            grouping: grouping,
        }, () => {
            this.setColumnWidths();
        });

        this.getAccountsReceivable(updatedGroupByColumns);
        Setting.set("reports.ARDashboard.grouping", grouping);
    }

    getFilteredColumns() {
        const fixedColumns =
            [
                "blank",
                "brightree_invoice.balance.bucket_total",
                "brightree_invoice.balance.bucket_0_30",
                "brightree_invoice.balance.bucket_31_60",
                "brightree_invoice.balance.bucket_61_90",
                "brightree_invoice.balance.bucket_90_plus",
                "brightree_invoice.balance.bucket_91_120",
                "brightree_invoice.balance.bucket_121_plus",
            ];

        // Get the column names from the current grouping
        const groupNames = this.state.grouping.map(group => group.columnName);

        // Return columns: always include fixed columns and add selected group columns
        return this.columns.filter(column => fixedColumns.includes(column.name) || groupNames.includes(column.name));
    }

    /**
     * Render the content.
     */
    renderContent() {
        const filteredColumns = this.getFilteredColumns();

        return (
            <>
                <CardReportSettings
                    isLoading={this.state.accountsReceivableLoading}
                    onRun={(settings) => this.handleGroupingChange(settings.draggableList.grouping)}
                    settingComponents={{
                        draggableList: {
                            grouping: this.state.grouping,
                            allGroups: this.state.allGroups,
                            validationRules: {},
                        },
                    }}
                />
                <CardARDashboardResults
                    rows={this.state.rows || []}
                    columns={filteredColumns}
                    // onClick={(row) => console.log(row)}
                    grouping={this.state.dataGridGrouping}
                    onGroupingChange={this.handleGroupingChange.bind(this)}
                    isLoading={this.state.accountsReceivableLoading}
                    defaultColumnWidths={this.state.defaultColumnWidths}
                />
            </>
        );
    }

    renderHeader() {
        return (
            <PageHeader title="AR Dashboard" />
        );
    }

    getMaxWidth() {
        return 1400;
    }
}
