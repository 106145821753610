import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DatePicker,
    Input,
    InputFile,
    MDBIcon,
    Modal,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
    ToastContainer,
    toast,
    Fa, MDBModalHeader, MDBModalFooter, MDBBtn,
} from "mdbreact";
import ActivityService from "../Security/ActivityService/activityService";
import NoteService from "../Security/NoteService/noteService";
import ContactService from "../Security/ContactService/contactService";
import "./activities.css";
import classnames from "classnames";
import Select from "react-select";
import { Route } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import GlobalFunctions from "../Filters/GlobalFunctions";
import LayerLegacy from "../../LayerLegacy";

export default class activityEdit extends LayerLegacy {
    constructor(props) {
        super(props);

        let prevPage = "",
            activityId = props.match.params.id;

        if (props.location.state != null) {
            prevPage = props.location.state.prevPage;
        }

        this.retrieveActivity(activityId);

        this.state = {
            isDeleted: false,
            id: activityId,
            isLoaded: false,
            activity: {},
            eActivity: {},
            notes: [],
            completed: false,
            noteModalOpen: false,
            noteText: "",
            createdBy: {},
            createdAt: "",
            activeItem: "1",
            selectedExpense: {},
            prevPage: prevPage,
            callTypes: [
                { label: "Face-To-Face", value: "FTF" },
                { label: "LCMP Evaluation", value: "LCMP Evaluation" },
                { label: "Drop Ins", value: "Drop Ins" },
            ],
            callReasons: [
                { label: "Following up with new information from a previous meeting", value: "Following up with new information from a previous meeting" },
                { label: "Following up regarding new information about changes in LCDs", value: "Following up regarding new information about changes in LCDs" },
                { label: "Paperwork Education/Streamlining Processes", value: "Paperwork Education/Streamlining Processes" },
                { label: "Equipment Changes", value: "Equipment Changes" },
                { label: "Policy Changes", value: "Policy Changes" },
                { label: "Other industry and/or inter-company changes that our accounts need to be aware of", value: "Other industry and/or inter-company changes that our accounts need to be aware of" },
                { label: "Other", value: "Other" },
            ],
            contacts: [],
            accountContacts: [],
            selectedContact: null,
        };
        // this.retrieveNotes(activityId);
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        expenseTypes: PropTypes.array,
        cardTypes: PropTypes.array,
    };

    retrieveActivity(id) {
        ActivityService.getActivity(id).then((data) => {
            console.log(data);
            let con = [];
            if (data.note && data.note.length > 0) {
                con = data.note.slice();
                con.sort((a, b) => {
                    if (a.createdAt > b.createdAt) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            }
            this.setState({
                activity: data,
                eActivity: JSON.parse(JSON.stringify(data)),
                isLoaded: true,
                notes: con,
            });
            this.getAccountContacts(data.accountId);
        });
    }

    // retrieveNotes(activityId) {
    // 	NoteService.getNotes(activityId)
    // 		.then((notes) => {
    // 			if (notes === null || notes.length === 0) {
    // 				//we have no notes, return...BC
    // 				return;
    // 			}

    // 			let con = notes.slice();

    // 			//sort the notes...BC
    // 			con.sort((a, b) => {
    // 				if (a.createdAt > b.createdAt) {
    // 					return -1;
    // 				} else {
    // 					return 1;
    // 				}
    // 			});

    // 			this.setState({ notes: con });
    // 			this.triggerTaskPopup();
    // 		})
    // 		.catch((err) => {
    // 			//handle error...BC
    // 		});
    // }

    getAccountContacts(id) {
        ContactService.getAccountContacts(id)
            .then(c => {
                let o = [];
                c.map(x => {
                    return o.push({
                        label: x.firstname + " " + x.lastname,
                        value: x.id,
                    });
                });
                this.setState({
                    contacts: o,
                    accountContacts: c,
                });
            });
    }

    encodeImageFileAsURL(element) {
        let file = element[0],
            reader = new FileReader(),
            t = this,
            act = t.state.eActivity;

        function callback(im){

            let idx = im.indexOf(","),
                newStr = im.slice(idx + 1);
            act.appointmentActivity.expenseReceipt = newStr;
            t.setState({ eActivity: act });

        }


        reader.onloadend = function () {
            t.base64Resize(reader.result, .5, callback);
        };
        reader.readAsDataURL(file);
    }

    base64Resize(sourceBase64, scale , setImage) {
        const _scale = scale;
        var img = document.createElement('img');
        img.setAttribute("src", sourceBase64);

        img.onload = () => {
            let canvas = document.createElement('canvas');
            canvas.width = img.width * _scale;
            canvas.height = img.height * _scale;
            let ctx = canvas.getContext("2d");
            let iw = img.width;
            let ih = img.height;
            let scl = Math.min((1000 / iw), (1000 / ih));
            let iwScaled = iw * scl;
            let ihScaled = ih * scl;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
            const newBase64 = canvas.toDataURL("image/jpeg", scl);

            setImage(newBase64);
        };
    }
    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    toggle = (tab) => {
        if (this.state.activeItem !== tab) {
            this.setState({ activeItem: tab });
        }
    };

    getCardList() {
        const { cardTypes } = this.context;

        let a = [];
        a.push({
            label: "Select Card Type...",
            value: "",
        });

        cardTypes.map((card) => {
            return a.push({
                label: card.name,
                value: card.id,
            });
        });

        return a;
    }

    getExpenseTypeList() {
        const { expenseTypes } = this.context;

        let act = this.state.eActivity;

        let a = [];
        a.push({
            label: "Select Expense Type...",
            value: "",
        });

        if (expenseTypes && expenseTypes.length > 0) {
            expenseTypes.map((exp) => {
                if (act.cardType != null) {
                    if (exp.cardTypes) {
                        let i = exp.cardTypes.findIndex((x) => x.id === act.cardType.id);
                        if (i > -1) {
                            return a.push({
                                label: exp.name,
                                value: exp.id,
                            });
                        }
                    }
                } else {
                    return a.push({
                        label: exp.name,
                        value: exp.id,
                    });
                }

                return null;
            });
        }

        return a;
    }

    renderGoBackButton() {
        return (
            <Route
                render={({ history }) => (
                    <MDBBtn
                        color={"yellow"}
                        floating
                        size="sm"
                        data-tip={"Go Back"}
                        data-place="bottom"
                        onClick={() => {
                            if (this.state.prevPage) {
                                history.push({
                                    pathname: "/account/" + this.state.prevPage + "/detail",
                                });
                            } else {
                                history.push({
                                    pathname: "/activities",
                                });
                            }
                        }}
                    >
                        <Fa size="2x" icon={"backward"}/>
                    </MDBBtn>
                )}
            />
        );
    }

    handleCardTypeSelected = (e) => {
        let act = this.state.eActivity;

        act.appointmentActivity.cardTypeId = e.value;

        this.setState({
            eActivity: act,
        });
    };

    handleExpenseTypeSelected = (e) => {
        let act = this.state.eActivity;

        act.appointmentActivity.expenseTypeId = e.value;

        this.setState({
            eActivity: act,
            selectedExpense: e.value,
        });
    };

    validateActivity(activity) {
        let shouldContinue = true;

        if (activity.type === "Appointment") {
            if (!activity.appointmentActivity.expenseDesc) {
                toast.warn("Please provide a description");
                shouldContinue = false;
            }

            if (!activity.appointmentActivity.expenseDate) {
                toast.warn("Please set the date");
                shouldContinue = false;
            }

            if (activity.appointmentActivity.expenses) {
                if (activity.appointmentActivity.expenses <= 0) {
                    toast.warn("Amount spent should be greater than 0");
                    shouldContinue = false;
                }

                if (!activity.appointmentActivity.expenseTypeId) {
                    toast.warn("Please select an expense type");
                    shouldContinue = false;
                }

                if (!activity.appointmentActivity.cardTypeId) {
                    toast.warn("Please select a card type");
                    shouldContinue = false;
                }

                if (
                    activity.appointmentActivity.expenseTypeId &&
					this.state.selectedExpense.receiptRequired === true
                ) {
                    if (!activity.appointmentActivity.receipt) {
                        toast.warn("Please upload a receipt");
                        shouldContinue = false;
                    }
                }

                if (!activity.appointmentActivity.vendor) {
                    toast.warn("Please identify the vendor");
                    shouldContinue = false;
                }

                if (!activity.appointmentActivity.peopleAttending) {
                    toast.warn("Please identify how many people were attending");
                    shouldContinue = false;
                }

                if (!activity.appointmentActivity.educationalTopics) {
                    toast.warn("Please identify the topics discussed");
                    shouldContinue = false;
                }
            }
        }
        else if(activity.type === "Call"){
            if(activity.quality === true){
                if(!activity.activityContact || activity.activityContact.length === 0){
                    toast.warn("Please select a contact");
                    shouldContinue = false;
                }

                if(!activity.callActivity.callReason){
                    toast.warn("Please select a call reason");
                    shouldContinue = false;
                }

                if(activity.callActivity.callReason === "Other" && (this.state.noteText === "" || this.state.noteText === null)){
                    toast.warn("Please enter the reason note");
                    shouldContinue = false;
                }
            }

            if(!activity.callActivity.callType){
                toast.warn("Please select a call type");
                shouldContinue = false;
            }
        }

        return shouldContinue;
    }

    updateActivity() {
        let act = this.state.eActivity,
            cont = this.validateActivity(act);

        if (cont !== true) {
            return;
        }

        if (act.appointmentActivity && act.appointmentActivity.expenses) {
            console.log(act);
            act.appointmentActivity.expenses = parseFloat(act.appointmentActivity.expenses);
        }

        return ActivityService.updateActivity(act)
            .then((data) => {
                this.setState({
                    activity: data,
                    eActivity: JSON.parse(JSON.stringify(data)),
                });
                toast.success("Saved Successfully!");
            })
            .catch((err) => {
                // act.appointmentActivity.expenseReceipt = str;
                toast.warn("An error occurred while saving.");
            });
    }

    renderNotesTask() {
        let notes = this.state.notes;
        return (
            <CardBody>
                <Row>
                    <Col size="12">
                        <div className={"pvNotesGroupOuterContainer"}>
                            <div className={"pvNotesGroupInnerContainer"}>
                                {notes.map((note, index) => {
                                    return this.generateNote(note, index);
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        );
    }

    renderNoteModal() {
        return (
            <Container>
                <Row>
                    <Modal
                        centered
                        className="salesPilotModal"
                        isOpen={this.state.noteModalOpen}
                        toggle={() => this.toggleNoteModalPopup()}
                    >
                        <MDBModalHeader>
							Add New Note
                        </MDBModalHeader>
                        <ModalBody>
                            <form className=" mx-3 grey-text">
                                <Input
                                    outline
                                    label="Note Text"
                                    value={this.state.noteText}
                                    onChange={this.noteChange.bind(this, "noteText")}
                                />
                                <div data-tip="If this is a note, check the 'completed' box. If it is a task, leave it unchecked.">
                                    <Input
                                        onChange={this.completedChange.bind(this)}
                                        filled
                                        label="Complete"
                                        type="checkbox"
                                        id="checkbox3"
                                    />
                                </div>
                                <ReactTooltip />
                            </form>
                        </ModalBody>
                        <MDBModalFooter>
                            <Button onClick={this.addNewNote.bind(this)}>Add Note</Button>
                        </MDBModalFooter>
                    </Modal>
                </Row>
            </Container>
        );
    }

    toggleNoteModalPopup() {
        let currentState = this.state.noteModalOpen;
        this.setState({ noteModalOpen: !currentState });
    }

    generateNote(note, index) {
        let t = this;

        function renderCompleteButton(note) {
            if (note.completed === false) {
                return (
                    <Button
                        className={"pvButtonFloat"}
                        color={"red darken-2"}
                        onClick={() => t.updateNote(note)}
                        size="sm"
                    >
						Complete
                    </Button>
                );
            }
        }

        return (
            <div className={"pvNoteContainer"} key={index}>
                <Card>
                    <CardHeader style={{backgroundColor: "#698ec7"}}>
                        {note.createdBy.firstname + " " + note.createdBy.lastname} &nbsp;&nbsp;&nbsp;
                        <span style={{ float: "right" }}>
                            {GlobalFunctions.formatDate(note.createdAt)}
                        </span>
                    </CardHeader>
                    <CardBody className={"pvNoteText"}>
                        {note.text}
                        <br />
                        {renderCompleteButton(note)}
                    </CardBody>
                </Card>
                <br />
            </div>
        );
    }

    noteChange = (property, event) => {
        this.setState({ [property]: event.target.value });
    };

    completedChange = (event) => {
        this.setState({ completed: event.target.checked });
    };

    addNewNote() {
        //get the current user context that can be passed into the note DTO...BC
        //another comment...BC
        const { currentUser } = this.context;
        let cb = { id: currentUser.id, username: currentUser.username, firstname: currentUser.firstname, lastname: currentUser.lastname },
            currentNotes = this.state.notes,
            act = this.state.activity;

        let note = {
            type: "ActivityNote",
            text: this.state.noteText,
            createdAt: GlobalFunctions.getUTCMoment(),
            account: { id: act.account.id, name: act.account.name },
            accountId: act.accountId,
            task: !this.state.completed,
            createdBy: cb,
            createdById: cb.id,
            completed: this.state.completed,
            activityId: act.id,
        };

        if (!this.state.completed) {
            note.assignedTo = act.owner;
            note.assignedToId = act.ownerId;
        }

        return NoteService.createActivityNote(act.id, note)
            .then((res) => {
                note.id = res.id;
                currentNotes.unshift(note);
                this.setState({ notes: currentNotes });
                this.toggleNoteModalPopup();
            })
            .catch((err) => {
                //Maybe send an error to the user?...BC
            });
    }

    updateNote(note) {
        let allNotes = this.state.notes;
        note.completed = true;
        return NoteService.updateActivityNote(note)
            .then((res) => {
                this.setState({ notes: allNotes });
            })
            .catch((err) => {
                //maybe throw an error if it fails...BC
            });
    }

    determineSection(act) {
        if (act.type === "Appointment") {
            return this.renderAppointmentSection();
        } else {
            return this.renderCallSection();
        }
    }

    renderAppointmentSection() {
        const { currentUser } = this.context;
        let canDelete = (this.state.eActivity.ownerId === currentUser.id || ( currentUser.role === "ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT"
        ));
        return (
            <div>
                <Row>
                    <Col size="3">
                        <div className={"datePickerOptions datePickerOutline"}>
                            <DatePicker
                                format="MM-DD-YYYY"
                                hint={"01-01-1900"}
                                keyboard
                                value={this.state.eActivity.appointmentActivity.expenseDate}
                                getValue={(evt) => {
                                    let act = this.state.eActivity;
                                    act.appointmentActivity.expenseDate = evt;
                                    this.setState({ eActivity: act });
                                }}
                                mask={[
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                ]}
                                label="Appointment Date"
                            />
                        </div>
                    </Col>
                    <Col size="3" className={"inputIcon"}>
                        <div style={{ marginTop: "1.5rem" }}>
                            <Input
                                outline
                                label="Amount Spent"
                                icon={"dollar-sign"}
                                value={this.state.eActivity.appointmentActivity.expenses}
                                onChange={(evt) => {
                                    let act = this.state.eActivity;
                                    act.appointmentActivity.expenses = evt.target.value;
                                    this.setState({ eActivity: act });
                                }}
                            />
                        </div>
                    </Col>

                    <Col size="3">
                        <div style={{ marginTop: "1.5rem" }}>
                            <Select
                                placeholder={
                                    this.state.eActivity.appointmentActivity.cardType
                                        ? this.state.eActivity.appointmentActivity.cardType.name
                                        : "Select Card Type"
                                }
                                value={this.state.eActivity.appointmentActivity.cardTypeId}
                                options={this.getCardList()}
                                onChange={this.handleCardTypeSelected.bind(this)}
                            />
                        </div>
                    </Col>
                    <Col size="3">
                        <div style={{ marginTop: "1.5rem" }}>
                            <Select
                                placeholder={
                                    this.state.eActivity.appointmentActivity.expenseType
                                        ? this.state.eActivity.appointmentActivity.expenseType.name
                                        : "Select Expense Type"
                                }
                                value={this.state.eActivity.appointmentActivity.expenseTypeId}
                                options={this.getExpenseTypeList()}
                                onChange={this.handleExpenseTypeSelected.bind(this)}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col size="8">
                        <Input
                            outline
                            label="Appointment Description"
                            value={this.state.eActivity.appointmentActivity.expenseDesc || ""}
                            maxLength="255"
                            onChange={(evt) => {
                                let act = this.state.eActivity;
                                act.appointmentActivity.expenseDesc = evt.target.value;
                                this.setState({ eActivity: act });
                            }}
                        />
                    </Col>
                    <Col size="4">
                        <Input
                            outline
                            label="Vendor"
                            value={this.state.eActivity.appointmentActivity.vendor || ""}
                            onChange={(evt) => {
                                let act = this.state.eActivity;
                                act.appointmentActivity.vendor = evt.target.value;
                                this.setState({ eActivity: act });
                            }}
                        />
                    </Col>
                </Row>

                <Row></Row>

                <Row>
                    <Col size="4">
                        <Input
                            outline
                            label="Topics Discussed"
                            value={this.state.eActivity.appointmentActivity.educationalTopics || ""}
                            onChange={(evt) => {
                                let act = this.state.eActivity;
                                act.appointmentActivity.educationalTopics = evt.target.value;
                                this.setState({ eActivity: act });
                            }}
                        />
                    </Col>

                    <Col size="4">
                        <Input
                            outline
                            label="People Attending (amount)"
                            value={this.state.eActivity.appointmentActivity.peopleAttending || ""}
                            onChange={(evt) => {
                                let act = this.state.eActivity;
                                act.appointmentActivity.peopleAttending = evt.target.value;
                                this.setState({ eActivity: act });
                            }}
                        />
                    </Col>

                    <Col size="4">
                        <Input
                            outline
                            label="Physicians Attending (names)"
                            value={this.state.eActivity.appointmentActivity.physiciansAttending || ""}
                            onChange={(evt) => {
                                let act = this.state.eActivity;
                                act.appointmentActivity.physiciansAttending = evt.target.value;
                                this.setState({ eActivity: act });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col size="4">
                        <Input
                            onChange={(evt) => {
                                let act = this.state.eActivity;
                                act.isDeleted = evt.target.checked;
                                this.setState({ eActivity: act });
                            }}
                            disabled={!canDelete}
                            filled
                            checked={this.state.eActivity.isDeleted}
                            label="Is Deleted"
                            type="checkbox"
                            id="checkbox4"
                        />
                    </Col>

                </Row>
            </div>
        );
    }

    renderCallSection() {
        return (
            <Row>
                <Col size="6">
                    <div className="userCheckbox">
                        <Input
                            onChange={(evt) => {
                                let call = this.state.eActivity;
                                call.quality = evt.target.checked;
                                if(call.quality === true){
                                    call.callType = null;
                                }
                                else{
                                    call.callReason = null;
                                }
                                this.setState({ eActivity: call });
                            }}
                            filled
                            checked={this.state.eActivity.quality}
                            label="Marketing"
                            type="checkbox"
                            id="checkbox6"
                        />
                    </div>
                </Col>

                <Col size="6">
                    <div className="datePickerOptions">
                        <DatePicker
                            format="MM-DD-YYYY"
                            hint={"01-01-1900"}
                            keyboard
                            value={this.state.eActivity.endedAt}
                            getValue={(evt) => {
                                let act = this.state.eActivity;
                                act.endedAt = evt;
                                this.setState({ eActivity: act });
                            }}
                            mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                            label="Call Date"
                        />
                    </div>
                </Col>

                <Col md="12">
                    {this.state.eActivity.quality === true ?
                        <Select
                            placeholder={this.state.eActivity.activityContact && this.state.eActivity.activityContact.length > 0 ? this.state.eActivity.activityContact[0].contact.firstname + " " + this.state.eActivity.activityContact[0].contact.lastname : "Select Contact..."}
                            options={this.state.contacts}
                            value={this.state.selectedContact}
                            onChange={(e) => {
                                let act = this.state.eActivity;
                                var c = this.state.accountContacts.filter(x => {
                                    if (x.id === e.value)
                                        return x;

                                    return null;
                                });
                                act.activityContact.push({ activityId: act.id, contactId: c.id, contact: c });
                                this.setState({
                                    eActivity: act,
                                });
                            }}
                        />
                        : ''
                    }
                    <br/>
                </Col>

                <Col md="12">
                    {this.state.eActivity.quality !== true ?
                        <Select
                            placeholder={"Select Call Type...(Required)"}
                            options={this.state.callTypes}
                            value={(this.state.eActivity.callActivity ? { label: this.state.eActivity.callActivity.callType, value: this.state.eActivity.callActivity.callType} : null)}
                            onChange={(e) => {
                                let act = this.state.eActivity;
                                act.callActivity.callType = e.value;
                                this.setState({
                                    eActivity: act,
                                });
                            }}
                        />
                        :
                        <Select
                            placeholder={"Select Call Reason...(Required)"}
                            value={{ label: this.state.eActivity.callActivity.callReason, value: this.state.eActivity.callActivity.callReason}}
                            options={this.state.callReasons}
                            onChange={(e) => {
                                let modalOpen = false;
                                let act = this.state.eActivity;
                                act.callActivity.callReason = e.value;
                                if(e.value === "Other"){
                                    modalOpen = true;
                                }
                                this.setState({
                                    noteModalOpen: modalOpen,
                                    eActivity: act,
                                });
                            }}
                        />
                    }
                </Col>
            </Row>
        );
    }

    renderReceiptSection() {
        return (
            <div>
                <InputFile
                    type="file"
                    textFieldTitle={"Upload receipt (.jpg or .png only)"}
                    className="form-control-file"
                    name="file"
                    getValue={this.encodeImageFileAsURL.bind(this)}
                />
                <Row style={{ justifyContent: "space-evenly" }}>
                    {this.renderReceipt()}
                </Row>
            </div>
        );
    }

    renderReceipt() {
        if (this.state.eActivity.appointmentActivity && this.state.eActivity.appointmentActivity.expenseReceipt){
            let rct = this.state.eActivity.appointmentActivity.expenseReceipt;

            if (!rct) {
                return <div></div>;
            }

            if (rct.indexOf("base64") === -1) {
                return (
                    <img
                        style={{ width: "200px", height: "300px" }}
                        src={"data:image/png;base64," + rct}
                        alt="Invalid format"
                    />
                );
            } else {
                return (
                    <img
                        style={{ width: "200px", height: "300px" }}
                        src={rct}
                        alt="Invalid format"
                    />
                );
            }
        }
    }

    renderContent() {
        let act = this.state.eActivity;

        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }

        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                {this.renderNoteModal()}

                <CardHeader style={{
                    paddingTop: 12,
                    paddingBottom: 8,
                    marginBottom: 10,
                    color: "white",
                    fontSize: 30,
                    backgroundColor: "#5881C1",
                }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Col md={"3"} style={{textAlign: 'right'}}> {this.renderGoBackButton()} </Col>
                        <Col size="6" style={{textAlign: 'center'}}>
                            <h3>
                                {act.account ? act.account.name : ""}
                                {act.isExpense ? " - This is an expense" : ""}
                            </h3>
                        </Col>
                        <Col md={"3"} >
                            <MDBBtn
                                color={"yellow"}
                                floating
                                size="sm"
                                data-tip={"Add Note"}
                                data-place={"bottom"}
                                onClick={this.toggleNoteModalPopup.bind(this)}
                            >
                                <MDBIcon size="2x" icon={"notes-medical"}/>

                            </MDBBtn>

                            <Button
                                floating
                                size="sm"
                                color={"success"}
                                data-tip={"Save Activity"}
                                data-place="bottom"
                                onClick={this.updateActivity.bind(this)}
                            >
                                <Fa far size="2x" icon={"save"}>
                                    {" "}
                                </Fa>
                            </Button>
                        </Col>
                    </Row>
                    <Row size="12">
                        {act.contacts
                            ? act.contacts.map((ct) => {
                                return <Col md="2"><h6> {ct.firstname + " " + ct.lastname}</h6></Col>;
                            })
                            : ""}
                    </Row>
                    <ReactTooltip />
                </CardHeader>
                <Container className="mt-4">
                    <Row>
                        <Col size="12">
                            <Nav tabs className="nav-justified navFull" style={{ marginTop: 20 }}>
                                <NavItem>
                                    <NavLink
                                        link
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeItem === "1",
                                        })}
                                        onClick={() => {
                                            this.toggle("1");
                                        }}
                                    >
                                        <MDBIcon icon="info" size="2x" />
                                        <br />
										Details
                                    </NavLink>
                                </NavItem>

                                {act.type === "Appointment" ? (
                                    <NavItem>
                                        <NavLink
                                            link
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeItem === "2",
                                            })}
                                            onClick={() => {
                                                this.toggle("2");
                                            }}
                                        >
                                            <MDBIcon icon="camera" size="2x" />
                                            <br />
											Receipt
                                        </NavLink>
                                    </NavItem>
                                ) : (
                                    ""
                                )}

                                <NavItem>
                                    <NavLink
                                        link
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeItem === "3",
                                        })}
                                        onClick={() => {
                                            this.toggle("3");
                                        }}
                                    >
                                        <MDBIcon icon="comments" size="2x" />
                                        <br />
										Notes & Tasks
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent className="card" activeItem={this.state.activeItem}>
                                <TabPane tabId="1" role="tabpanel">
                                    {this.determineSection(act)}
                                </TabPane>

                                <TabPane tabId="2" role="tabpanel">
                                    {this.renderReceiptSection()}
                                </TabPane>

                                <TabPane tabId="3" role="tabpanel">
                                    {this.renderNotesTask()}
                                </TabPane>

                                <br />

                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
