import api from "../api";

class DistributionReasonService {
    async getAllDistributionReasons() {
        return new Promise((res, reject) => {
            api
                .get("/distributionReason")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getDistributionReasonById(id) {
        return new Promise((res, reject) => {
            api
                .get("/distributionReason/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createDistributionReason(obj) {
        return new Promise((res, reject) => {
            api
                .post("/distributionReason", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateDistributionReason(obj) {
        return new Promise((res, reject) => {
            api
                .put("/distributionReason", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteDistributionReason(id) {
        return new Promise((res, rej) => {
            return api.delete('/distributionReason/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const distributionReasonService = new DistributionReasonService();

export default distributionReasonService;
