import TermList from "../AdminTermList";

export default class AdminTermListCommissionTier extends TermList {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'commission_tier';
    }
}
