import api from "../api";

class AccountService {
    async getAllAccounts(filters) {
        return new Promise((res, reject) => {
            let params = {
                ids: filters && filters.ids ? filters.ids.toString() : [],
                q: filters && filters.q ? filters.q : "",
            };
            api
                .get("/accounts/search", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spMergeAccounts
    async mergeAccounts(params) {
        return new Promise((res, reject) => {
            return api
                .get("/accounts/mergeAccounts", params)
                .then(() => {
                    res();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    async setMaster(params) {
        return new Promise((res, reject) => {

            return api
                .get("/accounts/setMaster", params)
                .then(() => {
                    res();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getAccountsByUser(userId) {
        return new Promise((res, reject) => {
            api
                .get("/users/" + userId + "/accounts")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //to get all account types
    async getAllAccountTypes() {
        return new Promise((res, reject) => {
            api
                .get("/accountTypes")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getAccount(id) {
        return new Promise((res, reject) => {
            api
                .get("/sources/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createAccount(account) {
        return api.post("/sources", account);
    }

    async updateAccount(account) {
        return new Promise((res, reject) => {
            api
                .put("/sources", account)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getRepAnalytics(id, scope) {
        return new Promise((res, reject) => {
            return api.get('/mobileRepAnalytics/' + id, {scope: scope}).then(data => {
                res(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    //user id
    // calls proc spMobileStatisticsForSalesRepsMonth
    async getRepsForLocationsMonth(id, month, year) {
        let params = {
            id: id,
            month: month,
            year: year
        };

        return new Promise((res, reject) => {
            return api.get('/salesRepMonthStats', params).then(data => {
                res(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    //user id
    // calls proc spMobileStatisticsForLocationsMonth
    async getLocationsStatisticsMonth(id, month, year) {
        let params = {
            id: id,
            month: month,
            year: year
        };

        return new Promise((res, reject) => {
            return api.get('/locationsMonthStats', params).then(data => {
                res(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    async getMasterAccounts() {
        return new Promise((res, rej) => {
            return api.get("/accounts/masterAccounts")
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async GetAccountPricesheets(id) {
        return new Promise((res, rej) => {
            return api.get("/accountPricesheets/" + id)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async CreateAccountPricesheets(sheets) {
        return new Promise((res, rej) => {
            return api.post("/accountPricesheets", sheets)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async CreateAccountPricesheet(sheet) {
        return new Promise((res, rej) => {
            return api.post("/accountPricesheet", sheet)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async UpdatePricesheets(sheets) {
        return new Promise((res, rej) => {
            return api.put("/accountPricesheets", sheets)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async DeleteAccountPricesheets(id) {
        return new Promise((res, rej) => {
            return api.delete("/accountPricesheets/" + id)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }
}

const accountService = new AccountService();

export default accountService;
