import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import TileRegion from "../../../Tile/TileRegion";
import DialogSearchRegion from "../../../Dialog/Search/DialogSearchRegion";

export default class CardAdminLocationGeneral extends Card {
    constructor(props) {
        super(props);

        this.state.salesRegionDialogOpen = false;
        this.state.fieldOperationsRegionDialogOpen = false;
    }

    static contextTypes = {
        regionsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        let location = this.props.location;
        let salesRegion = null;
        let fieldOperationsRegion = null;

        if (location && location.regionId !== null) {
            salesRegion = this.context.regionsIndexed[location.regionId];
        }

        if (location && location.fieldOperationsRegionId !== null) {
            fieldOperationsRegion = this.context.regionsIndexed[location.fieldOperationsRegionId];
        }

        // Sales Region
        const handleEditSalesRegion = () => {
            this.setState({ salesRegionDialogOpen: true });
        };
        const handleClearSalesRegion = () => {
            this.props.onChange({ regionId: null });
        };
        const salesRegionIconButtons = [
            (<MUIIconButton onClick={handleEditSalesRegion}><MUIEditIcon /></MUIIconButton>)
        ];

        if (salesRegion) {
            salesRegionIconButtons.push(
                (<MUIIconButton onClick={handleClearSalesRegion}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Field Ops Region
        const handleEditFieldOperationsRegion = () => {
            this.setState({ fieldOperationsRegionDialogOpen: true });
        };
        const handleClearFieldOperationsRegion = () => {
            this.props.onChange({ fieldOperationsRegionId: null });
        };
        const fieldOperationsRegionIconButtons = [
            (<MUIIconButton onClick={handleEditFieldOperationsRegion}><MUIEditIcon /></MUIIconButton>)
        ];

        if (salesRegion) {
            fieldOperationsRegionIconButtons.push(
                (<MUIIconButton onClick={handleClearFieldOperationsRegion}><MUIDeleteIcon /></MUIIconButton>)
            );
        }


        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.location && this.props.location.name ? this.props.location.name : ""}
                        autoFocus={true}
                        size="small"
                        label="Name"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ name: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ name: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.name ? true : false}
                        helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <TileRegion
                        region={salesRegion}
                        iconButtons={salesRegionIconButtons}
                        label="Sales Region"
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <TileRegion
                        region={fieldOperationsRegion}
                        iconButtons={fieldOperationsRegionIconButtons}
                        label="Field Operations Region"
                    />
                </MUIGrid>
                <DialogSearchRegion
                    data={Object.values(this.context.regionsIndexed).filter((region) => region.regionTypeTermId === 11)}
                    open={this.state.salesRegionDialogOpen}
                    onClose={() => this.setState({ salesRegionDialogOpen: false })}
                    onSelect={(region) => {
                        this.props.onChange({ regionId: region.id });
                    }}
                />
                <DialogSearchRegion
                    data={Object.values(this.context.regionsIndexed).filter((region) => region.regionTypeTermId === 12)}
                    open={this.state.fieldOperationsRegionDialogOpen}
                    onClose={() => this.setState({ fieldOperationsRegionDialogOpen: false })}
                    onSelect={(region) => {
                        this.props.onChange({ fieldOperationsRegionId: region.id });
                    }}
                />
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}