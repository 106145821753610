import Crud from "../Crud";
import MUIRedColor from "@mui/material/colors/red";

/**
 * Static helper functions related to InsuranceTypes.
 */
export default class InsuranceType extends Crud {

    /**
     * @param {object} insuranceType The insuranceType object.
     * @returns The display name of the insuranceType.
     */
    static getDisplayName(insuranceType) {
        return insuranceType ? insuranceType.name : null;
    }

    /**
     * Determines if a insuranceType matches a search query.
     *
     * @param {object} insuranceType The insuranceType to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the insuranceType matches the query.
     */
    static match(insuranceType, query) {
        return (
            (insuranceType.name !== null && insuranceType.name.toLowerCase().includes(query)) ||
            insuranceType.id === +query
        );
    }

    /**
     * @param {array} insuranceTypes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(insuranceTypes) {
        return insuranceTypes.sort((a, b) => {
            if (a.sortOrder !== null && b.sortOrder !== null) {
                return a.sortOrder - b.sortOrder;
            } else {
                return ( a.sortOrder === null ) - ( b.sortOrder === null ) || (a.name).localeCompare(b.name);
            }
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIRedColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "LocalHospital";
    }
}