import api from "../api";

class IntakeQuestionnaireService {
    async createIntakeQuestionnaire(obj) {
        return new Promise((res, reject) => {
            api
                .post("/orders/intakeQuestionnaire/", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getIntakeQuestionnaire(id) {
        return new Promise((res, rej) => {
            api.get("/orders/intakeQuestionnaire/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }
}

const intakeQuestionnaireService = new IntakeQuestionnaireService();

export default intakeQuestionnaireService;
