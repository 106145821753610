import React from "react";
import DialogSearch from "../DialogSearch";
import TileOrderActivityType from "../../Tile/TileOrderActivityType";
import PropTypes from "prop-types";
import OrderActivityType from "../../../Utility/Crud/OrderActivityType";

export default class DialogSearchOrderActivityType extends DialogSearch {

    static contextTypes = {
        orderActivityTypesIndexed: PropTypes.object,
    };

    /**
     * Searches for Order Activity Types by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered orderActivityTypes.
     */
    getSearchResults(query) {
        return OrderActivityType.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.orderActivityTypesIndexed);
        }
    }

    /**
     * @param {object} orderActivityType The orderActivityType object.
     * @returns A TileOrderActivityType component for this orderActivityType.
     */
    renderTile(orderActivityType) {
        return (
            <TileOrderActivityType
                key={orderActivityType.id}
                orderActivityType={orderActivityType}
            />
        );
    }
}
