import React from "react";
import Card from "../../../Card";
import DataGrid from "../../../DataGrid";
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIFileDownloadIcon from '@mui/icons-material/FileDownload';
import MUIMenuItem from "@mui/material/MenuItem";
import MUIBox from "@mui/material/Box";
import Progress from "../../../Progress";
import EmptyState from "../../../EmptyState";
import Exporter from "../../../../Utility/Exporter";
import Menu from "../../../Menu";
import moment from "moment";

export default class CardPatientCollectionsOpenBalances extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        if (this.props.loadedAt) {
            if (this.props.rows && this.props.rows.length > 0) { 
                return (
                    <MUIBox style={{ height: '580px', marginBottom: 40 }}>
                        <DataGrid
                            rows={this.props.rows}
                            columns={this.props.columns}
                            defaultColumnWidths={this.props.defaultColumnWidths}
                            onClick={this.props.onClick}
                        />
                    </MUIBox>
                )
            } else {
                return (
                    <EmptyState
                        line1={"No open balances"}
                        line2={"Start by uploading a collection file"}
                    />
                )
            }

        }
        return (<Progress message="Loading Data..." />);
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Open Balances";
    }

    /**
     * Renders add member button.
     */
    getActions() {
        const handleExport = () => {
            const csvRows = [];

            //Table headers
            csvRows.push(this.props.columns.map((column) => column.title));

            // Table rows
            const rows = this.props.rows;
            rows.forEach((row) => {
                const csvRow = [];
                this.props.columns.forEach((column) => {
                    csvRow.push(row[column.name]);
                });
                csvRows.push(csvRow);
            });

            const filename = `Patient Collections - ${moment(this.props.loadedAt).format("YYYY-MM-DD h mm a")}.csv`;

            Exporter.exportCSV(csvRows, filename);
        }

        return ([
            <Menu
                menuItems={[(
                    <MUIMenuItem disabled={!this.props.loadedAt || (this.state.rows && this.state.rows.length === 0)} onClick={handleExport}>
                        <MUIListItemIcon>
                            <MUIFileDownloadIcon fontSize="small" />
                        </MUIListItemIcon>
                        Export CSV
                    </MUIMenuItem>
                )]}
            >
            </Menu>
        ]);

      
    }
}