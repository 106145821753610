import Admin from "../Admin";
import React from "react";
import CardAdminTermList from "../../../Component/Card/Admin/Term/CardAdminTermList";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIAddIcon from "@mui/icons-material/Add";
import Term from "../../../Utility/Crud/Term";

export default class AdminTermList extends Admin {
    /**
     * Render the content.
     */
    renderContent() {
        return (
            <CardAdminTermList type={this.getType()} />
        );
    }

    /**
     * Render the page header.
     */
    renderHeader() {
        const handleClickCreate = () => {
            const path = this.getType().replace(
                /(_\w)/g,
                (match) => match[1].toUpperCase()
            );

            this.props.history.push({
                pathname: `/admin/${path}`
            });
        };

        const buttons = [
            (<MUIButton
                component="label"
                variant="contained"
                startIcon={<MUIAddIcon />}
                onClick={handleClickCreate}>Create New</MUIButton>),
        ];

        if (this.getType()) {
            const type = this.getType();
            let title = Term.getTermTypes()[type].name;

            if (title.toLowerCase().endsWith("category")) {
                title = title.replace(/category$/i, "Categories");
            } else if (title.toLowerCase().endsWith("status")) {
                title = title.replace(/status$/i, "Statuses");
            } else {
                title = `${title}s`;
            }

            return (
                <PageHeader title={title} buttons={buttons} />
            );
        }

        return (
            <PageHeader title="Terms" buttons={buttons} />
        );
    }

    /**
     * Left for child classes to implement. Leaving it null will show all term
     * types in the list.
     *
     * @returns {string} The term type.
     */
    getType() {
        return null;
    }
}
