import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBContainer,
    MDBRow,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ExpenseTypeService from "./expenseTypeService";
import Select from "react-select";
import CreditCardService from "./creditCardTypesService";
import ReactTooltip from "react-tooltip";
import LayerLegacy from "../../LayerLegacy";

export default class expenseTypes extends LayerLegacy {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Requires Receipt",
                    field: "requiresreceipt",
                    sort: "asc",
                },
                {
                    label: "Description",
                    field: "description",
                    sort: "asc",
                },
                {
                    label: "Card Type",
                    field: "cardtype",
                    sort: "asc",
                },
                {
                    label: "Edit",
                    field: "edit",
                    sort: "asc",
                },
                {
                    label: "Delete",
                    field: "delete",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            editModal: false,
            confirmationModal: false,
            addNewModal: false,
            name: "",
            currIndex: -1,
            errorMessage: "",
            currExpense: {},
            newExpense:{
                name:"",
                description: "",
                isChecked: false,
                creditCardsSelected: [],
            },
            isChecked: false,
            creditCardsSelected: [],
            creditCards: [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        creditCardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
    };

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.retrieveExpenseType();
        this.retrieveCCTypes();
    }

    retrieveCCTypes() {
        let t = this;
        return CreditCardService.getAllCreditCardTypes()
            .then((res) => {
                let arr = [];

                // Have to do ccVal b/c it will complain about matching keys
                // if you try to pass an obj as the value instead of a string
                res.forEach((cc) => {
                    arr.push({
                        value: cc.name,
                        label: cc.name,
                        ccVal: cc,
                    });
                });

                t.setState({
                    creditCards: arr,
                });
            })
            .catch((err) => {
                //handle error...BC
            });
    }

    retrieveExpenseType() {
        let t = this;
        return ExpenseTypeService.getAllExpenseTypes()
            .then((res) => {
                console.log(res);
                let arr = [],
                    d = this.state.data;
                res.forEach((exp, index) => {
                    arr.push({
                        id: exp.id,
                        name: exp.name,
                        receipt: String(exp.receiptRequired),
                        description: exp.description,
                        card: t.renderCreditCard(exp),
                        edit: t.renderEditButton(exp, index),
                        remove: t.renderRemoveButton(exp),
                    });
                });

                d.rows = arr;

                t.setState({
                    data: d,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderCreditCard(creditCardList) {
        let a = [];
        if (creditCardList.expenseTypeCreditCards && creditCardList.expenseTypeCreditCards.length > 0) {
            creditCardList.expenseTypeCreditCards.forEach((card) => {
                a.push(card.creditCards.name);
            });
        }
        return <i>{String(a)}</i>;
    }

    renderEditButton(exp, index) {
        return (
            <MDBIcon
                onClick={() => {this.handleEditClick(exp, index);}}
                icon="edit"
                style={{color: "#7ac5ff"}}
            />
        );
    }

    renderRemoveButton(id) {
        return (
            <MDBIcon
                onClick={() => this.handleRemoveClick(id)}
                icon="trash"
                style={{color: "#db0404"}}
            />
        );
    }

    handleConfirmationDelete(id){
        ExpenseTypeService.deleteExpenseType(id)
            .then((res) => {
                this.retrieveExpenseType();
                toast.success("Expense Type Removed");
                this.handleConfirmationClose();
            })
            .catch((err) => {
                toast.warn("Error occurred while removing Expense Type");
            });
    }

    handleRemoveClick(exp) {
        let i = {
            id: exp.id,
            name: exp.name,
        };
        this.setState({
            confirmationModal: !this.state.confirmationModal,
            currExpense: i,
        });
    }

    handleEditClick(exp, index) {
        let expense = JSON.parse(JSON.stringify(exp));
        let c = [];
        if (exp.expenseTypeCreditCards && exp.expenseTypeCreditCards.length > 0) {
            exp.expenseTypeCreditCards.forEach((card) => {
                c.push({
                    label: card.creditCards.name,
                    value: card.creditCards.name,
                    ccVal: card.creditCards,
                });
            });
        }
        this.setState({
            currExpense: expense,
            editModal: !this.state.editModal,
            currIndex: index,
            isChecked: exp.receiptRequired,
            creditCardsSelected: c,
        });
    }

    editToggle = () => {
        this.setState({
            editModal: !this.state.editModal,
            errorMessage: "",
        });
    };

    confirmationToggle = () => {
        this.setState({
            confirmationModal: !this.state.confirmationModal,
            errorMessage: "",
        });
    };

    handleConfirmationClose= () => {
        let i = {
            id: -1,
            name: "",
        };
        this.setState({
            currExpense: i,
            currIndex: -1,
            errorMessage: "",
            confirmationModal: !this.state.confirmationModal,
        });
    };

    renderConfirmationModal() {
        return (
            <Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
                <ModalHeader
                    style={{ backgroundColor: "#F93154", color: "white" }}
                    toggle={this.confirmationToggle}
                >
					Confirm Delete
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderConfirmationModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Confirm Delete"}
                        onClick={() => this.handleConfirmationDelete(this.state.currExpense.id)}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleConfirmationClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderConfirmationModalBody() {
        return (
            <div>
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <MDBContainer>
                    <MDBRow>
						Are you sure you want to delete {this.state.currExpense.name}?
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }

    renderEditModal() {
        if (this.state.editModal) {
            let exp = this.state.currExpense;
            return (
                <Modal isOpen={this.state.editModal} toggle={this.editToggle}>
                    <ModalHeader
                        style={{ backgroundColor: "#90A4AE", color: "white" }}
                        toggle={this.editToggle}
                    >
						Edit Expense Type
                    </ModalHeader>
                    <ModalBody style={{ backgroundColor: "white" }}>
                        <div>
                            <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                            <Input
                                label={"Expense Type Name"}
                                onChange={this.handleChange.bind(this, "name")}
                                value={exp.name}
                            />
                            <div className="form-check my-4">
                                <input
                                    className="form-check-input"
                                    checked={this.state.isChecked}
                                    type="checkbox"
                                    id="defaultCheck12"
                                    onChange={this.checkBoxChange.bind(this)}
                                />
                                <label htmlFor="defaultCheck12" className="grey-text">
									Receipt Required
                                </label>
                            </div>
                            <Input
                                label={"Description"}
                                onChange={this.handleChange.bind(this, "description")}
                                value={exp.description}
                            />
                            <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={this.state.creditCards}
                                onChange={this.handleSelectChange.bind(this)}
                                value={this.state.creditCardsSelected}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ backgroundColor: "white" }}>
                        <Button
                            style={{ float: "right" }}
                            floating
                            size="sm"
                            color={"success"}
                            data-tip={"Update Expense Type"}
                            onClick={this.handleEditSave}
                        >
                            <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                        </Button>

                        <Button
                            style={{ float: "right" }}
                            floating
                            size="sm"
                            color={"red"}
                            data-tip={"Close"}
                            onClick={this.handleEditClose}
                        >
                            <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                        </Button>

                        <ReactTooltip />
                    </ModalFooter>
                </Modal>
            );
        }
        return <div></div>;
    }

    renderAddNewModal() {
        return (
            <Modal isOpen={this.state.addNewModal} toggle={this.handleAddNewClose}>
                <ModalHeader
                    style={{ backgroundColor: "#5881C1", color: "white" }}
                    toggle={this.handleAddNewClose}
                >
					Add New Expense Type
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderAddNewModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Add New Expense Type"}
                        onClick={this.handleAddNewSave}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleAddNewClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderAddNewModalBody() {
        if (this.state.addNewModal) {
            return (
                <div>
                    <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                    <Input
                        outline
                        label={"Expense Type Name"}
                        onChange={this.newNameChange.bind(this)}
                        value={this.state.newExpense.name}
                    />
                    <Input
                        outline
                        label={"Description"}
                        onChange={this.newDescriptionChange.bind(this)}
                        value={this.state.newExpense.description}
                    />
                    <Select
                        placeholder={"Credit Card Type"}
                        closeMenuOnSelect={false}
                        isMulti
                        options={this.state.creditCards}
                        onChange={this.newHandleSelectChange.bind(this)}
                        value={this.state.newExpense.creditCardsSelected}
                    />
                    <div className="form-check my-4">
                        <input
                            className="form-check-input"
                            checked={this.state.newExpense.isChecked}
                            type="checkbox"
                            id="defaultCheck12"
                            onChange={this.newCheckBoxChange.bind(this)}
                        />
                        <label htmlFor="defaultCheck12" className="grey-text">
							Receipt Required
                        </label>
                    </div>
                </div>
            );
        }
    }

    newNameChange = (e) => {
        let a = this.state.newExpense;
        a.name = e.target.value;
        this.setState({
            newExpense: a,
        });
    };

    newCheckBoxChange = (e) => {
        let a = this.state.newExpense;
        a.isChecked = !a.isChecked;
        this.setState({
            newExpense: a,
        });
    };

    newDescriptionChange = (e) => {
        let a = this.state.newExpense;
        a.description = e.target.value;
        this.setState({
            newExpense: a,
        });
    };

    newHandleSelectChange = (e) => {
        let a = this.state.newExpense;
        a.creditCardsSelected = e;
        this.setState({
            newExpense: a,
        });
    };

    handleChange = (property, e) => {
        let exp = this.state.currExpense;
        exp[property] = e.target.value;
        this.setState({
            currExpense: exp,
        });
    };

    checkBoxChange = (e) => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    };

    handleSelectChange = (e) => {
        this.setState({
            creditCardsSelected: e,
        });
    };

    handleAddNewSave = () => {
        if (this.validateAddNew()) {
            let n = this.state.newExpense.name,
                receipt = this.state.newExpense.isChecked,
                d = this.state.newExpense.description,
                cc = this.state.newExpense.creditCardsSelected;

            //cc.forEach((credit) => {
            //	ccList.push(credit.ccVal);
            //});

            let obj = {
                name: n,
                description: d,
                //cardTypes: ccList,
                receiptRequired: receipt,
            };
            obj.expenseTypeCreditCards = cc.map(c => {
                return { creditCardsId: c.ccVal.id, creditCards: c.ccVal };
            });

            return ExpenseTypeService.createExpenseType(obj)
                .then((res) => {
                    this.setState({
                        errorMessage: "",
                    });
                    this.handleAddNewClose();
                    this.retrieveExpenseType();
                    toast.success(obj.name + " Expense Type has been Added!");
                })
                .catch((err) => {
                    toast.warn("An error occurred adding a new Expense Type");
                });
        }
    };

    handleEditSave = () => {
        if (this.validateEdit()) {
            let d = this.state.data,
                i = this.state.currIndex,
                exp = this.state.currExpense,
                cc = this.state.creditCardsSelected,
                check = this.state.isChecked;

            //cc.forEach((credit) => {
            //	ccList.push(credit.ccVal);
            //});
            exp.expenseTypeCreditCards = cc.map(c => {
                return { expenseTypeId: this.state.currExpense.id, creditCardsId: c.ccVal.id, creditCards: c.ccVal };
            });
            exp.receiptRequired = check;
            return ExpenseTypeService.updateExpenseType(this.state.currExpense)
                .then((res) => {
                    d.rows[i].name = res.name;
                    d.rows[i].receipt = String(res.receiptRequired);
                    d.rows[i].description = res.description;
                    d.rows[i].card = this.renderCreditCard(res);
                    d.rows[i].edit = this.renderEditButton(res, i);
                    this.setState({
                        data: d,
                        editModal: !this.state.editModal,
                        errorMessage: "",
                    });
                    toast.success("Expense Type Edited!");
                })
                .catch((err) => {
                    toast.warn("An error occurred while saving.");
                });
        }
    };

    validateAddNew(){
        let n = this.state.newExpense.name,
            d = this.state.newExpense.description;
        if (n === "") {
            this.setError("Please enter an Expense Name");
            return false;
        } else if (d === "") {
            this.setError("Please enter a Description");
            return false;
        }
        return true;
    }

    validateEdit() {
        let exp = this.state.currExpense;
        if (exp.name === "") {
            this.setError("Please add an Expense Name");
            return false;
        } else if (exp.description === "") {
            this.setError("Please add a Description");
            return false;
        }
        return true;
    }

    setError(e) {
        this.setState({
            errorMessage: e,
        });
    }

    handleEditClose = () => {
        this.setState({
            currIndex: -1,
            errorMessage: "",
            editModal: !this.state.editModal,
        });
    };

    handleAddNewClose = () => {
        let i = {
            name:"",
            description: "",
            isChecked: false,
            creditCardsSelected: [],
        };
        this.setState({
            newExpense: i,
            addNewModal: !this.state.addNewModal,
            errorMessage: "",
        });
    };

    renderTable() {
        if (this.state.isLoaded === true) {
            return (
                <MDBTable small striped style={{ textAlign: "center" }}>
                    <MDBTableHead columns={this.state.data.columns} />
                    <MDBTableBody rows={this.state.data.rows} />
                </MDBTable>
            );
        } else {
            return <div> </div>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    addNewClick = () => {
        this.setState({
            addNewModal: true
        });
    };

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Card>
                    <CardHeader
                        style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}>
						Expense Types
                        <Button
                            style={{ float: "right" }}
                            floating
                            size="sm"
                            color={"secondary"}
                            data-tip={"Add New Expense Type"}
                            onClick={this.addNewClick}
                        >
                            <MDBIcon icon="credit-card" style={{ fontSize: "2em" }} />
                        </Button>
                    </CardHeader>
                    <CardBody>{this.renderTable()}</CardBody>
                    <ReactTooltip />
                </Card>
                {this.renderEditModal()}
                {this.renderConfirmationModal()}
                {this.renderAddNewModal()}
            </Container>
        );
    }
}
