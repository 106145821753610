import React from "react";
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Fa,
    Nav,
    NavItem,
    NavLink,
} from "mdbreact";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import "./pageHeader.css";

export default class PageHeader extends React.Component {


    constructor(props) {
        super(props);


        this.actionItems = props.actionItems;
        this.tabNames = props.tabNames;
        this.breadCrumbs = props.breadCrumbs;

        this.toggle = props.toggle;
        this.TabsOneActiveItem = props.TabsOneActiveItem;

        this.state = {
            actionItems: props.actionItems,
            TabsOneActiveItem: props.TabsOneActiveItem,
        };
    }


    static contextTypes = {

    };

    componentDidUpdate(nextProps) {
        const {actionItems} = this.props;
        if (nextProps.actionItems !== actionItems) {
            this.setState({actionItems: actionItems});
        }

    }

    renderNavTab() {
        return (
            <div>

                <Nav  tabs className="nav-justified navGrey" style={{margin: 0}}>
                    <MUIBreadcrumbs aria-label="breadcrumb" style={{ minWidth: '20vw', fontSize: '15px', padding: '0 .5rem' }}>
                        {this.breadCrumbs.map((bread, idx) => {
                            return (
                                idx < (this.breadCrumbs.length - 1) ?
                                    // <Link color="inherit" href={bread.path}>
                                    //     {bread.title}
                                    // </Link>
                                    <NavLink key={idx} style={{color:"#292929", padding: '0px'}} to={bread.path}>
                                        {bread.title}
                                    </NavLink>
                                    :
                                    <div key={idx} style={ { color:"#5881c1"}}> {bread.title}</div>

                            );
                        }
                        )}
                    </MUIBreadcrumbs>

                    {this.tabNames.map((tab, idx) => {
                        return (
                            <NavItem>
                                <NavLink
                                    link
                                    to="#"
                                    style={ { textTransform: 'uppercase', color: "#a1a1a1", fontWeight: '400' }}
                                    className={this.props.TabsOneActiveItem === (idx + 1).toString() ? "highlight" : "nonHighlight"}
                                    onClick={() => {
                                        this.toggle("1", (idx + 1).toString());
                                    }}
                                >
                                    {tab}
                                </NavLink>
                            </NavItem>
                        );
                    }
                    )}
                    <NavItem className={"actionMenuDropdown1"}>
                        {/*<Col className={"actionMenuDropdown0"}>*/}
                        <Dropdown className={"actionMenuDropdown0"}>
                            <DropdownToggle
                                className={"actionMenuDropdown"}
                                nav
                            >
                                <span style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#fff", whiteSpace: 'nowrap', border: "1px solid #A1A1A1", borderRadius: '6px', padding: '.2rem .5rem'}}>
                                    {" "}
                                    {"Actions"} &nbsp;
                                    <Fa icon={"angle-down"} style={{float: 'right'}}/>
                                </span>
                            </DropdownToggle>
                            <DropdownMenu basic style={{width: "100%", zIndex: '9999'}}>
                                {this.state.actionItems.map((action, idx) => {
                                    return (
                                        action
                                    );
                                }
                                )}

                            </DropdownMenu>
                        </Dropdown>
                        {/*</Col>*/}
                    </NavItem>
                </Nav>
            </div>
        );
    }


    render() {
        return (
            this.renderNavTab()


        );
    }
}
