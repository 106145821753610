import Admin from "../Admin";
import React from "react";
import CardAdminIssueReasonGeneral from "../../../Component/Card/Admin/IssueReason/CardAdminIssueReasonGeneral";
import CardAdminIssueReasonBehavior from "../../../Component/Card/Admin/IssueReason/CardAdminIssueReasonBehavior";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import IssueReasonService from "../../../Seating/Security/IssueReasonService/issueReasonService";
import Validator from "../../../Utility/Validator";
import IssueReason from "../../../Utility/Crud/IssueReason";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminIssueReasonCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.issueReason = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Set the issueReason in the state.
     */
    afterComponentDidMount() {
        const issueReasonId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (issueReasonId !== null) {
            this.setState({
                issueReason: this.context.issueReasonsIndexed[issueReasonId]
            });
        }
    }

    /**
     * Creates the issueReason with the current properties in state.issueReason.
     */
    async createIssueReason() {
        this.setState({
            isProcessing: true,
        });

        return IssueReasonService.createIssueReason(this.state.issueReason)
            .then(issueReason => {
                this.context.updateIndexed("issueReasonsIndexed", issueReason);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Issue Reason created successfully!", { variant: "success" });
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Issue Reason.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the issueReason with the current properties in state.issueReason.
     */
    async updateIssueReason() {
        this.setState({
            isProcessing: true,
        });

        return IssueReasonService.updateIssueReason(this.state.issueReason)
            .then(issueReason => {
                this.context.updateIndexed("issueReasonsIndexed", issueReason);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Issue Reason updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Issue Reason.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    async deleteIssueReason() {
        this.setState({
            isProcessing: true,
        });

        return IssueReasonService.deleteIssueReason(this.state.issueReason.id)
            .then(() => {
                this.context.deleteIndexed("issueReasonsIndexed", this.state.issueReason.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Issue Reason deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Issue Reason.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (issueReason) => {
            this.setState({
                issueReason: {
                    ...this.state.issueReason,
                    ...issueReason
                },
            });
        };

        return (
            <>
                <CardAdminIssueReasonGeneral
                    issueReason={this.state.issueReason}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminIssueReasonBehavior
                    issueReason={this.state.issueReason}
                    onChange={handleChange}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteIssueReason()}
                    text="Are you sure you want to delete this Issue Reason? This cannot be undone."
                    header="Delete Issue Reason"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Renders the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = "Create Issue Reason";
            subtitle = null;
        } else {
            title = "Edit Issue Reason";
            subtitle = (this.state.issueReason && (IssueReason.getDisplayName(this.state.issueReason) + " • " + this.state.issueReason.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.issueReason,
                {
                    name: {
                        'required': true
                    },
                    issueCategoryId: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });


            if (Object.keys(validationIssues).length > 0) {
                return NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateIssueReason();
                } else {
                    this.createIssueReason();
                }
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
