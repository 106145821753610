import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminUserProductionTypeList extends Card {
    static contextTypes = {
        userProductionTypesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number", width: 100 },
                    { key: "name", name: "Name", type: "string"},
                    { key: "description", name: "Description", type: "string"},
                ]}
                sort={[
                    {
                        key: "id",
                        direction: "ascending",
                        type: "number"
                    }
                ]}
                rows={Object.values(this.context.userProductionTypesIndexed)}
                onClick={(userProductionType) => {
                    this.props.history.push({
                        pathname: "/admin/userProductionType/" + userProductionType.id
                    });
                }}
            />
        );
    }
});