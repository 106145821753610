import React from "react";
import Card from "../../../Card";
import EmptyState from "../../../EmptyState";
import MUIButton from "@mui/material/Button";
import MUIPowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MUITextField from "@mui/material/TextField";
import MUIAutocomplete from "@mui/material/Autocomplete";
import MUIStack from "@mui/material/Stack";

export default class CardAdminQueueFilterBrightreeInvoiceBalance extends Card {
    constructor(props) {
        super(props);

        this.state = {
            enabled: !!props.queue.filter?.["invoice.balance"]
        }
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleChange = (key, value) => {
            const filter = this.props.queue.filter;

            filter["invoice.balance"].properties[key] = value;

            this.props.onChange({
                filter: filter
            });
        };

        const options = {
            operators: [
                {
                    value: "=",
                    label: "Balance ="
                },
                {
                    value: "<",
                    label: "Balance <"
                },
                {
                    value: "<=",
                    label: "Balance <="
                },
                {
                    value: ">",
                    label: "Balance >"
                },
                {
                    value: ">=",
                    label: "Balance >="
                },
            ]
        }

        if (this.state.enabled) {
            return (
                <MUIStack direction="row" spacing={1}>
                    <MUIAutocomplete
                        options={options.operators}
                        getOptionLabel={option => option.label}
                        value={options.operators.find(operator => operator.value === this.props.queue?.filter["invoice.balance"].properties.operator) || null}
                        onChange={(e, option) => handleChange("operator", option?.value || null) }
                        renderInput={(params) => {
                            return (
                                <MUITextField
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    label="Operator"
                                    variant="standard"
                                    required={true}
                                    sx={{ width: 150 }}
                                />
                            );
                        }}
                    />
                    <MUITextField
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        label="Value"
                        variant="standard"
                        required={true}
                        sx={{ width: 70 }}
                        inputProps={{
                            maxLength: 7,
                            inputMode: "numeric"
                        }}
                        value={this.props.queue.filter["invoice.balance"].properties.value}
                        onChange={(e) => handleChange("value", +e.target.value) }
                    />
                </MUIStack>
            );
        } else {
            return (
                <EmptyState
                    line1="Not Enabled"
                    line2="Showing all Balances"
                />
            );
        }
    }

    /**
     * Renders add Term button
     */
    getActions() {
        const handleEnableDisable = () => {
            if (this.state.enabled) {
                const filter = this.props.queue.filter;
                delete filter["invoice.balance"];

                // Disable and delete
                this.setState({
                    enabled: false
                });
            } else {
                // Enable and initialize
                const filter = this.props.queue.filter || {};
                filter["invoice.balance"] = {
                    type: "number",
                    properties: {
                        operator: null,
                        value: ""
                    }
                };
                this.props.onChange({
                    filter: filter
                });

                this.setState({
                    enabled: true
                });
            }
        };

        const buttonItems = [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIPowerSettingsNewIcon />}
                onClick={handleEnableDisable}>{ this.state.enabled ? "Disable" : "Enable"}</MUIButton>)
        ];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Filter: Brightree Invoice > Balance";
    }
}