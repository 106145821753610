import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import MarketingCampaignCategory from "../../Utility/Crud/MarketingCampaignCategory";

export default class TileMarketingCampaignCategory extends Tile {
    /**
      * @returns {string} The MarketingCampaignCategory name.
      */
    getTitle() {
        return this.props.marketingCampaignCategory ? MarketingCampaignCategory.getDisplayName(this.props.marketingCampaignCategory) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.marketingCampaignCategory ? MarketingCampaignCategory.getDisplayName(this.props.marketingCampaignCategory) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.marketingCampaignCategory ? MarketingCampaignCategory.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return MarketingCampaignCategory.getIcon();
    }
}
