import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    toast, MDBContainer, MDBRow,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import CreditCardService from "./creditCardTypesService";
import LayerLegacy from "../../LayerLegacy";

export default class creditCardTypes extends LayerLegacy {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Edit",
                    field: "edit",
                    sort: "asc",
                },
                {
                    label: "Delete",
                    field: "delete",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            editModal: false,
            confirmationModal: false,
            addNewModal: false,
            name: "",
            currIndex: -1,
            errorMessage: "",
            newCC:{
                name: "",
            },
            currCC: {},
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        cardTypes: PropTypes.array,
    };

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.retrieveCCType(false);
    }

    retrieveCCType(fetch) {
        const { cardTypes } = this.context;
        let t = this;
        if (!cardTypes || cardTypes.length === 0 || fetch === true) {
            return CreditCardService.getAllCreditCardTypes()
                .then((res) => {
                    let arr = [],
                        d = this.state.data;

                    res.forEach((cc,index) => {
                        arr.push({
                            id: cc.id,
                            name: cc.name,
                            edit: t.renderEditButton(cc, index),
                            remove: t.renderRemoveButton(cc),
                        });
                    });

                    d.rows = arr;

                    t.setState({
                        data: d,
                        isLoaded: true,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else{
            let arr = [],
                d = this.state.data;

            cardTypes.forEach((cc, index) => {
                arr.push({
                    id: cc.id,
                    name: cc.name,
                    edit: t.renderEditButton(cc, index),
                    remove: t.renderRemoveButton(cc),
                });
            });

            d.rows = arr;

            t.setState({
                data: d,
                isLoaded: true,
            });
        }
    }

    renderEditButton(cc, index) {
        return (
            <MDBIcon icon="edit"
						 onClick={() => this.handleEditClick(cc, index)}
						 style={{color: "#7ac5ff"}}
            />
        );
    }

    renderRemoveButton(cc) {
        return (
            <MDBIcon icon="trash"
						 onClick={() => this.handleRemoveClick(cc)}
						 style={{color: "#db0404"}}
            />
        );
    }

    handleConfirmationDelete(id) {
        CreditCardService.deleteCreditCardType(id)
            .then((res) => {
                toast.success("Credit Card Type Removed");
                this.handleConfirmationClose();
                this.retrieveCCType(true);
            })
            .catch((err) => {
                toast.warn("Error occurred while removing Credit Card Type");
            });
    }

    handleRemoveClick(cc) {
        let i = {
            id: cc.id,
            name: cc.name,
        };
        this.setState({
            confirmationModal: !this.state.confirmationModal,
            currCC: i,
        });
    }

    handleEditClick(cc, index) {
        let a = {
            id: cc.id,
            name: cc.name,
        };
        this.setState({
            currCC: a,
            editModal: !this.state.editModal,
            currIndex: index,
        });
    }

    editToggle = () => {
        this.setState({
            editModal: !this.state.editModal,
            errorMessage: "",
        });
    };

    confirmationToggle = () => {
        this.setState({
            confirmationModal: !this.state.confirmationModal,
            errorMessage: "",
        });
    };

    handleConfirmationClose= () => {
        let i = {
            id: -1,
            name: "",
        };
        this.setState({
            currCC: i,
            currIndex: -1,
            errorMessage: "",
            confirmationModal: !this.state.confirmationModal,
        });
    };

    renderConfirmationModal() {
        return (
            <Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
                <ModalHeader
                    style={{ backgroundColor: "#F93154", color: "white" }}
                    toggle={this.confirmationToggle}
                >
					Confirm Delete
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderConfirmationModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Confirm Delete"}
                        onClick={() => this.handleConfirmationDelete(this.state.currCC.id)}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleConfirmationClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderConfirmationModalBody() {
        return (
            <div>
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <MDBContainer>
                    <MDBRow>
						Are you sure you want to delete {this.state.currCC.name}?
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }

    renderAddNewModal() {
        return (
            <Modal isOpen={this.state.addNewModal} toggle={this.handleAddNewClose}>
                <ModalHeader
                    style={{ backgroundColor: "#5881C1", color: "white" }}
                    toggle={this.handleAddNewClose}
                >
					Add New Credit Card Type
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderAddNewModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Add New Credit Card Type"}
                        onClick={this.addNewCC}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleAddNewClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderAddNewModalBody() {
        if (this.state.addNewModal) {
            return (
                <div>
                    <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                    <Input
                        outline
                        label="Credit Card Type"
                        onChange={this.handleAddNewChange.bind(this)}
                    />
                </div>
            );
        }
    }

    renderEditModal() {
        return (
            <Modal isOpen={this.state.editModal} toggle={this.editToggle}>
                <ModalHeader
                    style={{ backgroundColor: "#5881C1", color: "white" }}
                    toggle={this.editToggle}
                >
					Edit Credit Card Type
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    <div>
                        <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                        <Input
                            outline
                            label="Name"
                            value={this.state.currCC.name}
                            onChange={this.handleEditChange.bind(this)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Update Credit Card Type"}
                        onClick={this.handleEditSave}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleEditClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    validateAddNew(){
        let a = this.state.newCC;
        if (a.name === "") {
            this.setError("Please add a Credit Card Type");
            return false;
        }
        return true;
    }

    addNewCC = () => {
        if(this.validateAddNew()){

            let obj = this.state.newCC;
            return CreditCardService.createCreditCardType(obj)
                .then((res) => {
                    this.setState({
                        errorMessage: "",
                        name: "",
                    });
                    this.handleAddNewClose();
                    this.retrieveCCType(true);
                    toast.success(obj.name + " Credit Card Type has been Added!");
                })
                .catch((err) => {
                    toast.warn("An error occurred adding a new Credit Card Type");
                });
        }
    };

    handleAddNewChange(e) {
        let i = this.state.newCC;
        i.name = e.target.value;
        this.setState({
            newCC: i,
        }
        );
    }

    handleAddNewClose = () => {
        this.setState({
            newCC:{
                name: "",
            },
            addNewModal: !this.state.addNewModal,
            errorMessage: "",
        });
    };

    handleEditSave = () => {
        if (this.validateEdit()) {
            let d = this.state.data,
                i = this.state.currIndex,
                t = this;
            return CreditCardService.updateCreditCardType(this.state.currCC)
                .then((cc) => {
                    d.rows[i].name = cc.name;
                    d.rows[i].edit = t.renderEditButton(cc, i);
                    this.setState({
                        data: d,
                        editModal: !this.state.editModal,
                        errorMessage: "",
                    });
                    toast.success("Credit Card Type Edited!");
                })
                .catch((err) => {
                    toast.warn("An error occurred while saving.");
                });
        }
    };

    validateEdit() {
        let a = this.state.currCC;
        if (a.name === "") {
            this.setError("Please add a Credit Card Name");
            return false;
        }
        return true;
    }

    setError(e) {
        this.setState({
            errorMessage: e,
        });
    }

    handleEditClose = () => {
        this.setState({
            currIndex: -1,
            errorMessage: "",
            editModal: !this.state.editModal,
            currCC: {},
        });
    };

    handleEditChange(e) {
        let c = this.state.currCC;
        c.name = e.target.value;
        this.setState({
            currCC: c,
        });
    }

    renderTable() {
        if (this.state.isLoaded === true) {
            return (
                <MDBTable
                    small
                    striped
                    style={{ textAlign: "center" }}>
                    <MDBTableHead columns={this.state.data.columns} />
                    <MDBTableBody rows={this.state.data.rows} />
                </MDBTable>
            );
        } else {
            return <div> </div>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    addNewClick = () => {
        this.setState({
            addNewModal: true
        });
    };

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Card>
                    <CardHeader
                        style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30}}
                    >
						Credit Card Types
                        <Button
                            style={{ float: "right" }}
                            floating
                            size="sm"
                            color={"secondary"}
                            data-tip={"Add New Credit Card Type"}
                            onClick={this.addNewClick}
                        >
                            <MDBIcon icon="credit-card" style={{ fontSize: "2em" }} />
                        </Button>
                    </CardHeader>
                    <CardBody>{this.renderTable()}</CardBody>
                    <ReactTooltip />
                </Card>
                {this.renderEditModal()}
                {this.renderConfirmationModal()}
                {this.renderAddNewModal()}
            </Container>
        );
    }
}
