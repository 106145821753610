import React from "react";
import Card from "../../../Card";
import PropTypes from "prop-types";
import MUIGrid from "@mui/material/Grid";
import MUISwitch from "@mui/material/Switch";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
export default class CardAdminIssueReasonBehavior extends Card {
    renderContent() {
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} md={3}>
                        <MUIFormControlLabel
                            control={<MUISwitch/>}
                            onChange={(e) => this.props.onChange({ showAlert: e.target.checked })}
                            label="Show Alert"
                            checked={this.props.issueReason ? this.props.issueReason.showAlert : false}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} md={3}>
                        <MUIFormControlLabel
                            control={<MUISwitch/>}
                            onChange={(e) => this.props.onChange({ preventService: e.target.checked })}
                            label="Prevent Service"
                            checked={this.props.issueReason ? this.props.issueReason.preventService : false}
                        />
                    </MUIGrid>
                </MUIGrid>
            </>
        );
    }
    /**
     * @returns {string} The title of the card.
     */
     getTitle() {
        return "Behavior";
    }
    
}