import Crud from "../Crud";
import MUICyanColor from "@mui/material/colors/cyan";

/**
 * Static helper functions related to UserProfileLocations.
 */
export default class UserProfileLocation extends Crud {

    /**
     * @param {object} userProfileLocation The userProfileLocation object.
     * @returns The display name of the userProfileLocation.
     */
    static getDisplayName(userProfileLocation) {
        return userProfileLocation.userprofileId;
    }

    /**
     * Determines if a userProfileLocation matches a search query.
     *
     * @param {object} userProfileLocation The userProfileLocation to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the userProfileLocation matches the query.
     */
    static match(userProfileLocation, query) {
        return (
            userProfileLocation.userprofileId === +query
        );
    }

    /**
     * @param {array} userProfileLocations The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(userProfileLocations) {
        return userProfileLocations.sort((a, b) => {
            return (a.userprofileId).localeCompare(b.userprofileId);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUICyanColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Badge";
    }
}