import api from "../api";

class TeamQueueService {
    async getAllTeamQueues() {
        return new Promise((res, reject) => {
            api
                .get("/allTeamQueues")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getTeamQueuesById(id) {
        return new Promise((res, reject) => {
            api
                .get("/teamQueues/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createTeamQueues(obj) {
        return new Promise((res, reject) => {
            api
                .post("/teamQueues", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateTeamQueues(obj) {
        return new Promise((res, reject) => {
            api
                .put("/teamQueues", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteTeamQueues(id) {
        return new Promise((res, rej) => {
            return api.delete('/teamQueues/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const teamQueueService = new TeamQueueService();

export default teamQueueService;
