import CardQueueDataGrid from "../CardQueueDataGrid";
import DialogUpdateInvoice from "../../../Dialog/DialogUpdateInvoice";
import NoteService from "../../../../Seating/Security/NoteService/noteService";
import OrderService from "../../../../Seating/Security/OrderService/orderService";
import api from "../../../../Seating/Security/api";
import moment from "moment";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class CardQueueDataGridBrightreeInvoice extends CardQueueDataGrid {

    columns = {
        "brightree_invoice.id": {
            type: "number",
            name: "Invoice #",
            width: 130,
        },
        "brightree_invoice.patient_name": {
            type: "brightreeInvoicePatientName",
            name: "Patient Name",
            width: 160,
        },
        "brightree_invoice.payor_name": {
            type: "brightreeInvoicePayorName",
            name: "Payor Name",
            width: 220,
        },
        "brightree_invoice.sales_order_id": {
            type: "number",
            name: "BT Order ID",
            width: 120,
        },
        "brightree_invoice.order_activity.id": {
            type: "number",
            name: "SP Order #",
            width: 120,
        },
        "brightree_invoice._accounts_receivable_bucket": {
            type: "string",
            name: "AR Age",
            width: 100,
        },
        "brightree_invoice.balance": {
            type: "currency",
            name: "Balance",
            width: 110,
            align: "right",
        },
        "out_of_bounds": {
            type: "boolean",
            name: "OOB",
            width: 80,
        },
        "brightree_invoice.worked_at": {
            type: "date",
            name: "Worked At",
            width: 140,
        },
        "brightree_invoice.brightree_invoice_status_term_id": {
            type: "term",
            name: "Status",
            width: 100,
        },
        "brightree_invoice.order_activity.patient_id": {
            type: "string",
            name: "Patient #",
            width: 120,
        },
        "brightree_invoice.order_activity.currently_being_worked_by_id": {
            type: "user",
            name: "Open By",
            width: 140,
        },
        "brightree_invoice.worked_by_userprofile_id": {
            type: "user",
            name: "Worked By",
            width: 140,
        },
        "brightree_invoice.invoice_followup_status_term_id": {
            type: "number",
            name: "Followup Status",
            width: 140,
        },
        "brightree_invoice._distribution_reason_codes": {
            type: "string",
            name: "Denial Codes",
            width: 140,
        },
        "brightree_invoice.order_activity.sales_location_id": {
            type: "location",
            name: "Sales Location",
            width: 140,
        },
        "brightree_invoice.branch_location.id": {
            type: "location",
            name: "Branch",
            width: 140,
        },
        "brightree_invoice.followup_date": {
            type: "date",
            name: "F/U Date",
            width: 110,
        },
        "brightree_invoice.followup_date_business": {
            type: "date",
            name: "F/U Date Business",
            width: 110,
        },
        "brightree_invoice._queue_age": {
            type: "ageDays",
            name: "Age",
            width: 70,
        },
        "brightree_invoice._queue_age_business": {
            type: "ageBusinessDays",
            name: "Age",
            width: 70,
        },
        "brightree_invoice.sales_order_classification": {
            type: "string",
            name: "Sales Order Classification",
            width: 200,
        },
        "brightree_invoice.payor_level": {
            type: "string",
            name: "Payor Level",
            width: 120,
        },
        "brightree_invoice.date_of_service": {
            type: "date",
            name: "DoS",
            width: 140,
        },
        "brightree_invoice.date_opened": {
            type: "date",
            name: "Date Opened",
            width: 140,
        },
        "brightree_invoice.created_at": {
            type: "date",
            name: "Created At",
            width: 140,
        },
        "brightree_invoice.key": {
            type: "number",
            name: "Key",
            width: 110,
        },
    };

    constructor(props) {
        super(props);

        this.state.dialogUpdateInvoiceOpen = false;
        this.state.invoices = [];
        this.state.matchingInvoicesOriginal = [];
        this.state.isUpdating = false;
    }


    /**
      * Handles the saving of updated invoice changes.
      */
    handleOnSaveInvoiceUpdateChanges(invoices, note) {
        this.setState({
            isUpdating: true,
        })

        const promises = [];

        if (invoices[0]["orderActivityId"] && note) {
            OrderService.getOrderActivity(invoices[0]["orderActivityId"])
                .then((orderActivity) => {
                    promises.push(
                        NoteService.createActivityNote(orderActivity.id, {
                            text: note,
                            accountId: orderActivity.activity.accountId,
                            completed: true,
                            task: false,
                            createdAt: new Date().toISOString(),
                            activityId: orderActivity.activity.id,
                            createdById: this.context.currentUser.id,
                        })
                    );
                })
                .catch((err) => {
                    console.error("Error fetching order activity:", err);
                });
        }

        let updatedInvoicesCount = 0;

        invoices.forEach((invoice) => {
            if (
                invoice.invoiceFollowupStatusTermId !== null
            ) {
                if (invoice.denials) {
                    invoice.status = "Pending";
                    invoice.brightreeInvoiceStatusTermId = 36;
                }

                invoice.followupDate = moment(new Date()).add(3, 'weeks').startOf('day').toISOString();
                invoice.workedByUserprofileId = this.context.currentUser.id;
                invoice.workedAt = new Date().toISOString();

                updatedInvoicesCount++;

                promises.push(api.send("POST", "/BrightreeInvoiceUpdate", invoice));
            }
        });

        // const userProductionTypeId = invoices[0]["brightreeInvoiceStatusTermId"] === 36 ? 15 :
        //                              invoices[0]["brightreeInvoiceStatusTermId"] === 39 ? 16 : null;

        // if (userProductionTypeId !== null) {
        //     promises.push(api.send("POST", "/userProduction/update", {
        //         "userProductionTypeId": userProductionTypeId,
        //         "delta": updatedInvoicesCount,
        //     }));
        // } else {
        //     console.error("Invalid brightreeInvoiceStatusTermId");
        // }

        promises.push(api.send("POST", "/userProduction/update", {
            "userProductionTypeId": 1,
            "delta": updatedInvoicesCount,
        }));

        Promise.all(promises).then(() => {
            NotistackEnqueueSnackbar("Invoice updated successfully!", { variant: "success" });
            this.setState({
                isUpdating: false,
                dialogUpdateInvoiceOpen: false,
                invoices: [],
                matchingInvoicesOriginal: [],
            })
            this.props.onReload();
        }).catch((err) => {
            NotistackEnqueueSnackbar("Error updating invoice", { variant: "error" });
            this.setState({
                isUpdating: false,
            })
        })
    }


    /**
     * Render the Update Invoice Dialog
     */
    renderInternal() {
        return (
            <DialogUpdateInvoice
                open={this.state.dialogUpdateInvoiceOpen}
                onClose={() => {
                    this.setState({
                        dialogUpdateInvoiceOpen: false,
                        invoices: [],
                        matchingInvoicesOriginal: [],
                    });
                }}
                onConfirm={(invoices, note) => this.handleOnSaveInvoiceUpdateChanges(invoices, note)}
                invoices={this.state.invoices}
                loading={this.state.isUpdating}
            />
        )
    }

    /**
     * Handle clicking on a grid row.
     */
    handleClickRow(row) {
        const matchingInvoices = this.props.rows.filter(({ "brightree_invoice.sales_order_id": salesOrderId }) =>
            salesOrderId === row["brightree_invoice.sales_order_id"]
        );

        const promises = matchingInvoices.map(invoice =>
            api.send("POST", "/GetBrightreeInvoice", invoice["brightree_invoice.id"])
        );

        Promise.all(promises).then((invoices) => {
            invoices.map((invoice) => invoice.invoiceFollowupStatusTermId = null)
            const brightreeInvoiceIds = invoices.map((invoice) => invoice.id)
            api.send("POST", "/BrightreeDistributionByIds", brightreeInvoiceIds)
                .then((denials) => {
                    if (denials && denials.length > 0) {
                        invoices.forEach((invoice) => {
                            if (!invoice.denials) {
                                invoice.denials = [];
                            }

                            denials.forEach((denial) => {
                                if (invoice.id === denial.brightreeInvoiceId) {
                                    invoice.denials.push(denial);
                                }
                            });
                        });

                        this.setState({
                            invoices: invoices,
                        });
                    } else {
                        this.setState({
                            invoices: invoices
                        });
                    }
                }).catch((err) => {
                    console.error("Error fetching denials:", err)
                })

            this.setState({
                matchingInvoicesOriginal: matchingInvoices,
                dialogUpdateInvoiceOpen: true,
            });
        }).catch((err) => {
            console.error("Error fetching brightree invoices:", err);
        })
    }

    /**
     * Get any applicable group summary items for the data grid. This is used to
     * add custom values to the grouping rows.
     */
    getGroupSummaryItems() {
        return [{
            columnName: 'brightree_invoice.balance',
            type: 'sum',
            showInGroupFooter: false,
            alignByColumn: true
        }];
    }

}