import Admin from "../Admin";
import React from "react";
import CardAdminMarketingCampaignCategoryGeneral from "../../../Component/Card/Admin/MarketingCampaignCategory/CardAdminMarketingCampaignCategoryGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MarketingCampaignCategoryService from "../../../Seating/Security/MarketingCampaignCategoryService/marketingCampaignCategoryService";
import Validator from "../../../Utility/Validator";
import MarketingCampaignCategory from "../../../Utility/Crud/MarketingCampaignCategory";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminMarketingCampaignCategoryCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.marketingCampaignCategory = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Set the marketingCampaignCategory in the state.
     */
    afterComponentDidMount() {
        const marketingCampaignCategoryId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (marketingCampaignCategoryId !== null) {
            this.setState({
                marketingCampaignCategory: this.context.marketingCampaignCategoriesIndexed[marketingCampaignCategoryId]
            });
        }
    }

    /**
     * Creates the marketingCampaignCategory with the current properties in state.marketingCampaignCategory.
     */
    async createMarketingCampaignCategory() {
        this.setState({
            isProcessing: true,
        });

        return MarketingCampaignCategoryService.createMarketingCampaignCategory(this.state.marketingCampaignCategory)
            .then(marketingCampaignCategory => {
                this.context.updateIndexed("marketingCampaignCategoriesIndexed", marketingCampaignCategory);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Marketing Campaign Category created successfully!", { variant: "success" });
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Marketing Campaign Category.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the marketingCampaignCategory with the current properties in state.marketingCampaignCategory.
     */
    async updateMarketingCampaignCategory() {
        this.setState({
            isProcessing: true,
        });

        return MarketingCampaignCategoryService.updateMarketingCampaignCategory(this.state.marketingCampaignCategory)
            .then(marketingCampaignCategory => {
                this.context.updateIndexed("marketingCampaignCategoriesIndexed", marketingCampaignCategory);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Marketing Campaign Category updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Marketing Campaign Category.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    async deleteMarketingCampaignCategory() {
        this.setState({
            isProcessing: true,
        });

        return MarketingCampaignCategoryService.deleteMarketingCampaignCategory(this.state.marketingCampaignCategory.id)
            .then(() => {
                this.context.deleteIndexed("marketingCampaignCategoriesIndexed", this.state.marketingCampaignCategory.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Marketing Campaign Category deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Marketing Campaign Category.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (marketingCampaignCategory) => {
            this.setState({
                marketingCampaignCategory: {
                    ...this.state.marketingCampaignCategory,
                    ...marketingCampaignCategory
                },
            });
        };

        return (
            <>
                <CardAdminMarketingCampaignCategoryGeneral
                    marketingCampaignCategory={this.state.marketingCampaignCategory}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteMarketingCampaignCategory()}
                    text="Are you sure you want to delete this Marketing Campaign Category? This cannot be undone."
                    header="Delete Marketing Campaign Category"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Renders the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = "Create Marketing Campaign Category";
            subtitle = null;
        } else {
            title = "Edit Marketing Campaign Category";
            subtitle = (this.state.marketingCampaignCategory && (MarketingCampaignCategory.getDisplayName(this.state.marketingCampaignCategory) + " • " + this.state.marketingCampaignCategory.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.marketingCampaignCategory,
                {
                    name: {
                        'required': true
                    },
                    description: {
                        'required': true
                    }
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });


            if (Object.keys(validationIssues).length > 0) {
                return NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateMarketingCampaignCategory();
                } else {
                    this.createMarketingCampaignCategory();
                }
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
