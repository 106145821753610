import api from "../Security/api";

class TrainingLinkService {
    async getAllTrainingLinks() {
        return new Promise((res, reject) => {
            api
                .get("/trainingLink")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateTrainingLink(reason) {
        return new Promise((res, reject) => {
            api
                .put("/trainingLink", reason)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createTrainingLink(reason) {
        return new Promise((res, reject) => {
            api
                .post("/trainingLink", reason)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteTrainingLink(id) {
        return new Promise((res, reject) => {
            api
                .delete("/trainingLink/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const trainingLinkService = new TrainingLinkService();

export default trainingLinkService;
