import api from "../api";

class TermService {
    async getAllTerms() {
        return new Promise((res, reject) => {
            api
                .get("/allTerms")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getTermById(id) {
        return new Promise((res, reject) => {
            api
                .get("/terms/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createTerm(obj) {
        return new Promise((res, reject) => {
            api
                .post("/terms", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateTerm(obj) {
        return new Promise((res, reject) => {
            api
                .put("/terms", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteTerm(id) {
        return new Promise((res, rej) => {
            return api.delete('/terms/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const termService = new TermService();

export default termService;
