import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import InsuranceSubType from "../../../../Utility/Crud/InsuranceSubType";

export default withRouter(class CardAdminInsuranceSubTypeList extends Card {
    static contextTypes = {
        insuranceSubTypesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                    { key: "insuranceTypeId", name: "Payor Source", type: "insuranceType" },
                    { key: "insuranceSubTypeGroupTermId", name: "Group", type: "term" },
                    { key: "pvPayorId", name: "Payor ID", type: "number" },
                    { key: "brightreeExternalId", name: "Brightree ID", type: "number" },
                ]}
                rows={InsuranceSubType.sort(Object.values(this.context.insuranceSubTypesIndexed))}
                onClick={(insuranceSubType) => {
                    this.props.history.push({
                        pathname: "/admin/insuranceSubType/" + insuranceSubType.id
                    });
                }}
            />
        );
    }
});