import React from "react";
import {Route, Switch} from "react-router-dom";

import PatientOrderView from "./Seating/Patient/patientOrderView";
import PatientInformationView from "./Seating/Patient/patientInformationView";
import UserList from "./Seating/Users/userList";
import UserEdit from "./Seating/Users/userEdit";
import UserAdd from "./Seating/Users/userAdd";

// Login/Authorize/Load
import Login from "./Layer/Login";
import Authorize from "./Layer/Authorize";
import Load from "./Layer/Load";

// Dictionary Admin Layers
import AdminDepartmentCreateEdit from "./Layer/Admin/Department/AdminDepartmentCreateEdit";
import AdminDepartmentList from "./Layer/Admin/Department/AdminDepartmentList";
import AdminOrderActivityTypeCreateEdit from "./Layer/Admin/OrderActivityType/AdminOrderActivityTypeCreateEdit";
import AdminOrderActivityTypeList from "./Layer/Admin/OrderActivityType/AdminOrderActivityTypeList";
import AdminQueueCreateEdit from "./Layer/Admin/Queue/AdminQueueCreateEdit";
import AdminQueueList from "./Layer/Admin/Queue/AdminQueueList";
import AdminLocationList from "./Layer/Admin/Location/AdminLocationList";
import AdminLocationCreateEdit from "./Layer/Admin/Location/AdminLocationCreateEdit";
import AdminRegionCreateEdit from "./Layer/Admin/Region/AdminRegionCreateEdit";
import AdminRegionList from "./Layer/Admin/Region/AdminRegionList";
import AdminQueueRouteCreateEdit from "./Layer/Admin/QueueRoute/AdminQueueRouteCreateEdit";
import AdminQueueRouteList from "./Layer/Admin/QueueRoute/AdminQueueRouteList";
import AdminTeamCreateEdit from "./Layer/Admin/Team/AdminTeamCreateEdit";
import AdminTeamList from "./Layer/Admin/Team/AdminTeamList";
import AdminBaseUnitTypeCreateEdit from "./Layer/Admin/BaseUnitType/AdminBaseUnitTypeCreateEdit";
import AdminBaseUnitTypeList from "./Layer/Admin/BaseUnitType/AdminBaseUnitTypeList";
import AdminBaseUnitCreateEdit from "./Layer/Admin/BaseUnit/AdminBaseUnitCreateEdit";
import AdminBaseUnitList from "./Layer/Admin/BaseUnit/AdminBaseUnitList";
import AdminTermCreateEditQueueType from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditQueueType";
import AdminTermListQueueType from "./Layer/Admin/Term/TermList/AdminTermListQueueType";
import AdminTermCreateEditRegionType from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditRegionType";
import AdminTermListRegionType from "./Layer/Admin/Term/TermList/AdminTermListRegionType";
import AdminTermListInsuranceSubTypeGroup from "./Layer/Admin/Term/TermList/AdminTermListInsuranceSubTypeGroup";
import AdminTermCreateEditInsuranceSubTypeGroup from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditInsuranceSubTypeGroup";
import AdminTermListServicePartCatalogCategory from "./Layer/Admin/Term/TermList/AdminTermListServicePartCatalogCategory";
import AdminTermCreateEditServicePartCatalogCategory from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditServicePartCatalogCategory";
import AdminTermListBrightreeInvoiceStatus from "./Layer/Admin/Term/TermList/AdminTermListBrightreeInvoiceStatus";
import AdminTermCreateEditBrightreeInvoiceStatus from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditBrightreeInvoiceStatus";
import AdminTermListInvoiceFollowupStatus from "./Layer/Admin/Term/TermList/AdminTermListInvoiceFollowupStatus";
import AdminTermCreateEditInvoiceFollowupStatus from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditInvoiceFollowupStatus";
import AdminTermListDistributionType from "./Layer/Admin/Term/TermList/AdminTermListDistributionType";
import AdminTermCreateEditDistributionType from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditDistributionType";
import AdminUserProductionTypeList from "./Layer/Admin/UserProductionType/AdminUserProductionTypeList";
import AdminUserProductionTypeCreateEdit from "./Layer/Admin/UserProductionType/AdminUserProductionTypeCreateEdit";
import AdminBtProductList from "./Layer/Admin/BtProduct/AdminBtProductList";
import AdminBtProductCreateEdit from "./Layer/Admin/BtProduct/AdminBtProductCreateEdit";
import AdminSalesPcrLinkList from "./Layer/Admin/SalesPcrLink/AdminSalesPcrLinkList";
import AdminSalesPcrLinkCreateEdit from "./Layer/Admin/SalesPcrLink/AdminSalesPcrLinkCreateEdit";
import AdminOrderStatusReasonList from "./Layer/Admin/OrderStatusReason/AdminOrderStatusReasonList";
import AdminOrderStatusReasonCreateEdit from "./Layer/Admin/OrderStatusReason/AdminOrderStatusReasonCreateEdit";

// Queues
import Queue from "./Layer/Queue/Queue";
import QueueDashboard from "./Layer/Queue/QueueDashboard";

// Chart
import ChartPatientCreateEdit from "./Layer/Chart/Patient/ChartPatientCreateEdit";

import ThirdPartyUserList from "./Seating/Users/thirdPartyUserList";
import OrderList from "./Seating/Orders/orderList";
import PatientList from "./Seating/Patient/patientList";
import RecurringOrders from "./Seating/Orders/RecurringOrders";
import DeliveryManifest from "./Seating/Patient/deliveryManifest";
import MapComponentWindow from "./Seating/Deliveries/mapComponentWindow";
import PurchaseQueueView from "./Seating/PurchaseQueue/purchaseQueueView";
import InventoryRequest from "./Seating/PurchaseQueue/inventoryRequest";
import StateSalesTax from "./Seating/Settings/stateSalesTax";
import DailyOrderCounts from "./Seating/Reports/dailyOrderCounts";
import ReverseQualityReasons from "./Seating/Settings/reverseQualityReasons";
import Faxes from "./Seating/Faxes/faxes";
import InventoryTacking from "./Seating/Deliveries/inventoryTracking";
import PatientApprovals from "./Seating/Settings/patientApprovals";
import CollectionsView from "./Seating/Orders/collectionsView";
//account files
import AccountList from "./Seating/Accounts/accountList";
import AccountEdit from "./Seating/Accounts/accountEdit";
import AccountAdd from "./Seating/Accounts/accountAdd";
import AccountDetail from "./Seating/Accounts/accountDetail";
//activity files
import ActivityList from "./Seating/Activities/activities-list";
import ActivityEdit from "./Seating/Activities/activities-edit";
import ActivityAdd from "./Seating/Activities/activities-add";
//settings files
import ProductTypes from "./Seating/Settings/productTypes";
import EditProductSubType from "./Seating/Settings/editProductSubTypes";
import AddProductSubType from "./Seating/Settings/addProductSubType";
import InsuranceSubTypeDetails from "./Seating/Settings/insuranceSubTypeDetails";
import ProductSize from "./Seating/Settings/productSize";
import AddProductSize from "./Seating/Settings/addProductSize";
import NotificationTypes from "./Seating/Settings/notificationTypes";
import CreditCardTypes from "./Seating/Settings/creditCardTypes";
import ExpenseTypes from "./Seating/Settings/expenseTypes";
import DeliveryAcknowledgements from "./Seating/Settings/deliveryAcknowledgements";
import AddDeliveryAcknowledgement from "./Seating/Settings/addDeliveryAcknowledgement";
import Reasons from "./Seating/Settings/reasons";
import DeliveryList from "./Seating/Deliveries/deliveryList";

import ATPView from "./Seating/Orders/ATPView";
import ManualDocBuilder from "./Seating/Settings/manualDocumentBuilder";
import Vendor from "./Seating/Settings/vendor";
import ProductMatrixEntry from "./Seating/Settings/productMatrixEntry";
import InventoryProductView from "./Seating/InventoryProduct/inventoryProductView";
//report files
import Reports from "./Seating/Reports/reports";
import BonusReport from "./Seating/Reports/bonusReport";
import SalesPipelineGraphView from "./Seating/Reports/salesPipelineGraphView";
import MonthGlanceView from "./Components/Pipeline/MonthGlance";

//Task files
import Tasks from "./Seating/Tasks/Tasks";

//report files
import Expenses from "./Seating/Expenses/expenses-list";
import ExpenseEdit from "./Seating/Expenses/expenses-edit";

//contact files
import Contacts from "../src/Seating/Contacts/contact-list";
import ContactEdit from "../src/Seating/Contacts/contact-edit";

// presidents club
import PresidentsClubView from "./Seating/PresidentsClub/presidentsClubView";

// sales liaison meetings
import LiaisonMeetingView from "./Seating/LiaisonMeetings/liaisonMeetingView";

import TrainingLink from "./Seating/Settings/trainingLink";
import ShippingDetailsReport from "./Seating/PurchaseQueue/ShippingDetailsReport";
import ServicePartList from "./Seating/PurchaseQueue/ServicePartList";
import AdminMarketingCampaignList from "./Layer/Admin/MarketingCampaign/AdminMarketingCampaignList";
import AdminMarketingCampaignCreateEdit from "./Layer/Admin/MarketingCampaign/AdminMarketingCampaignCreateEdit";
import AdminMarketingCampaignCategoryList from "./Layer/Admin/MarketingCampaignCategory/AdminMarketingCampaignCategoryList";
import AdminMarketingCampaignCategoryCreateEdit from "./Layer/Admin/MarketingCampaignCategory/AdminMarketingCampaignCategoryCreateEdit";
import AdminIssueCategoryList from "./Layer/Admin/IssueCategory/AdminIssueCategoryList";
import AdminIssueCategoryCreateEdit from "./Layer/Admin/IssueCategory/AdminIssueCategoryCreateEdit";
import AdminIssueReasonList from "./Layer/Admin/IssueReason/AdminIssueReasonList";
import AdminIssueReasonCreateEdit from "./Layer/Admin/IssueReason/AdminIssueReasonCreateEdit";
import AdminInsuranceTypeList from "./Layer/Admin/InsuranceType/AdminInsuranceTypeList";
import AdminInsuranceTypeCreateEdit from "./Layer/Admin/InsuranceType/AdminInsuranceTypeCreateEdit";
import AdminInsuranceSubTypeList from "./Layer/Admin/InsuranceSubType/AdminInsuranceSubTypeList";
import AdminInsuranceSubTypeCreateEdit from "./Layer/Admin/InsuranceSubType/AdminInsuranceSubTypeCreateEdit";
import AdminAccountTypeList from "./Layer/Admin/AccountType/AdminAccountTypeList";
import AdminAccountTypeCreateEdit from "./Layer/Admin/AccountType/AdminAccountTypeCreateEdit";
import AdminContactTypeList from "./Layer/Admin/ContactType/AdminContactTypeList";
import AdminContactTypeCreateEdit from "./Layer/Admin/ContactType/AdminContactTypeCreateEdit";
import AdminDocumentTypeList from "./Layer/Admin/DocumentType/AdminDocumentTypeList";
import AdminDocumentTypeCreateEdit from "./Layer/Admin/DocumentType/AdminDocumentTypeCreateEdit";
import AdminDistributionReasonList from "./Layer/Admin/DistributionReason/AdminDistributionReasonList";
import AdminDistributionReasonCreateEdit from "./Layer/Admin/DistributionReason/AdminDistributionCreateEdit";
import AdminHcpcsCodeList from "./Layer/Admin/HcpcsCode/AdminHcpcsCodeList";
import AdminHcpcsCodeCreateEdit from "./Layer/Admin/HcpcsCode/AdminHcpcsCodeCreateEdit";
import Logout from "./Layer/Logout";
import PatientCollections from "./Layer/Operations/PatientCollections/PatientCollections";
import ReportMetric from "./Layer/Report/Metric/ReportMetric";
import ARDashboard from "./Layer/Report/AR/ARDashboard";
import ReportReverseQuality from "./Layer/Report/ReverseQuality/ReportReverseQuality";
import AdminTermListMarketingPreferredContactMethod from "./Layer/Admin/Term/TermList/AdminTermListMarketingPreferredContactMethod";
import AdminTermCreateEditMarketingPreferredContactMethod from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditMarketingPreferredContactMethod";
import AdminTermListPlaceOfService from "./Layer/Admin/Term/TermList/AdminTermListPlaceOfService";
import AdminTermCreateEditPlaceOfService from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditPlaceOfService";
import AdminTermCreateEditCommissionTier from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditCommissionTier";
import AdminTermListCommissionTier from "./Layer/Admin/Term/TermList/AdminTermListCommissionTier";

class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/authorize" component={Authorize} />
                <Route exact path="/load" component={Load} />
                <Route exact path="/dashboard" component={QueueDashboard} />
                <Route exact path="/order/:id" component={PatientOrderView} />
                <Route
                    exact
                    path="/patientInformation/:id"
                    component={PatientInformationView}
                />
                <Route
                    exact
                    path="/patientInformation/:id/:orderId"
                    component={PatientInformationView}
                />
                <Route exact path="/userList" component={UserList} />
                <Route exact path="/user/:id" component={UserEdit} />

                {/* Admin */}
                <Route exact path="/admin/queueTypes" component={AdminTermListQueueType} />
                <Route exact path="/admin/queueType/:id" component={AdminTermCreateEditQueueType} />
                <Route exact path="/admin/queueType" component={AdminTermCreateEditQueueType} />

                <Route exact path="/admin/departments" component={AdminDepartmentList} />
                <Route exact path="/admin/department/:id" component={AdminDepartmentCreateEdit} />
                <Route exact path="/admin/department" component={AdminDepartmentCreateEdit} />

                <Route exact path="/admin/teams" component={AdminTeamList} />
                <Route exact path="/admin/team" component={AdminTeamCreateEdit} />
                <Route exact path="/admin/team/:id" component={AdminTeamCreateEdit} />

                <Route exact path="/admin/orderActivityTypes" component={AdminOrderActivityTypeList} />
                <Route exact path="/admin/orderActivityType" component={AdminOrderActivityTypeCreateEdit} />
                <Route exact path="/admin/orderActivityType/:id" component={AdminOrderActivityTypeCreateEdit} />

                <Route exact path="/admin/queues" component={AdminQueueList} />
                <Route exact path="/admin/queue" component={AdminQueueCreateEdit} />
                <Route exact path="/admin/queue/:id" component={AdminQueueCreateEdit} />

                <Route exact path="/admin/queueRoutes" component={AdminQueueRouteList} />
                <Route exact path="/admin/queueRoute" component={AdminQueueRouteCreateEdit} />
                <Route exact path="/admin/queueRoute/:id" component={AdminQueueRouteCreateEdit} />

                <Route exact path="/admin/baseUnits" component={AdminBaseUnitList} />
                <Route exact path="/admin/baseUnit" component={AdminBaseUnitCreateEdit} />
                <Route exact path="/admin/baseUnit/:id" component={AdminBaseUnitCreateEdit} />

                <Route exact path="/admin/baseUnitTypes" component={AdminBaseUnitTypeList} />
                <Route exact path="/admin/baseUnitType" component={AdminBaseUnitTypeCreateEdit} />
                <Route exact path="/admin/baseUnitType/:id" component={AdminBaseUnitTypeCreateEdit} />

                <Route exact path="/admin/insuranceSubTypeGroups" component={AdminTermListInsuranceSubTypeGroup} />
                <Route exact path="/admin/insuranceSubTypeGroup/:id" component={AdminTermCreateEditInsuranceSubTypeGroup} />
                <Route exact path="/admin/insuranceSubTypeGroup" component={AdminTermCreateEditInsuranceSubTypeGroup} />

                <Route exact path="/admin/regionTypes" component={AdminTermListRegionType} />
                <Route exact path="/admin/regionType/:id" component={AdminTermCreateEditRegionType} />
                <Route exact path="/admin/regionType" component={AdminTermCreateEditRegionType} />

                <Route exact path="/admin/btProducts" component={AdminBtProductList} />
                <Route exact path="/admin/btProduct" component={AdminBtProductCreateEdit} />
                <Route exact path="/admin/btProduct/:id" component={AdminBtProductCreateEdit} />

                <Route exact path="/admin/locations" component={AdminLocationList} />
                <Route exact path="/admin/location" component={AdminLocationCreateEdit} />
                <Route exact path="/admin/location/:id" component={AdminLocationCreateEdit} />

                <Route exact path="/admin/regions" component={AdminRegionList} />
                <Route exact path="/admin/region" component={AdminRegionCreateEdit} />
                <Route exact path="/admin/region/:id" component={AdminRegionCreateEdit} />

                <Route exact path="/admin/marketingCampaigns" component={AdminMarketingCampaignList} />
                <Route exact path="/admin/marketingCampaign" component={AdminMarketingCampaignCreateEdit} />
                <Route exact path="/admin/marketingCampaign/:id" component={AdminMarketingCampaignCreateEdit} />

                <Route exact path="/admin/marketingCampaignCategories" component={AdminMarketingCampaignCategoryList} />
                <Route exact path="/admin/marketingCampaignCategory" component={AdminMarketingCampaignCategoryCreateEdit} />
                <Route exact path="/admin/marketingCampaignCategory/:id" component={AdminMarketingCampaignCategoryCreateEdit} />

                <Route exact path="/admin/marketingPreferredContactMethods" component={AdminTermListMarketingPreferredContactMethod} />
                <Route exact path="/admin/marketingPreferredContactMethod" component={AdminTermCreateEditMarketingPreferredContactMethod} />
                <Route exact path="/admin/marketingPreferredContactMethod/:id" component={AdminTermCreateEditMarketingPreferredContactMethod} />
                
                <Route exact path="/admin/commissionTiers" component={AdminTermListCommissionTier} />
                <Route exact path="/admin/commissionTier" component={AdminTermCreateEditCommissionTier} />
                <Route exact path="/admin/commissionTier/:id" component={AdminTermCreateEditCommissionTier} />

                <Route exact path="/admin/placeOfServices" component={AdminTermListPlaceOfService} />
                <Route exact path="/admin/placeOfService" component={AdminTermCreateEditPlaceOfService} />
                <Route exact path="/admin/placeOfService/:id" component={AdminTermCreateEditPlaceOfService} />

                <Route exact path="/admin/issueCategories" component={AdminIssueCategoryList} />
                <Route exact path="/admin/issueCategory" component={AdminIssueCategoryCreateEdit} />
                <Route exact path="/admin/issueCategory/:id" component={AdminIssueCategoryCreateEdit} />

                <Route exact path="/admin/issueReasons" component={AdminIssueReasonList} />
                <Route exact path="/admin/issueReason" component={AdminIssueReasonCreateEdit} />
                <Route exact path="/admin/issueReason/:id" component={AdminIssueReasonCreateEdit} />

                <Route exact path="/admin/insuranceTypes" component={AdminInsuranceTypeList} />
                <Route exact path="/admin/insuranceType" component={AdminInsuranceTypeCreateEdit} />
                <Route exact path="/admin/insuranceType/:id" component={AdminInsuranceTypeCreateEdit} />

                <Route exact path="/admin/insuranceSubTypes" component={AdminInsuranceSubTypeList} />
                <Route exact path="/admin/insuranceSubType" component={AdminInsuranceSubTypeCreateEdit} />
                <Route exact path="/admin/insuranceSubType/:id" component={AdminInsuranceSubTypeCreateEdit} />

                <Route exact path="/admin/accountTypes" component={AdminAccountTypeList} />
                <Route exact path="/admin/accountType" component={AdminAccountTypeCreateEdit} />
                <Route exact path="/admin/accountType/:id" component={AdminAccountTypeCreateEdit} />

                <Route exact path="/admin/contactTypes" component={AdminContactTypeList} />
                <Route exact path="/admin/contactType" component={AdminContactTypeCreateEdit} />
                <Route exact path="/admin/contactType/:id" component={AdminContactTypeCreateEdit} />

                <Route exact path="/admin/documentTypes" component={AdminDocumentTypeList} />
                <Route exact path="/admin/documentType" component={AdminDocumentTypeCreateEdit} />
                <Route exact path="/admin/documentType/:id" component={AdminDocumentTypeCreateEdit} />

                <Route exact path="/admin/orderStatusReasons" component={AdminOrderStatusReasonList} />
                <Route exact path="/admin/orderStatusReason" component={AdminOrderStatusReasonCreateEdit} />
                <Route exact path="/admin/orderStatusReason/:id" component={AdminOrderStatusReasonCreateEdit} />

                <Route exact path="/admin/servicePartCatalogCategories" component={AdminTermListServicePartCatalogCategory} />
                <Route exact path="/admin/servicePartCatalogCategory" component={AdminTermCreateEditServicePartCatalogCategory} />
                <Route exact path="/admin/servicePartCatalogCategory/:id" component={AdminTermCreateEditServicePartCatalogCategory} />

                <Route exact path="/admin/brightreeInvoiceStatuses" component={AdminTermListBrightreeInvoiceStatus} />
                <Route exact path="/admin/brightreeInvoiceStatus/:id" component={AdminTermCreateEditBrightreeInvoiceStatus} />
                <Route exact path="/admin/brightreeInvoiceStatus" component={AdminTermCreateEditBrightreeInvoiceStatus} />

                <Route exact path="/admin/invoiceFollowupStatuses" component={AdminTermListInvoiceFollowupStatus} />
                <Route exact path="/admin/invoiceFollowupStatus/:id" component={AdminTermCreateEditInvoiceFollowupStatus} />
                <Route exact path="/admin/invoiceFollowupStatus" component={AdminTermCreateEditInvoiceFollowupStatus} />

                <Route exact path="/admin/distributionTypes" component={AdminTermListDistributionType} />
                <Route exact path="/admin/distributionType/:id" component={AdminTermCreateEditDistributionType} />
                <Route exact path="/admin/distributionType" component={AdminTermCreateEditDistributionType} />

                <Route exact path="/admin/distributionReasons" component={AdminDistributionReasonList} />
                <Route exact path="/admin/distributionReason/:id" component={AdminDistributionReasonCreateEdit} />
                <Route exact path="/admin/distributionReason" component={AdminDistributionReasonCreateEdit} />

                <Route exact path="/admin/hcpcsCodes" component={AdminHcpcsCodeList} />
                <Route exact path="/admin/hcpcsCode/:id" component={AdminHcpcsCodeCreateEdit} />
                <Route exact path="/admin/hcpcsCode" component={AdminHcpcsCodeCreateEdit} />

                <Route exact path="/admin/userProductionTypes" component={AdminUserProductionTypeList} />
                <Route exact path="/admin/userProductionType/:id" component={AdminUserProductionTypeCreateEdit} />
                <Route exact path="/admin/userProductionType" component={AdminUserProductionTypeCreateEdit} />

                {/* Queue */}
                <Route exact path="/queue/:id" component={Queue} />

                {/* Chart */}
                <Route exact path="/chart/patient" component={ChartPatientCreateEdit} />

                {/* Reverse Quality */}
                <Route exact path={"/reports/reverseQuality"} component={ReportReverseQuality}/>
                <Route exact path={"/reverseQualityReasons"} component={ReverseQualityReasons}/>

                <Route
                    exact
                    path="/order/:id/deliveryManifest"
                    component={DeliveryManifest}
                />

                <Route
                    exact
                    path="/thirdPartyUserList"
                    component={ThirdPartyUserList}
                />

                <Route exact path="/faxes/" component={Faxes} />
                <Route exact path="/inventoryTracking/" component={InventoryTacking} />
                <Route exact path="/addUser/" component={UserAdd} />
                <Route exact path="/order" component={OrderList} />
                <Route exact path="/patientList/:patient" component={PatientList} />
                <Route exact path="/patientList/" component={PatientList} />

                {/* Account Route */}
                <Route exact path="/accounts" component={AccountList} />
                <Route exact path="/account/:id" component={AccountEdit} />
                <Route exact path="/addAccount" component={AccountAdd} />
                <Route exact path="/account/:id/detail" component={AccountDetail} />
                {/* Activity Route */}
                <Route exact path="/activities" component={ActivityList} />
                <Route exact path="/activity/:id" component={ActivityEdit} />
                <Route exact path="/addActivity" component={ActivityAdd} />
                {/*Settings Route */}
                <Route exact path="/products" component={ProductTypes} />
                <Route exact path="/products/:id" component={EditProductSubType} />
                <Route exact path="/productType/new" component={AddProductSubType} />
                <Route exact path="/insuranceSubTypeDetails" component={InsuranceSubTypeDetails} />
                <Route exact path={"/stateSalesTax"} component={StateSalesTax} />
                <Route exact path="/productSizes" component={ProductSize} />
                <Route exact path="/productSizes/new" component={AddProductSize} />
                <Route exact path="/NotificationTypes" component={NotificationTypes} />
                <Route exact path="/recurringOrders/" component={RecurringOrders} />
                <Route exact path="/trainingLink/" component={TrainingLink} />
                <Route exact path="/creditCardTypes" component={CreditCardTypes} />
                <Route exact path="/expenseTypes" component={ExpenseTypes} />
                <Route
                    exact
                    path="/deliveryAcknowledgements"
                    component={DeliveryAcknowledgements}
                />
                <Route
                    exact
                    path="/deliveryAcknowledgements/new"
                    component={AddDeliveryAcknowledgement}
                />
                <Route exact path="/reasons" component={Reasons} />
                <Route exact path="/deliveries" component={DeliveryList} />
                <Route exact path="/mapWindow/:id" component={MapComponentWindow} />
                <Route exact path="/collections" component={CollectionsView} />
                <Route exact path="/operations/patientCollections" component={PatientCollections} />
                <Route exact path="/atp" component={ATPView} />
                <Route exact path="/admin/salesPcrLinks" component={AdminSalesPcrLinkList} />
                <Route exact path="/admin/salesPcrLink" component={AdminSalesPcrLinkCreateEdit} />
                <Route exact path="/admin/salesPcrLink/:id" component={AdminSalesPcrLinkCreateEdit} />
                <Route
                    exact
                    path="/manualDocumentBuilder"
                    component={ManualDocBuilder}
                />
                <Route exact path="/vendors" component={Vendor} />
                <Route
                    exact
                    path="/productMatrixEntry"
                    component={ProductMatrixEntry}
                />
                <Route exact path="/patientApprovals" component={PatientApprovals} />

                {/*Report Routes*/}
                <Route exact path="/reports/home" component={Reports} />
                <Route exact path="/reports/salesPipeline" component={SalesPipelineGraphView} />
                <Route exact path="/reports/monthGlance" component={MonthGlanceView} />
                <Route exact path="/reports/metric" component={ReportMetric} />
                <Route exact path="/reports/ARDashboard" component={ARDashboard} />
                <Route exact path="/dailyOrderCounts" component={DailyOrderCounts} />

                {/* Task Route */}
                <Route exact path="/tasks" component={Tasks} />

                {/*Expenses Routes*/}
                <Route exact path="/expenses" component={Expenses} />
                <Route exact path="/expenses/:id" component={ExpenseEdit} />

                {/*Purchasing Routes*/}
                <Route exact path="/purchaseQueues" component={PurchaseQueueView} />
                <Route exact path="/inventoryRequest" component={InventoryRequest} />
                <Route exact path="/shippingDetails" component={ShippingDetailsReport} />

                {/*Inventory Product Routes*/}
                <Route
                    exact
                    path="/inventoryProducts"
                    component={InventoryProductView}
                />

                {/*Contacts Routes*/}
                <Route exact path="/contacts" component={Contacts} />
                <Route exact path="/contact/:id" component={ContactEdit} />

                {/*President's Club Routes*/}
                <Route exact path="/presidentsClub" component={PresidentsClubView} />

                {/*Liaison Meeting View*/}
                <Route exact path="/liaisonMeetings" component={LiaisonMeetingView} />

                {/*Bonus Points Routes*/}
                <Route exact path="/bonus" component={BonusReport} />

                <Route exact path="/serviceParts" component={ServicePartList} />

                <Route
                    render={function () {
                        return <h1>Not Found</h1>;
                    }}
                />
            </Switch>
        );
    }
}

export default Routes;
