import React from "react";
import Dialog from "../Dialog";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUISearchIcon from "@mui/icons-material/Search";
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUIBox from '@mui/material/Box';
import EmptyState from "../EmptyState";
import Tile from "../Tile";
import MUICheckbox from "@mui/material/Checkbox";
import MUIDialogActions from "@mui/material/DialogActions";
import MUIButton from "@mui/material/Button";

export default class DialogSearch extends Dialog {
    constructor(props) {
        super(props);

        this.state.query = "";
        this.state.searchResults = {};
        this.state.data = [];
        this.state.selected = {};
    }

    /**
     * Set the search results on mount. This ensures that the full list gets
     * rendered if it's set up to return all on empty search string.
     *
     * Note that getSearchResults() relies on data, so force that state update
     * to happen after.
     */
    componentDidMount() {
        this.setState({ data: this.getData() }, () => {
            this.setState({
                searchResults: this.getSearchResults(this.state.query),
                // Converts an array to an object where the keys of the object are the IDs of the array entries
                selected: (this.props.selected || []).reduce((accumulator, currentItem) => ({ ...accumulator, [currentItem.id]: currentItem }), {})
            });
        });
    }

    /**
      * Update any time the data changes.
      *
      * @param {object} prevProps
      */
    componentDidUpdate(prevProps) {
        if (
            (
                prevProps.data &&
                this.props.data &&
                prevProps.data.length !== this.props.data.length
            ) ||
            JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) ||
            JSON.stringify(prevProps.selected) !== JSON.stringify(this.props.selected)
        ) {
            this.setState({ data: this.getData() }, () => {
                this.setState({
                    searchResults: this.getSearchResults(this.state.query),
                    // Converts an array to an object where the keys of the object are the IDs of the array entries
                    selected: (this.props.selected || []).reduce((accumulator, currentItem) => ({ ...accumulator, [currentItem.id]: currentItem }), {})
                });
            });
        }
    }

    /**
     * Child classes should override this in order to provide their own list of
     * data to use.
     *
     * @returns {array}
     */
    getData() {
        return this.props.data;
    }

    /**
     * Clear state. Called when the dialog closes.
     */
    clearState() {
        super.clearState();

        this.setState({
            query: "",
            searchResults: this.getSearchResults(""),
        });
    }

    /**
     * Render the search box.
     */
    renderHeader() {
        const handleChange = (e) => {
            this.setState({
                query: e.target.value,
                searchResults: this.getSearchResults(e.target.value)
            });
        };

        const handleKeyDown = (e) => {
            if (
                e.key === "Enter" &&
                this.state.searchResults.length === 1 &&
                this.props.onSelect !== undefined
            ) {
                e.preventDefault();
                this.props.onSelect(this.state.searchResults[0]);
                this.close();
            };
        };

        return (
            <>
                <MUITextField
                    autoFocus={true}
                    placeholder="Type to search..."
                    fullWidth={true}
                    variant="standard"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        startAdornment: (
                            <MUIInputAdornment position="start">
                                <MUISearchIcon />
                            </MUIInputAdornment>
                        ),
                        disableUnderline: true,
                    }}
                />
            </>
        );
    }

    /**
     * Render search result tiles in a grid.
     */
    renderContent() {
        const height = 400;

        if (this.state.searchResults.length > 0) {
            return (
                <MUIBox height={height}>
                    <MUIGrid container spacing={2} sx={{ "paddingBottom": 2 }}>
                        {this.state.searchResults.slice(0, this.getMaxResults()).map((searchResult, i) => {
                            return (
                                <MUIGrid key={i} item xs={12} sm={6} md={4}>
                                    {this.renderTileInternal(searchResult)}
                                </MUIGrid>
                            );
                        })}
                    </MUIGrid>
                </MUIBox>
            );
        }

        /**
         * Empty state. This uses a grid container so the modal height doesn't
         * change depending on whether there are results or not.
         */
        return (
            <MUIBox height={height}>
                <MUIGrid container spacing={2} sx={{ "paddingBottom": 2 }}>
                    <MUIGrid item xs={12} >
                        <div style={{ "textAlign": "center", "paddingTop": 130 }}>
                            <EmptyState
                                line1={this.state.query.trim() === "" ? "Start typing to begin your search" : "No results found"}
                                line2={this.state.query.trim() === "" ? "What have you got to lose?" : "Try searching for something a little different"}
                            />
                        </div>
                    </MUIGrid>
                </MUIGrid>
            </MUIBox>
        );
    }

    /**
     * @returns The maximum number of results that can be rendered. Set to
     * Infinity to remove the cap. Just be aware that rendering too many items
     * is not performant or generally useful.
     */
    getMaxResults() {
        return 20;
    }

    /**
     * @returns {boolean} Whether or not to show dividers on content area.
     */
    getDividers() {
        return true;
    }

    /**
     * Searches for objects. Intended to only be used in the DialogSearch class
     * directly for generic search dialogs.
     *
     * @param {string} query The search query.
     * @returns A list of filtered objects.
     */
    getSearchResults(query) {
        const queryCleaned = query
            .toLowerCase()
            .trim()
            .replace(/\s+/g, ' ');

        return this.state.data.filter((row) => {
            return (
                (row.name.toLowerCase().includes(queryCleaned)) ||
                String(row.value) === String(queryCleaned)
            );
        });
    }

    /**
     * @param {object} object The object.
     * @returns A Tile component for this object. Intended to only be used in
     * the DialogSearch class directly for generic search dialogs.
     */
    renderTileInternal(object) {
        const handleClick = () => {
            // Update the state. For multi select, toggle the selected value.
            // For single select, just overwrite it.
            let selected;
            if (this.props.multiSelect) {
                selected = { ...this.state.selected };

                if (selected[object.id]) {
                    delete selected[object.id];
                } else {
                    selected[object.id] = object;
                }
            } else {
                selected = {
                    [object.id]: object
                };
            }

            // If this is not a multi select, immediately apply the change.
            this.setState({ selected }, () => {
                if (!this.props.multiSelect) {
                    this.handleApply();
                }
            });
        };

        // Render the tile component, or fall back to a default generic one.
        let tile;
        if (this.renderTile) {
            tile = this.renderTile(object);
        } else {
            tile = (
                <Tile
                    title={object.name}
                    onClick={handleClick}
                    avatarString={object.name}
                />
            );
        }

        // Turn the avatar into a checkbox if this is a multiSelect.
        tile = React.cloneElement(
            tile,
            {
                avatarContent: this.props.multiSelect ? <MUICheckbox checked={!!this.state.selected[object.id]} /> : undefined,
                avatarColor: this.props.multiSelect ? "transparent" : undefined,
                onClick: handleClick,
            }
        );

        return tile;
    }

    /**
     * Handle finalizing the selection either by clicking "Apply" for multi
     * select, or by clicking a single entry for single select.
     */
    handleApply() {
        if (this.props.multiSelect) {
            this.props.onSelect(Object.values(this.state.selected));
            this.close();
        } else {
            this.props.onSelect(Object.values(this.state.selected)[0]);
            this.close();
        }
    }

    /**
     * Handle clicking the select all button for multi select.
     */
    handleSelectAll() {
        const selected = {};
        this.state.data.forEach((object) => {
            selected[object.id] = object;
        });
        this.setState({ selected });
    }

    /**
     * Handle clicking the select none button for multi select.
     */
    handleSelectNone() {
        this.setState({ selected: {} });
    }

    /**
     * Render action buttons.
     */
    renderActions() {
        if (
            this.props.onClear ||
            this.props.multiSelect
        ) {
            return (
                <MUIDialogActions spacing={2}>
                    {this.props.onClear && (
                        <MUIButton variant="text" onClick={this.props.onClear} color="error">
                            Remove Filter
                        </MUIButton>
                    )}
                    {this.props.multiSelect && (
                        <>
                            <MUIButton variant="text" onClick={this.handleSelectNone.bind(this)}>
                                Select None
                            </MUIButton>
                            <MUIButton variant="text" onClick={this.handleSelectAll.bind(this)}>
                                Select All
                            </MUIButton>
                            <MUIButton variant="contained" onClick={this.handleApply.bind(this)}>
                                Apply
                            </MUIButton>
                        </>
                    )}
                </MUIDialogActions>
            );
        }
        return false;
    }

}