import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    MDBInput,
    Row,
    Spinner,
    toast,
    ToastContainer,
    MDBIcon, MDBBtn,
} from "mdbreact";
import "./userEdit.css";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import UserService from "../Security/UserService/userService";
import globalFunctions from "../Filters/GlobalFunctions";
import LayerLegacy from "../../LayerLegacy";

export default class userEdit extends LayerLegacy {
    constructor(props) {
        super(props);

        let user = {},
            isLoaded = false,
            id = props.match.params.id;

        this.retrieveUser(id);

        if (!user.userprofileLocations || user.userprofileLocations === null) {
            user.userprofileLocations = [];
        }

        this.state = {
            isLoaded: isLoaded,
            user: user,
            eUser: JSON.parse(JSON.stringify(user)),
            userAccounts: [],
            selectedAccounts: [],
            moveTo: null,
            npw: "",
            cpw: "",
            allLocations: [],
            salesReps: [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        salesReps: PropTypes.array,
    };

    componentDidMount(){
        this.getLocations();
        this.getSalesReps();
    }

    retrieveUser(id) {
        return UserService.getUser(id).then((data) => {
            // console.log(data)
            if (data.userprofileLocations === null) {
                data.userprofileLocations = [];
            }

            this.setState({
                user: data,
                eUser: JSON.parse(JSON.stringify(data)),
                userAccounts: data.accounts || [],
                isLoaded: true,
            });
        });
    }

    getSalesReps() {
        const { salesReps } = this.context;
        let a = [];
        a.push({
            label: "Select Sales Rep...",
            value: "",
        });
        salesReps.map((user) => {
            return a.push({
                label: user.username,
                value: user,
            });
        });
        this.setState({ salesReps: a });
    }

    getLocations() {
        const { allLocations } = this.context;
        let userLocs = this.state.eUser.userprofileLocations.map(x => x.locations) || [],
            locsMini = [];

        if (userLocs) {
            userLocs.forEach((l) => {
                locsMini.push(l.name);
            });
        }

        let a = [];
        a.push({
            label: "Select Location...",
            value: "",
        });

        allLocations.map(loc => {
            return a.push({
                label: loc.name,
                value: loc
            });
        });

        if (locsMini && locsMini.length > 0) {
            a = a.filter((x) => locsMini.indexOf(x.label) === -1);
        }

        return a;
    }

    moveAccounts() {
        let rAcc = this.state.selectedAccounts,
            userAcc = this.state.userAccounts;

        if (!this.state.moveTo || this.state.selectedAccounts.length === 0) {
            //can't do anything so just return...BC
            return;
        }

        rAcc.forEach((rA) => {
            let idx = userAcc.findIndex((curAcc) => {
                return curAcc.id === rA;
            });

            if (idx !== -1) {
                userAcc.splice(idx, 1);
            }
        });

        return UserService.moveAccounts(
            this.state.selectedAccounts,
            this.state.moveTo
        )
            .then((res) => {
                this.setState({ userAccounts: userAcc });
                if(res.moveAccounts){
                    toast.success("Accounts moved successfully");
                    this.setState({ userAccounts: userAcc });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleMoveToUserSelected = (e) => {
        let user = { name: e.value.username, id: e.value.id };

        this.setState({
            moveTo: user,
        });
    };

    addUserLocation(location) {
        let l = location.value,
            eUser = this.state.eUser;
        console.log(l,eUser);
        //Search to make sure this location doesn't already exist...BC
        let idx = eUser.userprofileLocations.findIndex((loc) => {
            return loc.locationsId === l.id;
        });

        //Only add if it doesn't exist...BC
        if (idx === -1) {
            let locationRef = {
                id: l.id,
                name: l.name,
            };

            eUser.userprofileLocations.push({ userprofileId: eUser.id, locationsId: l.id, locations: locationRef });
        }

        this.setState({ eUser: eUser });
    }

    addAllLocations() {
        const { allLocations } = this.context;

        let eUser = this.state.eUser;

        allLocations.forEach((loc) => {
            let idx = eUser.userprofileLocations.findIndex((ul) => {
                return ul.locationsId === loc.id;
            });

            if (idx === -1) {
                let locationRef = {
                    id: loc.id,
                    name: loc.name,
                };

                eUser.userprofileLocations.push({ userprofileId: eUser.id, locationsId: locationRef.id, locations: locationRef });
            }
        });

        this.setState({ eUser: eUser });
    }

    removeUserLocation(location) {
        let eUser = this.state.eUser;

        let idx = eUser.userprofileLocations.findIndex((loc) => {
            return loc.locationsId === location.id;
        });

        if (idx !== -1) {
            eUser.userprofileLocations.splice(idx, 1);
        }

        this.setState({ eUser: eUser });
    }

    updateUser() {
        let u = this.state.eUser;
        u.email = u.email.trim();

        if (!this.state.user.active && !u.active && !u.email.includes("-deleted-")) {
            u.email = u.email + "-deleted-" + globalFunctions.getUTCMoment();
            u.userprofileLocations = [];
        }

        return UserService.updateUser(u)
            .then((data) => {
                if(data) {
                    toast.success("Saved Successfully!");
                }
                //u.location = l;
                this.setState({
                    user: u,
                    eUser: JSON.parse(JSON.stringify(u)),
                });
            })
            .catch((err) => {
                toast.warn("An error occurred while saving.");
            });
    }

    clearTokens(){
        let u = this.state.eUser;

        return UserService.clearTokens(u)
            .then((data) => {
                toast.success("Tokens Cleared!");
            })
            .catch((err) => {
                toast.warn("An error occurred while updating tokens.");
            });
    }

    accountSelected = (account, checked) => {
        let selectedAccounts = this.state.selectedAccounts,
            id = account.id;

        let idx = selectedAccounts.findIndex((acc) => {
            return acc === id;
        });

        if (checked === true) {
            //make sure not to add it more than once...BC
            if (idx === -1) {
                selectedAccounts.push(id);
            }
        } else {
            //Only remove it if it is in the array...BC
            if (idx !== -1) {
                selectedAccounts.splice(idx, 1);
            }
        }

        this.setState({ selectedAccounts: selectedAccounts });
    };

    changeRole(role) {
        let oUser = this.state.eUser;
        oUser.role = role;
        this.setState({ eUser: oUser });
    }

    selectAllAccounts() {
        let selected = [];

        //if we have nothing selected, select all...BC
        if (this.state.selectedAccounts.length === 0) {
            this.state.userAccounts.forEach((acc) => {
                selected.push(acc.id);
            });
        }

        this.setState({
            selectedAccounts: selected,
        });
    }

    handleChange = (property, event) => {
        const nUser = this.state.eUser;
        //update the property that the user has edited in the order...BC
        if (property === "brightreeExternalId") {
            let value = parseInt(event.target.value)
            nUser[property] = value;
        } else {
            nUser[property] = event.target.value;
        }

        this.setState({ eUser: nUser });
    };

    validateForm() {
        let e = this.state.eUser;
        if (e.username === "") {
            return "Please enter a username.";
        } else if (e.firstname === "") {
            return "Please enter a first name.";
        } else if (e.lastname === "") {
            return "Please enter a last name.";
        } else if (e.role === "") {
            return "Please select a role.";
        }
        else if (!e.email || e.email === "") {
            return "Please enter an email.";
        } else if (!(e.email.includes("@") && e.email.includes("."))) {
            return "Please enter a valid email address.";
        }
        return true;
    }

    renderErrorMessage() {
        let res = this.validateForm();

        let save = (
            <Button
                floating
                size="sm"
                color={"success"}
                data-tip={"Update User"}
                onClick={this.updateUser.bind(this)}
            >
                <MDBIcon far icon="save" style={{ fontSize: "2em" }} />
            </Button>
        );

        let error = (
            <Button floating size="sm" color={"red"} data-tip={res}>
                <MDBIcon icon="times" style={{ fontSize: "2em" }} />
            </Button>
        );

        if (res !== true) {
            return error;
        }

        //return the chip...BC
        return save;
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderLocationsCard() {
        let locsSort = this.state.eUser.userprofileLocations.map(x => x.locations) || [];
        if(locsSort && locsSort.length > 1){
            locsSort.sort((a, b) => (a.name > b.name ? 1 : -1));
        }

        return (
            <div>
                <Card>
                    <CardHeader style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30, minHeight: 82 }}>
                        <h2>Locations</h2>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col size="9">
                                <Select
                                    placeholder={"Select Location..."}
                                    options={this.getLocations()}
                                    onChange={this.addUserLocation.bind(this)}
                                />
                            </Col>

                            <Col size="3">
                                <Button
                                    floating
                                    size="sm"
                                    color={"green"}
                                    data-tip={"Add All Locations"}
                                    onClick={() => this.addAllLocations()}
                                >
                                    <MDBIcon icon="plus" style={{ fontSize: "2em" }} />
                                </Button>
                            </Col>
                        </Row>

                        <br />

                        <div
                            style={{
                                overflowX: "hidden",
                                overFlowY: "auto",
                                maxHeight: "400px",
                            }}
                        >
                            {locsSort
                                ? locsSort.map((loc, idx) => {
                                    return (
                                        <Row key={idx}>
                                            <Col size="9">
                                                <h5>{loc.name}</h5>
                                            </Col>
                                            <Col size="3">
                                                <Button
                                                    floating
                                                    size="sm"
                                                    color={"red"}
                                                    data-tip={"Remove Location"}
                                                    onClick={this.removeUserLocation.bind(this, loc)}
                                                >
                                                    <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                                                </Button>
                                            </Col>
                                            <ReactTooltip />
                                        </Row>
                                    );
                                })
                                : ""}
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderAccountsCard() {
        return (
            <div>
                <Card>
                    <CardHeader style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30, minHeight: 82 }}>
                        <h2>Manage Accounts</h2>
                    </CardHeader>
                    <CardBody style={{paddingBottom:0}}>


                        <label> Move Accounts to</label>

                        <Row>
                            <Col size="8" className="align-self-center">
                                <Select
                                    placeholder={"Select User..."}
                                    options={this.state.salesReps}
                                    onChange={this.handleMoveToUserSelected.bind(this)}
                                />
                            </Col>

                            <Col size="4" style={{padding: 0}}>
                                <Button
                                    floating
                                    size="sm"
                                    color={"warning"}
                                    data-tip={"Select All Accounts"}
                                    onClick={this.selectAllAccounts.bind(this)}
                                >
                                    <MDBIcon far icon="list-alt" style={{ fontSize: "2em" }} />
                                </Button>

                                <Button
                                    floating
                                    size="sm"
                                    data-tip={"Move Accounts"}
                                    onClick={this.moveAccounts.bind(this)}
                                >
                                    <MDBIcon icon="people-carry" style={{ fontSize: "2em" }} />
                                </Button>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                overflowX: "hidden",
                                overFlowY: "auto",
                                maxHeight: "400px",
                            }}
                        >
                            {this.state.userAccounts.map((acc, idx) => {
                                return (
                                    <Col key={idx} size="6">
                                        <Input
                                            label={acc.name}
                                            checked={this.state.selectedAccounts.indexOf(acc.id) > -1}
                                            onChange={(evt) =>
                                                this.accountSelected(acc, evt.target.checked)
                                            }
                                            type="checkbox"
                                            id={"checkbox" + acc.id}
                                        />
                                    </Col>
                                );
                            })}
                            {/*Prevent unnecessary overflow on edge cases*/}
                            <Col size="6" >
                            </Col>
                            <Col size="6" style={{minHeight: 25}}>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderClearTokensButton(){
        return(
            <MDBBtn
                floating
                size="sm"
                color={"secondary"}
                data-tip={"Clear Tokens"}
                onClick={this.clearTokens.bind(this)}>
                <MDBIcon icon={"radiation"}/>
            </MDBBtn>
        );
    }

    renderUserCard() {
        let user = this.state.eUser;

        return (
            <Card>
                <CardHeader style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30, minHeight: 82 }}>
                    <Row>
                        <Col size="8" className="align-self-center">
                            <h2>
                                {this.state.user.firstname + " " + this.state.user.lastname}
                            </h2>
                        </Col>

                        <Col size ="4" style={{padding: 0}} className="align-self-center">
                            {this.renderErrorMessage()}
                            {this.renderClearTokensButton()}
                        </Col>
                        <ReactTooltip />
                    </Row>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col size="6">
                            <Input
                                outline
                                value={user.username || ""}
                                onChange={this.handleChange.bind(this, "username")}
                                label="Username"
                                size="sm"
                                icon="user"
                            />
                        </Col>

                        <Col size="6">
                            <Dropdown>
                                <DropdownToggle caret color="primary">
                                    {user.role}
                                </DropdownToggle>
                                <DropdownMenu basic>
                                    <DropdownItem onClick={this.changeRole.bind(this, "ADMIN")}>
										Admin
                                    </DropdownItem>
                                    <DropdownItem onClick={this.changeRole.bind(this, "USER_ADMIN")}>
										User Admin
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "EXECUTIVE_MANAGEMENT")}
                                    >
										Executive Management
                                    </DropdownItem>
                                    <DropdownItem onClick={this.changeRole.bind(this, "FINANCE")}>
										Finance
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "OFFICE_MANAGER")}
                                    >
										Office Manager
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "AREA_MANAGER")}
                                    >
										Area Manager
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "MARKETING")}
                                    >
										Marketing
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "MARKETING_MANAGER")}
                                    >
										Marketing Manager
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "SERVICE_TEAM")}
                                    >
										Service Team
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "CUSTOMER_EXPERIENCE")}
                                    >
										Customer Experience
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "TECHNICIAN")}
                                    >
										Technician
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "WAREHOUSE_TECHNICIAN")}
                                    >
										Warehouse Technician
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "SALES_MANAGER")}
                                    >
										Sales Manager
                                    </DropdownItem>
                                    <DropdownItem onClick={this.changeRole.bind(this, "SALES")}>
										Sales Rep
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(
                                            this,
                                            "SALES_LIAISON_MANAGER"
                                        )}
                                    >
										Sales Liaison Manager
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "SALES_LIAISON")}
                                    >
										Sales Liaison
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "PATIENT_CARE_REP")}
                                    >
										Patient Care Rep
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "OFFICE_SUPPORT")}
                                    >
										Office
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "CARE_COORDINATOR")}
                                    >
										Care Coordinator
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "VERIFICATION")}
                                    >
										Verification
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "FOLLOWUP")}
                                    >
										Follow Up
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "COLLECTIONS")}
                                    >
										Collections
                                    </DropdownItem>
                                    <DropdownItem onClick={this.changeRole.bind(this, "BILLING")}>
										Billing
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeRole.bind(this, "PURCHASING")}
                                    >
										Purchasing
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <Row>
                        <Col size="6">
                            <Input
                                outline
                                value={user.firstname || ""}
                                onChange={this.handleChange.bind(this, "firstname")}
                                label="First Name"
                                size="sm"
                                icon="book"
                            />
                        </Col>
                        <Col size="6">
                            <Input
                                outline
                                value={user.lastname || ""}
                                onChange={this.handleChange.bind(this, "lastname")}
                                label="Last Name"
                                size="sm"
                                icon="book"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Input
                                outline
                                value={user.email || ""}
                                onChange={this.handleChange.bind(this, "email")}
                                label="Email"
                                size="sm"
                                icon="envelope"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Input
                                outline
                                value={user.fax || ""}
                                onChange={this.handleChange.bind(this, "fax")}
                                label="Fax"
                                size="sm"
                                icon="fax"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Input
                                outline
                                value={user.brightreeExternalId || ""}
                                onChange={this.handleChange.bind(this, "brightreeExternalId")}
                                label="Brightree ID"
                                size="sm"
                                icon="id-card"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size="6">
                            <MDBInput
                                id="activeStatus"
                                label="Active"
                                checked={user.active}
                                value={user.active}
                                onChange={this.handleActive}
                                type="checkbox"
                            />

                            <MDBInput
                                id="pointsStatus"
                                label="Can Edit Points"
                                checked={user.canEditPoints}
                                value={user.canEditPoints}
                                onChange={this.handlePoints}
                                type="checkbox"
                            />
                        </Col>

                        <Col size="6">
                            <MDBInput
                                id="atpStatus"
                                label="is ATP"
                                checked={user.isAtp}
                                value={user.isAtp}
                                onChange={this.handleAtp}
                                type="checkbox"
                            />

                            <MDBInput
                                id="followUpStatus"
                                label="Can Follow Up"
                                checked={user.canFollowUp}
                                value={user.canFollowUp}
                                onChange={this.handleFollowUp}
                                type="checkbox"
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    handleActive = () => {
        let u = this.state.eUser;
        u.active = !u.active;

        if (this.state.user.active && !u.active && !u.email.includes("-deleted-")) {
            u.email = u.email + "-deleted-" + globalFunctions.getUTCMoment();
            u.userprofileLocations = [];
        } else if (this.state.user.active && u.active && u.email.includes("-deleted-")) {
            let index = u.email.indexOf("-deleted-");
            u.email = u.email.slice(0, index);
            u.userprofileLocations = this.state.user.userprofileLocations;
        } else if (!this.state.user.active && !u.active && !u.email.includes("-deleted-")) {
            u.email = u.email + "-deleted-" + globalFunctions.getUTCMoment();
            u.userprofileLocations = [];
        } else if (!this.state.user.active && u.active && u.email.includes("-deleted-")) {
            let index = u.email.indexOf("-deleted-");
            u.email = u.email.slice(0, index);
            //u.userprofileLocations = this.state.user.userprofileLocations // Display "Inactive Users" (if applicable) so we don't confuse our users
        }

        this.setState({
            eUser: u,
        });
    };

    handlePoints = () => {
        let u = this.state.eUser;
        u.canEditPoints = !u.canEditPoints;
        this.setState({
            eUser: u,
        });
    };

    handleAtp = () => {
        let u = this.state.eUser;
        u.isAtp = !u.isAtp;
        this.setState({
            eUser: u,
        });
    };

    handleFollowUp = () => {
        let u = this.state.eUser;
        u.canFollowUp = !u.canFollowUp;
        this.setState({
            eUser: u,
        });
    };

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }

        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Container className="mt-4" style={{minWidth: "1338px", maxWidth: "80%",}}>
                    <Row>
                        <Col size={"4"} style={{padding: 5}}>{this.renderUserCard()}</Col>
                        <Col size={"4"} style={{padding: 5}}>{this.renderAccountsCard()}</Col>
                        <Col size={"4"} style={{padding: 5}}>{this.renderLocationsCard()}</Col>


                    </Row>
                </Container>
            </div>
        );
        // 				<Col size="6">{this.renderUserCard()}</Col>
        // 				<Col size="6">
        // 					<Col size="12">{this.renderAccountsCard()}</Col>
        // 					<br />
        // 					<br />
        // 					<br />
        // 					<Col size="12">{this.renderLocationsCard()}</Col>
        // 				</Col>
        // 		</Row>
        // 	</Container>
        // </div>
        // );
    }
}
