import Crud from "../Crud";
import MUIAmberColor from "@mui/material/colors/amber";

/**
 * Static helper functions related to Accounts.
 */
export default class Account extends Crud {

    /**
     * @param {object} account The account object.
     * @returns The display name of the account.
     */
    static getDisplayName(account) {
        return account.name;
    }

    /**
     * Determines if a account matches a search query.
     *
     * @param {object} account The account to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the account matches the query.
     */
    static match(account, query) {
        return (
            (account.name !== null && account.name.toLowerCase().includes(query)) ||
            account.id === +query
        );
    }

    /**
     * @param {array} accounts The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(accounts) {
        return accounts.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIAmberColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "SwitchAccount";
    }
}