import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    MDBIcon,
    toast,
} from "mdbreact";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import CreatableSelect from "react-select/lib/Creatable";
import ReactTooltip from "react-tooltip";
import DeliveryAcknowledgementService from "./deliveryAcknowledgementService";
import LayerLegacy from "../../LayerLegacy";

const createOption = (label) => ({
    label,
    value: label,
});

export default class addDeliveryAcknowledgement extends LayerLegacy {
    constructor(props) {
        super(props);

        const o = [
            {
                label: "Signature",
                value: "Signature",
            },
            {
                label: "Initials",
                value: "Initials",
            },
            {
                label: "Date",
                value: "Date",
            },
            {
                label: "Select",
                value: "Select",
            },
        ];

        this.state = {
            description: "",
            errorMessage: "",
            selectOptions: o,
            selected: "",
            currChoice: "",
            choiceList: [],
            inputValue: "",
        };
    }

    descriptionChange = (e) => {
        this.setState({
            description: e.target.value,
        });
    };

    handleSelectChange(e) {
        console.log(e);
        let ind = this.state.selectOptions.indexOf(e);
        console.log(ind);
        this.setState({
            selected: ind,
        });
    }

    renderForm() {
        return (
            <div>
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <label htmlFor="defaultFormContactMessageEx" className="grey-text">
					Description
                </label>
                <textarea
                    id="defaultFormContactMessageEx"
                    className="form-control"
                    rows="3"
                    onChange={this.descriptionChange.bind(this)}
                    value={this.state.description}
                />
                <div className="mt-4">
                    <Select
                        placeholder={"Select Type..."}
                        options={this.state.selectOptions}
                        onChange={this.handleSelectChange.bind(this)}
                    />
                </div>
            </div>
        );
    }

    handleChange = (choiceList) => {
        this.setState({ choiceList });
    };
    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };
    handleKeyDown = (parameters) => {
        let event = parameters.event;
        const { inputValue, choiceList } = this.state;
        if (!inputValue) return;
        if (event.key === "Enter") {
            this.setState({
                inputValue: "",
                choiceList: [...choiceList, createOption(inputValue)],
            });
            event.preventDefault();
        }
    };

    renderSelectChosen() {
        if (this.state.selected === "Select") {
            const { inputValue, choiceList } = this.state;
            return (
                <div>
                    <CreatableSelect
                        className="mt-4"
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        onChange={this.handleChange}
                        onInputChange={this.handleInputChange}
                        placeholder="Type a choice and press enter..."
                        value={choiceList}
                    />
                </div>
            );
        }
    }

    addSaveButtonClick = () => {
        if (this.validate()) {
            let s = this.state.selected,
                obj = {
                    name: this.state.description,
                    type: s,
                };

            if (s === "Select") {
                let v = this.state.choiceList,
                    a = [];

                v.forEach((c) => {
                    a.push(c.value);
                });
                obj.choices = a.join(" / ");
            }
            return DeliveryAcknowledgementService.createDeliveryAcknowledgement(obj)
                .then((res) => {
                    this.setState({
                        errorMessage: "",
                        description: "",
                        selected: "",
                        currChoice: "",
                        choiceList: [],
                    });
                    toast.success("Delivery Acknowledgment has been Added!");
                })
                .catch((err) => {
                    toast.warn("An error occurred adding a new Delivery Acknowledgment");
                });
        }
    };

    validate() {
        let d = this.state.description,
            s = this.state.selected,
            cl = this.state.choiceList;
        if (d.trim() === "") {
            this.setError("Please enter a Description");
            return false;
        } else if (s === "") {
            this.setError("Please select a Type");
            return false;
        } else if (s === "Select" && cl.length === 0) {
            this.setError("Please add Choices to use the Select Type");
            return false;
        }
        return true;
    }
    setError(e) {
        this.setState({
            errorMessage: e,
        });
    }

    renderContent() {
        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Container className="mt-5">
                    <Card>
                        <CardHeader
                            color={"blue-grey lighten-2"}
                            style={{ textAlign: "center" }}
                        >
                            <Button
                                style={{ float: "left" }}
                                floating
                                size="sm"
                                color={"warning"}
                                data-tip={"Back to Delivery Acknowledgements"}
                                onClick={() =>
                                    this.props.history.push("/deliveryAcknowledgements")
                                }
                            >
                                <MDBIcon icon="backward" style={{ fontSize: "2em" }} />
                            </Button>
							Add New Delivery Acknowledgment
                            <Button
                                style={{ float: "right" }}
                                floating
                                size="sm"
                                color={"success"}
                                data-tip={"Save New Delivery Acknowledgement"}
                                onClick={this.addSaveButtonClick}
                            >
                                <MDBIcon far icon="save" style={{ fontSize: "2em" }} />
                            </Button>
                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                            {this.renderSelectChosen()}
                        </CardBody>
                    </Card>
                    <ReactTooltip />
                </Container>
            </div>
        );
    }
}