import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUIGrid from "@mui/material/Grid";
import TileOrderStatus from "../../../Tile/TileOrderStatus";
import DialogSearchOrderStatus from "../../../Dialog/Search/DialogSearchOrderStatus";

export default class CardAdminOrderStatusReasonGeneral extends Card {
    constructor(props) {
        super(props);

        this.state.orderStatusDialogOpen = false;
    }

    static contextTypes = {
        orderStatusesIndexed: PropTypes.object,
    };
    /**
     * Render the content of the card.
     */
    renderContent() {
        let orderStatusReason = this.props.orderStatusReason;
        let orderStatus = null;


        if (orderStatusReason && orderStatusReason.orderStatus !== null) {
            orderStatus = this.context.orderStatusesIndexed[orderStatusReason.orderStatus];
        }

        // Order Status
        const handleEditOrderStatus = () => {
            this.setState({ orderStatusDialogOpen: true });
        };
        const handleClearOrderStatus = () => {
            this.props.onChange({ orderStatus: null });
        };
        const orderStatusIconButtons = [
            (<MUIIconButton onClick={handleEditOrderStatus}><MUIEditIcon /></MUIIconButton>)
        ];

        if (orderStatus) {
            orderStatusIconButtons.push(
                (<MUIIconButton onClick={handleClearOrderStatus}><MUIDeleteIcon /></MUIIconButton>)
            );
        }


        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.orderStatusReason && this.props.orderStatusReason.description ? this.props.orderStatusReason.description : ""}
                        autoFocus={true}
                        size="small"
                        label="Name"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ description: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ description: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.description ? true : false}
                        helperText={this.props.validationIssues.description ? this.props.validationIssues.description : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <TileOrderStatus
                        orderStatus={orderStatus}
                        iconButtons={orderStatusIconButtons}
                        label="Status"
                        required={true}
                        error={this.props.validationIssues.orderStatus ? true : false}
                        helperText={this.props.validationIssues.orderStatus ? this.props.validationIssues.orderStatus : ""}
                    />
                </MUIGrid>
                <DialogSearchOrderStatus
                    open={this.state.orderStatusDialogOpen}
                    onClose={() => this.setState({ orderStatusDialogOpen: false })}
                    onSelect={(orderStatus) => {
                        this.props.onChange({ orderStatus: orderStatus.id });
                    }}
                />
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}