import Admin from "../Admin";
import React from "react";
import CardAdminOrderActivityTypeGeneral from "../../../Component/Card/Admin/OrderActivityType/CardAdminOrderActivityTypeGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import OrderActivityTypeService from "../../../Seating/Security/OrderActivityTypeService/orderActivityTypeService";
import Validator from "../../../Utility/Validator";
import OrderActivityType from "../../../Utility/Crud/OrderActivityType";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminOrderActivityTypeCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.orderActivityType = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Set the orderActivityType in the state.
     */
    afterComponentDidMount() {
        const orderActivityTypeId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (orderActivityTypeId !== null) {
            this.setState({
                orderActivityType: this.context.orderActivityTypesIndexed[orderActivityTypeId]
            });
        }
    }

    /**
     * Creates the orderActivityType with the current properties in state.orderActivityType.
     */
    async createOrderActivityType() {
        this.setState({
            isProcessing: true,
        });

        return OrderActivityTypeService.createOrderActivityType(this.state.orderActivityType)
            .then(orderActivityType => {
                this.context.updateIndexed("orderActivityTypesIndexed", orderActivityType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Order Activity Type created successfully!", {variant: "success"});
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Order Activity Type.", {variant: "error"});
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the orderActivityType with the current properties in state.orderActivityType.
     */
    async updateOrderActivityType() {
        this.setState({
            isProcessing: true,
        });

        return OrderActivityTypeService.updateOrderActivityType(this.state.orderActivityType)
            .then(orderActivityType => {
                this.context.updateIndexed("orderActivityTypesIndexed", orderActivityType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Order Activity Type updated successfully!", {variant: "success"});
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Order Activity Type.", {variant: "error"});
                this.setState({
                    isProcessing: false,
                });
            });
    }

    async deleteOrderActivityType() {
        this.setState({
            isProcessing: true,
        });

        return OrderActivityTypeService.deleteOrderActivityType(this.state.orderActivityType.id)
            .then(() => {
                this.context.deleteIndexed("orderActivityTypesIndexed", this.state.orderActivityType.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Order Activity Type deleted successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Order Activity Type.", {variant: "error"});
                this.setState({
                    confirmDialogOpen: false,
                });
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (orderActivityType) => {
            this.setState({
                orderActivityType: {
                    ...this.state.orderActivityType,
                    ...orderActivityType
                },
            });
        };

        return (
            <>
                <CardAdminOrderActivityTypeGeneral
                    orderActivityType={this.state.orderActivityType}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteOrderActivityType()}
                    text="Are you sure you want to delete this Order Activity Type? This cannot be undone."
                    header="Delete Order Activity Type"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Renders the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = "Create Order Activity Type";
            subtitle = null;
        } else {
            title = "Edit Order Activity Type";
            subtitle = (this.state.orderActivityType && (OrderActivityType.getDisplayName(this.state.orderActivityType) + " • " + this.state.orderActivityType.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.orderActivityType,
                {
                    name: {
                        'required': true
                    },
                    activityType: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateOrderActivityType();
                } else {
                    this.createOrderActivityType();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", {variant: "error"});
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
