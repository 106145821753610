import api from "../Security/api";

class ReverseQualityService {
    async getAllCategories() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/reverseQualityCategory", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createRQCategory(category){
        return new Promise((res, reject) => {
            api
                .post("/reverseQualityCategory", category)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }


    async getAllLogs(params) {
        return new Promise((res, reject) => {
            api
                .get("/reverseQualityLogs", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spReverseQualityReport
    async getAllLogsNative(params) {
        return new Promise((res, reject) => {
            api
                .get("/reverseQualityLog", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    async getReverseQualityLogs(params) {
        return new Promise((res, reject) => {
            api
                .post("/reverseQualityLogReport", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getLogById(id) {
        return new Promise((res, reject) => {
            api
                .get("/reverseQualityLog/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createReverseQualityLog(log) {
        return new Promise((res, reject) => {
            api
                .post("/reverseQualityLog", log)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async editReverseQualityLog(log) {
        return new Promise((res, reject) => {
            api.put("/reverseQualityLog", log)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //async reviewReverseQualityLog(log) {
    //	return new Promise((res, reject) => {
    //		api.put("/reverseQualityLogs", log)
    //			.then((data) => {
    //				res(data);
    //			})
    //			.catch((err) => {
    //				reject(err);
    //			});
    //	});
    //}




    async createQualityAssuranceLog(log) {
        return new Promise((res, reject) => {
            api
                .post("/qualityAssuranceRecords", log)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

}

const reverseQualityService = new ReverseQualityService();

export default reverseQualityService;
