import Crud from "../Crud";
import MUIRedColor from "@mui/material/colors/red";
import Region from "./Region";
import Location from "./Location";
import UserProfileLocation from "./UserProfileLocation";
import Security from "../Security";

/**
 * Static helper functions related to users.
 */
export default class User extends Crud {

    /**
     * @param {object} user The user object.
     * @returns The display name of the user.
     */
    static getDisplayName(user) {
        if (!user) {
            return null;
        }

        const parts = [];

        if (user.firstname !== null) {
            parts.push(user.firstname.trim());
        }

        if (user.lastname !== null) {
            parts.push(user.lastname.trim());
        }

        return parts.join(' ');
    }

    /**
     * Determines if a user matches a search query.
     *
     * @param {object} user The user to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the user matches the query.
     */
    static match(user, query) {
        return (
            (
                user.firstname !== null &&
                user.firstname.toLowerCase().includes(query)
            ) ||
            (
                user.firstname !== null &&
                user.lastname !== null &&
                (
                    (user.firstname.toLowerCase() + ' ' + user.lastname.toLowerCase()).includes(query) ||
                    (user.lastname.toLowerCase() + ' ' + user.firstname.toLowerCase()).includes(query) ||
                    (user.lastname.toLowerCase() + ', ' + user.firstname.toLowerCase()).includes(query) ||
                    (user.lastname.toLowerCase() + ',' + user.firstname.toLowerCase()).includes(query)
                )
            ) ||
            (
                user.lastname !== null &&
                user.lastname.toLowerCase().includes(query)
            ) ||
            (
                user.email !== null &&
                user.email.toLowerCase().includes(query)
            ) ||
            user.id === +query
        );
    }

    /**
     * @param {array} users The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(users) {
        return users.sort((a, b) => {
            return (a.firstname + ' ' + a.lastname).localeCompare(b.firstname + ' ' + b.lastname);
        });
    }

    /**
     * @returns {object} The color of this object.
     */
    static getBaseColor() {
        return MUIRedColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Person";
    }

    /**
     * Get regions the current user has access to.
     *
     * @returns {array}
     */
    static getFilteredRegions(user, userprofileLocationsIndexed, regionsIndexed, locationsIndexed) {
        let regions = [];

        if (Security.hasPermission("user.viewAllRegions") === true) {
            regions = Object.values(regionsIndexed);
        } else {
            const userProfileLocations = UserProfileLocation.read(
                userprofileLocationsIndexed,
                [
                    {
                        key: "userprofileId",
                        value: user.id
                    }
                ]
            );

            const regionIds = {};
            userProfileLocations.forEach((userProfileLocation) => {
                const location = locationsIndexed[userProfileLocation.locationsId];

                // Sales Region
                if (
                    location.regionId &&
                    !regionIds[location.regionId]
                ) {
                    regionIds[location.regionId] = true;
                    regions.push(regionsIndexed[location.regionId]);
                }

                // Field Operations Region
                if (
                    location.fieldOperationsRegionId &&
                    !regionIds[location.fieldOperationsRegionId]
                ) {
                    regionIds[location.fieldOperationsRegionId] = true;
                    regions.push(regionsIndexed[location.fieldOperationsRegionId]);
                }
            });
        }

        return Region.sort(regions);
    }

    /**
     * Get locations the current user has access to.
     *
     * @returns {array}
     */
    static getFilteredLocations(user, userprofileLocationsIndexed, locationsIndexed) {
        let locations = [];

        if (Security.hasPermission("user.viewAllLocations") === true) {
            locations = Object.values(locationsIndexed);
        } else {
            const userProfileLocations = UserProfileLocation.read(
                userprofileLocationsIndexed,
                [
                    {
                        key: "userprofileId",
                        value: user.id
                    }
                ]
            );

            const locationIds = {};
            userProfileLocations.forEach(userProfileLocation => {
                const location = locationsIndexed[userProfileLocation.locationsId];
                if (!locationIds[location.id]) {
                    locationIds[location.id] = true;
                    locations.push(location);
                }
            });
        }

        return Location.sort(locations);
    }

    /**
     * Get users the current user has access to.
     *
     * @returns {array}
     */
    static getFilteredUsers(user, userprofileLocationsIndexed, locationsIndexed, usersIndexed, salesPcrLinksIndexed) {
        let users = [];

        if (Security.hasPermission("user.viewAllUsers") === true) {
            users = this.read(
                usersIndexed,
                [
                    {
                        key: "active",
                        value: true
                    }
                ]
            );
        } else {
            let userIds = [];

            // Add current user
            userIds = userIds.concat([user.id]);

            // Add anyone the current user is a PCR for
            userIds = userIds.concat(this.read(
                salesPcrLinksIndexed,
                [
                    {
                        key: "patientCareRepId",
                        value: user.id
                    }
                ]
            ).map(salesPcrLink => salesPcrLink.salesRepId));

            // If the current user is an office manager or area manager, add
            // users from those locations.
            if (Security.hasPermission("user.viewAllUsersForMyLocations")) {
                // Get all of this user's locations
                const locations = this.getFilteredLocations(
                    user,
                    userprofileLocationsIndexed,
                    locationsIndexed
                );

                // Get all of the users for all of those locations
                userIds = userIds.concat(UserProfileLocation.read(
                    userprofileLocationsIndexed,
                    [
                        {
                            key: "locationsId",
                            value: locations.map(location => location.id)
                        }
                    ]
                ).map(userprofileLocation => userprofileLocation.userprofileId));
            }

            // Get the users
            users = this.read(
                usersIndexed,
                [
                    {
                        key: "id",
                        value: userIds
                    }, {
                        key: "active",
                        value: true
                    }
                ]
            );
        }

        return this.sort(users);
    }
}
