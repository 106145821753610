import Admin from "../Admin";
import React from "react";
import CardAdminQueueGeneral from "../../../Component/Card/Admin/Queue/CardAdminQueueGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import QueueService from "../../../Seating/Security/QueueService/queueService";
import Validator from "../../../Utility/Validator";
import Queue from "../../../Utility/Crud/Queue";
import CardAdminQueueFilterOrderActivityOrderStatusReason from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterOrderActivityOrderStatusReason";
import CardAdminQueueFilterOrderActivityInsuranceType from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterOrderActivityInsuranceType";
import CardAdminQueueFilterOrderActivityMarketingCampaign from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterOrderActivityMarketingCampaign";
import CardAdminQueueFilterOrderActivityType from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterOrderActivityType";
import CardAdminQueueFilterOrderActivityPrimaryProductSubType from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterOrderActivityPrimaryProductSubType";
import CardAdminQueueFilterOrderActivityOrderStatus from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterOrderActivityOrderStatus";
import CardAdminQueueFilterPatientIssueIssueCategory from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterPatientIssueIssueCategory";
import CardAdminQueueFilterPatientIssueIssueStatus from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterPatientIssueIssueStatus";
import CardAdminQueueFilterPatientIssueIssueReason from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterPatientIssueIssueReason";
import CardAdminQueueFilterPendingPreauthCompleted from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterPendingPreauthCompleted";
import CardAdminQueueFilterBrightreeInvoiceBrightreeInvoiceStatusTerm from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterBrightreeInvoiceBrightreeInvoiceStatusTerm";
import CardAdminQueueThresholds from "../../../Component/Card/Admin/Queue/CardAdminQueueThresholds";
import CardAdminQueueColumns from "../../../Component/Card/Admin/Queue/CardAdminQueueColumns";
import CardAdminQueueSorts from "../../../Component/Card/Admin/Queue/CardAdminQueueSorts";
import CardAdminQueueGroups from "../../../Component/Card/Admin/Queue/CardAdminQueueGroups";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import CardAdminQueueFilterBrightreeInvoiceDate from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterBrightreeInvoiceDate";
import CardAdminQueueFilterBrightreeInvoiceFollowupDate from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterBrightreeInvoiceFollowupDate";
import CardAdminQueueFilterBrightreeInvoiceBalance from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterBrightreeInvoiceBalance";
import CardAdminQueueFilterBrightreeInvoiceWorkedAt from "../../../Component/Card/Admin/Queue/CardAdminQueueFilterBrightreeInvoiceWorkedAt";

export default class AdminQueueCreateEdit extends Admin {
    constructor(props) {
        super(props);

        // TODO: This is temporary just to have something here. We can fix this
        // properly when we do the view configuration.
        this.state.queue = {
            view: {
                columns: [
                    "order_activity.id"
                ]
            }
        };
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Set the queue in the state.
     */
    afterComponentDidMount() {
        const queueId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (queueId !== null) {
            this.setState({
                queue: this.context.queuesIndexed[queueId]
            });
        }
    }

    /**
     * Creates the queue with the current properties in state.queue.
     */
    async createQueue() {
        this.setState({
            isProcessing: true,
        });

        return QueueService.createQueue(this.state.queue)
            .then(queue => {
                this.context.updateIndexed("queuesIndexed", queue);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Queue created successfully!", {variant: "success"});
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Queue", {variant: "error"});
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the queue with the current properties in state.queue.
     */
    async updateQueue() {
        this.setState({
            isProcessing: true,
        });

        return QueueService.updateQueue(this.state.queue)
            .then(queue => {
                this.context.updateIndexed("queuesIndexed", queue);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Queue updated successfully!", {variant: "success"});
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Queue", {variant: "error"});
                this.setState({
                    isProcessing: false,
                });
            });
    }

    async deleteQueue() {
        this.setState({
            isProcessing: true,
        });

        return QueueService.deleteQueue(this.state.queue.id)
            .then(() => {
                this.context.deleteIndexed("queuesIndexed", this.state.queue.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Queue deleted successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Queue", {variant: "error"});
                this.setState({
                    confirmDialogOpen: false,
                });
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (queue) => {
            this.setState((prevState) => ({
                queue: {
                    ...prevState.queue,
                    ...queue,
                    filter: queue.hasOwnProperty("table") ? null : queue.hasOwnProperty("filter") ? queue.filter : prevState.queue.filter,
                },
            }));
        };

        return (
            <>
                <CardAdminQueueGeneral
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                {this.state.queue && (this.state.queue.table === "order_activity" || this.state.queue.table === "order_activity.sales") && (<CardAdminQueueFilterOrderActivityType
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && (this.state.queue.table === "order_activity" || this.state.queue.table === "order_activity.sales") && (<CardAdminQueueFilterOrderActivityOrderStatus
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && (this.state.queue.table === "order_activity" || this.state.queue.table === "order_activity.sales") && (<CardAdminQueueFilterOrderActivityOrderStatusReason
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && (this.state.queue.table === "order_activity" || this.state.queue.table === "order_activity.sales") && (<CardAdminQueueFilterOrderActivityInsuranceType
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && (this.state.queue.table === "order_activity" || this.state.queue.table === "order_activity.sales") && (<CardAdminQueueFilterOrderActivityPrimaryProductSubType
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && (this.state.queue.table === "order_activity" || this.state.queue.table === "order_activity.sales") && (<CardAdminQueueFilterOrderActivityMarketingCampaign
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "patient_issue" && (<CardAdminQueueFilterPatientIssueIssueCategory
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "patient_issue" && (<CardAdminQueueFilterPatientIssueIssueReason
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "patient_issue" && (<CardAdminQueueFilterPatientIssueIssueStatus
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "pending_preauth" && (<CardAdminQueueFilterPendingPreauthCompleted
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "brightree_invoice" && (<CardAdminQueueFilterBrightreeInvoiceBrightreeInvoiceStatusTerm
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "brightree_invoice" && (<CardAdminQueueFilterBrightreeInvoiceBalance
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "brightree_invoice" && (<CardAdminQueueFilterBrightreeInvoiceFollowupDate
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "brightree_invoice" && (<CardAdminQueueFilterBrightreeInvoiceDate
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                {this.state.queue && this.state.queue.table === "brightree_invoice" && (<CardAdminQueueFilterBrightreeInvoiceWorkedAt
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                />)}
                <CardAdminQueueThresholds
                    queue={this.state.queue}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminQueueColumns
                    queue={this.state.queue}
                />
                <CardAdminQueueSorts
                    queue={this.state.queue}
                />
                <CardAdminQueueGroups
                    queue={this.state.queue}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteQueue()}
                    text="Are you sure you want to delete this Queue? This cannot be undone."
                    header="Delete Queue"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Renders the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = "Create Queue";
            subtitle = null;
        } else {
            title = "Edit Queue";
            subtitle = (this.state.queue && (Queue.getDisplayName(this.state.queue) + " • " + this.state.queue.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.queue,
                {
                    name: {
                        required: true
                    },
                    queueTypeTermId: {
                        required: true
                    },
                    thresholdSize: {
                        type: "integer"
                    },
                    thresholdAgeHours: {
                        type: "integer"
                    },
                    ordinal: {
                        type: "integer"
                    }
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateQueue();
                } else {
                    this.createQueue();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues", {variant: "error"});
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
