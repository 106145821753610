import React from "react";
import {MDBBtn, MDBCol, MDBCollapseHeader, MDBCollapse, MDBRow, MDBCard, MDBCardBody, MDBSpinner, MDBCardHeader, MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import AccountService from "../../Seating/Security/AccountService/accountService";
import LayerLegacy from "../../LayerLegacy";

export default class MonthGlanceView extends LayerLegacy {
    constructor(props) {
        super(props);

        const userData = {
            columns: [
                {
                    label: 'Name',
                    field: 'name'
                },
                {
                    label: 'Approvals Day',
                    field: 'approvalsDay'
                },
                {
                    label: 'Approvals Month',
                    field: 'approvalsMonth'
                },
                {
                    label: 'Points Day',
                    field: 'pointsDay'
                },
                {
                    label: 'Points Month',
                    field: 'pointsMonth'
                },
                {
                    label: 'Standard Calls',
                    field: 'standardCalls'
                },
                {
                    label: 'Marketing Calls',
                    field: 'marketingCalls'
                },
                {
                    label: 'Approved Expenses',
                    field: 'approvedExpenses'
                },
                {
                    label: 'Not Approved Expenses',
                    field: 'unapprovedExpenses'
                },
            ],
            rows: []
        };

        this.state = {
            isLoaded: false,
            usersLoaded: true,
            currentMonth: new Date().toLocaleString('default', {month: 'short', year: 'numeric'}),
            usersGrid: [],
            locationsGrid: [],
            byRep: false,
            grandTotals:{},
            dataTemplate: userData,
            users: [],
            allUsers: [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allActiveUsers: PropTypes.array,
        allUsers: PropTypes.array,
    };


    componentDidMount() {
        this.determineAnalytics();
        this.getAllUsers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { allUsers } = this.context;
	 	let st = this.state;
	 	if (st.allUsers.length !== allUsers.length) {
            this.getAllUsers();
	 	}
    }

    determineAnalytics() {
        const { currentUser } = this.context;
        let r = currentUser.role;

        if (currentUser.id) {
            if (['ADMIN','SALES_MANAGER','MARKETING_MANAGER','EXECUTIVE_MANAGEMENT','AREA_MANAGER', 'SALES_LIAISON_MANAGER'].includes(r)) {
                this.getAllLocationAnalytics(currentUser.id);
            } else {
                this.setState({
                    byRep: true
                });

                this.getRepAnalytics(currentUser.id);
            }
        }
    }

    getAllUsers(locationId) {
        const { allUsers } = this.context;
        let v = allUsers.filter(x => x.userprofileLocations.map(y => y.locationsId).includes(locationId));
        this.setState({
            users: v,
            allUsers: allUsers,
        });
    }

    getAllLocationAnalytics(id) {
        let m = (new Date().getMonth() + 1),
            y = new Date().getFullYear();

        AccountService.getLocationsStatisticsMonth(id, m, y).then(d => {
            this.setState({
                analytics: d
            });

            this.sortAnalytics(d);
        });
    }

    getAllLocationAnalyticsForReps(locationId) {
        let m = (new Date().getMonth() + 1),
            y = new Date().getFullYear();

        this.setState({
            usersLoaded: false
        });

        AccountService.getRepsForLocationsMonth(locationId, m, y).then(d => {
            this.sortAnalyticsRep(d, locationId);
        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    [locationId]: null,
                    usersLoaded: true,
                });
            });

    }

    getRepAnalytics(userId) {
        AccountService.getRepAnalytics(userId, "M").then(d => {
            this.sortAnalyticsRep(d);
        }).catch(err => {
            console.log(err);
        });
    }

    sortAnalytics(vals) {
        const { currentUser } = this.context;

        let locationsGrid = [],
            locationIds = [],
            at = 0,
            dat = 0,
            pt = 0,
            dpt = 0,
            rtd = 0,
            drtd = 0,
            rvfu = 0,
            drvfu = 0,
            gt = {};

        let userLocations = currentUser.userprofileLocations.map(x => x.locations).sort((a, b) => a.name > b.name ? 1 : -1);

        vals.forEach(val => {
            let currLoc = userLocations.find(p => {
                return p.id === val.spLocationId;
            });

            if (!currLoc) {
                //if the user doesn't have this location, skip it...BC
                return;
            }

            let idx = locationIds.indexOf(val.spLocationId),
                obj = {
                    locationId: val.spLocationId,
                    locationName: currLoc.name
                };

            if (idx > -1) {
                obj = locationsGrid[idx];
            } else {
                locationIds.push(val.spLocationId);
                locationsGrid.push(obj);
            }

            obj[val.spEntryType] = val;

            switch (val.spEntryType) {
                case 'approval':
                    at += parseFloat(val.spValue);
                    break;
                case 'dailyApproval':
                    dat += parseFloat(val.spValue);
                    break;
                case 'points':
                    pt += parseFloat(val.spValue);
                    break;
                case 'dailyPoints':
                    dpt += parseFloat(val.spValue);
                    break;
                case 'RTD':
                    rtd += parseFloat(val.spValue);
                    break;
                case 'dailyRTD':
                    drtd += parseFloat(val.spValue);
                    break;
                case 'revFollowUP':
                    rvfu += parseFloat(val.spValue);
                    break;
                case 'dailyRevFollowUp':
                    drvfu += parseFloat(val.spValue);
                    break;

                default:
                    break;
            }

            gt = {
                monthlyApprovalsTotal: at ? at.toFixed(2) : 0,
                dailyApprovalsTotal: dat ? dat.toFixed(2) : 0,
                monthlyPointsTotal: pt ? pt.toFixed(2) : 0,
                dailyPointsTotal: dpt ? dpt.toFixed(2) : 0,
                monthlyRTDTotal: rtd ? rtd.toFixed(2) : 0,
                dailyRTDTotal: drtd ? drtd.toFixed(2) : 0,
                monthlyReviewTotal: rvfu ? rvfu.toFixed(2) : 0,
                dailyReviewTotal: drvfu ? drvfu.toFixed(2) : 0,
            };
        });
        locationsGrid = locationsGrid.sort((a, b) => a.locationName > b.locationName ? 1 : -1);

        this.setState({
            locationsGrid: locationsGrid,
            isLoaded: true,
            grandTotals: gt

        });

    }

    sortAnalyticsRep(vals, locationId) {
        const { allUsers, currentUser } = this.context;

        let UsersGrid = [],
            UserIds = [];

        vals.forEach(val => {
            let currUser = allUsers.find(p => {
                return parseInt(p.id) === val.spLocationId;
            });

            if (!currUser) {
                if (this.state.byRep) {
                    currUser = currentUser;
                } else {
                    //if the user is not active, skip it...BC
                    return;
                    //}
                    //else {
                    //    //if the user is not active, skip it...BC
                    //    return;
                    //}
                }
            }

            if (currUser) {
                let idx = UserIds.indexOf(val.spLocationId),
                    obj = {
                        userId: val.spLocationId,
                        userName: currUser.firstname + ' ' + currUser.lastname
                    };

                if (idx > -1) {
                    obj = UsersGrid[idx];
                } else {
                    UserIds.push(val.spLocationId);
                    UsersGrid.push(obj);
                }

                obj[val.spEntryType] = val;
            }
            else {
                console.log('Could not locate user: ' + val.spLocationId);
            }
        });

        this.setState({
            [locationId]: UsersGrid,
            usersLoaded: true,
            isLoaded: true,
        });

    }

    renderTotals() {
        let val = this.state.grandTotals;

        return (
            <MDBRow style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <MDBCol style={{ width: '20%' }}>
                    All Locations
                </MDBCol>

                <MDBCol style={{ width: '10%' }}>
                    {val.dailyApprovalsTotal || 0}
                </MDBCol>
                <MDBCol style={{ width: '10%' }}>
                    {val.monthlyApprovalsTotal || 0}
                </MDBCol>

                <MDBCol style={{ width: '10%' }}>
                    {val.dailyReviewTotal || 0}
                </MDBCol>
                <MDBCol style={{ width: '10%' }}>
                    {val.monthlyReviewTotal || 0}
                </MDBCol>

                <MDBCol style={{ width: '10%' }}>
                    {val.dailyRTDTotal || 0}
                </MDBCol>
                <MDBCol style={{ width: '10%' }}>
                    {val.monthlyRTDTotal || 0}
                </MDBCol>

                <MDBCol style={{ width: '10%' }}>
                    {val.dailyPointsTotal || 0}
                </MDBCol>
                <MDBCol style={{ width: '10%' }}>
                    {val.monthlyPointsTotal || 0}
                </MDBCol>

            </MDBRow>);
    }

    renderCards() {
        const { allUsers } = this.context;
        let vals = this.state.locationsGrid;

        function renderPoints(val) {
            if (val.points) {

                let dp = val.dailyPoints ? val.dailyPoints.spValue : 0;
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Setup Pts</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{dp}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.points.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                );
            }
        }

        function renderApprovals(val) {
            if (val.approval) {
                let da = val.dailyApproval ? val.dailyApproval.spValue : 0;

                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Approvals</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{da}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.approval.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                );
            }
        }

        function renderReview(val) {
            if (val.revFollowUP) {
                let dp = val.dailyRevFollowUp ? val.dailyRevFollowUp.spValue : 0;
                let v = val.revFollowUP ? val.revFollowUP.spValue : 0;
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>R/F</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{dp}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{v}</span>
                        </MDBCol>
                    </MDBRow>
                );
            }
        }

        function renderRTD(val) {
            if (val.RTD) {

                let dp = val.dailyRTD ? val.dailyRTD.spValue : 0;
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>RTD Pts</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{dp}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.RTD.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                );
            }
        }

        return (
            vals.map((v, idx) => {
                return (
                    <MDBCol key={idx} style={{paddingBottom: 30}}>
                        <MDBBtn block className={"monthGlanceCard"} color={"white"}
                            onClick={() => {
                                if (!allUsers || allUsers.length === 0) {
                                    this.getAllUsers(v.locationId);
                                }
                                this.getAllLocationAnalyticsForReps(v.locationId);
                            }}>
                            <div>
                                <MDBRow style={{height: 25}}>
                                    <MDBCol>
                                        <span className={"monthGlanceCardRowTitle"}>{v.locationName}</span>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow style={{height: 20}}>
                                    <MDBCol/>
                                    <MDBCol>
                                        <span className={"monthGlanceCardRowTitleHeaders"}>Day</span>
                                    </MDBCol>
                                    <MDBCol>
                                        <span className={"monthGlanceCardRowTitleHeaders"}>Month</span>
                                    </MDBCol>
                                </MDBRow>
                                {renderApprovals(v)}
                                {renderReview(v)}
                                {renderRTD(v)}
                                {renderPoints(v)}
                            </div>
                        </MDBBtn>
                    </MDBCol>
                );
            })
        );
    }

    renderCardsReps() {
        let vals = this.state.usersGrid;

        function renderDayMonth(val) {
            if (val.points || val.approval) {
                return (
                    <MDBRow style={{height: 20}}>
                        <MDBCol/>
                        <MDBCol>
                            <span style={{
                                fontSize: 12,
                                textAlign: 'center',
                                textDecorationLine: 'underline',
                                color: 'white'
                            }}>Day</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{
                                fontSize: 12,
                                textAlign: 'center',
                                textDecorationLine: 'underline',
                                color: 'white'
                            }}>Month</span>
                        </MDBCol>
                    </MDBRow>
                );
            }
        }

        function renderPoints(val) {
            if (val.points) {

                let dp = val.dailyPoints ? val.dailyPoints.spValue : 0;

                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Points</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{dp}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.points.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                );
            }
        }

        function renderApprovals(val) {
            if (val.approval) {
                let da = val.dailyApproval ? val.dailyApproval.spValue : 0;

                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Approvals</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{da}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.approval.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                );
            }
        }

        function renderExpenses(val) {
            let app = val.approvedExpenses ? val.approvedExpenses.spValue : 0,
                uap = val.unapprovedExpenses ? val.unapprovedExpenses.spValue : 0;

            if (app || uap) {
                return (
                    <div>
                        <MDBRow style={{height: 30, paddingTop: 10}}>
                            <MDBCol/>
                            <MDBCol>
                                <span style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    textDecorationLine: 'underline',
                                    color: 'white'
                                }}>Approved</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    textDecorationLine: 'underline',
                                    color: 'white'
                                }}>Not Approved</span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"monthGlanceCardRow"}>
                            <MDBCol>
                                <span>Expenses</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{textAlign: 'center'}}>{app}</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{textAlign: 'center'}}>{uap}</span>
                            </MDBCol>
                        </MDBRow>
                    </div>
                );
            }
        }

        function renderCalls(val) {
            let cs = val.callsS ? val.callsS.spValue : 0,
                cq = val.callsQ ? val.callsQ.spValue : 0;

            if (cs || cq) {
                return (
                    <div>
                        <MDBRow style={{height: 30, paddingTop: 10}}>
                            <MDBCol/>
                            <MDBCol>
                                <span style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    textDecorationLine: 'underline',
                                    color: 'white'
                                }}>Standard</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    textDecorationLine: 'underline',
                                    color: 'white'
                                }}>Marketing</span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"monthGlanceCardRow"}>
                            <MDBCol>
                                <span>Calls</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{textAlign: 'center'}}>{cs}</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{textAlign: 'center'}}>{cq}</span>
                            </MDBCol>
                        </MDBRow>
                    </div>
                );
            }
        }

        //onPress={() => this._routeToAppointments(v.userName, v.userId)}

        if (!this.state.usersLoaded) {
            return (<MDBSpinner/>);
        }

        return (
            vals.map((v, idx) => {
                return (
                    <MDBCol key={idx} style={{paddingBottom: 30}}>
                        <MDBBtn block className={"monthGlanceCardRep"} color={"grey"}>
                            <div>
                                <MDBRow style={{height: 25}}>
                                    <MDBCol>
                                        <span style={{
                                            textAlign: 'center',
                                            fontSize: 20,
                                            textDecorationLine: 'underline',
                                            paddingBottom: 4,
                                            color: 'white'
                                        }}>{v.userName}</span>
                                    </MDBCol>
                                </MDBRow>
                                {renderDayMonth(v)}
                                {renderApprovals(v)}
                                {renderPoints(v)}
                                {renderCalls(v)}
                                {renderExpenses(v)}
                            </div>
                        </MDBBtn>
                    </MDBCol>
                );
            })
        );
    }

    renderRepRows(locationId) {
        if(this.state[locationId] == null)
            return <MDBSpinner />;

        if(this.state[locationId].length === 0)
            return (<tr>No Results to Display</tr>);

        var dt = this.state.dataTemplate,
            rows = [];
        this.state[locationId].map(val => {
            return rows.push({
                name: val.userName,
                approvalsDay: val.dailyApproval ? val.dailyApproval.spValue : 0,
                approvalsMonth: val.approval ? val.approval.spValue : 0,
                pointsDay: val.dailyPoints ? val.dailyPoints.spValue : 0,
                pointsMonth: val.points ? val.points.spValue : 0,
                standardCalls: val.callsS ? val.callsS.spValue : 0,
                marketingCalls: val.callsQ ? val.callsQ.spValue : 0,
                approvedExpenses: val.approvedExpenses ? val.approvedExpenses.spValue : 0,
                unapprovedExpenses: val.unapprovedExpenses ? val.unapprovedExpenses.spValue : 0,
            });
        });
        dt.rows = rows;
        return (<MDBDataTable data={dt}/>);
    }

    renderTable(){
        let t = this,
            data = this.state.locationsGrid;

        function flipCollapse(c) {
            let val = t.state[c.locationName];

            if (val != null) {
                val = !val;
            } else {
                val = true;
            }

            if (val) {
                t.getAllUsers(c.locationId);
                t.getAllLocationAnalyticsForReps(c.locationId);
            }
            t.setState({
                [c.locationName]: val
            });
        }

        return (data.map((x,idx) => {
            return <div key={idx}>
                <MDBCollapseHeader
                    className={"patientStatusHeaders"}
                    onClick={() => flipCollapse(x)}>
                    <MDBRow>
                        <MDBCol style={{width: '20%'}}>
                            {x.locationName}
                        </MDBCol>

                        <MDBCol style={{width: '10%'}}>
                            {x.dailyApproval ? x.dailyApproval.spValue : 0}
                        </MDBCol>
                        <MDBCol style={{width: '10%'}}>
                            {x.approval ? x.approval.spValue : 0}
                        </MDBCol>

                        <MDBCol style={{width: '10%'}}>
                            {x.dailyRevFollowUp ? x.dailyRevFollowUp.spValue : 0}
                        </MDBCol>
                        <MDBCol style={{width: '10%'}}>
                            {x.revFollowUP ? x.revFollowUP.spValue : 0}
                        </MDBCol>

                        <MDBCol style={{width: '10%'}}>
                            {x.dailyRTD ? x.dailyRTD.spValue : 0}
                        </MDBCol>
                        <MDBCol style={{width: '10%'}}>
                            {x.RTD ? x.RTD.spValue : 0}
                        </MDBCol>

                        <MDBCol style={{width: '10%'}}>
                            {x.dailyPoints ? x.dailyPoints.spValue : 0}
                        </MDBCol>
                        <MDBCol style={{width: '10%'}}>
                            {x.points ? x.points.spValue : 0}
                        </MDBCol>
                    </MDBRow>
                </MDBCollapseHeader>
                <MDBCollapse style={{paddingBottom: 20}} isOpen={this.state[x.locationName]}>
                    <MDBRow style={{
                        justifyContent: 'flex-end',
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 10,
                        borderBottom: "1px solid #999"
                    }}>
                        {/* <table striped>
                            <thead>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Approvals Day
                                </th>
                                <th>
                                    Approvals Month
                                </th>
                                <th>
                                    Points Day
                                </th>
                                <th>
                                    Points Month
                                </th>
                                <th>
                                    Standard Calls
                                </th>
                                <th>
                                    Marketing Calls
                                </th>
                                <th>
                                    Approved Expenses
                                </th>
                                <th>
                                    Not Approved Expenses
                                </th>
                            </thead> */}
                        <MDBCol size={"12"}>
                            {this.renderRepRows(x.locationId)}
                        </MDBCol>
                        {/* </table> */}
                    </MDBRow>

                </MDBCollapse>
            </div>;
        }));
    }

    renderContent() {
        if (!this.state.isLoaded) {
            return (<MDBSpinner/>);
        }

        return (
            <div style={{padding: 30}}>
                <MDBRow>
                    <MDBCol size={"12"}>
                        <MDBCard>
                            <MDBCardHeader>
                                <MDBRow style={{paddingRight: '10px',paddingLeft: '10px'}}>
                                    <MDBCol style={{width: '20%'}}>
                                        Location
                                    </MDBCol>

                                    <MDBCol style={{width: '10%'}}>
                                        Approvals Day
                                    </MDBCol>
                                    <MDBCol style={{width: '10%'}}>
                                        Approvals Month
                                    </MDBCol>

                                    <MDBCol style={{width: '10%'}}>
                                        Review Day
                                    </MDBCol>
                                    <MDBCol style={{width: '10%'}}>
                                        Review Month
                                    </MDBCol>

                                    <MDBCol style={{width: '10%'}}>
                                        RTD Day
                                    </MDBCol>
                                    <MDBCol style={{width: '10%'}}>
                                        RTD Month
                                    </MDBCol>

                                    <MDBCol style={{width: '10%'}}>
                                        Setup Pts Day
                                    </MDBCol>
                                    <MDBCol style={{width: '10%'}}>
                                        Setup Pts Month
                                    </MDBCol>

                                </MDBRow>
                            </MDBCardHeader>
                            <MDBCardBody>
                                {this.renderTotals()}
                                {this.renderTable()}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    {/* <MDBCol size={6}>
                        {this.renderCards()}
                    </MDBCol>
                    <MDBCol size={6}>
                        {this.renderCardsReps()}
                    </MDBCol> */}
                </MDBRow>
            </div>
        );
    }


}

