import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import TileUser from "../../../Tile/TileUser";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import DialogSearchUser from "../../../Dialog/Search/DialogSearchUser";
export default class CardAdminRegionManagement extends Card {
    constructor(props) {
        super(props);

        this.state.directorDialogOpen = false;
    }

    static contextTypes = {
        usersIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        let region = this.props.region;
        let directorUser = null;

        if (region && region.directorUserprofileId !== null) {
            directorUser = this.context.usersIndexed[region.directorUserprofileId];
        }

        // Director
        const handleEditDirector = () => {
            this.setState({ directorDialogOpen: true });
        };
        const handleClearDirector = () => {
            this.props.onChange({ directorUserprofileId: null });
        };
        const directorIconButtons = [
            (<MUIIconButton onClick={handleEditDirector}><MUIEditIcon /></MUIIconButton>)
        ];

        if (directorUser) {
            directorIconButtons.push(
                (<MUIIconButton onClick={handleClearDirector}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Render
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileUser
                            user={directorUser}
                            iconButtons={directorIconButtons}
                            label="Director"
                        />
                    </MUIGrid>
                </MUIGrid>
                <DialogSearchUser
                    open={this.state.directorDialogOpen}
                    onClose={() => this.setState({ directorDialogOpen: false })}
                    onSelect={(user) => {
                        this.props.onChange({ directorUserprofileId: user.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Management";
    }
}