import api from "../api";

class InsuranceService {
    async findAllInsurance() {
        return new Promise((res, reject) => {
            return api
                .get("/insurance")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findAllSubInsurance() {
        return new Promise((res, reject) => {
            return api
                .get("/insuranceSubTypes")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateSubInsurance(insurance) {
        return new Promise((res, reject) => {
            api
                .put("/insuranceSubTypes", insurance)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateInsurance(insurance) {
        return new Promise((res, reject) => {
            api
                .put("/insurance", insurance)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createInsurance(insurance) {
        return new Promise((res, reject) => {
            api
                .post("/insurance", insurance)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createSubInsurance(insurance) {
        return new Promise((res, reject) => {
            api
                .post("/insuranceSubTypes", insurance)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteInsurance(id) {
        return new Promise((res, reject) => {
            api
                .delete("/insurance/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteInsuranceSubType(id) {
        return new Promise((res, reject) => {
            api
                .delete("/insuranceSubTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createSubInsuranceDetails(insurance) {
        return new Promise((res, reject) => {
            api
                .post("/insuranceSubTypeDetails", insurance)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findAllSubInsuranceDetails() {
        return new Promise((res, reject) => {
            return api
                .get("/insuranceSubTypeDetails")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findAllSubInsuranceDetailsById(id) {
        return new Promise((res, reject) => {
            return api
                .get("/insuranceSubTypeDetails/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findAllSubInsuranceDetailsBySubTypeId(id) {
        return new Promise((res, reject) => {
            return api
                .get("/insuranceSubTypeDetailsSID/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateSubInsuranceDetails(insurance) {
        return new Promise((res, reject) => {
            api
                .put("/insuranceSubTypeDetails", insurance)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async submitForVerification(orderId) {
        return new Promise((res, reject) => {
            api
                .get("/availityVerification/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findPatientVerification(patientId) {
        return new Promise((res, reject) => {
            api
                .get("/patientVerification/" + patientId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async refreshCoverage(orderId) {
        return new Promise((res, reject) => {
            api
                .post("/refreshCoverage/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async refreshAvailityCoverage(orderId) {
        return new Promise((res, reject) => {
            api
                .post("/refreshAvailityCoverage/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async refreshPverifyCoverage(orderId) {
        return new Promise((res, reject) => {
            api
                .post("/refreshPverifyCoverage/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async refreshSameSim(orderId) {
        return new Promise((res, reject) => {
            api
                .post("/refreshSameSim/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async refreshSNF(orderId) {
        return new Promise((res, reject) => {
            api
                .post("/refreshSNF/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async verifyPatient(patientInfo) {
        return new Promise((res, reject) => {
            api
                .post("/verifyByPatient", patientInfo)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatientVerifications(patientId) {
        return new Promise((res, reject) => {
            api
                .get("/patientVerificationList/" + patientId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }


}

const insuranceService = new InsuranceService();

export default insuranceService;
