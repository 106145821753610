import React from "react";
import Card from "../../../Card";
import PropTypes from "prop-types";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";

export default class CardAdminMarketingCampaignCategoryGeneral extends Card {
    static contextTypes = {
        marketingCampaignCategoriesIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <MUITextField
                            value={this.props.marketingCampaignCategory && this.props.marketingCampaignCategory.name ? this.props.marketingCampaignCategory.name : ""}
                            autoFocus={true}
                            size="small"
                            label="Name"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 255,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ name: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ name: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.name ? true : false}
                            helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6} md={8}>
                        <MUITextField
                            value={this.props.marketingCampaignCategory && this.props.marketingCampaignCategory.description ? this.props.marketingCampaignCategory.description : ""}
                            autoFocus={true}
                            size="small"
                            label="Description"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 255,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ description: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ description: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.description ? true : false}
                            helperText={this.props.validationIssues.description ? this.props.validationIssues.description : ""}
                        />
                    </MUIGrid>
                </MUIGrid>
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}