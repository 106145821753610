import React from "react";
import CardQueueDataGrid from "../CardQueueDataGrid";

import DialogConfirm from "../../../Dialog/DialogConfirm";
import User from "../../../../Utility/Crud/User";
import OrderService from "../../../../Seating/Security/OrderService/orderService";

import MUIBackdrop from '@mui/material/Backdrop';
import MUIBackspaceIcon from '@mui/icons-material/Backspace';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIMenuItem from "@mui/material/MenuItem";

export default class CardQueueDataGridPendingPreauth extends CardQueueDataGrid {
    columns = {
        "pending_preauth.id": {
            "type": "string",
            "name": "Issue #",
            "width": 110,
        },
        "pending_preauth.order_activity.patient._pediatric": {
            "type": "pediatric",
            "name": "Ped",
            "width": 70,
        },
        "pending_preauth.order_activity.activity.priority": {
            "type": "priority",
            "name": "Pri",
            "width": 70,
        },
        "pending_preauth.order_activity.currently_being_worked_by_id": {
            "type": "user",
            "name": "Open By",
            "width": 140,
        },
        "pending_preauth.order_activity.patient._name": {
            "type": "string",
            "name": "Patient",
            "width": 140,
        },
        "pending_preauth.order_activity.follow_up_date": {
            "type": "date",
            "name": "F/U Date",
            "width": 110,
        },
        "pending_preauth.order_activity.patient.phone": {
            "type": "phone",
            "name": "Phone #",
            "width": 120,
        },
        "pending_preauth.order_activity.sales_location_id": {
            "type": "location",
            "name": "Sales Location",
            "width": 140,
        },
        "pending_preauth.order_activity.insurance_sub_type.insurance_sub_type_group_term_id": {
            "type": "term",
            "name": "Pri Ins Group",
            "width": 160,
        },
        "pending_preauth.order_activity.insurance_sub_type_id": {
            "type": "insuranceSubType",
            "name": "Pri Payor",
            "width": 140,
        },
        "pending_preauth.order_activity.order_activity_products._sum_points": {
            "type": "points",
            "name": "Pts",
            "width": 70,
        },
        "pending_preauth.order_activity.order_activity_products._primary_product_type_id": {
            "type": "productType",
            "name": "Category",
            "width": 120,
        },
        "pending_preauth.completed": {
            "type": "boolean",
            "name": "Completed",
            "width": 110,
        },
        "pending_preauth.added_on": {
            "type": "date",
            "name": "Added On",
            "width": 110,
        },
        "pending_preauth.added_by": {
            "type": "user",
            "name": "Added By",
            "width": 140,
        },
        "pending_preauth.order_id": {
            "type": "string",
            "name": "Order #",
            "width": 110,
        },
        "pending_preauth._queue_age": {
            "type": "ageHours",
            "name": "Age",
            "width": 70,
        },
        "pending_preauth._queue_age_business": {
            "type": "ageBusinessHours",
            "name": "Age",
            "width": 70,
        },
        "pending_preauth._queue_age_days": {
            "source": "pending_preauth._queue_age",
            "type": "ageDays",
            "name": "Age",
            "width": 70,
        },
        "pending_preauth._queue_age_business_days": {
            "source": "pending_preauth._queue_age_business",
            "type": "ageBusinessDays",
            "name": "Age",
            "width": 70,
        },
        "pending_preauth.order_activity.patient.id": {
            "type": "string",
            "name": "Patient #",
            "width": 110,
        },
        "out_of_bounds": {
            "type": "boolean",
            "name": "OOB",
            "width": 80,
        },
        "pending_preauth.order_activity.activity.owner_id": {
            "type": "user",
            "name": "Owner",
            "width": 140,
        },
        "pending_preauth.order_activity.last_order_status_reason_id_change": {
            "type": "date",
            "name": "Last Routed",
            "width": 130,
        },
        "pending_preauth.order_activity.sales_location.region_id": {
            "type": "region",
            "name": "Region",
            "width": 90,
        },
    };

    constructor(props) {
        super(props);

        this.state.checkingOpenBy = false;
        this.state.clickedRowOpenBy = null;
    }

    /**
    * Render the backdrop and worked on dialog.
    */
    renderInternal() {
        return (
            <>
                {this.renderBackdrop()}
                {this.renderBeingWorkedOnDialog()}
            </>
        );
    }

    /**
     * Renders a backdrop when clicking on a row while it does the API call to
     * check if it's being worked so there's instant feedback.
     */
    renderBackdrop() {
        return (<MUIBackdrop open={this.state.checkingOpenBy}></MUIBackdrop>);
    }

    /**
    * Renders a dialog to confirm whether or not to open an order currently open
    * by someone else.
    */
    renderBeingWorkedOnDialog() {
        const userDisplayName = this.state.clickedRowOpenBy ?
            User.getDisplayName(this.state.clickedRowOpenBy) :
            "";

        return (
            <DialogConfirm
                open={this.state.clickedRowOpenBy !== null}
                onClose={() => this.setState({ clickedRowOpenBy: null })}
                onConfirm={() => {
                    this.openOrder(this.state.clickedRow);
                    this.setState({
                        clickedRowOpenBy: null,
                        clickedRow: null
                    });
                }}
                text={`Order is already being worked on by ${userDisplayName}. Open anyways?`}
            />
        );
    }

    /**
     * @return {array} Order activity actions.
     */
    getActionsInternal() {
        let actions = [];

        actions.push(
            <MUIMenuItem disabled={!this.props.rows || !this.hasOpenByMe()} value={"Clear Open By Me"} onClick={this.clearOpenByMe.bind(this)} >
                <MUIListItemIcon>
                    <MUIBackspaceIcon fontSize="small" />
                </MUIListItemIcon>
                Clear Open By Me
            </MUIMenuItem>
        );

        return actions;
    }


    /**
     * Handle clicking on a grid row.
     */
    handleClickRow(row) {
        this.setState({
            checkingOpenBy: true,
            clickedRow: row
        });

        if (this.props.onClickRow) {
            this.props.onClickRow();
        }

        OrderService.getCurrentlyWorkingByOrder(row["pending_preauth.order_id"])
            .then((res) => {
                if (res === 0 || res === this.context.currentUser.id) {
                // If the order is open by nobody or me, open it.
                    this.setState({
                        checkingOpenBy: false,
                        clickedRow: null
                    });
                    this.openOrder(row);
                } else {
                // If the order is being worked on by someone else, update
                // the state with that info.
                    this.setState({
                        checkingOpenBy: false,
                        clickedRowOpenBy: this.context.usersIndexed[res],
                    });
                }
            });
    }


    /**
     * @return {boolean} Whether or not there are any orders currently open by
     * me.
     */
    hasOpenByMe() {
        for (const row of this.props.rows) {
            if (row["pending_preauth.order_activity.currently_being_worked_by_id"] === this.context.currentUser.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Clear all the orders currently being worked on by me.
     */
    clearOpenByMe() {
        const promises = [];

        this.props.rows.forEach((row) => {
            if (row["pending_preauth.order_activity.currently_being_worked_by_id"] === this.context.currentUser.id) {
                promises.push(OrderService.clearBeingWorkedBy(row["pending_preauth.order_id"]));
            }
        });

        Promise.all(promises).then(() => {
            this.props.onReload();
        });
    }


    /**
     * Opens the order.
     *
     * @param {Object} row The grid row.
     */
    openOrder(row) {
        window.open(`/patientInformation/${row["pending_preauth.order_activity.patient.id"]}/${row["pending_preauth.order_id"]}`, "_blank");

        OrderService.setBeingWorkedBy(row["pending_preauth.order_id"]);
        this.setState({
            queueDirty: true,
        });
    }

}