import React from "react";
import Card from "../../../Card";
import EmptyState from "../../../EmptyState";
import MUIButton from "@mui/material/Button";
import MUIIconButton from "@mui/material/IconButton";
import MUIAddIcon from "@mui/icons-material/Add";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUIGrid from "@mui/material/Grid";
import DialogSearchIssueCategory from "../../../Dialog/Search/DialogSearchIssueCategory";
import TileIssueCategory from "../../../Tile/TileIssueCategory";
import PropTypes from "prop-types";

export default class CardAdminQueueFilterPatientIssueIssueCategory extends Card {
    static contextTypes = {
        issueCategoriesIndexed: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state.issueCategoryDialogOpen = false;
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleRemove = (issueCategoryId) => {
            const filter = this.props.queue.filter;
            filter["patient_issue.issue_category_id"].splice(
                filter["patient_issue.issue_category_id"].indexOf(issueCategoryId),
                1
            );

            this.props.onChange({
                "filter": filter
            });
        };

        if (
            this.props.queue &&
            this.props.queue.filter &&
            this.props.queue.filter["patient_issue.issue_category_id"] &&
            this.props.queue.filter["patient_issue.issue_category_id"].length > 0
        ) {
            return (
                <>
                    <MUIGrid container spacing={2}>
                        {this.props.queue.filter["patient_issue.issue_category_id"].map((issueCategoryId) => {
                            return (
                                <MUIGrid key={issueCategoryId} item xs={12} sm={6} md={4}>
                                    <TileIssueCategory
                                        issueCategory={this.context.issueCategoriesIndexed[issueCategoryId]}
                                        iconButtons={[(<MUIIconButton onClick={() => handleRemove(issueCategoryId)}><MUIDeleteIcon /></MUIIconButton>)]}
                                    />
                                </MUIGrid>
                            );
                        })}
                        {this.renderDialog()}
                    </MUIGrid>
                </>
            );
        } else {
            return (
                <>
                    <EmptyState
                        line1="Not Configured"
                        line2="Showing all Issue Categories"
                    />
                    {this.renderDialog()}
                </>
            );
        }
    }



    /**
     * Renders a search dialog of Issue Categories
     */
    renderDialog() {
        const handleAdd = (issueCategory) => {
            const filter = (this.props.queue && this.props.queue.filter) || {};
            if (!filter["patient_issue.issue_category_id"]) {
                filter["patient_issue.issue_category_id"] = [];
            }
            filter["patient_issue.issue_category_id"].push(issueCategory.id);
            filter["patient_issue.issue_category_id"] = [...new Set(filter["patient_issue.issue_category_id"])];

            this.props.onChange({
                "filter": filter
            });
        };

        return (
            <DialogSearchIssueCategory
                open={this.state.issueCategoryDialogOpen}
                onClose={() => this.setState({ issueCategoryDialogOpen: false })}
                onSelect={handleAdd}
            />
        );
    }

    /**
     * Renders add Issue Category button
     */
    getActions() {
        const handleAdd = () => {
            this.setState({
                issueCategoryDialogOpen: true,
            });
        };

        const buttonItems = [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIAddIcon />}
                onClick={handleAdd}>Add Issue Category</MUIButton>)
        ];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Filter: Patient Issue > Issue Category";
    }
}