import React from "react";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Term from "../../../../Utility/Crud/Term";
import MUIMenuItem from "@mui/material/MenuItem";
export default class CardAdminRegionGeneral extends Card {
    static contextTypes = {
        termsIndexed: PropTypes.object
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.region ? this.props.region.name : ""}
                        autoFocus={true}
                        size="small"
                        label="Name"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ name: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ name: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.name ? true : false}
                        helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        select={true}
                        value={this.props.region && this.props.region.regionTypeTermId ? this.props.region.regionTypeTermId : ""}
                        size="small"
                        label="Type"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.props.onChange({ regionTypeTermId: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.regionTypeTermId ? true : false}
                        helperText={this.props.validationIssues.regionTypeTermId ? this.props.validationIssues.regionTypeTermId : ""}
                    >
                        {
                            Term.sort(Term.read(this.context.termsIndexed, [{ key: "type", value: "region_type" }])).map((term) => {
                                return (<MUIMenuItem key={term.id} value={term.id}>{term.name}</MUIMenuItem>);
                            })
                        }
                    </MUITextField>
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}