import api from "../api";

class ActivityService {
    async getAllMyActivities(filters) {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 150,
                orders_only: false,
                activities_only: true,
                q: filters.q || "",
                users: filters.users || [],
            };

            if (filters.startDate) {
                params.startDate = filters.startDate;
            }

            if (filters.endDate) {
                params.endDate = filters.endDate;
            }

            api
                .get("/activitiesReact", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetActivities
    async getActivityListing(filters) {
        return new Promise((res, reject) => {
            api
                .get("/activities/search", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getActivity(id) {
        return new Promise((res, reject) => {
            api
                .get("/activities/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getCreditCardTypes() {
        return new Promise((res, reject) => {
            api
                .get("/creditCardTypes")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getExpenseTypes() {
        return new Promise((res, reject) => {
            api
                .get("/activeExpenseTypes")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getAllContacts() {
        return new Promise((res, reject) => {
            api
                .get("/contacts")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getByPolicyNumber(number) {
        return new Promise((res, reject) => {
            api
                .get("/orders/findByPolicyNumber/" + number.trim())
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateActivity(activity) {
        return new Promise((res, reject) => {
            api
                .put("/activities", activity)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createActivity(activity) {
        return api.post("/activities", activity);
    }

    async getActivitiesByAccount(id) {
        return new Promise((res, reject) => {
            let url = "/accounts/" + id + "/activities";

            return api
                .get(url)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const activityService = new ActivityService();

export default activityService;
