import React from "react";
import moment from "moment";

import MUIBackdrop from '@mui/material/Backdrop';
import MUIBackspaceIcon from '@mui/icons-material/Backspace';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIMenuItem from "@mui/material/MenuItem";

import DialogConfirm from "../../../Dialog/DialogConfirm";
import CardQueueDataGrid from "../CardQueueDataGrid";
import User from "../../../../Utility/Crud/User";

import OrderService from "../../../../Seating/Security/OrderService/orderService";
import NoteService from "../../../../Seating/Security/NoteService/noteService";
import IntakeQuestionnaireService from "../../../../Seating/Security/IntakeQuestionnaireService/intakeQuestionnaireService";

import ProductType from "../../../../Utility/Crud/ProductType";
import OrderStatusReason from "../../../../Utility/Crud/OrderStatusReason";

import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
export default class CardQueueDataGridOrderActivity extends CardQueueDataGrid {

    columns = {
        "order_activity.id": {
            type:"number",
            name: "Order #",
            width: 110,
        },
        "order_activity.order_status_reason_id": {
            type:"orderStatusReason",
            name: "Reason",
            width: 160,
        },
        "order_activity.order_type": {
            type:"string",
            name: "Order Type",
            width: 120,
        },
        "order_activity.status": {
            type:"orderStatus",
            name: "Status",
            width: 120,
        },
        "order_activity.follow_up_date": {
            type:"date",
            name: "F/U Date",
            width: 110,
        },
        "order_activity.setup_date": {
            type:"date",
            name: "Setup Date",
            width: 120,
        },
        "order_activity._setup_year_month": {
            source: "order_activity.setup_date",
            type:"dateMonthYear",
            name: "Setup Month",
            width: 130,
        },
        "order_activity.activity.started_at": {
            type:"date",
            name: "Started Date",
            width: 130,
        },
        "order_activity.activity.priority": {
            type:"priority",
            name: "Pri",
            width: 70,
        },
        "order_activity.patient._pediatric": {
            type:"pediatric",
            name: "Ped",
            width: 70,
        },
        "order_activity.activity._age": {
            type:"ageHours",
            name: "Age",
            width: 70,
        },
        "order_activity._queue_age": {
            type:"ageHours",
            name: "Age",
            width: 70,
        },
        "order_activity._queue_age_business": {
            type:"ageBusinessHours",
            name: "Age",
            width: 70,
        },
        "order_activity._queue_age_days": {
            source: "order_activity._queue_age",
            type:"ageDays",
            name: "Age",
            width: 70,
        },
        "order_activity._queue_age_business_days": {
            source: "order_activity._queue_age_business",
            type:"ageBusinessDays",
            name: "Age",
            width: 70,
        },
        "order_activity.currently_being_worked_by_id": {
            type:"user",
            name: "Open By",
            width: 140,
        },
        "order_activity.patient._name": {
            type:"string",
            name: "Patient",
            width: 140,
        },
        "order_activity.insurance_type_id": {
            type:"insuranceType",
            name: "Pri Ins Type",
            width: 140,
        },
        "order_activity.insurance_sub_type_id": {
            type:"insuranceSubType",
            name: "Pri Payor",
            width: 140,
        },
        "order_activity.secondary_insurance_type_id": {
            type:"insuranceType",
            name: "Sec Ins Type",
            width: 140,
        },
        "order_activity.secondary_insurance_sub_type_id": {
            type:"insuranceSubType",
            name: "Sec Ins Payor",
            width: 140,
        },
        "order_activity.sales_location_id": {
            type:"location",
            name: "Sales Location",
            width: 140,
        },
        "order_activity.order_activity_products._sum_points": {
            type:"points",
            name: "Pts",
            width: 70,
        },
        "order_activity.order_activity_products._primary_product_type_id": {
            type:"productType",
            name: "Category",
            width: 120,
        },
        "order_activity.order_activity_products._primary_product_sub_type_id": {
            type:"productSubType",
            name: "Product",
            width: 120,
        },
        "order_activity.order_activity_products._product_sub_type_ids": {
            type:"productSubTypes",
            name: "Products",
            width: 300,
        },
        "order_activity.sales_location.region_id": {
            type:"region",
            name: "Region",
            width: 90,
        },
        "order_activity.service_location.field_operations_region_id": {
            type:"region",
            name: "Region",
            width: 90,
        },
        "order_activity.patient_id": {
            type:"string",
            name: "Patient #",
            width: 120,
        },
        "order_activity.patient.address": {
            type:"string",
            name: "Address",
            width: 200,
        },
        "order_activity.patient.city": {
            type:"string",
            name: "City",
            width: 140,
        },
        "order_activity.patient.state": {
            type:"string",
            name: "State",
            width: 100,
        },
        "order_activity.technician_id": {
            type:"user",
            name: "Technician",
            width: 140,
        },
        "order_activity.scheduled_delivery_date": {
            type:"date",
            name: "Delivery",
            width: 110,
        },
        "order_activity.expected_ship_date": {
            type:"date",
            name: "Expected",
            width: 110,
        },
        "order_activity.approval_date": {
            type:"date",
            name: "Approval",
            width: 110,
        },
        "order_activity.equipment_ordered_date": {
            type:"date",
            name: "Ordered",
            width: 110,
        },
        "order_activity.activity.last_note_date": {
            type:"date",
            name: "Last Note",
            width: 110,
        },
        "order_activity.service_location_id": {
            type:"location",
            name: "Service Location",
            width: 150,
        },
        "order_activity.bt_so_id": {
            type:"string",
            name: "BT ID",
            width: 80,
        },
        "order_activity.insurance_sub_type.insurance_sub_type_group_term_id": {
            type:"term",
            name: "Pri Ins Group",
            width: 160,
        },
        "order_activity.sdfu_contact_logs._count": {
            type:"number",
            name: "# Attempts",
            width: 120,
        },
        "order_activity.sdfu_contact_logs._max_call_date": {
            type:"date",
            name: "Last Attempt",
            width: 130,
        },
        "order_activity.order_status_reason.order_status": {
            type:"orderStatus",
            name: "Order Status",
            width: 130,
        },
        "order_activity.patient.zip_code": {
            type:"string",
            name: "Postal Code",
            width: 130,
        },
        "order_activity.patient.phone": {
            type:"phone",
            name: "Phone #",
            width: 120,
        },
        "order_activity.service_quote_costs._sum_msrp": {
            type:"currency",
            name: "MSRP",
            width: 100,
        },
        "order_activity.service_quote_costs._sum_cost": {
            type:"currency",
            name: "Cost",
            width: 100,
        },
        "out_of_bounds": {
            type:"boolean",
            name: "OOB",
            width: 80,
        },
        "order_activity.activity.owner_id": {
            type:"user",
            name: "Owner",
            width: 140,
        },
        "order_activity.marketing_campaign_id": {
            type:"marketingCampaign",
            name: "Campaign",
            width: 140,
        },
        "order_activity.marketing_campaign.campaign_type_id": {
            type:"marketingCampaignCategory",
            name: "Category",
            width: 140,
        },
        "order_activity.insurance_group": {
            type:"string",
            name: "Insurance Group",
            width: 140,
        },
        "order_activity.insurance_policy": {
            type:"string",
            name: "Insurance Policy",
            width: 140,
        },
        "order_activity.secondary_insurance_group": {
            type:"string",
            name: "Secondary Insurance Group",
            width: 140,
        },
        "order_activity.secondary_insurance_policy": {
            type:"string",
            name: "Secondary Insurance Policy",
            width: 140,
        },
        "order_activity.current_intake_questionnaire_id": {
            type:"intakeQuestionnaire",
            name: "Intake Q",
            width: 140,
        },
        "order_activity.evaluation_date": {
            type:"datePicker",
            name: "Eval Date",
            width: 140,
        },
        "order_activity.face_to_face_date": {
            type:"datePicker",
            name: "F2F Date",
            width: 140,
        },
        "order_activity.atp_evaluation_date": {
            type:"datePicker",
            name: "ATP Date",
            width: 140,
        },
        "order_activity.insurance_verified": {
            type:"booleanCheck",
            name: "Ins Verified",
            width: 140,
        },
        "order_activity._error_date": {
            source: "order_activity.last_order_status_reason_id_change",
            type:"errorDate",
            name: "Error Date",
            width: 130,
        },
        "order_activity.last_order_status_reason_id_change": {
            type:"date",
            name: "Last Routed",
            width: 130,
        },
        "order_activity.atp_id": {
            type:"user",
            name: "ATP",
            width: 140,
        },
    };

    constructor(props) {
        super(props);

        this.state.checkingOpenBy = false;
        this.state.clickedRowOpenBy = null;
        this.state.intakeQuestionnaire = {
            meta: {
                version: moment("2024-10-27") || null,
            },
        };
        this.state.intakeQuestionnaireDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Render the backdrop and worked on dialog.
     */
    renderInternal() {
        return (
            <>
                {this.renderBackdrop()}
                {this.renderBeingWorkedOnDialog()}
            </>
        );
    }

    /**
     * Renders a backdrop when clicking on a row while it does the API call to
     * check if it's being worked so there's instant feedback.
     */
    renderBackdrop() {
        return (<MUIBackdrop open={this.state.checkingOpenBy}></MUIBackdrop>);
    }

    /**
     * Renders a dialog to confirm whether or not to open an order currently
     * open by someone else.
     */
    renderBeingWorkedOnDialog() {
        const userDisplayName = this.state.clickedRowOpenBy ?
            User.getDisplayName(this.state.clickedRowOpenBy) :
            "";

        return (
            <DialogConfirm
                open={this.state.clickedRowOpenBy !== null}
                onClose={() => this.setState({ clickedRowOpenBy: null })}
                onConfirm={() => {
                    this.openOrder(this.state.clickedRow);
                    this.setState({
                        clickedRowOpenBy: null,
                        clickedRow: null
                    });
                }}
                text={`Order is already being worked on by ${userDisplayName}. Open anyways?`}
            />
        );
    }

    /**
     * @return {array} Order activity actions.
     */
    getActionsInternal() {
        let actions = [];

        actions.push(
            <MUIMenuItem disabled={!this.props.rows || !this.hasOpenByMe()} value={"Clear Open By Me"} onClick={this.clearOpenByMe.bind(this)} >
                <MUIListItemIcon>
                    <MUIBackspaceIcon fontSize="small" />
                </MUIListItemIcon>
                Clear Open By Me
            </MUIMenuItem>
        );

        return actions;
    }

    /**
     * Handle clicking on a grid row.
     */
    handleClickRow(row) {
        this.setState({
            checkingOpenBy: true,
            clickedRow: row
        });

        if (this.props.onClickRow) {
            this.props.onClickRow();
        }

        OrderService.getCurrentlyWorkingByOrder(row["order_activity.id"])
        .then((res) => {
            if (res === 0 || res === this.context.currentUser.id) {
                // If the order is open by nobody or me, open it.
                this.setState({
                    checkingOpenBy: false,
                    clickedRow: null
                });
                this.openOrder(row);
            } else {
                // If the order is open by someone else, update the state with
                // that info.
                this.setState({
                    checkingOpenBy: false,
                    clickedRowOpenBy: this.context.usersIndexed[res],
                });
            }
        });
    }


    /**
     * @return {boolean} Whether or not there are any orders currently open by
     * me.
     */
    hasOpenByMe() {
        for (const row of this.props.rows) {
            if (row["order_activity.currently_being_worked_by_id"] === this.context.currentUser.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Clear all the orders currently being worked on by me.
     */
    clearOpenByMe() {
        const promises = [];

        this.props.rows.forEach((row) => {
            if (row["order_activity.currently_being_worked_by_id"] === this.context.currentUser.id) {
                promises.push(OrderService.clearBeingWorkedBy(row["order_activity.id"]));
            }
        });

        Promise.all(promises).then(() => {
            this.props.onReload();
        });
    }

    /**
     * Opens the order.
     *
     * @param {Object} row The grid row.
     */
    openOrder(row) {
        let orderActivityType = Object.values(this.context.orderActivityTypesIndexed).find((o) => o.name.toLowerCase() === row["order_activity.order_type"].toLowerCase());

        if (orderActivityType.activityType.toLowerCase() === "ticket") {
            window.open(
                `/patientInformation/${row["order_activity.patient_id"]}/${row["order_activity.id"]}`,
                "_blank"
            );
        } else {
            window.open(`/order/${row["order_activity.id"]}`, "_blank");
        }

        OrderService.setBeingWorkedBy(row["order_activity.id"]);

        this.setState({
            queueDirty: true,
        });
    }

    /**
     * Closes the intake questionnaire and clears all values.
     *
     */
    handleOnCloseIntakeQuestionnaireDialog() {
        this.setState({
            intakeQuestionnaireDialogOpen: false,
            validationIssues: {},
            intakeQuestionnaire: {
                meta: {
                    version: moment("2024-10-27") || null,
                },
            }
        });
    }

    /**
     * Reads the row from the database, updates it with any fields in the
     * orderActivity argument, then saves it back to the database.
     *
     * @param {object} row
     * @param {object} partialNewOrderActivity
     */
    handleUpdateOrderActivity(row, partialNewOrderActivity) {
        this.setState({ loading: true });

        OrderService.getOrderActivity(row["order_activity.id"])
            .then(async (oldOrderActivity) => {
                const promises = [];

                // Create the intake questionnaire. Note that this has to happen
                // immediately so we know what the ID is so future updates don't
                // overwrite it back to null.
                if (partialNewOrderActivity.intakeQuestionnaire) {
                    const intakeQuestionnaire = await IntakeQuestionnaireService.createIntakeQuestionnaire(partialNewOrderActivity.intakeQuestionnaire);
                    oldOrderActivity.currentIntakeQuestionnaireId = intakeQuestionnaire.id;
                    partialNewOrderActivity.currentIntakeQuestionnaireId = intakeQuestionnaire.id;
                    partialNewOrderActivity.lastUpdatedById = this.context.currentUser.id;
                    row['order_activity.current_intake_questionnaire_id'] = intakeQuestionnaire.id;
                }

                // Prepare activity note creation
                if (partialNewOrderActivity.activityNote) {
                    promises.push(
                        NoteService.createActivityNote(oldOrderActivity.id, {
                            text: partialNewOrderActivity.activityNote.text,
                            accountId: oldOrderActivity.activity.accountId,
                            completed: true,
                            task: false,
                            createdAt: moment.utc(new Date()),
                            activityId: oldOrderActivity.activity.id,
                            createdById: this.context.currentUser.id,
                        })
                    );

                    // This is separate and not attached to orderActivity
                    // directly so remove it.
                    delete partialNewOrderActivity.activityNote;
                    delete partialNewOrderActivity.intakeQuestionnaire;
                }

                const newOrderActivity = {
                    ...oldOrderActivity,
                    ...partialNewOrderActivity,
                };

                /**
                 * Rehab-specific logic to automatically route the order.
                 */
                if (this.context.companyName === "uat" || this.context.companyName === "rehabmedical") {
                    const isComplex = ProductType.isComplex(
                        {
                            name: row["order_activity.order_activity_products._primary_product_type_id"] || ""
                        }
                    );

                    const intakeOrderStatusReasonId = 11;
                    const schedulingOrderStatusReasonId = 10;
                    const paperworkOrderStatusReasonId = 27;
                    const finalSignatureOrderStatusReasonId = 86;

                    // FORWARD ROUTE: Route to Intake -> Scheduling if the intake questionnaire is completed.
                    if (
                        oldOrderActivity.orderStatusReasonId === intakeOrderStatusReasonId &&
                        partialNewOrderActivity.currentIntakeQuestionnaireId
                    ) {
                        newOrderActivity.orderStatusReasonId = schedulingOrderStatusReasonId;
                        row["order_activity.order_status_reason_id"] = OrderStatusReason.getDisplayName(this.context.orderStatusReasonsIndexed[schedulingOrderStatusReasonId]);
                    }

                    // FORWARD ROUTE: Route to Scheduling -> Paperwork if the requisite dates are populated.
                    if (
                        oldOrderActivity.orderStatusReasonId === schedulingOrderStatusReasonId &&
                        (
                            (isComplex && newOrderActivity.evaluationDate && newOrderActivity.faceToFaceDate && newOrderActivity.atpEvaluationDate) ||
                            (!isComplex && newOrderActivity.evaluationDate && newOrderActivity.faceToFaceDate)
                        )
                    ) {
                        newOrderActivity.orderStatusReasonId = paperworkOrderStatusReasonId;
                        row["order_activity.order_status_reason_id"] = OrderStatusReason.getDisplayName(this.context.orderStatusReasonsIndexed[paperworkOrderStatusReasonId]);
                    }

                    // REVERSE ROUTE: Route Final Signature -> Paperwork if any requisite dates have changed
                    if (
                        oldOrderActivity.orderStatusReasonId === finalSignatureOrderStatusReasonId &&
                        (
                            !moment(newOrderActivity.evaluationDate).isSame(moment(oldOrderActivity.evaluationDate), 'date') ||
                            !moment(newOrderActivity.faceToFaceDate).isSame(moment(oldOrderActivity.faceToFaceDate), 'date') ||
                            !moment(newOrderActivity.atpEvaluationDate).isSame(moment(oldOrderActivity.atpEvaluationDate), 'date')
                        )
                    ) {
                        newOrderActivity.orderStatusReasonId = paperworkOrderStatusReasonId;
                        row["order_activity.order_status_reason_id"] = OrderStatusReason.getDisplayName(this.context.orderStatusReasonsIndexed[paperworkOrderStatusReasonId]);
                    }

                    // REVERSE ROUTE: Route Paperwork -> Scheduling if one of the requisite dates is removed.
                    if (
                        oldOrderActivity.orderStatusReasonId === paperworkOrderStatusReasonId &&
                        (
                            (isComplex && !(newOrderActivity.evaluationDate && newOrderActivity.faceToFaceDate && newOrderActivity.atpEvaluationDate)) ||
                            (!isComplex && !(newOrderActivity.evaluationDate && newOrderActivity.faceToFaceDate))
                        )
                    ) {
                        newOrderActivity.orderStatusReasonId = schedulingOrderStatusReasonId;
                        row["order_activity.order_status_reason_id"] = OrderStatusReason.getDisplayName(this.context.orderStatusReasonsIndexed[schedulingOrderStatusReasonId]);
                    }
                }

                // Update order
                promises.push(OrderService.updateOrder(newOrderActivity));

                // Handle promises
                const results = await Promise.allSettled(promises);

                // Check for any errors
                results.forEach((result) => {
                    if (result instanceof Error) {
                        throw result; // Throw an error if any promise failed
                    }
                });
            })
            .then(() => {
                const columnNameMap = {
                    "evaluationDate": "order_activity.evaluation_date",
                    "faceToFaceDate": "order_activity.face_to_face_date",
                    "atpEvaluationDate": "order_activity.atp_evaluation_date",
                };

                Object.keys(columnNameMap).forEach((columnName) => {
                    if (partialNewOrderActivity[columnName] || partialNewOrderActivity[columnName] === null) {
                        row[columnNameMap[columnName]] = partialNewOrderActivity[columnName];
                    }
                });
                this.setState({ loading: false, datePickerOpen: false });
                NotistackEnqueueSnackbar("Order updated successfully!", { variant: "success" });
                this.handleOnCloseIntakeQuestionnaireDialog()
            })
            .catch((err) => {
                NotistackEnqueueSnackbar("Oh no! There was an error updating the order.", { variant: "error" });
                console.log(err);
                this.setState({ loading: false });
            });
    }
}