import Crud from "../Crud";
import MUIBrownColor from "@mui/material/colors/brown";

/**
 * Static helper functions related to Accounts.
 */
export default class DocumentType extends Crud {

    /**
     * @param {object} documentType The documentType object.
     * @returns The display name of the documentType.
     */
    static getDisplayName(documentType) {
        return documentType.name;
    }

    /**
     * Determines if a documentType matches a search query.
     *
     * @param {object} documentType The documentType to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the documentType matches the query.
     */
    static match(documentType, query) {
        return (
            (documentType.name !== null && documentType.name.toLowerCase().includes(query)) ||
            documentType.id === +query
        );
    }

    /**
     * @param {array} documentTypes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(documentTypes) {
        return documentTypes.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIBrownColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Article";
    }
}