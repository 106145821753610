import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import Account from "../../Utility/Crud/Account";

export default class TileAccount extends Tile {
    /**
      * @returns {string} The Account name.
      */
    getTitle() {
        return this.props.account ? Account.getDisplayName(this.props.account) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.account ? Account.getDisplayName(this.props.account) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.account ? Account.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return Account.getIcon();
    }
}
