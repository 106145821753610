import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIAutoComplete from "@mui/material/Autocomplete";
import MUIGrid from "@mui/material/Grid";

export default class CardAdminLocationContact extends Card {
    constructor(props) {
        super(props);
 
    }

    static contextTypes = {
        stateSalesTax: PropTypes.array,
    };


    /**
     * Render the content of the card.
     */
    renderContent() {
        const getStateListOptions = () => {
            let stateOptions = [];
            this.context.stateSalesTax.forEach(state => {
                stateOptions.push({
                    label: state.name + " - " + state.abbr,
                    value: state.abbr,
                });
            });
            return stateOptions;
        }

        const handlePhoneNumberChange = (event) => {
            const { value } = event.target;
            // Remove all non-digit characters
            const cleaned = value.replace(/\D/g, '');
            // Format the cleaned number
            let formattedNumber = cleaned;
            if (cleaned.length > 3) {
                formattedNumber = `(${cleaned.slice(0, 3)}`;
            }
            if (cleaned.length > 6) {
                formattedNumber += `) ${cleaned.slice(3, 6)}-`;
                formattedNumber += cleaned.slice(6, 10);
            } else if (cleaned.length > 3) {
                formattedNumber += `) ${cleaned.slice(3)}`;
            }
            this.props.onChange({ phone: formattedNumber ? formattedNumber : null });
        };
 
        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.location && this.props.location.address ? this.props.location.address : ""}
                        size="small"
                        label="Address"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ address: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ address: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.address ? true : false}
                        helperText={this.props.validationIssues.address ? this.props.validationIssues.address : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.location && this.props.location.city ? this.props.location.city : ""}
                        size="small"
                        label="City"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ city: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ city: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.city ? true : false}
                        helperText={this.props.validationIssues.city ? this.props.validationIssues.city : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUIAutoComplete
                        options={getStateListOptions()}
                        value={
                            this.props.location 
                                ? getStateListOptions().find(option => option.value === this.props.location.state) || null 
                                : null
                        }
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(e, value) => this.props.onChange({ state: value ? value.value : null })}
                        renderInput={(params) => <MUITextField {...params}
                            InputLabelProps={{ shrink: true }}
                            size="small" 
                            variant="standard"
                            label="State"
                            required={true}
                            fullWidth
                            error={this.props.validationIssues && this.props.validationIssues.state ? true : false}
                            helperText={this.props.validationIssues ? this.props.validationIssues.state : ""}
                        />}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.location && this.props.location.zipCode ? this.props.location.zipCode : ""}
                        size="small"
                        label="Postal Code"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 5,
                            inputMode: "numeric", // Show numeric keyboard
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ zipCode: e.target.value.trim() })}
                        onChange={(e) => {
                            // Allow only digits
                            const value = e.target.value.replace(/[^0-9]/g, '');
                            this.props.onChange({ zipCode: value });
                        }}
                        required={true}
                        error={this.props.validationIssues.zipCode ? true : false}
                        helperText={this.props.validationIssues.zipCode ? this.props.validationIssues.zipCode : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.location && this.props.location.phone ? this.props.location.phone : ""}
                        size="small"
                        label="Phone"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={handlePhoneNumberChange}
                        error={this.props.validationIssues.phone ? true : false}
                        helperText={this.props.validationIssues.phone ? this.props.validationIssues.phone : ""}
                    />
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Contact";
    }
}