import Admin from "../Admin";
import React from "react";
import CardAdminIssueReasonList from "../../../Component/Card/Admin/IssueReason/CardAdminIssueReasonList";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIAddIcon from "@mui/icons-material/Add";

export default class AdminIssueReasonList extends Admin {

    /**
     * Render the content.
     */
    renderContent() {
        return (
            <CardAdminIssueReasonList />
        );
    }

    renderHeader() {
        const handleClickCreate = () => {
            this.props.history.push({
                pathname: "/admin/issueReason"
            });
        };

        const buttons = [
            (<MUIButton
                component="label"
                variant="contained"
                startIcon={<MUIAddIcon />}
                onClick={handleClickCreate}>Create New</MUIButton>),
        ];

        return (
            <PageHeader title="Issue Reasons" buttons={buttons} />
        );
    }
}
