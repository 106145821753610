import api from "../api";

class IssueReasonService {
    async getAllIssueReasons() {
        return new Promise((res, reject) => {
            api
                .get("/issueReasons")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getIssueReasonById(id) {
        return new Promise((res, reject) => {
            api
                .get("/issueReasons/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createIssueReason(obj) {
        return new Promise((res, reject) => {
            api
                .post("/issueReasons", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateIssueReason(obj) {
        return new Promise((res, reject) => {
            api
                .put("/issueReasons", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteIssueReason(id) {
        return new Promise((res, rej) => {
            return api.delete('/issueReasons/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const issueReasonService = new IssueReasonService();

export default issueReasonService;
