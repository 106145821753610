import api from "../api";

class UserService {
    async getAllUsers() {
        return new Promise((res, reject) => {
            api
                .get("/allUsersAsync")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getAllTherapists() {
        return new Promise((res, reject) => {
            api
                .get("/findTherapists")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getAllSalesReps() {
        return new Promise((res, reject) => {
            api
                .get("/findSalesReps")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getAllTechs() {
        return new Promise((res, reject) => {
            api
                .get("/canScheduleToDeliver")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateUser(user) {
        return new Promise((res, reject) => {
            api
                .put("/users", user)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async clearTokens(user){
        return new Promise((res, reject) => {
            api.delete("/usersTokens/" + user.id).then((data) => {
                res(data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async changePassword(params) {
        return new Promise((res, reject) => {
            api
                .put("/changePassword", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateUserAndSendEmail(user) {
        return new Promise((res, reject) => {
            api
                .put("/usersUpdateAndEmail/" + user.id, user)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getUser(id) {
        return new Promise((res, reject) => {
            api
                .get("/users/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getUserGroups() {
        return new Promise((res, reject) => {
            api
                .get("/users/groups")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createUser(user) {
        return new Promise((res, reject) => {
            api
                .post("/users", user)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findAllUsers() {
        let params = {
            page: 0,
            size: 1000,
            tpUsers: false,
        };
        return new Promise((res, reject) => {
            api
                .get("/users", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetUsers
    async findAllActiveUsers() {
        let params={includeAll: true};

        return new Promise((res, reject) => {
            api
                .get("/spActiveUsersList", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findThirdPartyUsers() {
        return new Promise((res, reject) => {
            api
                .get("/users/findThirdPartyUsers")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createThirdPartyUser(user) {
        return new Promise((res, reject) => {
            api
                .post("/thirdPartyUsers", user)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getThirdPartyUser(id) {
        return new Promise((res, reject) => {
            api
                .get("/thirdPartyUsers/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async checkThirdPartyUser(contactId) {
        return new Promise((res, reject) => {
            api
                .get("/checkThirdPartyUsers/" + contactId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getContactsByAccount(id) {
        return new Promise((res, reject) => {
            let params = {
                    page: 0,
                    size: 25,
                },
                url = "/sources/" + id + "/contacts";

            return api
                .get(url, params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async moveAccounts(accounts, newOwner) {
        return new Promise((res, reject) => {
            let params = {
                accounts: accounts || [],
                newOwnerId: newOwner.id || null,
            };

            return api
                .post("/moveAccounts", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findAccountsForUsers(id) {
        console.log(id);
        return new Promise((res, reject) => {
            return api
                .get("/users/" + id + "/accounts")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findUsersByLocation(id) {
        return new Promise((res, reject) => {
            return api
                .get("/findUsersByLocation/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findTechsByLocation(id) {
        return new Promise((res,reject) => {
            return api.get('/findTechsByLocation/' + id).then(data => {
                res(data);
            }).catch( err => {
                reject(err);
            });
        });
    }

    async addUserReportFilters(filters){
        return new Promise((res,rej) => {
            return api.post('/users/userReportFilters', filters).then(data => {
                res(data);
            }).catch(err => {
                rej(err);
            });
        });
    }

    async removeUserReportFilter(id){
        return new Promise((res,rej) => {
            return api.delete('/users/userReportFilters/' + id).then(data => {
                res();
            }).catch(err => {
                rej(err);
            });
        });
    }

    async getMyFaxes(id) {
        return new Promise((res, rej) => {
            return api.get('/users/myFaxes/' + id).then(data => {
                res(data);
            }).catch(err => {
                rej(err);
            });
        });
    }

    async removeFax(id) {
        return new Promise((res, rej) => {
            return api.delete('/fax/' + id).then(data => {
                res();
            }).catch(err => {
                rej(err);
            });
        });
    }

    async getFaxImages(docId) {
        return new Promise((res, reject) => {
            return api
                .get("/fax/images/" + docId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getMyPcrReps(id) {
        return new Promise((res, reject) => {
            return api
                .get("/users/myReps/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updatePreferences(prefs) {
        return new Promise((res, reject) => {
            return api
                .put("/users/updatePreferences", prefs)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createPreferences(id, prefs) {
        return new Promise((res, reject) => {
            return api
                .post("/users/createPreferences/" + id, prefs)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async downloadFax(docId) {
        return new Promise((res, reject) => {
            return api
                .getFile("/fax/" + docId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async mergeAndUploadFax(doc) {
        return new Promise((res, reject) => {
            return api
                .post("/fax/merge", doc)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

}

const userService = new UserService();

export default userService;
