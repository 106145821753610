import React from "react";
import MUIGrid from "@mui/material/Grid";
import CardApprovalSummaryPatient from "./Card/ApprovalSummary/CardApprovalSummaryPatient";
import CardApprovalSummaryInsurance from "./Card/ApprovalSummary/CardApprovalSummaryInsurance";
import CardApprovalSummaryApproval from "./Card/ApprovalSummary/CardApprovalSummaryApproval";
import CardApprovalSummaryPayment from "./Card/ApprovalSummary/CardApprovalSummaryPayment";
import CardApprovalApprovedEquipment from "./Card/ApprovalSummary/CardApprovalApprovedEquipment";
import api from "../Seating/Security/api";

export default class ApprovalSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order || {},
            products: this.props.products || [],
            approval: this.props.order.orderActivityApprovals[0] ? this.props.order.orderActivityApprovals[0].approvals : null,
            productDocumentInfo: [],
        }
    }

    componentDidMount() {
        this.getDeliveryProductInformation(this.props.order.id);
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.products) !== JSON.stringify(this.props.products) ||
            JSON.stringify(prevProps.order) !== JSON.stringify(this.props.order)
        ) {
            this.setState({
                products: this.props.products,
                order: this.props.order,
                approval: this.props.order.orderActivityApprovals[0] ? this.props.order.orderActivityApprovals[0].approvals : null,
            }, () => {
                this.checkAndSortByDeliveredProducts();
            })
        }
    }

    async getDeliveryProductInformation(orderId) {
        this.setState({loading: true})

        return api.send("POST", `/orders/getDeliveryProductInformation`, orderId)
            .then((res) => {
                if (res.success === true) {
                    this.setState({
                        productDocumentInfo: JSON.parse(res.data.documentInfo).products,
                    }, () => {
                        this.checkAndSortByDeliveredProducts();
                    });
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    checkAndSortByDeliveredProducts() {
        if (!this.state.productDocumentInfo || this.state.productDocumentInfo.length === 0) {
            return;
        }

        const updatedProducts = [...this.props.products];

        this.state.productDocumentInfo.forEach((documentInfo) => {
            updatedProducts.forEach((product) => {
                if (documentInfo.id === product.id) {
                    product.delivered = documentInfo.delivered;
                }
            })
        });

        updatedProducts.sort((a, b) => {
            if (a.delivered === b.delivered) return 0;
            return a.delivered ? -1 : 1;
        });

        this.setState({
            products: updatedProducts,
        })
    }

    /**
     * Renders content
     */
    render() {
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={12} md={12} lg={4}>
                        <CardApprovalSummaryPatient
                            order={this.state.order}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12} lg={8}>
                        <CardApprovalSummaryInsurance
                            order={this.state.order}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12} lg={8}>
                        <CardApprovalSummaryApproval
                            order={this.state.order}
                            approval={this.state.approval}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12} lg={4}>
                        <CardApprovalSummaryPayment
                            approval={this.state.approval}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12} lg={12}>
                        <CardApprovalApprovedEquipment
                            products={this.state.products}
                            productDocumentInfo={this.state.productDocumentInfo}
                        />
                    </MUIGrid>
                </MUIGrid>
            </>
        )
    }
}