import Crud from "../Crud";
import MUILimeColor from "@mui/material/colors/lime";

/**
 * Static helper functions related to Accounts.
 */
export default class ContactType extends Crud {

    /**
     * @param {object} contactType The contactType object.
     * @returns The display name of the contactType.
     */
    static getDisplayName(contactType) {
        return contactType.name;
    }

    /**
     * Determines if a contactType matches a search query.
     *
     * @param {object} contactType The contactType to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the contactType matches the query.
     */
    static match(contactType, query) {
        return (
            (contactType.name !== null && contactType.name.toLowerCase().includes(query)) ||
            contactType.id === +query
        );
    }

    /**
     * @param {array} contactTypes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(contactTypes) {
        return contactTypes.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUILimeColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Contacts";
    }
}