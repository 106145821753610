import api from "../api";

class PatientNoteService {
    async createPatientNote(note) {
        return api.post("/patientNotes", note);
    }

    async getNotesByPatient(id) {
        return new Promise((res, reject) => {
            let url = "/patientNotes/" + id;

            return api
                .get(url)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const patientNoteService = new PatientNoteService();

export default patientNoteService;
