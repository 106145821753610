import Crud from "../Crud";
import MUIBrownColor from "@mui/material/colors/brown";

/**
 * Static helper functions related to DistributionReason.
 */
export default class DistributionReason extends Crud {

    /**
     * @param {object} distributionReason The distributionReason object.
     * @returns The display name of the distributionReason.
     */
    static getDisplayName(distributionReason) {
        return distributionReason.description;
    }

    /**
     * Determines if a distributionReason matches a search query.
     *
     * @param {object} distributionReason The distributionReason to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the distributionReason matches the query.
     */
    static match(distributionReason, query) {
        return (
            (distributionReason.name !== null && distributionReason.name.toLowerCase().includes(query)) ||
            distributionReason.id === +query
        );
    }

    /**
     * @param {array} distributionReasons The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(distributionReasons) {
        return distributionReasons.sort((a, b) => {
            return (a.code).localeCompare(b.code);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIBrownColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Article";
    }
}