import React from "react";
import PropTypes from "prop-types";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    toast,
    MDBIcon,
    MDBRow,
    MDBCol,
    Card,
    Button,
} from "mdbreact";
import PurchaseQueueService from "../Security/PurchaseQueueService/purchaseQueueService";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import LayerLegacy from "../../LayerLegacy";


const columns = [
    {
        label: "ID",
        field: "id",
        sort: "asc",
    },
    {
        label: "Vendor",
        field: "vendor",
        sort: "asc",
    },
    {
        label: "Product",
        field: "product",
        sort: "asc",
    },
    {
        label: "Qty",
        field: "qty",
        sort: "asc",
    },
];

export default class inventoryRequest extends LayerLegacy {
    constructor(props) {
        super(props);

        this.onClose = props.onClose;

        this.state = {
            productCategory: null,
            productVendor: null,
            equipment: [],
            selectedProduct: null,
            selectedLocation: null,
            orderId: null,

            productTypeList: [],
            vendorList: [],
            productSubTypeList: [],
            locationsList: [],
            allProducts: [],

            isSaving: false,
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        productSubTypes: PropTypes.array,
        productTypes: PropTypes.array,
        internalLocations: PropTypes.array,
        allVendors: PropTypes.array,
    };

    componentDidMount(){
        this.getCategoryList();
        this.getVendorList();
        this.getLocations();
        this.getProductList();
    }

    componentDidUpdate() {
        const { productTypes, productSubTypes, allVendors } = this.context;
        if (productTypes && productTypes.length !== this.state.productTypeList.length) {
            this.getCategoryList();
        }
        if (productSubTypes && productSubTypes.length !== this.state.allProducts.length) {
            this.getProductList();
        }
        if (!this.state.locationsList || this.state.locationsList.length === 0) {
            this.getLocations();
        }
        if (allVendors && allVendors.length !== (this.state.vendorList.length-1)) {
            this.getVendorList();
        }
    }

    handleCategoryChange = (e) => {
        this.setState({
            productCategory: e,
        }, ()=> this.getProductList() );
    };

    handleVendorChange = (e) => {
        if (e.value == null) {
            e = null;
        }
        this.setState({
            productVendor: e,
        }, ()=> this.getProductList() );
    };

    getCategoryList() {
        const { productTypes } = this.context;
        let a = [];
        if (productTypes.length > 0) {
            productTypes.map((productType) => {
                return a.push({
                    label: productType.name,
                    value: productType,
                });
            });
        }
        this.setState({
            productTypeList: a,
        });
    }

    getVendorList() {
        const { allVendors } = this.context;
        let a = [];
        if (allVendors.length > 0) {
            a.push({
                label: "Clear",
                value: null,
            });

            allVendors.map((vendor) => {
                return a.push({
                    label: vendor.name,
                    value: vendor,
                });
            });
            this.setState({
                vendorList: a,
            });
        }
    }

    handleProductChange = (e) => {
        this.addProduct(e.value);

        this.setState({
            selectedProduct: null,
        });
    };

    handleOrderIdChange = (e) => {
        this.setState({
            orderId: e.target.value,
        });
    };

    addProduct(product) {
        const { productTypes, currentUser } = this.context;
        let st = this.state,
            e = st.equipment,
            productCategory = productTypes.find((p) => {
                return (p.id === product.productType.id);
            }),
            row = {
                itemId: product.itemId || null,
                quantity: 1,
                requestedOn: new Date(),
                productSubTypeId: product.id,
                productSubType: { id: product.id, name: product.name },
                requestedById: currentUser.id,
                // requestedBy: { id: currentUser.id, name: currentUser.username },
                vendorId: product.vendor ? product.vendor.id : null,
                vendor: product.vendor
                    ? { id: product.vendor.id, name: product.vendor.name }
                    : null,
                productId: productCategory.id,
                statusChangedOn: new Date(),
                statusChangedById: currentUser.id,
                // statusChangedBy: { id: currentUser.id, name: currentUser.username },
                status: "PENDING",
            };

        e.push(row);

        this.setState({
            equipment: e,
        });
    }

    getProductList = () => {
        const { productSubTypes } = this.context;
        let a = [],
            subTypes = [], //productSubTypes,
            cc = this.state.productCategory,
            vv = this.state.productVendor;

        subTypes = productSubTypes.filter((product) => {
            return product.itemId && product.itemId.length > 0;
        });

        if (cc) {
            subTypes = subTypes.filter((product) => {
                return product.productType.id === cc.value.id;
            });
        }

        if (vv) {
            subTypes = subTypes.filter((product) => {
                return product.vendor ? product.vendor.id === vv.value.id : false;
            });
        }

        subTypes.map((product) => {
            let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
            let i = product.itemId ? " | " + product.itemId : "";
            let	v = product.vendor ? " | " + product.vendor.name : "";
            let l = product.productType.name + " | " + product.name + h + v + i;

            return a.push({
                label: l,
                value: product,
            });
        });
        this.setState({
            productSubTypeList: a,
            allProducts: productSubTypes,
        });
    };

    renderCategoryDropdown() {
        return (
            <div>
                <Select
                    placeholder={"Category..."}
                    options={this.state.productTypeList}
                    onChange={this.handleCategoryChange.bind(this)}
                    value={this.state.productCategory}
                />
            </div>
        );
    }

    renderVendorDropdown() {
        return (
            <div>
                <Select
                    placeholder={"Vendor..."}
                    options={this.state.vendorList}
                    onChange={this.handleVendorChange.bind(this)}
                    value={this.state.productVendor}
                />
            </div>
        );
    }

    renderProductDropdown() {
        return (
            <div>
                <Select
                    placeholder={"Product..."}
                    value={this.state.selectedProduct}
                    options={this.state.productSubTypeList}
                    onChange={this.handleProductChange.bind(this)}
                />
            </div>
        );
    }

    renderLocationDropdown() {
        return (
            <div>
                <Select
                    placeholder={"Location..."}
                    options={this.state.locationsList}
                    onChange={this.handleLocationChange.bind(this)}
                    value={this.state.selectedLocation}
                />
            </div>
        );
    }

    getLocations() {
        const { internalLocations, currentUser } = this.context;
        console.log(currentUser);
        let locations = [];
        switch (currentUser.role) {
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
                locations = internalLocations;
                break;
            default:
                locations = currentUser.userprofileLocations.map(x => x.locations);
        }
        let arr = [];
        locations.map((loc) => {
            return arr.push({
                label: loc.name,
                value: { id: loc.id, name: loc.name },
            });
        });
        this.setState({
            locationsList: arr
        });
    }

    handleLocationChange(evt) {
        this.setState({
            selectedLocation: evt,
        });
    }

    renderRows() {
        let arr = [];

        this.state.equipment.map((r, index) => {
            return arr.push({
                id: r.productSubType.id,
                vendor: r.vendor ? r.vendor.name : "No Vendor",
                product: r.productSubType.name,
                quantity: this.renderEditRow(r, index),
            });
        });

        return arr;
    }

    renderEditRow(row, index) {
        return (
            <MDBRow style={{ width: 200 }}>
                <MDBCol size={"4"}>
                    <Button
                        style={{ paddingLeft: 8, paddingRight: 8 }}
                        size={"sm"}
                        onClick={() => this.handleQtyChange(index, -1)}
                    >
                        <MDBIcon icon="minus" style={{ fontSize: "1.5em" }} />
                    </Button>
                </MDBCol>
                <MDBCol size={"4"} style={{ margin: '0', padding: '0' }}>

                    <input type="number" style={{width:'100%', marginTop: '10px', border: '1px solid black', background: 'white'}} onChange={this.handleRowChange.bind(this,index)} value={row.quantity || 1}/>
                    {/*<p style={{ paddingTop: 15, textAlign: "center" }}>{row.quantity}</p>*/}
                </MDBCol>
                <MDBCol size={"4"}>
                    <Button
                        style={{ paddingLeft: 8, paddingRight: 8 }}
                        onClick={() => this.handleQtyChange(index, 1)}
                        size={"sm"}
                    >
                        <MDBIcon icon="plus" style={{ fontSize: "1.5em" }} />
                    </Button>
                </MDBCol>
            </MDBRow>
        );
    }

    handleQtyChange(index, val) {
        let st = this.state,
            e = st.equipment;

        e[index].quantity += val;

        if (e[index].quantity === 0) {
            e.splice(index, 1);
        }

        this.setState({
            equipment: e,
        });
    }

    handleRowChange(index, evt) {
        let val = evt.target.value;
        //if (parseInt(val)) {
        let st = this.state,
            e = st.equipment;
        e[index].quantity = parseInt(val);

        this.setState({
            equipment: e,
        });
        //}
        //else {
        //	alert('Please only enter a whole number for')
        //      }
    }

    handleSave() {
        let st = this.state,
            e = st.equipment,
            loc = st.selectedLocation,
            v = this.state.productVendor,
            oi = this.state.orderId;

        if (oi) {
            let r = this.getOrderExists(oi);
            if (r === false) {
                toast.error("The order ID is not a valid SP order number.");
                return false;
            }
        }

        this.setState({
            isSaving: true,
        });

        if (this.validateSave(e, loc, v)) {
            // e.forEach((r) => {
            // 	r.location = loc.value;
            // });

            // this.onClose();

            e.forEach(obj => {
                obj.locationId = loc.value.id;
                obj.vendorId = v && v.value ? v.value.id : null;
                obj.itemId = (obj.itemId === undefined ? null : obj.itemId);
                obj.orderId = oi ? parseInt(oi) : null;
            });

            PurchaseQueueService.createPurchaseQueues(e)
                .then((res) => {
                    this.setState({
                        equipment: [],
                        productCategory: null,
                        orderId: "",
                        selectedLocation: null,
                        productVendor: null,
                        isSaving: false,
                    });
                    toast.success("Equipment Purchased");
                })
                .catch((err) => {
                    toast.error(
                        "There was an error submitting the request.  Please try again later or contact support."
                    );
                });
        }
        else {
            this.setState({
                isSaving: false,
            });
        }
    };

    validateSave(equip, loc, v) {
        var isValid = true;
        let arr = equip.filter((r) => {
            return !r.quantity;
        });
        if (!loc) {
            toast.warn("Please select a location");
            //return false;
            isValid = false;
        }
        if (equip.length === 0) {
            toast.warn("Must add a product");
            //return false;
            isValid = false;
        }
        if (arr.length > 0) {
            toast.warn("Please enter a quntity for all products");
            //return false;
            isValid = false;
        }
        if (!v || !v.value || !v.value.id) {
            toast.warn("Please select a vendor.");
            //return false;
            isValid = false;
        }
        return isValid;
    }

    getOrderExists(id) {
        if (id && id.length > 0) {
            PurchaseQueueService.orderExists(id)
                .then(res => {
                    return res;
                })
                .catch(err => {
                    console.log(err);
                    return false;
                });
        }
    }

    renderContent() {
        return (
            <div>
                <Card style={{ margin: "0 0 1% 0", padding: "2%" }}>
                    <MDBRow>
                        <MDBCol size="4">
                            <div className="form-group">
                                <label style={{color: "#000"}}>Category</label>
                                {/*{this.renderCategoryDropdown()}*/}
                                <div>
                                    <Select
                                        isClearable
                                        placeholder={"Category..."}
                                        options={this.state.productTypeList}
                                        onChange={this.handleCategoryChange.bind(this)}
                                        value={this.state.productCategory}
                                        styles={{
                                            option: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        </MDBCol>

                        <MDBCol size="4">
                            <div className="form-group">
                                <label style={{color: "#000"}}>Vendor</label>
                                {/*{this.renderVendorDropdown()}*/}
                                <div>
                                    <Select
                                        isClearable
                                        placeholder={"Vendor..."}
                                        options={this.state.vendorList}
                                        onChange={this.handleVendorChange.bind(this)}
                                        value={this.state.productVendor}
                                        styles={{
                                            option: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        </MDBCol>

                        <MDBCol size="4">
                            <div className="form-group">
                                <label style={{color: "#000"}}>Location</label>
                                {/*{this.renderLocationDropdown()}*/}
                                <div>
                                    <Select
                                        isClearable
                                        placeholder={"Location..."}
                                        options={this.state.locationsList}
                                        onChange={this.handleLocationChange.bind(this)}
                                        value={this.state.selectedLocation}
                                        styles={{
                                            option: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ justifyContent: "space-between" }}>
                        <MDBCol size="8">
                            <div className="form-group">
                                <label style={{color: "#000"}}>Product</label>
                                {/*{this.renderProductDropdown()}*/}
                                <div>
                                    <Select
                                        isClearable
                                        placeholder={"Product..."}
                                        value={this.state.selectedProduct}
                                        options={this.state.productSubTypeList}
                                        onChange={this.handleProductChange.bind(this)}
                                        styles={{
                                            option: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        </MDBCol>

                        <MDBCol size={"4"}>
                            <div className="form-group">
                                <label style={{color: "#000"}}>SalesPilot Order #</label>
                                <div>
                                    <input
                                        style={{ border: "1px solid black", borderRadius: "4px", borderColor: "rgb(204, 204, 204)", minHeight: "38px", width: "100%" }}
                                        type="number"
                                        value={this.state.orderId}
                                        onChange={this.handleOrderIdChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ justifyContent: "space-between" }}>
                        <MDBCol size="8">
                        </MDBCol>

                        <MDBCol size={"2"}>
                            <Button
                                disabled={this.state.isSaving}
                                style={{ margin: "30% 0 0 0" }}
                                color={"green"}
                                data-tip={"Save"}
                                size={"sm"}
                                onClick={() => this.handleSave()}
                            >
                                <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                            </Button>
                        </MDBCol>
                    </MDBRow>
                    <ReactTooltip />
                </Card>
                <Card style={{ margin: "0", padding: "2%" }}>
                    <MDBTable striped small>
                        <MDBTableHead columns={columns} />
                        <MDBTableBody rows={this.renderRows()} />
                    </MDBTable>
                </Card>
            </div>
        );
    }
}
