import React from "react";
import {
    Button,
    Container,
    DataTable,
    MDBIcon,
    Spinner,
    toast,
} from "mdbreact";
import PropTypes from "prop-types";
// import ReactTooltip from "react-tooltip";
import ReportsService from "../Security/ReportsService/reportsService";

export default class approvalReport extends React.Component {
    constructor(props) {
        super(props);

        let filters = this.determineFilters(props.filters);
        let topFilters = props.topFilters;

        //table headers & columns
        const approvals = {
            columns: [
                {
                    label: "Approval Date",
                    field: "approvalDate",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Setup Date",
                    field: "setupDate",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Sales Location",
                    field: "salesLocation",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Service Location",
                    field: "serviceLocation",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Sales Rep",
                    field: "salesRep",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Patient",
                    field: "patient",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Account",
                    field: "account",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Payor Source",
                    field: "payorSource",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Insurance",
                    field: "insurance",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Category",
                    field: "category",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Product",
                    field: "product",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Points",
                    field: "points",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Amount",
                    field: "amount",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Region",
                    field: "region",
                    sort: "asc",
                    width: 200,
                },
            ],
            rows: [],
        };

        this.state = {
            allApprovals: [],
            approvalData: approvals,
            startDate: new Date(filters.startDate),
            endDate: new Date(filters.endDate),
            pointsOnlyProducts: false,
            isLoaded: true,
            users: [],
            csvFunction: props.csvFunction,
            topFilters: topFilters,
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        salesReps: PropTypes.array,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Get the most up-to-date filters passed down from parent
        if (
            JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
        ) {
            this.setState({
                topFilters: this.props.topFilters,
            });
        }
        // Checks if the generate reports button has been clicked...JK
        if (prevProps.generateClicked !== this.props.generateClicked) {
            this.getApprovalReport();
        }
    }

    formatCurrency(amount) {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
        }).format(amount);
    }

    determineFilters(userFilters) {
        let d = new Date(),
            filters = {};

        if (userFilters) {
            if (userFilters.startDate) {
                filters.startDate = new Date(userFilters.startDate);
            } else {
                filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
            }

            if (userFilters.endDate) {
                filters.endDate = new Date(userFilters.endDate);
            } else {
                filters.endDate = d;
            }
        } else {
            filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
            filters.endDate = d;
        }

        return filters;
    }

    getApprovalReport() {
        //const { currentUser, salesReps } = this.context;
        let st = this.state,
            tp = st.topFilters;
        let filters = {
            startDate: tp.startDate,
            endDate: tp.endDate,
        };

        try {
            filters = {
                startDate: new Date(tp.startDate).toLocaleDateString(),
                endDate: new Date(tp.endDate).toLocaleDateString(),
            };
        } catch {
            // toast.error("Please check your dates.");
            return;
        }

        // Have to check len since showDatePicker defaults to false at startup
        if (!tp.showDatePicker && tp.quickFilter.length > 0) {
            filters.startDate = tp.quickFilter[0].toLocaleDateString();
            filters.endDate = tp.quickFilter[1].toLocaleDateString();
        } else {
            // Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter
            if (
                filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
            ) {
                // toast.error('Please check your dates');
                return;
            }
        }
        // Get users in the sales rep dropdown and add to the filter
        if (tp.salesRepsSelected && tp.salesRepsSelected.length > 0) {
            filters.ids = tp.salesRepsSelected || null;
        }
        //else if (
        //	currentUser.role === "ADMIN" ||
        //	currentUser.role === "EXECUTIVE_MANAGEMENT" ||
        //	currentUser.role === "FINANCE"
        //) {
        //	filters.ids = salesReps.map(x => x.id);
        //}

        this.setState({ isLoaded: false });

        ReportsService.getApprovalsReport(filters)
            .then((res) => {
                this.setState({ allApprovals: res });

                this.formatRows();
            })
            .catch((err) => {
                //handle error..BC
            });
    }

    formatRows() {
        let approvals = this.state.allApprovals,
            dt = this.state.approvalData,
            ary = [];

        try {
            approvals.forEach((approval) => {
                ary.push({
                    approvalDate: approval.approvalDate || " ",
                    setupDate: approval.setupDate || " ",
                    salesLocation: approval.salesLocation,
                    serviceLocation: approval.serviceLocation,
                    salesRep: approval.salesRep
                        ? approval.salesRep.replace(/,/g, " ")
                        : "No Rep",
                    patient: approval.patient
                        ? approval.patient.replace(/,/g, " ")
                        : "No Patient",
                    account: approval.account
                        ? approval.account.replace(/,/g, " ")
                        : "No Account",
                    payorSource: approval.payorSource
                        ? approval.payorSource.replace(/,/g, " ")
                        : "No Payor Source",
                    insurance: approval.insurance
                        ? approval.insurance.replace(/,/g, " ")
                        : "No Insurance",
                    category: approval.category
                        ? approval.category.replace(/,/g, " ")
                        : "No Category",
                    product: approval.product
                        ? approval.product.replace(/,/g, " ")
                        : "No Product",
                    points: parseFloat(approval.points),
                    amount: parseFloat(approval.amount),
                    region: approval.region,
                });
            });
        } catch (e) {
            toast.error("An error occurred loading approvals.");
        }
        dt.rows = ary;

        this.setState({
            approvalData: dt,
            isLoaded: true,
        });
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    //get the data in the table
    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div>
                <DataTable
                    entriesOptions={[25, 50, 100]}
                    pagesAmount={10}
                    data={this.state.approvalData}
                    responsive
                    fixed
                    striped
                    bordered
                ></DataTable>

                <Button
                    style={{ float: "right" }}
                    color={"blue"}
                    floating
                    size="sm"
                    data-tip={"Download rCSV"}
                    onClick={this.state.csvFunction.bind(
                        this,
                        this.state.approvalData,
                        "approvalExport.csv"
                    )}
                >
                    <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                </Button>
                {/* <ReactTooltip /> */}
            </div>
        );
    }
}
