import Admin from "../Admin";
import React from "react";
import CardAdminRegionGeneral from "../../../Component/Card/Admin/Region/CardAdminRegionGeneral";
import CardAdminRegionManagement from "../../../Component/Card/Admin/Region/CardAdminRegionManagement";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import RegionService from "../../../Seating/Security/RegionService/regionService";
import Validator from "../../../Utility/Validator";
import Region from "../../../Utility/Crud/Region";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminRegionCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.region = null;
        this.state.teams = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the region in the state.
     */
    afterComponentDidMount() {
        const regionId = this.props.match.params.id ? +this.props.match.params.id : null;

        if (regionId !== null) {
            const region = this.context.regionsIndexed[regionId];
            this.setState({
                region: region,
                teams: Object.values(this.context.teamsIndexed).filter((team) => team.regionId === region.id)
            });
        }
    }

    /**
     * Creates the region with the current properties in state.region.
     */
    async createRegion() {
        this.setState({
            isProcessing: true,
        });

        return RegionService.createRegion(this.state.region)
            .then(region => {
                this.context.updateIndexed("regionsIndexed", region);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Region created successfully!", { variant: "success" });
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Region.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the region with the current properties in state.region.
     */
    async updateRegion() {
        this.setState({
            isProcessing: true,
        });

        return RegionService.updateRegion(this.state.region)
            .then(region => {
                this.context.updateIndexed("regionsIndexed", region);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Region updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Region.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    async deleteRegion() {
        this.setState({
            isProcessing: true,
        });

        return RegionService.deleteRegion(this.state.region.id)
            .then(() => {
                this.context.deleteIndexed("regionsIndexed", this.state.region.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Region deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Region.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (region) => {
            this.setState({
                region: {
                    ...this.state.region,
                    ...region
                },
            });
        };

        return (
            <>
                <CardAdminRegionGeneral
                    region={this.state.region}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminRegionManagement
                    region={this.state.region}
                    onChange={handleChange.bind(this)}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteRegion()}
                    text="Are you sure you want to delete this region? This cannot be undone."
                    header="Delete Region"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Render the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Region';
            subtitle = null;
        } else {
            title = 'Edit Region';
            subtitle = (this.state.region && (Region.getDisplayName(this.state.region) + " • " + this.state.region.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.region,
                {
                    name: {
                        'required': true
                    },
                    regionTypeTermId: {
                        'required': true
                    }
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateRegion();
                } else {
                    this.createRegion();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
