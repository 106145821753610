import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import MUIDialogActions from "@mui/material/DialogActions";
import MUITypography from "@mui/material/Typography";
import MUIButton from "@mui/material/Button";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUIAutocomplete from "@mui/material/Autocomplete";
import MUITextField from "@mui/material/TextField";
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import MUITableContainer from '@mui/material/TableContainer';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import MUIPaper from "@mui/material/Paper";
import MUIBox from "@mui/material/Box";
import MUIStack from "@mui/material/Stack";
import MUIGrid from "@mui/material/Grid";
import MUIOpenInNewIcon from "@mui/icons-material/OpenInNew";
import MUIAlert from "@mui/material/Alert";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIRedColor from "@mui/material/colors/red";
import Progress from "../Progress";
import OrderService from "../../Seating/Security/OrderService/orderService";
import DistributionReason from "../../Utility/Crud/DistributionReason";
import HcpcsCode from "../../Utility/Crud/HcpcsCode";
import Setting from "../../Utility/Setting";

export default class DialogUpdateInvoice extends Dialog {
    static contextTypes = {
        termsIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        distributionReasonsIndexed: PropTypes.object,
        hcpcsCodesIndexed: PropTypes.object,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                noteText: "",
            })
        }
        if (prevProps.invoices.length !== this.props.invoices.length) {
            this.setState({
                invoices: this.props.invoices
            })
        }
    }

    /**
    * Render header title of dialog
    */
    renderHeader() {
        return (
            <>
                <MUITypography variant="h6" color="textPrimary">
                    Update Invoice
                </MUITypography>
            </>
        );
    }

    /**
     * Render main content of dialog
     */
    renderContent() {
        if (this.state.invoices && this.state.invoices.length > 0) {
            return (
                <>
                    {this.renderOrderAndBrightreeLinks()}
                    {this.renderInvoiceTable()}
                    {this.renderNoteTextField()}
                    {this.renderNextWorkDateAlert()}
                </>
            );
        } else {
            return (
                <Progress message="Fetching Data..." />
            )
        }
    }

    /**
     * Render brightree and sales pilot links at top of the dialog
     */
    renderOrderAndBrightreeLinks() {
        const handleOnClickOpenOrder = (orderId) => {
            OrderService.getOrderActivity(orderId).then((order) => {
                const orderActivityType = Object.values(this.context.orderActivityTypesIndexed)
                    .find(type => type.name.toLowerCase() === order.orderType.toLowerCase());

                const targetUrl = orderActivityType.activityType.toLowerCase() === "ticket"
                    ? `/patientInformation/${order.patientId}/${order.id}`
                    : `/order/${order.id}`;

                window.open(targetUrl, "_blank");
            });
        }

        return (
            this.state.invoices && this.state.invoices.length > 0 ? (
                <MUIGrid container spacing={3}>
                    {this.state.invoices[0]["orderActivityId"] ? (
                        <MUIGrid item xs={"auto"}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleOnClickOpenOrder(this.state.invoices[0]["orderActivityId"])
                                }}
                            >
                                <MUITypography variant={"body2"} sx={{ display: 'flex', alignItems: 'center' }}>
                                    {`Order # ${this.state.invoices[0]["orderActivityId"]}`}
                                    <MUIOpenInNewIcon fontSize="small" sx={{ ml: '5px' }} />
                                </MUITypography>
                            </a>
                        </MUIGrid>
                    ) : null}

                    {this.state.invoices[0]["salesOrderId"] ? (
                        <MUIGrid item xs={"auto"}>
                            <a
                                href={`https://brightree.net/F1/02200/OrbitMedical/OrderEntry/frmSOOrderRO.aspx?SalesOrderKey=${this.state.invoices[0]["salesOrderId"]}`}
                                target="_blank"
                            >
                                <MUITypography variant={"body2"} sx={{ display: 'flex', alignItems: 'center' }}>
                                    {`Brightree ID ${this.state.invoices[0]["salesOrderId"]}`}
                                    <MUIOpenInNewIcon fontSize="small" sx={{ ml: '5px' }} />
                                </MUITypography>
                            </a>
                        </MUIGrid>
                    ) : null}

                </MUIGrid>
            ) : null
        )
    }

    /**
     * Render all invoice data and claim status dropdown
     */
    renderInvoiceTable() {
        const getInvoiceFollowupStatusOptions = () => {
            return Object.values(this.context.termsIndexed)
                .filter((term) => term.type === "invoice_followup_status")
                .map((option) => {
                    return {
                        title: option.name,
                        value: option.id,
                    };
                });
        };

        const formatToUSCurrency = (value) => {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(value);
        }

        const handleUpdateInvoiceChange = (updatedInvoice) => {
            this.setState((prevState) => {
                const updatedInvoices = prevState.invoices.map((invoice) => {
                    return invoice.id === updatedInvoice.id ? updatedInvoice : invoice;
                });

                return {
                    invoices: updatedInvoices,
                    invoiceIdForApplyToAll: updatedInvoice.id,
                };
            });
        }

        const handleOnClickApplyToAll = (e, selectedOption) => {
            const updatedInvoices = this.state.invoices.map((invoice) => ({
                ...invoice,
                invoiceFollowupStatusTermId: selectedOption,
            }));

            this.setState({
                invoices: updatedInvoices,
                invoiceIdForApplyToAll: null,
            });
        }

        return (
            <MUITableContainer component={MUIPaper} sx={{ mt: 2 }}>
                <MUITable stickyHeader size="small">
                    <MUITableHead>
                        <MUITableRow>
                            <MUITableCell sx={{ whiteSpace: 'nowrap' }}>Invoice #</MUITableCell>
                            <MUITableCell>DoS</MUITableCell>
                            <MUITableCell>{this.state.invoices?.some(invoice => invoice.denials) ? "Payor/HCPCS" : "Payor"}</MUITableCell>
                            <MUITableCell align="right">Amount</MUITableCell>
                            <MUITableCell>Status</MUITableCell>
                        </MUITableRow>
                    </MUITableHead>
                    <MUITableBody>
                        {this.state.invoices && this.state.invoices.length > 0 ? (
                            this.state.invoices
                            .sort((a, b) => new Date(b.dateOfService) - new Date(a.dateOfService))
                            .map((invoice, idx) => {
                                
                                const selectedOption = getInvoiceFollowupStatusOptions().find(
                                    (option) => option.value === invoice.invoiceFollowupStatusTermId
                                );

                                return (
                                    <>
                                        <MUITableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}>
                                            <MUITableCell>
                                                {invoice.id && invoice.key ? (
                                                    <a href={`https://brightree.net/F1/02200/OrbitMedical/Receipts/Invoices/Invoice_Invoice.aspx?InvoiceKey=${invoice.key}`} target="_blank">
                                                        {invoice.id}
                                                    </a>
                                                ) : invoice.id}
                                            </MUITableCell>
                                            <MUITableCell>{invoice.dateOfService ? moment(invoice.dateOfService).format("M/D/YYYY") : null}</MUITableCell>
                                            <MUITableCell >{invoice.payorName ? invoice.payorName : null}</MUITableCell>
                                            <MUITableCell align="right">{invoice.balance ? formatToUSCurrency(invoice.balance) : formatToUSCurrency(0)}</MUITableCell>
                                            <MUITableCell sx={{width: "330px"}}>
                                                <MUIStack direction="row" alignItems="center" spacing={1}>
                                                    <MUIBox sx={{ flexGrow: 1 }}>
                                                        <MUIAutocomplete
                                                            options={getInvoiceFollowupStatusOptions()}
                                                            value={selectedOption || null}
                                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                                            getOptionLabel={(option) => option.title}
                                                            onChange={(e, newValue) => {
                                                                handleUpdateInvoiceChange({
                                                                    ...invoice,
                                                                    invoiceFollowupStatusTermId: newValue ? newValue.value : null,
                                                                });
                                                            }}
                                                            renderInput={(params) => {
                                                                return (
                                                                    <MUITextField
                                                                        {...params}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        size="small"
                                                                        variant="standard"
                                                                        fullWidth={true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </MUIBox>
                                                    {this.state.invoices.length > 1 && selectedOption && this.state.invoiceIdForApplyToAll === invoice.id ? (
                                                        <MUIBox>
                                                            <MUIButton size="small" onClick={(e) => handleOnClickApplyToAll(e, selectedOption.value)}>Apply to all</MUIButton>
                                                        </MUIBox>
                                                    ) : null}
                                                </MUIStack>
                                            </MUITableCell>
                                        </MUITableRow>
                                        {invoice.denials && invoice.denials.length > 0 ? (
                                            invoice.denials
                                                .sort((a, b) => {
                                                    const dateA = new Date(a.created_at);
                                                    const dateB = new Date(b.created_at);
                                                    if (dateB > dateA) return 1;
                                                    if (dateB < dateA) return -1;

                                                    const hcpcsA = this.context.hcpcsCodesIndexed[a.hcpcsCodeId]?.code || '';
                                                    const hcpcsB = this.context.hcpcsCodesIndexed[b.hcpcsCodeId]?.code || '';

                                                    if (hcpcsA < hcpcsB) return -1;
                                                    if (hcpcsA > hcpcsB) return 1;
                                                    return 0;
                                                    return 0;
                                                })
                                                .map((denial) => {
                                                    return (
                                                        <MUITableRow sx={{ backgroundColor: Setting.get("ui.theme") === "dark" ? MUIGreyColor[900] : MUIGreyColor[200] }} key={denial.id}>
                                                            <MUITableCell></MUITableCell>
                                                            <MUITableCell>{denial.created_at ? moment(denial.created_at).format("M/D/YYYY") : null}</MUITableCell>
                                                            <MUITableCell>{denial.hcpcsCodeId ? HcpcsCode.getDisplayName(this.context.hcpcsCodesIndexed[denial.hcpcsCodeId]) : null}</MUITableCell>
                                                            <MUITableCell align="right" sx={{ color: denial.amount < 0 ? (Setting.get("ui.theme") === "dark" ? MUIRedColor[500] : MUIRedColor[800]) : "" }}>
                                                                {denial.amount ? denial.amount < 0 ? `(${formatToUSCurrency(denial.amount * -1)})` : formatToUSCurrency(denial.amount) : formatToUSCurrency(0)}
                                                            </MUITableCell>
                                                            <MUITableCell component="th" scope="row" style={{ display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: '300px' }} title={`${this.context.distributionReasonsIndexed[denial.distributionReasonId].code} - ${DistributionReason.getDisplayName(this.context.distributionReasonsIndexed[denial.distributionReasonId])}`}>
                                                                {denial.distributionReasonId ? `${this.context.distributionReasonsIndexed[denial.distributionReasonId].code} - ${DistributionReason.getDisplayName(this.context.distributionReasonsIndexed[denial.distributionReasonId])}` : null}
                                                            </MUITableCell>
                                                        </MUITableRow>
                                                    );
                                                })
                                        ) : null}
                                    </>
                                )
                            })
                        ) : null}
                    </MUITableBody>
                </MUITable>
            </MUITableContainer>
        )
    }

    /**
     * Render textfield and alert for note section
     */
    renderNoteTextField() {
        const handleNoteTextChange = (updatedFields) => {
            this.setState({
                noteText: updatedFields.noteText
            });
        };

        return (
            <MUIBox sx={{ mt: 3, padding: 0 }}>
                <MUITextField
                    multiline
                    rows={2}
                    disabled={this.state.invoices && this.state.invoices.length > 0 && !this.state.invoices.some((invoice) => invoice.orderActivityId)}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { resize: "both" } }}
                    label={"Note"}
                    value={this.state.noteText ? this.state.noteText : ""}
                    onChange={(e) => handleNoteTextChange({ noteText: e.target.value })}
                    variant="standard"
                />
                {this.state.invoices && this.state.invoices.length > 0 && !this.state.invoices.some((invoice) => invoice.orderActivityId) ? (
                    <MUIAlert severity="warning" sx={{ mt: 1 }}>
                        Cannot add note to invoices that do not have a Sales Pilot Order #.
                    </MUIAlert>
                ) : null}
            </MUIBox>
        )
    }

    renderNextWorkDateAlert() {
        if (this.state.invoices && this.state.invoices.length > 0 && !this.state.invoices.some((invoice) => invoice.denials)) {
            return (
                <MUIAlert severity="info" sx={{ mt: 1 }}>
                    {`Next followup date: ${moment(new Date()).add(3, 'weeks').format('MMMM D, YYYY')} (in 3 weeks)`}
                </MUIAlert>
            )
        }
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        const isDisabled = () => {
            const isAnyFollowupStatusSelected = this.state.invoices?.some((invoice) => invoice.invoiceFollowupStatusTermId != null);

            const isNoteTextProvided = this.state.noteText && this.state.noteText.trim() !== '';
            
            return !(isAnyFollowupStatusSelected || isNoteTextProvided);
        };

        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Cancel
                </MUIButton>
                <MUILoadingButton
                    variant="contained"
                    disabled={isDisabled()}
                    loading={this.props.loading}
                    onClick={() => this.props.onConfirm(this.state.invoices, this.state.noteText)}>
                    Update Invoice
                </MUILoadingButton>
            </MUIDialogActions>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "md";
    }
}
