import Admin from "../Admin";
import React from "react";
import CardAdminHcpcsCodeGeneral from "../../../Component/Card/Admin/HcpcsCode/CardAdminHcpcsCodeGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import HcpcsCodeService from "../../../Seating/Security/HcpcsCodeService/hcpcsCodeService";

import Validator from "../../../Utility/Validator";

import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class HcpcsCodeCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.hcpcsCode = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the hcpcsCode in the state.
     */
    afterComponentDidMount() {
        const hcpcsCodeId = this.props.match.params.id ? +this.props.match.params.id : null;

        if (hcpcsCodeId !== null) {
            const hcpcsCode = this.context.hcpcsCodesIndexed[hcpcsCodeId];
            this.setState({
                hcpcsCode: hcpcsCode,
            });
        }
    }

    /**
     * Creates the hcpcsCode with the current properties in state.hcpcsCode.
     */
    async createHcpcsCode() {
        this.setState({
            isProcessing: true,
        });

        return HcpcsCodeService.createHcpcsCode(this.state.hcpcsCode)
            .then(hcpcsCode => {
                this.context.updateIndexed("hcpcsCodesIndexed", hcpcsCode);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Hcpcs Code created successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Hcpcs Code.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the hcpcsCode with the current properties in state.hcpcsCode.
     */
    async updateHcpcsCode() {
        this.setState({
            isProcessing: true,
        });

        return HcpcsCodeService.updateHcpcsCode(this.state.hcpcsCode)
            .then(hcpcsCode => {
                this.context.updateIndexed("hcpcsCodesIndexed", hcpcsCode);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Hcpcs Code updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Hcpcs Code.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }


    async deleteHcpcsCode() {
        this.setState({
            isProcessing: true,
        });

        return HcpcsCodeService.deleteHcpcsCode(this.state.hcpcsCode.id)
            .then(() => {
                this.context.deleteIndexed("hcpcsCodesIndexed", this.state.hcpcsCode.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Hcpcs Code deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Hcpcs Code.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (hcpcsCode) => {
            this.setState({
                hcpcsCode: {
                    ...this.state.hcpcsCode,
                    ...hcpcsCode,
                },
            });
        };

        return (
            <>
                <CardAdminHcpcsCodeGeneral
                    hcpcsCode={this.state.hcpcsCode}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteHcpcsCode()}
                    text="Are you sure you want to delete this Hcpcs Code? This cannot be undone."
                    header="Delete Hcpcs Code"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Render the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Hcpcs Code';
            subtitle = null;
        } else {
            title = 'Edit Hcpcs Code';
            // subtitle = (this.state.location && (InsuranceSubType.getDisplayName(this.state.insuranceSubType) + " • " + this.state.insuranceSubType.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.hcpcsCode,
                {
                    code: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateHcpcsCode();
                } else {
                    this.createHcpcsCode();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
