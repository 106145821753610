import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import TileInsuranceType from "../../../Tile/TileInsuranceType";
import DialogSearchInsuranceType from "../../../Dialog/Search/DialogSearchInsuranceType";
import TileTerm from "../../../Tile/TileTerm";
import DialogSearchTerm from "../../../Dialog/Search/DialogSearchTerm";

export default class CardAdminInsuranceSubTypeOrganization extends Card {
    constructor(props) {
        super(props);

        this.state.insuranceTypeDialogOpen = false;
        this.state.insuranceSubTypeGroupTermDialogOpen = false;
    }

    static contextTypes = {
        insuranceTypesIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        let insuranceSubType = this.props.insuranceSubType;
        let insuranceType = null;
        let insuranceSubTypeGroupTerm = null;

        if (insuranceSubType && insuranceSubType.insuranceTypeId !== null) {
            insuranceType = this.context.insuranceTypesIndexed[insuranceSubType.insuranceTypeId];
        }

        if (insuranceSubType && insuranceSubType.insuranceSubTypeGroupTermId !== null) {
            insuranceSubTypeGroupTerm = this.context.termsIndexed[insuranceSubType.insuranceSubTypeGroupTermId];
        }

        // insuranceType
        const handleEditInsuranceType = () => {
            this.setState({ insuranceTypeDialogOpen: true });
        };
        const handleClearInsuranceType = () => {
            this.props.onChange({ insuranceTypeId: null });
        };
        const insuranceTypeIconButtons = [
            (<MUIIconButton onClick={handleEditInsuranceType}><MUIEditIcon /></MUIIconButton>)
        ];

        if (insuranceType) {
            insuranceTypeIconButtons.push(
                (<MUIIconButton onClick={handleClearInsuranceType}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // insuranceSubTypeGroupTerm
        const handleEditInsuranceSubTypeGroupTerm = () => {
            this.setState({ insuranceSubTypeGroupTermDialogOpen: true });
        };
        const handleClearInsuranceSubTypeGroupTerm = () => {
            this.props.onChange({ insuranceSubTypeGroupTermId: null });
        };
        const insuranceSubTypeGroupTermIconButtons = [
            (<MUIIconButton onClick={handleEditInsuranceSubTypeGroupTerm}><MUIEditIcon /></MUIIconButton>)
        ];

        if (insuranceSubTypeGroupTerm) {
            insuranceSubTypeGroupTermIconButtons.push(
                (<MUIIconButton onClick={handleClearInsuranceSubTypeGroupTerm}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Render
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileInsuranceType
                            insuranceType={insuranceType}
                            iconButtons={insuranceTypeIconButtons}
                            label="Payor Source"
                            required={true}
                            error={ this.props.validationIssues.insuranceTypeId ? true : false }
                            helperText={ this.props.validationIssues.insuranceTypeId ? this.props.validationIssues.insuranceTypeId : "" }
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileTerm
                            term={insuranceSubTypeGroupTerm}
                            iconButtons={insuranceSubTypeGroupTermIconButtons}
                            label="Group"
                            required={true}
                            error={ this.props.validationIssues.insuranceSubTypeGroupTermId ? true : false }
                            helperText={ this.props.validationIssues.insuranceSubTypeGroupTermId ? this.props.validationIssues.insuranceSubTypeGroupTermId : "" }
                        />
                    </MUIGrid>
                </MUIGrid>
                <DialogSearchInsuranceType
                    open={this.state.insuranceTypeDialogOpen}
                    onClose={() => this.setState({ insuranceTypeDialogOpen: false })}
                    onSelect={(insuranceType) => {
                        this.props.onChange({ 
                            insuranceType: insuranceType,
                            insuranceTypeId: insuranceType.id
                        });
                    }}
                />
                <DialogSearchTerm
                    data={Object.values(this.context.termsIndexed).filter((term) => term.type === "insurance_sub_type_group")}
                    open={this.state.insuranceSubTypeGroupTermDialogOpen}
                    onClose={() => this.setState({ insuranceSubTypeGroupTermDialogOpen: false })}
                    onSelect={(term) => {
                        this.props.onChange({ insuranceSubTypeGroupTermId: term.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Organization";
    }
}