import React from "react";
import Validator from "../Utility/Validator";

export default class ReportSetting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}

        this.state.settings = this.props.settings || {};
        this.state.validationIssues = this.validate();
    }

    /**
     * Validates the current settings and sends the settings and any validation
     * issues back through props.onSettingsChange.
     */
    validate() {
        const validationIssues = Validator.validate(
            this.state.settings,
            this.props.validationRules
        );

        this.props.onSettingsChange(
            this.state.settings,
            validationIssues
        );

        return validationIssues;
    }

    /**
     * Updates state with the new setting and triggers a validation.
     *
     * @param {string} key The setting that changed.
     * @param {*} value The value of that setting.
     */
    handleSettingChange(key, value) {
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [key]: value
            }
        }), () => {
            this.setState({
                validationIssues: this.validate()
            })
        });
    }

}