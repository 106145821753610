import React from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import ProductSizeService from "./productSizeService";
import ProductSubTypeService from "../Security/ProductService/productSubTypeService";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import LayerLegacy from "../../LayerLegacy";

export default class ProductSize extends LayerLegacy {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Size",
                    field: "size",
                    sort: "asc",
                },
                {
                    label: "Product",
                    field: "product",
                    sort: "asc",
                },
                {
                    label: "Display Order",
                    field: "displayorder",
                    sort: "asc",
                },
                {
                    label: "Edit",
                    field: "edit",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            editModal: false,
            currSize: {},
            currIndex: -1,
            errorMessage: "",
            productNames: [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    componentDidMount() {
        this.retrieveProductSizes();
        this.retrieveProductNames();
    }

    retrieveProductNames() {
        let t = this;
        return ProductSubTypeService.getAllProductSubTypes()
            .then((res) => {
                let arr = [];

                res.forEach((product) => {
                    arr.push({
                        label: product.name,
                        value: {
                            id: product.id,
                            name: product.name,
                        },
                    });
                });
                t.setState({
                    productNames: arr,
                });
            })
            .catch((err) => {
                //handle error...BC
            });
    }

    retrieveProductSizes() {
        let t = this;
        return ProductSizeService.getAllProductSizes()
            .then((res) => {
                let arr = [],
                    d = this.state.data;

                res.forEach((product, index) => {
                    arr.push({
                        id: product.id,
                        size: product.size,
                        product: product.productSubType.name,
                        displayorder:
							typeof product.sortOrder === "number" ? product.sortOrder : "no order",
                        edit: t.renderEditButton(product, index),
                    });
                });
                d.rows = arr;
                t.setState({
                    data: d,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderEditButton(product, index) {
        return (
            <MDBIcon
                onClick={() => this.handleEditClick(product, index)}
                icon="edit"
                style={{color: "#7ac5ff"}}
            />
        );
    }


    // handleRemoveClick(id) {
    // 	const{currentUser}=this.context;
    //
    //  ProductSizeService.deleteProductSize(id)
    // 		.then((res) => {
    // 			this.retrieveProductSizes();
    // 			toast.success("Product Size Removed");
    // 		})
    // 		.catch((err) => {
    // 			toast.warn("Error occurred while removing Product Size");
    // 		});
    // }

    handleEditClick(product, index) {
        let p = JSON.parse(JSON.stringify(product));
        // let names = this.state.productNames;
        // // Have to do this since its the insurance obj is missing the order number initially
        // for (let e in names) {
        //     if(names[e].value.id === i.insuranceType.id) {
        //         i.insuranceType = names[e].value;
        //         break;
        //     }
        // }

        this.setState({
            editModal: !this.state.editModal,
            currSize: p,
            currIndex: index,
        });
    }

    editToggle = () => {
        this.setState({
            editModal: !this.state.editModal,
            errorMessage: "",
        });
    };

    renderEditModal() {
        return (
            <Modal isOpen={this.state.editModal} toggle={this.editToggle}>
                <ModalHeader
                    style={{ backgroundColor: "#90A4AE", color: "white" }}
                    toggle={this.editToggle}
                >
					Edit Product Size
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Update Product Size"}
                        onClick={this.handleEditSave}
                    >
                        <MDBIcon far icon="save" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleEditClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderModalBody() {
        if (this.state.editModal) {
            let i = this.state.currSize.productSubType.name;
            return (
                <div>
                    <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                    <Input
                        label="Name"
                        value={this.state.currSize.size}
                        onChange={this.handleEditChange.bind(this, "size")}
                    />
                    <Select
                        placeholder={i}
                        options={this.state.productNames}
                        onChange={this.handleEditChange.bind(this, "productsubtype")}
                    />
                    <Input
                        label="Order"
                        value={String(this.state.currSize.sortOrder)}
                        onChange={this.handleEditChange.bind(this, "sortOrder")}
                    />
                </div>
            );
        }
    }

    handleEditSave = () => {
        if (this.validateEdit()) {
            let obj = this.state.currSize,
                d = this.state.data,
                i = this.state.currIndex,
                t = this;
            return ProductSizeService.updateProductSizes(obj)
                .then((res) => {
                    d.rows[i].size = obj.size;
                    d.rows[i].displayorder = obj.sortOrder;
                    d.rows[i].product = obj.productSubType.name;
                    d.rows[i].edit = t.renderEditButton(obj, i);
                    this.setState({
                        data: d,
                        editModal: !this.state.editModal,
                        errorMessage: "",
                    });
                    toast.success("Product Size Edited!");
                })
                .catch((err) => {
                    toast.warn(
                        "An error occurred: Make sure order number is different from others"
                    );
                });
        }
    };

    validateEdit() {
        let s = this.state.currSize;
        if (s.size === "") {
            this.setErrorMessage("Please enter a Size");
            return false;
        } else if (s.sortOrder === "") {
            this.setErrorMessage("Please enter an Order Number");
            return false;
        }
        return true;
    }

    setErrorMessage(e) {
        this.setState({
            errorMessage: e,
        });
    }

    handleEditClose = () => {
        this.setState({
            currIndex: -1,
            errorMessage: "",
            editModal: !this.state.editModal,
        });
    };

    handleEditChange(property, e) {
        let s = this.state.currSize;

        if (property === "productsubtype") {
            s.productSubType = e.value;
        }
        else if (property === "sortOrder") {
            s[property] = parseInt(e.target.value);
        } else {
            s[property] = e.target.value;
        }

        this.setState({
            currSize: s,
        });
    }

    addNewClick = () => {
        return this.props.history.push({
            pathname: "productSizes/new",
        });
    };

    renderTable() {
        if (this.state.isLoaded === true) {
            return (
                <MDBTable small striped style={{ textAlign: "center" }}>
                    <MDBTableHead columns={this.state.data.columns} />
                    <MDBTableBody rows={this.state.data.rows} />
                </MDBTable>
            );
        } else {
            return <div> </div>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Card>
                    <CardHeader
                        style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}>
                        <Button
                            style={{ float: "right" }}
                            floating
                            size="sm"
                            color={"secondary"}
                            data-tip={"Add New Product Size"}
                            onClick={this.addNewClick}
                        >
                            <MDBIcon icon="ruler" style={{ fontSize: "2em" }} />
                        </Button>
						Product Sizes
                    </CardHeader>
                    <CardBody>{this.renderTable()}</CardBody>

                    <ReactTooltip />
                </Card>
                {this.renderEditModal()}
            </Container>
        );
    }
}
