import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import Location from "../../../../Utility/Crud/Location";

export default withRouter(class CardAdminLocationList extends Card {
    static contextTypes = {
        locationsIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                    { key: "regionId", name: "Sales Region", type: "region", filter: true },
                    { key: "fieldOperationsRegionId", name: "Field Operations Region", type: "region", filter: true },
                    { key: "npi", name: "NPI", type: "number" },
                ]}
                rows={Location.sort(Object.values(this.context.locationsIndexed))}
                onClick={(location) => {
                    this.props.history.push({
                        pathname: "/admin/location/" + location.id
                    });
                }}
            />
        );
    }
});