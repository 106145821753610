import React from "react";
import Chip from "../Chip";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIAvatar from "@mui/material/Avatar";
import Queue from "../../Utility/Crud/Queue";
import PropTypes from "prop-types";

export default class ChipQueue extends Chip {
    static contextTypes = {
        colorsIndexed: PropTypes.object,
        queuesIndexed: PropTypes.object,
    };
    /**
     * Renders a MUI Avatar.
     */
    renderAvatar() {
        const color = (this.props.queue ? Queue.getColor() : MUIGreyColor[500]);
        const avatarColor = this.props.isTransparent ? "transparent" : color;
        const iconColor = this.props.isTransparent ? color : "#fff";

        return (
            <MUIAvatar sx={{ bgcolor: avatarColor }}>
                {Queue.getIcon({ fontSize: "small", sx: { color: iconColor } })}
            </MUIAvatar>
        );
    }

    /**
     * @return {string} The queue description.
     */
    getLabel() {
        return this.props.queue ? Queue.getDisplayName(this.props.queue) : "None";
    }
}
