import api from "../Security/api";

class AccountTypesService {
    async getAllAccountTypes() {
        return new Promise((res, reject) => {
            api
                .get("/accountTypes")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateAccountType(account) {
        return new Promise((res, reject) => {
            api
                .put("/accountTypes", account)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createAccountType(account) {
        return new Promise((res, reject) => {
            api
                .post("/accountTypes", account)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteAccount(id) {
        return new Promise((res, reject) => {
            api
                .delete("/accountTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const accountTypesService = new AccountTypesService();

export default accountTypesService;
