import React from "react";
import Card from "../../../Card";
import PropTypes from "prop-types";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUIBox from "@mui/material/Box";
import TileBaseUnitType from "../../../Tile/TileBaseUnitType";
import TileVendor from "../../../Tile/TileVendor";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import DialogSearchBaseUnitType from "../../../Dialog/Search/DialogSearchBaseUnitType";
import DialogSearchVendor from "../../../Dialog/Search/DialogSearchVendor";

export default class CardAdminBaseUnitGeneral extends Card {
    static contextTypes = {
        baseUnitTypesIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        const baseUnitType = this.props.baseUnit ?
            this.context.baseUnitTypesIndexed[this.props.baseUnit.baseUnitTypeId] :
            null;
        const vendor = this.props.baseUnit ?
            this.context.vendorsIndexed[this.props.baseUnit.vendorId] :
            null;

        const handleEditBaseUnitType = () => {
            this.setState({ baseUnitTypeDialogOpen: true });
        };
        const handleEditVendor = () => {
            this.setState({ vendorDialogOpen: true });
        };

        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <MUITextField
                            value={this.props.baseUnit ? this.props.baseUnit.name : ""}
                            autoFocus={true}
                            size="small"
                            label="Name"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 255,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ name: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ name: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.name ? true : false}
                            helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                        />
                    </MUIGrid>
                    <MUIBox width="100%" />
                    <MUIGrid item xs={12} md={6}>
                        <TileBaseUnitType
                            required={true}
                            error={!!this.props.validationIssues.baseUnitTypeId}
                            helperText={this.props.validationIssues.baseUnitTypeId ? this.props.validationIssues.baseUnitTypeId : null}
                            baseUnitType={baseUnitType}
                            label="Base Unit Type"
                            iconButtons={[
                                (<MUIIconButton onClick={handleEditBaseUnitType}><MUIEditIcon /></MUIIconButton>),
                            ]}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} md={6}>
                        <TileVendor
                            required={true}
                            error={!!this.props.validationIssues.vendorId}
                            helperText={this.props.validationIssues.vendorId ? this.props.validationIssues.vendorId : null}
                            vendor={vendor}
                            label="Vendor"
                            iconButtons={[
                                (<MUIIconButton onClick={handleEditVendor}><MUIEditIcon /></MUIIconButton>),
                            ]}
                        />
                    </MUIGrid>
                </MUIGrid>

                <DialogSearchBaseUnitType
                    open={this.state.baseUnitTypeDialogOpen}
                    onClose={() => this.setState({ baseUnitTypeDialogOpen: false })}
                    onSelect={(baseUnitType) => {
                        this.props.onChange({ baseUnitTypeId: baseUnitType.id });
                    }}
                />
                <DialogSearchVendor
                    open={this.state.vendorDialogOpen}
                    onClose={() => this.setState({ vendorDialogOpen: false })}
                    onSelect={(vendor) => {
                        this.props.onChange({ vendorId: vendor.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}