import React from "react";
import Layer from "../Layer";
import { Redirect } from "react-router-dom";
import Api from "../Seating/Security/api";
import MUIBackdrop from '@mui/material/Backdrop';
import MUIPaper from "@mui/material/Paper";
import Progress from "../Component/Progress";
import Setting from "../Utility/Setting";
import * as Sentry from "@sentry/browser";
import moment from "moment";

export default class Authorize extends Layer {
    /**
     * Get these from Okta. Each unique URL needs it's own Client ID.
     */
    static clientIds = {
        "http://localhost:5173": "0oam9koe0jukyqq45697",
        "https://test1.salespilot.com": "0oam9koe0jukyqq45697",
        "https://test2.salespilot.com": "0oam9koe0jukyqq45697",
        "https://test3.salespilot.com": "0oam9koe0jukyqq45697",
        "https://salespilot.com": "0oam9koe0jukyqq45697",
        "https://corkmedical.salespilot.com": "{CORKMEDICAL APP ID}"
    };

    constructor(props) {
        super(props);

        const currentUrlSearchParams = new URLSearchParams(window.location.search);

        const url = new URL(window.location);
        if (currentUrlSearchParams.has("organization") && currentUrlSearchParams.get("organization").trim() !== "") {
            // Step 1: http://localhost:5173/authorize?organization=rehabmedical
            this.state.mode = "authorizeOkta";

            /**
             * Remove any session information from storage; we're about to set
             * new ones. It's especially important that sessionStorage is
             * removed so that the new localStorage value that gets set can be
             * copied in.
             */
            Setting.clear("session", window.localStorage);
            Setting.clear("session", window.sessionStorage);

            Setting.set("organization", currentUrlSearchParams.get("organization").trim());

            const state = {
                organization: currentUrlSearchParams.get("organization").trim(),
                nonce: crypto.randomUUID()
            };
            const newUrlSearchParams = new URLSearchParams({
                client_id: Authorize.clientIds[url.origin],
                response_type: "code",
                scope: "openid profile email",
                redirect_uri: `${url.origin}/authorize`,
                state: window.btoa(JSON.stringify(state))
            });

            window.location.href = `https://rehabmedical.okta.com/oauth2/v1/authorize?${newUrlSearchParams.toString()}`;
        } else if (currentUrlSearchParams.has("code") && currentUrlSearchParams.get("code").trim() !== "") {
            // Step 2: http://localhost:5173/authorize?code=x&state=y
            Api.post2("/users/token", {
                code: currentUrlSearchParams.get("code").trim(),
                clientName: "Sales Pilot Web",
                clientVersion: window.salesPilot.meta.version,
                RedirectUri: `${url.origin}/authorize`,
            }).then((response) => {
                Setting.set("session", response.session);

                Sentry.setUser({
                    id: response.userprofile.id,
                    username: response.userprofile.username.toLowerCase(),
                    email: response.userprofile.email.toLowerCase()
                });
                Sentry.setTag("organization", Setting.get("organization"));

                Setting.set("userprofile", response.userprofile);

                this.setState({ mode: "success" });
            }).catch((error) => {
                console.error(error);
                Sentry.captureException(error);
                if (error.message.includes('outdated')) {
                    this.setState({ mode: "Client outdated - refresh your browser" });
                } else {
                    this.setState({ mode: "Sales Pilot error" });
                }
            });
        } else if (currentUrlSearchParams.has("error") && currentUrlSearchParams.get("error").trim() !== "") {
            /**
             * An error occured with Okta and it sent the user back here with an error
             *
             * Set state to error and redirect to login.
             */
            this.state.mode = "Okta error";
        }
        else {
            /**
             * Not the initial state /authorize?organization=a
             * Not the redirected state /authorize?code=b&state=c
             * Not a recognized specific error state /authorize?error=d
             *
             * Set state to error and redirect to login.
             */
            this.state.mode = "Unknown error";
        }

    }

    /**
     * Renders page content. Irrelevant unless there was no organization
     * specified, so just handle that situation.
     */
    renderContent() {
        if (this.state.mode === "success") {
            // Successful login; redirect to dashboard.
            return <Redirect to= "/dashboard" />
        } else if (
            this.state.mode === "Okta login failed" ||
            this.state.mode === "Unknown error" ||
            this.state.mode === "Sales Pilot error" ||
            this.state.mode === "Client outdated - refresh your browser"
        ) {
            // Some sort of error. Redirect to login with an error message.
            Setting.set("loginError", `Login failed: ${this.state.mode}. Please try again.`);
            Setting.set("loginErrorExpiration", moment().add(1, "minute").toISOString());
            return <Redirect to= "/" />
        } else {
            // Any other pending state.
            return (
                <MUIBackdrop open={true}>
                    <MUIPaper sx={{ padding: 4, width: 400 }}>
                        <Progress
                            variant="indeterminate"
                            message="Getting you logged in..."
                        />
                    </MUIPaper>
                </MUIBackdrop>
            );
        }
    }

    getBackgroundColor() {
        return "transparent";
    }
}