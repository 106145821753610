import React from "react";
import Card from "../../Card";
import MUIGrid from "@mui/material/Grid";
import MUITypography from "@mui/material/Typography";

export default class CardApprovalSummaryInsurance extends Card {

    /**
     * Renders the insurance details (type, subtype, policy, and group) with appropriate styling
     * based on whether each value is the default "No" value, marking them as disabled if so.
     */
    renderInsuranceDetails(label, insuranceType, insuranceSubType, policy, group) {
        const getDisabledStyle = (value, disabledValue) =>
            value === disabledValue ? { color: "text.disabled" } : {};

        const isInsuranceTypeDisabled = getDisabledStyle(insuranceType, "No Payor");
        const isInsuranceSubTypeDisabled = getDisabledStyle(insuranceSubType, "No Insurance");
        const isPolicyDisabled = getDisabledStyle(policy, "No Policy #");
        const isGroupDisabled = getDisabledStyle(group, "No Group #");

        return (
            <MUIGrid item xs={6}>
                <MUITypography variant="subtitle2">{label}</MUITypography>
                <MUIGrid container rowSpacing={1} spacing={2} sx={{mt: 1}}>
                    <MUIGrid item lg="auto">
                        <MUITypography variant="body2" sx={{ ...isInsuranceTypeDisabled }}>
                            {insuranceType}
                        </MUITypography>
                    </MUIGrid>
                    <MUIGrid item lg="auto">
                        <MUITypography variant="body2" sx={{ ...isInsuranceSubTypeDisabled }}>
                            {insuranceSubType}
                        </MUITypography>
                    </MUIGrid>
                </MUIGrid>
                <br />
                <MUIGrid container rowSpacing={1} spacing={2} sx={{mt: -3}}>
                    <MUIGrid item lg="auto">
                        <MUITypography variant="body2" sx={{ ...isPolicyDisabled }}>
                            {policy}
                        </MUITypography>
                    </MUIGrid>
                    <MUIGrid item lg="auto">
                        <MUITypography variant="body2" sx={{ ...isGroupDisabled }}>
                            {group}
                        </MUITypography>
                    </MUIGrid>
                </MUIGrid>
            </MUIGrid>
        );
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        const getInsuranceDetails = (type, subType, policy, group) => ({
            insuranceType: this.props.order[type]?.name || "No Payor",
            insuranceSubType: this.props.order[subType]?.name || "No Insurance",
            policy: this.props.order[policy] ? `Policy # ${this.props.order[policy]}` : "No Policy #",
            group: this.props.order[group] ? `Group # ${this.props.order[group]}` : "No Group #",
        });

        const primaryInsurance = getInsuranceDetails("insuranceType", "insuranceSubType", "insurancePolicy", "insuranceGroup");
        const secondaryInsurance = getInsuranceDetails("secondaryInsuranceType", "secondaryInsuranceSubType", "secondaryInsurancePolicy", "secondaryInsuranceGroup");

        return (
            <MUIGrid container spacing={1}>
                {this.renderInsuranceDetails('Primary Insurance', primaryInsurance.insuranceType, primaryInsurance.insuranceSubType, primaryInsurance.policy, primaryInsurance.group)}
                {this.renderInsuranceDetails('Secondary Insurance', secondaryInsurance.insuranceType, secondaryInsurance.insuranceSubType, secondaryInsurance.policy, secondaryInsurance.group)}
            </MUIGrid>
        );
    };


    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Insurance";
    }

    /**
     * @returns {string} The minHeight of the card.
     */
    getMinHeight() {
        return "100%";
    }
}