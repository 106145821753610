import React from "react";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";

export default class CardAdminInsuranceSubTypeExternalIdentifiers extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.insuranceSubType && this.props.insuranceSubType.pvPayorId ? this.props.insuranceSubType.pvPayorId : ""}
                        size="small"
                        label="Payor ID"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => { this.props.onChange({ pvPayorId: e.target.value.trim() === "" ? null : e.target.value.trim() })} }
                        onChange={(e) => { this.props.onChange({ pvPayorId: e.target.value })} }
                        error={this.props.validationIssues.pvPayorId ? true : false}
                        helperText={this.props.validationIssues.pvPayorId ? this.props.validationIssues.pvPayorId : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.insuranceSubType && this.props.insuranceSubType.brightreeExternalId ? this.props.insuranceSubType.brightreeExternalId : ""}
                        size="small"
                        label="Brightree ID"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => {
                            if (e.target.value.trim() === "") {
                                // If the input is an empty string, set it to null
                                this.props.onChange({ brightreeExternalId: null });
                            } else if (!isNaN(e.target.value.trim())) {
                                // If it's a valid number, parse it as an integer
                                this.props.onChange({ brightreeExternalId: parseInt(e.target.value.trim()) });
                            } else {
                                // If it's invalid input (non-numeric), keep the value as-is (text)
                                this.props.onChange({ brightreeExternalId: e.target.value.trim() });
                            }
                        }}
                        onChange={(e) => this.props.onChange({ brightreeExternalId: e.target.value })}
                        error={this.props.validationIssues.brightreeExternalId ? true : false}
                        helperText={this.props.validationIssues.brightreeExternalId ? this.props.validationIssues.brightreeExternalId : ""}
                    />
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "External Identifiers";
    }
}