import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import OrderActivityType from "../../Utility/Crud/OrderActivityType";

export default class TileOrderActivityType extends Tile {
    /**
      * @returns {string} The OrderActivityType name.
      */
    getTitle() {
        return this.props.orderActivityType ? OrderActivityType.getDisplayName(this.props.orderActivityType) : "None";
    }

    /**
      * @returns {string} The OrderActivityType activityType.
      */
    getSubtitle() {
        return this.props.orderActivityType ? this.props.orderActivityType.activityType : null;
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.orderActivityType ? OrderActivityType.getDisplayName(this.props.orderActivityType) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.orderActivityType ? OrderActivityType.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return OrderActivityType.getIcon();
    }
}
