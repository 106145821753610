import TermCreateEdit from "../AdminTermCreateEdit";

export default class AdminTermCreateEditMarketingPreferredContactMethod extends TermCreateEdit {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'marketing_preferred_contact_method';
    }
}
