import api from "../Security/api";

class ContactTypeService {
    async getAllContactTypes() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/contactTypes", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateContactType(contact) {
        return new Promise((res, reject) => {
            api
                .put("/contactTypes", contact)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createContactType(contact) {
        return new Promise((res, reject) => {
            api
                .post("/contactTypes", contact)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteContact(id) {
        return new Promise((res, reject) => {
            api
                .delete("/contactTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const contactTypeService = new ContactTypeService();

export default contactTypeService;
