const apiKey = "AIzaSyDwWgl9pPwHeaLuK8QvsDho_INN7gs_Y2Y";
// const apiKey = "AIzaSyBejcoCUW568rST69HI-GXtxFSmFkmOTQM";
// https://console.cloud.google.com/

class GoogleService {
    rootUrl = "https://maps.googleapis.com/maps/api/geocode/json?key=" + apiKey;
    searchAddress(address) {
        let regEx = /\s+/g;
        let a = address.replace(regEx, "+");
        let url = this.rootUrl + "&address=" + a;
        // let url = this.rootUrl + '&address=' + address;
        return new Promise((res, reject) => {
            fetch(url)
                .then((data) => {
                    return res(data.json());
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}

const googleService = new GoogleService();

export default googleService;
