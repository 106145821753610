import Admin from "../Admin";
import React from "react";
import CardAdminDocumentTypeGeneral from "../../../Component/Card/Admin/DocumentType/CardAdminDocumentTypeGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';

import Validator from "../../../Utility/Validator";

import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import DocumentType from "../../../Utility/Crud/DocumentType";
import DocumentTypesService from "../../../Seating/Settings/documentTypesService";

export default class AdminDocumentTypeCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.documentType = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the documentType in the state.
     */
    afterComponentDidMount() {
        const documentTypeId = this.props.match.params.id ? +this.props.match.params.id : null;
        
        if (documentTypeId !== null) {
            const documentType = this.context.documentTypesIndexed[documentTypeId];
            this.setState({
                documentType: documentType,
            });
        }
    }

    /**
     * Creates the document type with the current properties in state.documentType.
     */
    async createDocumentType() {
        this.setState({
            isProcessing: true,
        });

        return DocumentTypesService.createDocumentType(this.state.documentType)
            .then(documentType => {
                this.context.updateIndexed("documentTypesIndexed", documentType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Document Type created successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Document Type.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the document type with the current properties in state.documentType.
     */
    async updateDocumentType() {
        this.setState({
            isProcessing: true,
        });

        return DocumentTypesService.updateDocumentType(this.state.documentType)
            .then(documentType => {
                this.context.updateIndexed("documentTypesIndexed", documentType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Document Type updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Document Type.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }


    async deleteDocumentType() {
        this.setState({
            isProcessing: true,
        });

        return DocumentTypesService.deleteDocumentType(this.state.documentType.id)
            .then(() => {
                this.context.deleteIndexed("documentTypesIndexed", this.state.documentType.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Document Type deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Document Type.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (documentType) => {
            this.setState({
                documentType: {
                    ...this.state.documentType,
                    ...documentType,
                },
            });
        };
        
        return (
            <>
                <CardAdminDocumentTypeGeneral
                    documentType={this.state.documentType}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteDocumentType()}
                    text="Are you sure you want to delete this document type? This cannot be undone."
                    header="Delete Document Type"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Render the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Document Type';
            subtitle = null;
        } else {
            title = 'Edit Document Type';
            subtitle = (this.state.documentType && (DocumentType.getDisplayName(this.state.documentType) + " • " + this.state.documentType.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.documentType,
                {
                    name: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateDocumentType();
                } else {
                    this.createDocumentType();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
