import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import InsuranceSubType from "../../Utility/Crud/InsuranceSubType";
import PropTypes from "prop-types";

export default class TileInsuranceSubType extends Tile {
    static contextTypes = {
        insuranceSubTypesIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The InsuranceType name.
      */
    getTitle() {
        return this.props.insuranceSubType ? InsuranceSubType.getDisplayName(this.props.insuranceSubType) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.insuranceSubType ? InsuranceSubType.getDisplayName(this.props.insuranceSubType) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.insuranceSubType ? InsuranceSubType.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return InsuranceSubType.getIcon();
    }
}
