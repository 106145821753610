import React from "react";
import Card from "../../../Card";
import DataGrid from "../../../DataGrid";
import EmptyState from "../../../EmptyState";
import Progress from "../../../Progress";

export default class CardARDashboardResults extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        if (this.props.isLoading) {
            return (<Progress message="Loading Data..." />);
        }

        if (this.props.rows && this.props.rows.length > 0) {
            return (
                <DataGrid
                    rows={this.props.rows}
                    onClick={this.props.onClick}
                    columns={this.props.columns}
                    enableGroupingPanel={true}
                    grouping={this.props.grouping}
                    onGroupingChange={this.props.onGroupingChange}
                    defaultColumnWidths={this.props.defaultColumnWidths}
                    hideToolbar={true}
                    groupSummaryItems={[
                        {
                            columnName: 'brightree_invoice.balance.bucket_total',
                            type: 'sum',
                            showInGroupFooter: false,
                            alignByColumn: true
                        },
                        {
                            columnName: 'brightree_invoice.balance.bucket_0_30',
                            type: 'sum',
                            showInGroupFooter: false,
                            alignByColumn: true
                        },
                        {
                            columnName: 'brightree_invoice.balance.bucket_31_60',
                            type: 'sum',
                            showInGroupFooter: false,
                            alignByColumn: true
                        },
                        {
                            columnName: 'brightree_invoice.balance.bucket_61_90',
                            type: 'sum',
                            showInGroupFooter: false,
                            alignByColumn: true
                        },
                        {
                            columnName: 'brightree_invoice.balance.bucket_91_120',
                            type: 'sum',
                            showInGroupFooter: false,
                            alignByColumn: true
                        },
                        {
                            columnName: 'brightree_invoice.balance.bucket_121_plus',
                            type: 'sum',
                            showInGroupFooter: false,
                            alignByColumn: true
                        }
                    ]}
                />
            )
        } else {
            return (
                <EmptyState
                    line1={"No Data"}
                />
            )
        }

    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Results";
    }


}