import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import MUITable from "@mui/material/Table";
import MUITableHead from "@mui/material/TableHead";
import MUITableRow from "@mui/material/TableRow";
import MUITableCell from "@mui/material/TableCell";
import MUITableBody from "@mui/material/TableBody";
import MUITablePagination from "@mui/material/TablePagination";
import MUITextField from "@mui/material/TextField";
import MUISearchIcon from "@mui/icons-material/Search";
import * as MUIIcons from "@mui/icons-material";
import MUIAddIcon from "@mui/icons-material/Add";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIFilterAltIcon from "@mui/icons-material/FilterAlt";
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUIGrid from "@mui/material/Grid";
import MUIBadge from "@mui/material/Badge";
import MUIMenuItem from "@mui/material/MenuItem";
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUICheckbox from '@mui/material/Checkbox';
import MUITypography from "@mui/material/Typography";
import MUIStack from "@mui/material/Stack";
import MUIBox from "@mui/material/Box";
import EmptyState from "./EmptyState";
import User from "../Utility/Crud/User";
import Department from "../Utility/Crud/Department";
import Team from "../Utility/Crud/Team";
import OrderStatusReason from "../Utility/Crud/OrderStatusReason";
import OrderActivityType from "../Utility/Crud/OrderActivityType";
import BaseUnitType from "../Utility/Crud/BaseUnitType";
import ChipUser from "./Chip/ChipUser";
import ChipLocation from "./Chip/ChipLocation";
import ChipRegion from "./Chip/ChipRegion";
import ChipDepartment from "./Chip/ChipDepartment";
import ChipTeam from "./Chip/ChipTeam";
import ChipOrderStatusReason from "./Chip/ChipOrderStatusReason";
import ChipOrderActivityType from "./Chip/ChipOrderActivityType";
import ChipBaseUnitType from "./Chip/ChipBaseUnitType";
import ChipInsuranceType from "./Chip/ChipInsuranceType";
import ChipTerm from "./Chip/ChipTerm";
import ChipOrderStatus from "./Chip/ChipOrderStatus";
import ChipQueue from "./Chip/ChipQueue";
import ChipProductType from "./Chip/ChipProductType";
import Menu from "./Menu";
import DialogTableFilter from "./Dialog/DialogTableFilter";
import ChipVendor from "./Chip/ChipVendor";
import Vendor from "../Utility/Crud/Vendor";
import Term from "../Utility/Crud/Term";
import Region from "../Utility/Crud/Region";
import MarketingCampaignCategory from "../Utility/Crud/MarketingCampaignCategory";
import ChipMarketingCampaignCategory from "./Chip/ChipMarketingCampaignCategory";
import IssueCategory from "../Utility/Crud/IssueCategory";
import ChipIssueCategory from "./Chip/ChipIssueCategory";
import InsuranceType from "../Utility/Crud/InsuranceType";
import OrderStatus from "../Utility/Crud/OrderStatus";
import Queue from "../Utility/Crud/Queue";
import Location from "../Utility/Crud/Location";
import ProductType from "../Utility/Crud/ProductType";

export default class Table extends React.Component {

    static contextTypes = {
        usersIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        baseUnitTypesIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
        marketingCampaignCategoriesIndexed: PropTypes.object,
        issueCategoriesIndexed: PropTypes.object,
        regionsIndexed: PropTypes.object,
        insuranceTypesIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
        queuesIndexed: PropTypes.object,
        locationsIndexed: PropTypes.object,
        productTypesIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            query: "",
            filteredRows: props.rows,
            columns: props.columns,
            rowsPerPage: 100,
            page: 0,
            filterDialog: {
                open: false
            },
            selectedRows: this.props.selectedRows || {},
            filters: {}
        };
    }

    /**
     * Set the search results on mount. This ensures that the list is sorted
     * appropriately on first render.
     */
    componentDidMount() {
        this.setState({
            filteredRows: this.getSearchedAndFilteredRows(this.state.query, this.state.filters),
            columns: this.props.checkbox === true
                ? [{ key: "_checkbox", name: "", type: "checkbox" }].concat(this.state.columns)
                : this.state.columns,
        });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.rows.length !== this.props.rows.length
            || JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)
        ) {
            this.setState({
                filteredRows: this.getSearchedAndFilteredRows(this.state.query, this.state.filters)
            });
        }
    }

    /**
     * Renders a table if there are rows to display, otherwise an empty state.
     */
    render() {
        if (this.props.rows.length > 0) {
            return (
                <>
                    {(this.props.search || this.canFilter()) && this.renderSearchAndFilterBar()}
                    {this.canFilter() && this.renderFilterDetails()}
                    {this.canFilter() && this.renderFilterDialog()}
                    {this.state.filteredRows.length > 0 ? (
                        <>
                            <MUITable size="small" sx={{ tableLayout: "fixed" }}>
                                {this.renderTableHead()}
                                {this.renderTableBody()}
                            </MUITable>
                            {this.renderTablePagination()}
                        </>
                    ) : (
                        <EmptyState
                            line1="No results found"
                            line2="Try searching for something a little different."
                        />
                    )}
                </>
            );
        } else {
            return (
                <EmptyState
                    line1="Nothing to show"
                    line2="Better luck next time!"
                />
            );
        }
    }

    /**
     * @returns {boolean} Whether or not to show the filter icon.
     */
    canFilter() {
        for (let i = 0; i < this.state.columns.length; i++) {
            if (this.state.columns[i].filter === true) {
                return true;
            }
        }

        return false;
    }

    /**
     * Render an optional search box above a table.
     */
    renderSearchAndFilterBar() {
        const handleChangeSearch = (e) => {
            this.setState({
                query: e.target.value,
                filteredRows: this.getSearchedAndFilteredRows(e.target.value, this.state.filters)
            });
        };

        const handleClickFilterMenuItem = (column) => {
            // Generate unique rows.
            let rows = {};
            this.props.rows.forEach((row) => {
                rows[row[column.key]] = {
                    id: row[column.key],
                    [column.key]: row[column.key],
                };
            });
            rows = Object.values(rows);

            this.setState({
                filterDialog: {
                    open: true,
                    column: column,
                    rows: rows
                }
            });
        };

        const filterMenuItems = [(<MUIMenuItem disabled={true}>Filters</MUIMenuItem>)];
        this.state.columns.forEach((column) => {
            if (column.filter === true) {
                filterMenuItems.push((
                    <MUIMenuItem value={column.name} onClick={() => handleClickFilterMenuItem(column)}>
                        <MUIListItemIcon>
                            {this.state.filters[column.key] ? (<MUIEditIcon fontSize="small" />) : (<MUIAddIcon fontSize="small" />)}
                        </MUIListItemIcon>
                        {column.name}
                    </MUIMenuItem>
                ));
            }
        });

        return (
            <MUIGrid container spacing={1}>
                <MUIGrid item xs>
                    <MUITextField
                        autoFocus={true}
                        placeholder="Type to search..."
                        hiddenLabel={true}
                        fullWidth={true}
                        variant="filled"
                        size="small"
                        sx={{
                            marginBottom: 2,
                            "& .MuiInputLabel-root": { display: "none", height: 0 }
                        }}
                        InputProps={{
                            startAdornment: (
                                <MUIInputAdornment position="start">
                                    <MUISearchIcon />
                                </MUIInputAdornment>
                            ),
                            disableUnderline: true,
                            style: { borderRadius: 4 }
                        }}
                        onChange={handleChangeSearch}
                    />
                </MUIGrid>
                {this.canFilter() === true && (
                    <MUIGrid item xs="auto">
                        <Menu
                            icon={(<MUIBadge badgeContent={Object.keys(this.state.filters).length || null} color="secondary"><MUIFilterAltIcon /></MUIBadge>)}
                            menuItems={filterMenuItems}
                            tooltip="Filters"
                        />
                    </MUIGrid>
                )}
            </MUIGrid>

        );
    }

    /**
     * Renders the filter detail area.
     */
    renderFilterDetails() {
        if (Object.keys(this.state.filters).length === 0) {
            return null;
        }

        return (
            <MUIBox sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 2, paddingBottom: 0, bgcolor: "action.hover", borderRadius: 1 }}>
                {
                    Object.entries(this.state.filters).map((filter) => {
                        const key = filter[0];

                        const column = this.props.columns.find((column) => {
                            return column.key === key;
                        });

                        const title = (
                            <MUITypography variant="subtitle2">{column.name}</MUITypography>
                        );

                        const values = (
                            <MUIStack direction="row" spacing={1} flexWrap="wrap" useFlexGap={true} sx={{ paddingBottom: 2 }}>
                                {
                                    Object.keys(filter[1]).map((value) => {
                                        return this.renderCellContent(
                                            {
                                                [key]: value
                                            },
                                            column,
                                            key
                                        );
                                    })
                                }
                            </MUIStack>
                        );

                        return (
                            <>
                                {title}
                                {values}
                            </>
                        );
                    })
                }
            </MUIBox>
        );
    }

    /**
     * Renders the filter dialog.
     */
    renderFilterDialog() {
        const handleChange = (selectedRows) => {
            const filters = { ...this.state.filters };
            if (Object.keys(selectedRows).length === 0) {
                delete filters[this.state.filterDialog.column.key];
            } else {
                filters[this.state.filterDialog.column.key] = selectedRows;
            }

            this.setState({
                filters: filters,
                filteredRows: this.getSearchedAndFilteredRows(this.state.query, filters)
            });
        };

        return (
            <DialogTableFilter
                open={this.state.filterDialog.open}
                onClose={() => this.setState({ filterDialog: { open: false } })}
                onChange={handleChange}
                column={this.state.filterDialog.column}
                rows={this.state.filterDialog.rows}
                selectedRows={this.state.filterDialog.column ? this.state.filters[this.state.filterDialog.column.key] : null}
            />
        );
    }

    /**
     * Searches rows in the table. Currently this only supports string and
     * number columns. Other column types can be added as necessary.
     *
     * @param {string} query The search query.
     * @param {object} filters The search filters.
     * @returns A list of rows that match the search.
     */
    getSearchedAndFilteredRows(query, filters) {
        // Filter
        let filteredRows = [];
        if (Object.keys(filters).length === 0) {
            filteredRows = this.props.rows;
        } else {
            this.props.rows.forEach((row) => {
                let filterMatches = true;
                Object.keys(filters).forEach((key) => {
                    if (Object.keys(filters[key]).includes((row[key] !== null) ? row[key].toString() : "null") === false) {
                        filterMatches = false;
                    }
                });
                if (filterMatches === true) {
                    filteredRows.push(row);
                }
            });
        }

        // Search
        let searchedAndFilteredRows = [];
        const queryCleaned = query
            .toLowerCase()
            .trim()
            .replace(/\s+/g, ' ');
        if (queryCleaned === '') {
            searchedAndFilteredRows = filteredRows;
        } else {
            filteredRows.forEach((row) => {
                for (let i = 0; i < this.state.columns.length; i++) {
                    const column = this.state.columns[i];

                    if (column.type === "string") {
                        if (
                            row[column.key] &&
                            row[column.key].toLowerCase().includes(queryCleaned)
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    } else if (column.type === "custom") {
                        if (
                            row[column.key].value &&
                            row[column.key].value.toLowerCase().includes(queryCleaned)
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    } else if (column.type === "number") {
                        if (
                            row[column.key] === +queryCleaned
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    } else if (column.type === "orderActivityType") {
                        if (
                            this.context.orderActivityTypesIndexed[row[column.key]] &&
                            OrderActivityType.match(
                                this.context.orderActivityTypesIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "orderStatusReason") {
                        if (
                            this.context.orderStatusReasonsIndexed[row[column.key]] &&
                            OrderStatusReason.match(
                                this.context.orderStatusReasonsIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "department") {
                        if (
                            this.context.departmentsIndexed[row[column.key]] &&
                            Department.match(
                                this.context.departmentsIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "team") {
                        if (
                            this.context.teamsIndexed[row[column.key]] &&
                            Team.match(
                                this.context.teamsIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "user") {
                        if (
                            this.context.usersIndexed[row[column.key]] &&
                            User.match(
                                this.context.usersIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "location") {
                        if (
                            this.context.locationsIndexed[row[column.key]] &&
                            Location.match(
                                this.context.locationsIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "region") {
                        if (
                            this.context.regionsIndexed[row[column.key]] &&
                            Region.match(
                                this.context.regionsIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "baseUnitType") {
                        if (
                            this.context.baseUnitTypesIndexed[row[column.key]] &&
                            BaseUnitType.match(
                                this.context.baseUnitTypesIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "vendor") {
                        if (
                            this.context.vendorsIndexed[row[column.key]] &&
                            Vendor.match(
                                this.context.vendorsIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "term") {
                        if (
                            this.context.termsIndexed[row[column.key]] &&
                            Term.match(
                                this.context.termsIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "marketingCampaignCategory") {
                        if (
                            this.context.marketingCampaignCategoriesIndexed[row[column.key]] &&
                            MarketingCampaignCategory.match(
                                this.context.marketingCampaignCategoriesIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "issueCategory") {
                        if (
                            this.context.issueCategoriesIndexed[row[column.key]] &&
                            IssueCategory.match(
                                this.context.issueCategoriesIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "insuranceType") {
                        if (
                            this.context.insuranceTypesIndexed[row[column.key]] &&
                            InsuranceType.match(
                                this.context.insuranceTypesIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "orderStatus") {
                        if (
                            this.context.orderStatusesIndexed[row[column.key]] &&
                            OrderStatus.match(
                                this.context.orderStatusesIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "queue") {
                        if (
                            this.context.queuesIndexed[row[column.key]] &&
                            Queue.match(
                                this.context.queuesIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                    else if (column.type === "productType") {
                        if (
                            this.context.productTypesIndexed[row[column.key]] &&
                            ProductType.match(
                                this.context.productTypesIndexed[row[column.key]],
                                queryCleaned
                            )
                        ) {
                            searchedAndFilteredRows.push(row);
                            break;
                        }
                    }
                };
            });
        }

        return this.getSortedRows(searchedAndFilteredRows);
    }

    /**
     * Sorts the rows in the table as requested.
     */
    getSortedRows(rows) {
        if (!this.props.sort) {
            return rows;
        }

        return rows.sort((a, b) => {
            for (let i = 0; i < this.props.sort.length; i++) {
                let aValue;
                let bValue;
                const sort = this.props.sort[i];
                switch (sort.type) {
                    case "orderStatusReason":
                        aValue = this.context.orderStatusReasonsIndexed[a[sort.key]] ?
                            OrderStatusReason.getDisplayName(
                                this.context.orderStatusReasonsIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.orderStatusReasonsIndexed[b[sort.key]] ?
                            OrderStatusReason.getDisplayName(
                                this.context.orderStatusReasonsIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "orderActivityType":
                        aValue = this.context.orderActivityTypesIndexed[a[sort.key]] ?
                            OrderActivityType.getDisplayName(
                                this.context.orderActivityTypesIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.orderActivityTypesIndexed[b[sort.key]] ?
                            OrderActivityType.getDisplayName(
                                this.context.orderActivityTypesIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "baseUnitType":
                        aValue = this.context.baseUnitTypesIndexed[a[sort.key]] ?
                            BaseUnitType.getDisplayName(
                                this.context.baseUnitTypesIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.baseUnitTypesIndexed[b[sort.key]] ?
                            BaseUnitType.getDisplayName(
                                this.context.baseUnitTypesIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "vendor":
                        aValue = this.context.vendorsIndexed[a[sort.key]] ?
                            Vendor.getDisplayName(
                                this.context.vendorsIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.vendorsIndexed[b[sort.key]] ?
                            Vendor.getDisplayName(
                                this.context.vendorsIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "department":
                        aValue = this.context.departmentsIndexed[a[sort.key]] ?
                            Department.getDisplayName(
                                this.context.departmentsIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.departmentsIndexed[b[sort.key]] ?
                            Department.getDisplayName(
                                this.context.departmentsIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "team":
                        aValue = this.context.teamsIndexed[a[sort.key]] ?
                            Team.getDisplayName(
                                this.context.teamsIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.teamsIndexed[b[sort.key]] ?
                            Team.getDisplayName(
                                this.context.teamsIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "user":
                        aValue = this.context.usersIndexed[a[sort.key]] ?
                            User.getDisplayName(
                                this.context.usersIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.usersIndexed[b[sort.key]] ?
                            User.getDisplayName(
                                this.context.usersIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "location":
                        aValue = this.context.locationsIndexed[a[sort.key]] ?
                            Location.getDisplayName(
                                this.context.locationsIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.locationsIndexed[b[sort.key]] ?
                            Location.getDisplayName(
                                this.context.locationsIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "region":
                        aValue = this.context.regionsIndexed[a[sort.key]] ?
                            Region.getDisplayName(
                                this.context.regionsIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.regionsIndexed[b[sort.key]] ?
                            Region.getDisplayName(
                                this.context.regionsIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "term":
                        aValue = this.context.termsIndexed[a[sort.key]] ?
                            Term.getDisplayName(
                                this.context.termsIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.termsIndexed[b[sort.key]] ?
                            Term.getDisplayName(
                                this.context.termsIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "marketingCampaignCategory":
                        aValue = this.context.marketingCampaignCategoriesIndexed[a[sort.key]] ?
                            MarketingCampaignCategory.getDisplayName(
                                this.context.marketingCampaignCategoriesIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.marketingCampaignCategoriesIndexed[b[sort.key]] ?
                            MarketingCampaignCategory.getDisplayName(
                                this.context.marketingCampaignCategoriesIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "issueCategory":
                        aValue = this.context.issueCategoriesIndexed[a[sort.key]] ?
                            IssueCategory.getDisplayName(
                                this.context.issueCategoriesIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.issueCategoriesIndexed[b[sort.key]] ?
                            IssueCategory.getDisplayName(
                                this.context.issueCategoriesIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "insuranceType":
                        aValue = this.context.insuranceTypesIndexed[a[sort.key]] ?
                            InsuranceType.getDisplayName(
                                this.context.insuranceTypesIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.insuranceTypesIndexed[b[sort.key]] ?
                            InsuranceType.getDisplayName(
                                this.context.insuranceTypesIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "orderStatus":
                        aValue = this.context.orderStatusesIndexed[a[sort.key]] ?
                            OrderStatus.getDisplayName(
                                this.context.orderStatusesIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.orderStatusesIndexed[b[sort.key]] ?
                            OrderStatus.getDisplayName(
                                this.context.orderStatusesIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "queue":
                        aValue = this.context.queuesIndexed[a[sort.key]] ?
                            Queue.getDisplayName(
                                this.context.queuesIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.queuesIndexed[b[sort.key]] ?
                            Queue.getDisplayName(
                                this.context.queuesIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    case "productType":
                        aValue = this.context.productTypesIndexed[a[sort.key]] ?
                            ProductType.getDisplayName(
                                this.context.productTypesIndexed[a[sort.key]]
                            ) :
                            "";
                        bValue = this.context.productTypesIndexed[b[sort.key]] ?
                            ProductType.getDisplayName(
                                this.context.productTypesIndexed[b[sort.key]]
                            ) :
                            "";
                        break;
                    default:
                        aValue = a[sort.key];
                        bValue = b[sort.key];
                        break;
                }

                switch (sort.type) {
                    case "number":
                        if (aValue !== bValue) {
                            if (sort.direction === "ascending") {
                                return aValue - bValue;
                            } else if (sort.direction === "descending") {
                                return bValue - aValue;
                            }
                        }
                        break;
                    default:
                        if (aValue !== bValue) {
                            // Handle null or undefined values
                            if (aValue === null || aValue === undefined) {
                                return sort.direction === "ascending" ? -1 : 1;
                            }
                            if (bValue === null || bValue === undefined) {
                                return sort.direction === "ascending" ? 1 : -1;
                            }

                            if (sort.direction === "ascending") {
                                return aValue.localeCompare(bValue);
                            } else if (sort.direction === "descending") {
                                return bValue.localeCompare(aValue);
                            }
                        }
                        break;
                }
            }

            return 0;
        });
    }

    /**
     * Renders the table column headers.
     */
    renderTableHead() {
        return (
            <MUITableHead>
                <MUITableRow>
                    {this.state.columns.map((column, i) => {
                        let width = null;
                        if (column.width !== undefined) {
                            width = column.width;
                        } else if (column.key === "id") {
                            // Default width for ID columns. Can be overridden.
                            width = 75;
                        } else if (column.type === "checkbox") {
                            // Default width for checkbox columns. Can be overridden.
                            width = 50;
                        }

                        return (
                            <MUITableCell key={i} width={width}>{this.props.showHeader !== false && column.name}</MUITableCell>
                        );
                    })}
                </MUITableRow>
            </MUITableHead>
        );
    }

    /**
     * Renders all of the rows in the table.
     */
    renderTableBody() {
        const handleClick = (row) => {
            if (this.props.checkbox === true) {
                this.setState({
                    selectedRows: {
                        ...this.state.selectedRows,
                        ...{
                            [row.id]: this.state.selectedRows[row.id] ? !this.state.selectedRows[row.id] : true
                        },
                    }
                });
            }

            if (this.props.onClick) {
                this.props.onClick(row);
            }
        };

        return (
            <MUITableBody>
                {this.state.filteredRows.slice(this.state.page * this.state.rowsPerPage, (this.state.page * this.state.rowsPerPage) + this.state.rowsPerPage).map((row) => {
                    return (
                        <MUITableRow
                            selected={this.state.selectedRows[row.id] === true}
                            key={row.id}
                            hover={this.props.onClick ? true : false}
                            sx={{ "cursor": this.props.onClick ? "pointer" : "default" }}
                            onClick={() => { handleClick(row); }}
                        >
                            {this.state.columns.map((column, i) => {
                                const padding = column.type === "checkbox" ? "checkbox" : "normal";

                                return (
                                    <MUITableCell padding={padding} key={i}>
                                        {this.renderCellContent(row, column)}
                                    </MUITableCell>
                                );
                            })}
                        </MUITableRow>
                    );
                })}
            </MUITableBody>
        );
    }

    /**
     * Renders table pagination.
     */
    renderTablePagination() {
        const handleChangeRowsPerPage = (e) => {
            this.setState({
                rowsPerPage: e.target.value,
                page: 0
            });
        };

        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };

        return (
            <MUITablePagination
                rowsPerPageOptions={[100]}
                component="div"
                count={this.state.filteredRows.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        marginTop: 2,
                    },
                    '.MuiTablePagination-selectLabel': {
                        marginTop: '13px'
                    },
                    '.MuiTablePagination-displayedRows': {
                        marginTop: '15px'
                    },
                    '.MuiTablePagination-actions': {
                        marginTop: '-3px'
                    }
                }}
            />
        );
    }

    /**
     * Renders the formatted content of a cell in the table.
     *
     * @param {object} row The specific row.
     * @param {object} column The column definition.
     */
    renderCellContent(row, column) {
        let formatted;
        switch (column.type) {
            case "checkbox":
                formatted = (
                    <MUICheckbox checked={this.state.selectedRows[row.id] === true} />
                );
                break;
            case "currency":
                formatted = (
                    <span>
                        {new Intl.NumberFormat(
                            "en-US",
                            { style: "currency", currency: "USD" }
                        ).format(row[column.key])}
                    </span>
                );
                break;
            case "custom":
                switch (row[column.key].component) {
                    case 'ChipProductType':
                        formatted = (
                            <ChipProductType
                                productType={this.context.productTypesIndexed[row[column.key].id]}
                            />
                        );
                        break;
                    case 'ChipBaseUnitType':
                        formatted = (
                            <ChipBaseUnitType
                                baseUnitType={this.context.baseUnitTypesIndexed[row[column.key].id]}
                            />
                        );
                        break;
                    default:
                        formatted = null;
                        break;
                }
                break;
            case "user":
                formatted = (
                    <ChipUser user={this.context.usersIndexed[row[column.key]]} />
                );
                break;
            case "location":
                formatted = (
                    <ChipLocation location={this.context.locationsIndexed[row[column.key]]} />
                );
                break;
            case "region":
                formatted = (
                    <ChipRegion region={this.context.regionsIndexed[row[column.key]]} />
                );
                break;
            case "orderStatusReason":
                formatted = (
                    <ChipOrderStatusReason orderStatusReason={this.context.orderStatusReasonsIndexed[row[column.key]]} />
                );
                break;
            case "orderActivityType":
                formatted = (
                    <ChipOrderActivityType orderActivityType={this.context.orderActivityTypesIndexed[row[column.key]]} />
                );
                break;
            case "department":
                formatted = (
                    <ChipDepartment department={this.context.departmentsIndexed[row[column.key]]} />
                );
                break;
            case "team":
                formatted = (
                    <ChipTeam team={this.context.teamsIndexed[row[column.key]]} />
                );
                break;
            case "baseUnitType":
                formatted = (
                    <ChipBaseUnitType baseUnitType={this.context.baseUnitTypesIndexed[row[column.key]]} />
                );
                break;
            case "vendor":
                formatted = (
                    <ChipVendor vendor={this.context.vendorsIndexed[row[column.key]]} />
                );
                break;
            case "term":
                formatted = (
                    <ChipTerm term={this.context.termsIndexed[row[column.key]]} />
                );
                break;
            case "marketingCampaignCategory":
                formatted = (
                    <ChipMarketingCampaignCategory marketingCampaignCategory={this.context.marketingCampaignCategoriesIndexed[row[column.key]]} />
                );
                break;
            case "issueCategory":
                formatted = (
                    <ChipIssueCategory issueCategory={this.context.issueCategoriesIndexed[row[column.key]]} />
                );
                break;
            case "insuranceType":
                formatted = (
                    <ChipInsuranceType insuranceType={this.context.insuranceTypesIndexed[row[column.key]]} />
                );
                break;
            case "orderStatus":
                formatted = (
                    <ChipOrderStatus orderStatus={this.context.orderStatusesIndexed[row[column.key]]} />
                );
                break;
            case "queue":
                formatted = (
                    <ChipQueue queue={this.context.queuesIndexed[row[column.key]]} />
                );
                break;
            case "productType":
                formatted = (
                    <ChipProductType productType={this.context.productTypesIndexed[row[column.key]]} />
                );
                break;
            case "boolean":
                const IconComponent = MUIIcons[column.icon];

                formatted = row[column.key] === true ? (
                    <IconComponent
                        fontSize="small"
                        sx={{ color: column.color }}
                    />
                ) : null;
                break;
            case "rowActions":
                const menuItems = this.props.rowActions.map((rowAction, i) =>
                    React.cloneElement(rowAction.component, {
                        key: i,
                        onClick: () => {
                            this.props.onClickRowAction(row);
                        },
                        disabled: rowAction.isDisabled(row)
                    })
                );

                formatted = (
                    <Menu anchorOriginHorizontal="left" transformOriginHorizontal="left"  menuItems={menuItems} onClick={() => this.props.handleClickRowAction(row)} />
                );
                break;
            default:
                formatted = (
                    <span>
                        {row[column.key]}
                    </span>
                );
        }

        return formatted;
    }
}