import TermCreateEdit from "../AdminTermCreateEdit";

export default class AdminTermCreateEditQueueType extends TermCreateEdit {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'region_type';
    }
}
