import React from "react";
import DialogSearch from "../DialogSearch";
import TileOrderStatus from "../../Tile/TileOrderStatus";
import PropTypes from "prop-types";
import OrderStatus from "../../../Utility/Crud/OrderStatus";

export default class DialogSearchOrderStatus extends DialogSearch {

    static contextTypes = {
        orderStatusesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered orderStatus.
     */
    getSearchResults(query) {
        return OrderStatus.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.orderStatusesIndexed);
        }
    }

    /**
     * @param {object} orderStatus The orderStatus object.
     * @returns A TileOrderStatus component for this orderStatus.
     */
    renderTile(orderStatus) {
        return (
            <TileOrderStatus
                key={orderStatus.id}
                orderStatus={orderStatus}
            />
        );
    }
}
