import Card from "../../../Card";
import React from "react";
import TileOrderActivityType from "../../../Tile/TileOrderActivityType";
import PropTypes from "prop-types";
import TileOrderStatusReason from "../../../Tile/TileOrderStatusReason";
import MUIGrid from "@mui/material/Grid";
import MUIBox from "@mui/material/Box";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUINewReleasesIcon from "@mui/icons-material/NewReleases";
import MUIIconButton from "@mui/material/IconButton";
import DialogSearchOrderActivityType from "../../../Dialog/Search/DialogSearchOrderActivityType";
import DialogSearchOrderStatusReason from "../../../Dialog/Search/DialogSearchOrderStatusReason";
import MUIFormControlLabel from '@mui/material/FormControlLabel';
import MUISwitch from '@mui/material/Switch';
import Tile from "../../../Tile";

export default class CardAdminQueueRouteDetail extends Card {
    constructor(props) {
        super(props);
        this.state.isInitialRoute = props.queueRoute && props.queueRoute.fromOrderStatusReasonId === null;
        this.props.onChange({ isInitialRoute: this.state.isInitialRoute });
    }

    static contextTypes = {
        orderActivityTypesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        const orderActivityType = this.props.queueRoute ?
            this.context.orderActivityTypesIndexed[this.props.queueRoute.orderActivityTypeId] :
            null;
        const fromOrderStatusReason = this.props.queueRoute ?
            this.context.orderStatusReasonsIndexed[this.props.queueRoute.fromOrderStatusReasonId] :
            null;
        const toOrderStatusReason = this.props.queueRoute ?
            this.context.orderStatusReasonsIndexed[this.props.queueRoute.toOrderStatusReasonId] :
            null;

        const handleEditOrderActivityType = () => {
            this.setState({ orderActivityTypeDialogOpen: true });
        };
        const handleEditFromOrderStatusReason = () => {
            this.setState({ fromOrderStatusReasonDialogOpen: true });
        };
        const handleEditToOrderStatusReason = () => {
            this.setState({ toOrderStatusReasonDialogOpen: true });
        };
        const handleInitialRouteChange = (e) => {
            this.props.onChange({ isInitialRoute: e.target.checked });
            if (e.target.checked === true) {
                this.props.onChange({ fromOrderStatusReasonId: null });
            }
            this.setState({ isInitialRoute: e.target.checked });
        };

        let fromTile;
        if (this.state.isInitialRoute === true) {
            fromTile = (
                <Tile
                    orderStatusReason={fromOrderStatusReason}
                    label="From Order Status Reason"
                    title="Initial"
                    avatarIcon={(<MUINewReleasesIcon />)}
                />
            );
        } else {
            fromTile = (
                <TileOrderStatusReason
                    required={true}
                    error={!!this.props.validationIssues.fromOrderStatusReasonId}
                    helperText={this.props.validationIssues.fromOrderStatusReasonId ? this.props.validationIssues.fromOrderStatusReasonId : null}
                    orderStatusReason={fromOrderStatusReason}
                    label="From Order Status Reason"
                    iconButtons={[
                        (<MUIIconButton onClick={handleEditFromOrderStatusReason}><MUIEditIcon /></MUIIconButton>),
                    ]}
                />
            );
        }

        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12}>
                        <MUIFormControlLabel
                            control={<MUISwitch />}
                            label="Initial Route"
                            onChange={handleInitialRouteChange}
                            checked={this.state.isInitialRoute}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} md={6}>
                        <TileOrderActivityType
                            required={true}
                            error={!!this.props.validationIssues.orderActivityTypeId}
                            helperText={this.props.validationIssues.orderActivityTypeId ? this.props.validationIssues.orderActivityTypeId : null}
                            orderActivityType={orderActivityType}
                            label="Order Activity Type"
                            iconButtons={[
                                (<MUIIconButton onClick={handleEditOrderActivityType}><MUIEditIcon /></MUIIconButton>),
                            ]}
                        />
                    </MUIGrid>
                    <MUIBox width="100%" />
                    <MUIGrid item xs={12} md={6}>
                        {fromTile}
                    </MUIGrid>
                    <MUIGrid item xs={12} md={6}>
                        <TileOrderStatusReason
                            required={true}
                            error={!!this.props.validationIssues.toOrderStatusReasonId}
                            helperText={this.props.validationIssues.toOrderStatusReasonId ? this.props.validationIssues.toOrderStatusReasonId : null}
                            orderStatusReason={toOrderStatusReason}
                            label="To Order Status Reason"
                            iconButtons={[
                                (<MUIIconButton onClick={handleEditToOrderStatusReason}><MUIEditIcon /></MUIIconButton>),
                            ]}
                        />
                    </MUIGrid>
                </MUIGrid>

                <DialogSearchOrderActivityType
                    open={this.state.orderActivityTypeDialogOpen}
                    onClose={() => this.setState({ orderActivityTypeDialogOpen: false })}
                    onSelect={(orderActivityType) => {
                        this.props.onChange({ orderActivityTypeId: orderActivityType.id });
                    }}
                />
                <DialogSearchOrderStatusReason
                    open={this.state.fromOrderStatusReasonDialogOpen}
                    onClose={() => this.setState({ fromOrderStatusReasonDialogOpen: false })}
                    onSelect={(orderStatusReason) => {
                        this.props.onChange({ fromOrderStatusReasonId: orderStatusReason.id });
                    }}
                />
                <DialogSearchOrderStatusReason
                    open={this.state.toOrderStatusReasonDialogOpen}
                    onClose={() => this.setState({ toOrderStatusReasonDialogOpen: false })}
                    onSelect={(orderStatusReason) => {
                        this.props.onChange({ toOrderStatusReasonId: orderStatusReason.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Details";
    }
}