import Chart from "../Chart";
import React from "react";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUICard from "@mui/material/Card";
import MUICardContent from "@mui/material/CardContent";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import Menu from "../../../Component/Menu";
import Progress from "../../../Component/Progress";
import CardChartPatientGeneral from "../../../Component/Card/Chart/Patient/CardChartPatientGeneral";
import CardChartPatientContactInfo from "../../../Component/Card/Chart/Patient/CardChartPatientContactInfo";
import CardChartPatientSalesInfo from "../../../Component/Card/Chart/Patient/CardChartPatientSalesInfo";
import CardChartPatientInsuranceInfo from "../../../Component/Card/Chart/Patient/CardChartPatientInsuranceInfo";
import OrderService from "../../../Seating/Security/OrderService/orderService";
import Validator from "../../../Utility/Validator";
import AccountService from "../../../Seating/Security/AccountService/accountService";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class ChartPatientCreateEdit extends Chart {
    constructor(props) {
        super(props);

        this.state.isUpdate = !!props.match.params.id;

        this.state.isProcessing = false;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
        this.state.patient = null;
    }

    /**
     * Set the Patient and data in state.
     */
    afterComponentDidMount() {
        const patient = this.props.match.params.id ? +this.props.match.params.id : null;
        if (patient !== null) {
            this.setState({
                patient: this.context.patientsIndexed[patientId],
            });
        }
    }

    /**
     * Creates the Patient with the current properties in state.patient.
     */
    async createPatient() {
        this.setState({
            isProcessing: true,
        });

        return OrderService.createPatient(this.state.patient)
            .then((res) => {
                window.open(`/patientInformation/${res.id}`, '_blank');
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Patient created successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Patient", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (patient) => {
            this.setState({
                patient: {
                    ...this.state.patient,
                    ...patient
                },
            });
        };

        return (
            <>
                <CardChartPatientGeneral
                    patient={this.state.patient}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardChartPatientContactInfo
                    patient={this.state.patient}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardChartPatientSalesInfo
                    patient={this.state.patient}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardChartPatientInsuranceInfo
                    patient={this.state.patient}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
            </>
        );
    }

    /**
    * @returns Page header
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Patient';
            subtitle = null;
        } else {
            title = 'Edit Patient';
            subtitle = null;
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleSave = () => {
            const validationIssues = Validator.validate(
                this.state.patient,
                {
                    firstName: {
                        'required': true
                    },
                    lastName: {
                        'required': true
                    },
                    dateOfBirth: {
                        'required': true
                    },
                    phone: {
                        'required': true,
                        'type': 'phone',
                    },
                    insuranceType: {
                        'required': true
                    },
                    insuranceSubType: {
                        'required': true
                    },
                    account: {
                        'required': true
                    }
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updatePatient();
                } else {
                    this.createPatient();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }
        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
