import React from "react";
import MUICard from "@mui/material/Card";
import MUIStack from "@mui/material/Stack";
import MUICardHeader from "@mui/material/CardHeader";
import MUICardContent from "@mui/material/CardContent";

export default class Card extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * @returns A MUI Card component with a header and content.
     */
    render() {
        return (
            <MUICard sx={{ marginBottom: this.getMarginBottom(), background: this.getBackground(), minHeight: this.getMinHeight() }}>
                { this.renderHeader() }

                <MUICardContent sx={{ padding: this.getPadding() }}>
                    { this.renderContent() }
                </MUICardContent>
            </MUICard>
        );
    }

    /**
     * Render the card header. Defaults to empty; subclasses should override.
     */
    renderHeader() {
        return (
            <MUICardHeader
                titleTypographyProps={ {
                    fontSize: 18,
                    fontWeight: "light",
                    color: "text.secondary"
                } }
                action={ this.renderActions() }
                title={ this.getTitle() }
                sx={{ paddingBottom: 0 }}
            />
        );
    }

    /**
     * Render the card content. Defaults to empty; subclasses should override.
     */
    renderContent() {
        return null;
    }

    /**
     * Render a menu on the top right if there are menu items, and render a
     * stack of buttons if there are buttons.
     */
    renderActions() {
        const actions = this.getActions();
        if (actions !== null) {
            return (
                <MUIStack direction="row" spacing={1}>
                    {this.getActions().map((action, i) => (
                        React.cloneElement(action, { key: i })
                    ))}
                </MUIStack>
            );
        }

        return null;
    }

    /**
     * @returns {string} The title of the card. Defaults to empty; subclasses
     * should override.
     */
    getTitle() {
        return null;
    }

    /**
     * @returns {array} The MUIMenuItems/buttons/etc for the top right of the
     * card. Defaults to empty; subclasses should override.
     */
    getActions() {
        return null;
    }

    /**
     * @returns {string} the background color of the card. This can be anything
     * you can put in the CSS "background" attribute. Defaults to white.
     */
    getBackground() {
        return null;
    }

    /**
     * @returns {string} the minimum height of the card. Override if necessary;
     * defaults to empty. Most common to override when cards are used in a grid and
     * we need cards to match the same height (especially on page resize).
     */
    getMinHeight() {
        return null;
    }

    /**
     * @returns {number} The padding of the card. Override if necessary;
     * defaults to 2 (16px). Most common situation is to override to 0 for a
     * "full-bleed" look.
     */
    getPadding() {
        return 2;
    }

    /**
     * @returns {number} The margin of the card. Override if necessary;
     * defaults to 2 (16px). Most common situation is to override to 0 for a
     * "full-bleed" look.
     */
    getMarginBottom() {
        return 2;
    }
}