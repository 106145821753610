/**
 * Static functions for user settings. Saves everything to localStorage and
 * handles the (de)serialization automatically. Be sure to use good setting
 * names; namespacing to the layer is a good idea. Examples:
 *
 * ui.theme: light|dark
 * queue.68.view: {"sorts":["order_activity.id"]}
 */
export default class Setting {
    static memo = {};

    /**
     * Set a setting.
     *
     * Usage: Setting.set("foo", "bar"); Usage: Setting.set({"foo": "bar",
     * "baz": "bam"});
     *
     * @param {string|Object} key Either the string key of the setting or an
     * object containing all of the settings to set.
     * @param {*} value An optional value; must be serializable to JSON.
     * @param {object} storage The type of storage. Defaults to localStorage but
     * can be changed.
     */
    static set(key, value, storage = window.localStorage) {
        if (typeof key === "object") {
            Object.keys(key).forEach((k) => {
                const memoKey = this.getMemoKey(k, storage);
                delete this.memo[memoKey];
                storage.setItem(k, JSON.stringify(key[k]));
                window.dispatchEvent(new Event(`setting.${k}`));
            });
        } else {
            const memoKey = this.getMemoKey(key, storage);
            delete this.memo[memoKey];
            storage.setItem(key, JSON.stringify(value));
            window.dispatchEvent(new Event(`setting.${key}`));
        }
    }

    /**
     * Get a setting.
     *
     * @param {string} key The setting to get.
     * @param {boolean} useMemo Whether or not to use the memo. Useful in
     * cross-tab situations where one tab updates a setting and another tab
     * needs to ignore it's own memo.
     * @param {object} storage The type of storage. Defaults to localStorage but
     * can be changed.
     *
     * @returns {*} The value of the setting or undefined if not found.
     */
    static get(key, useMemo = true, storage = window.localStorage) {
        const memoKey = this.getMemoKey(key, storage);
        if (useMemo === false || this.memo.hasOwnProperty(key) === false) {
            const item = storage.getItem(key);
            if (item === null) { // Not set
                this.memo[memoKey] = undefined;
            } else {
                this.memo[memoKey] = JSON.parse(item);
            }
        }
        return this.memo[memoKey];
    }

    /**
     * Clear a setting.
     *
     * @param {string} key The setting to get.
     * @param {object} storage The type of storage. Defaults to localStorage but
     * can be changed.
     *
     * @returns {*} The value of the setting or null if not found.
     */
    static clear(key, storage = window.localStorage) {
        const memoKey = this.getMemoKey(key, storage);
        delete this.memo[memoKey];
        storage.removeItem(key);
        window.dispatchEvent(new Event(`setting.${key}`));
    }

    /**
     *
     * @param {string} key The storage key.
     * @param {object} storage localStorage|sessionStorage
     *
     * @returns {string} The memo key.
     */
    static getMemoKey(key, storage) {
        if (storage === window.localStorage) {
            return `local.${key}`;
        } else {
            return `session.${key}`;
        }
    }
}
