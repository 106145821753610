import api from "../api";

class OrderActivityTypeService {
    async getAllOrderActivityTypes() {
        return new Promise((res, reject) => {
            api
                .get("/allOrderActivityTypes")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getOrderActivityTypeById(id) {
        return new Promise((res, reject) => {
            api
                .get("/orderActivityTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createOrderActivityType(obj) {
        return new Promise((res, reject) => {
            api
                .post("/orderActivityTypes", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateOrderActivityType(obj) {
        return new Promise((res, reject) => {
            api
                .put("/orderActivityTypes", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteOrderActivityType(id) {
        return new Promise((res, rej) => {
            return api.delete('/orderActivityTypes/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const orderActivityTypeService = new OrderActivityTypeService();

export default orderActivityTypeService;
