import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import HcpcsCode from "../../../../Utility/Crud/HcpcsCode";


export default withRouter(class CardAdminHcpcsCodeList extends Card {
    static contextTypes = {
        hcpcsCodesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "code", name: "Code", type: "number", width: "200px" },
                    { key: "descriptionLong", name: "Long Description", type: "string" },
                    { key: "descriptionShort", name: "Short Description", type: "string", width: "300px" },
                ]}
                rows={HcpcsCode.sort(Object.values(this.context.hcpcsCodesIndexed))}
                onClick={(hcpcsCode) => {
                    this.props.history.push({
                        pathname: "/admin/hcpcsCode/" + hcpcsCode.id
                    });
                }}
            />
        );
    }
});