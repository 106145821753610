import React from "react";
import moment from "moment";
import Card from "../../../Card";
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIFileDownloadIcon from '@mui/icons-material/FileDownload';
import MUIReviewsIcon from '@mui/icons-material/Reviews';
import MUIMenuItem from "@mui/material/MenuItem";
import Progress from "../../../Progress";
import EmptyState from "../../../EmptyState";
import Table from "../../../Table";
import Exporter from "../../../../Utility/Exporter";
import Menu from "../../../Menu";

export default class CardReportReverseQualityResults extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        if (!this.props.isLoading) {
            if (this.props.rows && this.props.rows.length > 0) {
                return (
                    <Table
                        search={true}
                        columns={[
                            { key: "", name: "", type: "rowActions", width: "60" },
                            { key: "orderId", name: "Order #", type: "number", width: "100" },
                            { key: "reportedDetails", name: "Reported By", type: "string" },
                            { key: "userReported", name: "User Reported", type: "string" },
                            { key: `reviewedDetails`, name: "Reviewed By", type: "string" },
                            { key: "reverseQualityCategory", name: "Category", type: "string" },
                            { key: "reverseQualityReason", name: "Reason", type: "string" },
                            { key: "notes", name: "Notes", type: "string", width: "600" },
                        ]}
                        rows={this.props.rows}
                        rowActions={[{
                            component: (
                                <MUIMenuItem>
                                    <MUIListItemIcon>
                                        <MUIReviewsIcon fontSize="small" />
                                    </MUIListItemIcon>
                                    Mark as reviewed
                                </MUIMenuItem>
                            ),
                            isDisabled: (row) => {
                                return row["reviewedOn"] !== null;
                            }
                        }]}
                        onClickRowAction={this.props.onClickRowAction.bind(this)}
                    />
                )
            } else {
                return (
                    <EmptyState
                        line1={this.props.loadedAt ? "No results found" : "Waiting for results"}
                        line2={this.props.loadedAt ? "Try adjusting your settings" : "Please select your settings"}
                    />
                )
            }

        }
        return (<Progress message="Loading Data..." />);
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Results";
    }

    /**
     * Renders an export button that allows users to download a CSV file of the table data.
     * The CSV includes renamed column headers and rows with formatted values, including team, queue, location, department, and user details.
     */
    getActions() {
        const handleExport = () => {
            const csvRows = [];

            // Rename column headers
            const columnNames = Object.keys(this.props.rows[0]).map((column) => {
                if (column === "id" || column === "reportedDetails" || column === "reviewedDetails") {
                    return null;
                }

                let renamedColumn = column;

                if (column === "orderId") {
                    renamedColumn = "Order ID";
                }
                if (column === "reportedBy") {
                    renamedColumn = "Reported By";
                }
                if (column === "reportedOn") {
                    renamedColumn = "Reported On";
                }
                if (column === "userReported") {
                    renamedColumn = "User Reported";
                }
                if (column === "reviewedBy") {
                    renamedColumn = "Reviewed By";
                }
                if (column === "reviewedOn") {
                    renamedColumn = "Reviewed On";
                }
                if (column === "reverseQualityCategory") {
                    renamedColumn = "RQ Category";
                }
                if (column === "reverseQualityReason") {
                    renamedColumn = "RQ Reason";
                }
                if (column === "notes") {
                    renamedColumn = "Notes";
                }

                return renamedColumn
            }).filter((column) => column !== null);
            csvRows.push(columnNames);

            // Table rows
            this.props.rows.forEach((row) => {
                const csvRow = [];
                columnNames.forEach((column) => {
                    if (column === "Order ID") {
                        const orderId = row["orderId"] ? row["orderId"] : "";
                        csvRow.push(orderId);
                    } else if (column === "Reported By") {
                        const reportedBy = row["reportedBy"] ? row["reportedBy"] : "";
                        csvRow.push(reportedBy);
                    } else if (column === "Reported On") {
                        const reportedOn = row["reportedOn"] ? row["reportedOn"] : "";
                        csvRow.push(reportedOn);
                    } else if (column === "User Reported") {
                        const userReported = row["userReported"] ? row["userReported"] : "";
                        csvRow.push(userReported);
                    } else if (column === "Reviewed By") {
                        const reviewedBy = row["reviewedBy"] ? row["reviewedBy"] : "";
                        csvRow.push(reviewedBy);
                    } else if (column === "Reviewed On") {
                        const reviewedOn = row["reviewedOn"] ? row["reviewedOn"] : "";
                        csvRow.push(reviewedOn);
                    } else if (column === "RQ Category") {
                        const reverseQualityCategory = row["reverseQualityCategory"] ? row["reverseQualityCategory"] : "";
                        csvRow.push(reverseQualityCategory);
                    } else if (column === "RQ Reason") {
                        const reverseQualityReason = row["reverseQualityReason"] ? row["reverseQualityReason"] : "";
                        csvRow.push(reverseQualityReason);
                    } else if (column === "Notes") {
                        const notes = row["notes"] ? row["notes"] : "";
                        csvRow.push(notes);
                    } else {
                        csvRow.push(row[column] || "");
                    }
                });
                csvRows.push(csvRow);
            });

            const filename = `Reverse Quality Logs - ${moment(this.props.loadedAt).format("YYYY-MM-DD h mm a")}.csv`;

            Exporter.exportCSV(csvRows, filename);
        }

        return ([
            <Menu
                menuItems={[(
                    <MUIMenuItem disabled={this.props.isLoading || !this.props.rows || (this.props.rows && this.props.rows.length === 0)} onClick={handleExport}>
                        <MUIListItemIcon>
                            <MUIFileDownloadIcon fontSize="small" />
                        </MUIListItemIcon>
                        Export CSV
                    </MUIMenuItem>
                )]}
            >
            </Menu>
        ]);
    }
}