import api from "../api";

class IssueCategoryService {
    async getAllIssueCategories() {
        return new Promise((res, reject) => {
            api
                .get("/issueCategories")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getIssueCategoryById(id) {
        return new Promise((res, reject) => {
            api
                .get("/issueCategories/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createIssueCategory(obj) {
        return new Promise((res, reject) => {
            api
                .post("/issueCategories", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateIssueCategory(obj) {
        return new Promise((res, reject) => {
            api
                .put("/issueCategories", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteIssueCategory(id) {
        return new Promise((res, rej) => {
            return api.delete('/issueCategories/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const issueCategoryService = new IssueCategoryService();

export default issueCategoryService;
