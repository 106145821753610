import api from "../api";

class BaseUnitService {
    async getAllBaseUnits() {
        return new Promise((res, reject) => {
            api
                .get("/baseUnits")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getBaseUnitById(id) {
        return new Promise((res, reject) => {
            api
                .get("/baseUnits/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createBaseUnit(obj) {
        return new Promise((res, reject) => {
            api
                .post("/baseUnits", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateBaseUnit(obj) {
        return new Promise((res, reject) => {
            api
                .put("/baseUnits", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteBaseUnit(id) {
        return new Promise((res, rej) => {
            return api.delete('/baseUnits/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const baseUnitService = new BaseUnitService();

export default baseUnitService;
