import React from "react";
import DialogSearch from "../DialogSearch";
import TileServiceReason from "../../Tile/TileServiceReason";
import PropTypes from "prop-types";
import ServiceReason from "../../../Utility/Crud/ServiceReason";

export default class DialogSearchServiceReason extends DialogSearch {

    static contextTypes = {
        serviceReasonsIndexed: PropTypes.object,
    };

    /**
     * Searches for Order Activity Types by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered serviceReasons.
     */
    getSearchResults(query) {
        return ServiceReason.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.serviceReasonsIndexed);
        }
    }

    /**
     * @param {object} serviceReason The serviceReason object.
     * @returns A TileServiceReason component for this serviceReason.
     */
    renderTile(serviceReason) {
        return (
            <TileServiceReason
                key={serviceReason.id}
                serviceReason={serviceReason}
            />
        );
    }
}
