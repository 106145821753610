import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Card from "../../../Card";
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIFileDownloadIcon from '@mui/icons-material/FileDownload';
import MUIMenuItem from "@mui/material/MenuItem";
import Progress from "../../../Progress";
import EmptyState from "../../../EmptyState";
import Table from "../../../Table";
import Exporter from "../../../../Utility/Exporter";
import Menu from "../../../Menu";

export default class CardReportMetricResults extends Card {
    static contextTypes = {
        teamsIndexed: PropTypes.object,
        queuesIndexed: PropTypes.object,
        locationsIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        if (!this.props.isLoading) {
            if (this.props.rows && this.props.rows.length > 0) {
                return (
                    <Table
                        columns={[
                            { key: "teamId", name: "Team", type: "team", width: "220", filter: true  },
                            { key: "queueId", name: "Queue", type: "queue", width: "220", filter: true  },
                            { key: "locationId", name: "Location", type: "location", width: "220", filter: true  },
                            { key: `date`, name: "Date", type: "string", width: "120" },
                            { key: "countOutOfBounds", name: "OOB", type: "number" },
                            { key: "count", name: "Count", type: "number" },
                            { key: "oobPercentage", name: "OOB %", type: "string" },
                        ]}
                        rows={this.props.rows}
                    />
                )
            } else {
                return (
                    <EmptyState
                        line1={this.props.loadedAt ? "No results found" : "Waiting for results"}
                        line2={this.props.loadedAt ? "Try adjusting your settings" : "Please select your settings"}
                    />
                )
            }

        }
        return (<Progress message="Loading Data..." />);
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Results";
    }

    /**
     * Renders an export button that allows users to download a CSV file of the table data.
     * The CSV includes renamed column headers and rows with formatted values, including team, queue, location, department, and user details.
     */
    getActions() {
        const handleExport = () => {
            const csvRows = [];

            // Rename column headers
            const columnNames = Object.keys(this.props.rows[0]).map((column) => {
                let renamedColumn = column;

                if (column === "id") {
                    renamedColumn = "ID";
                }
                if (column === "teamId") {
                    renamedColumn = "Team";
                }
                if (column === "queueId") {
                    renamedColumn = "Queue";
                }
                if (column === "departmentId") {
                    renamedColumn = "Department";
                }
                if (column === "departmentDirectorId") {
                    renamedColumn = "Director";
                }
                if (column === "departmentExecutiveId") {
                    renamedColumn = "Executive";
                }
                if (column === "teamManagerId") {
                    renamedColumn = "Manager";
                }
                if (column === "locationId") {
                    renamedColumn = "Location";
                }
                if (column === "date") {
                    renamedColumn = "Date";
                }
                if (column === "countOutOfBounds") {
                    renamedColumn = "OOB"
                }
                if (column === "count") {
                    renamedColumn = "Count"
                }
                if (column === "oobPercentage") {
                    renamedColumn = "OOB %";
                }

                return renamedColumn
            });
            csvRows.push(columnNames);

            // Table rows
            this.props.rows.forEach((row) => {
                const csvRow = [];
                columnNames.forEach((column) => {
                    if (column === "ID") {
                        const id = row["id"] ? row["id"] : "";
                        csvRow.push(id);
                    } else if (column === "Team") {
                        const teamName = row["teamId"] ? this.context.teamsIndexed[row["teamId"]].name : "";
                        csvRow.push(teamName);
                    } else if (column === "Queue") {
                        const queueName = row["queueId"] ? this.context.queuesIndexed[row["queueId"]].name : "";
                        csvRow.push(queueName);
                    } else if (column === "Department") {
                        const departmentName = row["departmentId"] ? this.context.departmentsIndexed[row["departmentId"]].name : "";
                        csvRow.push(departmentName);
                    } else if (column === "Director") {
                        const directorName = row["departmentDirectorId"] ? `${this.context.usersIndexed[row["departmentDirectorId"]].firstname} ${this.context.usersIndexed[row["departmentDirectorId"]].lastname}`  : "";
                        csvRow.push(directorName);
                    } else if (column === "Executive") {
                        const executiveName = row["departmentExecutiveId"] ? `${this.context.usersIndexed[row["departmentExecutiveId"]].firstname} ${this.context.usersIndexed[row["departmentExecutiveId"]].lastname}` : "";
                        csvRow.push(executiveName);
                    } else if (column === "Manager") {
                        const teamManagerName = row["teamManagerId"] ? `${this.context.usersIndexed[row["teamManagerId"]].firstname} ${this.context.usersIndexed[row["teamManagerId"]].lastname}` : "";
                        csvRow.push(teamManagerName);
                    } else if (column === "Location") {
                        const locationName = row["locationId"] ? this.context.locationsIndexed[row["locationId"]].name : "";
                        csvRow.push(locationName);
                    } else if (column === "Date") {
                        const date = row["date"] ? row["date"] : "";
                        csvRow.push(date);
                    } else if (column === "OOB") {
                        const countOutOfBounds = row["countOutOfBounds"] ? row["countOutOfBounds"] : 0;
                        csvRow.push(countOutOfBounds);
                    } else if (column === "Count") {
                        const count = row["count"] ? row["count"] : 0;
                        csvRow.push(count);
                    } else if (column === "OOB %") {
                        const oobPercentage = row["oobPercentage"] ? row["oobPercentage"] : "";
                        csvRow.push(oobPercentage);
                    } else {
                        csvRow.push(row[column]);
                    }
                });
                csvRows.push(csvRow);
            });

            const filename = `Metric Report - ${moment(this.props.loadedAt).format("YYYY-MM-DD h mm a")}.csv`;

            Exporter.exportCSV(csvRows, filename);
        }

        return ([
            <Menu
                menuItems={[(
                    <MUIMenuItem disabled={this.props.isLoading || !this.props.rows || (this.props.rows && this.props.rows.length === 0)} onClick={handleExport}>
                        <MUIListItemIcon>
                            <MUIFileDownloadIcon fontSize="small" />
                        </MUIListItemIcon>
                        Export CSV
                    </MUIMenuItem>
                )]}
            >
            </Menu>
        ]);
    }
}