import Crud from "../Crud";

/**
 * Static helper functions related to Colors.
 */
export default class Color extends Crud {

    /**
     * @param {object} color The color object.
     * @returns The display name of the color.
     */
    static getDisplayName(color) {
        return color.name;
    }

    /**
     * Determines if a color matches a search query.
     *
     * @param {object} color The color to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the color matches the query.
     */
    static match(color, query) {
        return (
            (color.name !== null && color.name.toLowerCase().includes(query)) ||
            (color.shade !== null && color.shade.toLowerCase().includes(query)) ||
            (color.hex_code !== null && color.hex_code.toLowerCase().includes(query)) ||
            color.id === +query
        );
    }

    /**
     * @param {array} colors The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(colors) {
        return colors.sort((a, b) => {
            return (a.ordinal).localeCompare(b.ordinal);
        });
    }

    /**
     * Gets a contrasting foreground color for a given background color.
     *
     * @param {object} color The background color
     * @returns {string} color The foreground color
     */
    static getForegroundColor(color) {
        const r = parseInt(color.hexCode.substring(1, 3), 16);
        const g = parseInt(color.hexCode.substring(3, 5), 16);
        const b = parseInt(color.hexCode.substring(5, 7), 16);
        const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        if (brightness >= 128) {
            return "#000";
        } else {
            return "#fff";
        }
    }
}