import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminSalesPcrLinkList extends Card {
    static contextTypes = {
        salesPcrLinksIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={ true }
                columns={ [
                    { key: "id", name: "ID", type: "number" },
                    { key: "salesRepId", name: "Sales Rep", type: "user", filter: true },
                    { key: "patientCareRepId", name: "PCR", type: "user", filter: true },
                ]}
                sort={[
                    {
                        key: "salesRepId",
                        direction: "ascending",
                        type: "user"
                    },
                    {
                        key: "patientCareRepId",
                        direction: "ascending",
                        type: "user"
                    },

                ]}
                rows={ Object.values(this.context.salesPcrLinksIndexed) }
                onClick={ (salesPcrLink) => {
                    this.props.history.push({
                        pathname: "/admin/salesPcrLink/" + salesPcrLink.id
                    });
                } }
            />
        );
    }
});