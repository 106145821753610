import React from "react";
import {Container, Spinner, MDBIcon} from "mdbreact";
import ReactTooltip from "react-tooltip";
import MUIPaper from "@mui/material/Paper";
import {DataGrid} from "devextreme-react";
import {
    ColumnChooser,
    Column,
    FilterRow,
    Grouping,
    GroupPanel,
    Paging,
    Sorting,
    Summary,
    GroupItem,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";
import PropTypes from "prop-types";
import GlobalFunctions from "../Filters/GlobalFunctions";
import NoteService from "../../Seating/Security/NoteService/noteService";
import { Link } from "react-router-dom";


export default class ReadyToScheduleGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipContent: "",
            renderEditOrderModal: props.renderEditOrderModal,
            gotoMap: props.gotoMap,
        };
    }

    static contextTypes = {
        companyName: PropTypes.string
    };


    formatDate(date) {
        if (date == null || date === "") {
            return "";
        }
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        }).format(date);
    }

    formatTime(date) {
        if (date == null || date === "") {
            return "";
        }
        return new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "2-digit",
        }).format(date);
    }

    formatToolTip(result) {
        if (result == null) {
            return;
        }

        let strResult = "",
            count = result.length >= 2 ? 2 : result.length;
        if (count > 0) {
            for (let i = 0; i < count; i++) {
                strResult +=
					"\nUser: " +
				result[i].createdBy.firstname + " " + result[i].createdBy.lastname +
					"\n" +
					"Date: " +
					GlobalFunctions.formatDate(result[i].createdAt) +
					"\n" +
					"Note: " +
					result[i].text;

                if (i < count - 1) {
                    strResult += "\n----------------------";
                }
            }
        }
        return strResult;
    }

    setTooltipContent(id) {
        NoteService.getNotes(id)
            .then((res) => {
                this.setState({tooltipContent: res});
                return res;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    cellWithTooltip(cellData) {
        return (
            <div>
                <div
                    data-tip
                    data-event={"click focus"}
                    data-for={"notes"}
                    onMouseEnter={() => {
                        this.setTooltipContent(cellData.data.order);
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    {cellData.data.lastNoteDate}
                </div>
                <ReactTooltip
                    className={"note-tooltip"}
                    id={"notes"}
                    place={"left"}
                    delayHide={0}
                    globalEventOff={"click"}
                    effect={"solid"}
                    getContent={() => (
                        <p> {this.formatToolTip(this.state.tooltipContent)} </p>
                    )}
                />
            </div>
        );
    }

    toggleCell(cellData) {
        return (
            <div>
                <a
                    href="#void"
                    data-tip={"Toggle this order on the map"}
                    onClick={() => this.state.gotoMap(cellData.data.order)}
                >
                    <MDBIcon icon="map-marker"/>
                </a>
                <ReactTooltip/>
            </div>
        );
    }

    linkingCell(cellData) {
        return (
            <div style={{ cursor: "pointer" }}>
                <span
                    style={{color: "blue"}}
                    onClick={(e) => {
                        if (cellData && cellData.data && (cellData.data.type === "Service" || cellData.data.type === "Ticket" || cellData.data.type === "Pickup" || cellData.data.type === "Billing Issue")) {
                            this.setState({
                                targetPage: "/patientInformation/",
                                targetLink: cellData.data.patientId + "/" + cellData.value,
                            });

                            setTimeout(() => {
                                document.getElementById("orderListId").click();
                            }, 5);
                            return;
                        }
                        this.setState({
                            targetPage: "/order/",
                            targetLink: cellData.value,
                        });

                        setTimeout(() => {
                            document.getElementById("orderListId").click();
                        }, 5);
                        // this.state.gotoMap(cellData.data.order);
                        // window.open("/order/" + cellData.data.order, "_blank");
                    }}
                >
                    {cellData.value}
                </span>
            </div>
        );
    }

    scheduleCell(cellData) {
        return (
            <div>
                <a
                    href="#void"
                    data-tip={"Schedule Delivery"}
                    onClick={() => {
                        this.state.renderEditOrderModal(cellData ? cellData.data.order : "");
                    }}
                >
                    <MDBIcon icon="calendar" />
                </a>
                <ReactTooltip />
            </div>
        );
    }

    hasAllProductsIcon(cellData) {
        if(cellData.data.missingIds){
            return (
                <div data-tip={"Needs Brightree IDs"}>


                    <MDBIcon icon="fill" />

                    <ReactTooltip />
                </div>
            );
        }

    }

    hasPatientRegistration(cellData) {
        if (cellData.data) {
            let reg = cellData.data.patientRegistered;
            if (reg === true) {
                return (
                    <div data-tip={"No Changes Indicated in Registration"}>
                        <MDBIcon icon="check-circle" style={{color: "green"}}/>
                        <ReactTooltip/>
                    </div>
                );
            } else if (reg === false) {
                return (
                    <div data-tip={"Changes Indicated in Registration"}>
                        <MDBIcon icon="times-circle" style={{color: "red"}}/>
                        <ReactTooltip/>
                    </div>
                );

            } else
                return (
                    <div data-tip={"Registration Not Started"}>
                        <MDBIcon far icon="circle"/>
                        <ReactTooltip/>
                    </div>
                );
        }
    }

    determinePediatricGroup() {
        const {companyName} = this.context;

        if (companyName === 'rehabmedical' || companyName === 'uat') {
            return 0;
        }
        return null;
    }

    render() {
        let h = window.innerHeight;

        if (!this.props.isLoaded && this.props.selectedLocationIds.length > 0) {
            return this.renderLoadingSpinner();
        }

        return (
            <div
                style={{ overflowY: "scroll", height: h * 0.85, maxHeight: h * 0.85 }}
            >
                <Link
                    id="orderListId"
                    to={{
                        pathname: `${this.state.targetPage}${this.state.targetLink}`,
                        state: { goto: "billing" },
                    }}
                    target="_blank"
                    rel="opener"
                    style={{ display: "none" }}
                    activeclassname="active"
                />
                <MUIPaper>
                    <DataGrid
                        className={"rtsGrid"}
                        style={{ maxWidth: "1800px !important" }}
                        dataSource={this.props.dataNotScheduled}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        showBorders={true}
                    >
                        <ColumnChooser enabled={true} mode="select" />

                        <Paging enabled={false}/>
                        <Sorting mode={"single"}/>

                        <GroupPanel visible={true}/>
                        <Grouping autoExpandAll={false}/>

                        <FilterRow visible={true}/>

                        <Column cellRender={this.toggleCell.bind(this)} width={20} />

                        <Column cellRender={this.scheduleCell.bind(this)} width={20} />
                        <Column cellRender={this.hasPatientRegistration.bind(this)} width={20} />
                        <Column cellRender={this.hasAllProductsIcon.bind(this)} width={20} />

                        <Column
                            dataField={"order"}
                            width={80}
                            cellRender={this.linkingCell.bind(this)}
                            alignment={"left"}
                        />
                        <Column dataField={"type"} groupIndex={2}/>
                        <Column dataField={"reason"} groupIndex={1}/>
                        <Column dataField={"pediatric"} groupIndex={this.determinePediatricGroup()}/>
                        <Column dataField={"name"}/>
                        <Column dataField={"address"}/>
                        <Column dataField={"city"}/>
                        <Column dataField={"state"}/>
                        <Column dataField={"zip"} alignment={"left"}/>
                        <Column dataField={"product"}/>
                        <Column dataField={"pts"} alignment={"left"}/>

                        <Column dataField={"tech"} />
                        <Column dataField={"scheduledDate"} />
                        <Column dataField={"expectedShipDate"}/>
                        <Column dataField={"approvalDate"} />
                        <Column dataField={"equipmentOrderedDate"} />
                        <Column dataField={"pediatric"} />
                        <Column
                            dataField={"lastNoteDate"}
                            cellRender={this.cellWithTooltip.bind(this)}
                        />

                        <Summary>
                            <GroupItem
                                column="order"
                                summaryType="count"
                                displayFormat={"{0} orders"}
                            />
                            {/*<GroupItem*/}
                            {/*    column="SaleAmount"*/}
                            {/*    summaryType="max"*/}
                            {/*    valueFormat="currency"*/}
                            {/*    showInGroupFooter={false}*/}
                            {/*    alignByColumn={true} />*/}
                            {/*<GroupItem*/}
                            {/*    column="TotalAmount"*/}
                            {/*    summaryType="max"*/}
                            {/*    valueFormat="currency"*/}
                            {/*    showInGroupFooter={false}*/}
                            {/*    alignByColumn={true} />*/}
                            {/*<GroupItem*/}
                            {/*    column="TotalAmount"*/}
                            {/*    summaryType="sum"*/}
                            {/*    valueFormat="currency"*/}
                            {/*    displayFormat={'Total: {0}'}*/}
                            {/*    showInGroupFooter={true} />*/}
                        </Summary>
                    </DataGrid>
                </MUIPaper>
                <ReactTooltip/>
            </div>
        );
    }
}
