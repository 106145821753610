import React from "react";
import DialogSearch from "../DialogSearch";
import TileIssueStatus from "../../Tile/TileIssueStatus";
import PropTypes from "prop-types";
import IssueStatus from "../../../Utility/Crud/IssueStatus";

export default class DialogSearchIssueStatus extends DialogSearch {

    static contextTypes = {
        issueStatusesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered issueStatus.
     */
    getSearchResults(query) {
        return IssueStatus.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.issueStatusesIndexed);
        }
    }

    /**
     * @param {object} issueStatus The issueStatus object.
     * @returns A TileIssueStatus component for this issueStatus.
     */
    renderTile(issueStatus) {
        return (
            <TileIssueStatus
                key={issueStatus.id}
                issueStatus={issueStatus}
            />
        );
    }
}
