import React from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    toast,
    MDBToastContainer,
    MDBModal,
    MDBModalBody,
    MDBRow,
    MDBCol,
    MDBModalHeader,
    MDBInput,
    MDBModalFooter,
    MDBBtn,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import StateSalesTaxService from "./stateSalesTaxService";
import LayerLegacy from "../../LayerLegacy";

export default class accountTypes extends LayerLegacy {
    constructor(props) {
        super(props);

        const columns = ["ID", "State", "Abbreviation", "Tax Rate"];
        const data = [];

        this.state = {
            data: data,
            alteredData: [],
            columns: columns,
            editedRows: [],
            differenceModalOpen: false,
            isLoaded: false,
            name: "",
            currIndex: -1,
            errorMessage: "",
            currAccount: {},
        };
    }

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.retrieveSalesTaxTable();
    }

    retrieveSalesTaxTable() {
        let t = this;

        StateSalesTaxService.getAllStates()
            .then((res) => {
                t.setState({
                    data: res,
                    alteredData: [],
                    differenceModalOpen: false,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                //handle error...BC
            });
    }

    alterData(obj, e) {
        let ad = this.state.alteredData;

        let idx = ad.findIndex((v) => {
            return v.id === obj.id;
        });

        if (idx > -1) {
            ad[idx].salesTax = e.target.valueAsNumber;
        } else {
            obj.salesTax = e.target.valueAsNumber;
            ad.push(obj);
        }

        this.setState({
            alteredData: ad,
        });
    }

    compareData() {
        let od = this.state.data,
            ad = this.state.alteredData;

        let difference = ad.filter((x) => !od.includes(x));

        this.setState({
            differenceModalOpen: true,
            editedRows: difference,
        });

        return;
    }

    async updateTaxRates() {
        let ad = this.state.alteredData,
            errors = [];

        this.setState({
            isLoaded: false,
        });

        await Promise.all(
            ad.map(async (r) => {
                await StateSalesTaxService.updateState(r)
                    .then((res) => {
                    })
                    .catch((e) => {
                        errors.push(r);
                    });
            })
        );

        if (errors.length === 0) {
            toast.success("Updated Successfully");
        } else {
            toast.danger("There was an error while saving");
        }

		 this.retrieveSalesTaxTable();
    }

    renderTaxRows() {
        if (this.state.isLoaded === true) {
            return this.state.data.map((t, idx) => {
                let border = "";
                if (idx % 2 === 0) {
                    border = "2px solid #CCC";
                }

                let no = Object.assign({}, t);

                return (
                    <MDBRow style={{ border: border, padding: 2 }} key={idx}>
                        <MDBCol>
                            <h5 style={{ lineHeight: 2 }}>{t.name}</h5>
                        </MDBCol>
                        <MDBCol>
                            <h5 style={{ lineHeight: 2 }}>{t.abbr}</h5>
                        </MDBCol>
                        <MDBCol size="2">
                            <MDBInput
                                style={{ backgroundColor: "#FFF" }}
                                type={"number"}
                                containerClass={"containerMargin"}
                                label={"Tax Percent"}
                                outline
                                onChange={(e) => this.alterData(no, e)}
                                valueDefault={t.salesTax}
                            />
                        </MDBCol>
                    </MDBRow>
                );
            });
        }
    }

    renderDifferenceModal() {
        let difference = this.state.editedRows;

        return (
            <MDBModal
                centered
                isOpen={this.state.differenceModalOpen}
                toggle={() =>
                    this.setState({
                        differenceModalOpen: false,
                    })
                }
            >
                <MDBModalHeader>Review Changes</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow style={{ fontWeight: "bold" }}>
                        <MDBCol>State</MDBCol>
                        <MDBCol>Abbreviation</MDBCol>
                        <MDBCol>State Tax</MDBCol>
                    </MDBRow>

                    {difference.map((d, idx) => {
                        return (
                            <MDBRow key={idx}>
                                <MDBCol>{d.name}</MDBCol>
                                <MDBCol>{d.abbr}</MDBCol>
                                <MDBCol>{d.salesTax}%</MDBCol>
                            </MDBRow>
                        );
                    })}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn data-text={"Confirm"} onClick={() => this.updateTaxRates()}>
                        <MDBIcon icon={"check"} color={"success"} />
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                {this.renderDifferenceModal()}
                <MDBToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Card>
                    <CardHeader
                        style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30 }}>
						State Sales Tax
                        <Button
                            style={{ float: "right" }}
                            floating
                            size="sm"
                            color={"secondary"}
                            data-tip={"Review Changes"}
                            onClick={() => this.compareData()}
                        >
                            <MDBIcon icon="address-card" style={{ fontSize: "2em" }} />
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <MDBRow style={{ fontWeight: "bold" }}>
                            <MDBCol>State</MDBCol>
                            <MDBCol>Abbreviation</MDBCol>
                            <MDBCol size="2">State Tax</MDBCol>
                        </MDBRow>
                        {this.renderTaxRows()}
                    </CardBody>
                </Card>
                <ReactTooltip />
            </Container>
        );
    }
}
