import React from "react";
import Dialog from "../../Dialog";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUIButton from "@mui/material/Button";
import MUISearchIcon from "@mui/icons-material/Search";
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import MUITableContainer from '@mui/material/TableContainer';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import MUIPaper from "@mui/material/Paper";
import MUIBox from "@mui/material/Box";
import MUIOpenInNewIcon from "@mui/icons-material/OpenInNew";
import MUIIconButton from "@mui/material/IconButton";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIBlueColor from "@mui/material/colors/blue";
import EmptyState from "../../EmptyState";
import Progress from "../../Progress";
import moment from "moment";
import Setting from "../../../Utility/Setting";

export default class DialogSearchPatient extends Dialog {
    /**
     * Render search result tiles in a grid.
     */
      renderContent() {
        const handleClick = (e, row) => {
            e.stopPropagation();
            window.open(`/patientInformation/${row.id}`, "_blank");
        }

        if (this.props.isLoading) {
            return (
                <MUIBox sx={{ height: 300 }}>
                    <Progress message="Fetching Results..." />
                </MUIBox>
            )
        }

        if (this.props.rows && this.props.rows.length > 0) {
            return (
                <MUITableContainer component={MUIPaper} sx={{height: 500, overflow: 'auto'}}>
                    <MUITable stickyHeader size="small" sx={{ minWidth: 700 }}>
                        <MUITableHead>
                            <MUITableRow>
                                <MUITableCell>First Name</MUITableCell>
                                <MUITableCell align="left">Last Name</MUITableCell>
                                <MUITableCell align="left">City</MUITableCell>
                                <MUITableCell align="left">DOB</MUITableCell>
                                <MUITableCell align="left">Brightree&nbsp;ID</MUITableCell>
                                <MUITableCell align="left"></MUITableCell>
                            </MUITableRow>
                        </MUITableHead>
                        <MUITableBody>
                            {this.props.rows.map((row, idx) => (
                                <MUITableRow
                                    hover
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
                                    onClick={() => this.props.onSelect(row)}
                                >
                                    <MUITableCell component="th" scope="row">{row.firstName}</MUITableCell>
                                    <MUITableCell align="left">{row.lastName}</MUITableCell>
                                    <MUITableCell align="left">
                                        {row.city && row.state ? `${row.city}, ${row.state}` :
                                            row.city ? row.city :
                                                row.state ? row.state : ''}
                                    </MUITableCell>
                                    <MUITableCell align="left">{moment(row.dateOfBirth, "MM/DD/YYYY").format("M/D/YYYY")}</MUITableCell>
                                    <MUITableCell align="left">
                                        <a
                                            href={`https://brightree.net/F1/02200/OrbitMedical/Patient/frmPatientSummary.aspx?PatientKey=${row.brightreeExternalKey}&showAck=1`}
                                            target="_blank"
                                            style={{
                                                fontWeight: "400",
                                                textDecoration: "none",
                                                color: Setting.get("ui.theme") === "dark" ? MUIBlueColor[200] : MUIBlueColor[700],
                                            }}
                                            onMouseEnter={(e) => {
                                                e.target.style.fontWeight = "500";
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.fontWeight = "400";
                                            }}
                                        >
                                            {row.brightreeExternalId}
                                        </a>
                                    </MUITableCell>
                                    <MUITableCell align="left"><MUIIconButton onClick={(e) => handleClick(e, row)}><MUIOpenInNewIcon /></MUIIconButton></MUITableCell>
                                </MUITableRow>
                            ))}
                        </MUITableBody>
                    </MUITable>
                </MUITableContainer>
            );
         } else {
            return (
                <MUIBox sx={{height: 300, display: 'flex', alignItems: 'center'}}>
                    <EmptyState
                        line1={"No results"}
                        line2={"Enter a name to begin your search"}
                    />
                </MUIBox>
            )
        }

    }

    /**
     * Render the search box.
     */
    renderHeader() {
        const handleChange = (property, e) => {
            this.setState({
                [property]: e.target.value
            })
        };

        const handleSearch = () => {
            let searchParams = { ...this.props.searchParams }; 
        
            if (this.state.firstname !== undefined) {
                searchParams.firstname = this.state.firstname;
            }
            if (this.state.lastname !== undefined) {
                searchParams.lastname = this.state.lastname;
            }
    
            this.props.onSearch(searchParams);
        };
        
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                handleSearch(); 
            }
        }

        return (
            <MUIGrid container spacing={1} sx={{display: 'flex', alignItems: 'center'}}>
                <MUIGrid item xs={5} sx={{ paddingRight: 1 }}>
                    <MUITextField
                        defaultValue={this.props.searchParams ? this.props.searchParams.firstname : ""}
                        autoFocus={true}
                        placeholder="First Name..."
                        variant="standard"
                        onChange={(e) => handleChange("firstname", e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        InputProps={{
                            startAdornment: (
                                <MUIInputAdornment position="start">
                                    <MUISearchIcon sx={{ml: 1}}/>
                                </MUIInputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                        sx={{
                            width: '100%',
                            backgroundColor: Setting.get("ui.theme") === "dark" ? MUIGreyColor[800] : MUIGreyColor[100], 
                            borderRadius: '4px',  
                            padding: "5px 0px",
                            '& .MuiInputBase-root': {
                                backgroundColor: 'transparent',  
                            }
                        }}
                    />
                </MUIGrid>
                <MUIGrid item xs={5} sx={{ paddingRight: 1 }}>
                    <MUITextField
                        defaultValue={this.props.searchParams ? this.props.searchParams.lastname : ""}
                        placeholder="Last Name..."
                        variant="standard"
                        onChange={(e) => handleChange("lastname", e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        InputProps={{
                            startAdornment: (
                                <MUIInputAdornment position="start">
                                    <MUISearchIcon sx={{ml: 1}}/>
                                </MUIInputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                        sx={{
                            width: '100%',
                            backgroundColor: Setting.get("ui.theme") === "dark" ? MUIGreyColor[800] : MUIGreyColor[100], 
                            borderRadius: '4px',  
                            padding: "5px 0px",
                            '& .MuiInputBase-root': {
                                backgroundColor: 'transparent',  
                            }
                        }}
                    />
                </MUIGrid>
                <MUIGrid item xs="auto">
                    <MUIButton
                        variant="text"
                        onClick={handleSearch}
                    >
                        Search
                    </MUIButton>
                </MUIGrid>
            </MUIGrid>
        )
    }

    /**
     * @returns The maximum number of results that can be rendered. Set to
     * Infinity to remove the cap. Just be aware that rendering too many items
     * is not performant or generally useful.
     */
    getMaxResults() {
        return 100;
    }

    /**
     * @returns {boolean} Whether or not to show dividers on content area.
     */
    getDividers() {
        return true;
    }
}