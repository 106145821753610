import api from "../api";

class SalesPcrLinkService {

    async getAllSalesPcrLinks() {
        return new Promise((res, reject) => {
            api
                .get("/users/salesPCRLinks")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createSalesPcrLink(link) {
        return new Promise((res, reject) => {
            api
                .post("/users/salesPCRLinks", link)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateSalesPcrLink(link) {
        return new Promise((res, reject) => {
            api
                .put("/users/salesPCRLinks", link)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteSalesPcrLink(id) {
        return new Promise((res, rej) => {
            return api.delete('/users/salesPCRLinks/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }

}

const salesPcrLinkService = new SalesPcrLinkService();

export default salesPcrLinkService;
