import React from "react";
import DialogSearch from "../DialogSearch";
import MUIDialogActions from "@mui/material/DialogActions";
import MUIButton from "@mui/material/Button";
import TileRegion from "../../Tile/TileRegion";
import PropTypes from "prop-types";
import Region from "../../../Utility/Crud/Region";
export default class DialogSearchRegion extends DialogSearch {
    static contextTypes = {
        regionsIndexed: PropTypes.object,
    };

    /**
     * Searches for regions by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered region.
     */
    getSearchResults(query) {
        return Region.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.regionsIndexed);
        }
    }

    /**
     * @param {object} region The region object.
     * @returns A RegionTile component for this region.
     */
    renderTile(region) {
        return (
            <TileRegion
                key={ region.id }
                region={ region }
            />
        );
    }
}
