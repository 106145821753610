import React from "react";
import MUITypography from "@mui/material/Typography";
import MUIContainer from "@mui/material/Container";

export default class EmptyState extends React.Component {
    /**
     * Render up to two lines of text for the empty state.
     */
    render() {
        return (
            <MUIContainer maxWidth="md" sx={{"textAlign": "center", "paddingTop": 2, "paddingBottom": 2}}>
                {this.props.line1 && (
                    <MUITypography display="block" variant="body1" color="textPrimary">
                        {this.props.line1}
                    </MUITypography>
                )}
                {this.props.line2 && (
                    <MUITypography display="block" variant="body2" color="textSecondary">
                        {this.props.line2}
                    </MUITypography>
                )}
            </MUIContainer>
        );
    }
}