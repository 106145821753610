import api from "../api";

class TeamUserService {
    async getAllTeamUsers() {
        return new Promise((res, reject) => {
            api
                .get("/allTeamUsers")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getTeamUserById(id) {
        return new Promise((res, reject) => {
            api
                .get("/teamUsers/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createTeamUser(obj) {
        return new Promise((res, reject) => {
            api
                .post("/teamUsers", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateTeamUser(obj) {
        return new Promise((res, reject) => {
            api
                .put("/teamUsers", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteTeamUser(id) {
        return new Promise((res, rej) => {
            return api.delete('/teamUsers/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const teamUserService = new TeamUserService();

export default teamUserService;
