import React from "react";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import MUISwitch from "@mui/material/Switch";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import Term from "../../../../Utility/Crud/Term";
import MUITextField from "@mui/material/TextField";
import MUIAutocomplete from "@mui/material/Autocomplete";

export default class CardAdminDepartmentPrefilters extends Card {
    static contextTypes = {
        termsIndexed: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            enabled: this.props.department?.jsonPrefilters?.enabled || false,
            region: this.props.department?.jsonPrefilters?.region || false,
            regionTypeTermId: this.props.department?.jsonPrefilters?.regionTypeTermId || null,
            location: this.props.department?.jsonPrefilters?.location || false,
            user: this.props.department?.jsonPrefilters?.user || false,
            marketingCampaign: this.props.department?.jsonPrefilters?.marketingCampaign || false,
        };
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleChange = (property, value) => {
            this.setState(
                {
                    [property]: value,
                },
                () => {
                    // After state update, trigger onChange
                    this.props.onChange({
                        jsonPrefilters: {
                            enabled: this.state.enabled,
                            region: this.state.region,
                            regionTypeTermId: this.state.regionTypeTermId,
                            location: this.state.location,
                            user: this.state.user,
                            marketingCampaign: this.state.marketingCampaign,
                        }
                    });
                }
            );
        };

        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12}>
                    <MUIFormControlLabel
                        control={<MUISwitch />}
                        onChange={(e) => handleChange("enabled", e.target.checked) }
                        label="Use Prefilters"
                        checked={this.state.enabled}
                    />
                </MUIGrid>
                {this.state.enabled && (
                    <>
                        <MUIGrid item xs={12} sm={3}>
                            <MUIFormControlLabel
                                control={<MUISwitch />}
                                onChange={(e) => handleChange("region", e.target.checked) }
                                label="Region"
                                checked={this.state.region}
                            />
                        </MUIGrid>

                        <MUIGrid item xs={12} sm={3}>
                            <MUIFormControlLabel
                                control={<MUISwitch />}
                                onChange={(e) => handleChange("location", e.target.checked) }
                                label="Location"
                                checked={this.state.location}
                            />
                        </MUIGrid>

                        <MUIGrid item xs={12} sm={3}>
                            <MUIFormControlLabel
                                control={<MUISwitch />}
                                onChange={(e) => handleChange("user", e.target.checked) }
                                label="User"
                                checked={this.state.user}
                            />
                        </MUIGrid>

                        <MUIGrid item xs={12} sm={3}>
                            <MUIFormControlLabel
                                control={<MUISwitch />}
                                onChange={(e) => handleChange("marketingCampaign", e.target.checked) }
                                label="Marketing Campaign"
                                checked={this.state.marketingCampaign}
                            />
                        </MUIGrid>

                        <MUIGrid item xs={12} sm={4}>
                            {(this.state.region || this.state.location) && (
                                <MUIAutocomplete
                                    options={Term.sort(Term.read(this.context.termsIndexed, [{ key: "type", value: "region_type" }]))}
                                    getOptionLabel={Term.getDisplayName}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id;
                                    }}
                                    value={this.state.regionTypeTermId ? this.context.termsIndexed[this.state.regionTypeTermId] : null}
                                    onChange={(e, term) => handleChange("regionTypeTermId", term?.id || null) }
                                    renderInput={(params) => {
                                        return (
                                            <MUITextField
                                                {...params}
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                label="Region/Location Type"
                                                variant="standard"
                                                required={true}
                                                fullWidth={true}
                                                error={this.props.validationIssues["jsonPrefilters.regionTypeTermId"] ? true : false}
                                                helperText={this.props.validationIssues["jsonPrefilters.regionTypeTermId"] ? this.props.validationIssues["jsonPrefilters.regionTypeTermId"] : ""}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </MUIGrid>
                    </>
                )}

            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Prefilters";
    }
}
