import api from "../api";

class ServiceOrderReasonService {
    //region patient issues
    async getAllServiceOrderReasons() {
        return new Promise((res, reject) => {
            api
                .get("/serviceOrderReason")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //get serviceReason by ticket id
    async getServiceOrderReasonByServiceId(id) {
        return new Promise((res, reject) => {
            api
                .get("/orders/serviceOrderReasonByServiceId/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //update serviceReason
    async updateServiceOrderReason(issue) {
        return new Promise((res, reject) => {
            api
                .put("/orders/serviceOrderReason", issue)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getSpecificServiceOrderReason(id) {
        return new Promise((res, reject) => {
            api
                .get("/serviceOrderReason/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createServiceOrderReason(issue) {
        return new Promise((res, reject) => {
            api
                .post("/orders/serviceOrderReason", issue)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // async updateServiceOrderReason(issue) {
    // 	return new Promise((res, reject) => {
    // 		api
    // 			.put("/serviceOrderReason/" + issue.id, issue)
    // 			.then((data) => {
    // 				res(data);
    // 			})
    // 			.catch((err) => {
    // 				reject(err);
    // 			});
    // 	});
    // }
    //endregion

    // calls proc spGetServiceOrderReasonView
    async getServiceOrderReasonViewNative(filters) {
        return new Promise((res, reject) => {
            api
                .get("/serviceOrderReasonNative", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const serviceOrderReasonService = new ServiceOrderReasonService();

export default serviceOrderReasonService;
