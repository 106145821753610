import React from "react";
import PropTypes from "prop-types";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUIGrid from "@mui/material/Grid";
import Card from "../../../Card";
import TileInsuranceType from "../../../Tile/TileInsuranceType";
import TileInsuranceSubType from "../../../Tile/TileInsuranceSubType";
import DialogSearchInsuranceType from "../../../Dialog/Search/DialogSearchInsuranceType";
import DialogSearchInsuranceSubType from "../../../Dialog/Search/DialogSearchInsuranceSubType";

export default class CardChartPatientInsuranceInfo extends Card {
    constructor(props) {
        super(props);

        this.state.insuranceTypeDialogOpen = false;
        this.state.insuranceSubTypeDialogOpen = false;
    }

    static contextTypes = {
        insuranceTypesIndexed: PropTypes.object,
        insuranceSubTypesIndexed: PropTypes.object,
    };


    /**
       * Renders the content of the card.
       */
    renderContent() {
        let patient = this.props.patient;
        let insuranceType = null;
        let insuranceSubType = null;

        if (patient && patient.insuranceType !== null) {
            insuranceType = this.context.insuranceTypesIndexed[patient.insuranceTypeId];
        }

        if (patient && patient.insuranceSubType !== null) {
            insuranceSubType = this.context.insuranceSubTypesIndexed[patient.insuranceSubTypeId];
        }

        // Insurance Type
        const handleEditInsuranceType = () => {
            this.setState({ insuranceTypeDialogOpen: true });
        };
        const handleClearInsuranceType = () => {
            this.props.onChange({ insuranceType: null, insuranceTypeId: null, insuranceSubType: null, insuranceSubTypeId: null });
        };
        const insuranceTypeIconButtons = [
            (<MUIIconButton onClick={handleEditInsuranceType}><MUIEditIcon /></MUIIconButton>)
        ];

        if (insuranceType) {
            insuranceTypeIconButtons.push(
                (<MUIIconButton onClick={handleClearInsuranceType}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Insurance Sub Type
        const handleEditInsuranceSubType = () => {
            this.setState({ insuranceSubTypeDialogOpen: true });
        };
        const handleClearInsuranceSubType = () => {
            this.props.onChange({ insuranceSubType: null, insuranceSubTypeId: null });
        };
        const insuranceSubTypeIconButtons = [
            (<MUIIconButton onClick={handleEditInsuranceSubType} disabled={!insuranceType}><MUIEditIcon /></MUIIconButton>)
        ];

        if (insuranceSubType) {
            insuranceSubTypeIconButtons.push(
                (<MUIIconButton onClick={handleClearInsuranceSubType}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Render
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileInsuranceType
                            insuranceType={insuranceType}
                            iconButtons={insuranceTypeIconButtons}
                            label="Payor Source"
                            required={true}
                            error={this.props.validationIssues.insuranceType ? true : false}
                            helperText={this.props.validationIssues.insuranceType ? this.props.validationIssues.insuranceType : ""}
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileInsuranceSubType
                            insuranceSubType={insuranceSubType}
                            iconButtons={insuranceSubTypeIconButtons}
                            label="Insurance"
                            required={true}
                            error={this.props.validationIssues.insuranceSubType ? true : false}
                            helperText={this.props.validationIssues.insuranceSubType && insuranceType ? this.props.validationIssues.insuranceSubType : !insuranceType ? "You must select a Payor Source" : ""}
                        />
                    </MUIGrid>
                </MUIGrid>
                <DialogSearchInsuranceType
                    open={this.state.insuranceTypeDialogOpen}
                    onClose={() => this.setState({ insuranceTypeDialogOpen: false })}
                    onSelect={(insuranceType) => {
                        this.props.onChange({ insuranceType: insuranceType, insuranceTypeId: insuranceType.id });
                    }}
                />
                {insuranceType ? (
                    <DialogSearchInsuranceSubType
                        data={Object.values(this.context.insuranceSubTypesIndexed).filter((insuranceSubType) => insuranceSubType.insuranceTypeId === insuranceType.id)}
                        open={this.state.insuranceSubTypeDialogOpen}
                        onClose={() => this.setState({ insuranceSubTypeDialogOpen: false })}
                        onSelect={(insuranceSubType) => {
                            this.props.onChange({ insuranceSubType, insuranceSubTypeId: insuranceSubType.id });
                        }}
                    />
                ) : null}
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Insurance";
    }
}