import React from "react";
import MUIDialog from "@mui/material/Dialog";
import MUIDialogTitle from "@mui/material/DialogTitle";
import MUIDialogContent from "@mui/material/DialogContent";
import MUIStyled from "@mui/material/styles/styled";
import MUIIconButton from '@mui/material/IconButton';
import MUICloseIcon from '@mui/icons-material/Close';
import MUITooltip from '@mui/material/Tooltip'

export default class Dialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    /**
     * Clear state. Called when the dialog closes.
     */
    clearState() {
        this.setState({
            open: false
        });
    }

    /**
     * Renders a MUI Dialog component
     */
    render() {
        // Memoize so this only gets created one time despite how many times it
        // gets rerendered. Otherwise it flickers.
        if (!this.MUIDialogStyled) {
            this.MUIDialogStyled = MUIStyled(MUIDialog)(() => ({
                backdropFilter: `blur(${this.getBlur() ? "3px" : "0px"})`
            }));
        }

        return (
            <this.MUIDialogStyled open={this.props.open || false} fullWidth={this.getFullWidth()} maxWidth={this.getMaxWidth()} onClose={() => { this.close(); }}>
                <MUIDialogTitle component="div">
                    {this.renderHeader()}
                    {this.renderCloseIcon()}
                </MUIDialogTitle>
                <MUIDialogContent dividers={this.getDividers()}>
                    {this.renderContent()}
                </MUIDialogContent>

                {this.renderActions()}
            </this.MUIDialogStyled>
        );
    }

    /**
     * Render the dialog close icon.
     */
    renderCloseIcon() {
        return (
            <MUITooltip title="Close" placement="left">
                <MUIIconButton
                    onClick={() => this.close()}
                    sx={{
                        position: "absolute",
                        right: 16,
                        top: 14
                    }}
                >
                    <MUICloseIcon />
                </MUIIconButton>
            </MUITooltip>
        )
    }

    /**
     * Render the dialog header. Defaults to empty; subclasses should override.
     */
    renderHeader() {
        return null;
    }

    /**
     * Render the dialog content. Defaults to empty; subclasses should override.
     */
    renderContent() {
        return null;
    }

    /**
     * Render the dialog actions. Defaults to empty; subclasses should override.
     */
    renderActions() {
        return null;
    }

    /**
     * Close the dialog.
     */
    close() {
        this.clearState();

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    /**
     * @returns {string} The max width of the dialog. One of xs, sm, md, lg, xl.
     */
    getMaxWidth() {
        return "md";
    }

    /**
     * @returns {boolean} Whether or not to make the dialog full width.
     */
    getFullWidth() {
        return true;
    }

    /**
     * @returns {boolean} Whether or not to show dividers on content area.
     */
    getDividers() {
        return false;
    }

    /**
     * @returns {boolean} Whether or not to show blur the background. Use
     * sparingly; it was only added because there was a situation where we
     * needed to see behind a dialog.
     */
    getBlur() {
        return true;
    }

}