import api from "../api";

class PurchaseQueueService {

    // calls TWO procs
    // calls proc spGetPurchaseQueue
    // calls proc spGetPurchaseQueueApprovals
    async getPurchaseQueueListing(status) {
        return new Promise((res, reject) => {
            api
                .get("/purchaseQueueListing", { status: status })
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetInventoryRequestQueue
    async getInventoryRequestListing() {
        return new Promise((res, reject) => {
            api
                .get("/inventoryQueue")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createPurchaseQueues(purchseQueues) {
        return new Promise((res, reject) => {
            api
                .post("/purchaseQueues", purchseQueues)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    async createPurchaseTracking(params) {
        return new Promise((res, reject) => {
            api
                .post("/purchaseTracking", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findPurchaseTrackingById(orderId) {
        return new Promise((res, reject) => {
            api
                .get("/purchaseTrackingPOID/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updatePurchaseTracking(params) {
        return new Promise((res, reject) => {
            api
                .post("/purchaseTrackingProcUpdate", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findPurchaseQueuesByOrderId(orderId) {
        return new Promise((res, reject) => {
            api
                .get("/purchaseQueuesByOrder/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findPurchaseQueuesById(pqId) {
        return new Promise((res, reject) => {
            api
                .get("/purchaseQueue/" + pqId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findPurchaseQueuesByIds(ids) {
        return new Promise((res, reject) => {
            api
                .get("/purchaseQueuesByIds", ids)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // async createPurchaseQueue(vendor) {
    //     return new Promise((res,reject) => {
    //         api.post('/vendor', vendor).then(data => {
    //             res(data);
    //         }).catch(err => {
    //             reject(err);
    //         })
    //     })
    // }

    async approveAll(pqs) {
        return new Promise((res, reject) => {
            api
                .post("/approvePurchaseQueues", pqs)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    async approveInventoryRequests(pqs) {
        return new Promise((res, reject) => {
            api
                .post("/approveInventoryRequest", pqs)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    async editPurchaseQueue(pq) {
        return new Promise((res, reject) => {
            api
                .put("/purchaseQueue", pq)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deletePurchaseQueue(id) {
        return new Promise((res, reject) => {
            api
                .delete("/purchaseQueue/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async orderExists(id) {
        return new Promise((res, reject) => {
            api.get("/orders/exists/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const purchaseQueueService = new PurchaseQueueService();

export default purchaseQueueService;
