import React from "react";
import PropTypes from "prop-types";
import ChipRegion from "./Chip/ChipRegion";
import ChipLocation from "./Chip/ChipLocation";
import ChipUser from "./Chip/ChipUser";
import DialogSearchLocation from "./Dialog/Search/DialogSearchLocation";
import DialogSearchRegion from "./Dialog/Search/DialogSearchRegion";
import DialogSearchUser from "./Dialog/Search/DialogSearchUser";
import DialogSearchMarketingCampaign from "./Dialog/Search/DialogSearchMarketingCampaign";
import Setting from "../Utility/Setting";
import Location from "../Utility/Crud/Location";
import Region from "../Utility/Crud/Region";
import User from "../Utility/Crud/User";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIStack from "@mui/material/Stack";
import Security from "../Utility/Security";
import MUIMenuItem from "@mui/material/MenuItem";
import Term from "../Utility/Crud/Term";
import MUIChip from "@mui/material/Chip";
import MUIExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MUIMenu from "@mui/material/Menu";
import MarketingCampaign from "../Utility/Crud/MarketingCampaign";
import ChipMarketingCampaign from "./Chip/ChipMarketingCampaign";
export default class QueuePrefilters extends React.Component {
    static contextTypes = {
        currentUser: PropTypes.object,
        userprofileLocationsIndexed: PropTypes.object,
        regionsIndexed: PropTypes.object,
        locationsIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
        salesPcrLinksIndexed: PropTypes.object,
        teamUsersIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        marketingCampaignsIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            regionDialogOpen: false,
            locationDialogOpen: false,
            userDialogOpen: false,
            marketingCampaignDialogOpen: false,
            regionTypeMenuAnchor: null,
            filteredRegions: [],
            filteredRegionsWithType: [],
            filteredLocations: [],
            filteredUsers: [],
            filteredMarketingCampaigns: [],
            selectedRegionIds: [],
            selectedLocationIds: [],
            selectedUserIds: [],
            selectedMarketingCampaignIds: [],
            availableRegionTypeTerms: [],
        };
    }

    /**
     * Initialize these when mounting.
     */
    componentDidMount() {
        // Get a list of available region types.
        let availableRegionTypeTerms;
        if (Security.hasPermission("user.viewAllRegions") === true) {
            availableRegionTypeTerms = Term.sort(
                Term.read(
                    this.context.termsIndexed,
                    [{ key: "type", value: "region_type" }]
                )
            );
        } else {
            const availableRegionTypeTermIds = {};
            Object.values(this.context.teamUsersIndexed).some((teamUser) => {
                if (teamUser.userId === this.context.currentUser.id) {
                    const team = this.context.teamsIndexed[teamUser.teamId];
                    if (team && team.departmentId) {
                        const department = this.context.departmentsIndexed[team.departmentId];
                        if (department.jsonPrefilters?.regionTypeTermId) {
                            availableRegionTypeTermIds[department.jsonPrefilters.regionTypeTermId] = true;
                        }
                    }
                }

                return false;
            });
            availableRegionTypeTerms = Term.sort(
                Term.read(
                    this.context.termsIndexed,
                    [{ key: "id", value: Object.keys(availableRegionTypeTermIds).map(Number) }]
                )
            );
        }

        const filteredRegions = User.getFilteredRegions(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.regionsIndexed,
            this.context.locationsIndexed
        );
        const filteredRegionsWithType = filteredRegions.filter((region) => {
            return region.regionTypeTermId === Setting.get("queue.prefilters.regionTypeTermId");
        });

        const filteredLocations = User.getFilteredLocations(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.locationsIndexed
        );

        const filteredUsers = User.getFilteredUsers(
            this.context.currentUser,
            this.context.userprofileLocationsIndexed,
            this.context.locationsIndexed,
            this.context.usersIndexed,
            this.context.salesPcrLinksIndexed
        );

        const filteredMarketingCampaigns = Object.values(this.context.marketingCampaignsIndexed);

        /**
         * If the IDs are in a setting, use those IDs (filtered down to only
         * what you can see). If not, use all.
         */
        let selectedRegionIds;
        const filteredRegionIds = filteredRegionsWithType.map(region => region.id);
        if (Setting.get("queue.prefilters.regionIds")) {
            selectedRegionIds = Setting.get("queue.prefilters.regionIds").filter(regionId =>
                filteredRegionIds.includes(regionId)
            );
            if (selectedRegionIds.length === 0) {
                selectedRegionIds = filteredRegionIds;
            }
        } else {
            selectedRegionIds = filteredRegionIds;
        }

        let selectedLocationIds;
        const filteredLocationIds = filteredLocations.map(location => location.id);
        if (Setting.get("queue.prefilters.locationIds")) {
            selectedLocationIds = Setting.get("queue.prefilters.locationIds").filter(locationId =>
                filteredLocationIds.includes(locationId)
            );
            if (selectedLocationIds.length === 0) {
                selectedLocationIds = filteredLocationIds;
            }
        } else {
            selectedLocationIds = filteredLocationIds;
        }

        let selectedUserIds;
        const filteredUserIds = filteredUsers.map(user => user.id);
        if (Setting.get("queue.prefilters.userIds")) {
            selectedUserIds = Setting.get("queue.prefilters.userIds").filter(userId =>
                filteredUserIds.includes(userId)
            );
            if (selectedUserIds.length === 0) {
                selectedUserIds = filteredUserIds;
            }
        } else {
            selectedUserIds = filteredUserIds;
        }

        let selectedMarketingCampaignIds;
        const filteredMarketingCampaignIds = filteredMarketingCampaigns.map(marketingCampaign => marketingCampaign.id);
        if (Setting.get("queue.prefilters.marketingCampaignIds")) {
            selectedMarketingCampaignIds = Setting.get("queue.prefilters.marketingCampaignIds").filter(marketingCampaignId =>
                filteredMarketingCampaignIds.includes(marketingCampaignId)
            );
            if (selectedMarketingCampaignIds.length === 0) {
                selectedMarketingCampaignIds = filteredMarketingCampaignIds;
            }
        } else {
            selectedMarketingCampaignIds = filteredMarketingCampaignIds;
        }

        this.setState({
            filteredRegions: filteredRegions,
            filteredRegionsWithType: filteredRegionsWithType,
            filteredLocations: filteredLocations,
            filteredUsers: filteredUsers,
            filteredMarketingCampaigns: filteredMarketingCampaigns,
            selectedRegionIds: selectedRegionIds,
            selectedLocationIds: selectedLocationIds,
            selectedUserIds: selectedUserIds,
            selectedMarketingCampaignIds: selectedMarketingCampaignIds,
            availableRegionTypeTerms: availableRegionTypeTerms,
        });
    }

    /**
     * Renders a Chip group.
     */
    render() {
        const nameLimit = 25;

        // Region names
        const regionIds = this.state.selectedRegionIds.length > 0
            ? this.state.selectedRegionIds
            : this.state.filteredRegionsWithType.map(region => region.id);

        let regionDisplayNames = regionIds
            .slice(0, nameLimit)
            .map(regionId => Region.getDisplayName(this.context.regionsIndexed[regionId]))
            .join("\n");

        const moreRegionsCount = regionIds.length - nameLimit;
        regionDisplayNames = moreRegionsCount > 0
            ? `${regionDisplayNames}\n+ ${moreRegionsCount.toLocaleString()} more`
            : regionDisplayNames;

        // Location Names
        const locationIds = this.state.selectedLocationIds.length > 0
            ? this.state.selectedLocationIds
            : this.state.filteredLocations.map(location => location.id);


        let locationDisplayNames = locationIds
            .slice(0, nameLimit)
            .map(locationId => Location.getDisplayName(this.context.locationsIndexed[locationId]))
            .join("\n");

        const moreLocationsCount = locationIds.length - nameLimit;
        locationDisplayNames = moreLocationsCount > 0
            ? `${locationDisplayNames}\n+ ${moreLocationsCount.toLocaleString()} more`
            : locationDisplayNames;

        // User Names
        const userIds = this.state.selectedUserIds.length > 0
            ? this.state.selectedUserIds
            : this.state.filteredUsers.map(user => user.id);

        let userDisplayNames = userIds
            .slice(0, nameLimit)
            .map(userId => User.getDisplayName(this.context.usersIndexed[userId]))
            .join("\n");

        const moreUsersCount = userIds.length - nameLimit;
        userDisplayNames = moreUsersCount > 0
            ? `${userDisplayNames}\n+ ${moreUsersCount.toLocaleString()} more`
            : userDisplayNames;

        const handleOpenRegionMenu = (e) => {
            this.setState({
                regionTypeMenuAnchor: e.currentTarget
            });
        }
        const handleCloseRegionMenu = () => {
            this.setState({
                regionTypeMenuAnchor: null
            });
        }

        // Marketing Campaign Names
        const marketingCampaignIds = this.state.selectedMarketingCampaignIds.length > 0
            ? this.state.selectedMarketingCampaignIds
            : this.state.filteredMarketingCampaigns.map(marketingCampaign => marketingCampaign.id);

        let marketingCampaignDisplayNames = marketingCampaignIds
            .slice(0, nameLimit)
            .map(marketingCampaignId => MarketingCampaign.getDisplayName(this.context.marketingCampaignsIndexed[marketingCampaignId]))
            .join("\n");

        const moreMarketingCampaignsCount = marketingCampaignIds.length - nameLimit;
        marketingCampaignDisplayNames = moreMarketingCampaignsCount > 0
            ? `${marketingCampaignDisplayNames}\n+ ${moreMarketingCampaignsCount.toLocaleString()} more`
            : marketingCampaignDisplayNames;

        return (
            <MUIStack direction={this.props.direction}
                spacing={1}
                sx={{
                    alignItems: "flex-start",
                }}
            >
                {this.renderDialogs()}
                {
                    this.state.availableRegionTypeTerms.length > 1 && (
                        <>
                            <MUIChip
                                color="primary"
                                label={Term.getDisplayName(this.context.termsIndexed[Setting.get("queue.prefilters.regionTypeTermId")])}
                                clickable={true}
                                onClick={handleOpenRegionMenu}
                                onDelete={handleOpenRegionMenu}
                                deleteIcon={<MUIExpandMoreIcon />}
                            />
                            <MUIMenu
                                anchorEl={this.state.regionTypeMenuAnchor}
                                open={!!this.state.regionTypeMenuAnchor}
                                onClose={handleCloseRegionMenu}
                            >
                            {
                                this.state.availableRegionTypeTerms.map((term) => {
                                    return (
                                        <MUIMenuItem
                                            key={term.id}
                                            value={term.id}
                                            onClick={() => {
                                                Setting.set("queue.prefilters.regionTypeTermId", term.id);

                                                // Update state to clear the selected regions and set the list of regions.
                                                Setting.clear("queue.prefilters.regionIds");
                                                const filteredRegionsWithType = this.state.filteredRegions.filter((region) => {
                                                    return region.regionTypeTermId === Setting.get("queue.prefilters.regionTypeTermId");
                                                });
                                                this.setState({
                                                    filteredRegionsWithType: filteredRegionsWithType,
                                                    selectedRegionIds: filteredRegionsWithType.map(region => region.id),
                                                });

                                                if (this.props.onChange) {
                                                    this.props.onChange();
                                                }
                                                handleCloseRegionMenu();
                                            }}
                                        >
                                            {term.name}
                                        </MUIMenuItem>
                                    );
                                })
                            }
                            </MUIMenu>

                        </>
                    )
                }

                {Security.hasPermission("queue.useRegionPrefilter") && (
                    <ChipRegion
                        region={this.state.selectedRegionIds.length === 1 ? this.context.regionsIndexed[this.state.selectedRegionIds[0]] : null}
                        nullLabel={`My Regions (${this.state.filteredRegionsWithType.length.toLocaleString()})`}
                        tooltip={regionDisplayNames}
                        onClick={this.state.filteredRegionsWithType.length > 1 ? (() => { this.setState({ regionDialogOpen: true }); }) : null}
                        onDelete={this.state.filteredRegionsWithType.length > 1 ? (() => { this.setState({ regionDialogOpen: true }); }) : null}
                        deleteIcon={<MUIEditIcon />}
                    />
                )}
                {Security.hasPermission("queue.useLocationPrefilter") && (
                    <ChipLocation
                        location={this.state.selectedLocationIds.length === 1 ? this.context.locationsIndexed[this.state.selectedLocationIds[0]] : null}
                        nullLabel={this.state.selectedLocationIds.length > 1 ? `Locations (${this.state.selectedLocationIds.length})` : `My Locations (${this.state.filteredLocations.length.toLocaleString()})`}
                        tooltip={locationDisplayNames}
                        onClick={this.state.filteredLocations.length > 1 ? (() => { this.setState({ locationDialogOpen: true }); }) : null}
                        onDelete={this.state.filteredLocations.length > 1 ? (() => { this.setState({ locationDialogOpen: true }); }) : null}
                        deleteIcon={<MUIEditIcon />}
                    />
                )}
                {Security.hasPermission("queue.useUserPrefilter") && (
                    <ChipUser
                        user={this.state.selectedUserIds.length === 1 ? this.context.usersIndexed[this.state.selectedUserIds[0]] : null}
                        nullLabel={`My Users (${this.state.filteredUsers.length.toLocaleString()})`}
                        tooltip={userDisplayNames}
                        onClick={this.state.filteredUsers.length > 1 ? (() => { this.setState({ userDialogOpen: true }); }) : null}
                        onDelete={this.state.filteredUsers.length > 1 ? (() => { this.setState({ userDialogOpen: true }); }) : null}
                        deleteIcon={<MUIEditIcon />}
                    />
                )}
                {Security.hasPermission("queue.useMarketingCampaignPrefilter") && (
                    <ChipMarketingCampaign
                        marketingCampaign={this.state.selectedMarketingCampaignIds.length === 1 ? this.context.marketingCampaignsIndexed[this.state.selectedMarketingCampaignIds[0]] : null}
                        nullLabel={this.state.selectedMarketingCampaignIds.length > 1 ? `Campaigns (${this.state.selectedMarketingCampaignIds.length})` : `Not Filtered`}
                        tooltip={marketingCampaignDisplayNames}
                        onClick={this.state.filteredMarketingCampaigns.length > 1 ? (() => { this.setState({ marketingCampaignDialogOpen: true }); }) : null}
                        onDelete={this.state.filteredMarketingCampaigns.length > 1 ? (() => { this.setState({ marketingCampaignDialogOpen: true }); }) : null}
                        deleteIcon={<MUIEditIcon />}
                    />
                )}
            </MUIStack>
        );
    }

    /**
     * Renders Search Dialogs.
     */
    renderDialogs() {
        return (
            <>
                {Security.hasPermission("queue.useRegionPrefilter") && (
                    <DialogSearchRegion
                        data={this.state.filteredRegionsWithType}
                        open={this.state.regionDialogOpen}
                        onClose={() => this.setState({ regionDialogOpen: false })}
                        onSelect={(region) => {
                            this.setState({
                                selectedRegionIds: [region.id],
                            });
                            Setting.set("queue.prefilters.regionIds", [region.id]);
                            if (this.props.onChange) {
                                this.props.onChange();
                            }
                        }}
                        onClear={() => {
                            this.setState({
                                selectedRegionIds: this.state.filteredRegionsWithType.map(region => region.id),
                                regionDialogOpen: false
                            });
                            Setting.clear("queue.prefilters.regionIds");
                            if (this.props.onChange) {
                                this.props.onChange();
                            }
                        }}
                    />
                )}
                {Security.hasPermission("queue.useLocationPrefilter") && (
                    <DialogSearchLocation
                        data={this.state.filteredLocations}
                        open={this.state.locationDialogOpen}
                        onClose={() => this.setState({ locationDialogOpen: false })}
                        onSelect={(locations) => {
                            const locationIds = locations.map(location => location.id);
                            this.setState({
                                selectedLocationIds: locationIds,
                            });
                            Setting.set("queue.prefilters.locationIds", locationIds);
                            if (this.props.onChange) {
                                this.props.onChange();
                            }
                        }}
                        onClear={() => {
                            this.setState({
                                selectedLocationIds: this.state.filteredLocations.map(location => location.id),
                                locationDialogOpen: false,
                            });
                            Setting.clear("queue.prefilters.locationIds");
                            if (this.props.onChange) {
                                this.props.onChange();
                            }
                        }}
                        multiSelect={true}
                        selected={
                            Location.read(
                                this.context.locationsIndexed,
                                [
                                    {
                                        key: "id",
                                        value: Setting.get("queue.prefilters.locationIds")
                                    }
                                ]
                            )
                        }
                    />
                )}
                {Security.hasPermission("queue.useUserPrefilter") && (
                    <DialogSearchUser
                        data={this.state.filteredUsers}
                        open={this.state.userDialogOpen}
                        onClose={() => this.setState({ userDialogOpen: false })}
                        onSelect={(user) => {
                            this.setState({
                                selectedUserIds: [user.id]
                            });
                            Setting.set("queue.prefilters.userIds", [user.id]);
                            if (this.props.onChange) {
                                this.props.onChange();
                            }
                        }}
                        onClear={() => {
                            this.setState({
                                selectedUserIds: this.state.filteredUsers.map(user => user.id),
                                userDialogOpen: false
                            });
                            Setting.clear("queue.prefilters.userIds");
                            if (this.props.onChange) {
                                this.props.onChange();
                            }
                        }}
                    />
                )}
                {Security.hasPermission("queue.useMarketingCampaignPrefilter") && (
                    <DialogSearchMarketingCampaign
                        data={this.state.filteredMarketingCampaigns}
                        open={this.state.marketingCampaignDialogOpen}
                        onClose={() => this.setState({ marketingCampaignDialogOpen: false })}
                        onSelect={(marketingCampaigns) => {
                            const marketingCampaignIds = marketingCampaigns.map(marketingCampaign => marketingCampaign.id);
                            this.setState({
                                selectedMarketingCampaignIds: marketingCampaignIds,
                            });
                            Setting.set("queue.prefilters.marketingCampaignIds", marketingCampaignIds);
                            if (this.props.onChange) {
                                this.props.onChange();
                            }
                        }}
                        onClear={() => {
                            this.setState({
                                selectedMarketingCampaignIds: [],
                                marketingCampaignDialogOpen: false,
                            });
                            Setting.clear("queue.prefilters.marketingCampaignIds");
                            if (this.props.onChange) {
                                this.props.onChange();
                            }
                        }}
                        multiSelect={true}
                        selected={
                            MarketingCampaign.read(
                                this.context.marketingCampaignsIndexed,
                                [
                                    {
                                        key: "id",
                                        value: Setting.get("queue.prefilters.marketingCampaignIds")
                                    }
                                ]
                            )
                        }
                    />
                )}
            </>
        );
    }
}