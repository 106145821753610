import React from "react";
import {
    Button,
    Col,
    Row,
    Fa,
    MDBSelect,
    Input,
    MDBBtn,
    MDBBtnGroup,
    MDBIcon,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import GlobalFunctions from "../Filters/GlobalFunctions";

const apiKey = "eDF3LRILoAxo10s3TpIsSQcv034AyeVRrBYr8P4G8iM~";

const styles = {
    officeMarker: new window.ol.style.Style({
        image: new window.ol.style.Circle({
            radius: 10,
            snapToPixel: false,
            stroke: new window.ol.style.Stroke({
                color: [0, 0, 0, 0.5],
                width: 2,
            }),
            fill: new window.ol.style.Fill({
                color: [155, 244, 66, 1],
            }),
        }),
        text: new window.ol.style.Text({
            text: "O",
            scale: 1.5,
            offsetY: 3,
            fill: new window.ol.style.Fill({
                color: [0, 0, 0, 1],
            }),
        }),
    }),
    scheduledStyle: new window.ol.style.Style({
        image: new window.ol.style.RegularShape({
            points: 3,
            radius: 10,
            rotation: Math.PI / 3,
            angle: 0,
            stroke: new window.ol.style.Stroke({
                color: [0, 0, 0, 0.5],
                width: 2,
            }),
        }),
    }),
    unscheduledStyle: new window.ol.style.Style({
        image: new window.ol.style.Circle({
            radius: 10,
            snapToPixel: false,
            stroke: new window.ol.style.Stroke({
                color: [0, 0, 0, 0.5],
                width: 2,
            }),
            fill: new window.ol.style.Fill({
                color: [255, 0, 0, 1],
            }),
        }),
    }),
    searchMarker: new window.ol.style.Style({
        image: new window.ol.style.Circle({
            radius: 10,
            snapToPixel: false,
            stroke: new window.ol.style.Stroke({
                color: [0, 0, 0, 0.5],
                width: 2,
            }),
            fill: new window.ol.style.Fill({
                color: [0, 255, 0, 1],
            }),
        }),
    }),
};

export default class StreetMap extends React.Component {
    constructor(props) {
        super(props);

        let baseLayer = new window.ol.layer.Tile({
            source: new window.ol.source.XYZ({
                url: `https://cloud.thinkgeo.com/api/v1/maps/raster/light/x1/3857/512/{z}/{x}/{y}.png?apiKey=${apiKey}`,
                tileSize: 512,
            }),
        });

        let officeLayer = new window.ol.layer.Vector({
            source: new window.ol.source.Vector({
                features: [],
            }),
            name: "officeLayer",
        });

        let coords = { latitude: 39.9043316, longitude: -86.06314619999999 };
        navigator.geolocation.getCurrentPosition(function (location) {
            coords = {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
            };
        });

        let view = new window.ol.View({
            center: window.ol.proj.fromLonLat([
                coords.longitude || -86.06314619999999,
                coords.latitude || 39.9043316,
            ]),
            maxResolution: 40075016.68557849 / 512,
            zoom: 3,
            minZoom: 2,
            maxZoom: 19,
        });

        let map = null;
        try {

            map = new window.ol.Map({
                // renderer: "webgl",
                loadTilesWhileAnimating: true,
                loadTilesWhileInteracting: true,
                layers: [baseLayer],
                target: "map",
                view: view,
            });
            map.addLayer(officeLayer);
        }
        catch (err) {
            console.log(err);
        }

        let geoCodeClient = {};

        if (window.tg) {
            try {
                geoCodeClient = new window.tg.GeocodingClient(apiKey);
            } catch (e) {
                //do nothing
            }
        }

        let types = ["Pickup", "Exchange", "Service", "New"];

        this.state = {
            coords: {
                longitude: 0,
                latitude: 0,
            },
            layers: [baseLayer, officeLayer],
            officeLayer: officeLayer,
            scheduledLayer: {},
            notScheduledLayer: {},
            view: view,
            map: map,
            geoClient: geoCodeClient,
            overlay: null,
            focusIndex: -1,
            location: {}, // OFFICE LOCATION
            scheduled: [], // SCHEDULED LOCATIONS
            nonScheduled: [], // NOT-SCHEDULED LOCATIONS

            scheduledOrders: [],
            notScheduledOrders: [],
            officeLocation: {},

            selectedFeature: null,

            showOfficeLayer: true,
            showScheduledLayer: true,
            showUnscheduledLayer: true,

            scheduledOrderTypes: [],
            unscheduledOrderTypes: [],

            orderTypes: types,
            selectedScheduledTypes: types,
            selectedUnscheduledTypes: types,

            editHandler: props.editHandler || null,
            techLegend: [],

            fullScreen: false,
            thinkGeoResults: JSON.parse(window.sessionStorage.getItem("thinkgeoresults"))
        };

        window.WebFont.load({
            custom: {
                families: ["vectormap-icons"],
                urls: [
                    "https://cdn.thinkgeo.com/vectormap-icons/2.0.0/vectormap-icons.css",
                ],
                testStrings: {
                    "vectormap-icons": "\ue001",
                },
            },
            active: this.initializeMap(),
        });
        window.mapComponent = this;
    }

    componentDidMount() {
        for (let i = 0; i < this.state.layers.length; i++) {
            this.setLayerSourceEventHandlers(this.state.layers[i]);
        }
        this.setOrderTypes();
    }

    setOrderTypes() {
        let s = [],
            u = [],
            ot = this.state.orderTypes,
            ss = this.state.selectedScheduledTypes,
            us = this.state.selectedUnscheduledTypes;

        ot.forEach((v) => {
            s.push({
                checked: ss.indexOf(v) > -1,
                disabled: false,
                text: v,
                value: v,
            });

            u.push({
                checked: us.indexOf(v) > -1,
                disabled: false,
                text: v,
                value: v,
            });
        });

        this.setState({
            scheduledOrderTypes: s,
            unscheduledOrderTypes: u,
        });
    }

    setStyleFill(id) {
        let leg =
			this.state.techLegend.length > 0
			    ? this.state.techLegend.filter((l) => {
			        return l.tech === id;
				  })[0]
			    : null;

        let fill = new window.ol.style.Fill({
            color: "red",
        });

        if (leg) {
            fill = new window.ol.style.Fill({
                color: leg.fill,
            });
        }

        return new window.ol.style.Style({
            image: new window.ol.style.RegularShape({
                points: 3,
                radius: 10,
                rotation: Math.PI / 3,
                angle: 0,
                stroke: new window.ol.style.Stroke({
                    color: [0, 0, 0, 0.5],
                    width: 2,
                }),
                fill: fill,
            }),
        });
    }

    setTechStyle(style) {
        let techLegend = this.state.techLegend;

        if (!techLegend.find((x) => x.tech === style.tech)) {
            techLegend.push(style);
        }
        this.setState({ techLegend: techLegend });
    }

    clearTechStyle() {
        this.setState({ techLegend: [] });
    }

    toggleTechOrders(id) {
        let techLegend = this.state.techLegend,
            tech = techLegend.find((x) => x.tech === id),
            layers = this.state.layers;

        tech.visible = !tech.visible;

        let layer = layers[2];
        if (layer) {
            let f = layer.getSource().getFeatures();
            let c = f.filter((feat) => {
                return feat.get("tech").toString() === id.toString();
            });
            c.forEach((co) => {
                if (tech.visible) {
                    let styledLegend = this.setStyleFill(id);

                    co.setStyle(styledLegend);
                } else {
                    co.setStyle(new window.ol.style.Style({}));
                }
            });
        }

        // in case an order does not get transferred to the scheduled layer correctly... CD
        layer = layers[3];
        if (layer) {
            let f = layer.getSource().getFeatures();
            let c = f.filter((feat) => {
                return feat.get("tech").toString() === id.toString();
            });
            c.forEach((co) => {
                if (tech.visible) {
                    let styledLegend = this.setStyleFill(id);

                    co.setStyle(styledLegend);
                } else {
                    co.setStyle(new window.ol.style.Style({}));
                }
            });
        }

        this.setState({ techLegend: techLegend });
    }

    geocoderOffice(val) {
        let geocodingClient = this.state.geoClient,
            opts = {
                maxResults: 5,
                locationType: "Street,Address",
                fuzzyMatch: true,
            };

        const callback = (status, res, stored) => {
            if (status !== 200) {
                console.log(res.error);
                return this.insertOfficePin(null, null, null);
            } else {
                let locations = res.data.locations;
                if (!stored) {
                    let storage = this.state.thinkGeoResults || {};
                    storage[val] = res;
                    this.setState({
                        thinkGeoResults: storage
                    });
                    window.sessionStorage.setItem("thinkgeoresults", JSON.stringify(storage));
                }
                if (locations.length > 0) {
                    return this.insertOfficePin(
                        locations[0].address,
                        locations[0].locationPoint.pointY,
                        locations[0].locationPoint.pointX
                    );
                } else {
                    console.log("No locations found for office");
                    return this.insertOfficePin(null, null, null);
                }
            }
        };

        let storage = this.state.thinkGeoResults || {};
        let foundAddress = null;

        if (storage[val]) {
            foundAddress = storage[val];
        }

        if (foundAddress) {
            callback(200, foundAddress, true);
            return;
        }

        if (val) {
            if (geocodingClient && geocodingClient.searchByPoint) {
                geocodingClient.searchByPoint(val, callback, opts);
            }
        }
    }


    getGeoCodePoint(val, stat, order) {
        let geocodingClient = this.state.geoClient;

        const callback = (status, res, stored) => {

            if (status !== 200) {
                console.log("error from mapping service: ");
                console.log(res);
                return this.insertLocationPin(null, null, null, null);
            } else {
                if (!stored) {
                    let storage = this.state.thinkGeoResults || {};
                    storage[val] = res;
                    this.setState({
                        thinkGeoResults: storage
                    });
                    window.sessionStorage.setItem("thinkgeoresults", JSON.stringify(storage));
                }
                if (res.data.locations.length > 0) {
                    return this.insertLocationPin(
                        res.data.locations[0].locationPoint.pointY,
                        res.data.locations[0].locationPoint.pointX,
                        stat,
                        order
                    );
                } else {
                    //console.log("No locations found for order address: " + val);
                    return this.insertLocationPin(null, null, null, null);
                }
            }
        };
        let storage = this.state.thinkGeoResults || {};
        let foundAddress = null;
        if (storage[val]) {
            foundAddress = storage[val];
        }

        if (foundAddress) {
            callback(200, foundAddress, true);
            return;
        }

        if (val) {
            if (geocodingClient && geocodingClient.searchByPoint) {
                geocodingClient.searchByPoint(val, callback);
            }
        }
    }

    setFocusByScheduledOrder(id, techId) {
        if (id) {
            let view = this.state.view,
                layers = this.state.layers,
                ids = id.split(","),
                c = 0,
                x = 0.0,
                y = 0.0;

            if (layers.length > 2) {
                let l = layers[2];
                if (l) {
                    let f = l.getSource().getFeatures();

                    let styledLegend = this.setStyleFill(techId);

                    f.forEach((feat) => {
                        if (!ids.includes(feat.get("key").toString())) {
                            feat.setStyle(new window.ol.style.Style({}));
                        } else {
                            feat.setStyle(styledLegend);

                            let temp = feat.get("coords").split(",");

                            x += parseFloat(temp[0]);
                            y += parseFloat(temp[1]);
                            c++;
                        }
                    });

                    if (c > 0) {
                        //get the average of the x and y coordinates.
                        x = x / c;
                        y = y / c;

                        view.animate({
                            center: window.ol.proj.fromLonLat([parseFloat(y), parseFloat(x)]),
                            zoom: 7,
                            duration: 0,
                        });
                    }
                }
            }
        }
    }

    focusOnOrder(id) {
        if (id) {
            let view = this.state.view,
                layers = this.state.layers,
                scheduled = this.state.scheduledOrders,
                unscheduled = this.state.notScheduledOrders;

            let ord = scheduled.find((o) => o.id === id);
            if (!ord) {
                ord = unscheduled.find((o) => o.id === id);
            }

            if (ord) {
                let sFts = layers[2].getSource().getFeatures();
                let uFts = layers[3].getSource().getFeatures();
                let feature = uFts.filter(
                    (x) => x.get("key").toString() === ord.id.toString()
                );
                if (!feature || feature.length === 0) {
                    feature = sFts.filter(
                        (x) => x.get("key").toString() === ord.id.toString()
                    );
                }

                if (feature && feature.length > 0) {
                    let c = feature[0].get("coords").split(",");
                    if (c) {
                        view.animate({
                            center: window.ol.proj.fromLonLat([
                                parseFloat(c[1]),
                                parseFloat(c[0]),
                            ]),
                            zoom: 8,
                            duration: 0,
                        });
                    }
                    let coords = feature[0].getGeometry().getCoordinates();
                    this.setState({ selectedFeature: feature[0] });
                    this.addPopup(coords);
                }
            }
        }
    }

    forceMapRender() {
        let map = this.state.map;

        setTimeout(() => {
            map.updateSize();
        }, 100);
    }

    initializeMap = () => {
        let view = this.state.view;

        setTimeout(() => {
            let map = new window.ol.Map({
                // renderer: "webgl",
                loadTilesWhileAnimating: true,
                loadTilesWhileInteracting: true,
                layers: this.state.layers,
                target: "map",
                view: view,
                controls: window.ol.control.defaults({
                    zoom: false,
                }),
            });

            map.on("click", this.setPopupInfo.bind(this));

            this.setState({ map: map });
        }, 1000);
    };

    setLayerSourceEventHandlers = (layer) => {
        let layerSource = layer.getSource();
        layerSource.on("tileloaderror", function () {
            console.log("error loading tile");
        });
    };

    addPopup = (coordinates) => {
        let map = this.state.map,
            overlay = this.state.overlay;

        if (!overlay) {
            overlay = new window.ol.Overlay({
                element: null,
                autoPan: false,
            });
        }

        const container = document.getElementById("popup");
        container.classList.remove("hidden");
        const closer = document.getElementById("popup-closer");
        const editLink = document.getElementById("editOrderLink");

        overlay.element = container;

        overlay.setPosition(coordinates);
        map.addOverlay(overlay);

        closer.onclick = () => {
            overlay.setPosition(undefined);
            closer.blur();
            return false;
        };

        editLink.onclick = this.handleEditClick.bind(this);

        this.setState({ overlay: overlay });
    };

    setPopupInfo = (evt) => {
        let map = this.state.map,
            pixel = map.getPixelFromCoordinate(evt.coordinate);

        let feature = map.forEachFeatureAtPixel(pixel, function (feature) {
            return feature;
        });

        if (feature) {
            let coords = feature.getGeometry().getCoordinates();

            this.setState({ selectedFeature: feature });
            this.addPopup(coords);
        }
    };

    handleEditClick = (ev) => {
        const { editHandler } = this.props;

        if (editHandler) {
            let id = parseInt(ev.target.attributes["data-id"].value);
            editHandler(id);
        }
    };

    renderPopup() {
        return (
            <div
                id="popup"
                className={
                    this.state.selectedFeature
                        ? this.state.selectedFeature.get("name") === "searchLayer" ||
						  this.state.selectedFeature.get("name") === "officeLayer"
                            ? "ol-popup hidden"
                            : "ol-popup hidden detailed"
                        : "ol-popup hidden"
                }
            >
                <button id="popup-closer" className="ol-popup-closer" />
                <div id="popup-content">
                    <p>
                        {this.state.selectedFeature
                            ? this.state.selectedFeature.get("patient")
                            : ""}
                    </p>
                    <p>
                        {this.state.selectedFeature
                            ? this.state.selectedFeature.get("orderType")
                            : ""}
                        <span style={{ color: "red" }}>
                            {this.state.selectedFeature
                                ? this.state.selectedFeature.get("schedule")
                                : ""}
                        </span>
                    </p>
                    <p>
                        {this.state.selectedFeature
                            ? this.state.selectedFeature.get("equipment")
                            : ""}
                    </p>
                    <p className={"address"}>
                        {this.state.selectedFeature
                            ? this.state.selectedFeature.get("address")
                            : ""}
                    </p>
                    <p>
                        {this.state.selectedFeature
                            ? this.state.selectedFeature.get("phone")
                            : ""}
                    </p>
                    <p><i>Technician: </i>
                        {this.state.selectedFeature
                            ? this.state.selectedFeature.get("techName")
                            : ""}
                    </p>
                    <div
                        className={
                            !this.props.editHandler ||
							(this.state.selectedFeature &&
								this.state.selectedFeature.get("name") === "officeLayer")
                                ? "hidden"
                                : ""
                        }
                    >
                        <button
                            className={"edit-order-link"}
                            id={"editOrderLink"}
                            data-id={
                                this.state.selectedFeature
                                    ? this.state.selectedFeature.get("key")
                                    : ""
                            }
                        >
							Schedule
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    setOfficeLocation(address, latitude, longitude) {
        if (address) {
            let layers = this.state.layers,
                map = this.state.map,
                officeLocation = this.state.officeLocation,
                officeLayer = this.state.officeLayer;

            this.removeFilters();

            if (layers.length < 2) {
                officeLayer = new window.ol.layer.Vector({
                    source: new window.ol.source.Vector({
                        features: [],
                    }),
                    name: "officeLayer",
                });
                layers.push(officeLayer);
                map.addLayer(officeLayer);
            }

            this.setState({
                focusIndex: 0,
                layers: layers,
                map: map,
                officeLayer: officeLayer,
                officeLocation: officeLocation,
            });

            if (layers.length > 3) {
                layers[3].getSource().clear();
                layers[2].getSource().clear();
            } else if (layers.length > 2) {
                layers[2].getSource().clear();
            }

            if (!latitude && !longitude) {
                this.geocoderOffice(address);
            } else {
                this.insertOfficePin(address, latitude, longitude);
            }
        }
    }

    setOrderLocations(orders) {
        if (orders && orders.length > 0) {
            let scheduled = [],
                notScheduled = [];

            orders.map((ord) => {
                let address =
					(ord.address ? ord.address : "") +
					(ord.city !== null ? " " + ord.city : "") +
					(ord.state !== null ? ", " + ord.state : "") +
					(ord.zipCode !== null ? " " + ord.zipCode : "");

                let type = "unscheduled",
                    typeAry = notScheduled;

                if (ord.technicianId && ord.scheduledDeliveryDate) {
                    type = "scheduled";
                    typeAry = scheduled;
                }

                if (ord.latitude && ord.longitude) {
                    this.insertLocationPin(ord.latitude, ord.longitude, type, ord);
                } else {
                    this.getGeoCodePoint(address, type, ord);
                }
                return typeAry.push(ord);
            });


            this.setState({
                scheduledOrders: scheduled,
                notScheduledOrders: notScheduled,
            });
        }
    }

    updateOrderPin(order) {
        let map = this.state.map,
            layers = this.state.layers,
            so = this.state.scheduledOrders,
            newS = [],
            uo = this.state.notScheduledOrders,
            newU = [],
            t = this;

        const closer = document.getElementById("popup-closer");
        closer.click();

        // update the order lists
        so.forEach((s) => {
            if (s.id === order.id) {
                if (order.technicianId && order.scheduledDeliveryDate) {
                    return newS.push(order);
                } else {
                    return newU.push(s);
                }
            } else {
                return newS.push(s);
            }
        });

        uo.forEach((s) => {
            if (s.id === order.id) {
                if (order.technicianId && order.scheduledDeliveryDate) {
                    return newS.push(order);
                } else {
                    return newU.push(s);
                }
            } else {
                return newU.push(s);
            }
        });

        let scheduledLayer = layers[2],
            unscheduledLayer = layers[3],
            fts = scheduledLayer.getSource().getFeatures(),
            ftu = unscheduledLayer.getSource().getFeatures(),
            timeStamp = "No Delivery Scheduled";

        if (order.scheduledDeliveryDate) {
            timeStamp =
				GlobalFunctions.formatDateTime(order.scheduledDeliveryDate);
            // timeStamp =
            // 	GlobalFunctions.formatDate(order.scheduledDeliveryDate) +
            // 	" " +
            // 	GlobalFunctions.formatTime(order.scheduledDeliveryDate);
        }

        function layerStyle(item) {
            if (item.get("key") === order.id && order.technicianId) {
                let techId = order.technicianId;
                item.set("tech", techId);

                let th = t.state.techLegend.filter(x => x.tech === order.technicianId)[0];
                item.set("techName", t ? th.name : "");
                item.set("schedule", " - " + timeStamp);

                let styledLegend = t.setStyleFill(techId);

                item.setStyle(styledLegend);
            } else if (item.get("key") === order.id && !order.technicianId) {
                item.set("schedule", " - " + timeStamp);
                item.setStyle(styles.unscheduledStyle);
            }
        }

        fts.forEach(function (item) {
            layerStyle(item);
        });

        ftu.forEach(function (item) {
            layerStyle(item);
        });

        this.setState({
            scheduledOrders: newS,
            notScheduledOrders: newU,
            layers: layers,
            map: map,
        });
    }

    getScheduledValue(val) {
        this.setState({
            selectedScheduledTypes: val,
        });
        this.updateScheduledLayer(val);
    }

    getUnscheduledValue(val) {
        this.setState({
            selectedunScheduledTypes: val,
        });
        this.updateUnscheduledLayer(val);
    }

    insertLocationPin(latitude, longitude, type, order) {
        let map = this.state.map,
            layers = this.state.layers,
            timeStamp = "No Delivery Scheduled";

        if (layers.length < 3) {
            let scheduledLayer = new window.ol.layer.Vector({
                source: new window.ol.source.Vector({
                    features: [],
                }),
                name: "scheduledLayer",
            });

            layers.push(scheduledLayer);
            map.addLayer(scheduledLayer);
        }

        if (layers.length < 4) {
            let nonScheduledLayer = new window.ol.layer.Vector({
                source: new window.ol.source.Vector({
                    features: [],
                }),
                name: "nonScheduledLayer",
            });
            layers.push(nonScheduledLayer);
            map.addLayer(nonScheduledLayer);
        }

        if (latitude && longitude && type && order) {
            if (order.scheduledDeliveryDate) {
                timeStamp =
					GlobalFunctions.formatDateTime(order.scheduledDeliveryDate);
                // timeStamp =
                // 	GlobalFunctions.formatDate(order.scheduledDeliveryDate) +
                // 	" " +
                // 	GlobalFunctions.formatTime(order.scheduledDeliveryDate);
            }

            let address =
				(order.address !== null ? order.address : "*** No Address Listed ***") +
				(order.city !== null ? " " + order.city : "") +
				(order.state !== null ? ", " + order.state : "") +
				(order.zipCode !== null ? " " + order.zipCode : "");

            let sFeature = new window.ol.Feature({
                geometry: new window.ol.geom.Point(
                    window.ol.proj.fromLonLat([
                        parseFloat(longitude),
                        parseFloat(latitude),
                    ])
                ),
                name: "scheduledLayer",
            });

            let nsFeature = new window.ol.Feature({
                geometry: new window.ol.geom.Point(
                    window.ol.proj.fromLonLat([
                        parseFloat(longitude),
                        parseFloat(latitude),
                    ])
                ),
                name: "unscheduledLayer",
            });

            let fts = {},
                layer = layers[2] || layers.item(2),
                typeAry = sFeature;

            if (type === "scheduled") {
                let styledLegend = this.setStyleFill(order.technicianId);
                sFeature.setStyle(styledLegend);
            } else if (type === "unscheduled") {
                layer = layers[3] || layers.item(3);
                typeAry = nsFeature;
                nsFeature.setStyle(styles.unscheduledStyle);
            }

            fts = layer.getSource().getFeatures();

            var t = null;
            if(order.technicianId){
                t = this.state.techLegend.filter(x => x.tech === order.technicianId)[0];
            }

            typeAry.set("patient", order.patientName);
            typeAry.set("address", address);
            typeAry.set("orderType", order.orderType);
            typeAry.set("phone", order.phone || "");
            typeAry.set("equipment", order.product || "");
            typeAry.set("schedule", " - " + timeStamp);
            typeAry.set("coords", `${latitude},${longitude}`);
            typeAry.set("tech", order.technicianId || "");
            typeAry.set("techName", t ? t.name : "");
            typeAry.set("key", order.id);
            fts.push(typeAry);

            layer.getSource().clear();
            layer.getSource().addFeatures(fts);
        }
        this.setState({
            layers: layers,
            map: map,
        });
    }

    insertOfficePin(address, latitude, longitude) {
        let layers = this.state.layers,
            view = this.state.view,
            type = "Street",
            map = this.state.map;

        if (address && latitude && longitude) {
            let geocodingLayer = layers[1];

            if (type === "Street") {
                let office = new window.ol.Feature({
                    geometry: new window.ol.geom.Point(
                        window.ol.proj.fromLonLat([longitude, latitude])
                    ),
                    name: "officeLayer",
                    visible: this.state.showOfficeLayer,
                });

                office.set("address", address);
                office.setStyle(styles["officeMarker"]);
                geocodingLayer.getSource().clear();
                geocodingLayer.getSource().addFeature(office);
                view.animate({
                    center: window.ol.proj.fromLonLat([longitude, latitude]),
                    zoom: 8,
                    duration: 0,
                });
            }
        } else {
            view.animate({
                center: window.ol.proj.fromLonLat([-86.06314619999999, 39.9043316]),
                maxResolution: 40075016.68557849 / 512,
                zoom: 3,
                minZoom: 2,
                maxZoom: 19,
            });
        }
        this.setState({
            view: view,
            layers: layers,
            map: map,
            coords: { latitude: latitude, longitude: longitude },
        });
    }

    toggleOfficeLayer = () => {
        let show = !this.state.showOfficeLayer,
            map = this.state.map;

        let l = map.getLayers().item(1);

        if (l && l.get("name") === "officeLayer") {
            l.setVisible(show);
        }

        this.setState({
            showOfficeLayer: show,
            map: map,
        });
    };

    removeFilters = () => {
        setTimeout(() => {
            if (!this.state.showOfficeLayer) {
                this.toggleOfficeLayer();
            }
            this.setState({
                selectedScheduledTypes: ["Pickup", "Exchange", "Service", "New"],
                selectedUnscheduledTypes: ["Pickup", "Exchange", "Service", "New"],
            });
            this.setOrderTypes();
        }, 300);
    };

    updateScheduledLayer = (values) => {
        let map = this.state.map,
            t = this;

        let l = map.getLayers().item(2);
        if (!values || values.length === 0) {
            if (l && l.get("name") === "scheduledLayer") {
                l.setVisible(false);
            }
        } else {
            if (l && l.get("name") === "scheduledLayer") {
                l.setVisible(true);

                let fts = l.getSource().getFeatures();
                fts.forEach(function (item, index) {
                    let type = item.get("orderType"),
                        techId = item.get("tech"),
                        res = values.includes(type);

                    if (res) {
                        let styledLegend = t.setStyleFill(techId);

                        item.setStyle(styledLegend);
                    } else {
                        item.setStyle(new window.ol.style.Style({}));
                    }
                });
            }
        }
    };

    updateUnscheduledLayer = (values) => {
        let map = this.state.map;
        let l = map.getLayers().item(3);
        if (!values || values.length === 0) {
            if (l && l.get("name") === "nonScheduledLayer") {
                l.setVisible(false);
            }
        } else {
            if (l && l.get("name") === "nonScheduledLayer") {
                l.setVisible(true);

                let fts = l.getSource().getFeatures();
                fts.forEach(function (item, index) {
                    let type = item.get("orderType"),
                        res = values.includes(type);

                    if (res) {
                        item.setStyle(styles.unscheduledStyle);
                    } else {
                        item.setStyle(new window.ol.style.Style({}));
                    }
                });
            }
        }
    };

    toggleFilters = (e) => {
        e.preventDefault();
        let popup = document.getElementById("filterPopup");
        if (popup) {
            if (popup.style.display === "none") {
                popup.style.display = "block";
            } else {
                popup.style.display = "none";
            }
        }
    };

    renderScheduledDropDown() {
        return (
            <Col md={"12"} style={{ color: "#000" }}>
                <label>Scheduled Options</label>
                <MDBSelect
                    multiple
                    color="danger"
                    selected={"Choose Order Types"}
                    style={{ maxHeight: "100px" }}
                    options={this.state.scheduledOrderTypes}
                    getValue={this.getScheduledValue.bind(this)}
                />
            </Col>
        );
    }

    renderUnscheduledDropDown() {
        return (
            <Col md={"12"} style={{ color: "#000" }}>
                <label>Unscheduled Options</label>
                <MDBSelect
                    multiple
                    color="danger"
                    selected={"Choose Order Types"}
                    style={{ maxHeight: "100px" }}
                    options={this.state.unscheduledOrderTypes}
                    getValue={this.getUnscheduledValue.bind(this)}
                />
            </Col>
        );
    }

    renderFilterPopup() {
        return (
            <div id="filterPopup" style={{ display: "none" }}>
                <button
                    id="filter-popup-closer"
                    className="ol-popup-closer"
                    onClick={this.toggleFilters.bind(this)}
                />
                <div id="filter-popup-content">
                    <div>
                        <Input
                            label={"Show Office Layer?"}
                            id={"toggleOfficeLayer"}
                            type={"checkbox"}
                            checked={this.state.showOfficeLayer}
                            onChange={this.toggleOfficeLayer.bind(this)}
                        />
                    </div>
                    <hr />
                    <div>{this.renderScheduledDropDown()}</div>
                    <div>{this.renderUnscheduledDropDown()}</div>
                    <div>
                        <Button color={"info"} onClick={this.removeFilters.bind(this)}>
							Show All Orders
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    renderTechBar() {
        let techLegend = this.state.techLegend;

        if (techLegend && techLegend.length > 0) {
            return (
                <div>
                    {techLegend.map((x) => {
                        let fill =
								"rgba(" +
								x.fill[0] +
								"," +
								x.fill[1] +
								"," +
								x.fill[2] +
								"," +
								x.fill[3] +
								")",
                            tech = techLegend.find((t) => t.tech === x.tech);
                        return (
                            <MDBBtn
                                size="sm"
                                key={x.tech}
                                color={tech.visible ? "info" : "primary"}
                                style={{ textTransform: "none" }}
                                data-tip={"Toggle " + x.name + "'s Orders"}
                                onClick={this.toggleTechOrders.bind(this, x.tech)}
                            >
                                <span>{x.name}</span>
                                <MDBIcon
                                    icon="map-marker-alt"
                                    style={{ color: fill, marginLeft: 8 }}
                                />
                                <ReactTooltip />
                            </MDBBtn>
                        );
                    })}
                </div>
            );
        }

        return <div />;
    }

    setMapToFullScreen() {
        //if your map element id is other than 'map' change it here
        let elem = document.getElementById("map");

        if (this.state.fullScreen === true) {
            document.exitFullscreen();
        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            }
        }

        this.setState({
            fullScreen: !this.state.fullScreen,
        });
    }

    zoomMap(zoomType) {
        let view = this.state.view,
            zoom = view.getZoom();

        if (zoomType === "zoomIn") {
            view.setZoom(zoom + 1);
        }
        if (zoomType === "zoomOut") {
            view.setZoom(zoom - 1);
        }
    }

    render() {
        return (
            <div id="map" style={ { width: "100%", height: "100%" } }>
                <div style={ { backgroundColor: "rgb(46, 95, 147)", maxWidth: "99%" } }>
                    <Row style={ { justifyContent: "flex-end" } }>
                        <MDBBtnGroup>
                            { this.renderTechBar() }
                            <MDBBtn
                                color={ "info" }
                                size="sm"
                                onClick={ this.toggleFilters.bind(this) }
                            >
                                <Fa icon="filter" />
                            </MDBBtn>

                            <MDBBtn
                                color={ "info" }
                                size="sm"
                                onClick={ () => this.zoomMap("zoomOut") }
                            >
                                <Fa icon="minus" />
                            </MDBBtn>

                            <MDBBtn
                                color={ "info" }
                                size="sm"
                                onClick={ () => this.zoomMap("zoomIn") }
                            >
                                <Fa icon="plus" />
                            </MDBBtn>

                            <MDBBtn
                                color={ "info" }
                                size="sm"
                                onClick={ () => this.setMapToFullScreen() }
                            >
                                <Fa icon="expand" />
                            </MDBBtn>
                        </MDBBtnGroup>
                    </Row>
                </div>
                { this.renderFilterPopup() }
                { this.renderPopup() }
                <ReactTooltip />
            </div>
        );
    }
}
