import api from "../api";

class HcpcsCodeService {
    async getAllHcpcsCodes() {
        return new Promise((res, reject) => {
            api
                .get("/hcpcsCode")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getHcpcsCodeById(id) {
        return new Promise((res, reject) => {
            api
                .get("/hcpcsCode/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createHcpcsCode(obj) {
        return new Promise((res, reject) => {
            api
                .post("/hcpcsCode", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateHcpcsCode(obj) {
        return new Promise((res, reject) => {
            api
                .put("/hcpcsCode", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteHcpcsCode(id) {
        return new Promise((res, rej) => {
            return api.delete('/hcpcsCode/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }
}

const hcpcsCodeService = new HcpcsCodeService();

export default hcpcsCodeService;
