import api from "../api";

class VendorService {
    async getAllVendors() {
        return new Promise((res, reject) => {
            api
                .get("/vendors")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createVendor(vendor) {
        return new Promise((res, reject) => {
            api
                .post("/vendor", vendor)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async editVendor(vendor) {
        return new Promise((res, reject) => {
            api
                .put("/vendor", vendor)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteVendor(id) {
        return new Promise((res, reject) => {
            api
                .delete("/vendor/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const vendorService = new VendorService();

export default vendorService;
