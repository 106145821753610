import React from "react";
import PropTypes from "prop-types";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
} from "@devexpress/dx-react-grid-material-ui";

import {
    FilteringState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import {
    GroupingState,
    SortingState,
    IntegratedSorting,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
    Button,
    Container,
    Input,
    MDBSpinner,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    toast,
    ToastContainer,
    MDBIcon,
    Modal,
    ModalFooter,
    ModalBody,
    MDBListGroupItem,
    CardHeader,
    MDBInput,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import PurchaseQueueService from "../Security/PurchaseQueueService/purchaseQueueService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import ReactTooltip from "react-tooltip";
import NoteService from "../Security/NoteService/noteService";
import "./purchaseGrid.css";
import GlobalFunctions from "../Filters/GlobalFunctions";

export default class PurchaseQueueTable extends React.Component {
    constructor(props) {
        super(props);

        const approvalData = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Qty",
                    field: "qty",
                    sort: "asc",
                },
                {
                    label: "Description",
                    field: "desc",
                    sort: "asc",
                },
                {
                    label: "Cost",
                    field: "cost",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        function filterOnButtonKey(value, filter, row) {
            if(value.key) {
                return (value.key.includes(filter.value));
            }
            return false;
        }

        const filteringColumnExtensions = [
            {
                columnName: "orderId",
                predicate: (value, filter, row) => { return filterOnButtonKey(value, filter, row); },
            }
        ];

        const status = this.props.status;

        const groupColumns = [
            { columnName: "serviceLocation" },
            { columnName: "patient" },
            { columnName: "vendor" },
        ];

        const data = this.buildData(status);

        let defaultColumnWidths = [{ columnName: "itemId", width: 120 }];

        this.state = {
            filteringColumnExtensions: filteringColumnExtensions,
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            defaultColumnWidths: defaultColumnWidths,
            groupSummaryItems: [],
            status: status,
            approvalModal: false,
            nPurchaseQueues: [],
            brightreeId: null,
            errorMessage: "",
            approvalData: approvalData,
            saveloading: false,
        };

        this.changeGrouping = (grouping) => {
            let ary = [];

            grouping.forEach((group) => {
                ary.push({ columnName: group.columnName });
            });
            this.setState({
                groupingColumns: ary,
            });
        };

        this.getResults(status);
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
        allVendors: PropTypes.array,
    };

    buildData(status) {
        let d = {
            columns: [
                {
                    title: "ID",
                    name: "id",
                },
                // {
                // 	title: "Patient",
                // 	name: "patient",
                // },
                {
                    title: "Order Id",
                    name: "orderId",
                },
                {
                    title: "Item Id",
                    name: "itemId",
                },
                {
                    title: "Quantity",
                    name: "quantity",
                },
                {
                    title: "Requested On",
                    name: "requestedOn",
                },
                {
                    title: "Status",
                    name: "status",
                },
                {
                    title: "Service Location",
                    name: "serviceLocation",
                },
                {
                    title: "Product",
                    name: "product",
                },
                {
                    title: "Vendor",
                    name: "vendor",
                },
                {
                    title: "Status Changed On",
                    name: "statusChangedOn",
                },
            ],
            rows: [],
        };

        switch (status) {
            case "APPROVED":
                d.columns = [
                    ...d.columns,
                    { title: "BT ID", name: "brightreeId" },
                    { title: "View Purchase Order", name: "purchaseOrder" },
                    {
                        title: "Cancel",
                        name: "cancel",
                    },
                ];
                break;
            case "DENIED":
                d.columns = [
                    ...d.columns,
                    {
                        title: "Patient",
                        name: "patient",
                    },
                    { title: "View Quote", name: "quote" },
                    { title: "Pending", name: "pending" },
                ];
                break;
            case "PENDING":
                d.columns = [
                    ...d.columns,
                    {
                        title: "Patient",
                        name: "patient",
                    },
                    { title: "Approve / Deny", name: "approve" },
                    { title: "View Quote", name: "quote" },
                ];
                break;
            default:
                break;
        }
        return d;
    }

    Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

    CellContent = ({ row, column, expanded, ...props }) => {
        let cell = (
            <TableGroupRow.Cell
                {...props}
                row={row}
                column={column}
                expanded={expanded}
            />
        );
        return cell;
    };

    async handleApproveAll(e, grid) {
        const { currentUser } = this.context;
        e.stopPropagation();

        let purchaseIds = [];

        grid.rows.forEach((r) => {
            purchaseIds.push(r.id);
        });

        let objs = await PurchaseQueueService.findPurchaseQueuesByIds({
            ids: purchaseIds,
        });

        if (objs && objs.length > 0) {
            objs.map((p) => {
                p.statusChangedBy = { id: currentUser.id, name: currentUser.username };
                p.statusChangedOn = new Date();
                p.status = "APPROVED";
                return p;
            });

            this.setState({
                nPurchaseQueues: objs,
                approvalModal: true,
            });
        }
    }

    createOrderNotes(orders) {
        const { allVendors } = this.context;

        orders.forEach((o) => {
            //let vendor = o.vendor || "(No vendor)";
            let v = allVendors.find(x => x.id === o.vendorId),
                vendor = v && v.name ? v.name : "(No Vendor)";

            let text =
				vendor + " quote approved . Brightree ID:" + o.brightreeId;

            let note = {
                type: "ActivityNote",
                text: text,
                createdAt: GlobalFunctions.getUTCMoment(),
                task: false,
                completed: false,
                createdBy: o.statusChangedBy,
                createdById: o.statusChangedById,
            };

            OrderService.getOrderActivity(o.orderId).then((r) => {
                note.accountId = r.activity.accountId;

                NoteService.createActivityNote(o.orderId, note)
                    .then((res) => {})
                    .catch((err) => {
                        console.log(err);
                    });
            });
        });
    }

    approvePurchaseQueues = () => {
        let st = this.state,
            purchaseQueues = st.nPurchaseQueues,
            btId = parseInt(st.brightreeId);
        this.setState({ saveloading: true });

        if (this.validateApproval(btId)) {
            purchaseQueues.map((p) => {
                p.brightreeId = btId;
                return p;
            });

            let pqtracking = {
                name: st.activeName,
                poNumber: btId.toString(),
                expectedShipDate: null,
                confirmationNumber: "",
                trackingNumber: "",
                orderId: st.activeOrderId,
            };

            // this is also the create call
            PurchaseQueueService.updatePurchaseTracking(pqtracking)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });

            PurchaseQueueService.approveAll(purchaseQueues)
                .then((res) => {
                    console.log(res);
                    let data = this.state.data,
                        rows = data.rows.slice();

                    purchaseQueues.map((result) => {
                        let i = rows.findIndex((row) => {
                            return result.id === row.id;
                        });
                        return rows.splice(i, 1);
                    });

                    data.rows = rows;
                    this.setState({
                        data: data,
                        approvalModal: false,
                        errorMessage: "",
                        brightreeId: "",
                        saveloading: false,
                    });

                    this.createOrderNotes(purchaseQueues);

                    if (res.id && res.id > 0) {
                        this.genrateDownload(res.id);
                        toast.success("Equipment Approved");
                    }
                    else {
                        toast.warn("There was an error creating the purchase order.");
                    }
                })
                .catch((err) => {
                    toast.error("There was an error creating the purchase order.");
                    console.log(err);
                    this.setState({
                        saveloading: false,
                    });
                });
        }
        else {
            this.setState({
                saveloading: false,
            });
        }
    };

    genrateDownload(fileId) {
        return OrderService.downloadDoc(fileId).then((res) => {
            let file = new Blob([res], { type: "application/pdf" });
            let url = window.URL.createObjectURL(file);
            let temp = document.createElement("a");
            temp.href = url;
            temp.setAttribute("download", "Purchase Order.pdf");
            temp.click();
            window.URL.revokeObjectURL(url);
        });
    }

    validateApproval(btId) {
        const { companyName } = this.context;
        if (!btId && companyName !== "corkmedical") {
            this.setState({
                errorMessage: "Please enter a Brightree P.O.",
            });
            return false;
        }
        return true;
    }

    Content = ({ row, column }) => (
        <span>
            <span>
                {column.title} : {row.value || "(Blank)"}
            </span>
            <span style={{ fontWeight: "bold" }}>&nbsp; {this.getVal(row)}</span>
        </span>
    );

    getVal(row) {
        if (row.collapsedRows.length > 0) {
            return "Count: " + row.collapsedRows.length;
        } else {
            return "";
        }
    }

    getResults(status) {
        let gc = [
            { columnName: "serviceLocation" },
            { columnName: "patient" },
            { columnName: "vendor" },
        ];

        if (status === "APPROVED" || status === "DENIED") {
            gc = [{ columnName: "serviceLocation" }, { columnName: "vendor" }];
        }

        return PurchaseQueueService.getPurchaseQueueListing(status)
            .then((res) => {
                this.setState({
                    groupingColumns: gc,
                });
                this.buildNewResults(res);
            })
            .catch((err) => {
                this.setState({
                    isLoaded: true,
                });
            });
    }

    buildNewResults(res) {
        let ary = [],
            dt = this.state.data,
            status = this.state.status;

        res.forEach((val) => {
            let row = {
                id: val.id,
                orderId: val.orderId ? this.createBtn(val.orderId, 1) : "",
                itemId: val.itemId || "",
                quantity: val.quantity || "",
                requestedOn: this.convertDate(val.requestedOn),
                status: val.status,
                serviceLocation: val.inventoryLocation || val.serviceLocation,
                product: val.product || "From Quote",
                vendor: val.vendor || "",
                statusChangedOn: this.convertDate(val.statusChangedOn),
            };
            switch (status) {
                case "APPROVED":
                    row = {
                        ...row,
                        brightreeId: val.brightreeId,
                        purchaseOrder: this.createBtn(val, 3),
                        cancel: this.createBtn(val.id, 2),
                    };
                    break;
                case "DENIED":
                    row = {
                        ...row,
                        patient: val.patientName,
                        quote: val.quoteId ? this.createBtn(val.quoteId, 3) : "",
                        pending: this.createBtn(val.id, 4),
                    };
                    break;
                case "PENDING":
                    row = {
                        ...row,
                        approve: this.createMultiBtn(val.id),
                        patient: val.patientName,
                        quote: val.quoteId ? this.createBtn(val, 3) : "",
                    };
                    break;
                default:
                    break;
            }
            ary.push(row);
        });

        dt.rows = ary;

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    createMultiBtn(id, orderId, patientName) {
        return (
            <div style={{ backgroundColor: "transparent" }}>
                <Button
                    floating
                    size="sm"
                    color="green"
                    disabled={!this.canApprove()}
                    onClick={() => this.handleEdit(id, "APPROVED", orderId, patientName)}
                >
                    <MDBIcon icon="check" style={{ fontSize: "1.2em" }} />
                </Button>

                <Button
                    floating
                    size="sm"
                    color="red"
                    disabled={!this.canApprove()}
                    onClick={() => this.handleEdit(id, "DENIED")}
                >
                    <MDBIcon icon="times" style={{ fontSize: "1.2em" }} />
                </Button>
            </div>
        );
    }

    createBtn(id, num) {
        switch (num) {
            case 0:
                return (
                    <Button
                        floating
                        size="sm"
                        color="green"
                        disabled={!this.canApprove()}
                        onClick={() => this.handleEdit(id, "APPROVED")}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "1.2em" }} />
                    </Button>
                );
            case 1:
                return (
                    <a
                        key={id}
                        className="btn-rounded btn btn-primary btn-sm Ripple-parent"
                        href={"/order/" + id}
                        target={"_blank"}
                        rel="opener"
                    >
                        <span>{id}</span>
                        {/*<MDBIcon icon="edit" style={{fontSize: "1.2em"}}/>*/}
                    </a>
                );
            case 2:
                return (
                    <Button
                        floating
                        size="sm"
                        color="red"
                        disabled={!this.canApprove()}
                        onClick={() => this.handleEdit(id, "DENIED")}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "1.2em" }} />
                    </Button>
                );
            case 3:
                return (
                    <Button
                        floating
                        size="sm"
                        color="secondary"
                        onClick={() => this.fileDownloadHandler(id)}
                    >
                        <MDBIcon icon="file-pdf" style={{ fontSize: "1.2em" }} />
                    </Button>
                );
            case 4:
                return (
                    <Button
                        floating
                        size="sm"
                        color="yellow"
                        onClick={() => this.handleEdit(id, "PENDING")}
                    >
                        <MDBIcon icon="retweet" style={{ fontSize: "2em" }} />
                    </Button>
                );
            default:
                return;
        }
    }

    fileDownloadHandler(row) {
        if (row.inventoryFile) {
            return OrderService.downloadDoc(row.inventoryFile)
                .then((res) => {
                    this.genrateNewTab(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (row.fileId) {
            return OrderService.downloadDoc(row.fileId)
                .then((res) => {
                    this.genrateNewTab(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            return OrderService.downloadDoc(row.quoteId)
                .then((res) => {
                    this.genrateNewTab(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    genrateNewTab(file) {
        let pdf = new Blob([file], { type: "application/pdf" });
        let url = window.URL.createObjectURL(pdf);
        let w = window.open();
        w.location.href = url;
        window.URL.revokeObjectURL(url);
    }

    saveEdit(purchaseQueue, orderId, patientName) {
        const { allVendors } = this.context;
        PurchaseQueueService.editPurchaseQueue(purchaseQueue)
            .then((res) => {
                let data = this.state.data,
                    rows = data.rows.slice(),
                    i = rows.findIndex((p) => {
                        return p.id === res.id;
                    });

                rows.splice(i, 1);
                data.rows = rows;
                this.setState({
                    data: data,
                });


                let v = allVendors.find(x => x.id === res.vendorId),
                    vendor = v && v.name ? v.name : "(No Vendor)";

                //let vendor = obj.vendor
                //	? obj.vendor.name
                //	: "(No vendor)";

                let text = vendor + " quote denied ";

                let note = {
                    type: "ActivityNote",
                    text: text,
                    createdAt: GlobalFunctions.getUTCMoment(),
                    task: false,
                    completed: false,
                    createdBy: res.statusChangedBy,
                    createdById: res.statusChangedById,
                };

                OrderService.getOrderActivity(purchaseQueue.orderId).then((r) => {
                    note.accountId = r.activity.accountId;

                    NoteService.createActivityNote(purchaseQueue.orderId, note)
                        .then((res) => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async handleEdit(id, status, orderId, patientName) {
        const { currentUser } = this.context;

        this.setState({
            activeName: patientName,
            activeOrderId: orderId,
        });
        let purchaseQueue = await PurchaseQueueService.findPurchaseQueuesById(id);

        purchaseQueue.status = status;
        purchaseQueue.statusChangedOn = new Date().toISOString();
        purchaseQueue.statusChangedById = currentUser.id;
        purchaseQueue.statusChangedBy = {
            id: currentUser.id,
            name: currentUser.username,
        };

        if (status === "APPROVED") {
            this.setState({
                nPurchaseQueues: [purchaseQueue],
                approvalModal: true,
            });
            return;
        }

        this.saveEdit(purchaseQueue, orderId, patientName);
    }

    convertDate(date) {
        //console.log(date)
        if (!date)
            return null;

        //var dt = new Date(date);

        //var mo = dt.getMonth() + 1,
        //	dy = dt.getDate(),
        //	yr = dt.getFullYear();

        //var res = (mo < 10 ? "0" + mo.toString() : mo.toString()) + "-" + (dy.toString() < 10 ? "0" + dy.toString() : dy.toString()) + "-" + yr.toString();
        //console.log(res)
        //return res;
        //let arr = date.split("-");
        //return arr[1] + "-" + arr[2] + "-" + arr[0];
        /*return new Date(date).toString();*/
        return GlobalFunctions.formatJsDate(date);
    }

    renderTable() {
        let st = this.state;
        return (
            <Grid
                rootComponent={this.Root}
                rows={st.data.rows}
                columns={st.data.columns}
            >
                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering columnExtensions={this.state.filteringColumnExtensions} />
                <PagingState defaultCurrentPage={0} pageSize={50} />
                <DragDropProvider />
                <SortingState
                    defaultSorting={[{ columnName: "requestedOn", direction: "desc" }]}
                />

                <GroupingState
                    columnGroupingEnabled={true}
                    defaultGrouping={st.groupingColumns}
                    // columnExtensions={st.disabledGroupings}
                />
                <IntegratedGrouping />
                <IntegratedPaging />
                <IntegratedSorting />
                <VirtualTable height={"auto"} />
                <PagingPanel />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <TableGroupRow
                    cellComponent={this.CellContent}
                    contentComponent={this.Content}
                    showColumnsWhenGrouped={false}
                />
                {this.ItemCounter()}
                <Toolbar />
                <GroupingPanel />
            </Grid>
        );
    }

    ItemCounter = () => (
        <Plugin name="ItemCounter">
            <Template
                name="tableCell"
                predicate={({ tableRow }) => isGroupTableRow(tableRow)}
            >
                {(params) => (
                    <TemplateConnector>
                        {({ getCollapsedRows, rows }) => {
                            const updatedParams = {
                                ...params,
                                tableRow: {
                                    ...params.tableRow,
                                    row: {
                                        ...params.tableRow.row,
                                        collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                        rows: rows.filter((r) => {
                                            return r.groupedBy === undefined;
                                        }),
                                    },
                                },
                            };
                            return <TemplatePlaceholder params={updatedParams} />;
                        }}
                    </TemplateConnector>
                )}
            </Template>
        </Plugin>
    );

    canApprove() {
        const { currentUser } = this.context;

        switch (currentUser.role) {
            case "PURCHASING":
            case "EXECUTIVE_MANAGEMENT":
            case "ADMIN":
                return true;
            default:
                return false;
        }
    }

    renderApproveModal() {
        let st = this.state;
        return (
            <Modal
                centered
                isOpen={st.approvalModal}
                toggle={() => {
                    return;
                }}
            >
                <CardHeader className="form-header text-center font-weight-bold blue-gradient">
                    <h3>Approve Equipment</h3>
                </CardHeader>
                <ModalBody>
                    <p style={{ color: "red" }}>{st.errorMessage}</p>
                    <Input
                        outline
                        label={"Brightree P.O."}
                        onChange={(evt) =>
                            this.setState({
                                brightreeId: evt.target.value && evt.target.value > 0 ? parseInt(evt.target.value) : null,
                            })
                        }
                    />
                    <div
                        style={{
                            maxHeight: "400px",
                            overflowX: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        <MDBTable striped>
                            <MDBTableHead columns={this.state.approvalData.columns} />
                            <MDBTableBody rows={this.renderApprovalTable()} />
                        </MDBTable>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={"red"}
                        floating
                        data-tip={"Cancel"}
                        size={"sm"}
                        label={"Close"}
                        onClick={() => {
                            this.setState({ approvalModal: false, errorMessage: "", saveloading: false, });
                        }}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                    {this.state.saveloading !== true ?
                        <Button
                            color={"green"}
                            floating
                            data-tip={"Save"}
                            size={"sm"}
                            onClick={this.approvePurchaseQueues}
                        >
                            <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                        </Button>
                        : <MDBSpinner small />
                    }
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderQueues() {
        return this.state.nPurchaseQueues.map((p, index) => {
            return (
                <MDBListGroupItem key={index}>
                    <div className="d-flex w-100 justify-content-between">
                        {!p.quoteId ? (
                            <Input
                                label={"Cost"}
                                onChange={this.handleCost.bind(this, index)}
                            />
                        ) : (
                            <div />
                        )}
                        <h5 className="mb-1">ID: {p.id}</h5>
                    </div>
                    {p.quoteId ? (
                        <p className="mb-1">Ordered from quote</p>
                    ) : (
                        <div>
                            <p className="mb-1">{p.productSubType.name}</p>
                            <small className="text-muted">{"Quantity: " + p.quantity}</small>
                        </div>
                    )}
                </MDBListGroupItem>
            );
        });
    }

    renderApprovalTable() {
        let st = this.state,
            arr = [];
        st.nPurchaseQueues.map((p, index) => {
            return arr.push({
                id: p.id,
                qty: (
                    <MDBInput
                        type={"number"}
                        containerClass={"tableInputContainer"}
                        outline
                        valueDefault={p.quantity}
                        onChange={this.handleQty.bind(this, index)}
                    />
                ),
                desc: p.quoteId
                    ? "Ordered from quote"
                    : p.productSubType
                        ? p.productSubType.name
                        : "No Sub Type",
                cost: (
                    <MDBInput
                        type={"number"}
                        containerClass={"tableInputContainer"}
                        outline
                        onChange={this.handleCost.bind(this, index)}
                    />
                ),
            });
        });
        return arr;
    }

    handleCost(index, evt) {
        let pq = this.state.nPurchaseQueues;
        pq[index].cost = evt.target.value && evt.target.value > 0 ? parseFloat(evt.target.value) : null;
        this.setState({
            nPurchaseQueues: pq,
        });
    }

    handleQty(index, evt) {
        let pq = this.state.nPurchaseQueues;
        pq[index].quantity = evt.target.value ? parseInt(evt.target.value) : null;
        this.setState({
            nPurchaseQueues: pq,
        });
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <MDBSpinner multicolor />
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div className={"purchasingGrid"} style={{ height: "100%" }}>
                {this.renderTable()}
            </div>
        );
    }

    render() {
        let h = window.innerHeight;
        return (
            <div style={{ overflowY: "scroll", height: h * 0.7, maxHeight: h * 0.7 }}>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{ marginTop: "75px" }}
                />
                {this.renderTableOrSpinner()}
                {this.renderApproveModal()}
            </div>
        );
    }
}
