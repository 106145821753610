import Tile from "../Tile";
import PropTypes from "prop-types";
import MUIGreyColor from "@mui/material/colors/grey";
import User from "../../Utility/Crud/User";

export default class TileUser extends Tile {
    static contextTypes = {
        teamUsersIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
    };
    /**
      * @returns {string} The User name.
      */
    getTitle() {
        return this.props.user ? User.getDisplayName(this.props.user) : "None";
    }

    /**
     * @returns {string} The user's email. Ideally in the future we can change
     * this to title or something more meaningful.
     */
    getSubtitle() {
        // Function to convert snake_case to Title Case
        const formatRole = (role) => {
            if (!role) return '';
            return role
                .split('_') 
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
                .join(' ');
        }

        if (this.props.user) {
            // Get all teams the user is part of
            const teams = Object.values(this.context.teamUsersIndexed).filter((teamUser) => teamUser.userId === this.props.user.id);

            // Get the team objects from teamsIndexed that match the teamIds
            const userTeams = teams
                .map(teamUser => this.context.teamsIndexed[teamUser.teamId])
                .filter(team => team)
                .sort((a, b) => a.name.localeCompare(b.name)); 

            // Get the formatted role
            const role = this.props.user?.role ? formatRole(this.props.user.role) : 'No role';

            // Return the role and teams
            if (userTeams.length > 0) {
                const teamNames = userTeams.map((userTeam) => userTeam.name).join(', ');
                return `${role} • ${teamNames}`;
            } else {
                return `${role} • No teams`;
            }
        }

        return null;
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.user ? User.getDisplayName(this.props.user) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.user ? User.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return User.getIcon();
    }
}