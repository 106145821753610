import Crud from "../Crud";
import MUICyanColor from "@mui/material/colors/cyan";

/**
 * Static helper functions related to MarketingCampaignCategories.
 */
export default class MarketingCampaignCategory extends Crud {

    /**
     * @param {object} marketingCampaignCategory The marketingCampaignCategory object.
     * @returns The display name of the marketingCampaignCategory.
     */
    static getDisplayName(marketingCampaignCategory) {
        return marketingCampaignCategory.name;
    }

    /**
     * Determines if a marketingCampaignCategory matches a search query.
     *
     * @param {object} marketingCampaignCategory The marketingCampaignCategory to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the marketingCampaignCategory matches the query.
     */
    static match(marketingCampaignCategory, query) {
        return (
            (marketingCampaignCategory.name !== null && marketingCampaignCategory.name.toLowerCase().includes(query)) ||
            marketingCampaignCategory.id === +query
        );
    }

    /**
     * @param {array} marketingCampaignCategories The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(marketingCampaignCategories) {
        return marketingCampaignCategories.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUICyanColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Campaign";
    }
}