import React from "react";
import {
    Button,
    CardHeader,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Fa,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBCollapse,
    MDBCollapseHeader,
    MDBIcon,
    MDBInput,
    MDBRow,
    Modal,
    ModalBody,
    Row,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
} from "mdbreact";
import PropTypes from "prop-types";
import MUIAlert from '@mui/material/Alert';
import AccountService from "../../Security/AccountService/accountService";
import moment from "moment";
import OrderService from "../../Security/OrderService/orderService";
import MUIMenuItem from "@mui/material/MenuItem";
import MUITextField from "@mui/material/TextField";
import ReactTooltip from "react-tooltip";
import NoteService from "../../Security/NoteService/noteService";
import { toast } from "react-toastify";
import GlobalFunctions from "../../Filters/GlobalFunctions";
import Security from "../../../Utility/Security";
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import MUIGreyColor from "@mui/material/colors/grey";

export default class TypeStatusContainer extends React.Component {
    constructor(props) {
        super(props);

        this.updateParent = props.updateParent;
        this.handleCollapse = props.handleCollapseId;
        this.updateTableRows = props.updateTableRows;
        this.saveOrder = props.saveOrder;
        this.validateSave = props.validateSave;
        this.updateNotes = props.updateNotes;

        this.state = {
            eOrder: props.eOrder,
            order: props.order,
            collapseID: props.collapseID,
            goto: props.goto,
            statuses: [
                {label: "New", value: 0},
                {label: "In Process", value: 1},
                {label: "Ready To Deliver", value: 2},
                {label: "Set Up", value: 3},
                {label: "Cancelled", value: 4},
            ],
            orderTypes: ["New", "Pickup", "Service", "Exchange", "Billing Issue", "Re-Supply"],
            filteredReasons: [],
            currSalesRepAccounts: [],
            accountSearch: "",
            scheduleModalOpen: false,
            paymentModalOpen: false,
            ssAcknowledge: false,
            customerSuccessDisabled: false,
            frequencies: ["None / Disabled", "Monthly", "Annually"],
            frequencyRecord: {},
            salesReps: [],
            hasApprovals: (props.order.orderActivityApprovals && props.order.orderActivityApprovals.length > 0),
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
        reasons: PropTypes.array,
        salesReps: PropTypes.array,
        userRefs: PropTypes.array,
        allActiveUsers: PropTypes.array,
        allAtp: PropTypes.array,
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        marketingCampaigns: PropTypes.array,
        techs: PropTypes.array,
        orderStatusesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        marketingCampaignsIndexed: PropTypes.object,

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.eOrder !== prevState.eOrder) {
            return {eOrder: nextProps.eOrder};
        } else return null;
    }

    componentDidMount() {
        //const {companyName} = this.context;
        let order = this.state.order;

        this.getSalesRepsAccounts();

        // if (companyName === "corkmedical" || companyName === "uat") {
        //     let ot = this.state.orderTypes;
        //     ot.push("Re-Supply");
        //     this.setState({
        //         orderTypes: ot
        //     })
        // }

        this.getOrderFromFrequency(order.id);
        this.filterReps();
    }

    componentDidUpdate() {
        const { salesReps } = this.context;
        if (salesReps.length !== this.state.salesReps.length) {
            this.filterReps();
        }
    }

    formatDate(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    }

    saveNote(noteText) {
        let eOrder = this.state.eOrder;

        const {currentUser} = this.context;
        let note = {
            type: "ActivityNote",
            text: noteText,
            createdAt: GlobalFunctions.getUTCMoment(),
            account: eOrder.activity.account,
            accountId: eOrder.activity.accountId,
            task: false,
            completed: false,
            createdBy: { id: currentUser.id, name: currentUser.username },
            createdById: currentUser.id,
            patientName: eOrder.patientName,
        };

        NoteService.createActivityNote(eOrder.id, note)
            .then((res) => {
                toast.success("Note saved");
            })
            .catch((err) => {
                toast.error("Error: Note not saved");
            });
    }

    getSalesRepsAccounts() {
        let rep = this.state.eOrder.activity.ownerId;
        AccountService.getAccountsByUser(rep)
            .then((accounts) => {
                this.setState({
                    currSalesRepAccounts: accounts,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getOrderFromFrequency(id) {
        OrderService.getOrderFrequency(id).then(res => {
            let last = {};

            if (res && res.length > 0) {
                last = [...res].pop();
            }

            this.setState({
                frequencyRecord: last
            });
        }).catch(e => {

        });
    }

    createOrderFrequency() {
        let d = this.state.frequencyRecord,
            eOrder = this.state.eOrder;

        if (!d.nextDate || !d.frequency) {
            return toast.warn("Please fill out all fields to set the frequency");
        }
        d.orderId = eOrder.id;
        d.name = eOrder.patientName;

        OrderService.createOrderFrequency(d).then(res => {
            toast.success("Frequency order created successfully");
        }).catch(e => {
            toast.warn("Error creating order frequency");
        });
    }

    updateOrderFrequency() {
        let d = this.state.frequencyRecord,
            eOrder = this.state.eOrder;

        d.name = eOrder.patientName;

        OrderService.updateOrderFrequency(d).then(res => {
            toast.success("Successfully updated order frequency");
        }).catch(e => {
            toast.warn("Error updating order frequency");
        });
    }

    toggleCollapse(collapseID) {
        this.setState({
            collapseID: collapseID,
        });
        this.handleCollapse(collapseID)
    }

    togglePaymentModalPopup = () => {
        let currentState = this.state.paymentModalOpen;
        this.setState({paymentModalOpen: !currentState});
    };

    toggleScheduleModalPopup = () => {
        let currentState = this.state.scheduleModalOpen;
        this.setState({
            scheduleModalOpen: !currentState,
            ssAcknowledge: false,
        });
    };

    paymentPlanApprovedChange = (event) => {
        this.setState({paymentPlanApproved: event.target.checked});
    };

    confirmCustomerSuccessDate() {
        let eOrder = this.state.eOrder,
            approvals = eOrder.orderActivityApprovals ? eOrder.orderActivityApprovals[0].approvals : [];

        if (!this.validateSave(eOrder)) {
            return;
        } else {
            this.setState({
                customerSuccessDisabled: true,
            });
        }

        approvals.customerSuccessDate = new Date().toLocaleDateString();

        eOrder.orderActivityApprovals[0].approvals = approvals;
        this.updateParent(eOrder);
        this.generateCustomerSuccessNote();
    }

    confirmScheduleDelivery() {
        const {currentUser} = this.context;

        let eOrder = this.state.eOrder,
            order = this.state.order;


        let noteDate = GlobalFunctions.formatDateTimeTimeZone(eOrder.scheduledDeliveryDate);

        //this has a technician and date and is being scheduled...BC
        if (eOrder.technician && eOrder.scheduledDeliveryDate) {
            if (this.state.ssAcknowledge) {
                let noteText =
                    currentUser.username +
                    " acknowledged that there is a same/sim on file and scheduled the order to be delivered on " +
                    noteDate;

                if (eOrder.technician && eOrder.technician.name) {
                    noteText += " with technician " + eOrder.technician.name;
                }

                this.saveNote(noteText);
            }

            if (
                eOrder.orderStatusReason &&
                eOrder.orderStatusReason.description === "Scheduled"
            ) {
                let text =
                    "Order was scheduled by " +
                    currentUser.username +
                    " for " +
                    noteDate +
                    " with technician " +
                    eOrder.technician.firstname + " " + eOrder.technician.lastname;

                this.saveNote(text);
            }

            //if the old date does not match the new date, send the video...BC
            if (
                new Date(order.scheduledDeliveryDate) !==
                new Date(eOrder.scheduledDeliveryDate)
            ) {


                //save after
                this.saveOrder(this.state.eOrder);
            }
        } else {
            //save even if it doesn't have both parts...BC
            this.saveOrder(eOrder);
        }
        //close the modal...BC
        this.toggleScheduleModalPopup();
    }

    generateCustomerSuccessNote() {
        const {currentUser} = this.context;

        let st = this.state,
            noteText =
                currentUser.username +
                " contacted patient regarding customer success on " +
                new Date().toLocaleString(),
            cb = {id: currentUser.id, name: currentUser.username},
            eOrder = st.eOrder;

        let note = {
            type: "ActivityNote",
            text: noteText,
            createdAt: GlobalFunctions.getUTCMoment(),
            account: eOrder.activity.account,
            accountId: eOrder.activity.accountId,
            task: false,
            completed: true,
            createdBy: cb,
            createdById: cb.id,
            activityId: eOrder.id,
        };

        NoteService.createActivityNote(eOrder.id, note)
            .then((res) => {
                this.updateNotes(note);
                this.saveOrder(eOrder);
                this.setState({
                    customerSuccessDisabled: false,
                });
            })
            .catch((err) => {
                //Maybe send an error to the user?...BC
            });
    }

    confirmPaymentPlanProcessed() {
        const {currentUser} = this.context;

        let eOrder = this.state.eOrder,
            approvals = eOrder.orderActivityApprovals ? eOrder.orderActivityApprovals[0].approvals : [];

        approvals.paymentProcessedBy = currentUser;

        approvals.paymentProcessedById = currentUser.id;
        approvals.paymentProcessedOn = new Date();

        eOrder.orderActivityApprovals[0].approvals = approvals;

        this.setState({eOrder: eOrder});

        this.updateParent(eOrder);
        this.togglePaymentModalPopup();
    }

    setAndUpdate(prop, val) {
        if (val && val.toString() === "Invalid date") {
            val = null;
        }
        let eOrder = this.state.eOrder;

        if (eOrder.hasOwnProperty(prop)) {
            eOrder[prop] = val;
        }

        if(eOrder.activity.hasOwnProperty(prop)){
            eOrder.activity[prop] = val;
        }

        if (eOrder.patient.hasOwnProperty(prop)) {
            eOrder.patient[prop] = val;
        }

        this.setState({eOrder: eOrder});
        this.updateParent(eOrder);
    }

    approvalAndUpdate(prop, val) {
        const { currentUser } = this.context;
        let eOrder = this.state.eOrder,
            cb = { id: currentUser.id, name: currentUser.username };
        // console.log(val)
        if (prop === "deliverByDate") {
            if (eOrder.orderActivityApprovals && eOrder.orderActivityApprovals.length > 0) {
                if (eOrder.orderActivityApprovals[0].approvals
                    && eOrder.orderActivityApprovals[0].approvals.deliverByDate
                    && new Date(eOrder.orderActivityApprovals[0].approvals.deliverByDate).toLocaleDateString() !== new Date(val).toLocaleDateString()
                    && !["", undefined, "Invalid date"].includes(val)) {
                    alert("You are changing the deliver by date. Please verify that the new date is still within the approval date range.");
                    let txt = "Deliver by date has been changed to: " + new Date(val).toLocaleDateString() + ", from: " +
                        new Date(eOrder.orderActivityApprovals[0].approvals.deliverByDate).toLocaleDateString() + ", by: " +
                        currentUser.firstname + " " + currentUser.lastname;
                    let note = {
                        type: "ActivityNote",
                        text: txt,
                        createdAt: GlobalFunctions.getUTCMoment(),
                        account: eOrder.activity.account,
                        accountId: eOrder.activity.accountId,
                        task: false,
                        completed: true,
                        createdBy: cb,
                        createdById: cb.id,
                        activityId: eOrder.id,
                    };

                    NoteService.createActivityNote(eOrder.id, note)
                        .then((res) => {
                            if (res.createdAt.indexOf("Z") > 0) {
                                res.createdAt = res.createdAt.split("Z")[0];
                            }
                            this.updateNotes(res);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
                else if (!eOrder.orderActivityApprovals[0].approvals.deliverByDate && !["", undefined, "Invalid date"].includes(val)) {
                    let note = {
                        type: "ActivityNote",
                        text: "Delivery by date set to " + new Date(val).toLocaleDateString() + " by " + currentUser.username,
                        createdAt: GlobalFunctions.getUTCMoment(),
                        account: eOrder.activity.account,
                        accountId: eOrder.activity.accountId,
                        task: false,
                        completed: true,
                        createdBy: cb,
                        createdById: cb.id,
                        activityId: eOrder.id,
                    };

                    NoteService.createActivityNote(eOrder.id, note)
                        .then((res) => {
                            if (res.createdAt.indexOf("Z") > 0) {
                                res.createdAt = res.createdAt.split("Z")[0];
                            }
                            this.updateNotes(res);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
        }

        //     if (eOrder.orderActivityApprovals && eOrder.orderActivityApprovals.length > 0) {
        eOrder.orderActivityApprovals[0].approvals[prop] = ["", undefined, "Invalid date"].includes(val) ? null : val;
        this.setState({ eOrder: eOrder });
        this.updateParent(eOrder);
        //     }
        //     else {
        //eOrder.orderActivityApprovals[0].approvals[prop] = val;
        //         this.setState({ eOrder: eOrder });
        //         this.updateParent(eOrder);
        //     }
    }

    statusChanged(v) {
        let eOrder = this.state.eOrder;

        eOrder.orderStatusReason = null;
        eOrder.orderStatusReasonId = null;

        //this.setState({ eOrder: eOrder });
        this.setAndUpdate("status", v);
        this.updateTableRows();
    }

    updateReason(v) {
        const {reasons} = this.context;

        if (v) {
            let r = reasons.find((o) => {
                return v === o.id;
            });

            if (r) {
                let q = {
                    id: r.id,
                    description: r.description,
                };
                this.setAndUpdate("orderStatusReason", q);
                this.setAndUpdate("orderStatusReasonId", q.id);
            }
        } else {
            this.setAndUpdate("orderStatusReason", null);
            this.setAndUpdate("orderStatusReasonId", null);
        }
    }

    changeByRef(prop, val) {
        let o = null;

        if (val) {
            o = {
                name: val.name,
                id: val.id,
            };
        }

        this.setAndUpdate(prop, o);
    }

    //region internal details functions
    changeSalesRep(rep) {
        let eOrder = this.state.eOrder;
        // console.log(rep)
        eOrder.activity.owner = rep; //{
        //    id: rep.id,
        //    usernamename: rep.username,
        //};
        eOrder.activity.ownerId = rep.id;
        //if (rep.userprofileLocations) {
        //    let newLoc = rep.userprofileLocations.find((loc) => {
        //        return loc.locations.type === 0;
        //    });
        //    console.log(newLoc)
        //    //if we have a new sales location use it...BC
        //    if (newLoc) {
        //        eOrder.salesLocation = {id: newLoc.id, name: newLoc.name};
        //        eOrder.serviceLocation = {id: newLoc.id, name: newLoc.name};
        //    }
        //}

        this.setState({
            eOrder: eOrder,
            selectedRep: rep,
        });
        this.updateParent(eOrder);
        this.getSalesRepsAccounts();
    }

    changeSeatingClinic(clinic) {
        let eOrder = this.state.eOrder;

        if (clinic == null) {
            eOrder.seatingClinicLocation = null;
            eOrder.seatingClinicLocationId = null;
        } else {
            eOrder.seatingClinicLocation = {
                id: clinic.id,
                name: clinic.name,
            };
            eOrder.seatingClinicLocationId = clinic.id;
        }

        this.setState({eOrder: eOrder});
    }

    changeTherapist(therapist) {
        let eOrder = this.state.eOrder;

        if (therapist == null) {
            eOrder.therapist = null;
            eOrder.therapistId = null;
        } else {
            eOrder.therapist = {
                id: therapist.id,
                name: therapist.name,
            };
            eOrder.therapistId = therapist.id;
        }

        this.setState({eOrder: eOrder});
    }

    //endregion

    handleDate(prop, e) {
        //let v;
        if(prop === "setupDate" || prop === "deliverByDate"){
            if(e){
                e.set("hour",12);
                e.set("minute", 0);
            }
        }
console.log(e)

        let q = new moment.utc(e);
        let v = q.format("YYYY-MM-DDTHH:mm:ss");
        //e ? (v = parseInt(e.format("x"))) : (v = e);

        if (prop === "deliverByDate") {
            // console.log(v)
            this.approvalAndUpdate(prop, v);
        } else {
            this.setAndUpdate(prop, v);
        }

    }

    //region type and status

    renderOrderTypeDropdown() {
        let eOrder = this.state.eOrder,
            types = this.state.orderTypes;

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="outlined-select-status"
                    select
                    size={"small"}
                    fullWidth
                    label="Order Type"
                    value={eOrder.orderType}
                    onChange={(e) => {
                        this.setAndUpdate("orderType", e.target.value);
                        this.updateTableRows();
                    }}
                    variant="outlined"
                >
                    {types.map((option, idx) => {
                        let d = false;
                        if (option === "Service" || (option === "Pickup" && eOrder.orderType !== "Pickup") || option === "Exchange") {
                            return null;
                        }
                        if (option === "Billing Issue" && eOrder.orderType !== "Billing Issue") {
                            return null;
                        }
                        return (
                            <MUIMenuItem disabled={d} key={idx} value={option}>
                                {option}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }

    renderOrderStatusDropdown() {
        const {currentUser} = this.context;

        let eOrder = this.state.eOrder,
            statuses = this.state.statuses,
            shouldDisable = false;

        if (this.state.order.status === 3) {
            let r = currentUser.role;
            if (
                r !== "ADMIN" &&
                r !== "EXECUTIVE_MANAGEMENT" &&
                r !== "SALES_LIAISON"
            ) {
                shouldDisable = true;
            }
        }

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="outlined-select-status"
                    select
                    disabled={false}
                    size={"small"}
                    fullWidth
                    label="Order Status"
                    value={eOrder.status}
                    onChange={(e) => this.statusChanged(e.target.value)}
                    variant="outlined"
                >
                    {statuses.map((option, idx) => {
                        let d = false;
                        if (option.value === 4) {
                            d = !Security.hasPermission("order.cancel");
                        }
                        return (
                            <MUIMenuItem
                                disabled={option.value === 2 && this.state.eOrder.status !== 3 || d || shouldDisable && this.state.eOrder.status !== 3}
                                key={idx}
                                value={option.value}
                            >
                                {option.label}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }
    shouldDisableReason(reasonToID) {
        const { orderStatusReasonsIndexed } = this.context;


        let order = this.state.order;
        if (order.orderStatusReasonId === null) {
            return false;
        }
        if (order.orderType === "Internal Request") {
            return false;
        }
        let shouldDisable = false;
        let longDisableList = [16, 75, 76, 78, 79, 80, 81, 84, 87]; //16 Need Paperwork Corrections (Chart Notes), 75 Need Paperwork Corrections (SWO), 76 Need Paperwork Corrections (DSWO), 78 Need Paperwork Corrections (F2F), 79 Need Paperwork Corrections (LCMP Eval - Technical), 80 Need Paperwork Corrections (Pre-Auth Form), 81 Need Paperwork Corrections (ATP Assessment), 84 Need Paperwork Corrections (LCMP Eval - Medical Necessity), 87 Sales Corrections
        let finalIntakePaperworkScheduling = [86, 11, 27, 10]; //86 Final Signature, 11 Intake, 27 Paperwork, 10 Scheduling

        //Disable most things sending to 86,11,27,10
        if (finalIntakePaperworkScheduling.includes(reasonToID)) {
            shouldDisable = true;
            if (reasonToID === 10 && order.orderStatusReasonId === 14) { //10 Scheduling, 14 pdf review
                shouldDisable = false;
            }
            //Check based on currentIntakeQuestionaire
            else if (order.orderStatusReasonId === 15) {
                if (!order.currentIntakeQuestionnaireId && reasonToID === 11) { // 11 Intake
                    shouldDisable = false;
                } else if (order.currentIntakeQuestionnaireId && reasonToID === 10) { //10 Scheduling
                    shouldDisable = false;
                }
            } else if (orderStatusReasonsIndexed[order.orderStatusReasonId].orderStatus === 0) {//If orderstatus is New
                shouldDisable = false;
            }
        }
        //Disable 86,11,27,10 from sending to 16, 75, 76, 78, 79, 80, 81, 84, 87
        if (finalIntakePaperworkScheduling.includes(order.orderStatusReasonId) && longDisableList.includes(reasonToID)) {
            shouldDisable = true;
        }
        if (reasonToID === 11 && order.status === 4) { // 11 Intake, Status 4 Cancelled 
            shouldDisable = false;
        }

        return shouldDisable;
    }
    renderOrderReasonDropdown() {
        const { reasons } = this.context;
        let sortedReasons = reasons.slice().sort((a, b) => {
            return a.description.localeCompare(b.description);
        });        
        let eOrder = this.state.eOrder;

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="outlined-select-statusReason"
                    select
                    size={"small"}
                    fullWidth
                    label="Status Reason"
                    value={eOrder.orderStatusReason ? eOrder.orderStatusReason.id : ""}
                    onChange={ (e) => this.updateReason(e.target.value) }
                    variant="outlined"
                >
                    {   eOrder.status === 1 ||
                        eOrder.status === 2 ||
                        eOrder.status === 3 ?
                        null :
                        (
                            <MUIMenuItem key={-1} value={null}>
                                { "(Remove Reason)" }
                            </MUIMenuItem>
                        ) }

                    {sortedReasons ? sortedReasons.map((r, idx) => {
                        if (r.id === 56) { return false; }
                        if (r.orderStatus === eOrder.status) {
                            if (eOrder.status === 2) {
                                if (r.description === "Scheduled") {
                                    if (!eOrder.technician || !eOrder.scheduledDeliveryDate) {
                                        return (
                                            <MUIMenuItem disabled key={idx} value={r.id}>
                                                {r.description}
                                            </MUIMenuItem>
                                        );
                                    }
                                }
                            }

                            if (this.shouldDisableReason(r.id)) {
                                return (
                                    <MUIMenuItem disabled key={idx} value={r.id}>
                                        {r.description}
                                    </MUIMenuItem>
                                );
                            } else {
                                return (
                                    <MUIMenuItem key={idx} value={r.id}>
                                        {r.description}
                                    </MUIMenuItem>
                                );
                            }
                        } else {
                            return null;
                        }
                    }) : null}
                </MUITextField>
            </div>
        );
    }

    renderStatusSection() {
        let collapseID = this.state.collapseID,
            eOrder = this.state.eOrder;

        return (
            <div>
                <MDBCollapseHeader
                    className={"patientStatusHeaders"}
                    tagClassName="d-flex justify-content-between"
                    onClick={() => this.toggleCollapse("collapse1")}
                >
                    <span style={{fontSize: "1em"}}>Type and Status</span>
                    <MDBIcon
                        icon={collapseID === "collapse1" ? "angle-up" : "angle-down"}
                    />
                </MDBCollapseHeader>

                <MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol size={"2"}>
                                <MDBInput
                                    label={"Priority"}
                                    value={eOrder.activity.priority === 1}
                                    checked={eOrder.activity.priority === 1}
                                    data-tip={"Priority"}
                                    type="checkbox"
                                    onChange={(e) =>
                                        this.setAndUpdate("priority", e.target.checked ? 1 : 0)
                                    }
                                    filled
                                    id="priorityCheckbox"
                                />
                            </MDBCol>
                        </MDBRow>
                        {this.renderOrderTypeDropdown()}
                        {this.renderOrderStatusDropdown()}
                        {this.renderOrderReasonDropdown()}
                    </MDBCardBody>
                </MDBCollapse>
            </div>
        );
    }

    //endregion

    //region internal details

    filterReps() {
        const { salesReps } = this.context;
        let eOrder = this.state.eOrder;
        //let reps = this.state.salesReps || salesReps;

        if (salesReps && salesReps.length > 0) {
            let copy = salesReps.filter(x => {
                if (x.userprofileLocations && x.userprofileLocations.length > 0 &&
                    x.userprofileLocations.filter(y => y.locationsId === eOrder.salesLocationId).length > 0) {
                    return x;
                }
                return null;
            });

            let idex = salesReps.find((rep) => {
                return rep.id === eOrder.activity.owner.id || rep.id === eOrder.activity.ownerId;
            });

            copy = copy.filter((c) => c.active !== false);

            if (idex && !copy.includes(idex)) {
                copy.push(idex);
            }

            if (!idex) {
                copy.push(eOrder.activity.owner);
                idex = eOrder.activity.owner;
            }

            this.setState({
                filteredReps: copy.sort((a,b) => {
                    if (a.firstname === b.firstname) {
                        return a.lastname.localeCompare(b.lastname);
                    } else {
                        return a.firstname.localeCompare(b.firstname);
                    }
                }),
                selectedRep: idex,
                salesReps: salesReps,
            });
        }
        else {

            this.setState({
                filteredReps: [eOrder.activity.owner],
                selectedRep: eOrder.activity.owner,
                //salesReps: salesReps,
            });
        }
    }

    renderSalesRepDropdown() {
        const { salesReps } = this.context;
        let copy = this.state.filteredReps || salesReps,
            idex = this.state.selectedRep || this.state.eOrder.activity.owner;
        //const { salesReps } = this.context;
        //let eOrder = this.state.eOrder;
        //let reps = this.state.salesReps || salesReps;

        //let copy = reps.filter(x => {
        //    if (x.userprofileLocations && x.userprofileLocations.length > 0) {
        //        if (eOrder.salesLocationId) {
        //            return x.userprofileLocations.find(y => y.locationsId === eOrder.salesLocationId) != null;
        //        }
        //    }
        //    return null;
        //});
        //let idex = reps.find((rep) => {
        //    return rep.id === eOrder.activity.owner.id || rep.id === eOrder.activity.ownerId;
        //});

        //if (idex && !copy.includes(idex)) {
        //    copy.push(idex);
        //}

        //if (!idex) {
        //    copy.push(eOrder.activity.owner);
        //    idex = eOrder.activity.owner;
        //}


        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="Sales-Rep"
                    select
                    size={"small"}
                    fullWidth
                    label="Sales Rep"
                    value={idex || ""}
                    onChange={(e) => this.changeSalesRep(e.target.value)}
                    variant="outlined"
                >
                    {copy.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {(option.firstname && option.lastname ? option.firstname + " " + option.lastname : option.username)}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }

    renderATPDropdown() {
        const { allAtp } = this.context;
        let eOrder = this.state.eOrder,
            atp = eOrder.atp || [];

        let c = allAtp.sort((a, b) => {
            if (a.firstname && a.lastname && b.firstname && b.lastname)
                return a.firstname.trim() + " " + a.lastname.trim() > b.firstname.trim() + " " + b.lastname.trim() ? 1 : -1;
            else
                return 1;
        });
        if(c.filter(x => x.id === atp.id).length === 0){
            c.push(atp);
        }

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="ATP"
                    select
                    size={"small"}
                    fullWidth
                    label="ATP"
                    value={eOrder.atpId|| ""}
                    onChange={(e) => this.setAndUpdate("atpId", e.target.value)}
                    variant="outlined"
                >
                    {c.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option.id}>
                                {option.firstname + " " + option.lastname}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }

    renderAccountDropdown() {
        let eOrder = this.state.eOrder,
            currSalesRepAccounts = this.state.currSalesRepAccounts,
            currAccount = eOrder.activity.account,
            arr = [currAccount],
            index = eOrder.activity.account;

        currSalesRepAccounts.forEach((acc) => {
            let o = {
                name: acc.name,
                id: acc.id,
            };

            if (o.id === currAccount.id) {
                index = o;
            }

            arr.push(o);
        });

        arr.sort((a, b) => {
            return a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1;
        });

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="Rep-Accounts"
                    select
                    size={"small"}
                    fullWidth
                    label="Account"
                    value={index ? index : currAccount}
                    onChange={(e) => {
                        // this.changeByRef("account", e.target.value)
                        let o = this.state.eOrder;
                        o.activity.account = e.target.value;
                        o.activity.accountId = e.target.value.id;
                        this.setState({ eOrder: o });
                    }}
                    variant="outlined"
                >
                    {arr.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }

    renderSalesLocationDropdown() {
        const { internalLocations } = this.context;
        let eOrder = this.state.eOrder;

        let index = internalLocations.find((loc) => {
            if (eOrder.salesLocation) {
                return loc.id === eOrder.salesLocation.id;
            } else if (eOrder.salesLocationId) {
                return loc.id === eOrder.salesLocationId;
            } else {
                return null;
            }
        });

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="Sales-Location"
                    select
                    size={"small"}
                    fullWidth
                    label="Sales Location"
                    value={index ? index : ""}
                    onChange={(e) => {
                        this.changeByRef("salesLocation", e.target.value);
                        this.setAndUpdate("salesLocationId", e.target.value.id);
                        //this.getSalesRepsByLocation();
                        this.filterReps();
                    }}
                    variant="outlined"
                >
                    {internalLocations.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }

    renderServiceLocationDropdown() {
        const { internalLocations } = this.context;
        let eOrder = this.state.eOrder;

        let index = internalLocations.find((loc) => {
            if (eOrder.serviceLocation) {
                return loc.id === eOrder.serviceLocation.id;
            } else if (eOrder.serviceLocationId) {
                return loc.id === eOrder.serviceLocationId;
            } else {
                return null;
            }
        });

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="Service-Location"
                    select
                    size={"small"}
                    fullWidth
                    label="Service Location"
                    value={index ? index : ""}
                    onChange={(e) => {
                        this.changeByRef("serviceLocation", e.target.value);
                        this.setAndUpdate("serviceLocationId", e.target.value.id);
                    }}
                    variant="outlined"
                >
                    {internalLocations.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }

    renderSeatingClinicDropdown() {
        let t = this;
        const {allLocations} = this.context;

        let fLoc = allLocations.filter((loc) => {
            return loc.type === 1;
        });

        return (
            <Dropdown dropright className={"idDropdown idDropdownClinic"}>
                <DropdownToggle className={"idDropdownToggle"} nav>
                    Seating Clinic
                    <span style={{float: "right"}}>
                        {this.state.eOrder.seatingClinicLocation
                            ? this.state.eOrder.seatingClinicLocation.name
                            : "No Seating Clinic"}
                        &nbsp;
                        <Fa icon={"angle-down"}/>
                    </span>
                </DropdownToggle>
                <DropdownMenu color={"ins"}>
                    {fLoc.map((loc, index) => {
                        return (
                            <DropdownItem
                                onClick={t.changeSeatingClinic.bind(t, loc)}
                                active={
                                    this.state.eOrder.seatingClinicLocation
                                        ? this.state.eOrder.seatingClinicLocation.name ===
                                        loc.name
                                        : false
                                }
                                key={index}
                            >
                                {loc.name}{" "}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }

    renderTherapistDropdown() {
        let t = this,
            eOrder = this.state.eOrder,
            disabled = true;
        const {therapists} = this.context;

        let tps = therapists;

        if (eOrder.seatingClinicLocation) {
            disabled = false;
            tps = [];

            let clinic = {};

            therapists.map((ts) => {
                if (ts.location && ts.location.length > 0) {
                    clinic = ts.location.find((loc) => {
                        return loc.type === 1;
                    });
                }

                if (clinic && clinic.id === eOrder.seatingClinicLocation.id) {
                    return tps.push({
                        id: ts.id,
                        name: ts.username,
                    });
                }
                return null;
            });
        }

        return (
            <Dropdown
                dropright
                disabled={disabled}
                className={"idDropdown idDropdownClinic"}
            >
                <DropdownToggle className={"idDropdownToggle"} nav>
                    Therapist
                    <span style={{float: "right"}}>
                        {this.state.eOrder.therapist
                            ? this.state.eOrder.therapist.name
                            : "No Therapist"}
                        &nbsp;
                        <Fa icon={"angle-down"}/>
                    </span>
                </DropdownToggle>
                <DropdownMenu color={"ins"}>
                    {tps.map((thera, index) => {
                        return (
                            <DropdownItem
                                onClick={t.changeTherapist.bind(t, thera)}
                                active={
                                    this.state.eOrder.therapist
                                        ? this.state.eOrder.therapist.name === thera.name
                                        : false
                                }
                                key={index}
                            >
                                {thera.name}{" "}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }

    renderMarketingCampaignDropdown() {
        let eOrder = this.state.eOrder;
    
        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="Marketing-campaign"
                    select
                    size={"small"}
                    fullWidth
                    label="Marketing Campaign"
                    value={eOrder.marketingCampaignId}
                    onChange={(e) =>
                        this.setAndUpdate("marketingCampaignId", e.target.value)
                    }
                    variant="outlined"
                >
                    {Object.values(this.context.marketingCampaignsIndexed).map((option, idx) => {
                        if (option.isActive || (!option.isActive && eOrder.marketingCampaignId === option.id)) {
                            return (
                                <MUIMenuItem key={idx} value={option.id}>
                                    {option.name}
                                </MUIMenuItem>
                            );
                        } else {
                            return null;
                        }
                    })}
                </MUITextField>
            </div>
        );
    }

    renderRepSection() {
        let collapseID = this.state.collapseID;

        return (
            <div>
                <MDBCollapseHeader
                    className={"patientStatusHeaders"}
                    tagClassName="d-flex justify-content-between"
                    onClick={() => this.toggleCollapse("collapse2")}
                >
                    <span style={{fontSize: "1em"}}>Sales Information</span>
                    <MDBIcon
                        icon={collapseID === "collapse2" ? "angle-up" : "angle-down"}
                    />
                </MDBCollapseHeader>
                <MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
                    <MDBCardBody>
                        {this.renderSalesRepDropdown()}
                        {this.renderATPDropdown()}
                        {this.renderAccountDropdown()}
                        {this.renderSalesLocationDropdown()}
                        {this.renderServiceLocationDropdown()}
                        {this.renderMarketingCampaignDropdown()}
                    </MDBCardBody>
                </MDBCollapse>
            </div>
        );
    }

    //endregion

    renderTechnicianDropdown() {
        let eOrder = this.state.eOrder;
        const {techs} = this.context;

        if (techs && techs.length > 0) {
            let index = techs.find((rep) => {
                if (eOrder.technician) {
                    return rep.id === eOrder.technician.id;
                } else {
                    return null;
                }
            });
            var resp = techs.sort((a, b) => {
                if (a.firstname && a.lastname && b.firstname && b.lastname)
                    return a.firstname.trim() + " " + a.lastname.trim() > b.firstname.trim() + " " + b.lastname.trim() ? 1 : -1;
                else
                    return 1;
            });

            return (
                <div className={"uiOutlines"}>
                    <MUITextField
                        id="Technicians"
                        disabled={
                            eOrder.status !== 2 && eOrder.status !== 3
                        }
                        select
                        size={"small"}
                        fullWidth
                        label="Technician"
                        value={index ? index : ""}
                        onChange={(e) => {
                            // this.changeByRef("technician", e.target.value)
                            let o = this.state.eOrder;
                            o.technician = e.target.value;
                            o.technicianId = e.target.value ? e.target.value.id : null;
                            this.setState({ eOrder: o });
                        }}
                        variant="outlined"
                    >
                        <MUIMenuItem key={-1} value={null}>
                            {"(Remove Technician)"}
                        </MUIMenuItem>
                        {resp.map((option, idx) => {
                            return (
                                <MUIMenuItem key={idx} value={resp[idx]}>
                                    {option.firstname + " " + option.lastname}
                                </MUIMenuItem>
                            );
                        })}
                    </MUITextField>
                </div>
            );
        }
    }

    renderScheduledDate() {
        let eOrder = this.state.eOrder,
            appr = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        const { currentUser } = this.context;

        let dateDisabled = true;
        if (currentUser.role === "ADMIN") {
            dateDisabled = false;
        } else if (
            eOrder.status === 2 ||
            eOrder.status === 3
        ) {
            dateDisabled = false;
        }

        return (
            <MDBRow>
                <MDBCol size="12">
                    <div className={"uiOutlines"}>
                         <MUIDateTimePicker
                              disabled={dateDisabled}
                              maxDate={
                                  appr && appr.deliverByDate
                                      ? moment(appr.deliverByDate)
                                      : null
                              }
                              label="Scheduled Delivery Date"
                              defaultValue={eOrder.scheduledDeliveryDate ? moment(GlobalFunctions.formatDateTime(eOrder.scheduledDeliveryDate)) : null}
                              slotProps={{
                              field: {
                                  clearable: true,
                              },
                              textField: {
                                  fullWidth: true,
                                  size: "small",
                                  variant: 'outlined',
                                  InputLabelProps: {
                                      shrink: true
                                  },
                              },
                              layout: {
                                  sx: {
                                      ul: {
                                          '::-webkit-scrollbar': {
                                              width: '2px',
                                          },
                                          '::-webkit-scrollbar-thumb:hover': {
                                              backgroundColor: MUIGreyColor[500],
                                          },
                                          '::-webkit-scrollbar-thumb': {
                                              backgroundColor: MUIGreyColor[500],
                                          },
                                          '::-webkit-scrollbar-track': {
                                              backgroundColor: MUIGreyColor[300],
                                          }
                                      }
                                  }
                              }
                          }}
                          onChange={(e) => this.handleDate("scheduledDeliveryDate", e)}
                          views={['year', 'month', 'day', 'hours', 'minutes']}
                        />
                    </div>
                </MDBCol>
            </MDBRow>
        );
    }

    renderNeedsRedelivery() {
        if (this.state.eOrder.needsRedelivery) {
            return (
                <MUIAlert
                    style={{ marginTop: "10px" }}
                    severity="info"
                > Needs Redelivery</MUIAlert>
            );
        }
    }

    renderDeliverByDate() {
        let eOrder = this.state.eOrder,
            appr = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        return (
            <MDBRow>
                <MDBCol size="12">
                    <div className={"uiOutlines"}>
                           <MUIDatePicker
                               label={"Deliver By Date"}
                               value={appr && appr.deliverByDate ? moment(appr.deliverByDate) : null}
                               slotProps={{
                                   field: {
                                       clearable: true,
                                   },
                                   textField: {
                                       fullWidth: true,
                                       size: "small",
                                       variant: 'outlined',
                                       InputLabelProps: {
                                           shrink: true
                                       },
                                   },
                               }}
                               onChange={(date) => this.handleDate("deliverByDate", date)}
                               views={['year', 'month', 'day']}
                           />
                    </div>
                </MDBCol>
            </MDBRow>
        );
    }

    renderSetupDate() {
        let eOrder = this.state.eOrder;

        function renderHomeAssessmentDifferencesIcon() {
            if (eOrder.homeAssessmentIsDifferent) {
                return (
                    <MDBIcon style={{ color: "red", paddingTop: 15 }}
                        icon="home"
                        size={"2x"}
                        data-place={"right"}
                        data-tip={"There are differences in the home assessment forms"}
                        onClick={(e) => {
                            e.preventDefault();
                            return null;
                        }}
                    />
                );
            }
        }

        if (eOrder.status === 3) {
            return (
                <div>
                    <hr/>
                    <MDBRow>
                        <MDBCol size="9">
                            <div className={"uiOutlines"}>
                                <MUIDatePicker
                                     label={"Setup Date"}
                                     value={eOrder.setupDate ? moment(eOrder.setupDate) : null}
                                     slotProps={{
                                         field: {
                                             clearable: true,
                                         },
                                         textField: {
                                             fullWidth: true,
                                             size: "small",
                                             variant: 'outlined',
                                             InputLabelProps: {
                                                 shrink: true
                                             },
                                         },
                                     }}
                                     onChange={(value) => this.handleDate("setupDate", value)}
                                     views={['year', 'month', 'day']}
                                />
                            </div>
                        </MDBCol>
                        <MDBCol>
                            {renderHomeAssessmentDifferencesIcon()}
                        </MDBCol>
                    </MDBRow>
                </div>
            );
        }
    }

    renderCurrentModelSection() {
        let eOrder = this.state.eOrder,
            statusSetup = eOrder.status === 3 || this.state.hasApprovals === false;


        return (
            <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col size={"6"}>
                    <MDBInput
                        label={"Current Model"}
                        outline
                        containerClass={"smallMargin"}
                        // disabled={statusSetup}
                        valueDefault={eOrder.currentModel || ""}
                        onChange={(e) => this.setAndUpdate("currentModel", e.target.value)}
                    />
                </Col>
                <Col size={"6"}>
                    <MDBInput
                        label={"Serial Number"}
                        outline
                        containerClass={"smallMargin"}
                        // disabled={statusSetup}
                        valueDefault={eOrder.serialNumber || ""}
                        onChange={(e) => this.setAndUpdate("serialNumber", e.target.value)}
                    />
                </Col>
                {(eOrder.orderType === "Service" || eOrder.orderType === "Pickup") ?
                    <Col size={"12"}>
                        <MDBInput
                            label={eOrder.orderType === "Pickup" ? "Pickup Reason" : "Service Reason"}
                            type={"textarea"}
                            outline
                            containerClass={"smallMargin"}
                            disabled={statusSetup}
                            valueDefault={eOrder.deliveryReason || ""}
                            onChange={(e) =>
                                this.setAndUpdate("deliveryReason", e.target.value)
                            }
                        />
                    </Col>
                    :
                    null
                }

            </Row>
        );

    }

    renderSetupDeliverySection() {
        let collapseID = this.state.collapseID,
            eOrder = this.state.eOrder,
            ss = false,
            onFile = false,
            t = this;

        if (eOrder.orderActivityApprovals[0] && eOrder.orderActivityApprovals[0].approvals) {
            ss = eOrder.orderActivityApprovals[0].approvals.sameOrSimilar;
            onFile =
                ss === "Change in Condition" ||
                ss === "Damage" ||
                ss === "Identified Same/Sim on File" ||
                ss === "Identified Patient in SNF";
        }

        function renderSSSection() {
            if (onFile === true) {
                return (
                    <MDBCol size="12">
                        <MDBInput
                            type={"checkbox"}
                            value={t.state.ssAcknowledge}
                            id={"sameSimCheckbox"}
                            onChange={(e) =>
                                t.setState({
                                    ssAcknowledge: e.target.checked,
                                })
                            }
                            label={"I acknowledge there is a same or similar on file."}
                        />
                    </MDBCol>
                );
            }
        }

        return (
            <div>
                <MDBCollapseHeader
                    className={"patientStatusHeaders"}
                    tagClassName="d-flex justify-content-between"
                    onClick={() => this.toggleCollapse("collapse3")}
                >
                    <span style={{fontSize: "1em"}}>Delivery Information</span>
                    <MDBIcon
                        icon={collapseID === "collapse3" ? "angle-up" : "angle-down"}
                    />
                </MDBCollapseHeader>
                <MDBCollapse id="collapse3" isOpen={this.state.collapseID}>
                    <MDBCardBody>
                            {this.renderCurrentModelSection()}
                            <MDBInput
                                outline
                                type={"textarea"}
                                label={"Delivery Notes"}
                                style={{paddingTop: 8}}
                                valueDefault={eOrder.deliveryNotes}
                                onChange={(e) =>
                                    this.setAndUpdate("deliveryNotes", e.target.value)
                                }
                            />
                            <MDBInput
                                containerClass={"medMargin"}
                                outline
                                disabled={true}
                                value={eOrder.expectedShipDate || "Not Specified"}
                                label={"Expected Ship Date"}
                            />
                            {this.renderDeliverByDate()}
                            {this.renderScheduledDate()}
                            {this.renderNeedsRedelivery()}
                            {this.renderTechnicianDropdown()}
                            {renderSSSection()}
                            <MDBRow style={{justifyContent: "center"}}>
                                <MDBCol size={"12"}>
                                    <MDBBtn
                                        disabled={
                                            (onFile === true && !this.state.ssAcknowledge) ||
                                            !eOrder.scheduledDeliveryDate ||
                                            !eOrder.technician
                                        }
                                        outline
                                        onClick={() => this.confirmScheduleDelivery()}
                                    >
                                        Confirm, Save, and Send SMS
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                            {this.renderSetupDate()}
                    </MDBCardBody>
                </MDBCollapse>
            </div>
        );
    }

    renderPaymentProcessedSection() {
        let eOrder = this.state.eOrder,
            appr = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null,
            t = this;

        function renderButton() {
            if (!appr.paymentProcessedBy) {
                return (
                    <MDBCol size={"12"}>
                        <Button
                            color={"secondary"}
                            outline
                            disabled={appr ? appr.paymentProcessedBy : false}
                            onClick={() => t.togglePaymentModalPopup()}
                        >
                            {" "}
                            Confirm Payment Processed
                        </Button>
                    </MDBCol>
                );
            }
        }

        function renderProcessed() {
            if (appr && appr.paymentProcessedBy) {
                return (
                    <MDBCol size={"12"}>
                        <MDBBtn color={"secondary"} outline>
                            <MDBRow>Processed By: {appr && appr.paymentProcessedBy ? appr.paymentProcessedBy.firstname + " " + appr.paymentProcessedBy.lastname : ""}</MDBRow>

                            <MDBRow>On: {appr && appr.paymentProcessedOn ? GlobalFunctions.formatDateTime(appr.paymentProcessedOn) : ""}</MDBRow>
                        </MDBBtn>
                    </MDBCol>
                );
            }
        }

        if (appr && appr.patientResponsibility && appr.patientResponsibility > 0) {
            return (
                <MDBRow>
                    {renderButton()}
                    {renderProcessed()}
                </MDBRow>
            );
        }
    }

    renderPaymentProcessedModal() {
        let eOrder = this.state.eOrder;

        return (
            <Modal
                centered
                isOpen={this.state.paymentModalOpen}
                toggle={() => this.togglePaymentModalPopup()}
            >
                <ModalBody>
                    <ReactTooltip/>
                    <CardHeader
                        className="form-header text-center"
                        style={{
                            backgroundColor: "#1e88e5",
                            margin: "-40px 30px 10px 30px",
                            fontSize: 24,
                        }}
                    >
                        <h4>Payment Plan Processed</h4>
                        <h4>{eOrder.patient.firstName + " " + eOrder.patient.lastName}</h4>
                    </CardHeader>

                    <div align="left">
                        <MDBInput
                            key={eOrder.id}
                            onChange={this.paymentPlanApprovedChange.bind(this)}
                            value={this.state.paymentPlanApproved}
                            label="I verify that a payment/ payment plan has been put in place for this patient."
                            type="checkbox"
                            id="approvePayment"
                        />
                    </div>
                </ModalBody>
                <MDBRow style={{justifyContent: "flex-end"}}>
                    <MDBCol size={"4"}>
                        <MDBBtn
                            size={"sm"}
                            floating
                            data-tip={"Cancel"}
                            color="danger"
                            onClick={() => this.togglePaymentModalPopup()}
                        >
                            <MDBIcon icon="times"/>
                        </MDBBtn>

                        <MDBBtn
                            size={"sm"}
                            floating
                            data-tip={"Approve"}
                            disabled={!this.state.paymentPlanApproved}
                            color="success"
                            onClick={() => this.confirmPaymentPlanProcessed()}
                        >
                            <MDBIcon icon="check"/>
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </Modal>
        );
    }

    renderPaymentInfoSection() {
        let collapseID = this.state.collapseID,
            eOrder = this.state.eOrder,
            statusSetup = eOrder.status === 3 || this.state.hasApprovals === false,
            appr = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        return (
            <div>
                <MDBCollapseHeader
                    className={"patientStatusHeaders"}
                    tagClassName="d-flex justify-content-between"
                    onClick={() => this.toggleCollapse("collapse4")}
                >
                    <span style={{fontSize: "1em"}}>Payment Information</span>
                    <MDBIcon
                        icon={collapseID === "collapse4" ? "angle-up" : "angle-down"}
                    />
                </MDBCollapseHeader>
                <MDBCollapse id="collapse4" isOpen={this.state.collapseID}>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol
                                size={"6"}
                                className={"insuranceSelects insuranceSelectsText"}
                            >
                                <MDBInput
                                    type={"number"}
                                    outline
                                    style={{paddingTop: 8}}
                                    label={"Deductible"}
                                    disabled={statusSetup}
                                    valueDefault={appr ? appr.deductible : ""}
                                    onChange={(evt) =>
                                        this.approvalAndUpdate("deductible", evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null)
                                    }
                                />
                            </MDBCol>

                            <MDBCol
                                size={"6"}
                                className={"insuranceSelects insuranceSelectsText"}
                            >
                                <MDBInput
                                    type={"number"}
                                    outline
                                    style={{paddingTop: 8}}
                                    label={"Co-Insurance"}
                                    disabled={statusSetup}
                                    valueDefault={appr ? appr.coInsurance : ""}
                                    onChange={(evt) =>
                                        this.approvalAndUpdate("coInsurance", evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null)
                                    }
                                />
                            </MDBCol>

                            <MDBCol
                                size={"6"}
                                className={"insuranceSelects insuranceSelectsText"}
                            >
                                <MDBInput
                                    type={"number"}
                                    outline
                                    style={{paddingTop: 8}}
                                    label={"Pt. Resp"}
                                    disabled={statusSetup}
                                    valueDefault={appr ? appr.patientResponsibility : ""}
                                    onChange={(evt) =>
                                        this.approvalAndUpdate(
                                            "patientResponsibility",
                                            evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null
                                        )
                                    }
                                />
                            </MDBCol>

                            <MDBCol
                                size={"6"}
                                className={"insuranceSelects insuranceSelectsText"}
                            >
                                <MDBInput
                                    type={"number"}
                                    outline
                                    style={{paddingTop: 8}}
                                    label={"State Sales Tax"}
                                    disabled={true}
                                    value={appr ? appr.stateSalesTax : ""}
                                    onChange={(evt) =>
                                        this.approvalAndUpdate("stateSalesTax", evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null)
                                    }
                                />
                            </MDBCol>

                            <MDBCol
                                size={"12"}
                                className={"insuranceSelects insuranceSelectsText"}
                            >
                                <MDBInput
                                    type={"number"}
                                    outline
                                    style={{paddingTop: 8}}
                                    label={"Payment On Delivery"}
                                    disabled={statusSetup}
                                    valueDefault={appr ? appr.paymentOnDelivery : ""}
                                    onChange={(evt) =>
                                        this.approvalAndUpdate(
                                            "paymentOnDelivery",
                                            evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null
                                        )
                                    }
                                />
                            </MDBCol>

                            <MDBCol
                                size={"12"}
                                className={"insuranceSelects insuranceSelectsText"}
                            >
                                <MDBInput
                                    type={"number"}
                                    outline
                                    style={{ paddingTop: 8 }}
                                    label={"Payment Collected"}
                                    disabled={statusSetup}
                                    valueDefault={appr ? appr.paymentCollected : ""}
                                    onChange={(evt) =>
                                        this.approvalAndUpdate(
                                            "paymentCollected",
                                            evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null
                                        )
                                    }
                                />
                            </MDBCol>

                            <MDBCol size={"12"}>
                                <MDBBtn
                                    disabled={this.state.customerSuccessDisabled}
                                    outline
                                    onClick={() => {
                                        Sentry.captureMessage(
                                            `Customer Care Rep button (from payment information) clicked`,
                                            {
                                                fingerprint: ['orderPageButtonActivity']
                                            }
                                        );
                                        this.confirmCustomerSuccessDate();
                                    }}
                                >
                                    Customer Care Rep Called Patient{" "}
                                    {appr && appr.customerSuccessDate
                                        ? "on " + appr.customerSuccessDate
                                        : ""}
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>

                        {this.renderPaymentProcessedSection()}
                    </MDBCardBody>
                </MDBCollapse>
            </div>
        );
    }

    renderFrequencyDropdown() {
        let r = this.state.frequencyRecord,
            frequencies = this.state.frequencies;

        let index = frequencies.find((cp) => {
            if (r.frequency) {
                return cp === r.frequency;
            } else {
                return null;
            }
        });

        return (
            <div className={"uiOutlines"}>
                <MUITextField
                    id="outlined-select-status"
                    select
                    size={"small"}
                    fullWidth
                    label="Frequency"
                    value={index ? index : ""}
                    onChange={(e) => {
                        let nr = this.state.frequencyRecord;
                        nr.frequency = e.target.value;
                        this.setState({frequencyRecord: nr});
                    }}
                    variant="outlined"
                >
                    {frequencies.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </div>
        );
    }

    renderFrequencyDate() {
        let r = this.state.frequencyRecord;

        return (
            <MDBRow>
                <MDBCol size="12">
                    <div className={"uiOutlines frequency"}>
                          <MUIDatePicker
                               disablePast={true}
                               label={"Next Frequency Date"}
                               value={r.nextDate ? moment(r.nextDate) : null}
                               slotProps={{
                                   field: {
                                       clearable: true,
                                   },
                                   textField: {
                                       fullWidth: true,
                                       size: "small",
                                       variant: 'outlined',
                                       InputLabelProps: {
                                           shrink: true
                                       },
                                   },
                               }}
                               onChange={(date) => {
                                  let nr = this.state.frequencyRecord;
                                  nr.nextDate = date;
                                  this.setState({ frequencyRecord: nr });
                              }}
                               views={['year', 'month', 'day']}
                          />
                    </div>
                </MDBCol>
            </MDBRow>
        );
    }

    renderHelpButton() {
        return (
            <MDBCol size={5}>
                <MDBPopover
                    placement="bottom"
                    popover
                    clickable
                    id="popper1"
                >
                    <MDBBtn block size={"sm"} color={"secondary"}> Help ? </MDBBtn>
                    <div className="popoverBody">
                        <MDBPopoverHeader className={"popoverHeader"}>Frequency</MDBPopoverHeader>
                        <MDBPopoverBody>
                            Set the date you want the first recurring order to be created on,
                            and the system will automatically create an order on that date and continue
                            the specified frequency. Set the frequency to "None / Disabled" to prevent future
                            orders from being created
                        </MDBPopoverBody>
                    </div>
                </MDBPopover>
            </MDBCol>
        );
    }

    renderSaveUpdateButton() {
        let text = "Set Frequency",
            click = () => this.createOrderFrequency();

        if (this.state.frequencyRecord.id != null) {
            text = "Update Frequency";
            click = () => this.updateOrderFrequency();
        }

        return (
            <MDBCol size={7}>
                <MDBBtn block size={"sm"} color={"default"} onClick={click}> {text} </MDBBtn>
            </MDBCol>
        );
    }

    renderRecurringInformationSection() {
        let collapseID = this.state.collapseID,
            eOrder = this.state.eOrder;

        if (eOrder.orderType !== "Re-Supply") {
            return;
        }

        return (
            <div>
                <MDBCollapseHeader
                    className={"patientStatusHeaders"}
                    tagClassName="d-flex justify-content-between"
                    onClick={() => this.toggleCollapse("collapse5")}
                >
                    <span style={{fontSize: "1em"}}>Recurring Order Info</span>
                    <MDBIcon
                        icon={collapseID === "collapse5" ? "angle-up" : "angle-down"}
                    />

                </MDBCollapseHeader>
                <MDBCollapse id="collapse5" isOpen={this.state.collapseID}>
                    <MDBCardBody>

                            {this.renderFrequencyDropdown()}
                            {this.renderFrequencyDate()}

                            <MDBRow style={{paddingTop: 8}}>
                                {this.renderHelpButton()}
                                {this.renderSaveUpdateButton()}
                            </MDBRow>

                    </MDBCardBody>
                </MDBCollapse>
            </div>
        );
    }

    render() {
        return (
            <MDBCard>
                {this.renderPaymentProcessedModal()}
                {this.renderStatusSection()}
                {this.renderRepSection()}
                {this.renderPaymentInfoSection()}
                {this.renderSetupDeliverySection()}
                {this.renderRecurringInformationSection()}
            </MDBCard>
        );
    }
}
