import React from "react";
import Card from "../../../Card";
import EmptyState from "../../../EmptyState";
import MUIButton from "@mui/material/Button";
import MUIPowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MUITextField from "@mui/material/TextField";
import MUIAutocomplete from "@mui/material/Autocomplete";
import MUIStack from "@mui/material/Stack";

export default class CardAdminQueueFilterBrightreeInvoiceFollowupDate extends Card {
    constructor(props) {
        super(props);

        this.state = {
            enabled: !!props.queue.filter?.["invoice.followup_date"]
        }
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleChange = (key, value) => {
            const filter = this.props.queue.filter;

            filter["invoice.followup_date"].properties[key] = value;

            this.props.onChange({
                filter: filter
            });
        };

        const options = {
            operators: [
                {
                    value: "<",
                    label: "Followup Date is before"
                },
                {
                    value: ">",
                    label: "Followup Date is after"
                },
            ],
            units: [
                {
                    value: "hour",
                    label: "hours ago"
                },
                {
                    value: "day",
                    label: "days ago"
                },
                {
                    value: "week",
                    label: "weeks ago"
                },
                {
                    value: "month",
                    label: "months ago"
                },
                {
                    value: "year",
                    label: "years ago"
                },
            ],
        }

        if (this.state.enabled) {
            return (
                <MUIStack direction="row" spacing={1}>
                    <MUIAutocomplete
                        options={options.operators}
                        getOptionLabel={option => option.label}
                        value={options.operators.find(operator => operator.value === this.props.queue?.filter["invoice.followup_date"].properties.operator) || null}
                        onChange={(e, option) => handleChange("operator", option?.value || null) }
                        renderInput={(params) => {
                            return (
                                <MUITextField
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    label="Operator"
                                    variant="standard"
                                    required={true}
                                    sx={{ width: 225 }}
                                />
                            );
                        }}
                    />
                    <MUITextField
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        label="Value"
                        variant="standard"
                        required={true}
                        sx={{ width: 50 }}
                        inputProps={{
                            maxLength: 3,
                            inputMode: "numeric"
                        }}
                        value={this.props.queue.filter["invoice.followup_date"].properties.value}
                        onChange={(e) => handleChange("value", +e.target.value) }
                    />
                    <MUIAutocomplete
                        options={options.units}
                        getOptionLabel={option => option.label}
                        value={options.units.find(unit => unit.value === this.props.queue?.filter["invoice.followup_date"].properties.unit) || null}
                        onChange={(e, option) => handleChange("unit", option?.value || null) }
                        renderInput={(params) => {
                            return (
                                <MUITextField
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    label="Unit"
                                    variant="standard"
                                    required={true}
                                    sx={{ width: 150 }}
                                />
                            );
                        }}
                    />
                </MUIStack>
            );
        } else {
            return (
                <EmptyState
                    line1="Not Enabled"
                    line2="Showing all Followup Dates"
                />
            );
        }
    }

    /**
     * Renders add Term button
     */
    getActions() {
        const handleEnableDisable = () => {
            if (this.state.enabled) {
                const filter = this.props.queue.filter;
                delete filter["invoice.followup_date"];

                // Disable and delete
                this.setState({
                    enabled: false
                });
            } else {
                // Enable and initialize
                const filter = this.props.queue.filter || {};
                filter["invoice.followup_date"] = {
                    type: "relativeDate",
                    properties: {
                        operator: null,
                        value: "",
                        unit: null
                    }
                };
                this.props.onChange({
                    filter: filter
                });

                this.setState({
                    enabled: true
                });
            }
        };

        const buttonItems = [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIPowerSettingsNewIcon />}
                onClick={handleEnableDisable}>{ this.state.enabled ? "Disable" : "Enable"}</MUIButton>)
        ];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Filter: Brightree Invoice > Followup Date";
    }
}