import React from "react";
import {
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Fa,
    MDBBtn,
    MDBCol,
    MDBIcon,
    MDBRow,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    toast,
} from "mdbreact";
import LiaisonMeetingService from "../../Security/LiaisonMeetingService/LiaisonMeetingService";
import ReactTooltip from "react-tooltip";
import NoteService from "../../Security/NoteService/noteService";
import PropTypes from "prop-types";
import globalFunctions from "../../Filters/GlobalFunctions";

export default class LiaisonModal extends React.Component {
    constructor(props) {
        super(props);

        this.closeModal = props.closeModal;
        this.setExistingEmpty = props.setExistingEmpty;

        this.state = {
            saving: false,
            resultingLiaisonKeys: props.resultingLiaisonKeys,
            existingMeeting: props.existingMeeting,
            liaisonFullKeys: props.liaisonFullKeys,
            helpModalOpen: false,
            eOrder: props.eOrder,
            selectedMeeting: {}
        };
    }

    static contextTypes = {
        allLiaisons: PropTypes.array,
        currentUser: PropTypes.object,
        timezoneVariation: PropTypes.object,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.helpModalOpen !== prevState.helpModalOpen || nextProps.liaisonFullKeys !== prevState.liaisonFullKeys) {
            return {
                resultingLiaisonKeys: nextProps.resultingLiaisonKeys,
                liaisonFullKeys: nextProps.liaisonFullKeys,
                helpModalOpen: nextProps.helpModalOpen,
                existingMeeting: nextProps.existingMeeting
            };
        } else
            return null;
    }


    createSalesLiaisonMeeting() {
        if (this.state.saving) {
            return;
        }
        this.setState({ saving: true });
        const options = { timeZoneName: "short", hour: "numeric", minute: "2-digit", year: "numeric", month: "numeric", day: "numeric" };
        const optionsEST = { timeZoneName: "short", hour: "numeric", minute: "2-digit", year: "numeric", month: "numeric", day: "numeric", timeZone: "America/Indianapolis" };

        const { currentUser } = this.context;
        let selectedMeeting = this.state.selectedMeeting,
            o = this.state.eOrder;

        if (!selectedMeeting.utcTime) {
            toast.error('Please select a time');
            this.setState({ saving: false });
            return;
        }


        let obj = {
            orderId: o.id,
            status: o.status,
            reason: o.orderStatusReason ? o.orderStatusReason.description : '',
            salesRep: {
                name: currentUser.firstname + " " + currentUser.lastname,
                id: currentUser.id
            },
            salesRepId: currentUser.id,
            salesLiaison: {
                name: selectedMeeting.liaisonName,
                id: selectedMeeting.liaisonId
            },
            salesLiaisonId: selectedMeeting.liaisonId,
            scheduledDate: selectedMeeting.utcTime,
            complete: false,
            closedOn: null,
            meetingNotes: '',
            meetingReason: "",
        };

        let text = "A meeting has been scheduled with " + selectedMeeting.liaisonName + " on " + new Date(this.state.selectedMeeting.utcTime).toLocaleString("en-US", options)
            + " which is " + new Date(this.state.selectedMeeting.utcTime).toLocaleString("en-US", optionsEST);

        LiaisonMeetingService.createMeeting(obj)
            .then(r => {
                if (r && r.id) {
                    toast.success("Meeting Created Successfully!");

                    this.setState({
                        selectedMeeting: {},
                    });

                    this.addMeetingNote(currentUser, selectedMeeting.liaisonId, text);

                    this.setState({ saving: false });

                    this.closeModal();
                } else {
                    toast.error("An issue occurred trying to create the meeting.");
                }
            }).catch(e => {
                console.log(e);
                toast.error("There was an issue creating the meeting. Try again later.");
            });

    }

    renderHelpModal() {
        let st = this.state;

        return (
            <Modal centered
                isOpen={st.helpModalOpen}
                toggle={() => {
                    return;
                }}>
                <ModalHeader style={{ textAlign: 'center' }}>
                    Request Sales Paperwork Help
                </ModalHeader>
                <ModalBody style={{ height: 100 }}>
                    {!this.state.existingMeeting ? this.renderHelpModalCreateSection() : this.renderHelpModalCancelSection()}
                </ModalBody>
                <ModalFooter>
                    <span
                        className={"ptBtn cancelBtn"}
                        data-tip={"Cancel"}
                        onClick={this.closeModal}>
                        <Fa size={'2x'} icon={'times'}> </Fa>
                    </span>
                    {!this.state.existingMeeting ?
                        <MDBBtn
                            size={"sm"}
                            floating
                            disabled={this.state.saving}
                            data-tip={"Submit Request"}
                            onClick={() => this.createSalesLiaisonMeeting()}>
                            <Fa size={'2x'} icon={'check'}> </Fa>
                        </MDBBtn>
                        : ''}
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderHelpModalCancelSection() {
        let m = this.state.existingMeeting;

        return (
            <MDBRow>
                <Container>
                    A meeting already exists at {globalFunctions.formatDateTime(m.scheduledDate)} with {m.salesLiaison && m.salesLiaison.name ? m.salesLiaison.name : m.salesLiaison}.

                    <MDBBtn
                        style={{ textTransform: 'none' }}
                        color="mdb-color"
                        floating size='sm'
                        data-tip={"Cancel Meeting"}
                        onClick={() => this.cancelMeeting()}>
                        <MDBIcon icon="times" />
                    </MDBBtn>
                </Container>

            </MDBRow>
        );
    }

    cancelMeeting() {
        const { allLiaisons, currentUser } = this.context;

        let m = Object.assign(this.state.existingMeeting);

        //this will get the liaison;
        let liaison = allLiaisons.find(l => {
            return l.firstname + " " + l.lastname === m.salesLiaison;
        });
        if (!liaison) {
            liaison = m.liaison;
        }
        console.log(m);
        m.status = this.state.eOrder.status;
        m.salesRep = { name: currentUser.username, id: currentUser.id };
        m.salesRepId = currentUser.id;
        m.salesLiaison = { name: liaison.username, id: parseInt(liaison.id) };
        m.salesLiaisonId = parseInt(liaison.id);
        m.closedOn = new Date();
        m.scheduledDate = new Date(m.scheduledDate);
        m.complete = true;
        m.orderId = parseInt(m.orderId);
        m.meetingReason = "Closed By Sales Rep";
        m.meetingNotes = m.meetingNotes || "";

        LiaisonMeetingService.updateMeeting(m)
            .then(r => {
                if (r) {
                    this.closeModal();
                    this.setExistingEmpty();

                    toast.success("Meeting Cancelled");
                }
            }).catch(e => {
                console.log(e);
                toast.error("There was an error cancelling the meeting. Try again later.");
            });
    }

    handleSelectMeeting(meeting) {
        let selectedMeeting = JSON.parse(meeting);
        this.setState({ selectedMeeting: selectedMeeting });
    }

    renderHelpModalCreateSection() {
        const options = { timeZoneName: "short", hour: "numeric", minute: "2-digit", year: "numeric", month: "numeric", day: "numeric" };
        return (
            <MDBRow>
                <MDBCol size={"12"}>
                    <Dropdown dropright className={"idDropdown idDropdownService"}>
                        <DropdownToggle className={"idDropdownToggle"} nav>
                            Time
                            <span style={{ float: 'right' }}>
                                {this.state.selectedMeeting.utcTime ? new Date(this.state.selectedMeeting.utcTime).toLocaleString("en-US", options) + " - " + this.state.selectedMeeting.liaisonName : ""}
                                <Fa icon={'angle-down'} />
                            </span>
                        </DropdownToggle>
                        {this.state.liaisonFullKeys ? <DropdownMenu >
                            {this.state.liaisonFullKeys.map((entry, index) => {
                                return (
                                    <DropdownItem key={index} value={JSON.stringify(entry)} disabled={entry.scheduled}
                                        onClick={(e) => {
                                            this.handleSelectMeeting(e.target.value);
                                        }}>
                                        {new Date(entry.utcTime).toLocaleString("en-US", options) + " - " + entry.liaisonName + (entry.scheduled ? " (Not Avalible)" : "")}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu> : null}
                    </Dropdown>
                </MDBCol>
            </MDBRow>
        );
    }
    addMeetingNote(user, liaisonId, text) {
        let cb = {id: user.id, name: user.username},
            currentNotes = this.state.allNotes;

        let note = {
            task: true,
            completed: false,
            assignedToId: liaisonId,
            createdAt: globalFunctions.getUTCMoment(),
            createdBy: cb,
            createdById: cb.id,
            account: this.state.eOrder.activity.account,
            accountId: this.state.eOrder.activity.accountId,
            patientName: this.state.eOrder.patientName,
            text: text,
            activityId: this.state.eOrder.id,
        };

        NoteService.createActivityNote(this.state.eOrder.id, note)
            .then(res => {
                //need to set the last note date because otherwise the save overrides it...BC
                let ord = this.state.eOrder;
                ord.activity.lastNoteDate = new Date();

                currentNotes.unshift(res.addNote);
                this.setState({
                    allNotes: currentNotes,
                    eOrder: ord
                });
            }).catch(err => {
                //Maybe send an error to the user?...BC
                console.log(err);
            });
    }

    render() {
        return (
            <div>
                {this.renderHelpModal()}
            </div>
        );
    }
}
