import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import MUIIconButton from '@mui/material/IconButton';
import MUIOpenInNewIcon from '@mui/icons-material/OpenInNew';
import MUITypography from "@mui/material/Typography";
import MUIGrid from "@mui/material/Grid";
import MUIAlert from '@mui/material/Alert';
import MUIAlertTitle from "@mui/material/AlertTitle";
import IssueCategory from "../../Utility/Crud/IssueCategory";
import IssueReason from "../../Utility/Crud/IssueReason";

export default class DialogPatientIssueAlert extends Dialog {
    static contextTypes = {
        issueCategoriesIndexed: PropTypes.object,
        issueReasonsIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
    };

    /**
     * Render header title of dialog
     */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                Alert
            </MUITypography>
        );
    }

    /**
     * Render main content text of dialog
     */
    renderContent() {    
        if (!this.props.patientIssues || this.props.patientIssues.length === 0) {
            return null;
        }

        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return (
            <>
                <MUIGrid container spacing={2}>
                    {this.props.patientIssues.map((patientIssue, index) => {
                        const loggedOn = moment.utc(patientIssue.issueLoggedOn).local();
                        const age = loggedOn.fromNow();
 
                        return (
                            <MUIGrid item lg={12} xs={12} key={index}>
                                <MUIAlert
                                    severity="error"
                                    variant="filled"
                                    action={
                                        <MUIIconButton
                                            color="inherit"
                                            size="small"
                                            onClick={() => this.props.onClick(patientIssue)}
                                            style={patientIssue.collectionsBalance ? {display: 'none'} : {}}
                                        >
                                            <MUIOpenInNewIcon />
                                        </MUIIconButton>
                                    }
                                >
                                    {patientIssue.collectionsBalance ? (
                                         <MUIAlertTitle>{`${patientIssue.description} • ${USDollar.format(patientIssue.collectionsBalance)}`}</MUIAlertTitle>
                                    ) : (
                                        <MUIAlertTitle>{`${IssueCategory.getDisplayName(patientIssue.issueCategory)} • ${IssueReason.getDisplayName(patientIssue.issueReason)} • ${age}`}</MUIAlertTitle>
                                    )}
                                    <MUITypography variant="body2">{patientIssue.complaintDescription}</MUITypography>
                                </MUIAlert>
                            </MUIGrid>
                        )
                    })}
                </MUIGrid>
            </>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "sm";
    }
}
