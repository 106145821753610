import React from "react";
import Card from "../../../Card";
import PropTypes from "prop-types";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUIBox from "@mui/material/Box";
import TileIssueCategory from "../../../Tile/TileIssueCategory";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import DialogSearchIssueCategory from "../../../Dialog/Search/DialogSearchIssueCategory";

export default class CardAdminIssueReasonGeneral extends Card {
    static contextTypes = {
        issueCategoriesIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        const issueCategory = this.props.issueReason ?
            this.context.issueCategoriesIndexed[this.props.issueReason.issueCategoryId] :
            null;

        const handleEditIssueCategory = () => {
            this.setState({ issueCategoryDialogOpen: true });
        };

        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <MUITextField
                            value={this.props.issueReason ? this.props.issueReason.name : ""}
                            autoFocus={true}
                            size="small"
                            label="Name"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 255,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ name: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ name: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.name ? true : false}
                            helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                        />
                    </MUIGrid>
                    <MUIBox width="100%" />
                    <MUIGrid item xs={12} md={6}>
                        <TileIssueCategory
                            required={true}
                            error={!!this.props.validationIssues.issueCategoryId}
                            helperText={this.props.validationIssues.issueCategoryId ? this.props.validationIssues.issueCategoryId : null}
                            issueCategory={issueCategory}
                            label="Issue Category"
                            iconButtons={[
                                (<MUIIconButton onClick={handleEditIssueCategory}><MUIEditIcon /></MUIIconButton>),
                            ]}
                        />
                    </MUIGrid>
                </MUIGrid>

                <DialogSearchIssueCategory
                    open={this.state.issueCategoryDialogOpen}
                    onClose={() => this.setState({ issueCategoryDialogOpen: false })}
                    onSelect={(IssueCategory) => {
                        this.props.onChange({ issueCategoryId: IssueCategory.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}