import React from "react";
import Card from "../../Card";
import MUIGrid from "@mui/material/Grid";
import MUIPersonIcon from '@mui/icons-material/Person';
import MUIPlaceIcon from '@mui/icons-material/Place';
import MUITypography from "@mui/material/Typography";
import moment from "moment";

export default class CardApprovalSummaryPatient extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <MUIGrid container spacing={1}>
                <MUIGrid item xs={1} lg={2} sx={{display: 'flex', alignItems: 'center'}}>
                    <MUIPersonIcon color="primary" fontSize="large" />
                </MUIGrid>
                <MUIGrid item xs={5} lg={9}>
                    <MUITypography variant="subtitle2">{this.props.order.patientName || ""}</MUITypography>
                    <MUITypography variant="body2">{moment(this.props.order.patient.dateOfBirth).format("M/D/YYYY") || "No DoB"}</MUITypography>
                </MUIGrid>
                <MUIGrid item xs={1} lg={2} sx={{display: 'flex', alignItems: 'center'}}>
                    <MUIPlaceIcon color="error" fontSize="large" />
                </MUIGrid>
                <MUIGrid item xs={5} lg={9}>
                    <MUITypography variant="subtitle2">{this.props.order.patient.address}</MUITypography>
                    <MUITypography variant="body2">{`${this.props.order.patient.city || "No City"}, ${this.props.order.patient.state || "No State"} ${this.props.order.patient.zipCode || "No Zip"}`}</MUITypography>
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Patient";
    }

    /**
     * @returns {string} The minHeight of the card.
     */
    getMinHeight() {
        return "100%";
    }
}