import React from "react";
import Card from "../../../Card";
import EmptyState from "../../../EmptyState";
import MUIButton from "@mui/material/Button";
import MUIIconButton from "@mui/material/IconButton";
import MUIAddIcon from "@mui/icons-material/Add";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUIGrid from "@mui/material/Grid";
import DialogSearchMarketingCampaign from "../../../Dialog/Search/DialogSearchMarketingCampaign";
import TileMarketingCampaign from "../../../Tile/TileMarketingCampaign";
import PropTypes from "prop-types";
import MarketingCampaign from "../../../../Utility/Crud/MarketingCampaign";

export default class CardAdminQueueFilterOrderActivityMarketingCampaign extends Card {
    static contextTypes = {
        marketingCampaignsIndexed: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state.marketingCampaignDialogOpen = false;
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleRemove = (marketingCampaign) => {
            const filter = this.props.queue.filter;
            filter["order_activity.marketing_campaign_id"].splice(
                filter["order_activity.marketing_campaign_id"].indexOf(marketingCampaign),
                1
            );

            this.props.onChange({
                "filter": filter
            });
        };

        if (
            this.props.queue &&
            this.props.queue.filter &&
            this.props.queue.filter["order_activity.marketing_campaign_id"] &&
            this.props.queue.filter["order_activity.marketing_campaign_id"].length > 0
        ) {
            return (
                <>
                    <MUIGrid container spacing={2}>
                        {this.props.queue.filter["order_activity.marketing_campaign_id"].map((marketingCampaignId) => {
                            const marketingCampaign = MarketingCampaign.read(
                                this.context.marketingCampaignsIndexed,
                                [{
                                    "key": "id",
                                    "value": marketingCampaignId
                                }]
                            )[0];
                            return (
                                <MUIGrid key={marketingCampaignId} item xs={12} sm={6} md={4}>
                                    <TileMarketingCampaign
                                        marketingCampaign={marketingCampaign}
                                        iconButtons={[(<MUIIconButton onClick={() => handleRemove(marketingCampaignId)}><MUIDeleteIcon /></MUIIconButton>)]}
                                    />
                                </MUIGrid>
                            );
                        })}
                        {this.renderDialog()}
                    </MUIGrid>
                </>
            );
        } else {
            return (
                <>
                    <EmptyState
                        line1="Not Configured"
                        line2="Showing all Marketing Campaigns"
                    />
                    {this.renderDialog()}
                </>
            );
        }
    }

    /**
     * Renders a search dialog of Order Status
     */
    renderDialog() {
        const handleAdd = (marketingCampaign) => {
            const filter = (this.props.queue && this.props.queue.filter) || {};
            if (!filter["order_activity.marketing_campaign_id"]) {
                filter["order_activity.marketing_campaign_id"] = [];
            }
            filter["order_activity.marketing_campaign_id"].push(marketingCampaign.id);
            filter["order_activity.marketing_campaign_id"] = [...new Set(filter["order_activity.marketing_campaign_id"])];

            this.props.onChange({
                "filter": filter
            });
        };

        return (
            <DialogSearchMarketingCampaign
                open={this.state.marketingCampaignDialogOpen}
                onClose={() => this.setState({ marketingCampaignDialogOpen: false })}
                onSelect={handleAdd}
            />
        );
    }

    /**
     * Renders add Order Statu button
     */
    getActions() {
        const handleAdd = () => {
            this.setState({
                marketingCampaignDialogOpen: true,
            });
        };

        const buttonItems = [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIAddIcon />}
                onClick={handleAdd}>Add Marketing Campaign</MUIButton>)
        ];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Filter: Order Activity > Marketing Campaign";
    }
}