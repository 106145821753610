import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import MUIGreenColor from "@mui/material/colors/green";
import AccountType from "../../../../Utility/Crud/AccountType";

export default withRouter(class CardAdminAccountTypeList extends Card {
    static contextTypes = {
        accountTypesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                    { key: "isMarketing", name: "Marketing", type: "boolean", icon: "Check", color: MUIGreenColor[500], width: "140"},
                ]}
                rows={AccountType.sort(Object.values(this.context.accountTypesIndexed))}
                onClick={(accountType) => {
                    this.props.history.push({
                        pathname: "/admin/accountType/" + accountType.id
                    });
                }}
            />
        );
    }
});