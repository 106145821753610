import api from "../api";

class RegionService {

    async getAllRegions() {
        return new Promise((res, reject) => {
            api
                .get("/regions")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateRegion(region) {
        return new Promise((res, reject) => {
            api
                .put("/regions", region)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createRegion(region) {
        return new Promise((res, reject) => {
            api
                .post("/regions", region)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteRegion(id) {
        return new Promise((res, reject) => {
            api
                .delete("/regions/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const regionService = new RegionService();

export default regionService;
