import React from "react";
import MUIRedColor from "@mui/material/colors/red";
import MUITypography from "@mui/material/Typography";
import MUICheckCircle from '@mui/icons-material/CheckCircle';
import * as MUIColors from "@mui/material/colors";
import PropTypes from "prop-types";
import Color from "../Utility/Crud/Color";

export default class ColorPicker extends React.Component {
    static contextTypes = {
        colorsIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            color: null,
        };
    }

    componentDidMount() {
        this.setState({
            color: this.props.department ? this.context.colorsIndexed[this.props.department.colorId] : null,
        });
    }

    /**
     * Renders a color picker.
     */
    render() {
        let colors = Object.values(this.context.colorsIndexed).filter((c) => c.shade === "500");
        // Example of how to get a color dynamically
        // const color = {
        //     'hue': 'red',
        //     'shade': '500'
        // }

        // const mainColors = Color.read([{
        //     key: "shade",
        //     value: "500"
        // }]);
        const handleClick = (color) => {
            this.setState({
                color: color
            });
            if (color) {
                this.props.onChange({ colorId: color.id });
            }
        };

        return (
            <>
                { this.renderLabel() }
                < table width="100%" >
                    <tbody>
                        <tr>
                            { colors.map((color, index) => (
                                <td
                                    id={`colorTile${index}`}
                                    key={ index }
                                    style={ {
                                        backgroundColor: MUIColors[color.hue][color.shade],
                                        height: "32px",
                                        cursor: "pointer",
                                        textAlign: "center",
                                    } }
                                    onClick={ () => handleClick(color) }
                                >
                                    { this.props.department ? (
                                        <MUICheckCircle fontSize="small" sx={ this.state.color === color || this.props.department.colorId === color.id ? { color: Color.getForegroundColor(color) } : { visibility: "hidden" } } />
                                    ) : null }

                                </td>
                            )) }
                        </tr>
                    </tbody>
                </table >
            </>
        );
    }


    /**
     * Renders an optional label.
     */
    renderLabel() {
        if (this.props.label !== undefined) {
            return (
                <MUITypography variant="caption" component="p" color={ this.props.error ? MUIRedColor[700] : "text.secondary" } sx={ { marginTop: '-2px' } }>
                    { this.props.required ? this.props.label + " *" : this.props.label }
                </MUITypography>
            );
        }

        return null;
    }

}