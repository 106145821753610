import * as React from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';
import MUIAppBar from '@mui/material/AppBar';
import MUIBox from '@mui/material/Box';
import MUIToolbar from '@mui/material/Toolbar';
import MUITypography from '@mui/material/Typography'
import MUIIconButton from '@mui/material/IconButton';
import MUIButton from '@mui/material/Button';
import MUIMenu from '@mui/material/Menu';
import MUIMenuIcon from '@mui/icons-material/Menu';
import MUIMenuItem from '@mui/material/MenuItem';
import MUILink from '@mui/material/Link';
import MUIInputBase from '@mui/material/InputBase';
import MUIArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MUIStyled from '@mui/material/styles/styled';
import MUICollapse from "@mui/material/Collapse";
import MUISwitch from '@mui/material/Switch';
import MUIFormControlLabel from '@mui/material/FormControlLabel';
import MUILogoutIcon from '@mui/icons-material/Logout';
import MUIInfoIcon from '@mui/icons-material/Info';
import MUIList from "@mui/material/List";
import MUIListItemIcon from "@mui/material/ListItemIcon";
import MUISupportIcon from '@mui/icons-material/Support';
import MUIListItemText from "@mui/material/ListItemText";
import MUIListItemButton from "@mui/material/ListItemButton";
import MUIMoreVertIcon from '@mui/icons-material/MoreVert';
import MUISearchIcon from '@mui/icons-material/Search';
import MUIExpandLessIcon from '@mui/icons-material/ExpandLess';
import MUIExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MUIDarkModeIcon from '@mui/icons-material/DarkMode';
import MUIGreyColor from "@mui/material/colors/grey";

import OrderService from '../Seating/Security/OrderService/orderService';
import Security from '../Utility/Security';
import Setting from '../Utility/Setting';
import logoIcon from "../Asset/Image/logoWhite.png"

import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import DialogUpdateNotes from './Dialog/DialogUpdateNotes';
import * as Sentry from "@sentry/react";

export default class AppBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openItems: {},
            viewMode: 'desktop',
            isSearching: false,
            pages: {
                'reverseQuality': {
                    title: "Reverse Quality",
                    subpages: [
                        // TODO: Refactor ReportReverseQualityModal into a SP Component and import into this file.
                        // Keeping the logic in this file for now due to the complexity of refactoring.
                        { title: 'Report Reverse Quality', href: '#!', onClick: props.openReportReverseQualityModal },
                        { title: 'Reverse Quality Logs', href: '/reports/reverseQuality', permission: 'module.reverseQualityLogs' },
                        { title: 'Reverse Quality Reasons', href: '/reverseQualityReasons', permission: 'module.reverseQualityReasons' },
                    ]
                },
                'reports': {
                    title: "Reports",
                    subpages: [
                        { title: 'Home', href: '/reports/home' },
                        { title: 'Sales Pipeline', href: '/reports/salesPipeline' },
                        { title: 'Month at a Glance', href: '/reports/monthGlance' },
                        { title: 'Metric', href: '/reports/metric', permission: 'module.metric' },
                        { title: 'AR Dashboard', href: '/reports/ARDashboard', permission: 'module.ARDashboard' },
                    ]
                },
                'tasks': { title: 'Tasks', href: '/tasks' },
                'faxes': { title: 'Faxes', href: '/faxes' },
                'operations': {
                    title: "Operations",
                    subpages: [
                        { title: 'Orders', href: '/order' },
                        { title: 'Patients', href: '/patientList' },
                        { title: 'RTD Patient Collections', href: '/collections', permission: 'module.operationsPatientCollections' },
                        { title: 'Patient Collections', href: '/operations/patientCollections', permission: 'module.operationsPatientCollections' },
                        { title: 'Recurring Orders', href: '/recurringOrders' },
                        { title: 'Patient App Approvals', href: '/patientApprovals' },
                    ]
                },
                'fieldOperations': {
                    title: "Field Ops",
                    subpages: [
                        { title: 'Deliveries', href: '/deliveries' },
                        { title: 'ATP', href: '/atp' },
                        { title: 'Inventory Tracking', href: '/inventoryTracking' },
                    ]
                },
                'sales': {
                    title: "Sales",
                    subpages: [
                        { title: 'Accounts', href: '/accounts' },
                        { title: 'Activities', href: '/activities' },
                        { title: 'Contacts', href: '/contacts' },
                        { title: 'PCR Links', href: '/admin/salesPcrLinks', permission: 'module.salesPcrLinks' },
                        { title: 'Presidents Club', href: '/presidentsClub', permission: 'module.presidentsClub' },
                        { title: 'Sales Bonus', href: '/bonus', permission: 'module.salesBonus' },
                        { title: 'Sales Liaison Meetings', href: '/liaisonMeetings', permission: 'module.liaisonMeetings' },
                    ]
                },
                'purchasing': {
                    title: "Purchasing",
                    subpages: [
                        { title: 'Purchase Queues', href: '/purchaseQueues', permission: 'module.purchasingQueues' },
                        // TODO: Refactor InventoryRequestModal && PODeliveryUpdateModal into a SP Component and import into this file.
                        // Keeping the logic in this file for now due to the complexity of refactoring.
                        { title: 'Inventory Request', href: '#!', permission: 'module.inventoryRequest', onClick: props.openInventoryRequestModal },
                        { title: 'PO Delivery Update', href: '#!', permission: 'module.poDeliveryUpdate', onClick: props.openPODeliveryUpdateModal },
                        { title: 'Inventory Products', href: '/inventoryProducts', permission: 'module.inventoryProducts' },
                        { title: 'Vendors', href: '/vendors', permission: 'module.vendors' },
                        { title: 'Shipping Details Report', href: '/shippingDetails', permission: 'module.shippingDetailsReport' },
                        { title: 'Manage Service Parts', href: '/serviceParts', permission: 'module.manageServiceParts' },
                        { title: 'Product Categories', href: '/products', permission: 'module.productCategories' },
                        { title: 'Service Part Categories', href: '/admin/servicePartCatalogCategories', permission: 'module.servicePartCategories' },
                    ]
                },
                'marketing': {
                    title: "Marketing",
                    subpages: [
                        { title: 'Campaigns', href: '/admin/marketingCampaigns', permission: 'module.marketingCampaigns' },
                        { title: 'Campaign Categories', href: '/admin/marketingCampaignCategories', permission: 'module.marketingCampaignCategories' },
                        { title: 'Preferred Contact Methods', href: '/admin/marketingPreferredContactMethods', permission: 'module.marketingPreferredContactMethods'},
                    ]
                },
                'admin': {
                    title: "Admin",
                    subpages: [
                        { title: "Users", href: "/userList", permission: "module.adminUsers", category: "User Mangement" },
                        { title: "Departments", href: "/admin/departments", permission: "module.adminDepartments", category: "User Mangement" },
                        { title: "Teams", href: "/admin/teams", permission: "module.adminTeams", category: "User Mangement" },
                        { title: "Payor Sources", href: "/admin/insuranceTypes", permission: "module.adminPayorSources", category: "Insurance" },
                        { title: "Insurance", href: "/admin/insuranceSubTypes", permission: "module.adminInsurance", category: "Insurance" },
                        { title: "Insurance Groups", href: "/admin/insuranceSubTypeGroups", permission: "module.adminInsuranceGroups", category: "Insurance" },
                        { title: "Insurance Details", href: "/insuranceSubTypeDetails", permission: "module.adminInsuranceDetails", category: "Insurance" },
                        { title: "Account Types", href: "/admin/accountTypes", permission: "module.adminAccountTypes", category: "Sales" },
                        { title: "Notification Types", href: "/notificationTypes", permission: "module.adminNotificationTypes", category: "Sales" },
                        { title: "Contact Types", href: "/admin/contactTypes", permission: "module.adminContactTypes", category: "Sales" },
                        { title: "Credit Card Types", href: "/creditCardTypes", permission: "module.adminCreditCardTypes", category: "Sales" },
                        { title: "Expense Types", href: "/expenseTypes", permission: "module.adminExpenseTypes", category: "Sales" },
                        { title: "Document Types", href: "/admin/documentTypes", permission: "module.adminDocumentTypes", category: "Sales" },
                        { title: "Status Reasons", href: "/admin/orderStatusReasons", permission: "module.adminStatusReasons", category: "System Config" },
                        { title: "Locations", href: "/admin/locations", permission: "module.adminLocations", category: "System Config" },
                        { title: "Regions", href: "/admin/regions", permission: "module.adminRegions", category: "System Config" },
                        { title: "Manual Document Builder", href: "/manualDocumentBuilder", permission: "module.adminManualDocumentBuilder", category: "System Config" },
                        { title: "Delivery Form Acknowledgements", href: "/deliveryAcknowledgements", permission: "module.adminDeliveryFormAcknowledgements", category: "System Config" },
                        { title: "Order Activity Types", href: "/admin/orderActivityTypes", permission: "module.adminOrderActivityTypes", category: "System Config" },
                        { title: "Queues", href: "/admin/queues", permission: "module.adminQueues", category: "System Config" },
                        { title: "Queue Types", href: "/admin/queueTypes", permission: "module.adminQueueTypes", category: "System Config" },
                        { title: "Queue Routes", href: "/admin/queueRoutes", permission: "module.adminQueueRoutes", category: "System Config" },
                        { title: "Commission Tiers", href: "/admin/commissionTiers", permission: "module.adminCommissionTiers", category: "System Config"},
                        { title: "Place of Services", href: "/admin/placeOfServices", permission: "module.adminPlaceOfServices", category: "System Config"},
                        { title: "Product Sizes", href: "/productSizes", permission: "module.adminProductSizes", category: "Products" },
                        { title: "Base Unit Types", href: "/admin/baseUnitTypes", permission: "module.adminBaseUnitTypes", category: "Products" },
                        { title: "Base Units", href: "/admin/baseUnits", permission: "module.adminBaseUnits", category: "Products" },
                        { title: "Brightree Products", href: "/admin/btProducts", permission: "module.adminBrightreeProducts", category: "Products" },
                        { title: "Issue Categories", href: "/admin/issueCategories", permission: "module.adminIssueCategories", category: "Patient Config" },
                        { title: "Issue Reasons", href: "/admin/issueReasons", permission: "module.adminIssueReasons", category: "Patient Config" },
                        { title: "Patient Training Videos", href: "/trainingLink", permission: "module.adminPatientTrainingVideos", category: "Patient Config" },
                        { title: "Product Matrix Entries", href: "/productMatrixEntry", permission: "module.adminProductMatrixEntries", category: "Patient Config" },
                        { title: "Patient App Approvals", href: "/patientApprovals", permission: "module.adminPatientAppApprovals", category: "Patient Config" },
                        { title: "Hcpcs Codes", href: "/admin/hcpcsCodes", permission: "module.adminHcpcsCodes", category: "Billing" },
                        { title: "Distribution Reasons", href: "/admin/distributionReasons", permission: "module.adminDistributionReasons", category: "Billing" },
                        { title: "Invoice Followup Statuses", href: "/admin/invoiceFollowupStatuses", permission: "module.adminInvoiceFollowupStatuses", category: "Billing" },
                        { title: "Distribution Types", href: "/admin/distributionTypes", permission: "module.adminDistributionTypes", category: "Billing" },
                        { title: "Brightree Invoice Statuses", href: "/admin/brightreeInvoiceStatuses", permission: "module.adminBrightreeInvoiceStatuses", category: "Billing" },
                        { title: "User Production Types", href: "/admin/userProductionTypes", permission: "module.adminUserProductionTypes", category: "Billing" },
                    ]
                },
            },
        }

        // Refs to track the width of each element
        this.logoRef = React.createRef();
        this.searchBoxRef = React.createRef();
        this.navBoxRef = React.createRef();
        this.logoutRef = React.createRef();
        this.hamburgerMenuRef = React.createRef();

        // Initialize debounce variables
        this.resizeTimeout = null;
    }

   // Function to update the view mode dynamically based on element widths and window width
    updateViewModeDynamicallyBasedOnBrowserAndElementWidths = () => {
        const getElementWidth = (elementRef) => {
            if (elementRef && elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                return rect.width;
            }
            return 0;
        };

        // Get the widths of the elements using getBoundingClientRect()
        const logoWidth = getElementWidth(this.logoRef);
        const searchBoxWidth = getElementWidth(this.searchBoxRef);
        const navBoxWidth = getElementWidth(this.navBoxRef);
        const logoutWidth = getElementWidth(this.logoutRef);
        const hamburgerMenuWidth = getElementWidth(this.hamburgerMenuRef);

        // Get the total width of the elements inside the AppBar for different view modes
        const totalDesktopViewWidth = logoWidth + searchBoxWidth + navBoxWidth + logoutWidth + 70; // Add extra padding/margin
        const totalTabletViewWidth = hamburgerMenuWidth + logoWidth + searchBoxWidth + logoutWidth + 50; // Add extra padding/margin
        const totalMobileViewWidth = hamburgerMenuWidth + searchBoxWidth + logoutWidth + 50; // Add extra padding/margin

        if (this.state.viewMode === "mobile" && window.innerWidth > totalMobileViewWidth) {
            this.setState({ viewMode: 'tablet' });
        }

        if (this.state.viewMode === "tablet" && window.innerWidth > totalDesktopViewWidth) {
            this.setState({
                viewMode: 'desktop',
            });
        }

        if (this.state.viewMode === "desktop" && totalDesktopViewWidth > window.innerWidth) {
            this.setState({
                viewMode: 'tablet',
            });
        }

        if (this.state.viewMode === "tablet" && totalTabletViewWidth > window.innerWidth) {
            this.setState({
                viewMode: 'mobile',
            });
        }
    };

    handleResize = () => {
        // Set a new timeout to call updateViewModeDynamicallyBasedOnBrowserAndElementWidths after 200ms
        this.resizeTimeout = setTimeout(this.updateViewModeDynamicallyBasedOnBrowserAndElementWidths, 200);
    };

    componentDidMount() {
        // Add resize event listener
        window.addEventListener('resize', this.handleResize);
        // Check initial width on mount
        this.updateViewModeDynamicallyBasedOnBrowserAndElementWidths();
    }

    componentWillUnmount() {
        // Cleanup event listener and clear timeout on unmount
        window.removeEventListener('resize', this.handleResize);
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
    }

    render() {
        const Search = MUIStyled('div')(() => ({
            position: 'relative',
            borderRadius: '4px',
            backgroundColor: '#5398dd',
            '&:hover': {
                backgroundColor: '#64a3e3',
            },
            width: '170px',
        }));

        const SearchIconWrapper = MUIStyled('div')(() => ({
            paddingLeft: 10,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }));

        const StyledInputBase = MUIStyled(MUIInputBase)(() => ({
            color: MUIGreyColor[50],
            width: '100%',
            '& .MuiInputBase-input': {
                padding: 5,
                paddingLeft: 40,
            },
        }));

        const renderRedirect = () => {
            if (this.state.orderFound === true) {
                this.setState({ orderFound: false });
                if (this.state.order.activity.type === "Ticket" ||
                    this.state.order.activity.type === "Order" && this.state.order.orderType === "Billing Issue" && moment(this.state.order.activity.startedAt).isAfter(moment("2024-12-27", "YYYY-MM-DD"))
                ) {
                    return <Redirect to={{ pathname: "/patientInformation/" + this.state.order.patientId + "/" + this.state.order.id, state: { shouldOpenOrderId: this.state.order.id } }} />;
                } else {
                    return <Redirect to={{ pathname: "/order/" + this.state.order.id, state: { order: this.state.order } }} />;
                }
            }
        }

        const searchForOrderId = (id) => {
            if (this.state.isSearching) {
                // If a search is already in progress, do nothing
                return;
            }

            // Mark as searching
            this.setState({ isSearching: true });

            OrderService
                .getOrderActivity(id)
                .then((res) => {
                    if ((res && res.id && res.activity && res.activity.type.toLowerCase() === "order") || res.activity.type.toLowerCase() === "ticket") {
                        this.setState({
                            orderFound: true,
                            order: res,
                        });
                    } else {
                        NotistackEnqueueSnackbar("Order not found.", { variant: "error" });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    NotistackEnqueueSnackbar("Order not found.", { variant: "error" });
                })
                .finally(() => {
                    // Reset the searching state once the request is complete
                    this.setState({ isSearching: false });
                });
        }

        return (
            <MUIAppBar position="fixed" sx={{zIndex: 1040}} onClick={() => {if (this.props.modalOpen) {this.props.onCloseDialog()}}}>
                <MUIToolbar
                    variant="dense"
                    disableGutters
                    sx={this.state.viewMode === "desktop" ? { justifyContent: 'flex-start', ml: 2} : { justifyContent: 'flex-start' }}
                >
                    {/* Hamburger Menu for Tablet and Mobile Screens */}
                    {(this.state.viewMode === "tablet" || this.state.viewMode === "mobile") && (
                        <MUIBox ref={this.hamburgerMenuRef}>
                            <MUIIconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={(e) => this.setState({ anchorElHamburgerMenuNav: e.currentTarget })}
                                color="inherit"
                            >
                                <MUIMenuIcon />
                            </MUIIconButton>
                            <MUIMenu
                                anchorEl={this.state.anchorElHamburgerMenuNav}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                open={Boolean(this.state.anchorElHamburgerMenuNav)}
                                onClose={() => this.setState({ anchorElHamburgerMenuNav: null, openItems: {} })}
                            >
                                {/* Prepending the "Home" page to the list of pages */}
                                {[{
                                    title: "Dashboard",
                                    href: "/dashboard",
                                }, ...Object.values(this.state.pages)].map(this.renderHamburgerMenuNavItems)}
                            </MUIMenu>
                        </MUIBox>
                    )}

                    {/* Logo for Desktop and Tablet Screens */}
                    <MUIBox ref={this.logoRef} sx={this.state.viewMode === 'tablet' || this.state.viewMode === 'desktop' ? { display: 'flex' } : { position: 'absolute', visibility: 'hidden' }}>
                        <ReactRouterLink to="/dashboard" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                            <img src={logoIcon} alt="Sales Pilot Logo" />
                            <MUITypography variant="h6" noWrap>
                                Sales Pilot
                            </MUITypography>
                        </ReactRouterLink>
                    </MUIBox>

                    {/* Search Box for All Screen Sizes */}
                    <MUIBox ref={this.searchBoxRef} sx={{ display: 'flex', alignItems: 'center', marginRight: 2, marginLeft: 3 }}>
                        <Search>
                            <SearchIconWrapper>
                                <MUISearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                fullWidth
                                placeholder="Order #"
                                inputProps={{ 'aria-label': 'search' }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        const orderId = e.target.value;
                                        searchForOrderId(orderId);
                                    }
                                }}
                                disabled={this.state.isSearching} // Disable input while searching
                            />
                        </Search>
                    </MUIBox>

                    {/* Navigation Links for Desktop Screens */}
                    <MUIBox ref={this.navBoxRef} sx={this.state.viewMode === "desktop" ? { display: 'flex', justifyContent: 'space-between'} : { position: 'absolute', visibility: 'hidden', display: 'flex', justifyContent: 'space-between' }}>
                        {Object.keys(this.state.pages).map(key => this.renderNavItem(key))}
                    </MUIBox>

                    {/* Logout Button for All Screen Sizes */}
                    <MUIBox sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        {this.renderLogoutNavMenu()}
                    </MUIBox>
                </MUIToolbar>
                <DialogUpdateNotes
                    open={this.state.updateNotesDialogOpen}
                    onClose={() => {
                        this.setState({
                            updateNotesDialogOpen: false,
                        });
                    }}
                />
                {renderRedirect()}
            </MUIAppBar>
        );
    }

    filterSubpages(subpages) {
        return subpages
            .map(subpage => ({
                ...subpage,
                // Recursively filter any nested subpages
                subpages: subpage.subpages ? this.filterSubpages(subpage.subpages) : undefined
            }))
            .filter(subpage =>
                !subpage.permission || Security.hasPermission(subpage.permission) || (subpage.subpages && subpage.subpages.length > 0)
            ); // Keep subpages without permissions or with accessible nested subpages
    }

    renderNavItem(pageKey) {
        const page = this.state.pages[pageKey];
        const isDropdown = page.subpages && page.subpages.length > 0;

        // Check permissions for non-dropdown pages
        const hasPermission = !page.permission || Security.hasPermission(page.permission);

        // If it's a dropdown, filter subpages based on permissions
        const filteredSubpages = isDropdown ? this.filterSubpages(page.subpages) : [];

        // If the page has no accessible subpages, remove the title
        const shouldRenderTitle = isDropdown && filteredSubpages.length > 0;

        // If the page is not a dropdown and no permission, don't render it
        if (!isDropdown && !hasPermission) {
            return null;
        }

        const menuItems = [];
        let currentCategory = null;

        filteredSubpages.forEach(subpage => {
            // If the category of the current subpage is different from the previous one
            if (subpage.category && subpage.category !== currentCategory) {
                // Add a category header
                menuItems.push(
                    <MUIMenuItem key={`${subpage.category}-header`} sx={{ pointerEvents: 'none', pl: 2 }}>
                        <MUITypography variant="subtitle2" sx={{ padding: '6px 0', color: MUIGreyColor[500], textAlign: 'left', textTransform: 'uppercase', display: 'inline-block', borderBottom: `1px solid ${MUIGreyColor[500]}`, width: '100%'}}>{subpage.category}</MUITypography>
                    </MUIMenuItem>
                );
                // Update the current category
                currentCategory = subpage.category;
            }

            // Check if the subpage has its own subpages
            const hasSubsubpages = subpage.subpages && subpage.subpages.length > 0;

            // Render subpage title
            if (hasSubsubpages) {
                menuItems.push(
                    <MUIMenuItem key={subpage.title} sx={{ pointerEvents: 'none', pl: 2 }}>
                        <MUITypography sx={{ textAlign: 'left' }}>{subpage.title}</MUITypography>
                    </MUIMenuItem>
                );
            } else {
                menuItems.push(
                    <ReactRouterLink to={subpage.href} style={{ textDecoration: 'none', color: 'inherit' }} key={subpage.title} onClick={subpage.onClick ? subpage.onClick : null}>
                        <MUIMenuItem onClick={() => this.setState({ [pageKey]: null })}>
                            <MUITypography sx={{ textAlign: 'center' }}>
                                {subpage.title}
                            </MUITypography>
                        </MUIMenuItem>
                    </ReactRouterLink>
                );
            }

            // If the subpage has sub-subpages, render them
            if (hasSubsubpages) {
                const filteredSubsubpages = this.filterSubpages(subpage.subpages);
                filteredSubsubpages.forEach(subsubpage => {
                    menuItems.push(
                        <ReactRouterLink to={subsubpage.href} style={{ textDecoration: 'none', color: 'inherit' }} key={subsubpage.title}>
                            <MUIMenuItem onClick={() => this.setState({ [pageKey]: null })} sx={{ pl: 4 }}>
                                <MUITypography sx={{ textAlign: 'left' }}>{subsubpage.title}</MUITypography>
                            </MUIMenuItem>
                        </ReactRouterLink>
                    );
                });
            }
        });

        return isDropdown ? (
            <div key={page.title}>
                {shouldRenderTitle && (
                    <MUIButton
                        color="inherit"
                        size="small"
                        sx={{pl: 1}}
                        onClick={(e) => this.setState({ [pageKey]: e.currentTarget })}
                        endIcon={<MUIArrowDropDownIcon/>}
                    >
                        <MUITypography variant="button" sx={{whiteSpace: 'nowrap'}}>{page.title}</MUITypography>
                    </MUIButton>
                )}
                <MUIMenu
                    anchorEl={this.state[pageKey]}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    keepMounted
                    open={Boolean(this.state[pageKey])}
                    onClick={() => this.setState({ [pageKey]: null })}
                    sx={{ display: { xs: 'none', lg: 'block' }, mt: '45px', maxHeight: '550px' }}
                >
                    {menuItems}
                </MUIMenu>
            </div>
        ) : (
            hasPermission && ( // Render non-dropdown page only if the user has permission
                <ReactRouterLink to={page.href} key={page.title} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <MUIButton
                        color="inherit"
                        size="small">
                        <MUITypography variant="button">{page.title}</MUITypography>
                    </MUIButton>
                </ReactRouterLink>
            )
        );
    }

    // Helper function to render menu items
    renderHamburgerMenuNavItems = (page) => {
        const hasPermission = !page.permission || Security.hasPermission(page.permission);
        if (!hasPermission) return null; // Skip pages without permission

        const isDropdown = page.subpages && page.subpages.length > 0;
        const hasAccessibleSubpages = isDropdown && page.subpages.some(subpage => !subpage.permission || Security.hasPermission(subpage.permission));

        if (isDropdown && !hasAccessibleSubpages) return null; // Skip dropdown titles without accessible subpages

        // Toggles the open state of the specific item
        const handleToggleCollapse = (pageTitle) => {
            this.setState((prevState) => {
                return {
                    openItems: {
                        [pageTitle]: !prevState.openItems[pageTitle], // Toggle the state of the clicked item
                    },
                };
            });
        };

        const renderCategoryHeader = (category) => {
            return (
                <MUIListItemButton sx={{ pointerEvents: 'none', pl: 2 }}>
                    <MUITypography variant="subtitle2" sx={{ padding: '6px 0', color: MUIGreyColor[500], textAlign: 'left', textTransform: 'uppercase', display: 'inline-block', borderBottom: `1px solid ${MUIGreyColor[500]}`, width: '100%' }}>
                        {category}
                    </MUITypography>
                </MUIListItemButton>
            );
        };

        const renderSubpages = (subpages) => {
            let lastCategory = null;  // Track the last rendered category
            return subpages.map((subpage, index) => {
                // Skip subpages without permission
                const hasSubpagePermission = !subpage.permission || Security.hasPermission(subpage.permission);
                if (!hasSubpagePermission) return null;

                const category = subpage.category;

                // Render the category header only if it has changed (or if it's the first subpage with that category)
                const renderCategory = category && category !== lastCategory;
                if (renderCategory) {
                    lastCategory = category;  // Update last rendered category
                }

                // Determine padding for the subpage: first subpage in category should not be indented
                const paddingLeft = 3

                return (
                    <React.Fragment key={subpage.title}>
                        {renderCategory && renderCategoryHeader(category)}
                        <ReactRouterLink to={subpage.href} style={{ textDecoration: 'none', color: 'inherit' }} onClick={subpage.onClick ? subpage.onClick : null}>
                            <MUIListItemButton sx={{ pl: paddingLeft }} onClick={() => this.setState({ anchorElHamburgerMenuNav: null, openItems: {} })}>
                                <MUIListItemText primary={subpage.title} />
                            </MUIListItemButton>
                        </ReactRouterLink>
                    </React.Fragment>
                );
            });
        };

        return (
            <div key={page.title}>
                {isDropdown && hasAccessibleSubpages && (
                    <MUIList component="div" disablePadding>
                        <MUIListItemButton sx={{ minWidth: '300px' }} onClick={() => handleToggleCollapse(page.title)}>
                            <MUIListItemText primary={page.title} />
                            {this.state.openItems[page.title] ? <MUIExpandLessIcon /> : <MUIExpandMoreIcon />}
                        </MUIListItemButton>
                        <MUICollapse in={this.state.openItems[page.title]} timeout="auto" unmountOnExit>
                            <MUIList component="div" disablePadding>
                                {renderSubpages(page.subpages)} {/* Render subpages with category headers */}
                            </MUIList>
                        </MUICollapse>
                    </MUIList>
                )}
                {!isDropdown && (
                    <ReactRouterLink to={page.href} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <MUIMenuItem onClick={() => this.setState({ anchorElHamburgerMenuNav: null, openItems: {} })}>
                            <MUITypography sx={{ textAlign: 'center', padding: '4px 0' }}>
                                {page.title}
                            </MUITypography>
                        </MUIMenuItem>
                    </ReactRouterLink>
                )}
            </div>
        );
    };

    renderLogoutNavMenu() {
        const logoutMenuItems =
        {
            title: "Settings",
            subpages: [
                { title: 'Log Out', href: '/logout', icon: <MUILogoutIcon />, onClick: "logout" },
                { title: `Update Notes (${window.salesPilot.meta.version})`, href: '#!', icon: <MUIInfoIcon />, onClick: "updateNotes" },
                { title: `Support`, href: '#!', icon: <MUISupportIcon />, onClick: "support" },
                {
                    title: 'Dark Mode',
                    href: '#!',
                    icon: <MUIDarkModeIcon />,
                    onClick: null,  // No need for an onClick function since it will toggle a switch
                    darkModeSwitch: true // Special flag to indicate this item is a switch (for Dark Mode)
                },
            ],
        }

        return (
            <MUIBox ref={this.logoutRef}>
                {/* Icon Button that triggers the menu */}
                <MUIIconButton
                    color="inherit"
                    size="small"
                    onClick={(e) => this.setState({ anchorElMenu: e.currentTarget })}
                    sx={{
                        '&:hover': {
                            borderRadius: '50%',
                        },
                        width: '50px',
                        height: '50px',
                    }}
                >
                    <MUILink underline="none">
                        <MUIMoreVertIcon />
                    </MUILink>
                </MUIIconButton>

                {/* Menu Component that is displayed when the icon button is clicked */}
                <MUIMenu
                    anchorEl={this.state.anchorElMenu}
                    anchorOrigin={{
                        horizontal: "left",
                        vertical: "top",
                    }}
                    keepMounted
                    transformOrigin={{
                        horizontal: "left",
                        vertical: "top",
                    }}
                    open={Boolean(this.state.anchorElMenu)}
                    onClose={() => this.setState({ anchorElMenu: null })}
                    sx={{ display: { xs: 'block', lg: 'block' }, mt: '45px' }}
                >
                    {/* Iterate over the subpages in the 'settingsMenu' */}
                    {logoutMenuItems.subpages.map((item) => (
                        <div key={item.title}>
                            {/* Render items that are links (e.g., Logout, Update Notes) */}
                            {!item.darkModeSwitch ? (
                                <ReactRouterLink to={item.href} style={{ textDecoration: 'none', color: 'inherit' }}
                                    onClick={() => {
                                        if (item.onClick === "logout") {
                                            window.sessionStorage.clear();
                                            window.location.href = "/";
                                        } else if (item.onClick === "updateNotes") {
                                            this.setState({
                                                updateNotesDialogOpen: true,
                                            })
                                        } else if (item.onClick === "support") {
                                            window.open("https://rehabmedical.desk365.io/support/form/salespilot/")
                                        }
                                    }}>
                                    <MUIMenuItem
                                        onClick={() => this.setState({ anchorElMenu: null })}
                                    >
                                        <MUIListItemIcon>
                                            {item.icon}
                                        </MUIListItemIcon>
                                        <MUIListItemText primary={item.title} />
                                    </MUIMenuItem>
                                </ReactRouterLink>
                            ) : null}

                            {/* Render Dark Mode switch */}
                            {item.darkModeSwitch && (
                            <MUIBox sx={{ display: 'flex', alignItems: 'center' }}>
                                <MUIListItemIcon sx={{ minWidth: '36px', ml: 2 }}>
                                    {item.icon} {/* Dark Mode Icon */}
                                </MUIListItemIcon>
                                <MUIFormControlLabel
                                    sx={{
                                        mb: 0,
                                        ml: 1, // Add margin to align better with text
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        margin: '0 0', // Remove margin around the label
                                    }}
                                    control={<MUISwitch
                                        sx={{ml: 3}}
                                        checked={Setting.get("ui.theme") === "dark"}
                                        onChange={(e) => {
                                            const theme = e.target.checked ? 'dark' : 'light';
                                            Setting.set("ui.theme", theme);  // Update the theme globally
                                            this.setState({ theme });  // Optionally update local state for immediate reflection
                                        }}
                                    />}
                                    label={item.title}  // The label for Dark Mode
                                    labelPlacement="start"
                                />
                            </MUIBox>
                        )}
                        </div>
                    ))}
                </MUIMenu>
            </MUIBox>
        );
    }
}