import api from "../api";

class PatientAppService {

    // calls proc spGetPatientAppRequests
    async getPendingApprovals(patientId) {
        return new Promise((res, reject) => {
            api
                .get("/patientapp/getlisting")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatientApprovals(patientId) {
        return new Promise((res, reject) => {
            api
                .get("/patientapp/getbypatient/" + patientId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    async approvePatient(id, patientId) {
        return new Promise((res, reject) => {
            api
                .put("/patientapp/approve/" + id, patientId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async denyPatient(id) {
        return new Promise((res, reject) => {
            api
                .put("/patientapp/deny/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updatePatientAppRequest(obj) {
        return new Promise((res, reject) => {
            api
                .put("/patientapp", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async changePassword(patientId, password) {
        return new Promise((res, reject) => {
            let obj = {
                id: patientId,
                newPassword: password,
            };
            api
                .post("/patientapp/changepassword", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const patientAppService = new PatientAppService();

export default patientAppService;
