import React from "react";
import {
    Button,
    DataTable,
    Container,
    Spinner,
    MDBIcon,
} from "mdbreact";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import ReportsService from "../Security/ReportsService/reportsService";

export default class servicesReport extends React.Component {
    constructor(props) {
        super(props);

        let filters = this.determineFilters(props.filters);
        let topFilters = props.topFilters;

        const data = {
            columns: [
                {
                    label: "Setup Date",
                    field: "setupDate",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Approval Date",
                    field: "approvalDate",
                    sort: "asc",
                    width: 200,
                },

                {
                    label: "Service Location",
                    field: "serviceLocation",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Sales Rep",
                    field: "salesRep",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "ATP",
                    field: "atp",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Patient",
                    field: "patient",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Order ID",
                    field: "orderId",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Account",
                    field: "account",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Payor Source",
                    field: "payorSource",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Insurance",
                    field: "insurance",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Category",
                    field: "category",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Product",
                    field: "product",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Points",
                    field: "points",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Amount",
                    field: "amount",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Region",
                    field: "region",
                    sort: "asc",
                    width: 200,
                },
            ],
            rows: [],
        };

        this.state = {
            servicesData: data,
            allServices: [],
            isLoaded: true,
            startDate: new Date(filters.startDate),
            endDate: new Date(filters.endDate),
            csvFunction: props.csvFunction,
            topFilters: topFilters,
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        //salesReps: PropTypes.array,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Get the most up-to-date filters passed down from parent
        if (
            JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
        ) {
            this.setState({
                topFilters: this.props.topFilters,
            });
        }
        // Checks if the generate reports button has been clicked...JK
        if (prevProps.generateClicked !== this.props.generateClicked) {
            this.getServiceReport();
        }
    }

    formatCurrency(amount) {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
        }).format(amount);
    }

    determineFilters(userFilters) {
        let d = new Date(),
            filters = {};

        if (userFilters) {
            if (userFilters.startDate) {
                filters.startDate = new Date(userFilters.startDate);
            } else {
                filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
            }

            if (userFilters.endDate) {
                filters.endDate = new Date(userFilters.endDate);
            } else {
                filters.endDate = d;
            }
        } else {
            filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
            filters.endDate = d;
        }

        return filters;
    }

    getServiceReport() {
        let st = this.state;

        let tp = st.topFilters,
            filters = {};

        try {
            filters = {
                startDate: new Date(tp.startDate).toLocaleDateString(),
                endDate: new Date(tp.endDate).toLocaleDateString(),
            };
        } catch {
            // toast.error("Please check your dates.");
            return;
        }

        // Have to check len since showDatePicker defaults to false at startup
        if (!tp.showDatePicker && tp.quickFilter.length > 0) {
            filters.startDate = tp.quickFilter[0].toLocaleDateString();
            filters.endDate = tp.quickFilter[1].toLocaleDateString();
        } else {
            // Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter
            if (
                filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
            ) {
                //toast.error("Please check your dates");
                return;
            }
        }

        if (tp.locationsSelected && tp.locationsSelected.length > 0) {
            filters.locations = tp.locationsSelected;
        }

        this.setState({ isLoaded: false });

        ReportsService.getServiceReport(filters)
            .then((res) => {
                this.setState({
                    allServices: res,
                });

                this.formatRows();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    transformDate(date) {
        let result = "";
        if (date) {
            var ds = date.split("T");
            let parts = ds[0].split("-");
            if (parts.length > 1) {
                result = `${parts[1]}/${parts[2]}/${parts[0]}`;
            }
            return result;
        }
    }

    formatRows() {
        let services = this.state.allServices,
            ary = [],
            dt = this.state.servicesData;

        services.forEach((service) => {
            ary.push({
                setupDate: service.setupDate
                    ? this.transformDate(service.setupDate.split(" ")[0])
                    : " ",
                approvalDate: service.approvalDate
                    ? this.transformDate(service.approvalDate.split(" ")[0])
                    : " ",
                serviceLocation: service.serviceLocation,
                salesRep: service.username,
                atp: service.atp ? service.atp.replace(/,/g, " ") : "No ATP",
                patient: service.patientName
                    ? service.patientName.replace(/,/g, " ")
                    : "No Patient",
                orderId: service.orderId ? service.orderId.toString() : " ",
                account: service.account
                    ? service.account.replace(/,/g, " ")
                    : "No Account",
                payorSource: service.payorSource
                    ? service.payorSource.replace(/,/g, " ")
                    : "No Payor Source",
                insurance: service.insurance
                    ? service.insurance.replace(/,/g, " ")
                    : "No Insurance",
                category: service.productCategory
                    ? service.productCategory.replace(/,/g, " ")
                    : "No Category",
                product: service.product
                    ? service.product.replace(/,/g, " ")
                    : "No Product",
                points: service.points ? parseFloat(service.points) : 0,
                amount: service.amount ? parseFloat(service.amount) : 0,
                region: service.region,
            });
        });
        dt.rows = ary;

        this.setState({
            servicesData: dt,
            isLoaded: true,
        });
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    //load the data in the table
    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div>
                <DataTable
                    entriesOptions={[25, 50, 100]}
                    pagesAmount={10}
                    data={this.state.servicesData}
                    responsive
                    // fixed
                    striped
                    bordered
                ></DataTable>

                <Button
                    style={{ float: "right" }}
                    color={"blue"}
                    floating
                    size="sm"
                    data-tip={"Download CSV"}
                    onClick={this.state.csvFunction.bind(
                        this,
                        this.state.servicesData,
                        "servicesExport.csv"
                    )}
                >
                    <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                </Button>
                <ReactTooltip />
            </div>
        );
    }
}
