import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import TileTeam from "../../../Tile/TileTeam";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import DialogSearchTeam from "../../../Dialog/Search/DialogSearchTeam";

export default class CardAdminUserProductionTypeTeam extends Card {
    constructor(props) {
        super(props);

        this.state.teamDialogOpen = false;
    }

    static contextTypes = {
        teamsIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        let userProductionType = this.props.userProductionType;
        let team = null;

        if (userProductionType && userProductionType.teamId !== null) {
            team = this.context.teamsIndexed[userProductionType.teamId];
        }

        const handleEditTeam = () => {
            this.setState({ teamDialogOpen: true });
        };
        const handleClearTeam = () => {
            this.props.onChange({ teamId: null });
        };
        const teamIconButtons = [
            (<MUIIconButton onClick={handleEditTeam}><MUIEditIcon /></MUIIconButton>)
        ];

        if (team) {
            teamIconButtons.push(
                (<MUIIconButton onClick={handleClearTeam}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Render
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileTeam
                            team={team}
                            iconButtons={teamIconButtons}
                            label="Team"
                            required={true}
                            error={ this.props.validationIssues.teamId ? true : false }
                            helperText={ this.props.validationIssues.teamId ? this.props.validationIssues.teamId : "" }
                        />
                    </MUIGrid>
                </MUIGrid>
                <DialogSearchTeam
                    open={this.state.teamDialogOpen}
                    onClose={() => this.setState({ teamDialogOpen: false })}
                    onSelect={(team) => {
                        this.props.onChange({ teamId: team.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Team";
    }
}