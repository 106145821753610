import Layer from "../../../Layer";
import moment from "moment";
import React from "react";
import PageHeader from "../../../Component/PageHeader";
import CardReportSettings from "../../../Component/Card/Report/CardReportSettings";
import CardReportMetricResults from "../../../Component/Card/Report/Metric/CardReportMetricResults";
import QueueService from "../../../Seating/Security/QueueService/queueService";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class ReportMetric extends Layer {

    /**
     * Fetches and processes queue statistics metrics, updating the component's
     * state with the results.
     *
     * @param {Object} settings - Configuration settings for the metrics
     * request.
     */
    async getQueueStatisticsMetrics(settings) {
        let transformedSettings = {
            beginDate: settings.dateRange.beginDate,
            endDate: settings.dateRange.endDate,
        }

        this.setState({ loading: true });

        return QueueService.getQueueStatisticsMetrics(transformedSettings)
            .then((response) => {
                // Get a list of all Rehab Medical holidays in the report date range.
                const holidays = this.getHolidaysInRange(settings.dateRange.beginDate, settings.dateRange.endDate);

                // Filter out holidays and weekends.
                const filteredRows = response.data.filter((row) => {
                    const date = moment.utc([row.year, row.month - 1, row.day]);
                    const isWeekend = date.day() === 0 || date.day() === 6;
                    const isHoliday = holidays[date.format("YYYY-MM-DD")];

                    if (isWeekend || isHoliday) {
                        return false;
                    }

                    return true;
                });

                const transformedRows = filteredRows.map((row) => {
                    const date = moment.utc([row.year, row.month - 1, row.day]);

                    const teamQueue = Object.values(this.context.teamQueuesIndexed).find(
                        (teamQueue) => teamQueue.queueId === row.queueId
                    );

                    const team = teamQueue ? this.context.teamsIndexed[teamQueue.teamId] : null;
                    const department = team ? this.context.departmentsIndexed[team.departmentId] : null;

                    return {
                        id: row.id,
                        teamId: team ? team.id : null,
                        queueId: row.queueId,
                        departmentId: department ? department.id : null,
                        departmentDirectorId: department ? department.directorId : null,
                        departmentExecutiveId: department ? department.executiveId : null,
                        teamManagerId: team ? team.managerId : null,
                        locationId: row.locationId,
                        date: date.format("M/D/YYYY"),
                        countOutOfBounds: row.countOutOfBounds,
                        count: row.count,
                        oobPercentage: `${((row.count ? (row.countOutOfBounds / row.count) : 0) * 100).toFixed(1)}%`,
                    };
                });

                this.setState({
                    rows: transformedRows,
                    loading: false,
                    loadedAt: new Date(),
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false });
                NotistackEnqueueSnackbar("Error retrieving queue statistic metrics", { variant: "error" });
            });
    }

    /**
     * @param {string} beginDate
     * @param {string} endDate
     * @returns {object} A list of all holidays within the date range in an
     * object keyed by the date (YYYY-MM-DD) for easy lookup.
     */
    getHolidaysInRange(beginDate, endDate) {
        const begin = moment(beginDate);
        const end = moment(endDate);
        const holidayMap = {};

        for (let year = begin.year(); year <= end.year(); year++) {
            const fixedHolidays = [
                moment(`January 1, ${year}`, "MMMM D, YYYY"), // New Year's Day
                moment(`July 4, ${year}`, "MMMM D, YYYY"), // Independence Day
                moment(`December 25, ${year}`, "MMMM D, YYYY") // Christmas Day
            ];

            const memorialDay = moment({ year, month: 4, day: 31 }).day(-1); // Last Monday in May
            const laborDay = moment({ year, month: 8, day: 1 }).day(1); // First Monday in September
            const thanksgiving = moment({ year, month: 10, day: 1 }).day(4).add(3, 'weeks'); // Fourth Thursday in November
            const dayAfterThanksgiving = thanksgiving.clone().add(1, 'day');

            const allHolidays = [...fixedHolidays, memorialDay, laborDay, thanksgiving, dayAfterThanksgiving];

            allHolidays.forEach(holiday => {
                if (holiday.isBetween(begin, end, null, "[]")) {
                    holidayMap[holiday.format("YYYY-MM-DD")] = true;
                }
            });
        }

        return holidayMap;
    }

    /**
     * Render the content.
     */
    renderContent() {
        return (
            <>
                <CardReportSettings
                    onRun={(settings) => this.getQueueStatisticsMetrics(settings)}
                    isLoading={this.state.loading}
                    settingComponents={{
                        dateRange: {
                            validationRules: {
                                beginDate: {
                                    required: true
                                },
                                endDate: {
                                    required: true
                                },
                            },
                        },
                    }}
                />
                <CardReportMetricResults
                    rows={this.state.rows}
                    isLoading={this.state.loading}
                    loadedAt={this.state.loadedAt}
                />
            </>
        );
    }

    renderHeader() {
        return (
            <PageHeader title="Metric Report" />
        );
    }

    getMaxWidth() {
        return 1100;
    }
}
