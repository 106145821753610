import React from "react";
import {
    Input,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSelect
} from "mdbreact";
import manualDocumentBuilderService from "../../Settings/manualDocumentBuilderService";
import PropTypes from "prop-types";
import { forEach } from "draft-js/lib/DefaultDraftBlockRenderMap";

export default class HomeAssessmentModal extends React.Component {
    constructor(props) {
        super(props);


        this.toggleHomeAssessmentModal = props.toggleHomeAssessmentModal;
        this.setReload = props.setReload;

        this.state = {
            order: props.order,
            flooringTypes: [],
            assessmentTypes: [],
            selectedFlooringTypes: [],
            selectedAssessmentType: "",

            flooringMobilityAppropriate: true,
            flooringComments: "",

            kitchenThresholdWidth: "",
            kitchenDimensionX: "",
            kitchenDimensionY: "",

            bedroomThresholdWidth: '',
            bedroomDimensionX: '',
            bedroomDimensionY: '',

            bathroomThresholdWidth: '',
            bathroomDimensionX: '',
            bathroomDimensionY: '',

            livingRoomThresholdWidth: '',
            livingRoomDimensionX: '',
            livingRoomDimensionY: '',

            hallwaysThresholdWidth: '',
            hallwaysDimensionX: '',
            hallwaysDimensionY: '',

            adequateSpacePOV: false,
            inadequateSpacePOVDescription: '',
            isNinetyThresholdPOV: false,
            ninetyThresholdPOV: '',
            isOtherPOV: false,
            otherPOV: '',

            adequateSpacePWC: false,
            inadequateSpacePWCDescription: '',
            isNinetyThresholdPWC: false,
            ninetyThresholdPWC: '',
            isOtherPWC: false,
            otherPWC: '',

            adequateIngressRegress: false,
            inadequateIngressRegress: '',

            homeAssessmentFromWeb: props.homeAssessmentFromWeb,


        };


    }

    componentDidMount() {
        this.setFlooringOptions();
        this.setValuesFromWeb();
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string
    };

    setValuesFromWeb() {
        let homeAssessmentFromWeb = this.state.homeAssessmentFromWeb;
        if (!homeAssessmentFromWeb) { return; }
        let selectedFlooringTypes = [];
        let flooringTypes = ["carpet", "tile", "woodLaminate", "linoleum", "other"];
        let flooringKeyValue = { carpet: "Carpet", tile: "Tile", woodLaminate: "Wood/Laminate", linoleum: "Linoleum", other: "Other" };
        flooringTypes.forEach((type) => {
            if (homeAssessmentFromWeb[type]) {
                selectedFlooringTypes.push(flooringKeyValue[type]);
            }
        });


        this.setState({
            selectedFlooringTypes: selectedFlooringTypes,
            selectedAssessmentType: homeAssessmentFromWeb.selectedAssessmentType,
            flooringMobilityAppropriate: homeAssessmentFromWeb.flooringMobilityAppropriate ? homeAssessmentFromWeb.flooringMobilityAppropriate : true,
            flooringComments: homeAssessmentFromWeb.flooringComments ? homeAssessmentFromWeb.flooringComments : "",

            kitchenThresholdWidth: homeAssessmentFromWeb.kitchenThresholdWidth ? homeAssessmentFromWeb.kitchenThresholdWidth : "",
            kitchenDimensionX: homeAssessmentFromWeb.kitchenDimensionX ? homeAssessmentFromWeb.kitchenDimensionX : "",
            kitchenDimensionY: homeAssessmentFromWeb.kitchenDimensionY ? homeAssessmentFromWeb.kitchenDimensionY : "",

            bedroomThresholdWidth: homeAssessmentFromWeb.bedroomThresholdWidth ? homeAssessmentFromWeb.bedroomThresholdWidth : '',
            bedroomDimensionX: homeAssessmentFromWeb.bedroomDimensionX ? homeAssessmentFromWeb.bedroomDimensionX : '',
            bedroomDimensionY: homeAssessmentFromWeb.bedroomDimensionY ? homeAssessmentFromWeb.bedroomDimensionY : '',

            bathroomThresholdWidth: homeAssessmentFromWeb.bathroomThresholdWidth ? homeAssessmentFromWeb.bathroomThresholdWidth : '',
            bathroomDimensionX: homeAssessmentFromWeb.bathroomDimensionX ? homeAssessmentFromWeb.bathroomDimensionX : '',
            bathroomDimensionY: homeAssessmentFromWeb.bathroomDimensionY ? homeAssessmentFromWeb.bathroomDimensionY : '',

            livingRoomThresholdWidth: homeAssessmentFromWeb.livingRoomThresholdWidth ? homeAssessmentFromWeb.livingRoomThresholdWidth : '',
            livingRoomDimensionX: homeAssessmentFromWeb.livingRoomDimensionX ? homeAssessmentFromWeb.livingRoomDimensionX : '',
            livingRoomDimensionY: homeAssessmentFromWeb.livingRoomDimensionY ? homeAssessmentFromWeb.livingRoomDimensionY : '',

            hallwaysThresholdWidth: homeAssessmentFromWeb.hallwaysThresholdWidth ? homeAssessmentFromWeb.hallwaysThresholdWidth : '',
            hallwaysDimensionX: homeAssessmentFromWeb.hallwaysDimensionX ? homeAssessmentFromWeb.hallwaysDimensionX : '',
            hallwaysDimensionY: homeAssessmentFromWeb.hallwaysDimensionY ? homeAssessmentFromWeb.hallwaysDimensionY : '',

            adequateSpacePOV: homeAssessmentFromWeb.adequateSpacePOV ? homeAssessmentFromWeb.adequateSpacePOV : false,
            inadequateSpacePOVDescription: homeAssessmentFromWeb.inadequateSpacePOVDescription ? homeAssessmentFromWeb.inadequateSpacePOVDescription : '',
            isNinetyThresholdPOV: homeAssessmentFromWeb.isNinetyThresholdPOV ? homeAssessmentFromWeb.isNinetyThresholdPOV : false,
            ninetyThresholdPOV: homeAssessmentFromWeb.ninetyThresholdPOV ? homeAssessmentFromWeb.ninetyThresholdPOV : '',
            isOtherPOV: homeAssessmentFromWeb.isOtherPOV ? homeAssessmentFromWeb.isOtherPOV : false,
            otherPOV: homeAssessmentFromWeb.otherPOV ? homeAssessmentFromWeb.otherPOV : '',

            adequateSpacePWC: homeAssessmentFromWeb.adequateSpacePWC ? homeAssessmentFromWeb.adequateSpacePWC : false,
            inadequateSpacePWCDescription: homeAssessmentFromWeb.inadequateSpacePWCDescription ? homeAssessmentFromWeb.inadequateSpacePWCDescription : '',
            isNinetyThresholdPWC: homeAssessmentFromWeb.isNinetyThresholdPWC ? homeAssessmentFromWeb.isNinetyThresholdPWC : false,
            ninetyThresholdPWC: homeAssessmentFromWeb.ninetyThresholdPWC ? homeAssessmentFromWeb.ninetyThresholdPWC : '',
            isOtherPWC: homeAssessmentFromWeb.isOtherPWC ? homeAssessmentFromWeb.isOtherPWC : false,
            otherPWC: homeAssessmentFromWeb.otherPWC ? homeAssessmentFromWeb.otherPWC : '',

            adequateIngressRegress: homeAssessmentFromWeb.adequateIngressRegress ? homeAssessmentFromWeb.adequateIngressRegress : false,
            inadequateIngressRegress: homeAssessmentFromWeb.inadequateIngressRegress ? homeAssessmentFromWeb.inadequateIngressRegress : '',
        });
    }

    handleTextChange(property, e) {
        this.setState({
            [property]: e.target.value,
        });
    }

    createHomeAssessmentDocument() {
        const { companyName } = this.context;
        let st = this.state;

        let selectedFlooringTypes = st.selectedFlooringTypes;

        let hao = {
            orderId: st.order.id,
            company: companyName,
            carpet: selectedFlooringTypes.indexOf("Carpet") > -1,
            tile: selectedFlooringTypes.indexOf("Tile") > -1,
            woodLaminate: selectedFlooringTypes.indexOf("Wood/Laminate") > -1,
            linoleum: selectedFlooringTypes.indexOf("Linoleum") > -1,
            flooringOther: selectedFlooringTypes.indexOf("Other") > -1 ? "Other" : "",
            typeOfAssessment: st.selectedAssessmentType,

            flooringMobilityAppropriate: st.flooringMobilityAppropriate,
            flooringComments: st.flooringComments,

            kitchenThresholdWidth: st.kitchenThresholdWidth,
            kitchenDimensionX: st.kitchenDimensionX,
            kitchenDimensionY: st.kitchenDimensionY,

            bedroomThresholdWidth: st.bedroomThresholdWidth,
            bedroomDimensionX: st.bedroomDimensionX,
            bedroomDimensionY: st.bedroomDimensionY,

            bathroomThresholdWidth: st.bathroomThresholdWidth,
            bathroomDimensionX: st.bathroomDimensionX,
            bathroomDimensionY: st.bathroomDimensionY,

            livingRoomThresholdWidth: st.livingRoomThresholdWidth,
            livingRoomDimensionX: st.livingRoomDimensionX,
            livingRoomDimensionY: st.livingRoomDimensionY,

            hallwaysThresholdWidth: st.hallwaysThresholdWidth,
            hallwaysDimensionX: st.hallwaysDimensionX,
            hallwaysDimensionY: st.hallwaysDimensionY,

            adequateSpacePOV: st.adequateSpacePOV,
            inadequateSpacePOVDescription: st.inadequateSpacePOVDescription,
            isNinetyThresholdPOV: st.isNinetyThresholdPOV,
            ninetyThresholdPOV: st.ninetyThresholdPOV,
            isOtherPOV: st.isOtherPOV,
            otherPOV: st.otherPOV,

            adequateSpacePWC: st.adequateSpacePWC,
            inadequateSpacePWCDescription: st.inadequateSpacePWCDescription,
            isNinetyThresholdPWC: st.isNinetyThresholdPWC,
            ninetyThresholdPWC: st.ninetyThresholdPWC,
            isOtherPWC: st.isOtherPWC,
            otherPWC: st.otherPWC,

            adequateIngressRegress: st.adequateIngressRegress,
            inadequateIngressRegress: st.inadequateIngressRegress,

            patientSignature: "",
            patientSignedDate: null,
            representativeSignature: "",
            representativeSignedDate: new Date().toISOString(),
            covidVirus: false
        };

        manualDocumentBuilderService.addHomeAssessmentWeb(hao).then(res => {
            this.setReload();
            this.toggleHomeAssessmentModal();
        })
            .catch((err) => {
                console.log(err);
            });
    }

    setFlooringOptions() {
        // let flooringTypes = ["Carpet", "Tile", "Wood/Laminate", "Linoleum", "Other"],
        let ary = [],
            sary = [];
        let flooringKeyValue = { carpet: "Carpet", tile: "Tile", woodLaminate: "Wood/Laminate", linoleum: "Linoleum", other: "Other" };

        let assessmentTypes = ["In Person", "Phone"];
        let homeAssessmentFromWeb = this.state.homeAssessmentFromWeb;

        for (var t in flooringKeyValue) {
            if (Object.prototype.hasOwnProperty.call(flooringKeyValue, t)) {
                ary.push({
                    checked: homeAssessmentFromWeb && homeAssessmentFromWeb[t],
                    disabled: false,
                    text: flooringKeyValue[t],
                    value: flooringKeyValue[t]
                });
            }
        }

        assessmentTypes.forEach(t => {
            sary.push({
                checked: homeAssessmentFromWeb && homeAssessmentFromWeb.typeOfAssessment == t,
                disabled: false,
                text: t,
                value: t
            });
        });


        this.setState({
            flooringTypes: ary,
            assessmentTypes: sary,
        });
    }

    getFlooringValue(e) {
        this.setState({
            selectedFlooringTypes: e,
        });
    }

    getAssessmentTypeValue(e) {
        this.setState({
            selectedAssessmentType: e[0],
        });
    }


    renderAssessmentType() {
        return (
            <MDBSelect
                outline
                color="info"
                selected={"Choose Assessment Type"}
                style={{ maxHeight: "100px" }}
                options={this.state.assessmentTypes}
                getValue={this.getAssessmentTypeValue.bind(this)}
            />

        );
    }

    renderFlooringTypes() {
        return (
            <MDBSelect
                multiple
                outline
                color="info"
                selected={"Choose Flooring Types"}
                style={{ maxHeight: "100px" }}
                options={this.state.flooringTypes}
                getValue={this.getFlooringValue.bind(this)}
            />
        );
    }

    renderFlooringSuitable() {
        return (

            <Input
                style={{ paddingTop: 18 }}
                key={"flooringSuitable"}
                onChange={(e) => this.setState({ flooringMobilityAppropriate: e.target.checked })}
                value={this.state.flooringMobilityAppropriate}
                checked={this.state.flooringMobilityAppropriate}
                label="Flooring suitable for power mobility device"
                type="checkbox"
                id="flooringSuitable"
            />

        );
    }

    renderUnsuitableReason() {
        let t = this;
        if (this.state.flooringMobilityAppropriate) {
            return;
        }

        return (
            <MDBInput
                containerClass={"smallMargin"}
                label="Flooring Unsuitable Reason"
                outline
                value={t.state.flooringComments}
                onChange={this.handleTextChange.bind(this, "flooringComments")}
            />
        );
    }

    renderRooms() {

        return (
            <div>
                {/*region kitchen*/}
                <MDBRow>
                    <MDBCol size={3}>
                        <span style={{ lineHeight: 3 }}>
                            Kitchen/Food Prep:
                        </span>
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Threshold Width"
                            outline
                            value={this.state.kitchenThresholdWidth}
                            onChange={this.handleTextChange.bind(this, "kitchenThresholdWidth")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Length"
                            outline
                            value={this.state.kitchenDimensionX}
                            onChange={this.handleTextChange.bind(this, "kitchenDimensionX")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Width"
                            outline
                            value={this.state.kitchenDimensionY}
                            onChange={this.handleTextChange.bind(this, "kitchenDimensionY")}
                        />
                    </MDBCol>
                </MDBRow>
                {/*//endregion*/}

                {/*region bedroom*/}
                <MDBRow>
                    <MDBCol size={3}>
                        <span style={{ lineHeight: 3 }}>
                            Bedroom/Sleeping
                        </span>
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Threshold Width"
                            outline
                            value={this.state.bedroomThresholdWidth}
                            onChange={this.handleTextChange.bind(this, "bedroomThresholdWidth")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Length"
                            outline
                            value={this.state.bedroomDimensionX}
                            onChange={this.handleTextChange.bind(this, "bedroomDimensionX")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Width"
                            outline
                            value={this.state.bedroomDimensionY}
                            onChange={this.handleTextChange.bind(this, "bedroomDimensionY")}
                        />
                    </MDBCol>
                </MDBRow>
                {/*//endregion*/}

                {/*region Bathroom*/}
                <MDBRow>
                    <MDBCol size={3}>
                        <span style={{ lineHeight: 3 }}>
                            Bathroom
                        </span>
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Threshold Width"
                            outline
                            value={this.state.bathroomThresholdWidth}
                            onChange={this.handleTextChange.bind(this, "bathroomThresholdWidth")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Length"
                            outline
                            value={this.state.bathroomDimensionX}
                            onChange={this.handleTextChange.bind(this, "bathroomDimensionX")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Width"
                            outline
                            value={this.state.bathroomDimensionY}
                            onChange={this.handleTextChange.bind(this, "bathroomDimensionY")}
                        />
                    </MDBCol>
                </MDBRow>
                {/*//endregion*/}

                {/*region Living ROom*/}
                <MDBRow>
                    <MDBCol size={3}>
                        <span style={{ lineHeight: 3 }}>
                            Living Room:
                        </span>
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Threshold Width"
                            outline
                            value={this.state.livingRoomThresholdWidth}
                            onChange={this.handleTextChange.bind(this, "livingRoomThresholdWidth")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Length"
                            outline
                            value={this.state.livingRoomDimensionX}
                            onChange={this.handleTextChange.bind(this, "livingRoomDimensionX")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Width"
                            outline
                            value={this.state.livingRoomDimensionY}
                            onChange={this.handleTextChange.bind(this, "livingRoomDimensionY")}
                        />
                    </MDBCol>
                </MDBRow>
                {/*//endregion*/}

                {/*region Hallways*/}
                <MDBRow>
                    <MDBCol size={3}>
                        <span style={{ lineHeight: 3 }}>
                            Connecting Hallways:
                        </span>
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Threshold Width"
                            outline
                            value={this.state.hallwaysThresholdWidth}
                            onChange={this.handleTextChange.bind(this, "hallwaysThresholdWidth")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Length"
                            outline
                            value={this.state.hallwaysDimensionX}
                            onChange={this.handleTextChange.bind(this, "hallwaysDimensionX")}
                        />
                    </MDBCol>
                    <MDBCol size={3}>
                        <MDBInput
                            min="0"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            type={"number"}
                            containerClass={"smallMargin"}
                            label="Width"
                            outline
                            value={this.state.hallwaysDimensionY}
                            onChange={this.handleTextChange.bind(this, "hallwaysDimensionY")}
                        />
                    </MDBCol>
                </MDBRow>
                {/*//endregion*/}
            </div>
        );

    }

    renderScooterSection() {

        return (
            <div>
                POV / Scooter
                <hr />
                <MDBRow>
                    <MDBCol size={4}>
                        <Input
                            style={{ paddingTop: 18 }}
                            key={"adequateSpacePOV"}
                            onChange={(e) => this.setState({ adequateSpacePOV: e.target.checked })}
                            value={this.state.adequateSpacePOV}
                            checked={this.state.adequateSpacePOV}
                            label="Adequate Access for POV/Scooter"
                            type="checkbox"
                            id="adequateSpacePOV"
                        />
                    </MDBCol>
                    <MDBCol size={8}>
                        <MDBInput
                            containerClass={"smallMargin"}
                            label="If NO, describe"
                            outline
                            value={this.state.inadequateSpacePOVDescription}
                            onChange={this.handleTextChange.bind(this, "inadequateSpacePOVDescription")}
                        />
                    </MDBCol>
                </MDBRow>

                {/*<MDBRow>*/}
                {/*    <MDBCol size={4}>*/}
                {/*        <Input*/}
                {/*            style={{paddingTop: 18}}*/}
                {/*            key={"isNinetyThresholdPOV"}*/}
                {/*            onChange={(e) => this.setState({isNinetyThresholdPOV: e.target.checked})}*/}
                {/*            value={this.state.isNinetyThresholdPOV}*/}
                {/*            checked={this.state.isNinetyThresholdPOV}*/}
                {/*            label="90 Degree Threshold"*/}
                {/*            type="checkbox"*/}
                {/*            id="isNinetyThresholdPOV"*/}
                {/*        />*/}
                {/*    </MDBCol>*/}
                {/*    <MDBCol size={8}>*/}
                {/*        <MDBInput*/}
                {/*            containerClass={"smallMargin"}*/}
                {/*            label="If YES, describe problematic area"*/}
                {/*            outline*/}
                {/*            value={this.state.ninetyThresholdPOV}*/}
                {/*            onChange={this.handleTextChange.bind(this, "ninetyThresholdPOV")}*/}
                {/*        />*/}
                {/*    </MDBCol>*/}
                {/*</MDBRow>*/}

                <MDBRow>
                    <MDBCol size={4}>
                        <Input
                            style={{ paddingTop: 18 }}
                            key={"isOtherPOV"}
                            onChange={(e) => this.setState({ isOtherPOV: e.target.checked })}
                            value={this.state.isOtherPOV}
                            checked={this.state.isOtherPOV}
                            label="Other Issues"
                            type="checkbox"
                            id="isOtherPOV"
                        />
                    </MDBCol>
                    <MDBCol size={8}>
                        <MDBInput
                            containerClass={"smallMargin"}
                            label="If YES, describe"
                            outline
                            value={this.state.otherPOV}
                            onChange={this.handleTextChange.bind(this, "otherPOV")}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );

    }

    renderWheelchairSection() {
        return (
            <div>
                Power Wheelchair
                <hr />
                <MDBRow>
                    <MDBCol size={4}>
                        <Input
                            style={{ paddingTop: 18 }}
                            key={"adequateSpacePWC"}
                            onChange={(e) => this.setState({ adequateSpacePWC: e.target.checked })}
                            value={this.state.adequateSpacePWC}
                            checked={this.state.adequateSpacePWC}
                            label="Adequate Access for Power Wheelchair"
                            type="checkbox"
                            id="adequateSpacePWC"
                        />
                    </MDBCol>
                    <MDBCol size={8}>
                        <MDBInput
                            containerClass={"smallMargin"}
                            label="If NO, describe"
                            outline
                            value={this.state.inadequateSpacePWCDescription}
                            onChange={this.handleTextChange.bind(this, "inadequateSpacePWCDescription")}
                        />
                    </MDBCol>
                </MDBRow>

                {/*<MDBRow>*/}
                {/*    <MDBCol size={4}>*/}
                {/*        <Input*/}
                {/*            style={{paddingTop: 18}}*/}
                {/*            key={"isNinetyThresholdPWC"}*/}
                {/*            onChange={(e) => this.setState({isNinetyThresholdPWC: e.target.checked})}*/}
                {/*            value={this.state.isNinetyThresholdPWC}*/}
                {/*            checked={this.state.isNinetyThresholdPWC}*/}
                {/*            label="90 Degree Threshold"*/}
                {/*            type="checkbox"*/}
                {/*            id="isNinetyThresholdPWC"*/}
                {/*        />*/}
                {/*    </MDBCol>*/}
                {/*    <MDBCol size={8}>*/}
                {/*        <MDBInput*/}
                {/*            containerClass={"smallMargin"}*/}
                {/*            label="If YES, describe problematic area"*/}
                {/*            outline*/}
                {/*            value={this.state.ninetyThresholdPWC}*/}
                {/*            onChange={this.handleTextChange.bind(this, "ninetyThresholdPWC")}*/}
                {/*        />*/}
                {/*    </MDBCol>*/}
                {/*</MDBRow>*/}

                <MDBRow>
                    <MDBCol size={4}>
                        <Input
                            style={{ paddingTop: 18 }}
                            key={"isOtherPWC"}
                            onChange={(e) => this.setState({ isOtherPWC: e.target.checked })}
                            value={this.state.isOtherPWC}
                            checked={this.state.isOtherPWC}
                            label="Other Issues"
                            type="checkbox"
                            id="isOtherPWC"
                        />
                    </MDBCol>
                    <MDBCol size={8}>
                        <MDBInput
                            containerClass={"smallMargin"}
                            label="If YES, describe"
                            outline
                            value={this.state.otherPWC}
                            onChange={this.handleTextChange.bind(this, "otherPWC")}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderIngressSection() {

        return (
            <div>
                Ingress / Regress
                <hr />
                <MDBRow>
                    <MDBCol size={4}>
                        <Input
                            style={{ paddingTop: 18 }}
                            key={"adequateIngressRegress"}
                            onChange={(e) => this.setState({ adequateIngressRegress: e.target.checked })}
                            value={this.state.adequateIngressRegress}
                            checked={this.state.adequateIngressRegress}
                            label="Adequate Ingress / Regress"
                            type="checkbox"
                            id="adequateIngressRegress"
                        />
                    </MDBCol>
                    <MDBCol size={8}>
                        <MDBInput
                            containerClass={"smallMargin"}
                            label="If NO, describe"
                            outline
                            value={this.state.inadequateIngressRegress}
                            onChange={this.handleTextChange.bind(this, "inadequateIngressRegress")}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );

    }


    render() {
        return (
            <MDBCard>
                <MDBCardHeader>
                    Home Assessment
                    <span style={{ float: 'right' }}>
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="danger"
                            className="px-2 toolbarButton"
                            data-tip={"Close Home Assessment"}
                            data-place={"bottom"}
                            onClick={() => this.toggleHomeAssessmentModal()}
                        >
                            <MDBIcon icon="times" />
                        </MDBBtn>
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="success"
                            className="px-2 toolbarButton"
                            data-tip={"Close Home Assessment"}
                            data-place={"bottom"}
                            onClick={() => this.createHomeAssessmentDocument()}
                        >
                            <MDBIcon icon="check" />
                        </MDBBtn>
                    </span>
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size={4}>
                            {this.renderAssessmentType()}
                        </MDBCol>
                        <MDBCol size={4}>
                            {this.renderFlooringTypes()}
                        </MDBCol>
                        <MDBCol size={4} className={"homeAssessmentCheck"}>
                            {this.renderFlooringSuitable()}
                        </MDBCol>
                        <MDBCol>
                            {this.renderUnsuitableReason()}
                        </MDBCol>
                    </MDBRow>
                    <hr />
                    {this.renderRooms()}
                    <hr />
                    {this.renderScooterSection()}
                    {this.renderWheelchairSection()}
                    {this.renderIngressSection()}
                </MDBCardBody>
            </MDBCard>
        );
    }
}