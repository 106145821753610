import React from "react";
import DialogSearch from "../DialogSearch";
import TileIssueReason from "../../Tile/TileIssueReason";
import PropTypes from "prop-types";
import IssueReason from "../../../Utility/Crud/IssueReason";

export default class DialogSearchIssueReason extends DialogSearch {

    static contextTypes = {
        issueReasonsIndexed: PropTypes.object,
    };

    /**
     * Searches for issue reasons by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered issueReason.
     */
    getSearchResults(query) {
        return IssueReason.search(this.state.data, query, true);
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.issueReasonsIndexed);
        }
    }

    /**
     * @param {object} issueReason The issueReason object.
     * @returns A TileIssueReason component for this issueReason.
     */
    renderTile(issueReason) {
        return (
            <TileIssueReason
                key={issueReason.id}
                issueReason={issueReason}
            />
        );
    }
}
