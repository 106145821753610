import api from "../Security/api";

class CreditCardService {
    async getAllCreditCardTypes() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/creditCardTypes", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateCreditCardType(cc) {
        return new Promise((res, reject) => {
            api
                .put("/creditCardTypes", cc)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createCreditCardType(cc) {
        return new Promise((res, reject) => {
            api
                .post("/creditCardTypes", cc)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteCreditCardType(id) {
        return new Promise((res, reject) => {
            api
                .delete("/creditCardTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const creditCardService = new CreditCardService();

export default creditCardService;
