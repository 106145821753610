import React from "react";
import { Redirect } from "react-router-dom";
import Setting from "./Utility/Setting";

export default class LayerLegacy extends React.Component {

    /**
     * Render a layer inside some containers. Requires this component to be
     * mounted before rendering anything. To further explain: Layers are often
     * reading data from context and placing it in state to be mutated. Context
     * is not accessible in the constructor, so it must be done in
     * componentDidMount(). This means that the component will render, mount,
     * set state, then render again. The second render isn't a performance
     * problem, but it does require coding around the missing data which is
     * annoying. To fix that, this basically only performs the second render.
     */
    render() {
        this.setDocumentTitle();
        this.copyCredentialsToSessionStorage();

        const url = new URL(window.location);

        // If you are not logged in and you're not on one of these layers,
        // redirect to the login layer.
        if (
            !Setting.get("session") &&
            url.pathname !== "/authorize" &&
            url.pathname !== "/" &&
            url.pathname !== "/load"
        ) {
            return (
                <Redirect to={{ pathname: "/", state: { from: url.pathname } }} />
            );
        }

        /**
         * If Sales Pilot is not loaded and you're not on the authorize, login,
         * or loading layers, redirect you to the loading layer.
         */
        if (
            !window.salesPilot.meta.loadedLegacy &&
            url.pathname !== "/authorize" &&
            url.pathname !== "/" &&
            url.pathname !== "/load" &&
            url.pathname !== "/logout"
        ) {
            return (
                <Redirect to={{ pathname: "/load", state: { from: url.pathname } }} />
            );
        }



        return this.renderContent();
    }

    /**
     * Copies a few things from localStorage into sessionStorage. Basically,
     * when you authorize, the session, organization, and userprofile are stored
     * in localStorage. This allows you to open new tabs and persist the same
     * credentials so you don't have to log in every time. However, if you log
     * in to Rehab in one tab, then UAT in another tab, it would be bad to
     * replace those credentials. The Rehab tab would break, or at worst do bad
     * things to the database. To fix that, the API always pulls the credentials
     * out of sessionStorage, so each tab behaves as it's own unique instance.
     */
    copyCredentialsToSessionStorage() {
        if (
            (
                !Setting.get("session", true, window.sessionStorage) ||
                !Setting.get("organization", true, window.sessionStorage) ||
                !Setting.get("userprofile", true, window.sessionStorage)
            ) &&
            (
                Setting.get("session", true, window.localStorage) &&
                Setting.get("organization", true, window.localStorage) &&
                Setting.get("userprofile", true, window.localStorage)
            )
        ) {
            Setting.set("session", Setting.get("session", true, window.localStorage), window.sessionStorage);
            Setting.set("organization", Setting.get("organization", true, window.localStorage), window.sessionStorage);
            Setting.set("userprofile", Setting.get("userprofile", true, window.localStorage), window.sessionStorage);
        }
    }

    /**
     * Sets the document title and adds the company to it.
     */
    setDocumentTitle() {
        const url = new URL(window.location);
        if (
            url.pathname === "/authorize" ||
            url.pathname === "/"
        ) {
            document.title = "Sales Pilot";
        } else {
            let displayedCompany;
            switch (Setting.get("organization")) {
                case 'rehabmedical':
                    displayedCompany = 'Rehab Medical';
                    break;
                case 'corkmedical':
                    displayedCompany = 'Cork Medical';
                    break;
                case 'uat':
                    displayedCompany = 'UAT';
                    break;
                case 'localhost':
                    displayedCompany = 'UAT [localhost]';
                    break;
                default:
                    displayedCompany = Setting.get("organization");
                    break;
            }
            document.title = "Sales Pilot " + (displayedCompany ? ` • ${displayedCompany}` : "");
        }
    }
}
