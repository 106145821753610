import api from "../Security/api";

class ExpenseTypeService {
    async getAllExpenseTypes() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/expenseTypes", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateExpenseType(exp) {
        return new Promise((res, reject) => {
            api
                .put("/expenseTypes", exp)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createExpenseType(exp) {
        return new Promise((res, reject) => {
            api
                .post("/expenseTypes", exp)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteExpenseType(id) {
        return new Promise((res, reject) => {
            api
                .delete("/expenseTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const expenseTypeService = new ExpenseTypeService();

export default expenseTypeService;
