import React from "react";
import MUIIconButton from "@mui/material/IconButton";
import MUIButton from "@mui/material/Button";
import MUIGrid from "@mui/material/Grid";
import TileUser from "../../../Tile/TileUser";
import MUIAddIcon from "@mui/icons-material/Add";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import EmptyState from "../../../EmptyState";
import DialogSearchUser from "../../../Dialog/Search/DialogSearchUser";
import User from "../../../../Utility/Crud/User";
import PropTypes from "prop-types";
import MUITextField from "@mui/material/TextField";
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUISearchIcon from "@mui/icons-material/Search";
import Card from "../../../Card";

export default class CardAdminTeamUsers extends Card {
    static contextTypes = {
        usersIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state.teamUserDialogOpen = false;
        this.state.query = "";
        this.state.filteredTeamUsers = this.props.teamUsers;
    }

    /**
     * Set the search results on mount. This ensures that the list is sorted
     * appropriately on first render.
     */
    componentDidMount() {
        this.setState({
            filteredTeamUsers: this.getFilteredTeamUsers(this.state.query),
        });
    }

    /**
     * Handle removes or adds to the teamUsers prop.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.teamUsers.length !== this.props.teamUsers.length) {
            this.setState({
                filteredTeamUsers: this.getFilteredTeamUsers(this.state.query),
            });
        }
    }

    /**
     * Renders a list of members on the team or an empty state if there are none.
     */
    renderContent() {
        const handleRemoveTeamUser = (removedTeamUser) => {
            this.props.onChange(this.props.teamUsers.filter((existingTeamUser) => {
                return (existingTeamUser.userId !== removedTeamUser.userId);
            }));
        };

        if (this.props.teamUsers.length > 0) {
            if (this.state.filteredTeamUsers.length > 0) {
                return (
                    <>
                        {this.renderSearchBox()}
                        <MUIGrid container spacing={2}>
                            {this.state.filteredTeamUsers.map((teamUser) => {
                                return (
                                    <MUIGrid key={teamUser.userId} item xs={12} sm={6} md={4}>
                                        <TileUser
                                            user={this.context.usersIndexed[teamUser.userId]}
                                            iconButtons={[(<MUIIconButton onClick={() => handleRemoveTeamUser(teamUser)}><MUIDeleteIcon /></MUIIconButton>)]}
                                        />
                                    </MUIGrid>
                                );
                            })}
                            {this.renderDialog()}
                        </MUIGrid>
                    </>
                );
            } else {
                return (
                    <>
                        {this.renderSearchBox()}
                        <EmptyState
                            line1="No results found"
                            line2="Try searching for something a little different."
                        />
                        {this.renderDialog()}
                    </>
                );
            }
        } else {
            return (
                <>
                    <EmptyState
                        line1="No Members"
                        line2="Members added to this team will appear here"
                    />
                    {this.renderDialog()}
                </>
            );
        }
    }

    /**
     * Renders a search dialog of all users
     */
    renderDialog() {
        const handleAddTeamUser = (user) => {
            const duplicateTeamUsers = this.props.teamUsers.filter((teamUser) => {
                return teamUser.userId === user.id;
            });
            if (duplicateTeamUsers.length === 0) {
                // TODO: It would be cool to be able to show a snackbar here.
                // Was thinking a component needs access to the layer's snackbar
                // capabilities.
                this.props.onChange(
                    this.props.teamUsers.concat([{
                        teamId: this.props.team.id,
                        userId: user.id
                    }])
                );
            }
        };

        return (
            <DialogSearchUser
                open={this.state.teamUserDialogOpen}
                onClose={() => this.setState({ teamUserDialogOpen: false })}
                onSelect={(user) => { handleAddTeamUser(user); }}
            />
        );
    }

    /**
     * Renders add member button.
     */
    getActions() {
        const handleClick = () => {
            this.setState({
                teamUserDialogOpen: true,
            });
        };

        return [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIAddIcon />}
                onClick={handleClick}>Add Member</MUIButton>)
        ];
    }

    /**
     * Searches for team users using the standard user search.
     *
     * @param {string} query The search query.
     * @returns A list of filtered team users.
     */
    getFilteredTeamUsers(query) {
        const users = [];
        this.props.teamUsers.forEach((teamUser) => {
            users.push(this.context.usersIndexed[teamUser.userId]);
        });

        const filteredUsers = User.search(
            Object.values(users),
            query,
            true
        );

        const filteredTeamUsers = [];
        filteredUsers.forEach((user) => {
            // Nested loop not ideal, but set size is small enough.
            filteredTeamUsers.push(this.props.teamUsers.find((teamUser) => {
                return teamUser.userId === user.id;
            }));
        });

        return filteredTeamUsers;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Members";
    }

    /**
     * Render a search box.
     */
    renderSearchBox() {
        const handleChange = (e) => {
            this.setState({
                query: e.target.value,
                filteredTeamUsers: this.getFilteredTeamUsers(e.target.value)
            });
        };

        return (
            <MUITextField
                autoFocus={true}
                placeholder="Type to search..."
                hiddenLabel={true}
                fullWidth={true}
                variant="filled"
                size="small"
                onChange={handleChange}
                sx={{
                    marginBottom: 2,
                    "& .MuiInputLabel-root": { display: "none", height: 0 }
                }}
                InputProps={{
                    startAdornment: (
                        <MUIInputAdornment position="start">
                            <MUISearchIcon />
                        </MUIInputAdornment>
                    ),
                    disableUnderline: true,
                    style: { borderRadius: 4 }
                }}
            />
        );
    }
}