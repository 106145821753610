import Crud from "../Crud";
import MUICyanColor from "@mui/material/colors/cyan";

/**
 * Static helper functions related to MarketingCampaigns.
 */
export default class MarketingCampaign extends Crud {

    /**
     * @param {object} marketingCampaign The marketingCampaign object.
     * @returns The display name of the marketingCampaign.
     */
    static getDisplayName(marketingCampaign) {
        return marketingCampaign ? marketingCampaign.name : null;
    }

    /**
     * Determines if a marketingCampaign matches a search query.
     *
     * @param {object} marketingCampaign The marketingCampaign to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the marketingCampaign matches the query.
     */
    static match(marketingCampaign, query) {
        return (
            (marketingCampaign.name !== null && marketingCampaign.name.toLowerCase().includes(query)) ||
            marketingCampaign.id === +query
        );
    }

    /**
     * @param {array} marketingCampaigns The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(marketingCampaigns) {
        return marketingCampaigns.sort((a, b) => {
            if (a.isActive === false && b.isActive !== false) return 1;
            if (a.isActive !== false && b.isActive === false) return -1;
            return a.name.localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUICyanColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Campaign";
    }
}