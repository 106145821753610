import TermList from "../AdminTermList";

export default class AdminTermListDistributionType extends TermList {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return "distribution_type";
    }
}
