import api from "../api";

class PartsService {
    async getAllParts() {
        return new Promise((res, rej) => {
            return api.get("/allServiceParts")
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async getPartsByHcpcs(hcpcs) {
        return new Promise((res, rej) => {
            return api.get("/serviceParts/" + hcpcs)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async getServiceQuoteByOrder(orderId) {
        if (!orderId ) {
            return "";
        }
        return new Promise((res, rej) => {
            return api.get("/getServiceQuoteByOrder/" + orderId)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }
    async getServiceOrderProductsByOrder(orderId) {

        return new Promise((res, rej) => {
            return api.get("/serviceOrderProducts/" + orderId)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }



    async createPart(part) {
        return new Promise((res, rej) => {
            return api.post("/serviceParts", part)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async editPart(part) {
        return new Promise((res, rej) => {
            return api.put("/serviceParts", part)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async batchEditParts(parts) {
        return new Promise((res, rej) => {
            return api.put("/servicePartsBatch", parts)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async deletePart(id) {
        return new Promise((res, rej) => {
            return api.delete("/serviceParts/" + id)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async createQuote(orderId, items, shipTo) {
        return new Promise((res, rej) => {
            return api.post("/buildServiceQuotes/" + orderId + "?shipToPatient=" + shipTo, items)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async createRx(orderId, model) {
        return new Promise((res, rej) => {
            return api.post("/buildrx/" + orderId, model)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }
}

const partsService = new PartsService();

export default partsService;