import api from "../api";

class QueueService {

    // Temporary until we implement this in the API
    static getQueueCountsMemo = {};

    parseJSON(queues) {
        if (Array.isArray(queues) === false) {
            let parsedQueues = queues;
            if (parsedQueues.filter) {
                parsedQueues = { ...parsedQueues, ...{ filter: JSON.parse(parsedQueues.filter) } };
            }
            if (parsedQueues.view) {
                parsedQueues = { ...parsedQueues, ...{ view: JSON.parse(parsedQueues.view) } };
            }
            return parsedQueues;
        }
        return queues.map((queue) => {
            let parsedQueue = queue;
            if (parsedQueue.filter) {
                parsedQueue = { ...parsedQueue, ...{ filter: JSON.parse(parsedQueue.filter) } };
            }
            if (parsedQueue.view) {
                parsedQueue = { ...parsedQueue, ...{ view: JSON.parse(parsedQueue.view) } };
            }
            return parsedQueue;
        });
    }

    async getAllQueues() {
        return new Promise((res, reject) => {
            api
                .get("/allQueues")
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getQueueById(id) {
        return new Promise((res, reject) => {
            api
                .get("/queues/" + id)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createQueue(obj) {
        const queue = {
            ...obj,
            ...{
                filter: obj.filter ? JSON.stringify(obj.filter) : null,
                view: obj.view ? JSON.stringify(obj.view) : null
            }
        };
        return new Promise((res, reject) => {
            api
                .post("/queues", queue)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateQueue(obj) {
        const queue = {
            ...obj,
            ...{
                filter: obj.filter ? JSON.stringify(obj.filter) : null,
                view: obj.view ? JSON.stringify(obj.view) : null
            }
        };
        return new Promise((res, reject) => {
            api
                .put("/queues", queue)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteQueue(id) {
        return new Promise((res, rej) => {
            return api.delete('/queues/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            });
        });
    }

    async getQueueRows(args) {
        return new Promise((res, reject) => {
            api.post("/getQueueRows", args)
                .then((response) => {
                    res(response.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getQueueStatistics(id, args) {
        return new Promise((res, reject) => {
            api.get(`/queueStatistics/${id}`, args)
                .then((response) => {
                    res(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getQueueStatisticsMetrics(args) {
        return new Promise((res, reject) => {
            api.post(`/queueStatisticsMetrics`, args)
                .then((response) => {
                    res(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getQueueCounts(args) {
        return api
            .post("/getQueueCounts", args)
            .then((response) => {
                return response.data;
            });
    }

}

const queueService = new QueueService();

export default queueService;
