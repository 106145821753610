import React from "react";
import Card from "../../Card";
import MUILoadingButton from "@mui/lab/LoadingButton";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import ReportSettingDateRange from "../../ReportSetting/ReportSettingDateRange";
import ReportSettingMultiUserByLocation from "../../ReportSetting/ReportSettingMultiUserByLocation";
import ReportSettingDraggableList from "../../ReportSetting/ReportSettingDraggableList";
import Setting from "../../../Utility/Setting";
import MUIStack from "@mui/material/Stack";

export default class CardReportSettings extends Card {
    constructor(props) {
        super(props);

        this.state = {
            settings: Setting.get(this.generateSettingKey()) || {},
            validationIssues: {},
            showValidationIssues: false,
        };
    }

    generateSettingKey() {
        return window.location.pathname
            .replace(/^\/|\/$/g, '').replace(/\//g, '.') + '.settings';
    }

    saveSettings() {
        Setting.set(
            this.generateSettingKey(),
            this.state.settings
        )
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleSettingsChange = (key, settings, validationIssues) => {
            // Any time a setting component is created or the data changes, it
            // sends back the entire updated settings and validation objects.
            this.setState((prevState) => ({
                settings: {
                    ...prevState.settings,
                    [key]: settings,
                },
                validationIssues: {
                    ...prevState.validationIssues,
                    [key]: validationIssues,
                },
            }));
        };

        const settingComponents = {
            dateRange: ReportSettingDateRange,
            multiUserByLocation: ReportSettingMultiUserByLocation,
            draggableList: ReportSettingDraggableList,
            // Add more here...
        };

        return (
            <MUIStack direction="column" spacing={1}>
                {Object.keys(this.props.settingComponents).map((key) => {
                    const SettingComponent = settingComponents[key];
                    if (SettingComponent) {
                        return (
                            <SettingComponent
                                key={key}
                                settings={this.state.settings[key]}
                                grouping={this.props.settingComponents[key].grouping ? this.props.settingComponents[key].grouping : null}
                                allGroups={this.props.settingComponents[key].allGroups ? this.props.settingComponents[key].allGroups : null}
                                validationRules={this.props.settingComponents[key].validationRules}
                                validationIssues={this.state.validationIssues[key]}
                                showValidationIssues={this.state.showValidationIssues}
                                onSettingsChange={(settings, validationIssues) => {handleSettingsChange(key, settings, validationIssues)}}
                            />
                        );
                    }
                    return null;
                })}
            </MUIStack>
        )
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Settings";
    }

    /**
     * Renders add member button.
     */
    getActions() {
        const handleRun = () => {
            this.setState({ showValidationIssues: true });
            if (Object.values(this.state.validationIssues).some(object => Object.keys(object).length > 0)) {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            } else {
                this.saveSettings();
                this.props.onRun(this.state.settings);
            }
        };

        return [
            (<MUILoadingButton
                loading={this.props.isLoading}
                component="label"
                variant="contained"
                onClick={handleRun}
            >
                Run
            </MUILoadingButton>),
        ];
    }
}