import api from "../api";

class ComplaintService {
    //region issue categories
    async getAllIssueCategories() {
        return new Promise((res, reject) => {
            api
                .get("/issueCategories")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getIssueCategory(id) {
        return new Promise((res, reject) => {
            api
                .get("/issueCategories/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createIssueCategory(category) {
        return api.post("/issueCategories", category);
    }

    async updateIssueCategory(category) {
        return new Promise((res, reject) => {
            api
                .put("/issueCategories", category)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteIssueCategory(id) {
        return new Promise((res, reject) => {
            api
                .delete("/issueCategories/" + id)
                .then((data) => {
                    res();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //endregion

    //region issue reasons
    async getAllIssueReasons() {
        return new Promise((res, reject) => {
            api
                .get("/issueReasons")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getIssueReason(id) {
        return new Promise((res, reject) => {
            api
                .get("/issueReasons/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createIssueReason(reason) {
        return api.post("/issueReasons", reason);
    }

    async updateIssueReason(reason) {
        return new Promise((res, reject) => {
            api
                .put("/issueReasons", reason)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteIssueReason(id) {
        return new Promise((res, reject) => {
            api
                .delete("/issueReasons/" + id)
                .then((data) => {
                    res();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //endregion

    //region patient issues
    async getAllPatientIssues() {
        return new Promise((res, reject) => {
            api
                .get("/patientIssues")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatientIssuesForPatient(patientId) {
        return new Promise((res, reject) => {
            api
                .get("/patientIssues/" + patientId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createPatientIssue(issue) {
        return new Promise((res, reject) => {
            api
                .post("/patientIssues", issue)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updatePatientIssue(issue) {
        return new Promise((res, reject) => {
            api
                .put("/patientIssues", issue)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    //endregion

    //region patient issue notes
    async createPatientIssueNote(note) {
        return new Promise((res, reject) => {
            return api
                .post("/issueNotes", note)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatientNotesByIssue(issueId) {
        return new Promise((res, reject) => {
            return api
                .get("/issueNotesPID/" + issueId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    //endregion

    // calls proc spGetComplaints
    async getComplaintsViewNative(filters) {
        return new Promise((res, reject) => {
            api
                .post("/complaintsView", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const complaintService = new ComplaintService();

export default complaintService;
