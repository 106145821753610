import React from "react";
import {
    Button,
    Input,
    MDBBtn,
    MDBCloseIcon,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOption,
    MDBSelectOptions,
    MDBSpinner,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    toast,
    Row,
    Col,
    MDBModalFooter,
    MDBCollapseHeader,
    MDBCollapse,
} from "mdbreact";
import moment from "moment";
import MUIFormLabel from "@mui/material/FormLabel";
import MUIFormControl from "@mui/material/FormControl";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
import MUIRadio from "@mui/material/Radio";
import MUIRadioGroup from "@mui/material/RadioGroup";
import MUIAutoComplete from "@mui/material/Autocomplete";
import MUIAlert from '@mui/material/Alert';
import MUIButton from '@mui/material/Button';
import MUIButtonGroup from '@mui/material/ButtonGroup';
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import googleService from "../../Security/GoogleService/GoogleService";
import MUIMenuItem from "@mui/material/MenuItem";
import MUITextField from "@mui/material/TextField";
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUIIconButton from "@mui/material/IconButton";
import MUIClearIcon from "@mui/icons-material/Clear";
import InputMask from "react-input-mask";
import {EditorState, ContentState} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw } from 'draft-js';
import GlobalFunctions from "../../Filters/GlobalFunctions";
import InsuranceService from "../../Security/InsuranceService/insuranceService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactDOMServer from 'react-dom/server';
import Select from "react-select";
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import DialogPatientIssueAlert from "../../../Component/Dialog/DialogPatientIssueAlert";
import Location from "../../../Utility/Crud/Location";
import Term from "../../../Utility/Crud/Term";

export default class PatientInformationTab extends React.Component {
    constructor(props) {
        super(props);

        this.updateParentObject = props.updateParentObject;
        this.saveParentObject = props.saveParentObject;
        this.updateTableRows = props.updateTableRows;
        this.toggleNoteModal = props.toggleNoteModal;
        this.setNoteText = props.setNoteText;
        this.toggleShouldVerifyInsurance = props.toggleShouldVerifyInsurance;

        this.state = {
            eOrder: props.eOrder,
            searchResults: [],
            insuranceResults: [],
            mostRecentInsuranceResults: {
                pvPrimary: null,
                pvSecondary: null,
                pvSameSim: null,
                pvSnf: null,
                pvSameSimK: null,
                pvSameSimE: null
            },
            allowOverride: false,
            shouldVerifyInsurance: props.shouldVerifyInsurance,
            googleModal: false,
            insuranceModal: false,
            collapseModal: false,
            insuranceTypes: [],
            secondaryInsuranceTypes: [],
            insuranceSubTypes:[],
            secondaryInsuranceSubTypes: [],
            filteredPrimaryInsuranceSubTypes: [],
            filteredSecondaryInsuranceSubTypes: [],
            showAlertPatientIssueDialogOpen: false,
            detailsModalOpen: false,
            popupComplaints: false,
            editorState: EditorState.createEmpty(),
            contentState: {},
            subInsDetails: "",
            collapse: [],

            coverageDetails: null,

            activeInsuranceTab: "1",

            msgColor: 'green',
            insuranceError: null,
            refreshCoverageDisabled: false,

            sameSimClaims: [],
            snfDetails: [],
            sameSnfModal: false,


        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.eOrder !== prevState.eOrder || nextProps.shouldVerifyInsurance !== prevState.shouldVerifyInsurance) {
            return {
                eOrder: nextProps.eOrder,
                shouldVerifyInsurance: nextProps.shouldVerifyInsurance
            };
        } else return null;
    }

    static contextTypes = {
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        currentUser: PropTypes.object,
        stateSalesTax: PropTypes.array,
        issueReasonsIndexed: PropTypes.object,
        locationsIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
    };

    componentDidMount() {
        this.setInsuranceTypeLists();
        this.checkAndOpenDialogPatientIssueAlert();
        this.viewInsurance();
    }

    componentDidUpdate() {
        if (this.state.shouldVerifyInsurance) {
            this.toggleShouldVerifyInsurance();
            this.checkAndAutoVerifyInsurance();
        }

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.shouldVerifyInsurance !== this.state.shouldVerifyInsurance) {
            this.setState({
                shouldVerifyInsurance: nextProps.shouldVerifyInsurance,
            });
        }
    }
    getSubInsuranceDetails(id) {
        InsuranceService.findAllSubInsuranceDetailsBySubTypeId(id).then(r => {
            let fromjs = JSON.parse(r.details);
            let from = convertFromRaw(fromjs);


            let cb = ContentState.createFromBlockArray(from.getBlocksAsArray());

            let cs = EditorState.createWithContent(cb);


            this.setState({
                editorState: cs,
                detailsModalOpen: true
            });

        }).catch(e => {
            toast.warn("No Insurance Details in system");
        });

    }

    // region Google address search

    clearSearch() {
        this.setState({searchResults: []});
    }

    addressTextChanged(e) {
        let val = e.target.value,
            o = Object.assign({}, this.state.eOrder),
            p = Object.assign({}, o.patient);
        p.address = val;
        o.patient = p;
        this.setState({
            eOrder: o,
        });

        // let geocoderResultNode = document.getElementById('geocoderResult');
        // if (val) {
        //     this.setTimer(val)
        // } else {
        //     this.setState({searchResults: []});
        //     if (geocoderResultNode) {
        //         geocoderResultNode.innerHTML = ''
        //     }
        // }
    }

    setTimer(val) {
        if (this.state.timer) {
            clearInterval(this.state.timer);
        }
        let t = setInterval(() => {
            this.geoGoogle(val);
        }, 1000);
        this.setState({
            timer: t,
        });
    }

    geoGoogle(val) {
        if (val) {
            googleService.searchAddress(val).then((res) => {
                this.setState({searchResults: res.results});
            });
        }
    }

    renderGoogleSearchResult() {
        let results = this.state.searchResults;

        if (results.length > 0) {
            return (
                <div className="resultWrap">
                    {/*<div style={{width: '100%'}}>*/}
                    {/*    <button id="popup-closer" className="ol-popup-closer" onClick={this.clearSearch.bind(this)}/>*/}
                    {/*</div>*/}
                    {/*<br/>*/}
                    <ul id="geocoderResult">
                        {results.map((item, index) => {
                            if (
                                index < 4 &&
								item.formatted_address &&
								item.formatted_address.length > 0
                            ) {
                                return (
                                    <li key={results.indexOf(item)}>
                                        <button
                                            style={{
                                                border: "0",
                                                backgroundColor: "transparent",
                                                color: "#3887BE",
                                            }}
                                            onClick={this.setGoogleAddress.bind(this, item)}
                                        >
                                            {" "}
                                            {item.formatted_address}{" "}
                                        </button>
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ul>
                    <div className="loading hidden">
                        <img
                            src="https://samples.thinkgeo.com/cloud/example/image/Spinner-1s-50px.gif"
                            alt="loading"
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    toggleGoogleSearch = () => {
        this.setState((state, props) => ({
            googleModal: !state.googleModal,
            searchResults: [],
        }));
    };

    toggleInsuranceModal = () => {
        this.setState((state, props) => ({
            insuranceModal: !state.insuranceModal,
            insuranceError: null,
            allowOverride: false
            // searchResults: [],
        }));
    };

    toggleCollapseModal = () => {
        this.setState((state, props) => ({
            collapseModal: !state.collapseModal,
            collapse: [],
        }));
    };

    googleAddressTextChanged(e) {
        let val = e.target.value,
            geocoderResultNode = document.getElementById("geocoderResult");
        if (val) {
            this.setTimer(val);
        } else {
            this.setState({searchResults: []});
            if (geocoderResultNode) {
                geocoderResultNode.innerHTML = "";
            }
        }
    }

    setGoogleAddress = (location) => {
        let order = this.state.eOrder,
            patient = order.patient;

        let addr = location.address_components;
        let numComp = "",
            streetComp = "";
        addr.forEach((item) => {
            if (item.types.includes("street_number")) {
                numComp = item.long_name;
            } else if (item.types.includes("route")) {
                streetComp = item.long_name;
            } else if (
                item.types.includes("neighborhood") ||
                item.types.includes("locality")
            ) {
                patient.city = item.long_name;
            } else if (item.types.includes("administrative_area_level_1")) {
                patient.state = item.long_name;
            } else if (item.types.includes("postal_code")) {
                patient.zipCode = item.long_name;
            }
        });

        patient.address = numComp + " " + streetComp;
        patient.latitude = location.geometry.location.lat;
        patient.longitude = location.geometry.location.lng;
        order.patient = patient;
        this.setState({eOrder: order, searchResults: []});
        this.toggleGoogleSearch();
    };

    getStateList() {
        const { stateSalesTax } = this.context;
        let lst = [];
        stateSalesTax.forEach(x => {
            lst.push({
                label: x.name + " - " + x.abbr,
                value: x.abbr,
            });
        });
        return lst;
    }

    // endregion

    approvalAndUpdate(prop, val) {
        let eOrder = this.state.eOrder;
        eOrder.orderActivityApprovals[0].approvals[prop] = val;
        this.setState({eOrder: eOrder});
        this.updateParentObject(eOrder);
    }

    setAndUpdate(prop, val) {
        let eOrder = this.state.eOrder;

        if (prop === "mbi") {
            val = val.toUpperCase();
        }

        if (eOrder.hasOwnProperty(prop)) {
            eOrder[prop] = val;
        }

        if (eOrder.patient.hasOwnProperty(prop)) {
            eOrder.patient[prop] = val;
        }

        this.setState({eOrder: eOrder});
        this.updateParentObject(eOrder);
    }

    setClinicalInfo(prop, val) {
        let eOrder = this.state.eOrder;


        if (eOrder.orderActivityClinicalInfo === null) {
            eOrder.orderActivityClinicalInfo = {
                orderId: eOrder.id,
                name: eOrder.patient.ciName || "",
                address: eOrder.patient.ciAddress || "",
                city: eOrder.patient.ciCity || "",
                state: eOrder.patient.ciState || "",
                zip: eOrder.patient.ciZip || "",
                phone: eOrder.patient.ciPhone || "",
                fax: eOrder.patient.ciFax || "",
                npi: eOrder.patient.ciNpi || "",
            };
        }

        eOrder.orderActivityClinicalInfo[prop] = val;

        this.updateParentObject(eOrder);
    }

    setInsuranceTypeLists() {
        const { insuranceTypes } = this.context;

        let a = [];

        insuranceTypes.map((t) => {
            let obj = {
                name: t.name,
                id: parseInt(t.id),
            };
            return a.push(obj);
        });

        return a;
    }

    filterInsuranceSubTypes(secondary) {
        const {insuranceSubTypes} = this.context;
        let eOrder = this.state.eOrder,
            ary = [];

        if (!eOrder.insuranceType) {
            return;
        }

        insuranceSubTypes.map((t) => {
            let einst = secondary
                ? eOrder.secondaryInsuranceType
                : eOrder.insuranceType;

            if (t.insuranceType.id === einst.id) {
                let obj = {
                    text: t.name,
                    value: {
                        name: t.name,
                        id: parseInt(t.id),
                    },
                };

                if (secondary) {
                    if (
                        eOrder.secondaryInsuranceSubType &&
                        eOrder.secondaryInsuranceSubType.id === t.id
                    ) {
                        obj.checked = true;
                    }
                } else if (
                    eOrder.insuranceSubType &&
                    eOrder.insuranceSubType.id === t.id
                ) {
                    obj.checked = true;
                }
                ary.push(obj);
            }
            return null;
        });

        if (secondary) {
            this.setState({
                filteredSecondaryInsuranceSubTypes: ary,
            });
        } else {
            this.setState({
                filteredPrimaryInsuranceSubTypes: ary,
            });
        }
    }

    changeInsuranceType(type, secondary) {
        let eOrder = this.state.eOrder,
            nv = null;

        if (type) {
            nv = {name: type.name, id: type.id};
        }

        if (secondary === true) {
            eOrder.secondaryInsuranceTypeId = nv.id;
            eOrder.secondaryInsuranceSubTypeId = null;
            eOrder.secondaryInsuranceType = nv;
            eOrder.secondaryInsuranceSubType = null;
        } else {
            eOrder.insuranceTypeId = nv.id;
            eOrder.insuranceSubTypeId = null;
            eOrder.insuranceType = nv;
            eOrder.insuranceSubType = null;
        }

        this.updateTableRows("insuranceChange");
        this.setState({eOrder: eOrder});
        this.updateParentObject(eOrder);
    }

    changeInsuranceSubType(type, secondary) {
        let eOrder = this.state.eOrder,
            nv = null;

        if (type) {
            nv = {name: type.name, id: type.id};
        }

        if (secondary === true && type) {
            eOrder.secondaryInsuranceSubType = nv;
            eOrder.secondaryInsuranceSubTypeId = nv.id;
        } else if (secondary === true && !type) {
            eOrder.secondaryInsuranceSubType = null;
            eOrder.secondaryInsuranceSubTypeId = null;
        } else {
            eOrder.insuranceSubType = nv;
            eOrder.insuranceSubTypeId = nv.id;
        }

        console.log(eOrder)

        this.setState({eOrder: eOrder});
        this.updateParentObject(eOrder);
    }

    renderInfoSection() {
        let eOrder = this.state.eOrder;
        let types = [
            "Male",
            "Female",
            "Other"
        ];

        return (
            <div style={{ border: "1px solid #7986cb", margin: 8, padding: 15 }} id="patientBasicInfo">
                <MDBRow>
                    <label className={"demographicsLabel labelPosition"}>Basic Info</label>

                    <MDBCol size={"3"}>
                        <MDBInput
                            label={"First Name"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.patient.firstName}
                            outline
                            onChange={(e) => this.setAndUpdate("firstName", e.target.value)}
                        />
                    </MDBCol>

                    <MDBCol size={"3"}>
                        <MDBInput
                            label={"Middle Name"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.patient.middleName}
                            outline
                            onChange={(e) => this.setAndUpdate("middleName", e.target.value)}
                        />
                    </MDBCol>

                    <MDBCol size={"4"}>
                        <MDBInput
                            label={"Last Name"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.patient.lastName}
                            outline
                            onChange={(e) => this.setAndUpdate("lastName", e.target.value)}
                        />
                    </MDBCol>

                    <MDBCol size={"2"}>
                        <MDBInput
                            label={"Suffix"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.patient.suffix}
                            outline
                            onChange={(e) => this.setAndUpdate("suffix", e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{marginTop: 8, marginBottom: 8}}>
                    <MDBCol size="3">
                        <MDBInput
                            type={"number"}
                            label={"Height (Inches)"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.patient.height}
                            outline
                            onChange={(e) => this.setAndUpdate("height", e.target.value ? parseInt(e.target.value) : null)}
                        />
                    </MDBCol>

                    <MDBCol size="3">
                        <MDBInput
                            label={"Weight (Pounds)"}
                            type="number"
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.patient.weight}
                            outline
                            onChange={(e) => this.setAndUpdate("weight", e.target.value ? parseInt(e.target.value) : null)}
                        />
                    </MDBCol>

                    <MDBCol size="2">
                        <MUITextField
                            select
                            size="small"
                            fullWidth
                            label="Gender"
                            value={eOrder.patient.gender || ""}
                            onChange={(e) => this.setAndUpdate("gender", e.target.value)}
                            variant="outlined"
                            InputProps={{
                                sx: {
                                    height: '44px', // Set the height
                                },
                            }}
                        >
                            {types.map((option, idx) => {
                                return (
                                    <MUIMenuItem key={idx} value={types[idx]}>
                                        {option}
                                    </MUIMenuItem>
                                );
                            })}
                        </MUITextField>
                    </MDBCol>

                    <MDBCol size={"4"}>
                        {this.renderDateOfBirth(eOrder)}
                    </MDBCol>
                </MDBRow>
                {this.renderAddressSection()}
            </div>
        );
    }

    renderDateOfBirth(eOrder) {
        const isPediatric = (dateOfBirth) => {
            const dob = dateOfBirth;
            const age = moment().diff(dob, 'years');
            return age < 18;
        };

        return (
            <MUIDatePicker
                label={isPediatric(eOrder.patient.dateOfBirth) ? "DOB - Pediatric" : "Date of Birth"}
                value={eOrder.patient.dateOfBirth ? moment(eOrder.patient.dateOfBirth) : null}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        size: "small",
                        variant: 'outlined',
                        InputLabelProps: {
                            shrink: true
                        },
                        InputProps: {
                            sx: {
                                height: '44px', // Match the height of the other inputs
                            },
                        },
                    },
                }}
                onChange={(value) => this.setAndUpdate("dateOfBirth", value)}
                views={['year', 'month', 'day']}
            />
        );
    }

    toCamel(obj) {
        var newO,
            origKey,
            newKey,
            value;
        if (obj instanceof Array) {
            return obj.map(value => {
                if (typeof value === "object") {
                    value = this.toCamel(value);
                }
                return value;
            });
        }
        else {
            newO = {};
            for (origKey in obj) {
                if (obj.hasOwnProperty(origKey)) {
                    if (["HCPCS"].includes(origKey))
                        newKey = origKey.toLowerCase();
                    else if (origKey.toLowerCase() === "dosend")
                        newKey = "dosEnd";
                    else if (origKey.toLowerCase() === "dosstart")
                        newKey = "dosStart";
                    else if (origKey.toLowerCase() === "apiresponsecode")
                        newKey = "apiResponseCode";
                    else if (origKey.toLowerCase() === "apiresponsemessage")
                        newKey = "apiResponseMessage";
                    else
                        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                    value = obj[origKey];
                    if (value instanceof Array || (value !== null && typeof value === "object")) {
                        value = this.toCamel(value);
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }

    isJSONParsable(value) {
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            return false;
        }
    };

    viewInsurance() {

        let eOrder = this.state.eOrder,
            apiRes = eOrder.apiResults || [];

        apiRes = apiRes.filter((res) =>
            this.isJSONParsable(res.stringResult)
        );



        let primary = null,
            primary2 = null,
            secondary = null,
            secondary2 = null,
            sameSim = [],
            snf = null,
            //availityPrimary = false,
            pVerifyPrimary = true;
        let mostRecentInsuranceResults = {
            pvSameSimK: null,
            pvSameSimE: null,
            pvPrimary: null,
            pvSecondary: null,
            pvSameSim: null,
            pvSnf: null,
        };

        //if (apiRes && apiRes.length > 1 && apiRes.filter(x => x.resultType === "Primary").length > 1) {
        //	var srs = apiRes.filter(x => x.resultType === "Primary").map(x => x.source);
        //	srs = srs.reverse()

        //	if (srs.filter(x => x.toLowerCase() === "pverify").length > 0) {
        //		//availityPrimary = false;
        //		pVerifyPrimary = true;
        //          }
        //	else if (srs.length > 1 && srs[0] !== srs[1]) {
        //		//availityPrimary = srs[0].toLowerCase() === "availity";
        //		pVerifyPrimary = srs[0].toLowerCase() === "pverify";
        //	}
        //	else if (srs.length === 1) {
        //		//availityPrimary = srs[0].toLowerCase() === "availity";
        //		pVerifyPrimary = srs[0].toLowerCase() === "pverify";
        //	}
        //}
        //else if (apiRes && apiRes.length > 0 && apiRes.filter(x => x.resultType === "Primary").length === 1) {
        //	availityPrimary = apiRes[0].source.toLowerCase() === "availity";
        //	pVerifyPrimary = apiRes[0].source.toLowerCase() === "pverify";
        //}

        let prims = apiRes && apiRes.length > 0 && apiRes.filter(x => x.resultType === "Primary" && x.source === "Availity").length > 0 ? apiRes.filter(x => x.resultType === "Primary" && x.source === "Availity") : null;
        if (prims && prims.length > 0) {
            if (prims.length > 1) {
                prims = prims.sort((a, b) => {
                    if (new Date(a.createDate) > new Date(b.createDate))
                        return -1;
                    else if (new Date(a.createDate) === new Date(b.createDate))
                        return 0;
                    else
                        return 1;
                });
            }
            primary = prims[0].stringResult;
        }

        let secs = apiRes && apiRes.length > 0 && apiRes.filter(x => x.resultType === "Secondary" && x.source === "Availity").length > 0 ? apiRes.filter(x => x.resultType === "Secondary" && x.source === "Availity") : null;
        if (secs && secs.length > 0) {
            if (secs.length > 1) {
                secs = secs.sort((a, b) => {
                    if (new Date(a.createDate) > new Date(b.createDate))
                        return -1;
                    else if (new Date(a.createDate) === new Date(b.createDate))
                        return 0;
                    else
                        return 1;
                });
            }
            secondary = secs[0].stringResult;
        }

        // pVerify sameSim and SNF - only grab the latest one
        let sameSimResults = apiRes && apiRes.length > 0 && apiRes.filter(x => x.resultType === "SameSim" && x.stringResult !== null && x.stringResult.indexOf('"IsDataError": true') < 0).length > 0 ? apiRes.filter(x => x.resultType === "SameSim" && x.stringResult !== null && x.stringResult.indexOf('"IsDataError": true') < 0) : null;
        if (sameSimResults && sameSimResults.length > 0) {
            if (sameSimResults.length > 1) {
                sameSimResults = sameSimResults.sort((a, b) => {
                    if (new Date(a.createDate) > new Date(b.createDate))
                        return -1;
                    else if (new Date(a.createDate) === new Date(b.createDate))
                        return 0;
                    else
                        return 1;
                });
            }

            var SameSimCreateDate = new Date(sameSimResults[0].createDate);
            try {
                if (sameSimResults && sameSimResults.length > 1) {
                    sameSimResults.map(x => {
                        var ds = new Date(x.createDate);
                        if (ds.getFullYear() === SameSimCreateDate.getFullYear() &&
                            ds.getMonth() === SameSimCreateDate.getMonth() &&
                            ds.getDate() === SameSimCreateDate.getDate() &&
                            ds.getHours() === SameSimCreateDate.getHours() &&
                            ds.getMinutes() - SameSimCreateDate.getMinutes() < 2) {
                            return sameSim.push(x.stringResult);
                        }
                        return null;
                    });
                }
                else {
                    sameSim.push(sameSimResults[0].stringResult);
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        let sameSimE = [];
        let sameSimK = [];
        if (sameSimResults && sameSimResults.length > 0) {
            sameSimResults.map(sameSimRes => {
                if (sameSimRes.stringResult.includes('"hcpcs": "K')) {
                    return sameSimK.push(sameSimRes.stringResult)
                } else if (sameSimRes.stringResult.includes('"hcpcs": "E')) {
                    return sameSimE.push(sameSimRes.stringResult)
                }
            })
        }
        mostRecentInsuranceResults.pvSameSimK = sameSimK ? sameSimK[0] : null;
        mostRecentInsuranceResults.pvSameSimE = sameSimE ? sameSimE[0] : null;
        mostRecentInsuranceResults.pvSameSim = sameSimResults ? sameSimResults[0] : null;


        let sn = apiRes && apiRes.length > 0 && apiRes.filter(x => x.resultType === "SNF" && x.stringResult !== null && x.stringResult.indexOf('"IsDataError": true') < 0).length > 0 ? apiRes.filter(x => x.resultType === "SNF" && x.stringResult !== null && x.stringResult.indexOf('"IsDataError": true') < 0) : null;
        if (sn && sn.length > 0) {
            if (sn.length > 1) {
                sn = sn.sort((a, b) => {
                    if (new Date(a.createDate) > new Date(b.createDate))
                        return -1;
                    else if (new Date(a.createDate) === new Date(b.createDate))
                        return 0;
                    else
                        return 1;
                });
            }
            snf = sn[0].stringResult;
            mostRecentInsuranceResults.pvSnf = sn ? sn[0] : null;

        }

        let prims2 = apiRes && apiRes.length > 0 && apiRes.filter(x => x.resultType === "Primary" && x.source === "pVerify").length > 0 ? apiRes.filter(x => x.resultType === "Primary" && x.source === "pVerify") : null;
        if (prims2 && prims2.length > 0) {
            if (prims2.length > 1) {
                prims2 = prims2.sort((a, b) => {
                    if (new Date(a.createDate) > new Date(b.createDate))
                        return -1;
                    else if (new Date(a.createDate) === new Date(b.createDate))
                        return 0;
                    else
                        return 1;
                });
            }
            if (prims2[0].stringResult)
                mostRecentInsuranceResults.pvPrimary = prims2 ? prims2[0] : null;
            primary2 = prims2[0].stringResult.replace(/\r\n/g, "");
        }

        let secs2 = apiRes && apiRes.length > 0 && apiRes.filter(x => x.resultType === "Secondary" && x.source === "pVerify").length > 0 ? apiRes.filter(x => x.resultType === "Secondary" && x.source === "pVerify") : null;
        if (secs2 && secs2.length > 0) {
            if (secs2.length > 1) {
                secs2 = secs2.sort((a, b) => {
                    if (new Date(a.createDate) > new Date(b.createDate))
                        return -1;
                    else if (new Date(a.createDate) === new Date(b.createDate))
                        return 0;
                    else
                        return 1;
                });
            }
            if (secs2[0].stringResult)
                mostRecentInsuranceResults.pvSecondary = secs2 ? secs2[0] : null;

            secondary2 = secs2[0].stringResult.replace(/\r\n/g, "");
        }

        let y = {
            "availity": {
                "secondary": secondary,
                "primary": primary,
                "isPrimary": false,
            },
            "pVerify": {
                "sameSim": sameSim,
                "snf": snf,
                "primary": primary2,
                "secondary": secondary2,
                "isPrimary": pVerifyPrimary,
                "sameSimK": sameSimK,
                "sameSimE": sameSimE
            }
        };

        let t = JSON.stringify({
            "availity": {
                "secondary": JSON.parse(secondary),
                "primary": JSON.parse(primary),
                "isPrimary": false,
            },
            "pVerify": {
                "sameSim": sameSim && sameSim.length > 0 ? sameSim.map(x => JSON.parse(x)) : [], //JSON.parse(sameSim),
                "snf": JSON.parse(snf),
                "primary": primary2 ? JSON.parse(primary2) : null,
                "secondary": secondary2 ? JSON.parse(secondary2) : null,
                "isPrimary": pVerifyPrimary,
                "sameSimK": sameSimK && sameSimK.length > 0 ? sameSimK.map(x => JSON.parse(x)) : [],
                "sameSimE": sameSimE && sameSimE.length > 0 ? sameSimE.map(x => JSON.parse(x)) : [],
            }
        });

        this.setState({
            mostRecentInsuranceResults: mostRecentInsuranceResults,
            insuranceResults: y,
            jsonResults: t,
        });
    }

    getInsuranceSubType(isSecondary) {
        let eOrder = this.state.eOrder,
            id = null;

        if (isSecondary) {
            id = eOrder.secondaryInsuranceSubType ? eOrder.secondaryInsuranceSubType.id : null;
        } else {
            id = eOrder.insuranceSubType ? eOrder.insuranceSubType.id : null;
        }

        if (id != null) {
            this.getSubInsuranceDetails(id);
        }

    }

    renderEmergencyRelationshipSelect() {
        let eOrder = this.state.eOrder,
            patient = eOrder.patient,
            types = [
                "Spouse/Partner",
                "Sibling",
                "Child",
                "Parent",
                "Aunt/Uncle",
                "Other",
            ];

        return (
            <MDBCol size="12" className={"uiDateOutline smallMargin"}>
                <MUITextField
                    id="outlined-Primary-Insurance"
                    select
                    size={"small"}
                    fullWidth
                    label="Contact Relationship"
                    value={eOrder.emergencyRelationship || patient.emergencyRelationship}
                    onChange={(e) =>
                        this.setAndUpdate("emergencyRelationship", e.target.value)
                    }
                    variant="outlined"
                >
                    {types.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={types[idx]}>
                                {option}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </MDBCol>
        );
    }

    renderContactSection() {
        let eOrder = this.state.eOrder,
            patient = eOrder.patient;

        return (
            <div style={{border: "1px solid #7986cb", margin: 8, padding: 15}} id="patientContactInfo">
                <label className={"tabContactInfoLabel labelPosition"}>Contact Info</label>
                <MDBRow>
                    <MDBCol size={"12"}>
                        <InputMask
                            mask="(999)-999-9999"
                            value={eOrder.phone || patient.phone}
                            onChange={(e) => this.setAndUpdate("phone", e.target.value)}
                        >
                            {(inputProps) => (
                                <MDBInput
                                    outline
                                    containerClass={"smallMargin"}
                                    {...inputProps}
                                    label="Phone"
                                    style={{paddingTop: 8}}
                                />
                            )}
                        </InputMask>
                    </MDBCol>

                    <MDBCol size={"12"}>
                        <MDBInput
                            outline
                            label={"Alternative Phone"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={patient.alternativePhone}
                            onChange={(e) =>
                                this.setAndUpdate("alternativePhone", e.target.value)
                            }
                        />
                    </MDBCol>

                    <MDBCol size={"12"}>
                        <MDBInput
                            outline
                            containerClass={"smallMargin"}
                            label={"Email"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.email || patient.email}
                            onChange={(e) => this.setAndUpdate("email", e.target.value)}
                        />
                    </MDBCol>

                    <MDBCol size="12">
                        <MDBInput
                            label={"Emergency Contact"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.emergencyContact || patient.emergencyContact}
                            outline
                            onChange={(e) =>
                                this.setAndUpdate("emergencyContact", e.target.value)
                            }
                        />
                    </MDBCol>

                    <MDBCol size="12">
                        <MDBInput
                            label={"Emergency Contact Phone"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={
                                eOrder.emergencyContactPhone || patient.emergencyContactPhone
                            }
                            outline
                            onChange={(e) =>
                                this.setAndUpdate("emergencyContactPhone", e.target.value)
                            }
                        />
                    </MDBCol>

                    {this.renderEmergencyRelationshipSelect()}
                </MDBRow>
            </div>
        );
    }

    renderAddressSection() {
        let eOrder = this.state.eOrder,
            patient = eOrder.patient;

        return (
            <div>
                <MDBRow>
                    <MDBCol size={"2"}>
                        <MDBBtn
                            floating
                            data-tip={"Edit Address"}
                            style={{marginTop: 0, marginLeft: 20}}
                            size={"sm"}
                            onClick={this.toggleGoogleSearch}
                        >
                            <MDBIcon icon="map-marked-alt"/>
                        </MDBBtn>
                    </MDBCol>

                    <MDBCol size={"10"}>
                        <MDBInput
                            label={"Address"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.address || patient.address}
                            outline
                            onChange={(e) => this.setAndUpdate("address", e.target.value)}
                        />
                    </MDBCol>

                    <MDBCol size={"5"}>
                        <MDBInput
                            label={"City"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.city || patient.city}
                            outline
                            onChange={(e) => this.setAndUpdate("city", e.target.value)}
                        />
                    </MDBCol>

                    <MDBCol size={"4"}>
                        <Select
                            placeholder={eOrder.state || "Select State..."}
                            options={this.getStateList()}
                            onChange={(e) => this.setAndUpdate("state", e.value)}
                        />
                    </MDBCol>

                    <MDBCol size={"3"}>
                        <MDBInput
                            label={"ZIP Code"}
                            containerClass={"smallMargin"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.zipCode || patient.zipCode}
                            outline
                            onChange={(e) => this.setAndUpdate("zipCode", e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderDiseaseSection() {
        let eOrder = this.state.eOrder,
            patient = eOrder.patient;

        return (
            <div
                style={{
                    border: "1px solid #7986cb",
                    margin: 8,
                    padding: 15,
                    marginTop: 20,
                }}
                id="patientDiseaseInfo"
            >
                <label className={"tabDiseaseInfoLabel labelPosition"}>Disease Info</label>

                <MDBRow>
                    <MDBCol size="3">
                        <MDBInput
                            label={"Infectious Disease"}
                            containerClass={"smallMargin"}
                            value={
                                eOrder.hasInfectiousDisease || patient.hasInfectiousDisease
                            }
                            checked={
                                eOrder.hasInfectiousDisease || patient.hasInfectiousDisease
                            }
                            data-tip={"Priority"}
                            type="checkbox"
                            onChange={(e) =>
                                this.setAndUpdate(
                                    "hasInfectiousDisease",
                                    e.target.checked
                                )
                            }
                            filled
                            id="diseaseCheckbox"
                        />
                    </MDBCol>

                    <MDBCol size="9">
                        <MDBInput
                            outline
                            containerClass={"smallMargin"}
                            label={"Disease Notes"}
                            style={{ paddingTop: 8 }}
                            valueDefault={eOrder.diseaseNotes || patient.diseaseNotes}
                            onChange={(e) => this.setAndUpdate("diseaseNotes", e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderInsuranceSync(){
        let order = this.state.eOrder;

        //if (insurance && insurance.payorId && order.salesLocation && order.salesLocation.npi) {
        //if (order.apiResults && order.apiResults.length > 0) {
        if ((order.insuranceSubType && order.insuranceSubType.pvPayorId) || (order.secondaryInsuranceSubType && order.secondaryInsuranceSubType.pvPayorId)) {
            return (
                <Button onClick={() => this.toggleInsuranceModal()} style={{ right: "30px", top: "10px", position: "absolute", padding: "2px 5px" }} color={"primary"}>View Verification Info</Button>
            );
        }
    }

    renderPrimarySection() {
        const { insuranceTypes, insuranceSubTypes } = this.context;

        let t = this;

        let eOrder = this.state.eOrder,
            sAry = insuranceSubTypes,
            sIndex = null;

        let index = insuranceTypes.find((it) => {
            if (eOrder.insuranceType) {
                return it.id === eOrder.insuranceType.id;
            }
            return null;
        });

        if (eOrder.insuranceType) {
            sAry = insuranceSubTypes.filter((ist) => {
                return ist.insuranceType.id === eOrder.insuranceType.id;
            });

            if (eOrder.insuranceSubType) {
                sIndex = sAry.find((sit) => {
                    return sit.id === eOrder.insuranceSubType.id;
                });
            }
        }

        function renderInsuranceType() {
            if (insuranceTypes.length < 1) {
                return (
                    <MDBCol style={{textAlign: 'center'}} size={12}>
                        <MDBSpinner small/>
                    </MDBCol>
                );
            }

            return (
                <MDBCol size="12" className={"uiOutlines"}>
                    <MUITextField
                        id="outlined-Primary-Insurance"
                        select
                        size={"small"}
                        fullWidth
                        label="Payor Source"
                        value={index}
                        onChange={(e) => t.changeInsuranceType(e.target.value)}
                        variant="outlined"
                    >
                        {insuranceTypes.map((option, idx) => {
                            return (
                                <MUIMenuItem key={idx} value={insuranceTypes[idx]}>
                                    {option.name}
                                </MUIMenuItem>
                            );
                        })}
                    </MUITextField>
                </MDBCol>
            );
        }

        function renderInsuranceSubType() {
            if (sAry.length < 1) {
                return (
                    <MDBCol style={{textAlign: 'center'}} size={12}>
                        <MDBSpinner small/>
                    </MDBCol>
                );
            }

            return (
                <MDBCol size="12" className={"uiOutlines"}>
                    <MUITextField
                        id="outlined-Primary-Insurance"
                        select
                        size={"small"}
                        fullWidth
                        label="Insurance"
                        value={sIndex}
                        onChange={(e) => t.changeInsuranceSubType(e.target.value)}
                        variant="outlined"
                    >
                        {sAry.map((option, idx) => {
                            return (
                                <MUIMenuItem key={idx} value={sAry[idx]}>
                                    {option.name}
                                </MUIMenuItem>
                            );
                        })}
                    </MUITextField>
                </MDBCol>
            );
        }

        return (
            <div>
                <MDBRow
                    style={{
                        border: "1px solid #7986cb",
                        margin: 8,
                        padding: 15,
                        marginTop: 20,
                    }}
                    id="patientPrimaryInsurance"
                >
                    <label className={"insuranceLabel labelPosition"}>
						Primary Insurance <MDBIcon onClick={() => this.getInsuranceSubType()} style={{color: '#5881C1'}} icon={"question-circle"}/>

                    </label>
                    {this.renderInsuranceSync()}

                    {renderInsuranceType()}

                    {renderInsuranceSubType()}

                    <MDBCol size={"12"} className={"insuranceSelects"}>
                        <MDBInput
                            label={"Policy Number"}
                            style={{ paddingTop: 8 }}
                            outline
                            valueDefault={eOrder.insurancePolicy}
                            disabled={this.state.eOrder.status === "Setup"}
                            onChange={(e) =>
                                this.setAndUpdate("insurancePolicy", e.target.value)
                            }
                        />
                    </MDBCol>

                    <MDBCol size={"12"} className={"insuranceSelects"}>
                        <MDBInput
                            label={"Group Number"}
                            style={{ paddingTop: 8 }}
                            outline
                            valueDefault={eOrder.insuranceGroup}
                            disabled={this.state.eOrder.status === "Setup"}
                            onChange={(e) =>
                                this.setAndUpdate("insuranceGroup", e.target.value)
                            }
                        />
                    </MDBCol>
                    <MDBCol size={"12"} className={"insuranceSelects"}>
                            <MDBInput
                                maxLength={11}
                                label={"MBI"}
                                style={{ paddingTop: 8, textTransform: 'uppercase' }}
                                outline
                                valueDefault={eOrder.mbi || eOrder.patient.mbi}
                                onChange={(e) => this.setAndUpdate("mbi", e.target.value)}
                                onInput={(e) => {
                                    const newValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Letters and numbers only
                                    e.target.value = newValue; // Update the input field directly
                                }}
                            />
                        </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderSecondarySection() {
        const {insuranceTypes, insuranceSubTypes} = this.context;

        let t = this;

        let eOrder = this.state.eOrder,
            sAry = insuranceSubTypes,
            sIndex = null;

        let index = insuranceTypes.find((it) => {
            if (eOrder.secondaryInsuranceType) {
                return it.id === eOrder.secondaryInsuranceType.id;
            }
        });

        if (eOrder.secondaryInsuranceType) {
            sAry = insuranceSubTypes.filter((ist) => {
                return ist.insuranceType.id === eOrder.secondaryInsuranceType.id;
            });

            if (eOrder.secondaryInsuranceSubType) {
                sIndex = sAry.find((sit) => {
                    return sit.id === eOrder.secondaryInsuranceSubType.id;
                });
            }
        }

        function renderSecondaryInsurance() {
            if (insuranceTypes.length < 1) {
                return <MDBSpinner small/>;
            }

            return (
                <MDBCol size="12" className={"uiOutlines"}>
                    <MUITextField
                        id="outlined-Primary-Insurance"
                        select
                        size={"small"}
                        fullWidth
                        label="Payor Source"
                        value={index}
                        onChange={(e) => t.changeInsuranceType(e.target.value, true)}
                        variant="outlined"
                    >
                        {insuranceTypes.map((option, idx) => {
                            return (
                                <MUIMenuItem key={idx} value={option}>
                                    {option.name}
                                </MUIMenuItem>
                            );
                        })}
                    </MUITextField>
                </MDBCol>
            );
        }

        function renderSecondarySubInsurance() {
            if (sAry.length < 1) {
                return (
                    <MDBCol style={{textAlign: 'center'}} size={12}>
                        <MDBSpinner small/>
                    </MDBCol>
                );
            }

            return (
                <MDBCol size="12" className={"uiOutlines"}>
                    <MUITextField
                        id="outlined-Primary-Insurance"
                        select
                        size={"small"}
                        fullWidth
                        label="Insurance"
                        value={sIndex}
                        onChange={(e) => t.changeInsuranceSubType(e.target.value, true)}
                        variant="outlined"
                        InputProps={{
                            endAdornment: sIndex && (
                                <MUIInputAdornment position="start">
                                    <MUIIconButton
                                        onClick={() => t.changeInsuranceSubType(null, true)} // Clears the value
                                        edge="start"
                                        sx={{padding: 0, marginRight: 1}}
                                    >
                                        <MUIClearIcon />
                                    </MUIIconButton>
                                </MUIInputAdornment>
                            ),
                        }}
                    >
                        {sAry.map((option, idx) => {
                            return (
                                <MUIMenuItem key={idx} value={sAry[idx]}>
                                    {option.name}
                                </MUIMenuItem>
                            );
                        })}
                    </MUITextField>
                </MDBCol>
            );
        }

        return (
            <div>
                <MDBRow
                    style={{
                        border: "1px solid #7986cb",
                        margin: 8,
                        padding: 15,
                        marginTop: 20,
                    }}
                    id="patientSecondaryInsurance"
                >
                    <label className={"insuranceLabel labelPosition"}>
						Secondary Insurance <MDBIcon onClick={() => this.getInsuranceSubType(true)} style={{color: '#5881C1'}} icon={"question-circle"}/>
                    </label>
                    {/*{this.renderInsuranceSync(true)}*/}
                    <ReactTooltip />

                    {renderSecondaryInsurance()}

                    {renderSecondarySubInsurance()}

                    <MDBCol size={"12"} className={"insuranceSelects"}>
                        <MDBInput
                            label={"Policy Number"}
                            style={{ paddingTop: 8 }}
                            outline
                            valueDefault={eOrder.secondaryInsurancePolicy}
                            disabled={this.state.eOrder.status === "Setup"}
                            onChange={(e) =>
                                this.setAndUpdate("secondaryInsurancePolicy", e.target.value)
                            }
                        />
                    </MDBCol>

                    <MDBCol size={"12"} className={"insuranceSelects"}>
                        <MDBInput
                            label={"Group Number"}
                            style={{ paddingTop: 8 }}
                            outline
                            valueDefault={eOrder.secondaryInsuranceGroup}
                            disabled={this.state.eOrder.status === "Setup"}
                            onChange={(e) =>
                                this.setAndUpdate("secondaryInsuranceGroup", e.target.value)
                            }
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderClinicalInformation() {
        let st = this.state,
            eOrder = st.eOrder;

        return (
            <div>
                <MDBRow
                    style={ {
                        border: "1px solid #7986cb",
                        margin: 8,
                        padding: 15,
                        marginTop: 20,
                    } }
                    id="clinicalInformation"
                >
                    <label className={ "insuranceLabel labelPosition" }>
						Clinical Information
                    </label>

                    <ReactTooltip />

                    <MDBCol size={ "12" } className={ "insuranceSelects" } style={ { paddingTop: 8 } }>
                        <MDBInput
                            label={ "Doctor Name" }
                            style={ { paddingTop: 8 } }
                            outline
                            valueDefault={eOrder.orderActivityClinicalInfo ? eOrder.orderActivityClinicalInfo.name : eOrder.patient.ciName}
                            onChange={ (e) => {
                                this.setAndUpdate("ciName", e.target.value);
                                this.setClinicalInfo("name", e.target.value);
                            }
                            }
                        />
                    </MDBCol>

                    <MDBCol size={ "12" } className={ "insuranceSelects" } style={ { paddingTop: 8 } }>
                        <MDBInput
                            label={ "Address" }
                            style={ { paddingTop: 8 } }
                            outline
                            valueDefault={eOrder.orderActivityClinicalInfo ? eOrder.orderActivityClinicalInfo.address : eOrder.patient.ciAddress }
                            onChange={ (e) => {
                                this.setAndUpdate("ciAddress", e.target.value);
                                this.setClinicalInfo("address", e.target.value);
                            }
                            }
                        />
                    </MDBCol>
                    <MDBCol size={ "6" } className={ "insuranceSelects" } style={ { paddingTop: 8 } }>
                        <MDBInput
                            label={ "City" }
                            outline
                            valueDefault={eOrder.orderActivityClinicalInfo ? eOrder.orderActivityClinicalInfo.city : eOrder.patient.ciCity}
                            onChange={ (e) => {
                                this.setAndUpdate("ciCity", e.target.value);
                                this.setClinicalInfo("city", e.target.value);
                            }
                            }
                        />
                    </MDBCol>
                    <MDBCol size={ "6" } className={ "insuranceSelects" } style={ { paddingTop: 8 } }>
                        {/*<MDBInput*/}
                        {/*	label={ "State" }*/}
                        {/*	outline*/}
                        {/*	valueDefault={ eOrder.patient.ciState }*/}
                        {/*	onChange={ (e) => {*/}
                        {/*		this.setAndUpdate("ciState", e.target.value)*/}
                        {/*		this.setClinicalInfo("state", e.target.value)*/}
                        {/*	}*/}
                        {/*	}*/}
                        {/*/>*/}
                        <Select
                            placeholder={eOrder.orderActivityClinicalInfo ? eOrder.orderActivityClinicalInfo.state : (eOrder.patient.ciState || "Select State...")}
                            options={this.getStateList()}
                            onChange={(e) => {
                                this.setAndUpdate("ciState", e.value);
                                this.setClinicalInfo("state", e.value);
                            }}
                        />
                    </MDBCol>
                    <MDBCol size={ "6" } className={ "insuranceSelects" } style={ { paddingTop: 8 } }>
                        <MDBInput
                            label={ "Zip Code" }
                            outline
                            valueDefault={eOrder.orderActivityClinicalInfo ? eOrder.orderActivityClinicalInfo.zip : eOrder.patient.ciZip}
                            onChange={ (e) => {
                                this.setAndUpdate("ciZip", e.target.value);
                                this.setClinicalInfo("zip", e.target.value);
                            }}
                        />
                    </MDBCol>
                    <MDBCol size={ "6" } className={ "insuranceSelects" } style={ { paddingTop: 8 } }>
                        <MDBInput
                            label={ "Phone Number" }
                            outline
                            valueDefault={eOrder.orderActivityClinicalInfo ? eOrder.orderActivityClinicalInfo.phone : eOrder.patient.ciPhone}
                            onChange={ (e) => {
                                this.setAndUpdate("ciPhone", e.target.value);
                                this.setClinicalInfo("phone", e.target.value);
                            }}
                        />
                    </MDBCol>
                    <MDBCol size={ "6" } className={ "insuranceSelects" } style={ { paddingTop: 8 } }>
                        <MDBInput
                            label={ "Fax" }
                            outline
                            valueDefault={eOrder.orderActivityClinicalInfo ? eOrder.orderActivityClinicalInfo.fax : eOrder.patient.ciFax}
                            onChange={ (e) => {
                                this.setAndUpdate("ciFax", e.target.value);
                                this.setClinicalInfo("fax", e.target.value);
                            }
                            }
                        />
                    </MDBCol>
                    <MDBCol size={ "6" } className={ "insuranceSelects" } style={ { paddingTop: 8 } }>
                        <MDBInput
                            label={ "NPI" }
                            outline
                            valueDefault={eOrder.orderActivityClinicalInfo ? eOrder.orderActivityClinicalInfo.npi : eOrder.patient.ciNpi}
                            onChange={ (e) => {
                                this.setAndUpdate("ciNpi", e.target.value);
                                this.setClinicalInfo("npi", e.target.value);
                            }
                            }
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderGoogleModal() {
        let st = this.state;
        return (
            <MDBModal
                isOpen={st.googleModal}
                toggle={() => {
                    return;
                }}
            >
                <div className={"topBorder"} />
                <MDBModalBody>
                    <MDBCloseIcon style={{right: "0"}}
                        onClick={this.toggleGoogleSearch}
                        className={"closeBtn"}
                    />
                    <div>
                        <Input
                            label="Address Search"
                            hint={"Address, City, State Zip Code"}
                            onChange={(e) => {
                                clearInterval(this.state.timer);
                                this.googleAddressTextChanged(e);
                            }}
                        />
                        {this.renderGoogleSearchResult()}
                    </div>
                </MDBModalBody>
                <div className={"bottomBorder"} />
            </MDBModal>
        );
    }

    renderSnfSameSimModal() {
        let details = this.state.snfDetails,
            claims = this.state.sameSimClaims;

        let hc = Array.from(new Set([...claims.map(x => x.hcpcs && x.hcpcs.length > 0 && GlobalFunctions.containsHcpcs(x.hcpcs.substring(0, 5)) ? x.hcpcs.substring(0, 5) : null)]));
        let lst = [];

        hc.forEach(x => {
            if (x) {
                var c = claims.filter(r => r.hcpcs && r.hcpcs.length > 0 && r.hcpcs.substring(0, 5) === x);
                lst.push({
                    code: x,
                    start: c[0],
                    end: c[c.length - 1],
                });
            }
        });

        return (
            <MDBModal
                className="snfModal"
                isOpen={this.state.sameSnfModal}
                toggle={() => {
                    return;
                }}
            >
                <MDBModalHeader style={{ color: 'white', backgroundColor: '#5881c1' }}>Same/Sim & SNF Details</MDBModalHeader>
                <MDBModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
                    <div>
                        <div>
                            <h3>Same/Sim Claims</h3>
                            <div>
                                <table style={{ minWidth: "500px" }}>
                                    <tr>
                                        <th>HCPCS</th>
                                        <th>Status</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Same Category billed?</th>
                                        <th>Supplier</th>
                                    </tr>
                                    {(lst.length > 0 ? lst.map(x => {
                                        return (
                                            <tr>
                                                <td>{x.code}</td>
                                                <td>{x.end.status}</td>
                                                <td>{(x.start.dosStart || "-")}</td>
                                                <td>{(x.end.dosEnd || "-")}</td>
                                                <td>{x.end.sameCategoryBilled}</td>
                                                <td>{x.end.supplierName}</td>
                                            </tr>);
                                    }) :
                                        <tr>
                                            <td>No Claims to show</td>
                                        </tr>)}
                                </table>
                            </div>
                        </div>
                        <br />
                        <hr />
                        <br/>
                        <div>
                            <h3>SNF Details</h3>
                            <div>
                                <table style={{ minWidth: "500px" }}>
                                    <tr>
                                        <th>Service Start</th>
                                        <th>Service End</th>
                                        <th>Discharge Date</th>
                                    </tr>
                                    {details && details.length > 0 ? details.map(x => {
                                        return (
                                            <tr>
                                                <td>{(x.dosStart || "-")}</td>
                                                <td>{(x.dosEnd || "-")}</td>
                                                <td>{(x.dischargedDate || "-")}</td>
                                            </tr>);
                                    }) : <tr><td colSpan="3">No details to show</td></tr>}
                                </table>
                            </div>
                        </div>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBCloseIcon
                        style={{ right: "0" }}
                        onClick={() => {
                            this.setState({
                                sameSnfModal: false,
                            });
                        }}
                        className={"closeBtn"}
                    />
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderOptions(op) {
        //let primaryTemp = op.availity.primary && op.availity.primary.length > 0 ? JSON.parse(op.availity.primary) : null;
        let pvPrimaryTemp = op.pVerify.primary && op.pVerify.primary.length > 0 ? JSON.parse(op.pVerify.primary.replace(/\r\n/g, "")) : null;
        let pvSecondaryTemp = op.pVerify.secondary && op.pVerify.secondary.length > 0 ? JSON.parse(op.pVerify.secondary.replace(/\r\n/g, "")) : null;
        //let primary = [];
        let pvPrimary = [];
        let pvSecondary = [];
        //let secondary = op.availity.secondary ? JSON.parse(op.availity.secondary) : null;
        let pvSameSim = op.pVerify && op.pVerify.sameSim && op.pVerify.sameSim.length > 0 ? op.pVerify.sameSim.map(x => JSON.parse(x)) : null;
        let pvSameSimE = op.pVerify && op.pVerify.sameSimE && op.pVerify.sameSimE.length > 0 ? op.pVerify.sameSimE.map(x => JSON.parse(x)) : null;
        let pvSameSimK = op.pVerify && op.pVerify.sameSimK && op.pVerify.sameSimK.length > 0 ? op.pVerify.sameSimK.map(x => JSON.parse(x)) : null;
        let pvSnf = op.pVerify && op.pVerify.snf ? JSON.parse(op.pVerify.snf) : null;
        let ind = this.state.activeInsuranceTab;
        let msg = this.state.insuranceError;
        let order = this.state.eOrder;

        //if (pvPrimaryTemp && pvPrimaryTemp.RequestID) {
        //	pvPrimaryTemp = this.toCamel(pvPrimaryTemp)
        //}

        let pvSnf2 = {},
            pvSameSim2 = [],
            pvSameSimK2 = [],
            pvSameSimE2 = [];

        if (pvPrimaryTemp) {
            pvPrimary.push(pvPrimaryTemp);
        }

        if (pvSecondaryTemp) {
            pvSecondary.push(pvSecondaryTemp);
        }
        //if (primaryTemp) {
        //	primary.push(primaryTemp)
        //}
        if (!pvPrimaryTemp || pvPrimaryTemp.length === 0){
            ind = "2";
        }

        // filter out to only the last one pulled
        //if (pvPrimaryTemp && primaryTemp) {
        //	var p = pvPrimary[0];
        //	var a = primary[0];
        //	console.log(p)
        //	console.log(a)
        //	var pDate = p && p.DOS ? new Date(p.DOS.split(" - ")[0]) : null;
        //	var aDate = a && a.asOfDate ?
        //}

        if (pvSnf && pvSnf.APIResponseMessage) {
            pvSnf2 = this.toCamel(pvSnf);
        }

        if (pvSameSim && pvSameSim.length > 0) {
            pvSameSim.forEach(p => {
                pvSameSim2.push(this.toCamel(p));
            });
        }
        if (pvSameSimK && pvSameSimK.length > 0) {
            pvSameSimK.forEach(p => {
                pvSameSim2.push(this.toCamel(p));
            });
        }
        if (pvSameSimE && pvSameSimE.length > 0) {
            pvSameSimE.forEach(p => {
                pvSameSim2.push(this.toCamel(p));
            });
        }

        let claims = [];
        if (pvSameSim2 && pvSameSim2.length > 0) {
            pvSameSim2.forEach(x => {
                if(x.claims && x.claims.length > 0)
                    x.claims.map(c => claims.push(c));
            });
        }

        let lst = [];
        if (claims && claims.length > 0) {
            // get the hcpcs from the order
            let hc = Array.from(new Set([...claims.map(x => x.hcpcs && x.hcpcs.length > 0 && GlobalFunctions.containsHcpcs(x.hcpcs.substring(0, 5)) ? x.hcpcs.substring(0, 5) : null)]));

            hc.forEach(x => {
                if (x) {
                    var c = claims.filter(r => r.hcpcs && r.hcpcs.length > 0 && r.hcpcs.substring(0, 5) === x);
                    lst.push({
                        code: x,
                        start: c[0],
                        end: c[c.length - 1],
                    });
                }
            });
        }

        let details = pvSnf2 && pvSnf2.details && pvSnf2.isDataError !== true ? pvSnf2.details : [];
        //if (!primary) {
        //	ind = "2";
        //}

        var icon = claims.length === 0 || claims.filter(x => x.status === "Denied" && x.hcpcs && x.hcpcs.length > 0 && GlobalFunctions.containsHcpcs(x.hcpcs)).length === 0 ? "check-circle" : "times-circle";

        var bgco = claims.length === 0 || claims.filter(x => x.status === "Denied" && x.hcpcs && x.hcpcs.length > 0 && GlobalFunctions.containsHcpcs(x.hcpcs)).length === 0 ? "green" : "red";

        var icon2 = details.length === 0 || details.filter(x => !x.dischargedDate && !x.dosEnd).length === 0 ? "check-circle" : "times-circle";
        var bgco2 = details.length === 0 || details.filter(x => !x.dischargedDate && !x.dosEnd).length === 0 ? "green" : "red";

        let asOf = null,
            asOf2 = null; //primary && primary.length > 0 ? primary.map(x => x.asOfDate) : [];
        //console.log(op.pVerify)


        if ((claims && claims.length > 0) && (pvSameSim2 && (pvSameSim2.filter(x => x.status === "Pending" || x.isDataError === true || x.apiResponseCode === 4).length === 0 || pvSameSim2[0].status === "Processed"))) {
            if (icon === "check-circle") {
                if (!this.state.sameSimGood) {
                    this.setState({ sameSimGood: true });
                }
            }
        }


        if ((pvPrimary && pvPrimary.length > 0) || (pvSecondary && pvSecondary.length > 0)) {
            asOf = pvPrimary && pvPrimary.length > 0 ? pvPrimary.map(x => x.DOS) : [];
            asOf2 = pvSecondary && pvSecondary.length > 0 ? pvSecondary.map(x => x.DOS) : [];

            return (<div>
                {msg && msg.length > 0 ?
                    <Row>
                        <Col size="12" style={{ color: this.state.msgColor }}>
                            <b>{msg}</b>
                        </Col>
                    </Row>
                    : ''
                }
                <Row>
                    <Col size="12">
                        <div><b>Requested Service Type:</b> {pvPrimary && pvPrimary.length > 0 ? pvPrimary[0].ResultPracticeType : (pvSecondary && pvSecondary.length > 0 ? pvSecondary[0].ResultPracticeType : "")} - (pVerify)</div>
                    </Col>

                    {/*Demographics*/}
                    <Col size="6">
                        {pvPrimary && pvPrimary.length > 0 && pvPrimary[0].DemographicInfo && pvPrimary[0].DemographicInfo.Subscriber ?
                            <div><b>Patient:</b> {pvPrimary[0].DemographicInfo.Subscriber.Fullname || order.patientName}
                                <div><b>Member ID:</b> {pvPrimary[0].DemographicInfo.Subscriber.Identification && pvPrimary[0].DemographicInfo.Subscriber.Identification.length > 0 ? pvPrimary[0].DemographicInfo.Subscriber.Identification.filter(x => x.Type === "Member ID")[0].Code : ""}</div>
                                <div><b>BirthDate:</b> {pvPrimary[0].DemographicInfo.Subscriber.DOB_R || ""}</div>
                                {pvPrimary[0].DemographicInfo.Subscriber.Address1 ?
                                    <div><b>Address:</b> {pvPrimary[0].DemographicInfo.Subscriber.Address1 || ""} {pvPrimary[0].DemographicInfo.Subscriber.Address2 || ""}, {pvPrimary[0].DemographicInfo.Subscriber.City || ""}, {pvPrimary[0].DemographicInfo.Subscriber.State || ""} {pvPrimary[0].DemographicInfo.Subscriber.Zip || ""}</div>
                                    : <div><b>Address:</b> {order.address || ""}, {order.city || ""}, {order.state || ""} {order.zipCode || ""}</div>
                                }
                            </div>
                            : ''}
                        {(!pvPrimary || pvPrimary.length <= 0) && pvSecondary && pvSecondary.length > 0 && pvSecondary[0].DemographicInfo && pvSecondary[0].DemographicInfo.Subscriber ?
                            <div><b>Patient:</b> {pvSecondary[0].DemographicInfo.Subscriber.Fullname || order.patientName}
                                <div><b>Member ID:</b> {pvSecondary[0].DemographicInfo.Subscriber.Identification && pvSecondary[0].DemographicInfo.Subscriber.Identification.length > 0 ? pvSecondary[0].DemographicInfo.Subscriber.Identification.filter(x => x.Type === "Member ID")[0].Code : ""}</div>
                                <div><b>BirthDate:</b> {pvSecondary[0].DemographicInfo.Subscriber.DOB_R || ""}</div>
                                {pvSecondary[0].DemographicInfo.Subscriber.Address1 ?
                                    <div><b>Address:</b> {pvSecondary[0].DemographicInfo.Subscriber.Address1 || ""} {pvSecondary[0].DemographicInfo.Subscriber.Address2 || ""}, {pvSecondary[0].DemographicInfo.Subscriber.City || ""}, {pvSecondary[0].DemographicInfo.Subscriber.State || ""} {pvSecondary[0].DemographicInfo.Subscriber.Zip || ""}</div>
                                    : <div><b>Address:</b> {order.address || ""}, {order.city || ""}, {order.state || ""} {order.zipCode || ""}</div>
                                }
                            </div>
                            : ''}
                        {order && order.salesLocation ? <div><b>Office: </b>{order.salesLocation.name} <b>NPI: </b> {order.salesLocation.npi}</div> : ''}
                    </Col>

                    {/*SAME/SIM*/}
                    <Col size="3">
                        <div style={{ padding: "10px", borderRadius: "20px", border: "2px solid black", backgroundColor: "aliceblue" }}
                            data-place={"bottom"}
                            data-tip={ReactDOMServer.renderToString(
                                <div>
                                    <p>Claims:<br />
                                        <table style={{ minWidth: "500px" }}>
                                            <tr>
                                                <th>HCPCS</th>
                                                <th>Status</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Same Category billed?</th>
                                                <th>Supplier</th>
                                            </tr>
                                            {(lst.length > 0 ? lst.map(x => {
                                                return (
                                                    <tr>
                                                        <td>{x.code}</td>
                                                        <td>{x.end ? x.end.status : "-"}</td>
                                                        <td>{(x.start ? x.start.dosStart : "-")}</td>
                                                        <td>{(x.end ? x.end.dosEnd : "-")}</td>
                                                        <td>{x.end ? x.end.sameCategoryBilled : "-"}</td>
                                                        <td>{x.end.supplierName}</td>
                                                    </tr>);
                                            }) :
                                                <tr>
                                                    <td>No Claims to show</td>
                                                </tr>)}
                                        </table>
                                        <br />
                                    </p>
                                </div>)}
                            data-html={true}>
                            <div style={{ textAlign: "center" }}><b>Same/Sim</b></div>
                            {(claims && claims.length > 0) && (pvSameSim2 && (pvSameSim2.filter(x => x.status === "Pending" || x.isDataError === true || x.apiResponseCode === 4).length === 0 || pvSameSim2[0].status === "Processed")) ?
                                <div style={{ textAlign: "center" }}>
                                    <MDBIcon
                                        icon={icon}
                                        style={{ fontSize: "2em", color: bgco }}
                                    />
                                </div>
                                : (pvSameSim2 && pvSameSim2.length > 0 && pvSameSim2.filter(x => x.apiResponseCode === 4).length > 0 ? <div style={{ textAlign: "center", color: "red" }}>{pvSameSim2.apiResponseMessage}</div> : (pvSameSim2 && pvSameSim2.length > 0 ? <div style={{ textAlign: "center" }}>Status: {pvSameSim2[0].status}</div> : <div style={{ textAlign: "center" }}>No Records collected</div>))
                            }
                            <Button
                                data-tip="View Same/Sim Data"
                                floating
                                size="sm"
                                disabled={(!claims || claims.length === 0)}
                                onClick={() => {
                                    this.setState({
                                        sameSimClaims: claims || [],
                                        snfDetails: details || [],
                                        insuranceModal: false,
                                        sameSnfModal: true,
                                    });
                                }}
                                color={"primary"}>
                                <MDBIcon icon="eye" style={{ fontSize: "2em" }} />
                            </Button>
                        </div>
                    </Col>

                    {/*SNF*/}
                    <Col size="3">
                        <div style={{ padding: "10px", borderRadius: "20px", border: "2px solid black", backgroundColor: "aliceblue" }}
                            data-place={"bottom"}
                            data-tip={ReactDOMServer.renderToString(
                                <div>
                                    <p>Details:<br />
                                        <table style={{ minWidth: "500px" }}>
                                            <tr>
                                                <th>Service Start</th>
                                                <th>Service End</th>
                                                <th>Discharge Date</th>
                                            </tr>
                                            {(details.length > 0 || (pvSnf2 && pvSnf2.isDataError !== true && (!details || details.length === 0)) ? details.map(x => {
                                                return (
                                                    <tr>
                                                        <td>{(x.dosStart || "-")}</td>
                                                        <td>{(x.dosEnd || "-")}</td>
                                                        <td>{(x.dischargedDate || "-")}</td>
                                                    </tr>);
                                            }) : (pvSnf2 && pvSnf2.isDataError === true && pvSnf2.errorMessage.indexOf("Inpatient / SNF info not found for this Patient for requested DOS") > -1 ?
                                                <tr><td colSpan="3">{pvSnf2.errorMessage}</td></tr> : <tr><td colSpan="3">No details to show</td></tr>))}
                                        </table>
                                        <br />
                                    </p>
                                </div>)}
                            data-html={true}>
                            <div style={{ textAlign: "center" }}><b>SNF</b></div>
                            {(details && details.length > 0) || (pvSnf2 && pvSnf2.isDataError === true && pvSnf2.apiResponseCode !== 4 && pvSnf2.errorMessage.indexOf("Inpatient / SNF info not found") > -1) ?
                                <div style={{ textAlign: "center" }}>
                                    <MDBIcon
                                        icon={icon2}
                                        style={{ fontSize: "2em", color: bgco2 }}
                                    />
                                </div>
                                : (pvSnf2 && pvSnf2.apiResponseCode === 4 ? <div style={{ textAlign: "center", color: "red" }}>{pvSnf2.apiResponseMessage}</div> : (pvSnf2 && pvSnf2.status ? <div style={{ textAlign: "center" }}><div>Status: {pvSnf2.status}</div><div>{(pvSnf2.expectedTimeInSeconds ? "ETA: " + pvSnf2.expectedTimeInSeconds + " seconds" : "")}</div></div> : <div style={{ textAlign: "center" }}>No Records collected</div>))
                            }
                            <Button
                                data-tip="View SNF Data"
                                floating
                                size="sm"
                                disabled={(!details || details.length === 0)}
                                onClick={() => {
                                    this.setState({
                                        sameSimClaims: claims || [],
                                        snfDetails: details || [],
                                        insuranceModal: false,
                                        sameSnfModal: true,
                                    });
                                }}
                                color={"primary"}>
                                <MDBIcon icon="eye" style={{ fontSize: "2em" }} />
                            </Button>
                        </div>
                    </Col>

                    <Col size="12">
                        <hr />
                    </Col>

                    <Col size="12">
                        <div style={{ display: "inline-block" }}>
                            <label>
                                <Input
                                    onClick={() => this.setState({ activeInsuranceTab: "1" })}
                                    checked={ind === "1"}
                                    disabled={!pvPrimary || pvPrimary.length === 0}
                                    type="radio"
                                    label="Primary"
                                    id="rdPrimary"
                                />
                            </label>
                            <label>
                                <Input
                                    onClick={() => this.setState({ activeInsuranceTab: "2" })}
                                    checked={ind === "2"}
                                    disabled={!pvSecondary || pvSecondary.length === 0}
                                    type="radio"
                                    label="Secondary"
                                    id="rdSecondary"
                                />
                            </label>
                        </div>
                        <div id="dvPrimary" style={{ display: (ind === "1" ? "block" : "none")}}>
                            {pvPrimary && pvPrimary.length > 0 ?
                                <div>
                                    <div><b>Last Updated (DOS): </b>{asOf && asOf.length > 0 ? asOf[0] : "unknown"}</div>
                                    {
                                        pvPrimary.map(p => {
                                            if (p.ErrorCode && p.ErrorDescription) {
                                                return <div style={{ color: "red" }}>{p.ErrorDescription} <div>{p.APIResponseMessage || ""}</div></div>;
                                            }

                                            return (
                                                <div>
                                                    <Row>
                                                        <Col md="4">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Status</label>
                                                                <h4>{p && p.PlanCoverageSummary && p.PlanCoverageSummary.Status ? p.PlanCoverageSummary.Status : "Unknown"}</h4>
                                                            </div>
                                                        </Col>
                                                        <Col md="4">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Payer</label>
                                                                <h4>{p && p.PayerName ? p.PayerName : "Unknown"}</h4>
                                                            </div>
                                                        </Col>
                                                        <Col md="4">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Verification Type</label>
                                                                <h4>{p && p.VerificationType ? p.VerificationType : "Unknown"}</h4>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{marginTop: "8px"}}>
                                                        <Col md="3">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Provider Network</label>
                                                                <label>{p && p.IsProviderInNetwork !== "None" ? p.IsProviderInNetwork : ""}</label>
                                                            </div>
                                                        </Col>
                                                        <Col md="3">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>DOS</label>
                                                                <div>
                                                                    <label>{p && p.DOS ? p.DOS : ""}</label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Eligibility Period</label>
                                                                <div>
                                                                    <span style={{ width: "50%", textAlign: "left", float: "left", marginLeft: "6px" }}>
                                                                        <label>Effective From: {p && p.PlanCoverageSummary && p.PlanCoverageSummary.EffectiveDate ? p.PlanCoverageSummary.EffectiveDate : ""}</label>
                                                                    </span>
                                                                    <span style={{ width: "50%", textAlign: "right" }}>
                                                                        <label>Expired On: {p && p.PlanCoverageSummary && p.PlanCoverageSummary.ExpiryDate ? p.PlanCoverageSummary.ExpiryDate : ""}</label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ marginTop: "8px" }}>
                                                        {p && p.PlanCoverageSummary ?
                                                            <Col md="6">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Coverage Summary</label>
                                                                    <div style={{ textAlign: "left" }}>
                                                                        <Button id="btnPlanCoverage" size="sm" color="primary"
                                                                            onClick={e => {
                                                                                document.getElementById("tblPlan").style.display = "table";
                                                                                document.getElementById("tblMisc").style.display = "none";
                                                                                document.getElementById("tblPcpAuth").style.display = "none";
                                                                                document.getElementById("tblOtherPayer").style.display = "none";
                                                                            }}
                                                                        >
																			Plan Coverage
                                                                        </Button>

                                                                        {p.PCPAuthInfoSummary ?
                                                                            <Button id="btnPcpAuthInfo" size="sm" color="primary"
                                                                                onClick={e => {
                                                                                    document.getElementById("tblPlan").style.display = "none";
                                                                                    document.getElementById("tblMisc").style.display = "none";
                                                                                    document.getElementById("tblPcpAuth").style.display = "table";
                                                                                    document.getElementById("tblOtherPayer").style.display = "none";
                                                                                }}
                                                                            >
																				PCP/Auth. Info
                                                                            </Button>
                                                                            : ''}

                                                                        {p.OtherPayerInfo ?
                                                                            <Button id="btnOtherPayerInfo" size="sm" color="primary"
                                                                                onClick={e => {
                                                                                    document.getElementById("tblPlan").style.display = "none";
                                                                                    document.getElementById("tblMisc").style.display = "none";
                                                                                    document.getElementById("tblPcpAuth").style.display = "none";
                                                                                    document.getElementById("tblOtherPayer").style.display = "table";
                                                                                }}
                                                                            >
																				Other Payer Info
                                                                            </Button>
                                                                            : ''}

                                                                        {p.MiscellaneousInfoSummary ?
                                                                            <Button id="btnMiscInfo" size="sm" color="primary"
                                                                                onClick={e => {
                                                                                    document.getElementById("tblPlan").style.display = "none";
                                                                                    document.getElementById("tblMisc").style.display = "table";
                                                                                    document.getElementById("tblPcpAuth").style.display = "none";
                                                                                    document.getElementById("tblOtherPayer").style.display = "none";
                                                                                }}
                                                                            >
																				Misc. Info
                                                                            </Button>
                                                                            : ''}
                                                                    </div>

                                                                    <div style={{ maxHeight: "300px", padding: "6px", overflowY: "scroll" }}>
                                                                        <table id="tblPlan" style={{ textAlign: "left" }}>
                                                                            {[p.PlanCoverageSummary].map(d => {
                                                                                var res = [];
                                                                                for (var pkey in d) {
                                                                                    if (d[pkey] !== null) {
                                                                                        res.push({
                                                                                            name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                            value: d[pkey],
                                                                                        });
                                                                                    }
                                                                                }
                                                                                if (res && res.length > 0) {
                                                                                    return res.map(r => {
                                                                                        return (<tr>
                                                                                            <td><b>{r.name} &nbsp;</b></td>
                                                                                            <td>{r.value}</td>
                                                                                        </tr>);
                                                                                    });
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </table>

                                                                        <table id="tblOtherPayer" style={{ textAlign: "left", display: "none" }}>
                                                                            {p.OtherPayerInfo ?
                                                                                [p.OtherPayerInfo].map(d => {
                                                                                    var res = [];
                                                                                    for (var pkey in d) {
                                                                                        if (d[pkey] !== null && d[pkey] !== "None") {
                                                                                            res.push({
                                                                                                name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: d[pkey],
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (res && res.length > 0) {
                                                                                        return res.map(r => {
                                                                                            return (<tr>
                                                                                                <td><b>{r.name} &nbsp;</b></td>
                                                                                                <td>{r.value}</td>
                                                                                            </tr>);
                                                                                        });
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                                : ''}
                                                                        </table>

                                                                        <table id="tblPcpAuth" style={{ textAlign: "left", display: "none" }}>
                                                                            {p.PCPAuthInfoSummary ?
                                                                                [p.PCPAuthInfoSummary].map(d => {
                                                                                    var res = [];
                                                                                    for (var pkey in d) {
                                                                                        if (d[pkey] !== null && d[pkey] !== "None") {
                                                                                            res.push({
                                                                                                name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: d[pkey],
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (res && res.length > 0) {
                                                                                        return res.map(r => {
                                                                                            return (<tr>
                                                                                                <td><b>{r.name} &nbsp;</b></td>
                                                                                                <td>{r.value}</td>
                                                                                            </tr>);
                                                                                        });
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                                : ''}
                                                                        </table>

                                                                        <table id="tblMisc" style={{ textAlign: "left", display: "none" }}>
                                                                            {p.MiscellaneousInfoSummary ?
                                                                                [p.MiscellaneousInfoSummary].map(d => {
                                                                                    var res = [];
                                                                                    for (var pkey in d) {
                                                                                        if (d[pkey] !== null) {
                                                                                            res.push({
                                                                                                name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: d[pkey],
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (res && res.length > 0) {
                                                                                        return res.map(r => {
                                                                                            return (<tr>
                                                                                                <td><b>{r.name} &nbsp;</b></td>
                                                                                                <td>{r.value}</td>
                                                                                            </tr>);
                                                                                        });
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                                : ''}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : ''}
                                                        {p && p.MedicareInfoSummary ?
                                                            <Col md="6">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Medicare Benefit Summary</label>

                                                                    <div style={{textAlign: "left"}}>
                                                                        <Button id="btnMedicareInfo" size="sm" color="primary"
                                                                            onClick={e => {
                                                                                document.getElementById("tblMedicare").style.display = "table";
                                                                                document.getElementById("tblDedOop").style.display = "none";
                                                                            }}
                                                                        >
																			Medicare Info
                                                                        </Button>

                                                                        <Button id="btnDedOop" size="sm" color="primary"
                                                                            onClick={e => {
                                                                                document.getElementById("tblMedicare").style.display = "none";
                                                                                document.getElementById("tblDedOop").style.display = "table";
                                                                            }}
                                                                        >
																			Plan Deductible OOP
                                                                        </Button>
                                                                    </div>

                                                                    <div style={{ maxHeight: "200px", overflowY: "scroll", textAlign: "left" }}>
                                                                        <table id="tblMedicare">
                                                                            {[p.MedicareInfoSummary].map(v => {
                                                                                var ll = [];
                                                                                for (var oKey in v) {
                                                                                    if (oKey.toLowerCase().indexOf("part_a") === -1 &&
																						oKey.toLowerCase().indexOf("pt_slp_") === -1 &&
																						oKey.toLowerCase().indexOf("ot_capamount") === -1) {
                                                                                        if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey].Value,
                                                                                                note: v[oKey].Notes,
                                                                                            });
                                                                                        }
                                                                                        else if (v[oKey] !== null && v[oKey] !== "None" &&
																							oKey.toLowerCase().indexOf("pharmacy") < 0 &&
																							!["bin","pcn"].includes(oKey.toLowerCase())) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey],
                                                                                                note: null,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (ll && ll.length > 0) {
                                                                                    return ll.map(l => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <b>{l.name}: </b>
                                                                                                    {l.note ? <div>{l.note}</div> : ''}
                                                                                                </td>
                                                                                                <td>{l.value}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    });
                                                                                }
                                                                                return '';
                                                                            })}
                                                                        </table>

                                                                        <table id="tblDedOop" style={{ display: "none" }}>
                                                                            {p.HBPC_Deductible_OOP_Summary ?
                                                                                [p.HBPC_Deductible_OOP_Summary].map(v => {
                                                                                    var ll = [];
                                                                                    for (var oKey in v) {
                                                                                        if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey].Value,
                                                                                                note: v[oKey].Notes,
                                                                                            });
                                                                                        }
                                                                                        else if (v[oKey] !== null) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey],
                                                                                                note: null,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (ll && ll.length > 0) {
                                                                                        return ll.map(l => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <b>{l.name}: </b>
                                                                                                        {l.note ? <div>{l.note}</div> : ''}
                                                                                                    </td>
                                                                                                    <td>{l.value}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        });
                                                                                    }
                                                                                    return '';
                                                                                })
                                                                                : ""}
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : ''}

                                                        {p && !p.MedicareInfoSummary && p.PlanCoverageSummary ?
                                                            <Col md="6">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Plan Benefit & Service Summary</label>

                                                                    <div style={{ textAlign: "left" }}>

                                                                        <Button id="btnDedOop2" size="sm" color="primary"
                                                                            onClick={e => {
                                                                                document.getElementById("tblDME").style.display = "none";
                                                                                document.getElementById("tblDedOop2").style.display = "table";
                                                                            }}
                                                                        >
																			Plan Deductible OOP
                                                                        </Button>

                                                                        {p && p.DMESummary ?
                                                                            <Button id="btnDME" size="sm" color="primary"
                                                                                onClick={e => {
                                                                                    document.getElementById("tblDME").style.display = "table";
                                                                                    document.getElementById("tblDedOop2").style.display = "none";
                                                                                }}
                                                                            >
																				DME
                                                                            </Button>
                                                                            : ""}

                                                                    </div>

                                                                    <div style={{ maxHeight: "200px", overflowY: "scroll", textAlign: "left" }}>
                                                                        <table id="tblDedOop2">
                                                                            {p.HBPC_Deductible_OOP_Summary ?
                                                                                [p.HBPC_Deductible_OOP_Summary].map(v => {
                                                                                    var ll = [];
                                                                                    for (var oKey in v) {
                                                                                        if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey].Value,
                                                                                                note: v[oKey].Notes,
                                                                                            });
                                                                                        }
                                                                                        else if (v[oKey] !== null) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey],
                                                                                                note: null,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (ll && ll.length > 0) {
                                                                                        return ll.map(l => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <b>{l.name}: </b>
                                                                                                        {l.note ? <div>{l.note}</div> : ''}
                                                                                                    </td>
                                                                                                    <td>{l.value}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        });
                                                                                    }
                                                                                    return '';
                                                                                })
                                                                                : ""}
                                                                        </table>

                                                                        <table id="tblDME" style={{ display: "none" }}>
                                                                            {p.DMESummary ?
                                                                                [p.DMESummary].map(v => {
                                                                                    var ll = [];
                                                                                    for (var oKey in v) {
                                                                                        if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey].Value,
                                                                                                note: v[oKey].Notes,
                                                                                            });
                                                                                        }
                                                                                        else if (v[oKey] !== null && v[oKey] !== "None") {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey],
                                                                                                note: null,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (ll && ll.length > 0) {
                                                                                        return ll.map(l => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <b>{l.name}: </b>
                                                                                                        {l.note ? <div>{l.note}</div> : ''}
                                                                                                    </td>
                                                                                                    <td>{l.value}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        });
                                                                                    }
                                                                                    return '';
                                                                                })
                                                                                : ""}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : ''}
                                                    </Row>

                                                    {p.ExceptionNotes ?
                                                        <Row style={{ marginTop: "8px" }}>
                                                            <Col md="12">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Exception Info</label>
                                                                    <div style={{ width: "100%", textAlign: "left", padding: "8px" }}>
                                                                        <label>{p.ExceptionNotes}</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        : ''}


                                                    {p.AddtionalInfo ?
                                                        <Row style={{ marginTop: "8px" }}>
                                                            <Col md="12">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Additional Info / Additional Payer Info</label>
                                                                    <div style={{ width: "100%", textAlign: "left", padding: "8px" }}>
                                                                        <label>{p.AddtionalInfo}</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        : ''}

                                                    <Row style={{marginTop: "8px"}}>
                                                        {p.ServiceDetails && p.ServiceDetails.length > 0 ?
                                                            p.ServiceDetails.map(x => {
                                                                if (x.ServiceName && (x.ServiceName === "Health Benefit Plan Coverage" || x.ServiceName.indexOf("Durable Medical Equipment") > -1)) {
                                                                    return (
                                                                        <Col md="6">
                                                                            <div>
                                                                                <div><b>{x.ServiceName}</b></div>
                                                                                <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                                                                                {x.EligibilityDetails.map(v => {
                                                                                    return (<div>
                                                                                        {v.TimePeriodQualifier && v.TimePeriodQualifier !== "None" && v.EligibilityOrBenefit && v.EligibilityOrBenefit !== "None" &&
																						v.PlanCoverageDescription && v.PlanCoverageDescription !== "None" ?
                                                                                            <div><b>{v.TimePeriodQualifier || ""} {v.EligibilityOrBenefit || ""} ({v.PlanCoverageDescription || ""}): </b>{!v.MonetaryAmount || v.MonetaryAmount.toString() === "None" ? "" : "$" + parseFloat(v.MonetaryAmount).toFixed(2)}</div>
                                                                                            : ""}
                                                                                        {v.InsuranceType ?
                                                                                            <div><b>Policy Type: </b>{v.InsuranceType || ""}</div>
                                                                                            : ""}
                                                                                        {v.Identifications && v.Identifications.length > 0 ?
                                                                                            <div><b>{v.Identifications && v.Identifications.length > 0 ? v.Identifications[0].Type : ""}: </b>{v.Identifications && v.Identifications.length > 0 ? v.Identifications[0].Code : ""}</div>
                                                                                            : ""}
                                                                                        {v.Date && v.Date.length > 0 ?
                                                                                            <div><b>{v.Date && v.Date.length > 0 ? v.Date[0].Type : ""}: </b>{v.Date && v.Date.length > 0 ? v.Date[0].Date : ""}</div>
                                                                                            : ""}
                                                                                        {(v.TimePeriodQualifier && v.TimePeriodQualifier !== "None" && v.EligibilityOrBenefit && v.EligibilityOrBenefit !== "None" &&
																						v.PlanCoverageDescription && v.PlanCoverageDescription !== "None") ||
																						v.InsuranceType ||
																						(v.Identifications && v.Identifications.length > 0) ||
																						(v.Date && v.Date.length > 0) ?
                                                                                            <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                                                                                            : ""}
                                                                                    </div>);
                                                                                })}
                                                                            </div>
                                                                        </Col>);
                                                                }
                                                                return '';
                                                            })
                                                            : ''}
                                                    </Row>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                : (pvPrimary && pvPrimary.ErrorCode ? <div style={{ color: 'red' }}><b>Error: </b>{pvPrimary.ErrorDescription}</div> : '')}
                        </div>
                        <div id="dvSecondary" style={{ display: (ind === "2" ? "block" : "none") }}>
                            {pvSecondary && pvSecondary.length > 0 ?
                                <div>
                                    <div><b>Last Updated (DOS): </b>{asOf2 && asOf2.length > 0 ? asOf2[0] : "unknown"}</div>
                                    {
                                        pvSecondary.map(p => {
                                            if (p.ErrorCode && p.ErrorDescription) {
                                                return <div style={{ color: "red" }}>{p.ErrorDescription} <div>{p.APIResponseMessage || ""}</div></div>;
                                            }
                                            //let currentCoverage = null;
                                            //if (p.ServiceDetails && p.ServiceDetails.length > 0) {
                                            //	p.ServiceDetails.map((d,i) => {
                                            //		if (d.ServiceName.indexOf("Durable Medical Equipment") > -1) {
                                            //			currentCoverage = d;
                                            //		}
                                            //		return null;
                                            //	})
                                            //	if (currentCoverage == null) {
                                            //		p.ServiceDetails.map((d, i) => {
                                            //			if (d.ServiceName.indexOf("Health Benefit Plan Coverage") > -1) {
                                            //				currentCoverage = d;
                                            //			}
                                            //			return null;
                                            //		})
                                            //	}
                                            //}

                                            //if (currentCoverage) {
                                            return (
                                                <div>
                                                    <Row>
                                                        <Col md="4">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Status</label>
                                                                <h4>{p && p.PlanCoverageSummary && p.PlanCoverageSummary.Status ? p.PlanCoverageSummary.Status : "Unknown"}</h4>
                                                            </div>
                                                        </Col>
                                                        <Col md="4">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Payer</label>
                                                                <h4>{p && p.PayerName ? p.PayerName : "Unknown"}</h4>
                                                            </div>
                                                        </Col>
                                                        <Col md="4">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Verification Type</label>
                                                                <h4>{p && p.VerificationType ? p.VerificationType : "Unknown"}</h4>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ marginTop: "8px" }}>
                                                        <Col md="3">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Provider Network</label>
                                                                <label>{p && p.IsProviderInNetwork !== "None" ? p.IsProviderInNetwork : ""}</label>
                                                            </div>
                                                        </Col>
                                                        <Col md="3">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>DOS</label>
                                                                <div>
                                                                    <label>{p && p.DOS ? p.DOS : ""}</label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                <label>Eligibility Period</label>
                                                                <div>
                                                                    <span style={{ width: "50%", textAlign: "left", float: "left", marginLeft: "6px" }}>
                                                                        <label>Effective From: {p && p.PlanCoverageSummary && p.PlanCoverageSummary.EffectiveDate ? p.PlanCoverageSummary.EffectiveDate : ""}</label>
                                                                    </span>
                                                                    <span style={{ width: "50%", textAlign: "right" }}>
                                                                        <label>Expired On: {p && p.PlanCoverageSummary && p.PlanCoverageSummary.ExpiryDate ? p.PlanCoverageSummary.ExpiryDate : ""}</label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ marginTop: "8px" }}>
                                                        {p && p.PlanCoverageSummary ?
                                                            <Col md="6">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Coverage Summary</label>
                                                                    <div style={{ textAlign: "left" }}>
                                                                        <Button id="btnPlanCoverages" size="sm" color="primary"
                                                                            onClick={e => {
                                                                                document.getElementById("tblPlans").style.display = "table";
                                                                                document.getElementById("tblMiscs").style.display = "none";
                                                                                document.getElementById("tblPcpAuths").style.display = "none";
                                                                                document.getElementById("tblOtherPayers").style.display = "none";
                                                                            }}
                                                                        >
																			Plan Coverage
                                                                        </Button>

                                                                        {p.PCPAuthInfoSummary ?
                                                                            <Button id="btnPcpAuthInfos" size="sm" color="primary"
                                                                                onClick={e => {
                                                                                    document.getElementById("tblPlans").style.display = "none";
                                                                                    document.getElementById("tblMiscs").style.display = "none";
                                                                                    document.getElementById("tblPcpAuths").style.display = "table";
                                                                                    document.getElementById("tblOtherPayers").style.display = "none";
                                                                                }}
                                                                            >
																				PCP/Auth. Info
                                                                            </Button>
                                                                            : ''}

                                                                        {p.OtherPayerInfo ?
                                                                            <Button id="btnOtherPayerInfos" size="sm" color="primary"
                                                                                onClick={e => {
                                                                                    document.getElementById("tblPlans").style.display = "none";
                                                                                    document.getElementById("tblMiscs").style.display = "none";
                                                                                    document.getElementById("tblPcpAuths").style.display = "none";
                                                                                    document.getElementById("tblOtherPayers").style.display = "table";
                                                                                }}
                                                                            >
																				Other Payer Info
                                                                            </Button>
                                                                            : ''}

                                                                        {p.MiscellaneousInfoSummary ?
                                                                            <Button id="btnMiscInfos" size="sm" color="primary"
                                                                                onClick={e => {
                                                                                    document.getElementById("tblPlans").style.display = "none";
                                                                                    document.getElementById("tblMiscs").style.display = "table";
                                                                                    document.getElementById("tblPcpAuths").style.display = "none";
                                                                                    document.getElementById("tblOtherPayers").style.display = "none";
                                                                                }}
                                                                            >
																				Misc. Info
                                                                            </Button>
                                                                            : ''}
                                                                    </div>

                                                                    <div style={{ maxHeight: "300px", padding: "6px", overflowY: "scroll" }}>
                                                                        <table id="tblPlans" style={{ textAlign: "left" }}>
                                                                            {[p.PlanCoverageSummary].map(d => {
                                                                                var res = [];
                                                                                for (var pkey in d) {
                                                                                    if (d[pkey] !== null) {
                                                                                        res.push({
                                                                                            name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                            value: d[pkey],
                                                                                        });
                                                                                    }
                                                                                }
                                                                                if (res && res.length > 0) {
                                                                                    return res.map(r => {
                                                                                        return (<tr>
                                                                                            <td><b>{r.name} &nbsp;</b></td>
                                                                                            <td>{r.value}</td>
                                                                                        </tr>);
                                                                                    });
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </table>

                                                                        <table id="tblOtherPayers" style={{ textAlign: "left", display: "none" }}>
                                                                            {p.OtherPayerInfo ?
                                                                                [p.OtherPayerInfo].map(d => {
                                                                                    var res = [];
                                                                                    for (var pkey in d) {
                                                                                        if (d[pkey] !== null && d[pkey] !== "None") {
                                                                                            res.push({
                                                                                                name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: d[pkey],
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (res && res.length > 0) {
                                                                                        return res.map(r => {
                                                                                            return (<tr>
                                                                                                <td><b>{r.name} &nbsp;</b></td>
                                                                                                <td>{r.value}</td>
                                                                                            </tr>);
                                                                                        });
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                                : ''}
                                                                        </table>

                                                                        <table id="tblPcpAuths" style={{ textAlign: "left", display: "none" }}>
                                                                            {p.PCPAuthInfoSummary ?
                                                                                [p.PCPAuthInfoSummary].map(d => {
                                                                                    var res = [];
                                                                                    for (var pkey in d) {
                                                                                        if (d[pkey] !== null && d[pkey] !== "None") {
                                                                                            res.push({
                                                                                                name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: d[pkey],
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (res && res.length > 0) {
                                                                                        return res.map(r => {
                                                                                            return (<tr>
                                                                                                <td><b>{r.name} &nbsp;</b></td>
                                                                                                <td>{r.value}</td>
                                                                                            </tr>);
                                                                                        });
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                                : ''}
                                                                        </table>

                                                                        <table id="tblMiscs" style={{ textAlign: "left", display: "none" }}>
                                                                            {p.MiscellaneousInfoSummary ?
                                                                                [p.MiscellaneousInfoSummary].map(d => {
                                                                                    var res = [];
                                                                                    for (var pkey in d) {
                                                                                        if (d[pkey] !== null) {
                                                                                            res.push({
                                                                                                name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: d[pkey],
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (res && res.length > 0) {
                                                                                        return res.map(r => {
                                                                                            return (<tr>
                                                                                                <td><b>{r.name} &nbsp;</b></td>
                                                                                                <td>{r.value}</td>
                                                                                            </tr>);
                                                                                        });
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                                : ''}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : ''}
                                                        {p && p.MedicareInfoSummary ?
                                                            <Col md="6">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Medicare Benefit Summary</label>

                                                                    <div style={{ textAlign: "left" }}>
                                                                        <Button id="btnMedicareInfos" size="sm" color="primary"
                                                                            onClick={e => {
                                                                                document.getElementById("tblMedicares").style.display = "table";
                                                                                document.getElementById("tblDedOops").style.display = "none";
                                                                            }}
                                                                        >
																			Medicare Info
                                                                        </Button>

                                                                        <Button id="btnDedOops" size="sm" color="primary"
                                                                            onClick={e => {
                                                                                document.getElementById("tblMedicares").style.display = "none";
                                                                                document.getElementById("tblDedOops").style.display = "table";
                                                                            }}
                                                                        >
																			Plan Deductible OOP
                                                                        </Button>
                                                                    </div>

                                                                    <div style={{ maxHeight: "200px", overflowY: "scroll", textAlign: "left" }}>
                                                                        <table id="tblMedicares">
                                                                            {[p.MedicareInfoSummary].map(v => {
                                                                                var ll = [];
                                                                                for (var oKey in v) {
                                                                                    if (oKey.toLowerCase().indexOf("part_a") === -1 &&
																						oKey.toLowerCase().indexOf("pt_slp_") === -1 &&
																						oKey.toLowerCase().indexOf("ot_capamount") === -1) {
                                                                                        if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey].Value,
                                                                                                note: v[oKey].Notes,
                                                                                            });
                                                                                        }
                                                                                        else if (v[oKey] !== null && v[oKey] !== "None" &&
																							oKey.toLowerCase().indexOf("pharmacy") < 0 &&
																							!["bin", "pcn"].includes(oKey.toLowerCase())) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey],
                                                                                                note: null,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (ll && ll.length > 0) {
                                                                                    return ll.map(l => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <b>{l.name}: </b>
                                                                                                    {l.note ? <div>{l.note}</div> : ''}
                                                                                                </td>
                                                                                                <td>{l.value}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    });
                                                                                }
                                                                                return '';
                                                                            })}
                                                                        </table>

                                                                        <table id="tblDedOops" style={{ display: "none" }}>
                                                                            {p.HBPC_Deductible_OOP_Summary ?
                                                                                [p.HBPC_Deductible_OOP_Summary].map(v => {
                                                                                    var ll = [];
                                                                                    for (var oKey in v) {
                                                                                        if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey].Value,
                                                                                                note: v[oKey].Notes,
                                                                                            });
                                                                                        }
                                                                                        else if (v[oKey] !== null) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey],
                                                                                                note: null,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (ll && ll.length > 0) {
                                                                                        return ll.map(l => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <b>{l.name}: </b>
                                                                                                        {l.note ? <div>{l.note}</div> : ''}
                                                                                                    </td>
                                                                                                    <td>{l.value}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        });
                                                                                    }
                                                                                    return '';
                                                                                })
                                                                                : ""}
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : ''}

                                                        {p && !p.MedicareInfoSummary && p.PlanCoverageSummary ?
                                                            <Col md="6">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Plan Benefit & Service Summary</label>

                                                                    <div style={{ textAlign: "left" }}>

                                                                        <Button id="btnDedOops" size="sm" color="primary"
                                                                            onClick={e => {
                                                                                document.getElementById("tblDMEs").style.display = "none";
                                                                                document.getElementById("tblDedOops").style.display = "table";
                                                                            }}
                                                                        >
																			Plan Deductible OOP
                                                                        </Button>

                                                                        {p && p.DMESummary ?
                                                                            <Button id="btnDMEs" size="sm" color="primary"
                                                                                onClick={e => {
                                                                                    document.getElementById("tblDMEs").style.display = "table";
                                                                                    document.getElementById("tblDedOops").style.display = "none";
                                                                                }}
                                                                            >
																				DME
                                                                            </Button>
                                                                            : ""}

                                                                    </div>

                                                                    <div style={{ maxHeight: "200px", overflowY: "scroll", textAlign: "left" }}>
                                                                        <table id="tblDedOops">
                                                                            {p.HBPC_Deductible_OOP_Summary ?
                                                                                [p.HBPC_Deductible_OOP_Summary].map(v => {
                                                                                    var ll = [];
                                                                                    for (var oKey in v) {
                                                                                        if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey].Value,
                                                                                                note: v[oKey].Notes,
                                                                                            });
                                                                                        }
                                                                                        else if (v[oKey] !== null) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey],
                                                                                                note: null,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (ll && ll.length > 0) {
                                                                                        return ll.map(l => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <b>{l.name}: </b>
                                                                                                        {l.note ? <div>{l.note}</div> : ''}
                                                                                                    </td>
                                                                                                    <td>{l.value}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        });
                                                                                    }
                                                                                    return '';
                                                                                })
                                                                                : ""}
                                                                        </table>

                                                                        <table id="tblDMEs" style={{ display: "none" }}>
                                                                            {p.DMESummary ?
                                                                                [p.DMESummary].map(v => {
                                                                                    var ll = [];
                                                                                    for (var oKey in v) {
                                                                                        if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey].Value,
                                                                                                note: v[oKey].Notes,
                                                                                            });
                                                                                        }
                                                                                        else if (v[oKey] !== null && v[oKey] !== "None") {
                                                                                            ll.push({
                                                                                                name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                                                                                value: v[oKey],
                                                                                                note: null,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                    if (ll && ll.length > 0) {
                                                                                        return ll.map(l => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <b>{l.name}: </b>
                                                                                                        {l.note ? <div>{l.note}</div> : ''}
                                                                                                    </td>
                                                                                                    <td>{l.value}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        });
                                                                                    }
                                                                                    return '';
                                                                                })
                                                                                : ""}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : ''}

                                                    </Row>

                                                    {p.ExceptionNotes ?
                                                        <Row style={{ marginTop: "8px" }}>
                                                            <Col md="12">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Exception Info</label>
                                                                    <div style={{ width: "100%", textAlign: "left", padding: "8px" }}>
                                                                        <label>{p.ExceptionNotes}</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        : ''}


                                                    {p.AddtionalInfo ?
                                                        <Row style={{ marginTop: "8px" }}>
                                                            <Col md="12">
                                                                <div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
                                                                    <label>Additional Info / Additional Payer Info</label>
                                                                    <div style={{ width: "100%", textAlign: "left", padding: "8px" }}>
                                                                        <label>{p.AddtionalInfo}</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        : ''}

                                                    <Row style={{ marginTop: "8px" }}>
                                                        {p.ServiceDetails && p.ServiceDetails.length > 0 ?
                                                            p.ServiceDetails.map(x => {
                                                                if (x.ServiceName && (x.ServiceName === "Health Benefit Plan Coverage" || x.ServiceName.indexOf("Durable Medical Equipment") > -1)) {
                                                                    return (
                                                                        <Col md="6">
                                                                            <div>
                                                                                <div><b>{x.ServiceName}</b></div>
                                                                                <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                                                                                {x.EligibilityDetails.map(v => {
                                                                                    return (<div>
                                                                                        {v.TimePeriodQualifier && v.TimePeriodQualifier !== "None" && v.EligibilityOrBenefit && v.EligibilityOrBenefit !== "None" &&
																							v.PlanCoverageDescription && v.PlanCoverageDescription !== "None" ?
                                                                                            <div><b>{v.TimePeriodQualifier || ""} {v.EligibilityOrBenefit || ""} ({v.PlanCoverageDescription || ""}): </b>{!v.MonetaryAmount || v.MonetaryAmount.toString() === "None" ? "" : "$" + parseFloat(v.MonetaryAmount).toFixed(2)}</div>
                                                                                            : ""}
                                                                                        <div><b>Policy Type: </b>{v.InsuranceType || ""}</div>
                                                                                        {v.Identifications && v.Identifications.length > 0 ?
                                                                                            <div><b>{v.Identifications && v.Identifications.length > 0 ? v.Identifications[0].Type : ""}: </b>{v.Identifications && v.Identifications.length > 0 ? v.Identifications[0].Code : ""}</div>
                                                                                            : ""}
                                                                                        {v.Date && v.Date.length > 0 ?
                                                                                            <div><b>{v.Date && v.Date.length > 0 ? v.Date[0].Type : ""}: </b>{v.Date && v.Date.length > 0 ? v.Date[0].Date : ""}</div>
                                                                                            : ""}
                                                                                        <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                                                                                    </div>);
                                                                                })}
                                                                            </div>
                                                                        </Col>);
                                                                }
                                                                return '';
                                                            })
                                                            : ''}
                                                    </Row>
                                                </div>
                                            );
                                            //}

                                            //return '';
                                        })
                                    }
                                </div>
                                : (pvSecondary && pvSecondary.ErrorCode ? <div style={{ color: 'red' }}><b>Error: </b>{pvSecondary.ErrorDescription}</div> : '')}
                        </div>
                    </Col>
                </Row>
                <ReactTooltip />
            </div>);
        }
    }

    checkIfAllowedToVerifyInsurance(verificationType) {
        let allowed = false;
        let orderStatusReasonId = this.state.eOrder.orderStatusReasonId;
        if (verificationType === "verification") {
            if (orderStatusReasonId === 15 || orderStatusReasonId === 59 || this.state.eOrder.status === 2 || this.state.eOrder.orderStatusReasonId === 21) {
                allowed = true;
            }
        }
        if (verificationType === "samesim") {
            if (orderStatusReasonId === 15) {
                allowed = true;
            }
        }
        if (verificationType === "snf") {
            if (this.state.eOrder.status === 2) {
                allowed = true;
            }
        }
        return allowed;

    }
    checkForRTD() {
        let allowed = false;
        if (this.state.eOrder.status === 2) {
            allowed = true;
        }
        return allowed;
    }

    checkAndAutoVerifyInsurance() {
        //Here is where we could inport a list of what order status reasons to run the different verifications on
        let order = this.state.eOrder;
        if (order.orderStatusReasonId === 15) {
            this.refreshPverifyCoverage();
            this.refreshSameSim();
        }
        if (order.orderStatusReasonId === 59) {
            this.refreshPverifyCoverage();
        }
    }
    checkForCurrentMonth(date) {
        let createDate = new Date(date);
        let today = new Date();
        return (
            (createDate.getFullYear() === today.getFullYear()) &&
			(createDate.getMonth() === today.getMonth())
        );
    }

    checkApiResultForSuccess(type, stringResult) {
        let success = true;
        if (type === "primary") {
            if (stringResult.includes("Error Reason")) {
                success = false;
            }
        }
        if (type === "sameSim") {
            if (!this.state.sameSimGood) {
                success = false;
            }
        }
        return success;
    }
    refreshPverifyCoverage() {
        let order = this.state.eOrder,
            existingResults = this.state.insuranceResults;
        let pvPrimary = this.state.mostRecentInsuranceResults.pvPrimary;
    

        if (pvPrimary && this.checkForCurrentMonth(pvPrimary.createDate) && this.checkApiResultForSuccess("primary", pvPrimary.stringResult) && !this.checkForRTD() && !this.state.allowOverride) {
            this.setState({
                pvPrimaryDisabled: true
            });
            return;
        }

        this.setState({
            ranCoverage: true
        });
        InsuranceService.refreshPverifyCoverage(order.id)
            .then(res => {
                // console.log(res)
                if (res && res.length > 0) {
                    if (order.apiResults && order.apiResults.length > 0) {
                        let tmp = order.apiResults.find(x => x.id === res.id);
                        if (tmp) {
                            var idx = order.apiResults.indexOf(tmp);
                            order.apiResults.splice(idx, 1, res);
                        }
                    }
                    else {
                        res.forEach((resp) => {

                            order.apiResults.push(resp);
                        });
                    }
                    // console.log(order.apiResults);
                    res.forEach((resp) => {
                        if (resp.resultType === "Primary") {
                            existingResults["pVerify"].primary = resp.stringResult;
                        } else if (resp.resultType === "Secondary") {
                            existingResults["pVerify"].secondary = resp.stringResult;
                        }
                    });
                    if (res.resultType === "Primary")
                        existingResults["pVerify"].primary = res.stringResult;

                    this.setState({
                        insuranceError: "Coverage has been updated. Please close the verification window and reopen it to view the new details.",
                        msgColor: 'green',
                        eOrder: order,
                        refreshCoverageDisabled: false,
                        insuranceResults: existingResults,
                    });
                }
                else {
                    this.setState({
                        insuranceError: "Request was submitted, but an error occurred. Please close and reopen the verification window to view any error details.",
                        msgColor: 'red',
                        refreshCoverageDisabled: false,
                    });
                }
                this.viewInsurance();
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    insuranceError: "An error occurred submitting the coverage request: " + err,
                    msgColor: 'red',
                    refreshCoverageDisabled: false,
                });
                this.viewInsurance();
            });
    }

    refreshSameSim() {
        let order = this.state.eOrder,
            existingResults = this.state.insuranceResults;
        let pvSameSim = this.state.mostRecentInsuranceResults.pvSameSim;

        if (pvSameSim && this.checkForCurrentMonth(pvSameSim.createDate) && this.checkApiResultForSuccess("sameSim", pvSameSim.stringResult) && !this.state.allowOverride) {
            this.setState({
                pvSameSimDisabled: true
            });
            return;
        }
        this.setState({
            ranSameSim: true
        });

        InsuranceService.refreshSameSim(order.id)
            .then(resd => {
                // console.log(resd)
                if (resd && resd.length > 0) {
                    existingResults["pVerify"].sameSim = [];
                    resd.forEach(res => {
                        if (res.id && res.id > 0) {
                            if (order.apiResults && order.apiResults.length > 0) {
                                let tmp = order.apiResults.find(x => x.id === res.id);
                                if (tmp) {
                                    var idx = order.apiResults.indexOf(tmp);
                                    order.apiResults.splice(idx, 1, res);
                                }
                            }
                            else {
                                //order.apiResults = [];
                                order.apiResults.push(res);
                            }
                            // console.log(order.apiResults);
                            existingResults["pVerify"].sameSim.push(res.stringResult);
                            // console.log(existingResults);
                            this.setState({
                                eOrder: order,
                                insuranceResults: existingResults,
                            });
                        }

                        if (res.stringResult) {
                            var temp = JSON.parse(res.stringResult);
                            if (temp && (temp.requestId === 0 || temp.apiResponseCode === 4)) {
                                this.setState({
                                    insuranceError: temp.apiResponseMessage,
                                    msgColor: 'red',
                                    //eOrder: order,
                                });
                            }
                            else {
                                this.setState({
                                    insuranceError: "Request Submitted successfully for Same/Sim. Please refresh the page to view any changes.",
                                    msgColor: 'green',
                                    //eOrder: order,
                                });
                            }
                        }
                    });
                }
                this.viewInsurance();
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    insuranceError: "An error occurred submitting the Same/Sim request: " + err,
                    msgColor: 'red',
                });
                this.viewInsurance();
            });

    }

    refreshSnf() {
        let order = this.state.eOrder,
            existingResults = this.state.insuranceResults;
        let pvSnf = this.state.mostRecentInsuranceResults.pvSnf;
            

        if (pvSnf && this.checkForCurrentMonth(pvSnf.createDate) && !this.checkForRTD() && !this.state.allowOverride) {
            this.setState({
                pvSnfDisabled: true
            });
            return;
        }
        this.setState({ ranSNF: true });

        InsuranceService.refreshSNF(order.id)
            .then(res => {
                // console.log(res)
                if (res) {
                    if (res.id && res.id > 0) {
                        if (order.apiResults && order.apiResults.length > 0) {
                            let tmp = order.apiResults.find(x => x.id === res.id);
                            if (tmp) {
                                var idx = order.apiResults.indexOf(tmp);
                                order.apiResults.splice(idx, 1, res);
                            }
                        }
                        else {
                            //order.apiResults = [];
                            order.apiResults.push(res);
                        }
                        // console.log(order.apiResults);
                        existingResults["pVerify"].snf = res.stringResult;
                        // console.log(existingResults);
                        this.setState({
                            eOrder: order,
                            insuranceResults: existingResults,
                        });
                    }

                    if (res.stringResult) {
                        var temp = JSON.parse(res.stringResult);
                        if (temp && (temp.requestId === 0 || temp.apiResponseCode === 4)) {
                            this.setState({
                                insuranceError: temp.apiResponseMessage,
                                msgColor: 'red',
                            });
                        }
                        else {
                            this.setState({
                                insuranceError: "Request Submitted successfully for SNF. Please refresh the page to view the new information.",
                                msgColor: 'green',
                            });
                        }
                    }
                }
                this.viewInsurance();
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    insuranceError: "An error occurred submitting the SNF request: " + err,
                    msgColor: 'red',
                });
                this.viewInsurance();
            });
    }

    createVerificationNote() {
        let order = this.state.eOrder,
            txt = order.id + " " + order.patientName + "\nCoverage Summary",
            insuranceResults = this.state.insuranceResults;

        let pvPrimaryTemp = insuranceResults.pVerify.primary && insuranceResults.pVerify.primary.length > 0 ? JSON.parse(insuranceResults.pVerify.primary) : null;
        let pvSecondaryTemp = insuranceResults.pVerify.secondary && insuranceResults.pVerify.secondary.length > 0 ? JSON.parse(insuranceResults.pVerify.secondary) : null;

        let pvSameSim = insuranceResults.pVerify && insuranceResults.pVerify.sameSim && insuranceResults.pVerify.sameSim.length > 0 ? insuranceResults.pVerify.sameSim.map(s => JSON.parse(s)) : null;
        let pvSnf = insuranceResults.pVerify && insuranceResults.pVerify.snf ? JSON.parse(insuranceResults.pVerify.snf) : null;
        let pvPrimary = [],
            pvSecondary = [];

        let pvSnf2 = {},
            pvSameSim2 = [];

        if (pvPrimaryTemp)
            pvPrimary.push(pvPrimaryTemp);

        if (pvSecondaryTemp)
            pvSecondary.push(pvSecondaryTemp);

        if (pvSnf && pvSnf.APIResponseMessage) {
            pvSnf2 = this.toCamel(pvSnf);
        }

        if (pvSameSim && pvSameSim.length > 0) {
            pvSameSim.forEach(p => {
                pvSameSim2.push(this.toCamel(p));
            });
        }

        let claims = [];
        if (pvSameSim2 && pvSameSim2.length > 0) {
            pvSameSim2.map(s => {
                if (s.claims && s.claims.length > 0) {
                    return s.claims.forEach(sc => {
                        if (sc.hcpcs && sc.hcpcs.length > 0 && GlobalFunctions.containsHcpcs(sc.hcpcs.substring(0,5))) {
                            return claims.push(sc);
                        }
                    });
                }
                return null;
            });
        }
        let details = pvSnf2 && pvSnf2.details && pvSnf2.isDataError !== true ? pvSnf2.details : [];

        let address = null;

        if (pvPrimary && pvPrimary.length > 0) {
            pvPrimary.map(p => {
                //let currentCoverage = null;
                //if (p.ServiceDetails && p.ServiceDetails.length > 0) {
                //	p.ServiceDetails.map((d, i) => {
                //		if (d.ServiceName.indexOf("Durable Medical Equipment") === 0) {
                //			currentCoverage = d;
                //			return currentCoverage;
                //		}
                //		return '';
                //	})
                //}

                if (p.DemographicInfo && p.DemographicInfo.Subscriber && p.DemographicInfo.Subscriber.Address1) {
                    txt += "\nPrimary Member ID: " + (p.DemographicInfo.Subscriber.Identification && p.DemographicInfo.Subscriber.Identification.length > 0 ? p.DemographicInfo.Subscriber.Identification.filter(x => x.Type === "Member ID")[0].Code : order.insurancePolicy);
                    address = "\n" + (p.DemographicInfo.Subscriber.Address1 || "") + "\n" +
						(p.DemographicInfo.Subscriber.Address2 || "") + "\n" +
						(p.DemographicInfo.Subscriber.City || "") + ", " +
						(p.DemographicInfo.Subscriber.State || "") + " " +
						(p.DemographicInfo.Subscriber.Zip || "");
                }
                else {
                    txt += "\nMember ID: " + order.insurancePolicy;
                    address = "\n" + order.address + "\n" + order.city + ", " + order.state + " " + order.zipCode;
                }

                //if (currentCoverage) {
                if (p.PlanCoverageSummary) {
                    [p.PlanCoverageSummary].map(v => {
                        var ll = [];
                        for (var oKey in v) {
                            if (v[oKey] !== null) {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey],
                                });
                            }
                        }
                        if (ll && ll.length > 0) {
                            return ll.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return '';
                    });
                }

                if (p.OtherPayerInfo) {
                    [p.OtherPayerInfo].map(d => {
                        var res = [];
                        for (var pkey in d) {
                            if (d[pkey] !== null && d[pkey] !== "None") {
                                res.push({
                                    name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: d[pkey],
                                });
                            }
                        }
                        if (res && res.length > 0) {
                            return res.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return null;
                    });
                }

                if (p.MedicareInfoSummary) {
                    [p.MedicareInfoSummary].map(v => {
                        var ll = [];
                        for (var oKey in v) {
                            if (oKey.toLowerCase().indexOf("part_a") === -1 &&
									oKey.toLowerCase().indexOf("pt_slp_") === -1 &&
									oKey.toLowerCase().indexOf("ot_capamount") === -1) {
                                if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                    ll.push({
                                        name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                        value: v[oKey].Value,
                                        note: v[oKey].Notes,
                                    });
                                }
                                else if (v[oKey] !== null && v[oKey] !== "None" &&
										oKey.toLowerCase().indexOf("pharmacy") < 0 &&
										!["bin", "pcn"].includes(oKey.toLowerCase())) {
                                    ll.push({
                                        name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                        value: v[oKey],
                                        note: null,
                                    });
                                }
                            }
                        }
                        if (ll && ll.length > 0) {
                            return ll.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return '';
                    });
                }

                if (p.PCPAuthInfoSummary) {
                    [p.PCPAuthInfoSummary].map(d => {
                        var res = [];
                        for (var pkey in d) {
                            if (d[pkey] !== null && d[pkey] !== "None") {
                                res.push({
                                    name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: d[pkey],
                                });
                            }
                        }
                        if (res && res.length > 0) {
                            return res.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return null;
                    });
                }

                if (p.MiscellaneousInfoSummary) {
                    [p.MiscellaneousInfoSummary].map(d => {
                        var res = [];
                        for (var pkey in d) {
                            if (d[pkey] !== null) {
                                res.push({
                                    name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: d[pkey],
                                });
                            }
                        }
                        if (res && res.length > 0) {
                            return res.map(r => {
                                return txt += "\n" + r.name + ": " + r.value;
                            });
                        }
                        return '';
                    });
                }

                if (p.HBPC_Deductible_OOP_Summary) {
                    [p.HBPC_Deductible_OOP_Summary].map(v => {
                        var ll = [];
                        for (var oKey in v) {
                            if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey].Value,
                                });
                            }
                            else if (v[oKey] !== null) {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey],
                                });
                            }
                        }
                        if (ll && ll.length > 0) {
                            return ll.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return '';
                    });
                }

                if (p.DMESummary) {
                    [p.DMESummary].map(v => {
                        var ll = [];
                        for (var oKey in v) {
                            if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey].Value,
                                    note: v[oKey].Notes,
                                });
                            }
                            else if (v[oKey] !== null && v[oKey] !== "None") {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey],
                                    note: null,
                                });
                            }
                        }
                        if (ll && ll.length > 0) {
                            return ll.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return '';
                    });
                }

                txt += "\n";
                //}

                return txt;
            });
        }
        if (pvSecondary && pvSecondary.length > 0) {
            pvSecondary.map(p => {
                //let currentCoverage = null;
                //if (p.ServiceDetails && p.ServiceDetails.length > 0) {
                //	p.ServiceDetails.map((d, i) => {
                //		if (d.ServiceName.indexOf("Durable Medical Equipment") === 0) {
                //			currentCoverage = d;
                //			return currentCoverage;
                //		}
                //		return '';
                //	})
                //}

                if (p.DemographicInfo && p.DemographicInfo.Subscriber && p.DemographicInfo.Subscriber.Address1) {
                    txt += "\nSecondary Member ID: " + (p.DemographicInfo.Subscriber.Identification && p.DemographicInfo.Subscriber.Identification.length > 0 ? p.DemographicInfo.Subscriber.Identification.filter(x => x.Type === "Member ID")[0].Code : order.insurancePolicy);
                    address = "\n" + (p.DemographicInfo.Subscriber.Address1 || "") + "\n" +
						(p.DemographicInfo.Subscriber.Address2 || "") + "\n" +
						(p.DemographicInfo.Subscriber.City || "") + ", " +
						(p.DemographicInfo.Subscriber.State || "") + " " +
						(p.DemographicInfo.Subscriber.Zip || "");
                }
                else {
                    txt += "\nMember ID: " + order.insurancePolicy;
                    address = "\n" + order.address + "\n" + order.city + ", " + order.state + " " + order.zipCode;
                }

                //if (currentCoverage) {
                if (p.PlanCoverageSummary) {
                    [p.PlanCoverageSummary].map(v => {
                        var ll = [];
                        for (var oKey in v) {
                            if (v[oKey] !== null) {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey],
                                });
                            }
                        }
                        if (ll && ll.length > 0) {
                            return ll.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return '';
                    });
                }

                if (p.OtherPayerInfo) {
                    [p.OtherPayerInfo].map(d => {
                        var res = [];
                        for (var pkey in d) {
                            if (d[pkey] !== null && d[pkey] !== "None") {
                                res.push({
                                    name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: d[pkey],
                                });
                            }
                        }
                        if (res && res.length > 0) {
                            return res.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return null;
                    });
                }

                if (p.MedicareInfoSummary) {
                    [p.MedicareInfoSummary].map(v => {
                        var ll = [];
                        for (var oKey in v) {
                            if (oKey.toLowerCase().indexOf("part_a") === -1 &&
								oKey.toLowerCase().indexOf("pt_slp_") === -1 &&
								oKey.toLowerCase().indexOf("ot_capamount") === -1) {
                                if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                    ll.push({
                                        name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                        value: v[oKey].Value,
                                        note: v[oKey].Notes,
                                    });
                                }
                                else if (v[oKey] !== null && v[oKey] !== "None" &&
									oKey.toLowerCase().indexOf("pharmacy") < 0 &&
									!["bin", "pcn"].includes(oKey.toLowerCase())) {
                                    ll.push({
                                        name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                        value: v[oKey],
                                        note: null,
                                    });
                                }
                            }
                        }
                        if (ll && ll.length > 0) {
                            return ll.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return '';
                    });
                }

                if (p.PCPAuthInfoSummary) {
                    [p.PCPAuthInfoSummary].map(d => {
                        var res = [];
                        for (var pkey in d) {
                            if (d[pkey] !== null && d[pkey] !== "None") {
                                res.push({
                                    name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: d[pkey],
                                });
                            }
                        }
                        if (res && res.length > 0) {
                            return res.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return null;
                    });
                }

                if (p.MiscellaneousInfoSummary) {
                    [p.MiscellaneousInfoSummary].map(d => {
                        var res = [];
                        for (var pkey in d) {
                            if (d[pkey] !== null) {
                                res.push({
                                    name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: d[pkey],
                                });
                            }
                        }
                        if (res && res.length > 0) {
                            return res.map(r => {
                                return txt += "\n" + r.name + ": " + r.value;
                            });
                        }
                        return '';
                    });
                }

                if (p.HBPC_Deductible_OOP_Summary) {
                    [p.HBPC_Deductible_OOP_Summary].map(v => {
                        var ll = [];
                        for (var oKey in v) {
                            if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey].Value,
                                });
                            }
                            else if (v[oKey] !== null) {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey],
                                });
                            }
                        }
                        if (ll && ll.length > 0) {
                            return ll.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return '';
                    });
                }

                if (p.DMESummary) {
                    [p.DMESummary].map(v => {
                        var ll = [];
                        for (var oKey in v) {
                            if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey].Value,
                                    note: v[oKey].Notes,
                                });
                            }
                            else if (v[oKey] !== null && v[oKey] !== "None") {
                                ll.push({
                                    name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                                    value: v[oKey],
                                    note: null,
                                });
                            }
                        }
                        if (ll && ll.length > 0) {
                            return ll.map(l => {
                                return txt += "\n" + l.name + ": " + l.value;
                            });
                        }
                        return '';
                    });
                }

                txt += "\n";
                //}

                return txt;
            });
        }
        //txt += "\nSame / Sim: " + (claims && claims.length > 0 ? "Supplier: " + claims[0].supplierName : "No Result Collected");
        txt += "\nSame / Sim Supplier: " + (claims && claims.length > 0 && claims.filter(x => x.supplierName && x.supplierName !== "" && x.supplierName !== null).length > 0 ?
            [...new Set(claims.filter(x => x.supplierName && x.supplierName !== "" && x.supplierName !== null).map(x => x.supplierName))].join(",")
            : "No Result Collected");
        txt += "\nSNF: " + (details && details.length > 0 && details.filter(x => !x.dischargedDate && !x.dosEnd).length > 0 ? "Results Found" : "No Results");

        txt += "\n\nAddress on file: " + address;
        this.setNoteText(txt);
        this.toggleNoteModal();
        this.setState({
            insuranceModal: false,
        });
    }

    renderInsuranceModal() {
        const { currentUser } = this.context;
        let today = new Date();
        let nextMonth = new Date().setMonth(today.getMonth() + 1);
        nextMonth = new Date(nextMonth);
        let st = this.state,
            order = this.state.eOrder;
        let message = this.state.insuranceError;
        let insuranceResults = st.insuranceResults,
            dt = new Date(),
            o = this.state.eOrder.apiResults || [],
            snfs = o.length > 0 ? o.filter(x => x.source === "pVerify" && x.resultType === "SNF") : [],
            sss = o.length > 0 ? o.filter(x => x.source === "pVerify" && x.resultType === "SameSim") : [],
		    refreshCoverageDisabled = this.state.refreshCoverageDisabled;

        sss = sss.length > 0 ? sss.filter(x => {
            var t = JSON.parse(x.stringResult);
            if (t && t.length > 0 && !t.isDataError && t.apiResponseCode !== 4)
                return x;
            else
                return null;
        }) : [];

        snfs = snfs.length > 0 ? snfs.filter(x => {
            var t = JSON.parse(x.stringResult);
            if (t && t.length > 0 && !t.isDataError && t.apiResponseCode !== 4)
                return x;
            else
                return null;
        }) : [];

        let pvPrimary = this.state.mostRecentInsuranceResults.pvPrimary;
        let pvSecondary = this.state.mostRecentInsuranceResults.pvSecondary;
        let pvSameSim = this.state.mostRecentInsuranceResults.pvSameSim;
        let pvSnf = this.state.mostRecentInsuranceResults.pvSnf;
        if (!this.state.pvPrimaryDisabled && pvPrimary && this.checkForCurrentMonth(pvPrimary.createDate) && this.checkApiResultForSuccess("primary", pvPrimary.stringResult)) {
            this.setState({
                pvPrimaryDisabled: true
            });
        }
        if (!this.state.pvSameSimDisabled && pvSameSim && this.checkForCurrentMonth(pvSameSim.createDate) && this.checkApiResultForSuccess("sameSim", pvSameSim.stringResult)) {
            this.setState({
                pvSameSimDisabled: true
            });
        }
        if (!this.state.pvSnfDisabled && pvSnf && this.checkForCurrentMonth(pvSnf.createDate)) {
            this.setState({
                pvSnfDisabled: true
            });
        }

        var snfDate = snfs.length > 0 ? snfs.filter(x => new Date(x.createDate).toLocaleDateString() === dt.toLocaleDateString()) : [],
            ssDate = sss.length > 0 ? sss.filter(x => new Date(x.createDate).toLocaleDateString() === dt.toLocaleDateString()) : [];

        var snfDisplay = snfDate.length > 0 ? "none" : "inline-block",
            ssDisplay = ssDate.length > 0 ? "none" : "inline-block";
        //Check for disabled Coverage reasons
        let disableRefreshCoverageReason = "";
        if (this.state.pvPrimaryDisabled) {
            disableRefreshCoverageReason = "Coverage already ran for this month";
        } else if (this.checkIfAllowedToVerifyInsurance("verification")) {
            if (this.state.ranCoverage) {
                disableRefreshCoverageReason = "Already ran Coverage";
            } else {
                disableRefreshCoverageReason = "";
            }
        } else {
            disableRefreshCoverageReason = "Coverage: Must be in: Verification, Approval, RTD, Review";
        }
        if (this.state.allowOverride && this.checkIfAllowedToVerifyInsurance("verification")) {
            disableRefreshCoverageReason = "";
        } else if (this.state.allowOverride) {
            disableRefreshCoverageReason = "Coverage: Must be in: Verification, Approval, RTD, Review";
        }
        //Check for disabled SamSim reasons
        let disableRefreshSameSimReason = "";
        if (this.state.pvSameSimDisabled) {
            disableRefreshSameSimReason = "Same/Sim already ran for this month";
        } else if (this.checkIfAllowedToVerifyInsurance("samesim")) {
            if (this.state.ranSameSim) {
                disableRefreshSameSimReason = "Already ran Same/Sim";
            } else {
                disableRefreshSameSimReason = "";
            }
        } else {
            disableRefreshSameSimReason = "Same/Sim: Must be in Verification";
        }
        if (this.state.allowOverride && this.checkIfAllowedToVerifyInsurance("samesim")) {
            disableRefreshSameSimReason = "";
        } else if (this.state.allowOverride) {
            disableRefreshSameSimReason = "Same/Sim: Must be in Verification";
        }
        //Check for disabled SNF reasons
        let disableRefreshSnfReason = "";
        if (this.state.pvSnfDisabled) {
            disableRefreshSnfReason = "SNF already ran for this month";
        } else if (this.checkIfAllowedToVerifyInsurance("snf")) {
            if (this.state.ranSNF) {
                disableRefreshSnfReason = "Already ran SNF";
            } else {
                disableRefreshSnfReason = "";
            }
        } else {
            disableRefreshSnfReason = "SNF: Must be in RTD";
        }
        if (this.state.allowOverride && this.checkIfAllowedToVerifyInsurance("snf")) {
            disableRefreshSnfReason = "";
        } else if (this.state.allowOverride) {
            disableRefreshSnfReason = "SNF: Must be in RTD";
        }


        let inRTD = this.checkForRTD();
        if (inRTD) {
            disableRefreshCoverageReason = "";
            // disableRefreshSameSimReason = "";
            disableRefreshSnfReason = "";
        }
        // let disableRefreshCoverageReason2 = (this.state.pvPrimaryDisabled ? "Coverage already ran for this month" :this.checkIfAllowedToVerifyInsurance("verification") ? this.state.ranCoverage ? "Already ran Coverage" : "" : "Coverage: Must be in: Verification, Approval, RTD");
        // let disableRefreshSameSimReason2 = (this.state.pvSameSimDisabled ? "Same/Sim already ran for this month" : this.checkIfAllowedToVerifyInsurance("samesim") ? this.state.ranSameSim ? "Already ran Same/Sim" : "" : "Same/Sim: Must be in Verification");
        // let disableRefreshSnfReason2 = (this.state.pvSnfDisabled ? "SNF already ran for this month" : this.checkIfAllowedToVerifyInsurance("snf") ? this.state.ranSNF ? "Already ran SNF" : "" : "SNF: Must be in RTD");
        if (pvPrimary && pvPrimary.stringResult) {
            let pvPrimaryObj = JSON.parse(pvPrimary.stringResult);
            if (pvPrimaryObj.PlanCoverageSummary && pvPrimaryObj.PlanCoverageSummary.Status === "Inactive") {
                disableRefreshCoverageReason = "";
            }
        }
        if (pvSecondary && pvSecondary.stringResult) {
            let pvSecondaryObj = JSON.parse(pvSecondary.stringResult);
            if (pvSecondaryObj.PlanCoverageSummary && pvSecondaryObj.PlanCoverageSummary.Status === "Inactive") {
                disableRefreshCoverageReason = "";
            }
        }
        let allStrings = disableRefreshCoverageReason + disableRefreshSameSimReason + disableRefreshSnfReason;
        let atLeastOneRanThisMonth = allStrings.includes("this month");

        return (
            <MDBModal
                className="availityModal"
                isOpen={st.insuranceModal}
                toggle={() => {
                    return;
                }}
            >
                <MDBModalHeader style={{ color: 'white', backgroundColor: '#5881c1' }}>Insurance Details</MDBModalHeader>
                <MDBModalBody>
                    {/*{y.length !== 0?*/}
                    {/*	displayNestedObject(JSON.parse(y.availity.primary), 0): ""*/}
                    {/*}*/}
                </MDBModalBody>
                <MDBModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>

                    <div>
                        {!insuranceResults || insuranceResults.length < 2 ? <MDBSpinner small /> : this.renderOptions(insuranceResults)}
                        <div>
                            <Row>
                                <Col size="1">
                                    <Button
                                        data-tip="View all insurance results in a new tab"
                                        floating
                                        size="sm"
                                        onClick={() => {
                                            var o = window.open("data:text/json,", "_blank");
                                            var s = JSON.stringify(insuranceResults);
                                            s = s.replaceAll("{", "{\n");
                                            s = s.replaceAll(",", ",\n");
                                            o.document.open();
                                            o.document.write('<html><body><pre>' + s + '</pre></body></html>');
                                            o.document.close();
                                        }}
                                        color={"primary"}>
                                        <MDBIcon icon="notes-medical" style={{ fontSize: "2em" }} />
                                    </Button>
                                </Col>
                                <Col size="1">
                                    <Button
                                        data-tip="View All Insurance in a Drop-Down Table"
                                        floating
                                        size="sm"
                                        onClick={() => {
                                            this.toggleCollapseModal();
                                        }}
                                        color={"primary"}>
                                        <MDBIcon icon="list-ol" style={{ fontSize: "2em" }} />
                                    </Button>
                                </Col>
                                <Col size="1">
                                </Col>
                                <Col size="2">
                                    {(disableRefreshSnfReason && disableRefreshSameSimReason && disableRefreshCoverageReason) ?
                                        currentUser.role !== "SALES" && (order.status === 1 || order.status === 2) ?
                                            <span data-tip={"No insurance checks currently allowed"} style={{ display: "flex" }}>
                                                <Button
                                                    className="refreshInsuranceButtons"
                                                    disabled={true}
                                                    color={"primary"}>
													Refresh All
                                                </Button>
                                            </span>
                                            : ""
                                        :
                                        currentUser.role !== "SALES" && (order.status === 1 || order.status === 2) ?
                                            <Button
                                                className="refreshInsuranceButtons"
                                                disabled={refreshCoverageDisabled || this.state.allowOverride}
                                                data-tip="Refresh or Submit new requests for coverage, same/sim and SNF info"
                                                onClick={() => {
                                                    if (order.insuranceSubTypeId || order.secondaryInsuranceSubTypeId) {
                                                        if (!order.insurancePolicy && !order.mbi && !order.secondaryInsurancePolicy) {
                                                            toast.error("Policy # is required to submit verification.");
                                                            return;   
                                                        }
                                                    }
                                            
                                                    if (order.insuranceSubTypeId && order.secondaryInsuranceSubTypeId)  {
                                                        if ((!order.insurancePolicy && !order.mbi) && order.secondaryInsurancePolicy) {
                                                            toast.warning("Policy # is missing on primary insurance.");
                                                        }
                                                        if ((order.insurancePolicy || order.mbi) && !order.secondaryInsurancePolicy) {
                                                            toast.warning("Policy # is missing on secondary insurance.");
                                                        }
                                                    }
                                                    this.setState({
                                                        refreshCoverageDisabled: true,
                                                    });
                                                    this.refreshPverifyCoverage();
                                                    this.refreshSameSim();
                                                    this.refreshSnf();
                                                }}
                                                color={"primary"}>
												Refresh All
                                            </Button>
                                            : ""
                                    }
                                </Col>
                                <Col size="2">
                                    <span data-tip={disableRefreshCoverageReason}>
                                        {currentUser.role !== "SALES" ?
                                            disableRefreshCoverageReason ?
                                                <div>
                                                    {(order.insuranceSubType && order.insuranceSubType.pvPayorId) || (order.secondaryInsuranceSubType && order.secondaryInsuranceSubType.pvPayorId) ?
                                                        <Button
                                                            className="refreshInsuranceButtons"
                                                            disabled={true}
                                                            color={"primary"}>
                                                            {disableRefreshCoverageReason}
                                                        </Button>
                                                        : ""}
                                                </div> :
                                                <div>
                                                    {(order.insuranceSubType && order.insuranceSubType.pvPayorId) || (order.secondaryInsuranceSubType && order.secondaryInsuranceSubType.pvPayorId) ?
                                                        <Button
                                                            className="refreshInsuranceButtons"
                                                            disabled={refreshCoverageDisabled}
                                                            data-tip="Refresh or Submit a new request for coverage info"
                                                            onClick={() => {
                                                                if (order.insuranceSubTypeId || order.secondaryInsuranceSubTypeId) {
                                                                    if (!order.insurancePolicy && !order.mbi && !order.secondaryInsurancePolicy) {
                                                                        toast.error("Policy # is required to submit verification.");
                                                                        return;   
                                                                    }
                                                                }
                                                        
                                                                if (order.insuranceSubTypeId && order.secondaryInsuranceSubTypeId)  {
                                                                    if ((!order.insurancePolicy && !order.mbi) && order.secondaryInsurancePolicy) {
                                                                        toast.warning("Policy # is missing on primary insurance.");
                                                                    }
                                                                    if ((order.insurancePolicy || order.mbi) && !order.secondaryInsurancePolicy) {
                                                                        toast.warning("Policy # is missing on secondary insurance.");
                                                                    }
                                                                }
                                                                this.setState({
                                                                    refreshCoverageDisabled: true,
                                                                });
                                                                this.refreshPverifyCoverage();
                                                                this.setState({ allowOverride: false });
                                                            }}
                                                            color={"primary"}>
															Refresh Coverage
                                                        </Button>
                                                        : ""}
                                                </div>
                                            : ""}
                                    </span>
                                </Col>
                                {((order && order.insuranceSubType && order.insuranceSubType.pvPayorId) || (order.mbi || order.patient.mbi)) && currentUser.role !== "SALES" ?
                                    <Col size="2">
                                        <span data-tip={disableRefreshSameSimReason ? disableRefreshSameSimReason : "Refresh or Submit a new request for Same/Sim info"} style={{ display: "flex" }}>
                                            {disableRefreshSameSimReason ?
                                                <Button
                                                    className="refreshInsuranceButtons"
                                                    disabled={true}
                                                    style={{ display: ssDisplay }}
                                                    color={"primary"}>
                                                    {disableRefreshSameSimReason}
                                                </Button>
                                                :
                                                <Button
                                                    className="refreshInsuranceButtons"
                                                    style={{ display: ssDisplay }}
                                                    onClick={() => {
                                                        if (order.insuranceSubTypeId || order.secondaryInsuranceSubTypeId) {
                                                            if (!order.insurancePolicy && !order.mbi && !order.secondaryInsurancePolicy) {
                                                                toast.error("Policy # is required to submit verification.");
                                                                return;   
                                                            }
                                                        }
                                                
                                                        if (order.insuranceSubTypeId && order.secondaryInsuranceSubTypeId)  {
                                                            if ((!order.insurancePolicy && !order.mbi) && order.secondaryInsurancePolicy) {
                                                                toast.warning("Policy # is missing on primary insurance.");
                                                            }
                                                            if ((order.insurancePolicy || order.mbi) && !order.secondaryInsurancePolicy) {
                                                                toast.warning("Policy # is missing on secondary insurance.");
                                                            }
                                                        }
                                                        this.refreshSameSim()
                                                    }}
                                                    color={"primary"}>
													Refresh Same/Sim
                                                </Button>
                                            }
                                        </span>
                                    </Col>
                                    : ""}
                                {((order && order.insuranceSubType && order.insuranceSubType.pvPayorId) || (order.mbi || order.patient.mbi)) && currentUser.role !== "SALES" && (order.status === 1 || order.status === 2) ?
                                    <Col size="2">
                                        {disableRefreshSnfReason ?
                                            <span data-tip={disableRefreshSnfReason} style={{ display: "flex" }}>
                                                <Button
                                                    disabled={true}
                                                    style={{ display: snfDisplay }}
                                                    color={"primary"}>
                                                    {disableRefreshSnfReason}
                                                </Button>
                                            </span>
                                            :
                                            <Button
                                                style={{ display: snfDisplay }}
                                                data-tip="Refresh or Submit a new request for SNF info"
                                                onClick={() => {
                                                    if (order.insuranceSubTypeId || order.secondaryInsuranceSubTypeId) {
                                                        if (!order.insurancePolicy && !order.mbi && !order.secondaryInsurancePolicy) {
                                                            toast.error("Policy # is required to submit verification.");
                                                            return;   
                                                        }
                                                    }
                                            
                                                    if (order.insuranceSubTypeId && order.secondaryInsuranceSubTypeId)  {
                                                        if ((!order.insurancePolicy && !order.mbi) && order.secondaryInsurancePolicy) {
                                                            toast.warning("Policy # is missing on primary insurance.");
                                                        }
                                                        if ((order.insurancePolicy || order.mbi) && !order.secondaryInsurancePolicy) {
                                                            toast.warning("Policy # is missing on secondary insurance.");
                                                        }
                                                    }
                                                    this.refreshSnf()
                                                }}
                                                color={"primary"}>
												Refresh SNF
                                            </Button>
                                        }
                                    </Col>
                                    : ""}
                                <Col size="1">
                                    <Button
                                        style={{ float: "right" }}
                                        floating
                                        size="sm"
                                        color={"orange"}
                                        data-tip={"Create Verification Note"}
                                        onClick={() => {
                                            this.props.handleOnClick();
                                            this.createVerificationNote();
                                        }}
                                    >
                                        <MDBIcon icon="notes-medical" style={{ fontSize: "2em" }} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {(this.state.eOrder.orderStatusReasonId === 15 || this.state.eOrder.orderStatusReasonId === 21) && atLeastOneRanThisMonth ?
                        !this.state.allowOverride ? <MUIAlert
                            severity="warning"
                            // variant="filled"
                            action={
                                <MUIButton
                                    color="inherit"
                                    size="small"
                                    onClick={() => { this.setState({ allowOverride: true }); }}
                                >
									Override
                                </MUIButton>
                            }
                        >{`Eligibility has already been ran this month and cannot be ran again until ${nextMonth.toLocaleString('en-US', { month: 'long' })}. `}</MUIAlert>
                            :
                            <></>
                        :
                        <></>}
                    {this.state.allowOverride ? <MUIAlert
                        severity="error"
                    >{`Calander month restriction has been overridden.`}</MUIAlert>
                        :
                        <></>}


                    <ReactTooltip />
                </MDBModalBody>
                <MDBModalFooter>
                    <Row style={{ width: "100%" }}>
                        <Col size="10" style={{ color: this.state.msgColor }}>
                            <b>{message}</b>
                        </Col>
                        <Col size="2">
                            <MDBCloseIcon
                                style={{ right: "0" }}
                                onClick={this.toggleInsuranceModal}
                                className={"closeBtn"}
                            />
                        </Col>
                    </Row>
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderCollapseModal() {
        let parentTrack = 0;
        let t = this;


        function flipCollapse(c) {
            let collapse = t.state.collapse;
            let val = t.state.collapse[c];

            if (val != null) {
                val = !val;
            } else {
                val = true;
            }
            collapse[c] = val;

            t.setState({
                collapse: collapse
            });
        }

        function displayNestedObject(obj, count) {
            let keyObj = [];
            let keyString = [];
            Object.keys(obj).map(function (key, idx) {
                if (typeof obj[key] === 'object'  && obj[key] !== null) {
                    return keyObj.push(key);
                } else {
                    return keyString.push(key);
                }
            });
            let keys = keyObj.concat(keyString);

            return keys.map(function (key, idx) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {

                    parentTrack++;
                    let collapseTracker = parentTrack;
                    // If the current value is an object, display as a header and recursively call this function
                    return <div style={{marginLeft: 10 + "px", fontWeight: "bold"}}>
                        <MDBCollapseHeader className={"insuranceDetailsHeaders"}
										   style={{marginLeft: count * 10 + "px", fontWeight: "bold"}}
										   onClick={() => flipCollapse(collapseTracker)}>
                            <MDBIcon icon={t.state.collapse[parentTrack] ? "caret-up" : "caret-down"}/>
                            {"  " + key}
                        </MDBCollapseHeader>
                        <div className={"insuranceDetailsDiv"}>
                            <MDBCollapse isOpen={t.state.collapse[parentTrack]}>
                                <div>
                                    {displayNestedObject(obj[key], count + 1)}
                                </div>
                            </MDBCollapse>
                        </div>
                    </div>;
                } else {
                    let display = obj[key] ? obj[key] : "None";
                    // Otherwise, return the key-value pair
                    return (<div style={{marginLeft: 10 + "px", fontWeight: "normal"}}>{key + ': ' + display}</div>);
                }
            });
        }
        let st = this.state;
        return (
            <MDBModal
                className="availityModal"
                isOpen={st.collapseModal}
                toggle={() => {
                    this.toggleCollapseModal();
                }}
            >
                <MDBModalHeader style={{color: 'white', backgroundColor: '#5881c1'}}>Insurance Details</MDBModalHeader>
                <MDBModalBody>
                    {st.jsonResults ?
                        displayNestedObject(JSON.parse(st.jsonResults), 0) : ""
                    }
                </MDBModalBody>

                <MDBModalFooter>
                    <MDBCloseIcon
                        style={{right: "0"}}
                        onClick={this.toggleCollapseModal}
                        className={"closeBtn"}
                    />
                </MDBModalFooter>
            </MDBModal>
        );
    }

    checkAndOpenDialogPatientIssueAlert() {
        const getShowAlertPatientIssues = () => {
            const hasCollectionsBalance = this.state.eOrder.patient.collectionsBalance > 0 ? this.state.eOrder.patient.collectionsBalance : false;

            const alertFeedback = this.state.eOrder.patient.patientIssue.filter((feedback) => {
                const issueReason = this.context.issueReasonsIndexed[feedback.issueReasonId];
                const openStatus = feedback.issueStatus === 0;
                return openStatus && issueReason && issueReason.showAlert === true;
            });
            
            // Sort the filtered feedback by issueLoggedOn from oldest to newest
            alertFeedback.sort((a, b) => new Date(a.issueLoggedOn) - new Date(b.issueLoggedOn));

            if (hasCollectionsBalance) {
                alertFeedback.push({     
                    collectionsBalance: this.state.eOrder.patient.collectionsBalance,  
                    description: "Collections Balance"  
                });
            }  

            return alertFeedback
        }

        const showAlertPatientIssues = getShowAlertPatientIssues();

        if (showAlertPatientIssues && showAlertPatientIssues.length > 0) {
            this.setState({
                showAlertPatientIssueDialogOpen: true,
                showAlertPatientIssues: showAlertPatientIssues,
            })
        } else {
            return false
        }
    }

    renderDialogPatientIssueAlert() { 
        return (
            <DialogPatientIssueAlert
                open={this.state.showAlertPatientIssueDialogOpen}
                onClose={() => this.setState({ showAlertPatientIssueDialogOpen: false })}
                header={"Alert"}
                patientIssues={this.state.showAlertPatientIssues}
                onClick={(patientIssue) => {
                   this.props.history.push({
                        pathname: `/patientInformation/${patientIssue.patientId}/${patientIssue.orderId}`,
                        state: { openOnFeedback: true }
                    });
                }}
            />
        );
    }
 
    saveInsuranceDetails() {
        const {currentUser} = this.context;

        let eo = this.state.eOrder;

        let raw = convertToRaw(this.state.editorState.getCurrentContent());
        let tojs = JSON.stringify(raw);

        let sid = {
            name: "Hello",
            lastUpdatedOn: new Date(),
            lastUpdatedById: currentUser.id,
            lastUpdatedBy: {
                name: currentUser.username,
                id: currentUser.id
            },
            insuranceSubTypeId: eo.insuranceSubType.id,
            insuranceSubType: {
                name: eo.insuranceSubType.name,
                id: eo.insuranceSubType.id
            },
            details: tojs
        };

        InsuranceService.createSubInsuranceDetails(sid)
            .then((res) => {
                if(res != null){
                    toast.success("Details successfully added.");
                }
                else if(res.length === 0){
                    toast.warn("No details are available for this insurance.");
                }
                else{
                    toast.warn("There are no details available for this insurance.");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    renderInsuranceDetailsModal() {
        return (
            <MDBModal
                toggle={() => {
                    return;
                }}
                isOpen={this.state.detailsModalOpen}
                size={"lg"}
                // fullHeight
                // position="left"
                noClickableBodyWithoutBackdrop={true}
                overflowScroll={true}>
                <MDBModalHeader style={{backgroundColor: "#5881C1", color: "white"}}
                    toggle={() => this.setState({detailsModalOpen: false})}>
                   Insurance Details
                </MDBModalHeader>
                <MDBModalBody>

                    <Editor
                        editorState={this.state.editorState}
                        //initialEditorState={this.state.editorState}
                        toolbarHidden
                        readOnly
                        // toolbarClassName="toolbarClassName"
                        // wrapperClassName="wrapperClassName"
                        // editorClassName="editorClassName"
                        //onEditorStateChange={this.onEditorStateChange}
                    />

                </MDBModalBody>
            </MDBModal>
        );
    }

    renderBillingLocationDropdown() {
        const getLocations = () => {
            return Location.sort(Object.values(this.context.locationsIndexed))
                .filter(location => location.billingLocationId === location.id)
                .map(location => ({
                    title: location.name,
                    value: location.id
                }));
        }

        const selectedLocation = this.state.eOrder.billingLocationId
            ? getLocations().find(option => option.value === this.state.eOrder.billingLocationId)
            : null;

        return (
            <MDBCol size="12" className={"insuranceSelects nestedNoPadding"} >
                <MUIAutoComplete
                    options={getLocations()}
                    value={selectedLocation} 
                    disableClearable
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.title} // Tell MUI to use 'title' as the label
                    onChange={(e, value) => this.setAndUpdate("billingLocationId", value ? value.value : null)}
                    renderInput={(params) => <MUITextField {...params}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        label="Billing Location"
                        fullWidth
                    />}
                />
            </MDBCol>
        )
    }

    renderAuthNumberInput() {
        let eOrder = this.state.eOrder,
            approval = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        return (
            <MDBCol size="12" className={"insuranceSelects nestedNoPadding"}>
                <MUITextField
                    value={approval ? approval.authNumber : ""}
                    size="small"
                    label="Auth Number"
                    fullWidth={true}
                    inputProps={{
                        maxLength: 255,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => this.approvalAndUpdate("authNumber", e.target.value)}
                    disabled={this.state.statusSetup}
                />
            </MDBCol>
        );
    }

    renderBaseCushionUpgradeRadioGroup() {
        let eOrder = this.state.eOrder,
            approval = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        return (
            <MDBCol size="12" style={{ paddingLeft: 4, paddingTop: 4 }}>
                <MUIFormControl>
                    <MUIFormLabel sx={{mb: 0}}>Base/Cushion Upgrade?</MUIFormLabel>
                    <MUIRadioGroup
                        row
                        value={approval.unapprovedUpgrade !== undefined ? approval.unapprovedUpgrade : null}
                        onChange={(e) => this.approvalAndUpdate("unapprovedUpgrade", e.target.value === "true")}>
                        <MUIFormControlLabel
                            value={true}
                            control={<MUIRadio size="small" />}
                            label="Yes"
                        />
                        <MUIFormControlLabel
                            value={false}
                            control={<MUIRadio size="small" />}
                            label="No"
                        />
                    </MUIRadioGroup>
                </MUIFormControl>
            </MDBCol>
        );
    }

    renderPlaceOfServiceDropdown() {
        let eOrder = this.state.eOrder,
            approval = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;
 
        const getPlaceOfServiceOptions = () => {
            return Term.sort(Object.values(this.context.termsIndexed))
                .filter(term => term.type === 'place_of_service')
                .map(term => ({
                    title: term.name,
                    value: term.id
                }));
        }

        const selectedPlaceOfService = approval.placeOfServiceTermId
            ? getPlaceOfServiceOptions().find(option => option.value === approval.placeOfServiceTermId)
            : null;

        return (
            <MDBCol size="12" className={"insuranceSelects nestedNoPadding"} >
                <MUIAutoComplete
                    options={getPlaceOfServiceOptions()}
                    value={selectedPlaceOfService}
                    disableClearable
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, value) => this.approvalAndUpdate("placeOfServiceTermId", value ? value.value : null)}
                    renderInput={(params) => <MUITextField {...params}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        label="Place of Service"
                        fullWidth
                    />}
                />
            </MDBCol>
        )
    }

    renderActiveRentalSameSimRadioGroup() {
        let eOrder = this.state.eOrder,
            approval = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        return (
            <MDBCol size="12" style={{ paddingLeft: 4, paddingTop: 4 }}>
                <MUIFormControl>
                    <MUIFormLabel sx={{ mb: 0 }}>Active Rental Same/Sim?</MUIFormLabel>
                    <MUIRadioGroup
                        row
                        value={approval.activeRentalSameOrSimilar !== undefined ? approval.activeRentalSameOrSimilar : null}
                        onChange={(e) => this.approvalAndUpdate("activeRentalSameOrSimilar", e.target.value === "true")}>
                        <MUIFormControlLabel
                            value={true}
                            control={<MUIRadio size="small" />}
                            label="Yes"
                        />
                        <MUIFormControlLabel
                            value={false}
                            control={<MUIRadio size="small" />}
                            label="No"
                        />
                    </MUIRadioGroup>
                </MUIFormControl>
            </MDBCol>
        );
    }

    renderFinancialHardshipRadioGroup() {
        let eOrder = this.state.eOrder,
            approval = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        return (
            <MDBCol size="12" style={{ paddingLeft: 4 }}>
                <MUIFormControl>
                    <MUIFormLabel sx={{ mb: 0 }}>Financial Hardship?</MUIFormLabel>
                    <MUIRadioGroup
                        row
                        value={approval.financialHardship !== undefined ? approval.financialHardship : null}
                        onChange={(e) => this.approvalAndUpdate("financialHardship", e.target.value === "true")}>
                        <MUIFormControlLabel
                            value={true}
                            control={<MUIRadio size="small" />}
                            label="Yes"
                        />
                        <MUIFormControlLabel
                            value={false}
                            control={<MUIRadio size="small" />}
                            label="No"
                        />
                    </MUIRadioGroup>
                </MUIFormControl>
            </MDBCol>
        );
    }

    renderRentalOrPurchaseButtonGroup() {
        let eOrder = this.state.eOrder,
            approval = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        return (
            <MDBCol size="12" style={{padding: 0}}>
                <MUIButtonGroup fullWidth sx={{mb: 2}}>
                    <MUIButton variant={approval.rental === null || approval.rental === false ? "outlined" : "contained"} onClick={() => this.approvalAndUpdate("rental", true)}>Rental</MUIButton>
                    <MUIButton variant={approval.rental === null || approval.rental === true ? "outlined" : "contained"} onClick={() => this.approvalAndUpdate("rental", false)}>Purchase</MUIButton>
                </MUIButtonGroup>
            </MDBCol>
        )
    }   

    renderCommissionTierDropdown() {
        let eOrder = this.state.eOrder,
            approval = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null;

        const commissionTierOptions = () => {
            return Term.sort(Object.values(this.context.termsIndexed))
                .filter(term => term.type === 'commission_tier')
                .map(term => ({
                    title: term.name,
                    value: term.id
                }));
        }

        const selectedCommissionTier = approval.commissionTierTermId
            ? commissionTierOptions().find(option => option.value === approval.commissionTierTermId)
            : null;
 
        return (
            <MDBCol size="12" className={"insuranceSelects nestedNoPadding"} >
                <MUIAutoComplete
                    options={commissionTierOptions()}
                    value={selectedCommissionTier}
                    disableClearable
                    isOptionEqualToValue={(option, value) => option.value === value.title}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, value) => this.approvalAndUpdate("commissionTierTermId", value ? value.value : null)}
                    renderInput={(params) => <MUITextField {...params}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        label="Commission/Tier"
                        fullWidth
                    />}
                />
            </MDBCol>
        )
    }
 
    render() {
        return (
            <div>
                <MDBRow>
                    <MDBCol size="8">
                        {this.renderInfoSection()}
                        <div style={{ position: "relative" }}>
                            {this.renderDiseaseSection()}
                        </div>

                        <MDBRow>
                            <MDBCol size="6">{this.renderPrimarySection()}</MDBCol>

                            <MDBCol size="6">{this.renderSecondarySection()}</MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="12">{ this.renderClinicalInformation() }</MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol size="4">
                        <MDBRow>
                            <MDBCol size="12">{this.renderContactSection()}</MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12">
                                <div
                                    style={{
                                        border: "1px solid #7986cb",
                                        margin: 8,
                                        padding: 15,
                                        marginTop: 20,
                                    }}
                                    id="patientApprovalInformation"
                                >
                                    <label className={"insuranceLabel labelPosition labelPosition"}>
										Approval Information
                                    </label>
                                    {this.renderBillingLocationDropdown()}
                                    {this.renderAuthNumberInput()}
                                    {this.renderBaseCushionUpgradeRadioGroup()}
                                    {this.renderPlaceOfServiceDropdown()}
                                    {this.renderActiveRentalSameSimRadioGroup()}
                                    {this.renderFinancialHardshipRadioGroup()}
                                    {this.renderRentalOrPurchaseButtonGroup()} 
                                    {this.renderCommissionTierDropdown()}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                {this.renderGoogleModal()}
                {this.renderInsuranceModal()}
                {this.renderCollapseModal()}
                {this.renderInsuranceDetailsModal()}
                {this.renderDialogPatientIssueAlert()}
                {this.renderSnfSameSimModal()}  
                <ReactTooltip />
                <hr />
            </div>
        );
    }
}
