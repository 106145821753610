import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
    Spinner,
    ToastContainer,
    Chip,
    toast,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
} from "mdbreact";
import "./account.css";
import AccountService from "../Security/AccountService/accountService";
import LayerLegacy from "../../LayerLegacy";

export default class accountEdit extends LayerLegacy {
    constructor(props) {
        super(props);

        let account = {},
            isLoaded = false,
            id = props.match.params.id;

        if (props.location.state != null) {
            account = props.location.state.account;
            isLoaded = true;
        } else {
            this.retrieveAccount(id);
        }

        this.state = {
            isLoaded: isLoaded,
            account: account,
            eAccount: JSON.parse(JSON.stringify(account)),
            accountTypes: [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        accountTypes: PropTypes.array,
    };

    retrieveAccount(id) {
        return AccountService.getAccount(id)
            .then((data) => {
                this.setState({
                    account: data.account,
                    eAccount: JSON.parse(JSON.stringify(data.account)),
                    isLoaded: true,
                });
            });
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    validateForm() {
        let e = this.state.eAccount;

        if (e.name === "") {
            return "Please enter an Account Name.";
        } else if (Object.keys(e.owner).length === 0) {
            return "Select Owner";
        } else if (e.address.city === "") {
            return "Enter City";
        } else if (e.address.state === "") {
            return "Enter State";
        } else if (
            e.accountType === null ||
			Object.keys(e.accountType).length === 0
        ) {
            return "Select an Account Type";
        }
        return true;
    }

    renderErrorMessage() {
        let res = this.validateForm(),
            color = "green darken-2",
            text = "Save",
            onClick = this.updateAccount.bind(this);

        //if the form is not valid, change the style...BC
        if (res !== true) {
            text = res;
            color = "pink darken-4";
            onClick = () => {
                return;
            };
        }

        //return the chip...BC
        return (
            <Chip
                className="accountHeader"
                size="lg"
                bgColor={color}
                text="white"
                onClick={onClick}
            >
                {text}
            </Chip>
        );
    }

    updateAccount() {
        let acc = this.state.eAccount;
        return AccountService.updateAccount(acc)
            .then((data) => {
                if(data){
                    toast.success("Saved Successfully!");
                }
            })
            .catch((err) => {
                toast.warn("An error occurred while saving.");
            });
    }

    changeAccountType(accountType) {
        let oAccount = this.state.eAccount;
        oAccount.accountType = { id: accountType.id, name: accountType.name };
        this.setState({ eAccount: oAccount });
    }

    handleChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const nAccount = this.state.eAccount;
        nAccount[property] = event.target.value;
        this.setState({ eAccount: nAccount });
    };

    handleObjChange = (obj, property, event) => {
        //update the property on the object that the user has edited in the order...BC
        const nAccount = this.state.eAccount;
        if (property === "lines") {
            nAccount[obj][property][0] = event.target.value;
        } else {
            nAccount[obj][property] = event.target.value;
        }
        this.setState({ eAccount: nAccount });
    };

    renderContent() {
        const { accountTypes } = this.context;
        let account = this.state.eAccount;
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }

        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Container className="mt-5 w-50">
                    <Card>
                        <CardBody>
                            <CardHeader
                                style={{ backgroundColor: "#324191", color: "white" }}
                                className="form-header text-center"
                            >
                                <h2>
                                    {this.state.account.name}
                                    {this.renderErrorMessage()}
                                </h2>
                            </CardHeader>

                            <Input
                                value={account.name || ""}
                                onChange={this.handleChange.bind(this, "name")}
                                label="Account Name"
                                size="sm"
                                icon="user"
                            />
                            <Input
                                value={account.owner ? account.owner.firstname + " " + account.owner.lastname : ""}
                                label="Owner"
                                size="sm"
                                icon="briefcase"
                                readOnly
                            />
                            <Input
                                value={account.group ? account.group.name : ""}
                                label="Office"
                                size="sm"
                                icon="briefcase"
                                readOnly
                            />
                            <Input
                                value={account.address ? account.address.lines[0] : ""}
                                onChange={this.handleObjChange.bind(this, "address", "lines")}
                                label="Address"
                                size="sm"
                                icon="address-book"
                            />
                            <Input
                                value={account.address ? account.address.city : ""}
                                onChange={this.handleObjChange.bind(this, "address", "city")}
                                label="City"
                                size="sm"
                                icon="location-arrow"
                            />
                            <Input
                                value={account.address ? account.address.state : ""}
                                onChange={this.handleObjChange.bind(this, "address", "state")}
                                label="State"
                                size="sm"
                                icon="map-pin"
                            />
                            <Input
                                value={account.address ? account.address.zip : ""}
                                onChange={this.handleObjChange.bind(this, "address", "zip")}
                                label="ZipCode"
                                size="sm"
                                icon="map-pin"
                            />
                            <Input
                                value={account.phone || ""}
                                onChange={this.handleChange.bind(this, "phone")}
                                label="Phone"
                                size="sm"
                                icon="phone"
                            />
                            <Input
                                value={account.fax || ""}
                                onChange={this.handleChange.bind(this, "fax")}
                                label="Fax"
                                size="sm"
                                icon="fax"
                            />
                            <Input
                                value={account.email || ""}
                                onChange={this.handleChange.bind(this, "email")}
                                label="E-mail"
                                size="sm"
                                icon="envelope"
                            />
                            <Row>
                                <Col md={6}>
                                    <Dropdown>
                                        <DropdownToggle caret color="primary">
                                            {account.accountType
                                                ? account.accountType.name
                                                : "Account Type(required)"}
                                        </DropdownToggle>
                                        <DropdownMenu basic>
                                            {accountTypes.map((type, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={index}
                                                        onClick={this.changeAccountType.bind(this, type)}
                                                    >
                                                        {type.name}{" "}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col md={6}>
                                    <Button
                                        style={{ float: "right" }}
                                        color="secondary"
                                        onClick={() => this.props.history.push("/accounts")}
                                    >
										Back
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }
}
