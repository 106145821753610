import React from "react";
import MUIBox from "@mui/material/Box";
import MUIContainer from "@mui/material/Container";
import MUIThemeProvider from '@mui/material/styles/ThemeProvider';
import MUICreateTheme from '@mui/material/styles/createTheme';
import MUICssBaseline from '@mui/material/CssBaseline';
import MUIGreyColor from "@mui/material/colors/grey";
import Setting from "./Utility/Setting";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { debugIntegration } from "@sentry/react";

export default class Layer extends React.Component {
    static contextTypes = {
        loaded: PropTypes.bool,
        setContextItem: (key, value) => { },
        setCurrentUser: PropTypes.func,
        setCompanyName: PropTypes.func,
        company: PropTypes.string,
        companyName: PropTypes.string,
        currentUser: PropTypes.object,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        reasons: PropTypes.array,
        therapists: PropTypes.array,
        salesReps: PropTypes.array,
        accounts: PropTypes.array,
        myAccounts: PropTypes.array,
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        allRegions: PropTypes.array,
        allUsers: PropTypes.array,
        userRefs: PropTypes.array,
        allActiveUsers: PropTypes.array,
        allLiaisons: PropTypes.array,
        allPCR: PropTypes.array,
        allAtp: PropTypes.array,
        thirdPartyUsers: PropTypes.array,
        thirdPartyUsersLoaded: PropTypes.bool,
        patientCommunicationTriggerDefs: PropTypes.array,
        cardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
        documentTypes: PropTypes.array,
        contactTypes: PropTypes.array,
        accountTypes: PropTypes.array,
        techs: PropTypes.array,
        marketingCampaigns: PropTypes.array,
        complaintCategories: PropTypes.array,
        complaintReasons: PropTypes.array,
        allVendors: PropTypes.array,
        allBaseUnits: PropTypes.array,
        allBaseUnitTypes: PropTypes.array,
        productOverrides: PropTypes.array,
        fullLiaisonKeys: PropTypes.array,
        timezoneVariation: PropTypes.number,
        stateSalesTax: PropTypes.array,
        reverseQualityCategories: PropTypes.array,
        reverseQualityReasons: PropTypes.array,
        updateIndexed: PropTypes.func,
        deleteIndexed: PropTypes.func,
        termsIndexed: PropTypes.object,
        hcpcsCodesIndexed: PropTypes.object,
        distributionReasonsIndexed: PropTypes.object,
        userProductionTypesIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        teamUsersIndexed: PropTypes.object,
        teamQueuesIndexed: PropTypes.object,
        queueRoutesIndexed: PropTypes.object,
        queuesIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        issueStatusesIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        insuranceTypesIndexed: PropTypes.object,
        insuranceSubTypesIndexed: PropTypes.object,
        serviceReasonsIndexed: PropTypes.object,
        baseUnitTypesIndexed: PropTypes.object,
        baseUnitsIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
        colorsIndexed: PropTypes.object,
        locationsIndexed: PropTypes.object,
        regionsIndexed: PropTypes.object,
        productTypesIndexed: PropTypes.object,
        productSubTypesIndexed: PropTypes.object,
        issueCategoriesIndexed: PropTypes.object,
        issueReasonsIndexed: PropTypes.object,
        salesPcrLinksIndexed: PropTypes.object,
        marketingCampaignsIndexed: PropTypes.object,
        marketingCampaignCategoriesIndexed: PropTypes.object,
        userprofileLocationsIndexed: PropTypes.object,
        accountTypesIndexed: PropTypes.object,
        contactTypesIndexed: PropTypes.object,
        documentTypesIndexed: PropTypes.object,
        reverseQualityReasonsIndexed: PropTypes.object,
        creditCardTypesIndexed: PropTypes.object,
        expenseTypesIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
            theme: Setting.get("ui.theme") || "light"
        };
    }

    handleThemeStorage = () => {
        this.setState({
            theme: Setting.get("ui.theme")
        });
    };

    /**
     * Context is not available in the constructor, so update the state with
     * this info after the initial render.
     *
     * Do not override this; just use afterComponentDidMount().
     */
    componentDidMount() {
        const url = new URL(window.location);
        if (
            this.context.loaded === true ||
            url.pathname === "/authorize" ||
            url.pathname === "/" ||
            url.pathname === "/load" ||
            url.pathname === "/logout"
        ) {
            this.setState({
                mounted: true
            });
            document.body.scrollTo(0, 0);
            window.addEventListener('setting.ui.theme', this.handleThemeStorage);
            this.afterComponentDidMount();
        }
    }

    /**
     * Override this in the subclass if needed.
     */
    afterComponentDidMount() {
        return null;
    }

    /**
     * Remover the event listener.
     */
    componentWillUnmount() {
        window.removeEventListener('setting.ui.theme', this.handleThemeStorage);
    }

    /**
     * Render a layer inside some containers. Requires this component to be
     * mounted before rendering anything. To further explain: Layers are often
     * reading data from context and placing it in state to be mutated. Context
     * is not accessible in the constructor, so it must be done in
     * componentDidMount(). This means that the component will render, mount,
     * set state, then render again. The second render isn't a performance
     * problem, but it does require coding around the missing data which is
     * annoying. To fix that, this basically only performs the second render.
     */
    render() {
        this.setDocumentTitle();
        this.copyCredentialsToSessionStorage();

        const url = new URL(window.location);

        // If you are not logged in and you're not on one of these layers,
        // redirect to the login layer.

        if (
            !Setting.get("session") &&
            url.pathname !== "/authorize" &&
            url.pathname !== "/" &&
            url.pathname !== "/load"
        ) {
            return (
                <Redirect to={{ pathname: "/", state: { from: url.pathname } }} />
            );
        }

        /**
         * If Sales Pilot is not loaded and you're not on one of these layers,
         * redirect to the loading layer.
         */
        if (
            !this.context.loaded &&
            url.pathname !== "/authorize" &&
            url.pathname !== "/" &&
            url.pathname !== "/load" &&
            url.pathname !== "/logout"
        ) {
            return (
                <Redirect to={{ pathname: "/load", state: { from: url.pathname } }} />
            );
        }

        if (this.state.mounted === true) {
            const themes = {
                "light": {
                    palette: {
                        background: {
                            default: MUIGreyColor[50],

                        },
                        scrollBar: {
                            primary: MUIGreyColor[500],
                            secondary: MUIGreyColor[300]
                        }
                    }
                },
                "dark": {
                    palette: {
                        background: {
                            default: "#292929",

                        },
                        scrollBar: {
                            primary: MUIGreyColor[500],
                            secondary: MUIGreyColor[900]
                        }
                    }
                }
            };

            const theme = MUICreateTheme(
                {
                    palette: {
                        mode: this.state.theme
                    }
                },
                themes[this.state.theme]
            );

            return (
                <MUIThemeProvider theme={theme}>
                    <MUICssBaseline />
                    <MUIBox sx={{ backgroundColor: this.getBackgroundColor(), paddingTop: 2, paddingBottom: 2, paddingLeft: 1, paddingRight: 1, minHeight: '100vh' }} >
                        <MUIContainer disableGutters style={{ maxWidth: this.getMaxWidth() }}>
                            {this.renderHeader()}
                            {this.renderContent()}
                        </MUIContainer>
                    </MUIBox>
                </MUIThemeProvider>
            );
        }

        return null;
    }

    /**
     * Render the header. Defaults to null; subclass should override.
     */
    renderHeader() {
        return null;
    }

    /**
     * Render the content. Defaults to null; subclass should override.
     */
    renderContent() {
        return null;
    }

    /**
     * @returns The max width of this page. Overrideable by child layers.
     * Defaults to an absurdly large number for no max width.
     */
    getMaxWidth() {
        return 9999;
    }

    /**
     * @returns The background color of this page. Overrideable by child layers.
     * Defaults to the default background color for the current theme.
     */
    getBackgroundColor() {
        return "background.default";
    }

    /**
     * Copies a few things from localStorage into sessionStorage. Basically,
     * when you authorize, the session, organization, and userprofile are stored
     * in localStorage. This allows you to open new tabs and persist the same
     * credentials so you don't have to log in every time. However, if you log
     * in to Rehab in one tab, then UAT in another tab, it would be bad to
     * replace those credentials. The Rehab tab would break, or at worst do bad
     * things to the database. To fix that, the API always pulls the credentials
     * out of sessionStorage, so each tab behaves as it's own unique instance.
     */
    copyCredentialsToSessionStorage() {
        const url = new URL(window.location);
        if (
            (
                !Setting.get("session", true, window.sessionStorage) ||
                !Setting.get("organization", true, window.sessionStorage) ||
                !Setting.get("userprofile", true, window.sessionStorage)
            ) &&
            (
                Setting.get("session", true, window.localStorage) &&
                Setting.get("organization", true, window.localStorage) &&
                Setting.get("userprofile", true, window.localStorage)
            ) &&
            (
                url.pathname !== "/authorize" &&
                url.pathname !== "/"
            )
        ) {
            Setting.set("session", Setting.get("session", true, window.localStorage), window.sessionStorage);
            Setting.set("organization", Setting.get("organization", true, window.localStorage), window.sessionStorage);
            Setting.set("userprofile", Setting.get("userprofile", true, window.localStorage), window.sessionStorage);
        }
    }

    /**
     * Sets the document title and adds the company to it.
     */
    setDocumentTitle() {
        const url = new URL(window.location);
        if (
            url.pathname === "/authorize" ||
            url.pathname === "/"
        ) {
            document.title = "Sales Pilot";
        } else {
            let displayedCompany;
            switch (Setting.get("organization")) {
                case 'rehabmedical':
                    displayedCompany = 'Rehab Medical';
                    break;
                case 'corkmedical':
                    displayedCompany = 'Cork Medical';
                    break;
                case 'uat':
                    displayedCompany = 'UAT';
                    break;
                case 'localhost':
                    displayedCompany = 'UAT [localhost]';
                    break;
                default:
                    displayedCompany = Setting.get("organization");
                    break;
            }
            document.title = "Sales Pilot " + (displayedCompany ? ` • ${displayedCompany}` : "");
        }
    }
}
