import React from "react";
import MUIAvatar from "@mui/material/Avatar";
import MUIPaper from "@mui/material/Paper";
import MUITypography from "@mui/material/Typography";
import MUIRedColor from "@mui/material/colors/red";

export default class Tile extends React.Component {
    /**
     * Renders a tile with an avatar, text, and optional button.
     */
    render() {
        return (
            <>
                { this.renderLabel() }
                <MUIPaper
                    onClick={this.props.onClick}
                    sx={{
                        paddingLeft: 2,
                        paddingRight: 2,
                        height: 65,
                        cursor: this.props.onClick ? "pointer" : "default",
                        userSelect: this.props.onClick ? "none" : "auto",
                        backgroundColor: "action.hover",
                        ...(this.props.onClick && {
                            transition: "background 200ms",
                            "&:hover": {
                                backgroundColor: "action.hover", // TODO Find color
                            },
                        }),
                    }}
                >
                    <div style={ { display: "flex", alignItems: "center", height: "100%" } }>
                        { this.renderAvatar() }
                        { this.renderText() }
                        { this.renderButtons() }
                    </div>
                </MUIPaper>
                {this.renderHelperText()}
            </>
        );
    }

    /**
     * Renders optional helper text below the tile for when it's behaving like a
     * required input
     */
    renderHelperText() {
        if (this.props.helperText !== undefined) {
            return (
                <MUITypography variant="caption" color={this.props.error ? MUIRedColor[700] : "text.secondary"}>
                    { this.props.helperText }
                </MUITypography>
            );
        }
        return null;
    }

    /**
     * Renders an optional label above the tile for when it's behaving like an
     * input.
     */
    renderLabel() {
        if (this.props.label !== undefined) {
            return (
                <MUITypography variant="caption" component="p" color={this.props.error ? MUIRedColor[700] : "text.secondary"} sx={{ marginTop: '-2px' }}>
                    {this.props.required ? this.props.label + " *" : this.props.label}
                </MUITypography>
            );
        }

        return null;
    }

    /**
     * Renders a MUI Avatar with text in it.
     */
    renderAvatar() {
        return (
            <div style={{ marginRight: 14 }}>
                <MUIAvatar sx={{ bgcolor: this.props.avatarColor !== undefined ? this.props.avatarColor : this.getAvatarColor() }}>
                    {this.getAvatarContent()}
                </MUIAvatar>
            </div>
        );
    }

    /**
     * Renders up to two lines of text.
     */
    renderText() {
        const lines = [];

        if (this.getTitle() !== null) {
            lines.push((
                <MUITypography key={lines.length} variant="body2">
                    <span title={this.getTitle()} style={{ display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                        { this.getTitle() }
                    </span>
                </MUITypography>
            ));
        }

        if (this.getSubtitle() !== null) {
            lines.push((
                <MUITypography key={lines.length} variant="caption">
                    <span title={this.getSubtitle()} style={{ display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                        { this.getSubtitle() }
                    </span>
                </MUITypography>
            ));
        }

        return (
            <div style={ { overflow: "hidden", marginRight: 8, flexGrow: 1 } }>
                { lines }
            </div>
        );
    }

    /**
     * Renders an optional array of iconButtons. Adds a key prop to them
     * automatically for React.
     */
    renderButtons() {
        if (this.props.iconButtons !== undefined) {
            return (
                <>
                    {this.props.iconButtons.map((iconButton, i) => (
                        React.cloneElement(iconButton, { key: i })
                    ))}
                </>
            );
        }

        return null;
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return this.props.avatarIcon;
    }

    /**
     * @returns {string} The title of the tile. Defaults to empty; subclasses
     * should override. You can construct a generic tile yourself by specifying
     * this as a prop.
     */
    getTitle() {
        return this.props.title;
    }

    /**
     * @returns {string} The subtitle of the tile. Defaults to empty; subclasses
     * should override. You can construct a generic tile yourself by specifying
     * this as a prop.
     */
    getSubtitle() {
        return this.props.subtitle;
    }

    /**
     * @returns {string} The avatar string. Defaults to empty; subclasses should
     * override. This is the string that is provided to getAvatarContent().
     */
    getAvatarString() {
        return this.props.avatarString;
    }

    getAvatarContent() {
        if (this.props.avatarContent) {
            return this.props.avatarContent;
        }

        const avatarString = this.getAvatarString();
        if (avatarString) {
            const abbreviationParts = [];
            avatarString
                .replace(/[^A-z ]/g, '')
                .replace(/\s+/g, ' ')
                .split(' ')
                .slice(0, 2)
                .forEach((abbreviationPart) => {
                    abbreviationParts.push(abbreviationPart[0]);
                });
            return abbreviationParts.join('').toUpperCase();
        }

        return this.renderAvatarIcon();
    }

    /**
     * @returns {string} The avatar color. Defaults to empty; subclasses should
     * override.
     */
    getAvatarColor() {
        return null;
    }
}