import Layer from "../../../Layer";
import React from "react";
import PageHeader from "../../../Component/PageHeader";
import CardPatientCollectionsImport from "../../../Component/Card/Operations/PatientCollections/CardPatientCollectionsImport";
import CardPatientCollectionsOpenBalances from "../../../Component/Card/Operations/PatientCollections/CardPatientCollectionsOpenBalances";
import DialogUpdatePatientCollectionBalance from "../../../Component/Dialog/DialogUpdatePatientCollectionBalance";
import DialogSearchPatient from "../../../Component/Dialog/Search/DialogSearchPatient";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import OrderService from "../../../Seating/Security/OrderService/orderService";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class PatientCollections extends Layer {
    openBalancesColumns = [
        {
            title: "Patient",
            name: "patientName",
            type: "string",
            width: 350
        },
        {
            title: "Brightree ID",
            name: "brightreeExternalId",
            type: "brightreeExternalId",
            width: 350
        },
        {
            title: "Balance",
            name: "collectionsBalance",
            type: "currency",
            width: 180
        },
        {
            title: " ",
            name: "edit",
            type: "edit", 
            width: 80
        }
    ]

    importColumns = [
        {
            title: "Patient (Sales Pilot)",
            name: "patientName",
            type: "string",
            width: 250
        },
        {
            title: "Patient (Import File)",
            name: "importedName",
            type: "string",
            width: 250
        },
        {
            title: "Brightree ID",
            name: "brightreeExternalId",
            type: "brightreeExternalId",
            width: 150
        },
        {
            title: "Branch",
            name: "importedBranch",
            type: "string",
            width: 150
        },
        {
            title: "Balance",
            name: "collectionsBalance",
            type: "currency",
            width: 100
        },
        {
            title: " ",
            name: "edit",
            type: "edit",
            width: 80
        }
    ]

    constructor(props) {
        super(props);

        this.state.patientListDialogOpen = false;
        this.state.updatePatientCollectionBalanceDialogOpen = false;
        this.state.confirmDialogOpen = false;
    }

    /**
     * Set the data in the state.
     */
    afterComponentDidMount() {
        this.setColumnWidths();
        this.getPatientsInCollections({ hasCollectionsBalance: true, recordReturnLimit: 100000000 });
    }

    /**
     * Sets the column widths after component has mounted.
     */
    setColumnWidths() {
        let openBalancesDataGridDefaultColumnWidths = [];
        let importDataGridDefaultColumnWidths = [];

        this.openBalancesColumns.forEach((openBalanceColumn) => {
            openBalancesDataGridDefaultColumnWidths.push({
                columnName: openBalanceColumn.name,
                width: openBalanceColumn.width
            })
        })

        this.importColumns.forEach((importColumn) => {
            importDataGridDefaultColumnWidths.push({
                columnName: importColumn.name,
                width: importColumn.width
            })
        })

        this.setState({
            openBalancesDataGridDefaultColumnWidths,
            importDataGridDefaultColumnWidths 
        })
    }


    /**
     * Helper function to get patients by the given parameters.
     */
    async getPatients(params) {
        try {
            const patients = await OrderService.getPatientsByInformation(
                params.firstname,
                params.lastname,
                params.birthDate,
                params.phone,
                params.brightreeExternalId,
                params.brigthtreeExternalKey,
                params.hasCollectionsBalance,
                params.recordReturnLimit
            );
            return patients;
        } catch (err) {
            NotistackEnqueueSnackbar("Error retrieving patients", { variant: "error" });
            throw err;  
        }
    }

    /**
     * Initial API call for getting all patients with a collections balance > 0
     * and secondarily used for all file upload api calls for sending in account id to match brightree id
     */
    async getPatientsInCollections(params = {}) {
        this.setState({ loadedAt: null })

        try {
            const rows = await this.getPatients(params);

            const updatedRows = rows.map(patient => {
                const firstName = patient.firstName ? patient.firstName.trim() : "";
                const lastName = patient.lastName ? patient.lastName.trim() : "";
                const patientName = firstName || lastName ? `${firstName} ${lastName}` : "";  
        
                return {
                    ...patient,
                    patientName,
                    collectionsBalance: patient.collectionsBalance,
                };
            });

            const sortedUpdatedRows = updatedRows.sort((a, b) => {
                if (!a.patientName && b.patientName) return 1; 
                if (a.patientName && !b.patientName) return -1;  
                return a.patientName.localeCompare(b.patientName);
            });
    
            this.setState({
                rows: sortedUpdatedRows || [],
                loadedAt: new Date(),
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Updates the patient data using the OrderService API
     * @param {Object} patient - The patient object with updated data
     * @returns {Promise} - Resolves to the updated patient or rejects with an error
     */
    async updatePatient(patient) {
        try {
            await OrderService.updatePatient(patient);
            return { success: true };   
        } catch (err) {
            console.error('Error updating patient:', err);
            return { success: false, error: err };  
        }
    };

    /**
     * Search for patients based on parameters.
     */
    async searchPatient(params) {
        this.setState({isLoadingSearchedPatients: true})
        try {
            const rows = await this.getPatients({
                firstname: params.firstname,
                lastname: params.lastname
            });
    
            this.setState({
                searchPatientRows: rows || [],
                isLoadingSearchedPatients: false,
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Render the content.
     */
    renderContent() {
        const onUpload = (file) => {
            this.setState({ isUploading: true })

            const reader = new FileReader();
            reader.onload = (e) => {
                this.parseCsvString(e.target.result)
            };

            reader.readAsText(file);
        };

        const handleCancelImport = () => {
            this.setState({ fileImported: false, importedRows: [] })
        }

        const handleImport = async () => {
            let allSuccess = true;

            const results = await Promise.all(this.state.importedRows.map(patient => this.updatePatient(patient)));

            allSuccess = results.every(result => result.success);

            if (allSuccess) {
                NotistackEnqueueSnackbar("All patients updated successfully", { variant: "success" });
            } else {
                NotistackEnqueueSnackbar("Error updating one or more patients", { variant: "error" });
            }

            this.getPatientsInCollections({ hasCollectionsBalance: true, recordReturnLimit: 100000000 });
            this.setState({ fileImported: false, importedRows: [], confirmDialogOpen: false });
        }


        const handleOnEditRowClick = (row, importedRow) => {
            if (importedRow) {
                const nameParts = row.importedName.split(' '); // Split the import name by spaces
        
                let firstName = '';
                let lastName = '';
            
                if (nameParts.length > 1) {
                    firstName = nameParts[0]; 
                    lastName = nameParts.slice(1).join(' '); 
                } else if (nameParts.length === 1) {
                    firstName = nameParts[0]; 
                }
    
                const searchParams = {};
                if (firstName) searchParams.firstname = firstName;
                if (lastName) searchParams.lastname = lastName;
    
                this.setState({
                    selectedUnmappedPatientRow: row,
                    patientListDialogOpen: true,
                    searchParams: searchParams,
                })
     
                this.searchPatient(searchParams);
            } else {
                this.setState({
                    patient: row,
                    updatePatientCollectionBalanceDialogOpen: true,
                })
            }
        }

        const handleOnSelectPatient = (row) => {
            const updatedUnmappedPatientsArray = this.state.patientsNotMapped.filter(patient => patient !== this.state.selectedUnmappedPatientRow.brightreeExternalId);

            let patientRecordToUpdate = this.state.importedRows.find((importedRow) => importedRow.brightreeExternalId === this.state.selectedUnmappedPatientRow.brightreeExternalId);

            const brightreeExternalId = row.brightreeExternalId;
            const collectionsBalance = patientRecordToUpdate.collectionsBalance;

            if (isNaN(brightreeExternalId)) {
                console.error("brightreeExternalId must be a valid number.");
                return;
            }

            if (patientRecordToUpdate) {
                const patientName = `${row.firstName || ''} ${row.lastName || ''}`.trim();
                patientRecordToUpdate = {
                    ...row,
                    brightreeExternalId,
                    collectionsBalance,
                    importedBranch: patientRecordToUpdate.importedBranch,
                    importedName: row.importedName || patientRecordToUpdate.importedName,
                    patientName,
                };
            } else {
                console.warn("Row to update not found in imported data.");
                return;
            }
 
            this.setState({
                patientsNotMapped: updatedUnmappedPatientsArray,
                importedRows: this.state.importedRows.map((importedRow) =>
                    importedRow.brightreeExternalId === this.state.selectedUnmappedPatientRow.brightreeExternalId
                        ? patientRecordToUpdate
                        : importedRow
                ),
                selectedUnmappedPatientRow: null,
                patientListDialogOpen: false,
            });
        }

        const handleChange = (patient) => {
            this.setState({
                patient: {
                    ...this.state.patient,
                    ...patient
                },
            });
        };

        const handleUpdatePatientCollectionBalance = () => {
            this.updatePatient(this.state.patient)
                .then((result) => {
                    if (result.success) {
                        NotistackEnqueueSnackbar("Collection balance updated successfully", { variant: "success" });
                    } else {
                        NotistackEnqueueSnackbar("Error updating collection balance", { variant: "error" });
                    }
                })
                .catch((error) => {
                    console.error("Error during update:", error);
                    NotistackEnqueueSnackbar("Error updating collection balance");
                })
                .finally(() => {
                    this.getPatientsInCollections({ hasCollectionsBalance: true, recordReturnLimit: 100000000 });
                    this.setState({ updatePatientCollectionBalanceDialogOpen: false });
                });
        }
 
        return (
            <>
                <CardPatientCollectionsImport
                    onUpload={onUpload}
                    onCancel={handleCancelImport}
                    onImportButtonClick={() => this.setState({confirmDialogOpen: true})}
                    rows={this.state.importedRows}
                    columns={this.importColumns}
                    isUploading={this.state.isUploading}
                    fileImported={this.state.fileImported}
                    unmappedRows={this.state.patientsNotMapped}
                    onClick={(row) => handleOnEditRowClick(row, true)}
                    defaultColumnWidths={this.state.importDataGridDefaultColumnWidths}
                />
                <CardPatientCollectionsOpenBalances
                    columns={this.openBalancesColumns}
                    rows={this.state.rows}
                    loadedAt={this.state.loadedAt}
                    defaultColumnWidths={this.state.openBalancesDataGridDefaultColumnWidths}
                    onClick={(row => handleOnEditRowClick(row))}
                />
                <DialogSearchPatient
                    open={this.state.patientListDialogOpen}
                    onClose={() => this.setState({ patientListDialogOpen: false, searchParams: {}, searchPatientRows: []})}
                    onSearch={(params) => this.searchPatient(params)}
                    rows={this.state.searchPatientRows}
                    onSelect={(row) => handleOnSelectPatient(row)}
                    isLoading={this.state.isLoadingSearchedPatients}
                    searchParams={this.state.searchParams}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={handleImport}
                    header="Confirm Import"
                    text={`Are you sure you want to update the collections balance on these patients? Changes cannot be undone.`}
                />
                <DialogUpdatePatientCollectionBalance
                    patient={this.state.patient}
                    open={this.state.updatePatientCollectionBalanceDialogOpen}
                    onChange={handleChange.bind(this)}
                    onClose={() => this.setState({updatePatientCollectionBalanceDialogOpen: false})}
                    onConfirm={handleUpdatePatientCollectionBalance}
                />
            </>
        );
    }

    /**
    * Parse a CSV string (handles commas, quotes, and newlines)
    */
    parseCsvString(csvString) {
        const pattern = new RegExp(("(\\,|\\r?\\n|\\r|^)(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|([^\\,\\r\\n]*))"), "gi");
        let matches = null;
        let data = [[]];   
        while (matches = pattern.exec(csvString)) {
            if (matches[1].length && matches[1] !== ",") {
                data.push([]);
            }
            data[data.length - 1].push(matches[2] ? matches[2].replace(new RegExp("\"\"", "g"), "\"") : matches[3]);
        }

        this.parseCsvData(data);
    }

    /**
     * Parse the CSV data (string) to extract Acct #
     */
    parseCsvData(rows) {
        const header = rows[0];   

        const accountIndex = header.indexOf('Acct #');
        const currentBalanceIndex = header.indexOf('Current Balance');
        const nameIndex = header.indexOf('Name');
        const branchIndex = header.indexOf('Branch');

        if (accountIndex === -1 || currentBalanceIndex === -1 || nameIndex === -1 || branchIndex === -1) {
            console.error('Required columns not found.');
            NotistackEnqueueSnackbar("File could not be uploaded", { variant: "error" });
            this.setState({isUploading: false, fileImported: false})
            return;
        }

        const accountBalanceAndBranchInfo = rows.slice(1).map(row => {
            const brightreeExternalId = row[accountIndex].trim();
            const collectionsBalance =  parseFloat(row[currentBalanceIndex]) || 0;
            const importedName = row[nameIndex] ? row[nameIndex].trim() : '';
            const importedBranch = row[branchIndex];

            // Modify the Name format (Lastname, Firstname to Firstname Lastname)
            let formattedName = importedName;
            if (importedName.includes(',')) {
                const nameParts = importedName.split(',');
                if (nameParts.length === 2) {
                    formattedName = `${nameParts[1].trim()} ${nameParts[0].trim()}`;
                }
            }

            return {
                brightreeExternalId,
                importedName: formattedName, // Use the modified name for frontend display
                importedBranch,
                collectionsBalance,
            };
        }).filter(item => item.brightreeExternalId);

        this.updateImportedPatientCollectionsBalanceByBrightreeId(accountBalanceAndBranchInfo);
    }

    /**
     * Update the collections balance for the patients
     * @param {Array} accountBalanceAndBranchInfo - Array of account numbers and balances from the CSV
     */
    async updateImportedPatientCollectionsBalanceByBrightreeId(accountBalanceAndBranchInfo) {
        try {
            const patients = await this.getPatients({
                brightreeExternalId: accountBalanceAndBranchInfo.map(item => item.brightreeExternalId),
                recordReturnLimit: 100000000,
            });

            let mappedPatients = [];
            let unmappedPatients = [];

            accountBalanceAndBranchInfo.forEach((balanceData) => {
                const patient = patients.find((p) => p.brightreeExternalId.toString() === balanceData.brightreeExternalId);
                
                if (patient) {
                    const collectionsBalance = balanceData.collectionsBalance;
                    if (!isNaN(collectionsBalance)) {
                        patient.collectionsBalance = collectionsBalance;
                    } else {
                        console.warn(`Invalid collectionsBalance for Account # ${balanceData.brightreeExternalId}: ${balanceData.collectionsBalance}`);
                    }

                    patient.patientName = `${patient.firstName || ''} ${patient.lastName || ''}`.trim();
                    patient.importedBranch = balanceData.importedBranch;
                    mappedPatients.push(patient);

                } else {
                    unmappedPatients.push(balanceData.brightreeExternalId);
                    console.warn(`Patient with Account # ${balanceData.brightreeExternalId} not found.`);
                }
            });

            // Combine mapped and unmapped patients
            const combinedPatients = [
                ...unmappedPatients.map(brightreeExternalId => {
                    const balanceData = accountBalanceAndBranchInfo.find(data => data.brightreeExternalId === brightreeExternalId);
                    return {
                        brightreeExternalId,
                        importedName: balanceData.importedName,
                        importedBranch: balanceData.importedBranch,
                        collectionsBalance: balanceData.collectionsBalance,
                        out_of_bounds: 'Yes',
                    };
                }),
                ...mappedPatients.map(patient => ({
                    ...patient,
                    importedBranch: patient.importedBranch,
                    importedName: accountBalanceAndBranchInfo.find(data => data.brightreeExternalId === patient.brightreeExternalId.toString()).importedName,
                }))
            ];

            // Sort the patients so unmapped ones appear first
            const sortedPatients = combinedPatients.sort((a, b) => {
                if (a.out_of_bounds && !b.out_of_bounds) return -1;
                if (!a.out_of_bounds && b.out_of_bounds) return 1;
                return 0;
            });

            this.setState({
                importedRows: sortedPatients || [],
                patientsNotMapped: unmappedPatients,
                isUploading: false,
                fileImported: true,
            });
        } catch (err) {
            NotistackEnqueueSnackbar("Error retrieving patients", { variant: "error" });
            this.setState({
                isUploading: false,
                fileImported: false,
            })
        }
    }


    renderHeader() {
        return (
            <PageHeader title="Patient Collections" />
        );
    }

    getMaxWidth() {
        return 1000;
    }
}
