import Admin from "../Admin";
import React from "react";
import CardAdminContactTypeGeneral from "../../../Component/Card/Admin/ContactType/CardAdminContactTypeGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';

import Validator from "../../../Utility/Validator";

import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import ContactType from "../../../Utility/Crud/ContactType";
import ContactTypesService from "../../../Seating/Settings/contactTypesService";

export default class AdminContactTypeCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.contactType = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the contactType in the state.
     */
    afterComponentDidMount() {
        const contactTypeId = this.props.match.params.id ? +this.props.match.params.id : null;
        
        if (contactTypeId !== null) {
            const contactType = this.context.contactTypesIndexed[contactTypeId];
            this.setState({
                contactType: contactType,
            });
        }
    }

    /**
     * Creates the contact type with the current properties in state.contactType.
     */
    async createContactType() {
        this.setState({
            isProcessing: true,
        });

        return ContactTypesService.createContactType(this.state.contactType)
            .then(contactType => {
                this.context.updateIndexed("contactTypesIndexed", contactType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Contact Type created successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Contact Type.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the contact type with the current properties in state.contactType.
     */
    async updateContactType() {
        this.setState({
            isProcessing: true,
        });

        return ContactTypesService.updateContactType(this.state.contactType)
            .then(contactType => {
                this.context.updateIndexed("contactTypesIndexed", contactType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Contact Type updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Contact Type.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }


    async deleteContactType() {
        this.setState({
            isProcessing: true,
        });

        return ContactTypesService.deleteContact(this.state.contactType.id)
            .then(() => {
                this.context.deleteIndexed("contactTypesIndexed", this.state.contactType.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Contact Type deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Contact Type.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (contactType) => {
            this.setState({
                contactType: {
                    ...this.state.contactType,
                    ...contactType,
                },
            });
        };
        
        return (
            <>
                <CardAdminContactTypeGeneral
                    contactType={this.state.contactType}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteContactType()}
                    text="Are you sure you want to delete this contact type? This cannot be undone."
                    header="Delete Contact Type"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Render the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Contact Type';
            subtitle = null;
        } else {
            title = 'Edit Contact Type';
            subtitle = (this.state.contactType && (ContactType.getDisplayName(this.state.contactType) + " • " + this.state.contactType.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.contactType,
                {
                    name: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateContactType();
                } else {
                    this.createContactType();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
