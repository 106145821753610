import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Card from "../../Card";
import MUIGrid from "@mui/material/Grid";
import MUITypography from "@mui/material/Typography";
import MUIChip from "@mui/material/Chip";
import MUICheckCircleIcon from '@mui/icons-material/CheckCircle';
import MUICancelIcon from '@mui/icons-material/Cancel';
import MUIHelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Location from "../../../Utility/Crud/Location";
import Term from "../../../Utility/Crud/Term";

export default class CardApprovalSummaryApproval extends Card {
    static contextTypes = {
        locationsIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
    };
    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <>
                <MUIGrid container rowSpacing={1} spacing={{ xs: 2, sm: 2, md: 4 }} sx={{ mb: 4 }}>
                    <MUIGrid item xs="auto">
                        <MUITypography variant="subtitle2">Approved On</MUITypography>
                        <MUITypography variant="body2" sx={this.props.order.approvalDate ? {} : { color: "text.disabled" }}>{this.props.order.approvalDate ? moment(this.props.order.approvalDate).format("M/D/YYYY") : "None"}</MUITypography>
                    </MUIGrid>
                    <MUIGrid item xs="auto">
                        <MUITypography variant="subtitle2">Billing Location</MUITypography>
                        <MUITypography variant="body2" sx={this.props.order.billingLocationId ? {} : { color: "text.disabled" }}>
                            {this.props.order.billingLocationId && this.context.locationsIndexed[this.props.order.billingLocationId]
                                ? Location.getDisplayName(this.context.locationsIndexed[this.props.order.billingLocationId])
                                : "None"}
                        </MUITypography>
                    </MUIGrid>
                    <MUIGrid item xs="auto">
                        <MUITypography variant="subtitle2">Auth #</MUITypography>
                        <MUITypography variant="body2" sx={this.props.approval?.authNumber ? {} : { color: "text.disabled" }}>{this.props.approval?.authNumber || "None"}</MUITypography>
                    </MUIGrid>
                    <MUIGrid item xs="auto">
                        <MUITypography variant="subtitle2">Place of Service</MUITypography>
                        <MUITypography variant="body2" sx={this.props.approval?.placeOfServiceTermId ? {} : { color: "text.disabled" }}>{this.props.approval && this.props.approval.placeOfServiceTermId ? Term.getDisplayName(this.context.termsIndexed[this.props.approval.placeOfServiceTermId]) : "None"}</MUITypography>
                    </MUIGrid>
                    <MUIGrid item xs="auto">
                        <MUITypography variant="subtitle2">Commission/Tier</MUITypography>
                        <MUITypography variant="body2" sx={this.props.approval?.commissionTier ? {} : { color: "text.disabled" }}>{this.props.approval?.commissionTier || "None"}</MUITypography>
                    </MUIGrid>
                </MUIGrid>

                <MUIGrid container rowSpacing={1} spacing={{ xs: 2 }} >
                    <MUIGrid item xs="auto">
                        <MUIChip
                            label={"Base/Cushion Upgrade"}
                            color={this.props.approval && this.props.approval.unapprovedUpgrade ? "success" : undefined}
                            icon={this.props.approval && this.props.approval.unapprovedUpgrade === true ? (<MUICheckCircleIcon />) :
                                this.props.approval && this.props.approval.unapprovedUpgrade === false ? (<MUICancelIcon />) : (<MUIHelpOutlineIcon />)}
                            size="small" />
                    </MUIGrid>
                    <MUIGrid item xs="auto">
                        <MUIChip
                            label={"Active Rental Same/Sim"}
                            color={this.props.approval && this.props.approval.activeRentalSameOrSimilar ? "success" : undefined}
                            icon={this.props.approval && this.props.approval.activeRentalSameOrSimilar === true ? (<MUICheckCircleIcon />) :
                                this.props.approval && this.props.approval.activeRentalSameOrSimilar === false ? (<MUICancelIcon />) : (<MUIHelpOutlineIcon />)}
                            size="small" />
                    </MUIGrid>
                    <MUIGrid item xs="auto">
                        <MUIChip
                            label={"Financial Hardship"}
                            color={this.props.approval && this.props.approval.financialHardship ? "success" : undefined}
                            icon={this.props.approval && this.props.approval.financialHardship === true ? (<MUICheckCircleIcon />) :
                                this.props.approval && this.props.approval.financialHardship === false ? (<MUICancelIcon />) : (<MUIHelpOutlineIcon />)}
                            size="small" />
                    </MUIGrid>
                    {this.props.approval ? (
                        <MUIGrid item xs="auto">
                            <MUIChip label={this.props.approval.rental === true ? "Rental" : this.props.approval.rental === false ? "Purchase" : "Rental/Purchase"}
                                color={this.props.approval.rental === true || this.props.approval.rental === false ? "primary" : undefined}
                                size="small"
                                icon={this.props.approval.rental === true || this.props.approval.rental === false ? null : <MUIHelpOutlineIcon />}
                            />
                        </MUIGrid>
                    ) : null}
                </MUIGrid>
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Approval";
    }

    /**
     * @returns {string} The minHeight of the card.
     */
    getMinHeight() {
        return "100%";
    }
}