import React from "react";
import PropTypes from "prop-types";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUIGrid from "@mui/material/Grid";
import Card from "../../../Card";
import TileUser from "../../../Tile/TileUser";
import DialogSearchUser from "../../../Dialog/Search/DialogSearchUser";
import DialogSearchAccount from "../../../Dialog/Search/DialogSearchAccount";
import AccountService from "../../../../Seating/Security/AccountService/accountService";
import TileAccount from "../../../Tile/TileAccount";
// import DialogSearchAccount from "../../../Dialog/Search/DialogSearchAccount";

export default class CardChartPatientSalesInfo extends Card {
    constructor(props) {
        super(props);

        this.state.accountDialogOpen = false;
        this.state.salesRepDialogOpen = false;
        this.state.accounts = null;
    }

    static contextTypes = {
        usersIndexed: PropTypes.object,
    };
    /**
       * Renders the content of the card.
       */
    renderContent() {
        let patient = this.props.patient;
        let account = null;

        if (patient && patient.account && patient.account !== null ) {
            account = patient.account;
        }

        // Sales Rep
        const handleEditSalesRep = () => {
            this.setState({ salesRepDialogOpen: true });
        };
        const handleClearSalesRep = () => {
            this.setState({salesRep: null});
            this.props.onChange({account: null, accountId: null});
        };
        const salesRepIconButtons = [
            (<MUIIconButton onClick={handleEditSalesRep}><MUIEditIcon /></MUIIconButton>)
        ];

        if (this.state.salesRep) {
            salesRepIconButtons.push(
                (<MUIIconButton onClick={handleClearSalesRep}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Account
        const handleEditAccount = () => {
            this.setState({ accountDialogOpen: true });
        };
        const handleClearAccount = () => {
            this.props.onChange({ account: null, accountId: null });
        };
        const accountIconButtons = [
            (<MUIIconButton onClick={handleEditAccount} disabled={!this.state.salesRep}><MUIEditIcon /></MUIIconButton>)
        ];

        if (account) {
            accountIconButtons.push(
                (<MUIIconButton onClick={handleClearAccount}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Render
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileUser
                            user={this.state.salesRep}
                            iconButtons={salesRepIconButtons}
                            label="Sales Rep"
                        />
                    </MUIGrid>

                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileAccount
                            account={account}
                            iconButtons={accountIconButtons}
                            label="Account"
                            required={true}
                            error={this.props.validationIssues.account ? true : false}
                            helperText={this.props.validationIssues.account && this.state.salesRep ? this.props.validationIssues.account : !this.state.salesRep ? "You must select a Sales Rep" : ""}
                        />
                    </MUIGrid>
                </MUIGrid>

                <DialogSearchUser
                    open={this.state.salesRepDialogOpen}
                    onClose={() => this.setState({ salesRepDialogOpen: false })}
                    onSelect={(user) => {
                        this.setState({ salesRep: user, accounts: null });
                        this.props.onChange({ account: null, accountId: null });
                        AccountService.getAccountsByUser(user.id)
                            .then((res) => this.setState({ accounts: res }))
                            .catch(console.error);
                    }}
                />

                <DialogSearchAccount
                    data={this.state.accounts ? this.state.accounts : []}
                    open={this.state.accountDialogOpen}
                    onClose={() => this.setState({ accountDialogOpen: false })}
                    onSelect={(account) => {
                        this.props.onChange({ account: account, accountId: account.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Sales";
    }
}