import React from "react";
import DialogSearch from "../DialogSearch";
import MUIDialogActions from "@mui/material/DialogActions";
import MUIButton from "@mui/material/Button";
import TileUser from "../../Tile/TileUser";
import PropTypes from "prop-types";
import User from "../../../Utility/Crud/User";

export default class DialogSearchUser extends DialogSearch {

    static contextTypes = {
        usersIndexed: PropTypes.object,
    };

    /**
     * Searches for users by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered users.
     */
    getSearchResults(query) {
        return User.search(this.state.data, query, true).filter((user) => {
            return user.active === true;
        });
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.usersIndexed);
        }
    }

    /**
     * @param {object} user The user object.
     * @returns A TileUser component for this user.
     */
    renderTile(user) {
        return (
            <TileUser
                key={user.id}
                user={user}
            />
        );
    }
}
