import Crud from "../Crud";

/**
 * Static helper functions related to Order Status Reasons.
 */
export default class OrderStatusReason extends Crud {

    /**
     * @param {object} orderStatusReason The orderStatusReason object.
     * @returns The display name of the orderStatusReason.
     */
    static getDisplayName(orderStatusReason) {
        if (!orderStatusReason) {
            return null;
        }

        return orderStatusReason.description;
    }

    /**
     * Determines if an orderStatusReason matches a search query.
     *
     * @param {object} orderStatusReason The orderStatusReason to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the orderStatusReason matches the query.
     */
    static match(orderStatusReason, query) {
        return (
            (orderStatusReason.description.toLowerCase().includes(query)) ||
            orderStatusReason.id === +query
        );
    }

    /**
     * @param {array} orderStatusReasons The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(orderStatusReasons) {
        return orderStatusReasons.sort((a, b) => {
            if (a.orderStatus !== b.orderStatus) {
                return a.orderStatus - b.orderStatus;
            } else {
                return a.description.localeCompare(b.description);
            }
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        throw new Error("Use orderStatus.colorId");
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Topic";
    }
}