import React from "react";
import Card from "../../../Card";
import Upload from "../../../Upload";
import EmptyState from "../../../EmptyState";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class CardAdminBaseUnitImage extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        if (!this.state.imageSrc) {
            return (
                <EmptyState
                    line1="No Image"
                    line2="Select an image to represent this base unit"
                />
            );
        }

        return (
            <div style={{ textAlign: "center" }}>
                <img style={{ maxWidth: "100%" }} src={this.state.imageSrc} alt="" />
            </div>
        );
    }

    /**
     * Renders choose file button
     */
    getActions() {
        const handleChange = (file) => {
            if (file && file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({
                        imageSrc: reader.result
                    });
                    this.props.onChange({ image: file });
                };
                reader.readAsDataURL(file);
            } else {
                NotistackEnqueueSnackbar('Not a valid image.', { variant: "error" });
                this.setState({
                    imageSrc: null
                });
                this.props.onChange({ image: null });
            }
        };

        const buttonItems = [(<Upload
            accept="image/*"
            label="Choose Image"
            onChange={handleChange}
        />)];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Image";
    }
}