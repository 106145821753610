import React from "react";
import Card from "../../Card";
import Department from "../../../Utility/Crud/Department";
import Term from "../../../Utility/Crud/Term";
import Queue from "../../../Utility/Crud/Queue";
import User from "../../../Utility/Crud/User";
import MUITypography from "@mui/material/Typography";
import MUISkeleton from '@mui/material/Skeleton';
import MUITooltip from "@mui/material/Tooltip";
import * as MUIColors from "@mui/material/colors";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import MUIBox from "@mui/material/Box";
import Setting from "../../../Utility/Setting";
import Color from "../../../Utility/Crud/Color";
import { SvgIcon as MUISvgIcon } from '@mui/material';
import MUILabelIcon from '@mui/icons-material/Label';
import TeamQueue from "../../../Utility/Crud/TeamQueue";
import TeamUser from "../../../Utility/Crud/TeamUser";
export default withRouter(class CardQueueDashboard extends Card {
    static contextTypes = {
        teamQueuesIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        teamUsersIndexed: PropTypes.object,
        currentUser: PropTypes.object,
        colorsIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
    };

    /**
     * Render the card header. Defaults to empty; subclasses should override.
     */
    renderHeader() {
        const baseColor = this.context.colorsIndexed[this.props.department.colorId];
        const baseMUIColor = MUIColors[baseColor.hue];
        const color1 = baseMUIColor[500];
        const color2 = baseMUIColor[300];
        const contrastingTextColor = Color.getForegroundColor(this.context.colorsIndexed[this.props.department.colorId]);

        let background = `linear-gradient(45deg, ${color1}, ${color2})`;
        const chance = 1 / 20000; // One in every 20,000...
        if (Math.random() < chance) {
            background = "linear-gradient(45deg,rgba(255, 0, 0, 1) 0%,rgba(255, 154, 0, 1) 10%,rgba(208, 222, 33, 1) 20%,rgba(79, 220, 74, 1) 30%,rgba(63, 218, 218, 1) 40%,rgba(47, 201, 226, 1) 50%,rgba(28, 127, 238, 1) 60%,rgba(95, 21, 242, 1) 70%,rgba(186, 12, 248, 1) 80%,rgba(251, 7, 217, 1) 90%,rgba(255, 0, 0, 1) 100%)";
        }

        return (
            <MUIBox
                sx={{ paddingTop: 1, paddingBottom: 1, marginBottom: 1, background: background }}
            >
                <table style={{ width: "100%", color: contrastingTextColor }} >
                    <tbody>
                        <tr>
                            <td valign="top" style={{ paddingLeft: 18 }}>
                                <MUITypography variant="body1">
                                    {Department.getDisplayName(this.props.department)}
                                </MUITypography>
                                <MUITypography variant="caption">
                                    {Term.getDisplayName(this.props.queueTypeTerm)}
                                </MUITypography>
                            </td>
                            <td valign="top" style={{ width: 70, textAlign: "right" }}><MUITypography variant="body1">Count</MUITypography></td>
                            <td valign="top" style={{ width: 70, textAlign: "right" }}><MUITypography variant="body1">Points</MUITypography></td>
                            <td valign="top" style={{ width: 70, textAlign: "right", paddingRight: 18 }}><MUITypography variant="body1">OOB</MUITypography></td>
                        </tr>
                    </tbody>
                </table>
            </MUIBox>
        );
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const CrownIcon = (props) => (
            <MUISvgIcon {...props}>
                <path d="M5 18L3 5L8.5 10L12 4L15.5 10L21 5L19 18H5M19 19C19 19.6 18.6 20 18 20H6C5.4 20 5 19.6 5 19V18H19V19Z" />
            </MUISvgIcon>
        );

        const baseColor = this.context.colorsIndexed[this.props.department.colorId];
        const baseMUIColor = MUIColors[baseColor.hue];
        const hoverColor = Color.read(
            this.context.colorsIndexed,
            [
                {
                    key: "hue",
                    value: baseColor.hue
                },
                {
                    key: "shade",
                    value: Setting.get("ui.theme") === "dark" ? "700" : "50"
                }
            ]
        )[0];
        const OOBColor = Color.read(
            this.context.colorsIndexed,
            [
                {
                    key: "hue",
                    value: baseColor.hue
                },
                {
                    key: "shade",
                    value: Setting.get("ui.theme") === "dark" ? "100" : "700"
                }
            ]
        )[0];
        const hoverForegroundColor = Color.getForegroundColor(hoverColor);

        let size = 0;
        return (
            <table style={{ width: "100%" }}>
                <tbody>

                    <tr>
                        <td style={{ paddingLeft: 4, width: 14 }}></td>
                        <td></td>
                        <td style={{ width: 70, textAlign: "right" }}></td>
                        <td style={{ width: 70, textAlign: "right" }}></td>
                        <td style={{ width: 70, textAlign: "right", paddingRight: 18 }}></td>
                    </tr>

                    {this.props.queues.map((queue) => {
                        size++;

                        const outOfBoundsString = Queue.getOutOfBoundsString(queue);

                        let countsElements;
                        if (this.props.queueCounts && this.props.queueCounts[queue.id]) {
                            countsElements = (
                                <>
                                    <td style={{ width: 70, textAlign: "right" }}>{this.props.queueCounts[queue.id].count.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                                    <td style={{ width: 70, textAlign: "right" }}>{this.props.queueCounts[queue.id].points.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                                    <MUITooltip title={outOfBoundsString ? outOfBoundsString.charAt(0).toUpperCase() + outOfBoundsString.slice(1) : "No OOB Rule"} placement="right">
                                        <td
                                            onClick={(e) => {
                                                if (outOfBoundsString !== null) {
                                                    e.stopPropagation();
                                                    this.props.history.push({
                                                        pathname: `/queue/${queue.id}`,
                                                        state: {
                                                            viewOutOfBoundsOnly: true,
                                                        }
                                                    });
                                                }
                                            }}
                                            style={this.props.queueCounts[queue.id].outOfBounds > 0 ? { fontWeight: "bold", color: OOBColor.hexCode, textAlign: "right", paddingRight: 18 } : { width: 70, textAlign: "right", paddingRight: 18 }}
                                        >
                                            {outOfBoundsString ? this.props.queueCounts[queue.id].outOfBounds.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : "-"}
                                        </td>
                                    </MUITooltip>
                                </>
                            );
                        } else {
                            countsElements = (
                                <td colSpan="3" style={{ textAlign: "right", paddingRight: 18 }}>
                                    <MUISkeleton variant="text" />
                                </td>
                            );
                        }

                        let icon;
                        if (this.isManagerOfQueue(queue) === true) {
                            icon = (
                                <MUITooltip title="Manager" placement="right">
                                    <td style={{ paddingLeft: 4, width: 14 }}>
                                        <CrownIcon style={{ fontSize: 14, color: MUIColors.yellow[700] }} />
                                    </td>
                                </MUITooltip>
                            );
                        } else if (this.isMemberOfQueue(queue) === true) {
                            icon = (
                                <MUITooltip title="Member" placement="right">
                                    <td style={{ paddingLeft: 4, width: 14 }}>
                                        <MUILabelIcon style={{ fontSize: 14, color: baseMUIColor[500] }} />
                                    </td>
                                </MUITooltip>
                            );
                        } else {
                            icon = (
                                <td style={{ paddingLeft: 4, width: 14 }}></td>
                            );
                        }

                        return (
                            <tr
                                key={queue.id}
                                style={{ cursor: "pointer" }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = hoverColor.hexCode;
                                    e.currentTarget.style.color = hoverForegroundColor;
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = "";
                                    e.currentTarget.style.color = "";
                                }}
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: `/queue/${queue.id}`,
                                        state: {
                                            viewOutOfBoundsOnly: false,
                                        }
                                    });
                                }}
                            >
                                {icon}
                                    <td>
                                        <MUITooltip title={<div style={{ whiteSpace: 'pre-line' }}>{this.getQueueTooltip(queue)}</div>} placement="bottom">
                                            <MUITypography variant="subtitle2">
                                                {Queue.getDisplayName(queue)}
                                            </MUITypography>
                                        </MUITooltip>
                                    </td>
                                {countsElements}
                            </tr>
                        );
                    })}
                    {[...Array(this.props.size - size)].map((_, index) => (
                        <tr key={index}>
                            <td colSpan="5">&nbsp;</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    /**
     * Generate a tooltip for the queue.
     *
     * @param {object} queue
     */
    getQueueTooltip(queue) {
        const tooltipParts = [];
        tooltipParts.push(Queue.getDisplayName(queue));

        if (queue.description) {
            tooltipParts.push(queue.description);
        }

        tooltipParts.push("");

        const teamQueues = TeamQueue.read(
            this.context.teamQueuesIndexed,
            [
                {
                    key: "queueId",
                    value: queue.id
                }
            ]
        );

        const teamIds = teamQueues.map(teamQueue => teamQueue.teamId);

        teamIds.map(teamId => {
            const team = this.context.teamsIndexed[teamId];
            const teamUsers = TeamUser.read(
                this.context.teamUsersIndexed,
                [
                    {
                        key: "teamId",
                        value: team.id
                    }
                ]
            );

            const manager = this.context.usersIndexed[team.managerId];

            const managerName = manager ? User.getDisplayName(manager) : "No Manager";

            const multipleOrSingleMember = teamUsers.length === 1 ? "Member" : "Members";

            tooltipParts.push(`${managerName} • ${team.name} • ${teamUsers.length} ${multipleOrSingleMember}`);
        })

        return tooltipParts.join("\n");
    }

    /**
     * @returns {number} The padding of the card. Override if necessary;
     * defaults to 18. Most common situation is to override to 0 for a
     * "full-bleed" look.
     */
    getPadding() {
        return 0;
    }

    /**
     * @returns {number} These only exist on the dashboard which already adds
     * the necessary padding in the grid.
     */
    getMarginBottom() {
        return 0;
    }

    /**
     * @param {object} queue The queue in question.
     * @returns Whether or not the current user is a manager of the provided
     * queue. This is true if the user the manager of the team (team.managerId)
     * the provided queue is attached to (teamQueue)
     */
    isManagerOfQueue(queue) {
        // Get all of the teamQueues for this queue.
        const teamQueues = TeamQueue.read(
            this.context.teamQueuesIndexed,
            [
                {
                    key: "queueId",
                    value: queue.id
                }
            ]
        );

        // Check if the user is a manager of any team attached to the queue
        return teamQueues.some(teamQueue => {
            return this.context.teamsIndexed[teamQueue.teamId].managerId === this.context.currentUser.id;
        });
    }

    /**
     * @param {object} queue The queue in question.
     * @returns Whether or not the current user is a member of the provided
     * queue. This is true if both the queue and the user are attached to the
     * same team (teamUser/teamQueue);
     */
    isMemberOfQueue(queue) {
        // Get all of the teamQueues for this queue.
        const teamQueues = TeamQueue.read(
            this.context.teamQueuesIndexed,
            [
                {
                    key: "queueId",
                    value: queue.id
                }
            ]
        );

        // Get all the teamUsers for the current user.
        const teamUsers = TeamUser.read(
            this.context.teamUsersIndexed,
            [
                {
                    key: "userId",
                    value: this.context.currentUser.id
                }
            ]
        );

        return teamQueues.some(teamQueue => teamUsers.some(teamUser => teamUser.teamId === teamQueue.teamId));
    }

});