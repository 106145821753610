import api from "../api";

class PresidentsClubService {

    // calls proc spPresidentsClub
    async getPresidentsClub(year) {
        return new Promise((res, reject) => {
            api
                .get("/presidentsClub", year)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const presidentsClubService = new PresidentsClubService();

export default presidentsClubService;
