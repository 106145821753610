import api from "../Security/api";

class DeliveryAcknowledgementService {
    async getAllDeliveryAcknowledgements() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/deliveryAcknowledgements", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateDeliveryAcknowledgement(delivery) {
        return new Promise((res, reject) => {
            api
                .put("/deliveryAcknowledgements", delivery)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createDeliveryAcknowledgement(delivery) {
        return new Promise((res, reject) => {
            api
                .post("/deliveryAcknowledgements", delivery)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteDeliveryAcknowledgement(id) {
        return new Promise((res, reject) => {
            api
                .delete("/deliveryAcknowledgements/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const deliveryAcknowledgementService = new DeliveryAcknowledgementService();

export default deliveryAcknowledgementService;
