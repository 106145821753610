import React from "react";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUIButton from "@mui/material/Button";
import MUIDialogActions from "@mui/material/DialogActions";
import MUITypography from "@mui/material/Typography";
import MUIGrid from "@mui/material/Grid";
import MUIFormControlLabel from "@mui/material/FormControlLabel";
import MUICheckbox from "@mui/material/Checkbox";
import MUITextField from "@mui/material/TextField";
import MUIAutoComplete from "@mui/material/Autocomplete";
import MUIMenuItem from "@mui/material/MenuItem";
import User from "../../Utility/Crud/User";
import IssueReason from "../../Utility/Crud/IssueReason";
import Security from "../../Utility/Security";

export default class DialogAddPatientFeedback extends Dialog {
    static contextTypes = {
        issueCategoriesIndexed: PropTypes.object,
        issueReasonsIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
    };

    renderIssueCategoryDropdown() {
        return (
            <MUIGrid item lg={6} md={12} sm={12}>
                <MUITextField
                    select
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Feedback Type"
                    value={this.props.patientIssue.issueCategory ? this.props.patientIssue.issueCategory : "" }
                    onChange={(e) => {
                        this.props.onChange({ issueCategory: e.target.value, issueReason: null })
                        this.setState({selectedIssueCategory: e.target.value})
                    }}
                    variant="standard"
                    required={true}
                    error={this.props.validationIssues && this.props.validationIssues.issueCategory ? true : false}
                    helperText={this.props.validationIssues ? this.props.validationIssues.issueCategory : ""}
                >
                    {Object.values(this.context.issueCategoriesIndexed).map((issueCategory) => {
                        if (
                            issueCategory.id === 6 &&
                            Security.hasPermission("patientIssue.createClosePatientCollection") === false
                        ) {
                            return null;
                        }

                        return (
                            <MUIMenuItem key={issueCategory.id} value={issueCategory}>
                                {issueCategory.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </MUIGrid>
        );
    }

    renderIssueReasonDropdown() {
        // Filter options based on selected feedback type
        const filteredIssueReasons = this.state.selectedIssueCategory
            ? IssueReason.sort(Object.values(this.context.issueReasonsIndexed)).filter(reason =>
                reason.issueCategoryId === this.state.selectedIssueCategory.id
            )
            : [];

        return (
            <MUIGrid item lg={6} md={12} sm={12}>
                <MUITextField
                    select
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Feedback Reason"
                    value={this.props.patientIssue.issueReason ? this.props.patientIssue.issueReason : "" }
                    onChange={(e) => { this.props.onChange({ issueReason: e.target.value }); }}
                    variant="standard"
                    required={true}
                    error={this.props.validationIssues && this.props.validationIssues.issueReason ? true : false}
                    helperText={this.props.validationIssues ? this.props.validationIssues.issueReason : ""}
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: 400,
                                    overflowY: 'auto',
                                },
                            },
                            // Align the dropdown to the left of the text field
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        },
                    }}
                >
                    {filteredIssueReasons.map((option, idx) => {
                        return (
                            <MUIMenuItem key={idx} value={option}>
                                {option.name}
                            </MUIMenuItem>
                        );
                    })}
                </MUITextField>
            </MUIGrid>
        );
    }

    renderOrderDropdown() {
        return (
            <MUIGrid item lg={12} xs={12}>
                <MUIAutoComplete
                    options={this.props.orders}
                    onChange={(e, value) => this.props.onChange({ orderId: value.value })}
                    renderInput={(params) => <MUITextField {...params}
                        InputLabelProps={{ shrink: true }}
                        size="small" variant="standard"
                        label="Order"
                        required={true}
                        fullWidth
                        error={this.props.validationIssues && this.props.validationIssues.orderId ? true : false}
                        helperText={this.props.validationIssues ? this.props.validationIssues.orderId : ""}
                    />}
                />
            </MUIGrid>
        );
    }

    renderAssignToDropdown() {
        return (
            <MUIGrid item lg={12} xs={12}>
                <MUIAutoComplete
                    options={User.sort(Object.values(this.context.usersIndexed).filter((user) => user.active === true))}
                    getOptionLabel={(option) => User.getDisplayName(option)}
                    onChange={(e, value) => this.props.onChange({ complimentedUserId: value ? value.id : null })}
                    renderInput={(params) => <MUITextField {...params}
                        InputLabelProps={{ shrink: true }}
                        size="small" variant="standard"
                        label="Assign To"
                        required={true}
                        fullWidth
                        error={this.props.validationIssues && this.props.validationIssues.complimentedUserId ? true : false}
                        helperText={this.props.validationIssues ? this.props.validationIssues.complimentedUserId : ""}
                    />}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {`${option.firstname} ${option.lastname}`}
                        </li>
                    )}
                />
            </MUIGrid>
        );
    }

    renderActionPlanRequiredCheckbox() {
        return (
            <MUIGrid item lg={12} xs={12}>
                <MUIFormControlLabel onChange={(e) => this.props.onChange({actionPlanRequired: e.target.checked})} control={<MUICheckbox size="small"/>} label="Action Plan Required" />
            </MUIGrid>
        );
    }

    renderIssueDescriptionTextField() {
        const handleBlur = (e) => {
            if (this.props.patientIssue.complaintDescription) {
                this.props.onChange({ complaintDescription: e.target.value.trim() });
            }
        };

        return (
            <MUIGrid item lg={12} xs={12}>
                <MUITextField
                    multiline
                    rows={8}
                    fullWidth={true}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { resize: "both" } }}
                    label={"Feedback Description"}
                    onChange={(e) => this.props.onChange({complaintDescription: e.target.value})}
                    onBlur={handleBlur}
                    variant="standard"
                    error={this.props.validationIssues && this.props.validationIssues.complaintDescription ? true : false}
                    helperText={this.props.validationIssues ? this.props.validationIssues.complaintDescription : ""}
                />
            </MUIGrid>
        )
    }

    /**
     * Render header title of dialog
     */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                Add Patient Feedback
            </MUITypography>
        );
    }


    /**
     * Render main content text of dialog
     */
    renderContent() {
        return (
            <>
                <MUIGrid container spacing={2}>
                    {this.renderIssueCategoryDropdown()}
                    {this.renderIssueReasonDropdown()}
                    {this.props.patientIssue.issueCategory && this.props.patientIssue.issueCategory.id === 3 ? this.renderAssignToDropdown() : null}
                    {this.props.showOrderDropdown ? this.renderOrderDropdown() : null}
                    {this.renderIssueDescriptionTextField()}
                    {this.renderActionPlanRequiredCheckbox()}
                </MUIGrid>
            </>
        );
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Cancel
                </MUIButton>
                <MUILoadingButton variant="contained" loading={this.props.loading} onClick={this.props.onConfirm}>
                    Create Feedback
                </MUILoadingButton>
            </MUIDialogActions>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "sm";
    }
}
