import React from "react";
import MUIChip from '@mui/material/Chip';
import MUIGrid from '@mui/material/Grid';
import MUITextField from "@mui/material/TextField";
import MUIInputAdornment from "@mui/material/InputAdornment";
import ReportSetting from "../ReportSetting";

export default class ReportSettingDraggableList extends ReportSetting {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.grouping,
            deletedItems: [],
            draggingIndex: null,
        };
    }

    componentDidMount() {
        this.checkMissingItems();
    }

    checkMissingItems() {
        const missingItems = this.props.allGroups.filter(item =>
            !this.state.items.some(existingItem => existingItem.columnName === item.columnName)
        );

        if (missingItems.length > 0) {
            const missingItemsToAdd = missingItems.map(item => ({
                title: item.title,
                columnName: item.columnName,
            }));

            this.setState(prevState => ({
                deletedItems: [...prevState.deletedItems, ...missingItemsToAdd],
            }));
        }
    }

    handleDragStart(e, index) {
        this.setState({ draggingIndex: index });
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target);
    };

    handleDragOver = (e) => {
        e.preventDefault();
    };

    handleDrop(e, targetIndex) {
        const { draggingIndex, items } = this.state;
        const updatedItems = [...items];

        const [draggedItem] = updatedItems.splice(draggingIndex, 1);
        updatedItems.splice(targetIndex, 0, draggedItem);

        this.setState({
            items: updatedItems,
            draggingIndex: null,
        }, () => {
            this.handleSettingChange("grouping", this.state.items);
        });
    };

    handleDeleteChip(index) {
        const updatedItems = [...this.state.items];
        const deletedChip = updatedItems.splice(index, 1)[0];
        this.setState(
            (prevState) => ({
                items: updatedItems,
                deletedItems: [...prevState.deletedItems, deletedChip],
            }),
            () => {
                this.handleSettingChange("grouping", this.state.items);
            }
        );
    };

    handleReAddChip = (index) => {
        const updatedDeletedItems = [...this.state.deletedItems];
        const chipToReAdd = updatedDeletedItems.splice(index, 1)[0];
        this.setState(
            (prevState) => ({
                deletedItems: updatedDeletedItems,
                items: [...prevState.items, chipToReAdd],
            }),
            () => {
                this.handleSettingChange("grouping", this.state.items);
            }
        );
    };


    render() {
        return (
            <MUIGrid container spacing={1}>
                <MUIGrid item xs={12} sm={6}>
                    <MUITextField
                        value={this.props.accountType ? this.props.accountType.name : ""}
                        size="small"
                        label="Active Grouping"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <MUIInputAdornment position="start">
                                    <div style={{ display: 'flex', marginTop: 8 }}>
                                        {this.state.items.map((item, index) => (
                                            <MUIChip
                                                key={index}
                                                draggable
                                                onDragStart={(e) => this.handleDragStart(e, index)}
                                                onDragOver={this.handleDragOver}
                                                onDrop={(e) => this.handleDrop(e, index)}
                                                label={item.title}
                                                clickable
                                                onDelete={() => this.handleDeleteChip(index)}
                                                sx={{ mr: 1, mb: 1 }}
                                                color="primary"
                                            />
                                        ))}
                                    </div>
                                </MUIInputAdornment>
                            ),
                            sx: {
                                paddingBottom: 1,
                                paddingTop: 1,
                            },
                        }}
                        helperText={this.state.items.length > 0 ? "Drag and drop chips to reorder the grouping" : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6}>
                    <MUITextField
                        value={this.props.accountType ? this.props.accountType.name : ""}
                        size="small"
                        label="Removed Grouping"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <MUIInputAdornment position="start">
                                    <div style={{ display: 'flex', marginTop: 8 }}>
                                        {this.state.deletedItems.map((item, index) => (
                                            <MUIChip
                                                key={index}
                                                label={item.title}
                                                clickable
                                                onClick={() => this.handleReAddChip(index)}
                                                sx={{ mr: 1, mb: 1 }}
                                            />
                                        ))}
                                    </div>
                                </MUIInputAdornment>
                            ),
                            sx: {
                                paddingBottom: 1,
                                paddingTop: 1,
                            },
                        }}
                        helperText={this.state.deletedItems.length > 0 ? "Click a chip to restore it to the active grouping" : ""}
                    />
                </MUIGrid>
            </MUIGrid>
        );
    }
}