import React from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    toast,
    MDBToastContainer,
    MDBInput,
    MDBRow,
    MDBCol,
    MDBBtn,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import TrainingLinkService from "./trainingLinkService";
import LayerLegacy from "../../LayerLegacy";

export default class TrainingLink extends LayerLegacy {
    constructor(props) {
        super(props);

        // clickEvent: this.handleRowClick

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Description",
                    field: "description",
                    sort: "asc",
                },
                {
                    label: "Url",
                    field: "url",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            editModal: false,
            editObject: {},
            addEdit: "",
            currSize: {},
            currIndex: -1,
            errorMessage: "",
            productNames: [],
        };
    }

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.retrieveTrainingLinks();
    }

    handleClickEvent(e, row) {
        if (e && e.target.cellIndex === 3) {
            //window.open('https://www.codexworld.com', '_blank');
            window.open(row.url, "_blank");
            //open link in new tab;
            return;
        }

        this.setState({
            editObject: row,
            editModal: true,
            addEdit: "edit",
        });
    }

    addNewTrainingLink() {
        let l = this.state.editObject,
            d = this.state.data;

        if (!l.name || !l.url || !l.description) {
            return toast.error("Please fill out all fields");
        }

        TrainingLinkService.createTrainingLink(l).then((r) => {
            let cp = d.rows.slice();

            cp.push({
                id: r.id,
                name: r.name,
                description: r.description,
                url: r.url,
                clickEvent: (e) => this.handleClickEvent(e, r),
            });

            d.rows = cp;

            this.setState({
                data: d,
                editModal: false,
                editObject: {},
            });
        });
    }

    updateTrainingLink() {
        let l = this.state.editObject,
            d = this.state.data;

        if (!l.name || !l.url || !l.description) {
            return toast.error("Please fill out all fields");
        }
        let idx = d.rows.findIndex((v) => {
            return v.id === l.id;
        });

        TrainingLinkService.updateTrainingLink(l).then((r) => {
            let cp = d.rows.slice();

            let nObj = {
                id: r.id,
                name: r.name,
                description: r.description,
                url: r.url,
                clickEvent: (e) => this.handleClickEvent(e, r),
            };

            if (idx > -1) {
                cp.splice(idx, 1, nObj);
                d.rows = cp;
            }

            this.setState({
                data: d,
                editModal: false,
                editObject: {},
            });
        });
    }

    retrieveTrainingLinks() {
        let t = this;

        TrainingLinkService.getAllTrainingLinks()
            .then((res) => {
                let arr = [],
                    d = this.state.data;

                res.forEach((link, index) => {
                    arr.push({
                        id: link.id,
                        name: link.name,
                        description: link.description,
                        url: link.url,
                        clickEvent: (e) => this.handleClickEvent(e, link),
                    });
                });
                d.rows = arr;
                t.setState({
                    data: d,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                //handle error...BC
            });
    }

    renderEditModal() {
        let eo = this.state.editObject,
            addEdit = this.state.addEdit,
            t = this;

        function addEditButton() {
            if (addEdit === "add") {
                return (
                    <MDBBtn
                        size={"sm"}
                        rounded
                        color={"success"}
                        data-tip={"Add New Link"}
                        onClick={() => t.addNewTrainingLink()}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </MDBBtn>
                );
            }
            if (addEdit === "edit") {
                return (
                    <MDBBtn
                        size={"sm"}
                        rounded
                        color={"info"}
                        data-tip={"Update Link"}
                        onClick={() => t.updateTrainingLink()}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </MDBBtn>
                );
            }
        }

        return (
            <Modal
                backdrop={false}
                isOpen={this.state.editModal}
                toggle={() => {
                    return;
                }}
            >
                <ModalHeader style={{ backgroundColor: "#90A4AE", color: "white" }}>
                    {addEdit === "add" ? "New" : "Edit"} Training Link {eo.id}
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    <MDBRow>
                        <MDBCol size="6">
                            <MDBInput
                                outline
                                label={"Name"}
                                valueDefault={eo.name}
                                onChange={(e) => this.handleEditChange("name", e)}
                            />
                        </MDBCol>
                        <MDBCol size="6">
                            <MDBInput
                                outline
                                label={"Url"}
                                valueDefault={eo.url}
                                onChange={(e) => this.handleEditChange("url", e)}
                            />
                        </MDBCol>
                        <MDBCol size="12">
                            <MDBInput
                                outline
                                label={"Description"}
                                valueDefault={eo.description}
                                onChange={(e) => this.handleEditChange("description", e)}
                            />
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    {addEditButton()}

                    <Button
                        rounded
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={() => this.handleEditClose()}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    handleEditClose() {
        this.setState({
            editObject: {},
            editModal: !this.state.editModal,
        });
    }

    handleEditChange(property, e) {
        let s = this.state.editObject;
        s[property] = e.target.value;

        this.setState({
            editObject: s,
        });
    }

    renderTable() {
        if (this.state.isLoaded === true) {
            return (
                <MDBTable small striped style={{ textAlign: "center" }}>
                    <MDBTableHead columns={this.state.data.columns} />
                    <MDBTableBody rows={this.state.data.rows} />
                </MDBTable>
            );
        } else {
            return <div />;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div>
                <MDBToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Container className="mt-5">
                    {this.renderEditModal()}
                    <Card>
                        <CardHeader
                            style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30 }}>
                            <Button
                                style={{ float: "right" }}
                                floating
                                size="sm"
                                color={"secondary"}
                                data-tip={"Add New Training Link"}
                                onClick={() =>
                                    this.setState({ addEdit: "add", editModal: true })
                                }
                            >
                                <MDBIcon icon="photo-video" style={{ fontSize: "2em" }} />
                            </Button>
							Training Links
                        </CardHeader>
                        <CardBody>{this.renderTable()}</CardBody>

                        <ReactTooltip />
                    </Card>
                </Container>
            </div>
        );
    }
}
