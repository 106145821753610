import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    DataTable,
    ToastContainer,
    Spinner,
    MDBIcon,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import ProdcutMatrixEntryService from "../Security/ProdcutMatrixEntryService/prodcutMatrixEntryService";
import PropTypes from "prop-types";
import LayerLegacy from "../../LayerLegacy";

export default class ProductMatrixEntry extends LayerLegacy {
    constructor(props) {
        super(props);
        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Product",
                    field: "product",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Insurance",
                    field: "insurance",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Points",
                    field: "points",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Delete",
                    field: "delete",
                    sort: "asc",
                    width: 100,
                },
            ],
            rows: [],
        };

        let route = props.location.state && props.location.state.id ? "/" + props.location.state.id : "";

        this.state = {
            isLoaded: false,
            data: data,
            nProduct: {},
            editToggle: false,
            errorMessage: "",
            currIndex: -1,
            route: route,
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    componentDidMount() {
        this.retrieveProducts();
    }

    retrieveProducts() {
        ProdcutMatrixEntryService.getAllProductsWithMappings()
            .then((res) => {
                let arr = [],
                    d = this.state.data;
                res.map((p, index) => {
                    return arr.push(this.createRow(p, index));
                });
                d.rows = arr;
                this.setState({
                    data: { columns: d.columns, rows: [] },
                });
                this.setState({
                    isLoaded: true,
                    data: d,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    createRow(p, index) {
        // [ id, points, insuranceId, productTypeId, productName, insuranceName ]
        let c = {
            id: p.id,
            product: p.productSubType.name,
            insurance: p.insuranceType.name,
            points: p.points || "",
            delete: this.renderDeleteColumn(p.id),
        };
        return c;
    }

    renderDeleteColumn(id) {
        return (
            <Button
                floating
                color={"red"}
                size="sm"
                onClick={() => this.handleRemoveClick(id)}
            >
                <MDBIcon icon="trash" />
            </Button>
        );
    }

    handleRemoveClick(id) {
        ProdcutMatrixEntryService.deleteProduct(id)
            .then((res) => {
                this.retrieveProducts();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderTable() {
        return <DataTable small striped data={this.state.data} />;
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }

        return (
            <Container className="mt-5">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Card>
                    <CardHeader
                        style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30 }}>
						Product Matrix Entries
                        <Button
                            style={{ float: "left" }}
                            floating
                            size="sm"
                            color={"warning"}
                            data-tip={"Back to Product SubTypes"}
                            onClick={() =>
                                this.props.history.push({
                                    pathname: "/products" + this.state.route,
                                    state: { product: this.props.location.state.product },
                                })
                            }
                        >
                            <MDBIcon icon="backward" />
                        </Button>
                    </CardHeader>
                    <CardBody>
                        {this.renderTable()}
                        <ReactTooltip />
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
