import Admin from "../Admin";
import React from "react";
import CardAdminLocationGeneral from "../../../Component/Card/Admin/Location/CardAdminLocationGeneral";
import CardAdminLocationContact from "../../../Component/Card/Admin/Location/CardAdminLocationContact";
import CardAdminLocationCredentialing from "../../../Component/Card/Admin/Location/CardAdminLocationCredentialing";
import CardAdminLocationExternalIdentifiers from "../../../Component/Card/Admin/Location/CardAdminLocationExternalIdentifiers";
import CardAdminLocationUsers from "../../../Component/Card/Admin/Location/CardAdminLocationUsers";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import LocationService from "../../../Seating/Security/Locations/locationsService";
import Validator from "../../../Utility/Validator";
import Location from "../../../Utility/Crud/Location";
import UserProfileLocationService from "../../../Seating/Security/UserprofileLocationService/userprofileLocationService";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminLocationCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.location = null;
        this.state.userprofileLocations = [];
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the location and users in the state.
     */
    afterComponentDidMount() {
        const locationId = this.props.match.params.id ? +this.props.match.params.id : null;
        const userprofileLocations = Object.values(this.context.userprofileLocationsIndexed)
            .filter((userprofileLocation) => userprofileLocation.locationsId === locationId)
            .map((userprofileLocation) => {
                const user = this.context.usersIndexed[userprofileLocation.userprofileId];
                return {
                    ...userprofileLocation,
                    userDetails: user || null
                };
            });

        if (locationId !== null) {
            const location = this.context.locationsIndexed[locationId];
            this.setState({
                location: location,
                userprofileLocations: userprofileLocations,
                userprofileLocationsOriginal: userprofileLocations,
            });
        }
    }

    /**
     * Creates the location with the current properties in state.location.
     */
    async createLocation() {
        this.setState({
            isProcessing: true,
        });

        return LocationService.createLocation(this.state.location)
            .then(location => {
                this.context.updateIndexed("locationsIndexed", location);

                this.setState({
                    userprofileLocations: this.state.userprofileLocations.map((userprofileLocation) => {
                        return {
                            ...userprofileLocation,
                            ...{
                                locationsId: location.id
                            }
                        };
                    })
                });

                return this.createDeleteUserprofileLocations();
            })
            .then(() => {
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Location created successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Location.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the location with the current properties in state.location.
     */
    async updateLocation() {
        this.setState({
            isProcessing: true,
        });

        const promises = [];

        promises.push(
            LocationService.updateLocation(this.state.location).then(location => {
                this.context.updateIndexed("locationsIndexed", location);
            })
        );

        promises.push(this.createDeleteUserprofileLocations());

        return Promise.all(promises)
            .then(() => {
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Location updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Location", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
    * Creates or deletes location users from location.
    */
     async createDeleteUserprofileLocations() {
        const promises = [];

        this.state.userprofileLocations.forEach((userprofileLocation) => {
            if (!userprofileLocation.id) {
                promises.push(
                    UserProfileLocationService.createLocationUser(userprofileLocation).then((userprofileLocation) => {
                        this.context.updateIndexed("userprofileLocationsIndexed", userprofileLocation);
                    })
                );
            }
        });

        if (this.state.userprofileLocationsOriginal) {
            this.state.userprofileLocationsOriginal.forEach((userprofileLocationsOriginal) => {
                if (
                    this.state.userprofileLocations.find((userprofileLocation) => {
                        return userprofileLocation.userprofileId === userprofileLocationsOriginal.userprofileId;
                    }) === undefined
                ) {
                    promises.push(
                        UserProfileLocationService.deleteLocationUser(userprofileLocationsOriginal.id).then(() => {
                            this.context.deleteIndexed("userprofileLocationsIndexed", userprofileLocationsOriginal.id);
                        })
                    );
                }
            });
        }

        return Promise.all(promises);
    }

    async deleteLocation() {
        this.setState({
            isProcessing: true,
        });

        return LocationService.deleteLocation(this.state.location)
            .then(() => {
                this.context.deleteIndexed("locationsIndexed", this.state.location.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Location deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Location.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (location) => {
            this.setState({
                location: {
                    ...this.state.location,
                    ...location,
                },
            });
        };

        const handleChangeUserprofileLocations = (userprofileLocations) => {
            this.setState({
                userprofileLocations: userprofileLocations
            });
        };

        return (
            <>
                <CardAdminLocationGeneral
                    location={this.state.location}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminLocationContact
                    location={this.state.location}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminLocationCredentialing
                    location={this.state.location}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminLocationExternalIdentifiers
                    location={this.state.location}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminLocationUsers
                    location={this.state.location}
                    userprofileLocations={this.state.userprofileLocations}
                    onChange={handleChangeUserprofileLocations}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteLocation()}
                    text="Are you sure you want to delete this location? This cannot be undone."
                    header="Delete Location"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Render the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Location';
            subtitle = null;
        } else {
            title = 'Edit Location';
            subtitle = (this.state.location && (Location.getDisplayName(this.state.location) + " • " + this.state.location.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.location,
                {
                    name: {
                        'required': true
                    },
                    address: {
                        'required': true
                    },
                    city: {
                        'required': true
                    },
                    state: {
                        'required': true
                    },
                    zipCode: {
                        'type': 'zipCode',
                        'required': true,
                    },
                    phone: {
                        'type': 'phone',
                    },
                    npi: {
                        'type': 'npi',
                    },
                    brightreeExternalId: {
                        'type': 'integer',
                    },
                    birdeyeExternalId: {
                        'type': 'integer',
                    }
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateLocation();
                } else {
                    this.createLocation();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
