import TermCreateEdit from "../AdminTermCreateEdit";

export default class AdminTermCreateEditBrightreeInvoiceStatus extends TermCreateEdit {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return "brightree_invoice_status";
    }
}
