import Admin from "../Admin";
import React from "react";
import CardAdminAccountTypeGeneral from "../../../Component/Card/Admin/AccountType/CardAdminAccountTypeGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';

import Validator from "../../../Utility/Validator";

import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import AccountType from "../../../Utility/Crud/AccountType";
import AccountTypesService from "../../../Seating/Settings/accountTypesService";

export default class AdminAccountTypeCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.accountType = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the accountType in the state.
     */
    afterComponentDidMount() {
        const accountTypeId = this.props.match.params.id ? +this.props.match.params.id : null;
        
        if (accountTypeId !== null) {
            const accountType = this.context.accountTypesIndexed[accountTypeId];
            this.setState({
                accountType: accountType,
            });
        }
    }

    /**
     * Creates the account with the current properties in state.accountType.
     */
    async createAccountType() {
        this.setState({
            isProcessing: true,
        });

        return AccountTypesService.createAccountType(this.state.accountType)
            .then(accountType => {
                this.context.updateIndexed("accountTypesIndexed", accountType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Account Type created successfully!", {variant: "success"});
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Account Type.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }

    /**
     * Updates the account with the current properties in state.accountType.
     */
    async updateAccountType() {
        this.setState({
            isProcessing: true,
        });

        return AccountTypesService.updateAccountType(this.state.accountType)
            .then(accountType => {
                this.context.updateIndexed("accountTypesIndexed", accountType);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Account Type updated successfully!", { variant: "success" });
            })
            .catch(err => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Account Type.", { variant: "error" });
                this.setState({
                    isProcessing: false,
                });
            });
    }


    async deleteAccountType() {
        this.setState({
            isProcessing: true,
        });

        return AccountTypesService.deleteAccount(this.state.accountType.id)
            .then(() => {
                this.context.deleteIndexed("accountTypesIndexed", this.state.accountType.id);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Account Type deleted successfully!", { variant: "success" });
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Account Type.", { variant: "error" });
                this.setState({
                    confirmDialogOpen: false,
                });
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (accountType) => {
            this.setState({
                accountType: {
                    ...this.state.accountType,
                    ...accountType,
                },
            });
        };
        
        return (
            <>
                <CardAdminAccountTypeGeneral
                    accountType={this.state.accountType}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteAccountType()}
                    text="Are you sure you want to delete this account type? This cannot be undone."
                    header="Delete Account Type"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Render the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Account Type';
            subtitle = null;
        } else {
            title = 'Edit Account Type';
            subtitle = (this.state.accountType && (AccountType.getDisplayName(this.state.accountType) + " • " + this.state.accountType.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack();
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.accountType,
                {
                    name: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateAccountType();
                } else {
                    this.createAccountType();
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        };

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />));
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
