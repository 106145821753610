import React from "react";
import DialogSearch from "../DialogSearch";
import MUIDialogActions from "@mui/material/DialogActions";
import MUIButton from "@mui/material/Button";
import TileTerm from "../../Tile/TileTerm";
import PropTypes from "prop-types";
import Term from "../../../Utility/Crud/Term";

export default class DialogSearchTerm extends DialogSearch {

    static contextTypes = {
        termsIndexed: PropTypes.object,
    };

    /**
     * Searches for terms by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered terms.
     */
    getSearchResults(query) {
        return Term.search(this.state.data, query, true).filter((term) => {
            return term.deleted === false;
        });
    }

    /**
     * Gets the data that should be used in this dialog. Data can be manually
     * passed via the data prop, otherwise it falls back to the global list.
     *
     * @returns {array}
     */
    getData() {
        if (this.props.data) {
            return this.props.data;
        } else {
            return Object.values(this.context.termsIndexed);
        }
    }

    /**
     * @param {object} term The term object.
     * @returns A TileTerm component for this term.
     */
    renderTile(term) {
        return (
            <TileTerm
                key={term.id}
                term={term}
            />
        );
    }
}
