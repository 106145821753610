import React from "react";
import PropTypes from "prop-types";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import {
    GroupingState,
    SortingState,
    IntegratedSorting,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";

import {
    Button,
    Container,
    Input,
    Spinner,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    toast,
    ToastContainer,
    MDBIcon,
    Modal,
    ModalBody,
    MDBListGroupItem,
    CardHeader,
    MDBInput,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import OrderService from "../Security/OrderService/orderService";
import PurchaseQueueService from "../Security/PurchaseQueueService/purchaseQueueService";
import globalFunctions from "../Filters/GlobalFunctions";

export default class InventoryReqeustTable extends React.Component {
    constructor(props) {
        super(props);

        const approvalData = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Qty",
                    field: "qty",
                    sort: "asc",
                },
                {
                    label: "Description",
                    field: "desc",
                    sort: "asc",
                },
                {
                    label: "Cost",
                    field: "cost",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        const approvalDataLong = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Qty",
                    field: "qty",
                    sort: "asc",
                },
                {
                    label: "Description",
                    field: "desc",
                    sort: "asc",
                },
                {
                    label: "Cost",
                    field: "cost",
                    sort: "asc",
                },
                {
                    label: "Account",
                    field: "account",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        const groupColumns = [{ columnName: "location" }, { columnName: "vendor" }];

        const medlineAccounts = [
            {label: "Cork Medical: Indianapolis (1332105)", value: "1332105"},
            {label: "Cork Medical: Marion (1366253)", value: "1366253"},
            {label: "Cork Medical: Elmhurst (1367226)", value: "1367226"},
            {label: "Cork Medical: Louisville (1375335)", value: "1375335"},
            {label: "Cork Medical: (PHD) (1437871)", value: "1437871"},
            {label: "Cork Medical: Lenexa (1443086)", value: "1443086"},
            {label: "Cork Medical: Wisconsin (1332105)", value: "1332105"},
            {label: "Cork Medical: Elmhurst (1332105)", value: "1332105"},
            {label: "Cork Medical: Cincinnati (1332105)", value: "1332105"},
            {label: "Cork Medical: Grand Rapids (1332105)", value: "1332105"},
            {label: "Cork Medical: Terre Haute (1332105)", value: "1332105"},
            {label: "Cork Medical: Detroit (1332105)", value: "1332105"},
        ];

        const data = this.buildData();

        let defaultColumnWidths = [];

        this.state = {
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            defaultColumnWidths: defaultColumnWidths,
            groupSummaryItems: [],
            approvalModal: false,
            nPurchaseQueues: [],
            brightreeId: "",
            errorMessage: "",
            approvalData: approvalData,
            approvalDataLong: approvalDataLong,
            medlineAccounts: medlineAccounts,
            accountNumber: "",
            productSubTypes: [],
        };

        this.changeGrouping = (grouping) => {
            let ary = [];

            grouping.forEach((group) => {
                ary.push({ columnName: group.columnName });
            });
            this.setState({
                groupingColumns: ary,
            });
        };

        this.getResults();
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        productSubTypes: PropTypes.array,
        companyName: PropTypes.string,
        productTypes: PropTypes.array,
        allVendors: PropTypes.array,
    };

    getProductList(subType, prod) {
        // let subTypes = this.state.productSubTypes;
        const { productSubTypes } = this.context;
        let a = [];

        productSubTypes.map((product) => {
            if (product.vendor && product.vendor.id === prod.vendor.id) {
                let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
                let l = product.productType.name + " | " + product.name + h;

                return a.push({
                    label: l,
                    value: product,
                    checked: product.id === subType.id,
                });
            }
            return product;
        });
        this.setState({productSubTypes: a });
    }

    renderProductDropdown(subType, index, product) {
        return (
            <div style={{ width: 400, paddingTop: 2 }}>
                <Select
                    defaultValue={subType.name}
                    placeholder={subType.name}
                    options={this.state.productSubTypes}
                    onChange={this.handleProductSelectChange.bind(this, index)}
                />
            </div>
        );
    }

    handleProductSelectChange(index, subType) {
        if (subType && subType.value) {
            let pq = this.state.nPurchaseQueues,
                obj = {
                    id: subType.value.id,
                    name: subType.value.name,
                };
            pq[index].productSubType = obj;
            this.setState({
                nPurchaseQueues: pq,
            });
        }
    }

    handleAccountChange(index, accountNum) {
        if (accountNum && accountNum.value) {
            let pq = this.state.nPurchaseQueues;

            pq[index].accountNumber = accountNum.value;
            this.setState({ nPurchaseQueues: pq });
        }
    }

    renderAccountDropdown(index) {
        return (
            <div style={{ width: 400, paddingTop: 2 }}>
                <Select
                    defaultValue=""
                    placeholder="Medline Account"
                    options={this.state.medlineAccounts}
                    onChange={this.handleAccountChange.bind(this, index)}
                />
            </div>
        );
    }

    buildData() {
        let d = {
            columns: [
                {
                    title: "ID",
                    name: "id",
                },
                {
                    title: "Item Id",
                    name: "itemId",
                },
                {
                    title: "Quantity",
                    name: "quantity",
                },
                {
                    title: "Requested On",
                    name: "requestedOn",
                },
                {
                    title: "Status",
                    name: "status",
                },
                {
                    title: "Location",
                    name: "location",
                },
                {
                    title: "Product",
                    name: "product",
                },
                {
                    title: "Vendor",
                    name: "vendor",
                },
                {
                    title: "Status Changed On",
                    name: "statusChangedOn",
                },
                {
                    title: "Approve",
                    name: "approve",
                },
                {
                    title: "Cancel",
                    name: "cancel",
                },
            ],
            rows: [],
        };

        return d;
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // 	let status = window.location.href.split('/')[4]
    // 	if (status !== this.state.status) {
    // 	    this.setState({
    // 	        data: this.buildData(status),
    // 	        status: status
    // 	    })
    // 	    this.getResults(status)
    // 	}
    // }

    Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

    CellContent = ({ row, column, expanded, ...props }) => {
        let cell = (
            <TableGroupRow.Cell
                {...props}
                row={row}
                column={column}
                expanded={expanded}
            />
        );
        if (column.name === "vendor" && expanded) {
            cell = (
                <TableGroupRow.Cell
                    {...props}
                    row={row}
                    column={column}
                    expanded={expanded}
                    //onToggle={ () => this.handleApproveAll(props.tableRow.row)}
                />
            );
        }
        return cell;
    };

    async handleApproveAll(e, grid) {
        const { currentUser } = this.context;
        e.stopPropagation();

        let grouping = grid.compoundKey.split("|");
        let vendor = grid.value;
        let arr = grid.rows.filter((r) => {
            return grouping[0] === r.location && grouping[1] === vendor;
        });
        let ids = [];
        arr.map((r) => {
            return ids.push(r.id);
        });

        let objs = await PurchaseQueueService.findPurchaseQueuesByIds({ ids: ids });

        if (objs && objs.length > 0) {
            objs.map((p) => {
                p.statusChangedBy = { id: currentUser.id, name: currentUser.username };
                p.statusChangedById = currentUser.id;
                p.statusChangedOn = new Date().toISOString();
                p.status = "APPROVED";
                return p;
            });

            this.setState({
                nPurchaseQueues: objs,
                approvalModal: true,
            });
        }
    }

    approvePurchaseQueues = () => {
        let st = this.state,
            purchaseQueues = st.nPurchaseQueues,
            btId = st.brightreeId;
        this.setState({ saveloading: true });

        if (this.validateApproval(btId)) {
            purchaseQueues.map((p) => {
                p.brightreeId = parseInt(btId);
                return p;
            });
            PurchaseQueueService.approveInventoryRequests(purchaseQueues)
                .then((res) => {
                    if (res) {
                        toast.success("Equipment Approved");
                        let data = this.state.data,
                            rows = data.rows.slice();

                        purchaseQueues.map((result) => {
                            let i = rows.findIndex((row) => {
                                return result.id === row.id;
                            });
                            return rows.splice(i, 1);
                        });

                        data.rows = rows;
                        this.setState({
                            data: data,
                            approvalModal: false,
                            errorMessage: "",
                            brightreeId: "",
                        });

                        this.genrateDownload(res.id);

                        //this.createOrderNotes(purchaseQueues);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        }
    };

    genrateDownload(fileId) {
        return OrderService.downloadDoc(fileId).then((res) => {
            let file = new Blob([res], { type: "application/pdf" });
            let url = window.URL.createObjectURL(file);
            let temp = document.createElement("a");
            temp.href = url;
            temp.setAttribute("download", "Purchase Order.pdf");
            temp.click();
            window.URL.revokeObjectURL(url);
        });
        //let pdf = new File([file], "purchaseOrder.pdf");
        //let url = window.URL.createObjectURL(pdf);
        //let temp = document.createElement("a");
        //temp.href = url;
        //temp.setAttribute("download", "purchaseOrder.pdf");
        //temp.click();
        //window.URL.revokeObjectURL(url);
    }

    validateApproval(btId) {
        const { companyName } = this.context;
        if (!btId && companyName !== "corkmedical") {
            this.setState({
                errorMessage: "Please enter a Brightree P.O.",
            });
            return false;
        }
        return true;
    }

    Content = ({ row, column }) => (
        <span>
            <span>
                {column.title} : {row.value || "(Blank)"}
            </span>
            <span style={{ fontWeight: "bold" }}>
				&nbsp; {this.getVal(row, column)}
            </span>
            <span style={{ fontWeight: "bold" }}>
				&nbsp; {this.approveAllMessage(row, column)}
            </span>
        </span>
    );

    getVal(row, column) {
        if (row.collapsedRows.length > 0) {
            return "Count: " + row.collapsedRows.length;
        } else {
            return "";
        }
    }

    approveAllMessage(row) {
        if (row.groupedBy === "vendor" && row.collapsedRows.length === 0) {
            return (
                <Button
                    size={"sm"}
                    rounded
                    onClick={(e) => this.handleApproveAll(e, row)}
                >
					Approve All
                </Button>
            );
        }
    }

    getResults() {
        return PurchaseQueueService.getInventoryRequestListing()
            .then((res) => {
                this.buildNewResults(res);
            })
            .catch((err) => {
                this.setState({
                    isLoaded: true,
                });
            });
    }

    buildNewResults(res) {
        let ary = [],
            dt = this.state.data;

        res.forEach((val) => {
            let row = {
                id: val.id,
                itemId: val.itemId || "",
                quantity: val.quantity,
                requestedOn: this.convertDate(val.requestedOn),
                status: val.status,
                location: val.location,
                product: val.product,
                vendor: val.vendor || "",
                statusChangedOn: this.convertDate(val.statusChangedOn),
                approve: this.createBtn(val.id, 0),
                cancel: this.createBtn(val.id, 2),
            };
            ary.push(row);
        });

        dt.rows = ary;

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    canApprove() {
        const { currentUser } = this.context;

        switch (currentUser.role) {
            case "PURCHASING":
            case "EXECUTIVE_MANAGEMENT":
            case "ADMIN":
                return true;
            default:
                return false;
        }
    }

    createBtn(id, num) {
        switch (num) {
            case 0:
                return (
                    <Button
                        floating
                        size="sm"
                        disabled={!this.canApprove()}
                        color="green"
                        onClick={() => this.handleEdit(id, "APPROVED")}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>
                );
            case 2:
                return (
                    <Button
                        floating
                        size="sm"
                        disabled={!this.canApprove()}
                        color="red"
                        onClick={() => this.handleEdit(id, "DENIED")}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                );
            default:
                break;
        }
    }

    fileDownloadHandler(id) {
        return OrderService.downloadDoc(id)
            .then((res) => {
                this.genrateNewTab(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    genrateNewTab(file) {
        let pdf = new Blob([file], { type: "application/pdf" });
        let url = window.URL.createObjectURL(pdf);
        let w = window.open();
        w.location.href = url;
        window.URL.revokeObjectURL(url);
    }

    saveEdit(obj) {
        PurchaseQueueService.editPurchaseQueue(obj)
            .then((res) => {
                let data = this.state.data,
                    rows = data.rows.slice(),
                    i = rows.findIndex((p) => {
                        return p.id === obj.id;
                    });

                rows.splice(i, 1);
                data.rows = rows;
                this.setState({
                    data: data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async handleEdit(id, status) {
        const { currentUser } = this.context;

        let purchaseQueue = await PurchaseQueueService.findPurchaseQueuesById(id);

        purchaseQueue.status = status;
        purchaseQueue.statusChangedOn = new Date().toISOString();
        purchaseQueue.statusChangedById = currentUser.id;
        purchaseQueue.statusChangedBy = {
            id: currentUser.id,
            name: currentUser.username,
        };

        if (status === "APPROVED") {
            this.setState({
                nPurchaseQueues: [purchaseQueue],
                approvalModal: true,
            });
            return;
        }

        this.saveEdit(purchaseQueue);
    }

    convertDate(date) {
        if (!date)
            return null;
        //let arr = date.split("-");
        //return arr[1] + "-" + arr[2] + "-" + arr[0];
        /*return new Date(date).toString();*/
        return globalFunctions.formatDateTime(date);
    }

    renderTable() {
        let st = this.state;
        return (
            <Grid
                rootComponent={this.Root}
                rows={st.data.rows}
                columns={st.data.columns}
            >
                <PagingState defaultCurrentPage={0} pageSize={25} />
                <SortingState
                    defaultSorting={[{ columnName: "requestedOn", direction: "desc" }]}
                />

                <DragDropProvider />
                <GroupingState
                    columnGroupingEnabled={false}
                    defaultGrouping={st.groupingColumns}
                    // columnExtensions={st.disabledGroupings}
                />
                <IntegratedGrouping />
                <IntegratedPaging />
                <IntegratedSorting />
                <VirtualTable height={"auto"} />
                <PagingPanel />
                <TableHeaderRow showSortingControls />
                <TableGroupRow
                    cellComponent={this.CellContent}
                    contentComponent={this.Content}
                    showColumnsWhenGrouped={false}
                ></TableGroupRow>
                {this.ItemCounter()}
                <Toolbar />
                <GroupingPanel />
            </Grid>
        );
    }

    ItemCounter = () => (
        <Plugin name="ItemCounter">
            <Template
                name="tableCell"
                predicate={({ tableRow }) => isGroupTableRow(tableRow)}
            >
                {(params) => (
                    <TemplateConnector>
                        {({ getCollapsedRows, rows }) => {
                            const updatedParams = {
                                ...params,
                                tableRow: {
                                    ...params.tableRow,
                                    row: {
                                        ...params.tableRow.row,
                                        collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                        rows: rows.filter((r) => {
                                            return r.groupedBy === undefined;
                                        }),
                                    },
                                },
                            };
                            return <TemplatePlaceholder params={updatedParams} />;
                        }}
                    </TemplateConnector>
                )}
            </Template>
        </Plugin>
    );

    determineColumns() {
        const { companyName } = this.context;

        let st = this.state,
            pq = st.nPurchaseQueues;

        if (pq && pq.length > 0) {
            if (["uat", "local"].includes(companyName) && pq.filter(x => x.vendor === "Bright Way Group").length > 0) {
                //if (st.nPurchaseQueues !== null && st.nPurchaseQueues.length > 0) {
                //	if (["uat", "local"].includes(companyName) && st.nPurchaseQueues.filter(x => x.vendor === "Bright Way Group").length > 0) {
                // if (companyName === "uat" && st.nPurchaseQueues[0].vendor && st.nPurchaseQueues[0].vendor.name === "Medline") {
                // if (companyName === "corkmedical" && st.nPurchaseQueues[0].vendor && st.nPurchaseQueues[0].vendor.name === "Medline") {
                return st.approvalDataLong.columns;
            } else {
                return st.approvalData.columns;
            }
        }
    }

    renderApproveModal() {
        //const { companyName } = this.context;

        //let st = this.state,
        //	pq = st.nPurchaseQueues;
        let st = this.state;

        //function determineColumns() {
        //	console.log(pq)
        //	if (pq && pq.length > 0) {
        //		if (["uat", "local"].includes(companyName) && pq.filter(x => x.vendor === "Bright Way Group").length > 0) {
        //	//if (st.nPurchaseQueues !== null && st.nPurchaseQueues.length > 0) {
        //	//	if (["uat", "local"].includes(companyName) && st.nPurchaseQueues.filter(x => x.vendor === "Bright Way Group").length > 0) {
        //			// if (companyName === "uat" && st.nPurchaseQueues[0].vendor && st.nPurchaseQueues[0].vendor.name === "Medline") {
        //			// if (companyName === "corkmedical" && st.nPurchaseQueues[0].vendor && st.nPurchaseQueues[0].vendor.name === "Medline") {
        //			return st.approvalDataLong.columns;
        //		} else {
        //			return st.approvalData.columns;
        //		}
        //	}
        //}

        return (
            <Modal
                centered
                size="xl"
                isOpen={st.approvalModal}
                toggle={() => {
                    return;
                }}
            >
                <CardHeader className="text-center font-weight-bold blue-gradient">
                    <h3>Approve Equipment</h3>
                </CardHeader>
                <ModalBody>
                    <p style={{ color: "red" }}>{st.errorMessage}</p>
                    <MDBInput
                        outline
                        label={"Brightree P.O."}
                        onChange={(evt) =>
                            this.setState({
                                brightreeId: evt.target.value,
                            })
                        }
                    />
                    <div>
                        <MDBTable small striped>
                            <MDBTableHead columns={this.determineColumns()} />
                            <MDBTableBody style={{ paddingTop: "3px", paddingBottom: "3px" }} rows={this.renderApprovalTable()} />
                        </MDBTable>

                        <div>
                            <Button
                                color={"red"}
                                floating
                                data-tip={"Cancel"}
                                size={"sm"}
                                label={"Close"}
                                onClick={() => {
                                    this.setState({ approvalModal: false, errorMessage: "" });
                                }}
                            >
                                <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                            </Button>
                            <Button
                                color={"green"}
                                floating
                                data-tip={"Save"}
                                size={"sm"}
                                onClick={this.approvePurchaseQueues}
                            >
                                <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                            </Button>
                        </div>
                    </div>
                </ModalBody>

                <ReactTooltip />
            </Modal>
        );
    }

    renderQueues() {
        return this.state.nPurchaseQueues.map((p, index) => {
            return (
                <MDBListGroupItem key={index}>
                    <div className="d-flex w-100 justify-content-between">
                        {!p.quoteId ? (
                            <Input
                                label={"Cost"}
                                onChange={this.handleCost.bind(this, index)}
                            />
                        ) : (
                            <div />
                        )}
                        <h5 className="mb-1">ID: {p.id}</h5>
                    </div>
                    {p.quoteId ? (
                        <p className="mb-1">Ordered from quote</p>
                    ) : (
                        <div>
                            <p className="mb-1">{p.productSubType.name}</p>
                            <small className="text-muted">{"Quantity: " + p.quantity}</small>
                        </div>
                    )}
                </MDBListGroupItem>
            );
        });
    }

    renderApprovalTable() {
        const { companyName } = this.context;

        let st = this.state,
            arr = [];

        st.nPurchaseQueues.map((p, index) => {
            // if (companyName === "corkmedical" && p.vendor === "Bright Way Group") {
            let obj = {
                id: p.id,
                qty: (
                    <MDBInput
                        containerClass={"tableInputContainer"}
                        valueDefault={p.quantity}
                        style={{ width: 80, backgroundColor: "white" }}
                        type={"number"}
                        outline
                        onChange={this.handleQty.bind(this, index)}
                    />
                ),
                desc: this.renderProductDropdown(p.productSubType, index, p),
                cost: (
                    <MDBInput
                        containerClass={"tableInputContainer"}
                        valueDefault={p.cost}
                        style={{ width: 80, backgroundColor: "white" }}
                        type={"number"}
                        outline
                        onChange={this.handleCost.bind(this, index)}
                    />
                ),
            };
            // if (companyName === "corkmedical" && p.vendor === "Medline") {
            // if (companyName === "uat" && p.vendor.name === "Medline") {
            if (companyName === "uat" && p.vendor === "Bright Way Group") {
                obj.account = this.renderAccountDropdown.bind(this, index);
            }

            return arr.push(obj);
        });
        return arr;
    }

    handleCost(index, evt) {
        let pq = this.state.nPurchaseQueues;
        pq[index].cost = parseFloat(evt.target.value) || 0;
        this.setState({
            nPurchaseQueues: pq,
        });
    }

    handleQty(index, evt) {
        let pq = this.state.nPurchaseQueues;
        pq[index].quantity = evt.target.value;
        this.setState({
            nPurchaseQueues: pq,
        });
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div className={"purchasingGrid"} style={{ height: "100%" }}>
                {this.renderTable()}
            </div>
        );
    }

    render() {
        let h = window.innerHeight;
        return (
            <div style={{ overflowY: "scroll", height: h * 0.7, maxHeight: h * 0.7 }}>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{ marginTop: "75px" }}
                />
                {this.renderTableOrSpinner()}
                {this.renderApproveModal()}
            </div>
        );
    }
}
