import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import {
    GroupingState,
    SortingState,
    IntegratedSorting,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
    Button,
    Container,
    Col,
    Row,
    Spinner,
    toast,
    ToastContainer,
    MDBIcon,
    MDBBtn,
    MDBModalHeader,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBInput,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import ReactTooltip from "react-tooltip";
import OrderService from "../Security/OrderService/orderService";
import GlobalFunctions from "../Filters/GlobalFunctions";
import moment from "moment";
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import LayerLegacy from "../../LayerLegacy";

const ItemCounter = () => (
    <Plugin name="ItemCounter">
        <Template
            name="tableCell"
            predicate={({ tableRow }) => isGroupTableRow(tableRow)}
        >
            {(params) => (
                <TemplateConnector>
                    {({ getCollapsedRows }) => {
                        const updatedParams = {
                            ...params,
                            tableRow: {
                                ...params.tableRow,
                                row: {
                                    ...params.tableRow.row,
                                    collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                },
                            },
                        };
                        return <TemplatePlaceholder params={updatedParams} />;
                    }}
                </TemplateConnector>
            )}
        </Template>
    </Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

function getVal(row) {
    if (row.collapsedRows.length > 0) {
        return "Count: " + row.collapsedRows.length;
    } else {
        return "";
    }
}

const Content = ({ row, column }) => (
    <span>
        <span>
            {column.title} : {row.value}
        </span>
        <span style={{ fontWeight: "bold" }}>&nbsp; {getVal(row)}</span>
    </span>
);

const compareDate = (a, b) => {
    const priorityA = new Date(a);
    const priorityB = new Date(b);
    if (priorityA === priorityB) {
        return 0;
    }
    return priorityA < priorityB ? -1 : 1;
};

export default class collectionsView extends LayerLegacy {
    constructor(props) {
        super(props);

        const groupColumns = [
            { columnName: "status" },
            { columnName: "approvalDate" },
            { columnName: "serviceLocation" },
        ];

        const data = {
            columns: [
                {
                    title: "ID",
                    name: "id",
                },
                {
                    title: "Approval Date",
                    name: "approvalDate",
                },
                {
                    title: "Patient Name",
                    name: "patientName",
                },
                {
                    title: "Phone",
                    name: "phone",
                },
                {
                    title: "Service Location",
                    name: "serviceLocation",
                },
                {
                    title: "Patient Responsibility Amount",
                    name: "patientResponsibilityAmount",
                },
                {
                    title: "Sales Tax",
                    name: "stateSalesTax",
                },
                {
                    title: "Status",
                    name: "status",
                },
                {
                    title: "Action",
                    name: "action",
                },
            ],
            paymentCollectedColumns: [
                {
                    title: "ID",
                    name: "id",
                },
                {
                    title: "Order Id",
                    name: "orderId"
                },
                {
                    title: "Patient Responsibility",
                    name: "patientResponsibility",
                },
                {
                    title: "RTD Date",
                    name: "rtdDate",
                },
                {
                    title: "Payment Collected",
                    name: "paymentWasCollected",
                },
                {
                    title: "Collector",
                    name: "collector",
                },
                {
                    title: "Date Collected",
                    name: "dateCollected",
                },
                {
                    title: "Amount Collected",
                    name: "amountCollected",
                },
                {
                    title: "Billed Date",
                    name: "billedDate",
                }
            ],
            rows: [],
            integratedSortingColumnExtensions: [
                { columnName: "approvalDate", compare: compareDate },
            ],
        };

        let defaultColumnWidths = [
            { columnName: "id", width: 120 },
            { columnName: "approvalDate", width: 140 },
            { columnName: "patientName", width: 250 },
            { columnName: "phone", width: 250 },
            { columnName: "serviceLocation", width: 250 },
            { columnName: "patientResponsibilityAmount", width: 300 },
            { columnName: "stateSalesTax", width: 200 },
            { columnName: "status", width: 150 },
            { columnName: "action", width: 150 },
        ];

        this.state = {
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            approvals: {},
            defaultColumnWidths: defaultColumnWidths,
            groupSummaryItems: [],
            actionsModalOpen: false,
            customerSuccessDisabled: false,
            updatedPaymentCollected: {},
            actionsBtnHover: false,
            paymentsCollected: [],
            collectionsModal: false,
            startDate: null,
            endDate: null,
        };

        this.getResults();
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    getResults() {
        return OrderService.getCollectionsViewNative()
            .then((res) => {
                this.buildNewResults(res);
            })
            .catch((err) => {
                this.setState({ isLoaded: true });
            });
    }
    getApprovals(id) {
        return OrderService.getApprovalsByOrder(id)
            .then((res) => {
                if (!res || res.length < 1)
                    toast.warn("No approvals Found.");
                console.log(res);
                this.setState({
                    approvals: res,
                    updatedPaymentCollected: res,
                });
                this.toggleActionsModal();
            })
            .catch((err) => {
                toast.error("No approvals for order #" + id + ".");
                console.log(err);
            });
    }

    downloadFunction() {
        this.clientCSV(this.state.data, "collections.csv");
    }

    updateApproval() {
        const { currentUser } = this.context;
        let up = this.state.updatedPaymentCollected;
        up["paymentProcessedById"] = currentUser.id;
        up["paymentProcessedOn"] = GlobalFunctions.getUTCMoment(new Date());

        return OrderService.updateApprovalByOrder(up)
            .then((res) => {
                toast.success("Successfully updated payment amount collected.");
                this.setState({
                    actionsModalOpen: false,
                    updatedPaymentCollected: {},
                    approvals: {},
                });
            })
            .catch((err) => {
                toast.warn("Error updating payment amount collected");
            });
    }

    paymentsCollectedClientCSV() {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;
        let filename = "PaymentCollectionsHistory.csv";

        data = this.state.paymentsCollected;

        if (data == null || !data.length) {
            return null;
        }

        let columns = this.state.data.paymentCollectedColumns;

        columns.forEach((col) => {
            keys.push(col.name);
            headers.push(col.title);
        });

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;
        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {

                if (ctr > 0)
                    result += columnDelimiter;
                if (key.toLowerCase().indexOf("date") > -1) {
                    result += GlobalFunctions.formatFlatDate(item[key]);
                }
                else {
                    result += item[key];
                }
                ctr++;
            });
            result += lineDelimiter;
        });
        this.downloadBlob(result, filename);
    }

    renderActionsBtn(id) {
        return (
            <>
                <MDBBtn
                    size="sm"
                    floating
                    color="primary"
                    data-tip="View Approvals"
                    onMouseEnter={() => this.setState({ actionsBtnHover: true })}
                    onMouseLeave={() => this.setState({ actionsBtnHover: false })}
                    onClick={() => this.getApprovals(id)}>
                    <MDBIcon icon="info-circle" />
                </MDBBtn>
                <ReactTooltip />
            </>
        );
    }

    toggleActionsModal = () => {
        this.setState({
            actionsModalOpen: !this.state.actionsModalOpen,
        });
    };

    //This will convert all the data in the grid to a csv file
    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.name);
            headers.push(col.title);
        });

        let replace = ["patientName", "phone"];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    approvalAndUpdate(prop, val) {
        let up = this.state.updatedPaymentCollected;
        up[prop] = val;
        this.setState({
            updatedPaymentCollected: up,
        });
    }

    getPaymentsCollected() {
        let sd = this.state.startDate;
        let ed = this.state.endDate;

        let filters = {
            start: sd,
            end: ed,
        };

        return OrderService.getPaymentsCollected(filters)
            .then((res) => {
                console.log(res);
                if (res.length === 0) {
                    toast.error("No collections found.");
                }
                this.setState({
                    paymentsCollected: res,
                    collectionsModal: false,
                    startDate: null,
                    endDate: null,
                });
                this.paymentsCollectedClientCSV();
            })
            .catch((err) => {
                this.setState({ isLoaded: true });
            });
    }

    buildNewResults(res) {
        let ary = [],
            dt = this.state.data;

        res.forEach((val) => {
            ary.push({
                id: val.id,
                approvalDate: new Date(val.approvalDate).toLocaleDateString(),
                patientName: val.patientName,
                phone: val.phone,
                serviceLocation: val.serviceLocation,
                patientResponsibilityAmount: val.patientResponsibility,
                stateSalesTax: val.stateSalesTax,
                status: val.status,
                action: this.renderActionsBtn(val.id),
                patientId: val.patientId,
                orderType: val.orderType
            });
        });

        dt.rows = ary;

        toast.success("Found " + res.length + " Results");

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    renderTable() {
        const Cell = ({ row, column, ...props }) => {
            return (
                <Table.Cell
                    {...props}
                    onClick={(e) => {
                        let col = props.tableColumn.column.name;
                        if (col !== "action") {
                            if (row.orderType && (row.orderType.toLowerCase() === "service" || row.orderType.toLowerCase() === "pickup" || row.orderType.toLowerCase() === "billing issue")) {
                                if (e.ctrlKey === true) {
                                    this.setState({
                                        targetPage: "/patientInformation/",
                                        targetLink: row.patientId + "/" + row.id,
                                    });

                                    setTimeout(() => {
                                        document.getElementById("orderListId").click();
                                    }, 5);
                                } else {
                                    this.props.history.push({
                                        pathname: "/patientInformation/" + row.patientId + "/" + row.id
                                    });
                                }
                                return;
                            }
                            if (e.ctrlKey === true && col !== "action") {
                                this.setState({
                                    targetPage: "/order/",
                                    targetLink: row.id,
                                });

                                setTimeout(() => {
                                    document.getElementById("orderListId").click();
                                }, 5);
                            } else {
                                this.props.history.push({
                                    pathname: "/order/" + row.id,
                                    state: { goto: "collections" },
                                });
                            }
                        }
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                />
            );
        };

        if (this.state.isLoaded === true) {
            return (
                <Grid
                    style={{ maxWidth: "1800px !important" }}
                    rows={this.state.data.rows}
                    rootComponent={Root}
                    columns={this.state.data.columns}
                >
                    <DragDropProvider />
                    <SortingState
                        // defaultSorting={[{ columnName: "approvalDate", direction: "desc" }]}
                    />
                    <IntegratedSorting
                        columnExtensions={this.state.data.integratedSortingColumnExtensions}
                    />
                    <GroupingState
                        grouping={this.state.groupingColumns}
                        onGroupingChange={this.changeGrouping}
                    />
                    <IntegratedGrouping />
                    <PagingState defaultCurrentPage={0} pageSize={25} />
                    <IntegratedPaging />
                    <VirtualTable cellComponent={Cell} height={"auto"} />
                    <TableColumnResizing
                        defaultColumnWidths={this.state.defaultColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />
                    <PagingPanel />
                    <TableGroupRow contentComponent={Content} showColumnsWhenGrouped />
                    <ItemCounter />
                    <Toolbar />
                    <GroupingPanel />
                </Grid>
            );
        } else {
            return <div />;
        }
    }

    toggleExportModal() {
        let curState = this.state.collectionsModal;
        this.setState({
            collectionsModal: !curState,
        });
    }

    handleDatePickerChange = (property, value) => {
        this.setState({
            [property]: moment(value).format("MM/DD/YYYY"),
        });
    };

    renderCollectionsExportModal() {
        let st = this.state;

        return (
            <MDBModal
                isOpen={ st.collectionsModal }
                toggle={ () => this.toggleExportModal() }
                centered
            >
                <MDBModalHeader style={ { textAlign: "center" } }>
					Export Payment Collections
                </MDBModalHeader>
                <MDBModalBody>
                        <Row style={ { paddingBottom: "4%" } }>
                            <Col size="6">
                                <div className={ "uiOutlines" }>
                                    <MUIDatePicker
                                         label={"Start Date"}
                                         value={this.state.startDate ? moment(this.state.startDate) : null}
                                         slotProps={{
                                             field: {
                                                 clearable: true,
                                             },
                                             textField: {
                                                 fullWidth: true,
                                                 size: "small",
                                                 variant: 'outlined',
                                                 InputLabelProps: {
                                                     shrink: true
                                                 },
                                             },
                                         }}
                                         onChange={this.handleDatePickerChange.bind(this, "startDate")}
                                         views={['year', 'month', 'day']}
                                    />
                                </div>
                            </Col>
                            <Col size="6">
                                <div className={ "uiOutlines" }>
                                     <MUIDatePicker
                                         label={"End Date"}
                                         value={this.state.endDate ? moment(this.state.endDate) : null}
                                         slotProps={{
                                             field: {
                                                 clearable: true,
                                             },
                                             textField: {
                                                 fullWidth: true,
                                                 size: "small",
                                                 variant: 'outlined',
                                                 InputLabelProps: {
                                                     shrink: true
                                                 },
                                             },
                                         }}
                                         onChange={this.handleDatePickerChange.bind(this, "endDate")}
                                         views={['year', 'month', 'day']}
                                    />
                                </div>
                            </Col>
                        </Row>
                </MDBModalBody>
                <MDBModalFooter >
                    <Button
                        style={ { float: "right", whiteSpace: "nowrap" } }
                        size={ "sm" }
                        disabled={ this.state.startDate !== null && this.state.endDate !== null ? false : true }
                        color={ "primary" }
                        onClick={ () => this.getPaymentsCollected() }
                    >
                        <MDBIcon icon="download" style={ { marginRight: "6px" } } />
						Export
                    </Button>
                </MDBModalFooter>
            </MDBModal >
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div className={"purchasingGrid ordersListGrid"} style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
                <Row style={ { maxWidth: "100%", backgroundColor: "#f6f6f6", margin: "0 auto", borderBottom: "1px solid #c1c1c1" } }>
                    <Col>
                        <Button
                            style={ { float: "right", whiteSpace: "nowrap" } }
                            size={ "sm" }
                            color="mdb-color"
                            onClick={ () => this.toggleExportModal() }
                        >
                            <MDBIcon icon="download" style={ { marginRight: "5%" } } />
							Export Payment Collections
                        </Button>
                    </Col>
                </Row>
                {this.renderTable()}
            </div>
        );
    }

    renderContent() {
        let appr = this.state.approvals;
        return (
            <div>
                { this.renderCollectionsExportModal() }
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{ marginTop: "75px" }}
                />

                <MDBModal
                    isOpen={this.state.actionsModalOpen}
                    toggle={() => {
                        this.toggleActionsModal();
                    }}
                >
                    <MDBModalHeader>Payment Information</MDBModalHeader>
                    <MDBModalBody>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol
                                    size={"6"}
                                    className={"insuranceSelects insuranceSelectsText"}
                                >
                                    <MDBInput
                                        type={"number"}
                                        outline
                                        style={{ paddingTop: 8 }}
                                        label={"Deductible"}
                                        disabled={true}
                                        valueDefault={appr ? appr.deductible : ""}
                                    />
                                </MDBCol>
                                <MDBCol
                                    size={"6"}
                                    className={"insuranceSelects insuranceSelectsText"}
                                >
                                    <MDBInput
                                        type={"number"}
                                        outline
                                        style={{ paddingTop: 8 }}
                                        label={"Co-Insurance"}
                                        disabled={true}
                                        valueDefault={appr ? appr.coInsurance : ""}
                                    />
                                </MDBCol>
                                <MDBCol
                                    size={"6"}
                                    className={"insuranceSelects insuranceSelectsText"}
                                >
                                    <MDBInput
                                        type={"number"}
                                        outline
                                        style={{ paddingTop: 8 }}
                                        label={"Pt. Resp"}
                                        disabled={true}
                                        valueDefault={appr ? appr.patientResponsibility : ""}
                                    />
                                </MDBCol>
                                <MDBCol
                                    size={"6"}
                                    className={"insuranceSelects insuranceSelectsText"}
                                >
                                    <MDBInput
                                        type={"number"}
                                        outline
                                        style={{ paddingTop: 8 }}
                                        label={"State Sales Tax"}
                                        disabled={true}
                                        value={appr ? appr.stateSalesTax : ""}
                                    />
                                </MDBCol>
                                <MDBCol
                                    size={"12"}
                                    className={"insuranceSelects insuranceSelectsText"}
                                >
                                    <MDBInput
                                        type={"number"}
                                        outline
                                        style={{ paddingTop: 8 }}
                                        label={"Payment On Delivery"}
                                        disabled={true}
                                        valueDefault={appr ? appr.paymentOnDelivery : ""}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBCardBody style={{ paddingLeft: '.45rem' }}>
                            <MDBCol
                                size={"12"}
                                className={"insuranceSelects insuranceSelectsText"}
                            >
                                <MDBInput
                                    type={"number"}
                                    outline
                                    style={{ paddingTop: 8 }}
                                    label={"Payment Amount Collected"}
                                    valueDefault={appr ? appr.paymentCollected : ""}
                                    onChange={(evt) =>
                                        this.approvalAndUpdate(
                                            "paymentCollected",
                                            evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null
                                        )
                                    }
                                />
                            </MDBCol>
                        </MDBCardBody>
                        <MDBRow end>
                            <MDBCol>
                                <MDBBtn
                                    floating
                                    size="sm"
                                    data-tip={"Cancel"}
                                    color="danger"
                                    onClick={() => {
                                        this.toggleActionsModal();
                                        this.setState({
                                            updatedPaymentCollected: {},
                                            approvals: {},
                                        });
                                    }}
                                >
                                    <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                                </MDBBtn>
                                <MDBBtn
                                    floating
                                    size="sm"
                                    color={"success"}
                                    data-tip={"Save"}
                                    onClick={() => this.updateApproval()}
                                >
                                    <MDBIcon icon="save" style={{ fontSize: "2em" }} />
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalFooter>
                    <ReactTooltip />
                </MDBModal>


                <div style={ { maxWidth: "98%", marginLeft: "1rem", marginTop: "1%" } }>
                    <Row>
                        <Col size="12">{this.renderTableOrSpinner()}</Col>
                    </Row>
                    <Link
                        id="orderListId"
                        to={{
                            pathname: `${this.state.targetPage}${this.state.targetLink}`,
                            state: { goto: "collections" },
                        }}
                        target="_blank"
                        rel="opener"
                        style={{ display: "none" }}
                        activeclassname="active"
                    />

                    <div className={"fixed-bottom downloadCsvDiv"}>
                        <Button
                            className={"downloadCsvButton"}
                            floating
                            size="sm"
                            color={"primary"}
                            data-tip={"Download CSV"}
                            onClick={this.clientCSV.bind(
                                this,
                                this.state.data,
                                "Collections.csv"
                            )}
                        >
                            <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                        </Button>

                        <ReactTooltip />
                    </div>
                </div>
            </div>
        );
    }
}