import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import MUIGreenColor from "@mui/material/colors/green";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminMarketingCampaignList extends Card {
    static contextTypes = {
        marketingCampaignsIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "campaignTypeId", name: "Campaign Category", type: "marketingCampaignCategory", filter: true },
                    { key: "name", name: "Name", type: "string" },
                    { key: "isActive", name: "Active", type: "boolean", icon: "Check", color: MUIGreenColor[500], width: "110" }
                ]}
                sort={[
                    {
                        key: "campaignTypeId",
                        direction: "ascending",
                        type: "marketingCampaignCategory",
                    },
                    {
                        key: "name",
                        direction: "ascending",
                    },
                ]}
                rows={Object.values(this.context.marketingCampaignsIndexed)}
                onClick={(marketingCampaign) => {
                    this.props.history.push({
                        pathname: "/admin/marketingCampaign/" + marketingCampaign.id
                    });
                }}
            />
        );
    }
});