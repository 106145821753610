import React from "react";
import Dialog from "../Dialog";
import MUIButton from "@mui/material/Button";
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import MUITableContainer from '@mui/material/TableContainer';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import MUIPaper from "@mui/material/Paper";
import MUIBlueColor from "@mui/material/colors/blue";
import MUIDialogActions from "@mui/material/DialogActions";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUITypography from "@mui/material/Typography";
import MUIFormControl from "@mui/material/FormControl";
import MUIInput from "@mui/material/Input";
import MUIInputAdornment from "@mui/material/InputAdornment";
import Setting from "../../Utility/Setting";
 

export default class DialogUpdatePatientCollectionBalance extends Dialog {

    /**
     * Render header title of dialog
     */
      renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                Update Collection Blance
            </MUITypography>
        );
    }

    /**
     * Render search result tiles in a grid.
     */
      renderContent() {
        if (this.props.patient) {
            return (
                <MUITableContainer component={MUIPaper}>
                    <MUITable stickyHeader>
                        <MUITableHead>
                            <MUITableRow>
                                <MUITableCell>Patient</MUITableCell>
                                <MUITableCell align="left">Brightree&nbsp;ID</MUITableCell>
                                <MUITableCell align="right">Balance</MUITableCell>
                            </MUITableRow>
                        </MUITableHead>
                        <MUITableBody>
                            {this.props.patient ? (
                                <MUITableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <MUITableCell component="th" scope="row">{this.props.patient.firstName + " " + this.props.patient.lastName}</MUITableCell>
                                    <MUITableCell align="left">
                                        <a
                                            href={`https://brightree.net/F1/02200/OrbitMedical/Patient/frmPatientSummary.aspx?PatientKey=${this.props.patient.brightreeExternalKey}&showAck=1`}
                                            target="_blank"
                                            style={{
                                                fontWeight: "400",
                                                textDecoration: "none",
                                                color: Setting.get("ui.theme") === "dark" ? MUIBlueColor[200] : MUIBlueColor[700],
                                            }}
                                            onMouseEnter={(e) => {
                                                e.target.style.fontWeight = "500";
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.fontWeight = "400";
                                            }}
                                        >
                                            {this.props.patient.brightreeExternalId}
                                        </a>
                                    </MUITableCell>
                                    <MUITableCell sx={{width: "120px"}} align="left">{this.renderCollectionsBalanceInput()}</MUITableCell>
                                </MUITableRow>
                            ) : null}
                        </MUITableBody>
                    </MUITable>
                </MUITableContainer>
            );
        }
    }

    renderCollectionsBalanceInput() {
        const formatToTwoDecimalPlaces = (value) => {
            if (value === undefined || value === null || isNaN(value)) {
                return "0.00";
            }
            const parsedValue = parseFloat(value);
            return parsedValue.toFixed(2); 
        };
        
        return (
            <MUIFormControl fullWidth variant="standard">
                <MUIInput
                    autoFocus={true}
                    defaultValue={this.props.patient ? formatToTwoDecimalPlaces(this.props.patient.collectionsBalance) : ""}
                    inputProps={{
                        maxLength: 255,
                        style: { textAlign: "right" },
                    }}  
                    onInput={(e) => {
                        // Restrict user input to numbers and at most one decimal point
                        e.target.value = e.target.value.replace(/[^0-9.]/g, '');  
                        const decimalCount = e.target.value.split('.')[1]?.length || 0;
                        if (decimalCount > 2) {
                            e.target.value = e.target.value.slice(0, e.target.value.indexOf('.') + 3);  
                        }
                    }}
                    onBlur={(e) => {
                        const parsedValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                        this.props.onChange({ collectionsBalance: parsedValue ? parsedValue : 0 });
                    }}
                    startAdornment={<MUIInputAdornment position="start">$</MUIInputAdornment>}
                />
            </MUIFormControl>
        )
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Cancel
                </MUIButton>
                <MUILoadingButton variant="contained" loading={this.props.loading} onClick={this.props.onConfirm}>
                    Update Collection Balance
                </MUILoadingButton>
            </MUIDialogActions>
        );
    }


    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "sm";
    }
}