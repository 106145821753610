import api from "../api";

class LiaisonMeetingService {
    //This endpoint does not actually exist on the server...BC
    async findOneMeeting(id) {
        return new Promise((res, reject) => {
            return api
                .get("/liaison/" + id)
                .then((meeting) => {
                    res(meeting);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createMeeting(meeting) {
        return new Promise((res, reject) => {
            return api
                .post("/liaison", meeting)
                .then((meeting) => {
                    res(meeting);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateMeeting(meeting) {
        return new Promise((res, reject) => {
            return api
                .put("/liaison", meeting)
                .then((meeting) => {
                    res(meeting);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteMeeting(meeting) {
        return new Promise((res, reject) => {
            return api
                .delete("/liaison/" + meeting.id)
                .then((meeting) => {
                    res(meeting);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async findAllMeetings() {
        return new Promise((res, reject) => {
            return api
                .get("/liaison")
                .then((meeting) => {
                    res(meeting);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    async findAllMeetingTimes() {
        return new Promise((res, reject) => {
            return api
                .get("/liaison/allMeetingTimes")
                .then((meeting) => {
                    res(meeting);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetOpenSalesLiaisonMeetings
    async findAllMeetingsNative() {
        return new Promise((res, reject) => {
            return api
                .get("/liaison")
                .then((meeting) => {
                    res(meeting);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const liaisonMeetingService = new LiaisonMeetingService();

export default liaisonMeetingService;
